import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IIntegration, IZoom } from 'src/app/shared/interfaces/integrations.interface';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    constructor(
        private afDb: AngularFirestore
    ) { }

    /**
     * Getting integration
     * @param eventId 
     */
    getIntegration(eventId: string) {
        return (
            this.afDb.collection('integrations', (ref) => ref.where('eventId', '==', eventId)).snapshotChanges().pipe(
                map((docs) => docs.map((doc) => {
                    return ({
                        id: doc.payload.doc.id,
                        ...doc.payload.doc.data() as IIntegration
                    })
                })),
                switchMap((docs) => {
                    return ((docs && docs.length > 0) ? of(docs[0]) : of(null));
                })
            )
        )
    }

    /**
     * Update zoom integration
     * @param eventId 
     * @param zoom 
     */
    updateZoomIntegration(eventId: string, zoom: IZoom[]) {
        return (
            this.afDb.collection('integrations').doc(eventId).update({
                zoom: zoom
            })
        )
    }

    /**
     * Create integration
     * @param eventId 
     * @param integration 
     */
    createIntegration(eventId: string, integration: IIntegration) {
        return (this.afDb.collection('integrations').doc(eventId).set(integration));
    }
}
