import { DateTime } from "luxon";
import { NameModule } from "./name-module";

export class PageInfobooth {
    uid: string
    moduleId: string
    eventId: string
    title: NameModule;
    icon: string;
    iconFamily: string;
    htmlContent: NameModule;
    creationDate: number;
    order: number;
    pageHTMLContent: string;
    pageContent: string;
    emailEditorActive: boolean;

    constructor() {
        this.uid = ""
        this.moduleId = ""
        this.eventId = ""
        this.title = new NameModule('', '', '', '', '');
        this.htmlContent = new NameModule('', '', '', '', '');
        this.icon = ''
        this.iconFamily = 'material-icons';
        this.creationDate = DateTime.local().valueOf() / 1000;
    }
}