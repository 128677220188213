import { DbAttendeesProvider } from './../providers/database/db-attendees';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbAskQuestionProvider } from '../providers/database/db-ask-question';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DbEventsProvider } from '../providers/database/db.events';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ask-question-result',
    templateUrl: './ask-question-result.component.html',
    styleUrls: ['./ask-question-result.component.scss']
})
export class AskQuestionResultComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    eventId: string = null;
    moduleId: string = null;
    itemId: string = null;
    questions: Array<any> = [];

    refQuestions: any = null;
    refQuestionsCollection: AngularFirestoreCollection<any> = null;

    refVotes: any = null;
    refVotesCollection: AngularFirestoreCollection<any> = null;
    itemName: string = null;
    loader: boolean = true;

    activeLanguage: string = null;

    constructor(
        private dbAskQuestion: DbAskQuestionProvider,
        private dbEvent: DbEventsProvider,
        private route: ActivatedRoute,
        private afs: AngularFirestore,
        private dbAttendee: DbAttendeesProvider,
        private translateService: TranslateService
    ) {
        this.eventId = this.route.snapshot.params['eventId'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.itemId = this.route.snapshot.params['itemId'];

        this.getQuestionsSession();
        this.getEvent()
    }

    ngOnInit() {

    }

    /**
     * Unsubscribe subscriptions on destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * Get an item
     */
    getItem() {
        this.dbAskQuestion.getAskItem(this.moduleId, this.itemId, (item) => {
            this.itemName = item.name[this.activeLanguage];
        })
    }

    /**
     * Get an event
     */
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.activeLanguage = this.convertLangFormat(event.language);
            this.getItem();
        })
    }

    /**
     * Convert lang format
     * @param lang 
     */
    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    /**
     * Get questions for the session
     */
    getQuestionsSession() {
        this.refQuestionsCollection = this.afs
            .collection('modules')
            .doc(this.moduleId)
            .collection('items')
            .doc(this.itemId)
            .collection('questions', ref => ref.orderBy('createdAt').where('visibility', "==", true));


        this.subscriptions.push(this.refQuestionsCollection.valueChanges().subscribe(async (data: any) => {
            const usersIds = Array.from(new Set([...data].map(q => q.userId)));
            const allAttendees = await this.dbAttendee.getAttendeesFromArrayIdsPromise(this.eventId, usersIds)
            this.questions = [...data].map((question) => {
                const refVotes = this.refQuestionsCollection
                .doc(question.uid)
                .collection('votes');

                // sub.push({questionId: question.uid, votes: refVotes})
                this.subscriptions.push(refVotes.valueChanges().subscribe(votes => {
                    question.totalVotes = votes.length
                    this.sortQuestion()
                }))
                
                const attendee = [...allAttendees].filter(_ => _.uid == question.userId)[0]
                const name = attendee ? attendee.name : `${this.translateService.instant('global.anonymous_attendee')} (${usersIds.indexOf(question.userId) + 1})`
                question.userId = name;

                return question
            }).sort((a, b) => {
                return a.totalVotes > b.totalVotes
                ? -1
                : a.totalVotes < b.totalVotes
                ? 1
                : (a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
            });
            this.loader = false;
        }))
        // this.subscriptions.push(this.refQuestionsCollection.valueChanges().subscribe((data: any) => {
        //     let listQuestions = [];

        //     if (data.length <= 0) {
        //         this.questions = [];
        //     }

        //     data.forEach(element => {
        //         let question = element;

        //         this.refVotesCollection = this.refQuestionsCollection
        //             .doc(question.uid)
        //             .collection('votes');

        //         this.dbAttendee.getAttendee(this.eventId, this.moduleId, element.userId, (attendee) => {
        //             question.name = attendee.result.name;
        //             let dateCreated = element.createdAt;
        //             dateCreated = new Date(dateCreated * 1000);
        //             question.createdAt = dateCreated;
                

        //             this.subscriptions.push(this.refVotesCollection.valueChanges().subscribe((data: any) => {
        //                 let totalVotes = data.length;

        //                 question.totalVotes = totalVotes;

        //                 let index = this.checkIndexExists(listQuestions, question);

        //                 if (index >= 0) {
        //                     listQuestions[index] = question;
        //                 } else {
        //                     listQuestions.push(question);
        //                 }

        //                 this.questions = [...listQuestions].sort((a, b) => {
        //                     return a.totalVotes > b.totalVotes
        //                     ? -1
        //                     : a.totalVotes < b.totalVotes
        //                     ? 1
        //                     : (a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
        //                 });

        //                 // this.questions = listQuestions;
        //                 this.loader = false;
        //             }))
        //         })
        //     });
        // }))
    }

    /**
     * Check if index exist
     * @param array 
     * @param item 
     */
    checkIndexExists(array, item) {
        return array.map(function (e) { return e.uid; }).indexOf(item.uid);
    }

    getDate(dateNumber){
        return this.activeLanguage !== "FrFR" ? moment(dateNumber * 1000).format("YYYY/MM/DD - HH:mm") : moment(dateNumber * 1000).format('DD/MM/YYYY - HH:mm')
    }
    sortQuestion(){
        this.questions.sort((a, b) => {
            return a.totalVotes > b.totalVotes
            ? -1
            : a.totalVotes < b.totalVotes
            ? 1
            : (a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
        });
    }
}
