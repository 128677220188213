import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Container } from 'src/app/models/container';
import { StorageService } from '../storage/storage.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DbAdminUserProvider {
    public headers;
    public requestOptions: any;
    private db;

    constructor(
        private auth: AuthService,
        private afAuth: AngularFireAuth,
        private aFirestore: AngularFirestore,
        private http: HttpClient,
        private storageService: StorageService
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {} };

        this.db = aFirestore.firestore;
    }

    setClaims() {
        this.http.get('http://localhost:9000/high5event-a48b5/us-central1/dbUserSetClaimsForExistingUsers')
            .subscribe((a) => console.log(a), (e) => { console.log(e) });
    }

    createUser(user, onResolve) {
        this.auth.createUser(user).then((newUser) => {
            onResolve(newUser)
        })
            .catch((error) => {
                onResolve(error)
            })
    }

    getSuperGods(typeOrder) {
        let ref: AngularFirestoreCollection;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 0)
                        .orderBy('name', 'asc'));
                break;

            case 'desc': //z-a
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 0)
                        .orderBy('name', 'desc'));
                break;

            case 'recent'://distante-próximo
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 0)
                        .orderBy('createdAt', 'desc'));
                break;

            case 'oldest': //próximo-distante
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 0)
                        .orderBy('createdAt', 'asc'));
                break;
        }

        return (ref.valueChanges());
    }

    getGods(typeOrder) {
        let ref: AngularFirestoreCollection;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 1)
                        .orderBy('name', 'asc'));
                break;

            case 'desc': //z-a
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 1)
                        .orderBy('name', 'desc'));
                break;

            case 'recent'://distante-próximo
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 1)
                        .orderBy('createdAt', 'desc'));
                break;

            case 'oldest': //próximo-distante
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 1)
                        .orderBy('createdAt', 'asc'));
                break;
        }

        return (ref.valueChanges());
    }

    getClients(typeOrder) {
        let ref: AngularFirestoreCollection;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.aFirestore
                    .collection('users', (ref) => ref.where('type', '==', 2)
                        .orderBy('name', 'asc'));
                break;

            case 'desc': //z-a
                ref = this.db
                    .collection('users', (ref) => ref.where('type', '==', 2)
                        .orderBy('name', 'desc'));
                break;

            case 'recent'://distante-próximo
                ref = this.db
                    .collection('users', (ref) => ref.where('type', '==', 2)
                        .orderBy('createdAt', 'desc'));
                break;

            case 'oldest': //próximo-distante
                ref = this.db
                    .collection('users', (ref) => ref.where('type', '==', 2)
                        .orderBy('createdAt', 'asc'));
                break;
        }

        return (ref.valueChanges());

        // ref.get()
        //     .then((users) => {
        //         let listUsers = [];
        //         users.forEach(
        //             (doc) => {
        //                 let user = doc.data();
        //                 listUsers.push(user);
        //             }
        //         )
        //         onResolve({
        //             code: 200,
        //             message: 'success',
        //             result: listUsers
        //         });
        //     })
        //     .catch((error) => {
        //         onResolve({
        //             code: 404,
        //             message: 'error',
        //             result: error
        //         });
        //     });
    }

    checkClientWithEmail(email, onResolve) {
        this.db.collection("users")
            .where("email", "==", email)
            .where("type", "==", 2)
            .get()
            .then((snapshot) => {
                let client = null;
                snapshot.forEach((element) => {
                    client = element.data();
                })

                onResolve(client)
            }).catch((error) => {
                onResolve(error)
            })

    }

    getUser(userId, onResolve) {
        this.aFirestore.collection('users').doc(userId).get().toPromise().then((user) => {
            onResolve({
                result: user.data()
            });
        })
    }

    getUserByEmail(email, onResolve) {
        this.db
            .collection('users')
            .where('email', '==', email)
            .get()
            .then((data) => {

                if (data.size <= 0) {
                    onResolve(null);
                }

                let user;
                data.forEach(element => {
                    user = element.data();
                });

                onResolve(user);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    /**
     * Remove a user
     * @param userId 
     * @param onResolve 
     */
    removeUser(userId) {
        this.requestOptions.params.userId = userId;

        return (this.http.delete(PathApi.baseUrl + PathApi.authDeleteUser, this.requestOptions).pipe(
            take(1),
            switchMap((data) => {
                return (of(data['result']));
            })
        ))
    }

    removeClient(clientId, onResolve) {
        this.requestOptions.params.clientId = clientId;

        this.http.delete(PathApi.baseUrl + PathApi.authClientDelete, this.requestOptions).subscribe((data) => {
            onResolve(data['result']);
        }), err => {
            onResolve(err);
        }
    }

    updateUser(userId, user, onResolve) {
        this.auth.updateUserAuth(userId, user, (status) => {
            if (status['result'] == true) {
                this.checkHaveContainerAndUpdate(userId, user.name);
                this.db
                    .collection("users").doc(userId).update({
                        name: user.name,
                        email: user.email,
                        language: user.language,
                        description: user.description,
                        type: user.type,
                        uid: userId,
                        photoUrl: user.photoUrl,
                        title: user.title,
                        company: user.company,
                    })
                    .then((data) => {
                        onResolve({
                            code: 200,
                            message: 'success',
                            result: data
                        });
                    })
                    .catch((error) => {
                        onResolve({
                            code: 500,
                            message: 'error',
                            result: error
                        });
                    });
            } else {
                onResolve({
                    code: 500,
                    message: 'error-change-password',
                    result: null
                })
            }
        })
    }

    checkHaveContainerAndUpdate(clientId, name) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers');

        ref
            .where('clientId', '==', clientId)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        let container = element.data();
                        ref.doc(container.uid).update({ clientName: name });
                    });
                }
            })
    }

    getUserProfile(uid, onResolve) {
        this.db
            .collection('users').doc(uid).get().then((data) => {
                onResolve(data.data());
            }).catch((err) => {

            });
    }

    changeOrderInternalEvents(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ internalEventsOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    changeOrderClientsEvents(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ clientsEventsOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    changeOrderSupergods(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ superGodsOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    changeOrderGods(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ godsOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    changeOrderClients(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ clientsOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    changeOrderEmployees(userId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('users').doc(userId).update({ employeesOrder: typeOrder }).then(() => {
            onResolve(true);
        })
    }

    deleteAndRemakeUser(oldUser, newUser, onResolve) {
        let body = {
            oldUser: oldUser,
            newUser: newUser,
        }

        this.http.post(PathApi.baseUrl + PathApi.dbUserDeleteAndRemakeUser, body, this.requestOptions).subscribe(
            data => {
                onResolve(data)
            },

            err => {
                onResolve(err)
            }
        )
    }

    getContainers(onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers');
        ref
            .onSnapshot((snapshot) => {
                let containers = [];

                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        containers.push(element.data());
                    });
                }
                onResolve(containers);
            })
    }

    getContainer(containerId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers').doc(containerId);
        ref
            .get()
            .then((snapshot) => {
                onResolve(snapshot.data());
            })
    }

    getContainersClient(clientId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers').where('clientId', '==', clientId);
        ref
            .onSnapshot((snapshot) => {
                let containers = [];

                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        containers.push(element.data());
                    });
                }
                onResolve(containers);
            })
    }

    createContainer(container: Container, image: any,loginImg1: any,loginImg2: any, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers').doc();
        container.uid = ref.id;
        const container1stImgID = `${container.uid}_1stImg`;
        const container2ndImgID = `${container.uid}_2ndImg`;
        this.storageService.containerLogo(image, container.uid)
            .then((url: string) => {
                container.logo = url;
                container = Object.assign({}, container);
                ref.set(container)
                    .then(_ => onResolve(true))
                    .catch((e) => {
                        console.error(e);
                        onResolve(false);
                    });
            })
        this.storageService.containerLogo(loginImg1, container1stImgID)
            .then((url: string) => {
                container.login1stBackground = url;
                container = Object.assign({}, container);
                ref.set(container)
                    .then(_ => onResolve(true))
                    .catch((e) => {
                        console.error(e);
                        onResolve(false);
                    });
            })
        this.storageService.containerLogo(loginImg2, container2ndImgID)
            .then((url: string) => {
                container.login2ndBackground = url;
                container = Object.assign({}, container);
                ref.set(container)
                    .then(_ => onResolve(true))
                    .catch((e) => {
                        console.error(e);
                        onResolve(false);
                    });
            })
    }

    async removeBgImage(container: Container, imgType: string, onResolve){
        let ref = this.aFirestore.firestore.collection('containers').doc(container.uid);
        const containerImgID = `${container.uid}_${imgType}Img`;
        // await this.storageService.deleteBgImage(containerImgID)
        //     .then(value => {
        //         console.log(value)
        //         if(value) {
        //         }
        //     })

        if(imgType === '1st') container.login1stBackground = '';
        else if(imgType === '2nd') container.login2ndBackground = '';
        container = Object.assign({}, container);
        ref.update(container)
            .then(_ => {
                this.storageService.deleteBgImage(containerImgID)
                .then(value => {
                    onResolve(true)
                    console.log(value)
                    // if(value) {
                    // }
                })
                // onResolve(true)
            })
            .catch((e) => {
                console.error(e);
                onResolve(false);
        });
    }

    updateContainer(container: Container, image: any,loginImg1: any,loginImg2: any, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers').doc(container.uid);
        const container1stImgID = `${container.uid}_1stImg`;
        const container2ndImgID = `${container.uid}_2ndImg`;
        if (image !== null && image !== undefined) {
            this.storageService.containerLogo(image, container.uid)
                .then((url: string) => {
                    container.logo = url;
                    container = Object.assign({}, container);
                    ref.update(container)
                        .then(_ => onResolve(true))
                        .catch((e) => {
                            console.error(e);
                            onResolve(false);
                        });
                })
        } else if (loginImg1 !== null && loginImg1 !== undefined) {
            this.storageService.containerLogo(loginImg1, container1stImgID)
                .then((url: string) => {
                    container.login1stBackground = url;
                    container = Object.assign({}, container);
                    ref.update(container)
                        .then(_ => onResolve(true))
                        .catch((e) => {
                            console.error(e);
                            onResolve(false);
                        });
                })
        } else if (loginImg2 !== null && loginImg2 !== undefined) {
            this.storageService.containerLogo(loginImg2, container2ndImgID)
                .then((url: string) => {
                    container.login2ndBackground = url;
                    container = Object.assign({}, container);
                    ref.update(container)
                        .then(_ => onResolve(true))
                        .catch((e) => {
                            console.error(e);
                            onResolve(false);
                        });
                })
        } else {
            container = Object.assign({}, container);
            ref.update(container)
                .then(_ => onResolve(true))
                .catch((e) => {
                    console.error(e);
                    onResolve(false);
                });
        }
    }

    deleteContainer(container: Container, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('containers').doc(container.uid);

        ref.delete()
            .then(_ => onResolve(true))
            .catch((e) => {
                console.error(e);
                onResolve(false);
            });
    }
}