import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { Event } from '../../models/event';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';

@Component({
  selector: 'app-events-b3',
  templateUrl: './events-b3.component.html',
  styleUrls: ['./events-b3.component.scss'] 
})

@NgModule({
  declarations: [FilterPipe], 
})

export class EventsB3Component implements OnInit {
  // models
  events: Array<Event>;
  eventRemove: Event = null;
  loader: boolean = true;
  public p: number = 1;
  typeOrder: string = 'asc';
  userId: string = null;
  term: string = ''; 

  @ViewChild('successSwal') public successSwal: SwalComponent;

  constructor(
    private dbEvents: DbEventsProvider,
    private dbUser: DbAdminUserProvider,
    private global: GlobalService,
    private dt: DateTimeService
  ) {
    this.global.loadService(() => {
      this.loadOrder().then(() => {
        this.loadEvents();  
      });
    })
  }

  ngOnInit() {
  }

  loadOrder() {
    return new Promise((resolve) => {
      if(this.global.userType == TypeUser.SUPERGOD || this.global.userType == TypeUser.GOD) {
        this.userId = this.global.userId;
        
        this.dbUser.getUser(this.userId, (data) => {
          let user = data.result;

          if(user.clientsEventsOrder !== undefined && user.clientsEventsOrder !== null && user.clientsEventsOrder !== '') {
            this.typeOrder = user.clientsEventsOrder;
          }
  
          resolve(true);
        })
      } else {
        resolve(true);
      }
    })
  }

  changeOrder() {
    this.dbUser.changeOrderInternalEvents(this.userId, this.typeOrder, (data) => {
      if (data == true) {
        this.loadEvents();
      }
    })
  }

  loadEvents() {
    this.dbEvents.getEventsB3App(this.typeOrder, events => {
      this.events = [];

      for (const event of events) {
        event.startDate = this.dt.getDate(event.startDate);
        event.endDate = this.dt.getDate(event.endDate);

        this.events.push(event);
      }

      this.loader = false;
    });
  }

  setEventRemove(event) {
    this.eventRemove = event;
  }

  deleteEvent() {
    this.loader = true;

    const eventId = this.eventRemove.uid;

    this.dbEvents.deleteEvent(eventId, (data) => {
      if (data) {
        this.successSwal.fire();
        this.loader = false;
      }
    })
  }

}
