import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './providers/global/global.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    interval: any;
    constructor(public titleSvc: Title,
         public translateService: TranslateService, 
         public global: GlobalService) {
        this.setLanguageUse(this.checkLanguage(navigator.language));
        titleSvc.setTitle(environment.platform.name);
    }

    ngOnInit() {
        let aux = localStorage.getItem('idToken');

        if (aux !== null && aux !== undefined && aux !== '') {
            this.global.loadService((_) => {
                this.setLanguageUse(this.global.language);
            });
        }

        this.interval = setInterval(() => {
            if (this.global.language !== null) {
                this.setLanguageUse(this.global.language);
                clearInterval(this.interval);
            }
        }, 1000);

        $(document).ready(function () {
            // $("body").tooltip({ selector: '[data-toggle=tooltip]' });
            $("label.icon-selector").click(function () {
                $("#dropdownMenuButton").dropdown("toggle");
            });
        });
    }

    // @HostListener('window:beforeunload', ['$event'])
    // beforeUnload(evt) {
    //     // Track leave app analytics
    //     this.timerComponent.storeTrakingTimeToDB();
    //     return (true);
    // }

    // @HostListener('window:unload', ['$event'])
    // Unload(evt) {
    //     // Track leave app analytics
    //     this.timerComponent.storeTrakingTimeToDB();
    //     return (true);
    // }

    checkLanguage(language: string) {
        if (language == 'pt-BR') {
            return 'pt_BR';
        } else if (language == 'en-US') {
            return 'en_US';
        } else if (language == 'fr-FR') {
            return 'fr_FR';
        } else if (language == 'es-ES') {
            return 'es_ES';
        } else if (language == 'de-DE') {
            return 'de_DE';
        } else {
            return environment.platform.defaultLanguage;
        }
    }

    setLanguageUse(language: string) {
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
    }
}
