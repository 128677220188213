import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DbClientEmployeeProvider } from 'src/app/providers/database/db-client-employee';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { User } from 'src/app/models/user';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { IfStmt } from '@angular/compiler';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { TranslateService } from '@ngx-translate/core'
declare let $: any;

@Component({
    selector: 'app-edit-employee',
    templateUrl: './edit-employee.component.html',
    styleUrls: ['./edit-employee.component.scss']
})
export class EditEmployeeComponent implements OnInit {
    formValidation: FormGroup;
    public eventId: string = null;
    public moduleId: string = null;
    loader: boolean = false;
    loaderView: boolean = true;
    employeeId: string = null;

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorPermissionSwal') public errorPermissionSwal: SwalComponent;
    @ViewChild('confirmChangeUserTypeSwal') public confirmChangeUserTypeSwal: SwalComponent;
    @ViewChild('alreadyExistsSwal') public alreadyExistsSwal: SwalComponent;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    userRemoveId: string = null;
    userRemoveType: number = null;
    userRemoveTypeStr: string = null;

    photoBase64: string = null;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;

    constructor(private storage: StorageService,
        private global: GlobalService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbClientEmployee: DbClientEmployeeProvider,
        private dbAdminUser: DbAdminUserProvider,
        private auth: AuthService,
        private dbAttendee: DbAttendeesProvider,
        private dbSpeaker: DbSpeakersProvider,
        private translateService: TranslateService) {

        this.employeeId = this.route.snapshot.params['employeeId'];
        this.formValidation = fb.group({
            'photoUrl': [null],
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(35)])],
            'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
            'title': [null, Validators.compose([Validators.maxLength(50)])],
            'company': [null, Validators.compose([Validators.maxLength(50)])],
            'password': [null],
            'language': ['pt_BR', Validators.compose([Validators.required])],
            'description': [null],
        });

        this.getUser();
    }

    ngOnInit() {
    }

    getUser() {
        this.dbAdminUser.getUser(this.employeeId, (data) => {
            let userData = data['result'];
            this.photoBase64 = userData.photoUrl;
            this.formValidation.patchValue({
                photoUrl: userData.photoUrl,
                name: userData.name,
                email: userData.email,
                title: userData.title,
                company: userData.company,
                language: userData.language,
                description: userData.description
            });
            this.loaderView = false;
        });
    }


    checkEmailEditEmployee() {
        let data = this.formValidation.value;

        this.dbAdminUser.getUserByEmail(data.email, (user) => {
            if (user === null) {
                this.userRemoveId = null;
                this.userRemoveType = null;
                this.userRemoveTypeStr = null;
                this.editEmployee();

            } else {

                if (user.type === TypeUser.SUPERGOD) {
                    if (this.global.userType === TypeUser.SUPERGOD) {
                        this.userRemoveId = user.uid;
                        this.userRemoveType = user.type;
                        this.userRemoveTypeStr = this.translateService.instant('global.type_user_supergod');;
                        this.confirmChangeUserTypeSwal.fire();
                    } else {
                        this.errorPermissionSwal.fire();
                    }

                } else if (user.type === TypeUser.GOD) {
                    if (this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD) {
                        // Pergunta se o usuário deseja transformar o GOD em CLIENT
                        this.userRemoveId = user.uid;
                        this.userRemoveType = user.type;
                        this.userRemoveTypeStr = this.translateService.instant('global.type_user_god');
                        this.confirmChangeUserTypeSwal.fire();
                    } else {
                        //Informa que o usuário não tem permissão para mudar o tipo de usuário.
                        this.errorPermissionSwal.fire();
                    }

                } else if (user.type === TypeUser.CLIENT) {
                    if (this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD) {
                        // Pergunta se o usuário deseja transformar o CLIENT em SUPERGOD
                        this.userRemoveId = user.uid;
                        this.userRemoveType = user.type;
                        this.userRemoveTypeStr = this.translateService.instant('global.type_user_client');
                        this.confirmChangeUserTypeSwal.fire();
                    } else {
                        //Informa que o usuário não tem permissão para mudar o tipo de usuário.
                        this.errorPermissionSwal.fire();
                    }

                } else if (user.type === TypeUser.EMPLOYEE) {
                    this.userRemoveId = null;
                    this.userRemoveType = null;
                    this.userRemoveTypeStr = null;
                    this.editEmployee();

                } else if (user.type === TypeUser.SPEAKER) {
                    this.userRemoveId = user.uid;
                    this.userRemoveType = user.type;
                    this.userRemoveTypeStr = this.translateService.instant('global.type_user_speaker');
                    this.confirmChangeUserTypeSwal.fire();

                } else if (user.type === TypeUser.ATTENDEE) {
                    this.userRemoveId = user.uid;
                    this.userRemoveType = user.type;
                    this.userRemoveTypeStr = this.translateService.instant('global.type_user_attendee');
                    this.confirmChangeUserTypeSwal.fire();

                }
            }
        })
    }

    confirmChangeUserType() {
        if (this.userRemoveType == TypeUser.SUPERGOD || this.userRemoveType == TypeUser.GOD
            || this.userRemoveType == TypeUser.CLIENT || this.userRemoveType == TypeUser.EMPLOYEE) {

            this.auth.removeUserAuth(this.userRemoveId, (data) => {
                if (data.result === true) {
                    this.editEmployee();
                }
            });

        } else if (this.userRemoveType == TypeUser.ATTENDEE) {

            this.dbAttendee.removeAttendeeAllEvents(this.userRemoveId, (data) => {
                if (data == true) {
                    this.editEmployee();
                }
            })

        } else if (this.userRemoveType == TypeUser.SPEAKER) {

            this.dbSpeaker.removeSpeakerAllEvents(this.userRemoveId, (data) => {
                if (data == true) {
                    this.editEmployee();
                }
            })
        }

    }



    editEmployee() {
        let data = this.formValidation.value;
        this.loader = true;
        data.email = data.email.toLowerCase();
        let user = new User(data.name, data.email);
        user.$language = data.language;
        user.$company = data.company;
        user.$title = data.title;
        user.$description = typeof data.$description !== 'undefined' && data.$description !== null ? data.description.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        user.$uid = this.employeeId;
        user.$clientId = this.global.userId;

        if (this.finalFile !== null) {
            this.storage.profilePicture(this.finalFile, this.employeeId, async (url) => {
                user.$photoUrl = await url;
                this.saveEmployee(user);
            });
        } else {
            user.$photoUrl = this.photoBase64;
            this.saveEmployee(user);
        }

    }

    saveEmployee(user) {
        this.dbClientEmployee.editEmployee(user, this.employeeId, (status) => {
            if (status['code'] == 200) {
                this.successSwal.fire();
                this.loader = false;
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    /**
     * On upload image file
     * @param $event 
     */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }

    redirectList() {
        this.router.navigate([`/dash/employees/${this.global.userId}`]);
    }

}
