import { Component, OnInit, ViewChild } from '@angular/core';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { Container } from 'src/app/models/container';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-container-list',
  templateUrl: './container-list.component.html',
  styleUrls: ['./container-list.component.scss']
})
export class ContainerListComponent implements OnInit {
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  loader: boolean = true;
  term: string = '';
  p: number = 1;
  containers: Array<Container> = [];
  containerDelete: Container = null;
  constructor(
    private dbAdmin: DbAdminUserProvider
  ) { }

  ngOnInit() {
    this.dbAdmin.getContainers((containers: Array<Container>) => {
      this.containers = containers;
      this.loader = false;
    })
  }

  getDelete(container: Container) {
    this.containerDelete = container;
  }

  deleteContainer() {
    this.loader = true;
    this.dbAdmin.deleteContainer(this.containerDelete, (status) => {
      if (status) {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }
}
