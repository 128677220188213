import { isIdentifier } from "@angular/compiler";

/**
 * Represents a track from schedule module, it's possible insert in session
 * @attribute uid
 * @attribute name
 * @attribute bgColor
 * @attribute txtColor
 * @export
 * @class Track
 */

export class Track {
    public uid: string;
    private identifier: string;
    private name: any;
    private bgColor: string;
    private txtColor: string;
    private queryName: any;

    constructor(
        identifier: string,
        name: any,
        bgColor: string,
        txtColor: string,
        date?: string,
        startTime?: string,
        endTime?: string,
        location?: any
    ) {
        this.uid = null;
        this.identifier = identifier;
        this.name = name;
        this.bgColor = bgColor;
        this.txtColor = txtColor;
        this.queryName = {
            PtBR: '',
            EnUS: '',
            EsES: '',
            FrFR: '',
            DeDE: ''
        };
        for (let lang in this.name) {
            this.queryName[lang] = this.name[lang].toUpperCase();
        }
    }

    /**
  * Getter $uid
  * @return {string}
  */
    public get $uid(): string {
        return this.uid;
    }

    /**
  * Setter $uid
  * @param {string} value
  */
    public set $uid(value: string) {
        this.uid = value;
    }


    /**
     * Getter $identifier
     * @return {string}
     */
    public get $identifier(): string {
        return this.identifier;
    }

    /**
     * Setter $identifier
     * @param {string} value
     */
    public set $identifier(value: string) {
        this.identifier = value;
    }


    /**
* Getter $name
* @return {any}
*/
    public get $name(): any {
        return this.name;
    }

    /**
    * Setter $name
    * @param {any} value
    */
    public set $name(value: any) {
        this.name = value;
    }

    /**
* Getter $bgColor
* @return {string}
*/
    public get $bgColor(): string {
        return this.bgColor;
    }

    /**
    * Setter $bgColor
    * @param {string} value
    */
    public set $bgColor(value: string) {
        this.bgColor = value;
    }

    /**
* Getter $txtColor
* @return {string}
*/
    public get $txtColor(): string {
        return this.txtColor;
    }

    /**
    * Setter $txtColor
    * @param {string} value
    */
    public set $txtColor(value: string) {
        this.txtColor = value;
    }

    /**
* Getter $queryName
* @return {any}
*/
    public get $queryName(): any {
        return this.queryName;
    }

    /**
    * Setter $queryName
    * @param {any} value
    */
    public set $queryName(value: any) {
        this.queryName = value;
    }

    public get $date(): any {
        return this.queryName;
    }
    
    public set $date(value: any) {
        this.queryName = value;
    }

    public get $startTime(): any {
        return this.queryName;
    }
    
    public set $startTime(value: any) {
        this.queryName = value;
    }

    public get $endTime(): any {
        return this.queryName;
    }
    
    public set $endTime(value: any) {
        this.queryName = value;
    }

    public get $location(): any {
        return this.queryName;
    }
    
    public set $location(value: any) {
        this.queryName = value;
    }
}