import { Injectable } from '@angular/core';
import { 
    programacao,
    convidado_programacao,
    convidados,
    track,
    track_programacao
} from "src/app/providers/integrations/ccm/ccm-model"
import { DbAttendeesProvider } from '../../database/db-attendees';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { Attendee } from 'src/app/models/attendees';
import { DbSpeakersProvider } from '../../database/db-speakers';
import { Speaker } from 'src/app/models/speakers';
import { DBCustomFieldsProvider } from "../../database/db-custom-fields.service";
import { eventCustomField } from '../../../models/eventCustomField';
import { NameModule } from '../../../models/name-module';
import { DbScheduleProvider } from '../../database/db-schedule';
import { Track } from 'src/app/models/track';
import { Session } from 'src/app/models/session';
import { DateTimeService } from '../../date-time/date-time.service';
import { NameSession } from 'src/app/models/name-session';
import { DbLocationsProvider } from '../../database/db-locations';
import { Location } from '../../../models/location';
import { TypeUser } from 'src/app/enums/typeUser';

@Injectable({
    providedIn: 'root'
})
export class CCMIntegrationService {
    private eventData: any = null;
    private sessionSpeakers = {};

    constructor(
        private SAttendee: DbAttendeesProvider,
        private SAFirestore: AngularFirestore,
        private SSpeaker: DbSpeakersProvider,
        private SCustomField: DBCustomFieldsProvider,
        private SSchedule: DbScheduleProvider,
        private SDateTime: DateTimeService,
        private SLocations: DbLocationsProvider
    ) {}

    private async fetchData(key: string) {
        /**
         * JSON.parse: bad control character in string literal at line 1 column
         * 279551 of the JSON data.
         * 
         * https://stackoverflow.com/questions/17487058/how-to-solve-json-parse-bad-control-character-in-string-literal-in-this-code
         */
        const result = await fetch(
            `https://ccmcongresses.com.br/api.php?chave=${key}`
        );
        if (!result) return Promise.reject();
        
        const blob = await result.blob();
        if (!blob) return Promise.reject();

        const str = await blob.text()
        if (!str) return Promise.reject();
        
        this.eventData = JSON.parse(
            str.replace(/[\u0000-\u001F\u007F-\u009F]/g, ""
        ));
        this.eventData = this.eventData[0];
        return Promise.resolve(this.eventData);
    }

    private async getModuleIds(eventId: string) {
        const result = (await this.SAFirestore.firestore
            .collection(`events/${eventId}/modules`).get());
        
        if (!result) { return Promise.reject(); }
        
        const moduleIds: any = {
            users: {}
        };
        result.docs.forEach((doc) => {
            const data = doc.data();
            if (data.type == 0) {
                moduleIds["schedule"] = data.uid;
            } else if (data.type == 1 || data.type == 2) {  // 1: speakers; 2: attendees
                moduleIds["users"][data.name.EnUS] = data.uid;
            } else if (data.type == 29) {
                moduleIds["location"] = data.uid;
            }
        });
        return Promise.resolve(moduleIds);
    }

    /**
     * 
     * @param eventId 
     * @param moduleId attendee or speaker module id
     * @returns 
     */
    private getCFs(eventId: string, moduleId: string) {
        return new Promise<DocumentData[]>((resolve, reject) => {
            this.SAFirestore.firestore.collection(
                `events/${eventId}/modules/${moduleId}/customFields`
            ).get()
            .then((result) => { resolve(result.docs.map(doc => doc.data())) })
            .catch(error => reject(error))
        });
    }

    private createCF(name, visibility, editAccess, order: number) {
        const cf = new eventCustomField(
            new NameModule(name, name, name, name, name),
            "text",
            visibility,
            editAccess
        );
        cf.order = order;
        return cf;
    }

    /**
     * 
     */
    private async getGuests(eventId: string) {
        const refAtt = this.SAFirestore.firestore
            .collection(`events/${eventId}/attendees`);
        const refSpk = this.SAFirestore.firestore
            .collection(`events/${eventId}/speakers`);
        
        const result = await Promise.all([refAtt.get(), refSpk.get()]);
        if (!result) { return Promise.reject(); }

        const guests = {};
        [...result[0].docs, ...result[1].docs]
            .forEach(guest => { guests[guest.id] = true; });
        
        return Promise.resolve(guests);
    }

    /**
     * 
     * @param eventId 
     * @param userModuleIds e.g. { `Attendee`: <moduleId>, `Speakers`: <moduleId> }
     * @returns 
     */
    private async createUser(eventId: string, userModuleIds) {
        if (!this.eventData || !this.eventData.convidados) { 
            return Promise.reject(); 
        }

        const guests = await this.getGuests(eventId);
        if (!guests) { return Promise.reject(); }

        const cfList = (await this.getCFs(
            eventId, 
            userModuleIds[`Attendees`]
        ));
        if (!cfList) { return Promise.reject(); }

        const cfs: any[] = [];
        let cfOrder = 0;
        if (!cfList.some(cf => cf.name.PtBR == "Municipio")) {
            cfs.push(this.createCF("Municipio", '1', '2', cfOrder++))
        }
        if (!cfList.some(cf => cf.name.PtBR == "Uf")) {
            cfs.push(this.createCF("Uf", '1', '2', cfOrder++))
        }

        const cfPromises: Promise<any>[] = [];
        Object.keys(userModuleIds).forEach((module) => {
            cfs.forEach((cf) => {
                cfPromises.push(this.SCustomField.createCustomField(
                    eventId, 
                    userModuleIds[module], 
                    cf
                ));
            });
        });
        const res = await Promise.all(cfPromises);
        if (!res) { Promise.reject(); }

        const userPromises: Promise<any>[] = [];
        this.eventData.convidados.forEach((guest: convidados) => {
            const id = guest.IdConvidado;

            let speakerSchSess: convidado_programacao[];
            if (this.eventData.convidado_programacao) {
                speakerSchSess = this.eventData
                    .convidado_programacao
                    .filter((e: convidado_programacao) => {
                        return (
                            e.IdConvidado == id &&
                            (   
                                e.Funcao == "Palestrante" ||
                                e.FuncaoEN == "Speaker" ||
                                e.FuncaoES == "Orador"
                            )
                        )
                    });
            }

            cfs.forEach(cf => cf.answer = guest[cf.name.PtBR]);
            if (speakerSchSess.length > 0) {
                if (!guest.Email) { return; }
                const speaker = new Speaker(guest.Nome, 4, eventId);
                speaker.$email = guest.Email.trim();
                speaker.$description = Object.assign({}, new NameModule(
                    guest.Curriculo,
                    guest.CurriculoEN,
                    guest.CurriculoES,
                    guest.CurriculoEN,
                    guest.CurriculoEN
                ));
                speaker.$uid = guest.IdConvidado
                speaker.$identifier = guest.IdInscricao;
                
                speakerSchSess.forEach((sess) => {
                    if (!this.sessionSpeakers[sess.IdProgramacao]) {
                        this.sessionSpeakers[sess.IdProgramacao] = {}; 
                    }
                    Object.assign(
                        this.sessionSpeakers[sess.IdProgramacao], 
                        { [speaker.$uid]: Object.assign({}, speaker) }
                    )
                })
                if (guests[id]) { return; }

                userPromises.push(this.SSpeaker.createSpeaker(
                    eventId,
                    userModuleIds['Speakers'],
                    speaker,
                    JSON.parse(JSON.stringify(cfs)),
                    guest.Email,
                    null
                ));
            } else {
                if (!guest.Email || guests[id]) { return; }
                const attendee = new Attendee(guest.Nome, guest.Email.trim(), 5, eventId);
                attendee.$description = Object.assign({}, new NameModule(
                    guest.Curriculo,
                    guest.CurriculoEN,
                    guest.CurriculoES,
                    guest.CurriculoEN,
                    guest.CurriculoEN
                ));
                attendee.$uid = guest.IdConvidado
                attendee.$identifier = guest.IdConvidado;
                userPromises.push(this.SAttendee.createAttendee(
                    eventId,
                    userModuleIds['Attendees'],
                    attendee,
                    JSON.parse(JSON.stringify(cfs)),
                    guest.Email,
                    null
                ));
            }
        });
        return Promise.all(userPromises);
    }

    private getLocations(eventId: string) {
        return new Promise<DocumentData>((res, rej) => {
            this.SAFirestore.firestore
            .collection(`events/${eventId}/locations`)
            .get()
            .then((result) => {
                res(result.docs.map(doc => doc.data()))
            }).catch(error => rej(error))
        })
    }

    /**
     * 
     * @param eventId 
     * @param moduleId Localization module id 
     */
    private async createLocations(eventId: string, moduleId: string) {
        if (!this.eventData || !this.eventData.programacao) {
            return Promise.reject();
        }

        const result = await this.getLocations(eventId);
        if (!result) { return Promise.reject(); }

        const locations = {}
        result.forEach(location => {
            locations[location.name] = location;
        });

        const promises: Promise<any>[] = [];
        this.eventData.programacao.forEach((p: programacao) => {
            if (p.Sala && !locations[p.Sala]) {
                const l = new Location();
                l.eventId = eventId;
                l.moduleId = moduleId;
                l.name = p.Sala.trim();
                l.identifier = l.name;

                promises.push(this.SLocations.newLocation(l, null))
                locations[p.Sala] = true;
            }
        });
        return new Promise<Object>((res, rej) => {
            Promise.all(promises).then((result) => {
                result.forEach(location => {
                    locations[location.name] = location;
                });
                res(locations);
            }).catch(error => rej(error));
        })
    }

    private getTracks(eventId: string, moduleId: string) {
        return new Promise<DocumentData>((res, rej) => {
            this.SAFirestore.firestore.collection(
                `events/${eventId}/modules/${moduleId}/tracks`
            ).get()
            .then((result) => { res(result.docs.map(doc => doc.data())) })
            .catch(error => rej(error))
        })
    }

    /**
     * 
     * @param eventId 
     * @param moduleId  schedule module id 
     */
    private async createTracks(eventId: string, moduleId: string) {
        if (!this.eventData || !this.eventData.track) { 
            return Promise.reject(); 
        }

        const tracks = await this.getTracks(eventId, moduleId);
        if (!tracks) { return Promise.reject(); }

        const tmp = {};
        tracks.forEach(track => { tmp[track.uid] = track; })

        const promises: Promise<DocumentData>[] = [];
        this.eventData.track.forEach((track: track) => {
            if (!tmp[track.IdTrack]) {
                const t = new Track(
                    // track.IdTrack,
                    track.Titulo,
                    new NameModule(
                        track.Titulo, 
                        track.TituloEN, 
                        track.TituloES, 
                        track.TituloEN, 
                        track.TituloEN
                    ),
                    "#454545",
                    "#ffffff"
                )
                t.uid = track.IdTrack;
                promises.push(this.SSchedule.createNewTrack(eventId, moduleId, t));
            }
        });
        return new Promise<DocumentData[]>((res, rej) => {
            Promise.all(promises).then((result) => {
                res([...Object.values(tmp), ...result]);
            }).catch(error => rej(error));
        });
    }

    private getSessTracks(tracks: any[]) {
        if (!this.eventData || !this.eventData.track_programacao) {
            return Promise.reject();
        }
        const obj = {};
        this.eventData.track_programacao.forEach((tp: track_programacao) => {
            if (!obj[tp.IdProgramacao]) {
                obj[tp.IdProgramacao] = {};
            }
            const r = tracks.find(t => t.uid == tp.IdTrack);
            Object.assign(obj[tp.IdProgramacao], { [r.uid]: r });
        });
        return obj;
    }

    /**
     * 
     * @param eventId 
     * @returns 
     */
    private getSchSessions(eventId: string) {
        return new Promise<any>((resolve, reject) => {
            this.SAFirestore.firestore
            .collection(`events/${eventId}/sessions`)
            .get()
            .then((result) => {
                const sessions = {};
                result.docs.forEach(doc => { sessions[doc.id] = true })
                resolve(sessions);
            }).catch(error => reject(error));
        });
    }

    private sessName(type: string, name: string): string {
        if (type.trim().length > 0) {
            if (name.trim().length > 0) {
                return `${type}: ${name}`;
            }
            return type;
        }
        return name;
    }

    private getSessContent(
        sessId: string, 
        eventId: string, 
        attModId: string,
        spkModId: string
    ): string {
        const tmp = {};
        this.eventData.convidado_programacao
            .forEach((cp: convidado_programacao) => {
                if (sessId != cp.IdProgramacao) { return; }
                if (!tmp[cp.Tema.trim()]) {
                    tmp[cp.Tema.trim()] = [];
                }
                tmp[cp.Tema.trim()].push(cp);
            })
        const topics = [];
        for (let key in tmp) {
            tmp[key].sort((a, b) => a.Ordem - b.Ordem);

            let html;
            let order;
            if (key == "") {
                html = "";
                order = 0;
            } else {
                order =  tmp[key][0].Ordem;
                html = `<strong>${key}</strong>`;
            }

            for (let i = 0; i < tmp[key].length; i++) {
                const gtype = (   
                    tmp[key][i].Funcao == "Palestrante" ||
                    tmp[key][i].FuncaoEN == "Speaker" ||
                    tmp[key][i].FuncaoES == "Orador"
                ) ? TypeUser.SPEAKER : TypeUser.ATTENDEE;
                const modId = (gtype == TypeUser.SPEAKER) ? spkModId : attModId;
                const guest = this.eventData.convidados.find(guest => 
                    guest.IdConvidado == tmp[key][i].IdConvidado
                );

                if (tmp[key][i].Funcao != "" && guest) {
                    html += `<div>${tmp[key][i].Funcao}:` + 
                        `<a href="/#/event/${eventId}/profile/${modId}/${gtype}/${tmp[key][i].IdConvidado}">` +
                        `${guest.Nome}</a>`;
            
                    if (tmp[key][i].Uf != "") {
                        html += ` (${guest.Uf})`;
                    }
                    html += `</div>`;
                }
            }
            topics.push([order, html]);
        }
        topics.sort((a, b) => a[0] - b[0]);
        return topics.reduce((acc, val) => {
            return acc + val[1];
        }, "");
    }

    /**
     * 
     * @param eventId 
     * @param moduleId  schedule module id 
     */
    private async createSchSessions(
        eventId: string, 
        schModId: string,
        attModId: string,
        spkModId: string,
        locations: Object,
        sessTracks: Object,
        timezone: string = "America/Sao_Paulo"  // (UTC-03:00)
    ) {
        if (!this.eventData || !this.eventData.programacao) {
            return Promise.reject();
        }

        const sessions = await this.getSchSessions(eventId);
        if (!sessions) { return Promise.reject(); }

        const pms = this.eventData.programacao.reduce((acc, p: programacao) => {
            if (sessions[p.IdProgramacao]) { return acc; }

            const session = new Session();
            session.eventId = eventId;
            session.moduleId = schModId;
            session.uid = p.IdProgramacao;

            const auxDate = p.DataAtividade.split('-');
            const auxSTime = p.HoraInicio.split(':');
            session.date = this.SDateTime.createDateInTZ(
                auxDate[0], 
                auxDate[1], 
                auxDate[2],
                auxSTime[0], 
                auxSTime[1],
                timezone
            ).toISO();
            session.startTime = session.date;

            if (p.HoraTermino) {
                const auxETime = p.HoraTermino.split(":");
                session.endTime = this.SDateTime.createDateInTZ(
                    auxDate[0], 
                    auxDate[1], 
                    auxDate[2],
                    auxETime[0], 
                    auxETime[1],
                    timezone
                ).toISO();
            }

            session.name = new NameSession(
                this.sessName(p.TituloTipo, p.Titulo),
                this.sessName(p.TituloTipoEN, p.TituloEN),
                this.sessName(p.TituloTipoES, p.TituloES),
                this.sessName(p.TituloTipoEN, p.TituloEN),
                this.sessName(p.TituloTipoEN, p.TituloEN),
            );
            
            if (locations[p.Sala]) {
                session.locations[locations[p.Sala].uid] = locations[p.Sala];
            }

            if (sessTracks[p.IdProgramacao]) {
                session.tracks = sessTracks[p.IdProgramacao];
            }
            
            session.videoPlayer = null;

            if (this.sessionSpeakers[p.IdProgramacao]) {
                session.speakers = this.sessionSpeakers[p.IdProgramacao];
            }

            const description = this.getSessContent(
                p.IdProgramacao, 
                eventId, 
                attModId,
                spkModId
            );
            session.descriptions = {
                DeDE: description,
                EnUS: description,
                EsES: description,
                FrFR: description,
                PtBR: description
            }

            acc.push(this.SSchedule.createSession(session));
            return acc;
        }, []);
        return Promise.all(pms);
    }

    /**
     * build/make integration
     * @param eventId an event id from our platform to integrate the retrieved 
     * data
     * @param key api access key
     * @returns 
     */
    public async build(eventId: string, key: string) {
        const eventData = await this.fetchData(key);
        if (!eventData) { return Promise.reject(`error retrieving event data`); }

        const moduleIds = await this.getModuleIds(eventId);
        if (
            !moduleIds || 
            !moduleIds.location || 
            !moduleIds.schedule
        ) { return Promise.reject(`error retrieving module ids`); }

        const rstCrUser = await this.createUser(eventId, moduleIds.users);
        if (!rstCrUser) { return Promise.reject(`error in the creation of users`); }

        const locations = await this.createLocations(eventId, moduleIds.location);
        if (!locations) { return Promise.reject(`error at location creation`); }

        const tracks = await this.createTracks(eventId, moduleIds.schedule);
        if (!tracks) { return Promise.reject(`error at track creation`); }

        const sessTracks = this.getSessTracks(tracks);

        const rstCrSess = (await this.createSchSessions(
            eventId, 
            moduleIds.schedule, 
            moduleIds.users['Attendees'],
            moduleIds.users['Speakers'],
            locations, 
            sessTracks
        ));
        if (!rstCrSess) { return Promise.reject(`error at schedule sessions creation`); }
        
        return Promise.resolve();
    }
}
