import { Module } from "./module";
import { Group } from "./../group";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";


export class ModuleGroup extends Module {
    groups: Array<Group>;
    orderGroups: string;
    visOnlyForLoggedUser?: boolean;


    constructor(name: NameModule, icon: string, eventId: string, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.MANAGER_GROUP, eventId, null, false, null, true)
        this.groups = [];
        this.orderGroups = 'asc';
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }

}