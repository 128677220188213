import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleStand extends Module {
    typeVision: number;
    selected: boolean;
    sectors: string[] = ["sector 1"];
    sortBy: string = "asc";

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number) {
        super(name, icon, TypeModule.STAND, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.selected = true;
    }
}
