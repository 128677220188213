import { DestinationType } from "../enums/type-destination-email";

export class Email {
    uid: string;
    title: string;
    object: string;
    eventId: string;
    isTest: boolean = false;
    receivers: string;// emails separted by (,) ex: (user1@mail.com, user2@mail.fr ) or just a one email adress
    destinationType: DestinationType;
    groups: string[] = [];
    createdAt: number;
    status: number;// 0: draft, 1: sended, 2: scheduled
    sender: string = '';
    design?: string;
    deliveredAt: number = 0; // not send yet !!!
    mailGunId?: string = '';
    openCounter: number = 0;

    constructor(title: string, object: string, eventId: string, destinationType?: DestinationType){
        this.title = title;
        this.object = object;
        this.eventId = eventId;
        this.destinationType = destinationType;
        this.receivers = '';
        this.isTest = false;
    }
}