import { Injectable } from '@angular/core';
import { TypeModule } from '../../enums/type-module'

/**
 *
  Service created with the task of relating the urls of the modules to backoffice and app.
 *
 */

@Injectable({
    providedIn: 'root'
})
export class PlataformRoutersService {

    constructor() { }

    relatesUrls(typeModule: number, moduleId: string, pageId: string, eventId: string) {
        const url = {
            backoffice: '',
            app: ''
        }

        switch (typeModule) {
            case TypeModule.PRINCIPAL:
                url.backoffice = null;
                url.app = `/event/${eventId}/principal/${moduleId}`; // nova versão do modulo widget
                break;
            case TypeModule.SCHEDULE:
                url.backoffice = `/event/${eventId}/schedule/${moduleId}`;
                url.app = `/event/${eventId}/schedule/${moduleId}`;
                break;

            case TypeModule.ATTENDEE:
                url.backoffice = `/event/${eventId}/attendees/${moduleId}`;
                url.app = `/event/${eventId}/attendees/${moduleId}`;
                break;

            case TypeModule.LOCATION:
                url.backoffice = `/event/${eventId}/locations/${moduleId}`;
                break;

            case TypeModule.WIDGETS:
                url.backoffice = `/event/${eventId}/widgets/${moduleId}`;
                url.app = `/event/${eventId}/widget/${moduleId}`;
                break;

            case TypeModule.SPEAKER:
                url.backoffice = `/event/${eventId}/speakers/${moduleId}`;
                url.app = `/event/${eventId}/speakers/${moduleId}`;
                break;

            case TypeModule.SPONSOR:
                url.backoffice = `/event/${eventId}/sponsors/${moduleId}`;
                url.app = `/event/${eventId}/sponsors/${moduleId}`;
                break;

            case TypeModule.STAND:
                url.backoffice = `/event/${eventId}/stands/${moduleId}`;
                url.app = `/event/${eventId}/stands/${moduleId}`;
                break;

            case TypeModule.PERSONALSCHEDULE:
                url.backoffice = `/event/${eventId}/personal-schedule/${moduleId}`;
                url.app = `/event/${eventId}/personal-schedule/${moduleId}`;
                break;

            case TypeModule.MANAGER_GROUP:
                url.backoffice = `/event/${eventId}/groups/${moduleId}`;
                break;

            case TypeModule.MANAGER_MODULES:
                url.backoffice = `/event/${eventId}/manage-modules/${moduleId}`;
                break;

            case TypeModule.DOCUMENT:
                url.backoffice = `/event/${eventId}/documents/${moduleId}`;
                url.app = `/event/${eventId}/documents/${moduleId}`;
                break;

            case TypeModule.GALLERY:
                url.backoffice = `/event/${eventId}/gallery/${moduleId}`;
                url.app = `/event/${eventId}/gallery/${moduleId}`;
                break;

            case TypeModule.CHECKIN:
                url.backoffice = `/event/${eventId}/checkin/${moduleId}`;
                url.app = `/event/${eventId}/checkin/${moduleId}`;
                break;

            case TypeModule.EVENT:
                url.backoffice = null;
                url.app = `/event/${eventId}/info`;
                break;

            case TypeModule.NOTIFY:
                url.backoffice = `/event/${eventId}/notifications/${moduleId}`;
                url.app = null;
                break;

            case TypeModule.INTERACTIVITY:
                url.backoffice = `/event/${eventId}/interactivity`;
                url.app = `/event/${eventId}/interactivity`;
                break;

            case TypeModule.SURVEY:
                url.backoffice = `/event/${eventId}/interactivity/survey/${moduleId}`;
                url.app = `/event/${eventId}/surveys-list/${moduleId}`;
                break;

            case TypeModule.QUIZ:
                url.backoffice = `/event/${eventId}/interactivity/quiz/${moduleId}`;
                url.app = `/event/${eventId}/quiz-list/${moduleId}`;
                break;

            case TypeModule.PRIZE_DRAW:
                url.backoffice = `/event/${eventId}/interactivity/prize-draw/${moduleId}`;
                url.app = '';
                break;

            case TypeModule.TRAINING:
                url.backoffice = `/event/${eventId}/interactivity/training/${moduleId}`;
                url.app = `/event/${eventId}/training-list/${moduleId}`;
                break;

            case TypeModule.SESSION_FEEDBACK:
                url.backoffice = `/event/${eventId}/interactivity/session-feedback/${moduleId}`;
                url.app = "";
                break;

            case TypeModule.ASK_QUESTION:
                url.backoffice = `/event/${eventId}/interactivity/ask-question/${moduleId}`;
                url.app = `/event/${eventId}/ask-question-list/${moduleId}`;
                break;

            case TypeModule.WORDCLOUD:
                url.backoffice = `/event/${eventId}/interactivity/word-cloud/${moduleId}`;
                url.app = `/event/${eventId}/word-cloud-list/${moduleId}`;
                break;

            case TypeModule.NEWS_FEED:
                url.backoffice = `/event/${eventId}/feed-news/${moduleId}`;
                url.app = `/event/${eventId}/feed-news/${moduleId}`;
                break;
            case TypeModule.MAPS:
                url.backoffice = `/event/${eventId}/maps/${moduleId}`;
                url.app = `/event/${eventId}/maps/${moduleId}`;
                break;
            case TypeModule.RANKING:
                url.backoffice = `/event/${eventId}/ranking/${moduleId}`;
                url.app = `/event/${eventId}/ranking/${moduleId}`;
                break;
            case TypeModule.SELF_CHECKIN:
                url.backoffice = null;
                url.app = `/event/${eventId}/self-checkin/${moduleId}`;
                break;
            case TypeModule.INFOBOOTH:
                url.backoffice = `/event/${eventId}/page-booth/${moduleId}`;
                url.app = `/event/${eventId}/page-booth/${moduleId}/pageId/${pageId}`;
                break;

            case TypeModule.CUSTOM_PAGE:
                url.backoffice = `/event/${eventId}/custom-pages/${moduleId}`;
                url.app = `/event/${eventId}/custom-pages/${moduleId}`;
                break;

            case TypeModule.GAMING:
                url.backoffice = `/event/${eventId}/gaming/${moduleId}`;
                url.app = `/event/${eventId}/gaming/${moduleId}`;
                break;

            case TypeModule.NETWORKING:
                url.backoffice = `/event/${eventId}/group-discussions/${moduleId}`;
                url.app = `/event/${eventId}/list-group-discussions/${moduleId}`;
                break;
            case TypeModule.APPOINTMENTS:
                url.backoffice = `/event/${eventId}/appointments/${moduleId}`;
                url.app = `/event/${eventId}/appointments/${moduleId}`;
                break;
            case TypeModule.ROOMS:
                url.backoffice = `/event/${eventId}/rooms/${moduleId}`;
                url.app = `/event/${eventId}/rooms/${moduleId}`;
            break;
        }

        return url
    }

}
