import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class DbAdminTermsProvider {
    public headers;
    public requestOptions;

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getPrivacyTerms(onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('general-legal-terms') // general terms collection
            .doc('terms')
            .get()
            .then((terms) => {
                onResolve(terms.data().privacy);
            }).catch((error) => {
                onResolve(error);
            });
    }

    getTermsOfUse(onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('general-legal-terms') // general terms collection
            .doc('terms')
            .get()
            .then((terms) => {
                onResolve(terms.data().termOfUse);
            }).catch((error) => {
                onResolve(error);
            });
    }

    savePrivacyTerms(privacy: any, onResolve): void {
        let db = this.aFirestore.firestore;
        db
            .collection('general-legal-terms') // general terms collection
            .doc('terms')
            .update({
                privacy: privacy
            }).then((status) => {
                onResolve({
                    code: 200,
                    result: status
                });
            })
            .catch((err) => {
                onResolve({
                    code: 404,
                    message: err,
                    result: status
                });
            })
    }

    saveTermsOfUse(term: any, onResolve): void {
        let db = this.aFirestore.firestore;
        db
            .collection('general-legal-terms') // general terms collection
            .doc('terms')
            .update({
                termOfUse: term
            }).then((status) => {
                onResolve({
                    code: 200,
                    result: status
                });
            })
            .catch((err) => {
                onResolve({
                    code: 404,
                    status: err,
                    result: status
                });
            })
    }
}