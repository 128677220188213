/*
    class that represents the types of enabled and disabled modules of the event.
*/

export class AvailableModules {
    module_attendees: boolean;
    module_schedule: boolean;
    module_speaker: boolean;
    module_widget: boolean;
    module_survey: boolean;
    module_quiz: boolean;
    module_training: boolean;
    module_session_feedback: boolean;
    module_ask_question: boolean;
    module_word_cloud: boolean;
    module_checkin: boolean;
    module_document: boolean;
    module_gallery: boolean;
    module_feed_news: boolean;
    module_maps: boolean;
    module_ranking: boolean;
    module_infobooth: boolean;
    module_custom_page: boolean;
    module_external_link: boolean;
    module_gamification: boolean;
    module_group_discussion: boolean;
    module_appointments: boolean;
    module_prize_draw: boolean;
    module_sponsor: boolean;
    module_stand: boolean;
    module_rooms: boolean;

    constructor() {
        this.module_external_link = true;
        this.module_attendees = false;
        this.module_schedule = false;
        this.module_speaker = false;
        this.module_widget = false;
        this.module_survey = false;
        this.module_quiz = false;
        this.module_training = false;
        this.module_session_feedback = false;
        this.module_ask_question = false;
        this.module_word_cloud = false;
        this.module_document = false;
        this.module_gallery = false;
        this.module_feed_news = false;
        this.module_maps = false;
        this.module_ranking = false;
        this.module_checkin = false;
        this.module_infobooth = false
        this.module_custom_page = false
        this.module_gamification = false;
        this.module_group_discussion = false;
        this.module_appointments = false;
        this.module_prize_draw = true;
        this.module_sponsor = false;
        this.module_stand = false;
        this.module_rooms = false;
    }
}
