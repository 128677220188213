import { NameModule } from "./name-module";

export class DocumentsFolder {
    uid: string;
    name: NameModule;
    orderDocuments: string;
    createdAt: number;
    order: number;
    typeVision: number;
    groups: any;
    eventId: string;
    
    constructor(name: NameModule, order: number, typeVision) {
        this.uid = null;
        this.name = name;
        this.order = order;
        this.orderDocuments = 'recent';
        this.createdAt = null;
        this.typeVision = typeVision;
        this.groups = {};
        this.eventId = null;
    }
}