import { Component, OnInit } from '@angular/core';
import { getGalleriesAnalytics } from 'src/app/shared/selectors/analytics.selectors';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { AppState } from 'src/app/shared/reducers';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-gallery-analytics',
    templateUrl: './gallery-analytics.component.html',
    styleUrls: ['./gallery-analytics.component.scss']
})
export class GalleryAnalyticsComponent implements OnInit {
    loader: boolean = true;

    event: any;
    eventId: string;
    userLanguage: string;

    bestImages: any[] = [];
    totalImages: number = 0;

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics.getGalleriesAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getGalleriesAnalytics).subscribe((galleriesAnalytics) => {
                    this.bestImages = (galleriesAnalytics && galleriesAnalytics.bestImages) ? galleriesAnalytics.bestImages : [];
                    this.totalImages = (galleriesAnalytics && galleriesAnalytics.totalImages) ? galleriesAnalytics.totalImages : 0;

                    this.loader = false;
                })
            });
        });
    }

    /**
     * Export galleries analytics
     */
    exportAll() {
        this.loader = true;
        this.dbAnalytics.exportGalleriesAnalytics(this.eventId).subscribe((result) => {
            this.loader = false;
        }, (error) => {
            this.loader = false;
        })
    }
}
