import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/models/question';
import { Answer } from 'src/app/models/answer';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Track } from 'src/app/models/track';
import { Session } from 'src/app/models/session';
import { Group } from 'src/app/models/group';
import { TypeSurvey } from 'src/app/models/type-survey';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbSurveyProvider } from 'src/app/providers/database/db-survey';
import { Survey } from 'src/app/models/survey';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { GetNameTrack } from 'src/app/pipes/get-name-track';
import { NameModule } from 'src/app/models/name-module';
import { Languages } from 'src/app/models/languages';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';


declare let $: any;

@Component({
    selector: 'app-edit-survey',
    templateUrl: './edit-survey.component.html',
    styleUrls: ['./edit-survey.component.scss']
})

@NgModule({
    declarations: [
        GetNameSession
    ],
})

export class EditSurveyComponent implements OnInit {
    // get the language of the user.
    public userLanguage: string
    // get the language of the event.
    public eventLanguage: string
    languages: Languages = null //event languages
    activeLanguage: string = null;

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorDeleteLastQuestion') public errorDeleteLastQuestion: SwalComponent;

    eventId: string = null;
    eventTimezone: string = null;
    moduleId: string = null;
    surveyId: string = null;

    public questions: Array<Question> = [];
    public answers: Array<Answer> = [];

    public listAuxInput: Array<any> = [];
    loaderQuestions: Array<boolean> = [];

    questionId: string = null;
    iconsImg: MenuIcons[] = icons;
    selectedIcons;
    selectedIcon: string = null;
    selectedIconFamily: string = null;
    formGroup: FormGroup;
    formEditQuestion: FormGroup;
    formNewQuestion: FormGroup;
    addQuestionShow: boolean = false;
    editQuestionShow: boolean = false;
    allowShowAddQuestion: boolean = true;

    editQuestionIndex: number = null;

    // blockGraphicTypevisibilityEdit = true;
    blockOptionsAnswersVisibilityEdit = true;
    blockQuestionPointsVisibilityEdit = true;
    // blockGraphicTypevisibilityCreate = true;
    blockOptionsAnswersVisibilityCreate = true;
    blockQuestionPointsVisibilityCreate = true;
    activeMarker: boolean = false;
    activeWeight: boolean = false;
    forceComplete: boolean = false;

    public errorTitleEdit = false;
    public errorSelectQuestionEdit = false;
    public errorOptionAnswerEdit = false;
    public errorTitleCreate = false;
    public errorSelectQuestionCreate = false;
    public errorOptionAnswerCreate = false;

    survey;
    surveyTitle;
    surveyIcon;

    surveyLinkedToSession: boolean = false;
    SelectedSessionVisibility: boolean = false;
    tracksAttendeeVisibility: boolean = false;
    specificTrackVisibility: boolean = false;
    scheduleModuleVisibility: boolean = false;
    scheduleModuleTrackVisibility: boolean = false;
    scheduleModuleSessionVisibility: boolean = false;
    specificGroupVisibility: boolean = false;

    // tracks
    tracks: Array<Track>;
    public group = []; // array de ids
    public query = ''; // string digitada pelo usuário
    public filteredList = []; // lista de sugestões exibidas
    public selectedTracks = []; // array com os tracks selecionados

    // sessions
    sessions: Array<Session>;
    sessionsNames: Array<any>;
    public session = [];
    public querySession = ''; // string digitada pelo usuário
    public filteredSession = []; // lista de sugestões exibidas
    public selectedSessions = []; // array com os tracks selecionados

    // groups
    groups: Array<Group>;
    groupsNames: Array<any>;
    public ManagerGroup = []; // array de ids
    public queryGroup = ''; // string digitada pelo usuário
    public filteredGroup = []; // lista de sugestões exibidas
    public selectedGroups = []; // array com os tracks selecionados

    scheduleModules: Array<any> = [];
    surveyType: string = TypeSurvey.Pattern;
    scheduleModuleSurveyEdit: string = null;
    scheduleModuleTrackSurveyEdit: string = null;
    scheduleModuleSessionSurveyEdit: string = null;


    listAnswers: Array<any> = [];

    listNewQuestion: Array<any> = [];

    listDeleteInputAnswers: Array<any> = [];

    // Show Survey for specific groups
    groupesVisibility: boolean = false;

    // Show Survey for specific groups
    showForMinPoints: boolean = false;

    minPoints: number;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbSession: DbScheduleProvider,
        private dbGroup: DbGroupsProvider,
        private dbSurvey: DbSurveyProvider,
        private fb: FormBuilder,
        private router: Router,
        private global: GlobalService,
        private formatIdioma: FormatedMultiIdiomaService
    ) {
        this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.surveyId = this.route.snapshot.params['surveyId'];
        this.load();

        this.formGroup = new FormGroup({
            survey_name_pt_br: new FormControl(''),
            survey_name_en_us: new FormControl(''),
            survey_name_es_es: new FormControl(''),
            survey_name_fr_fr: new FormControl(''),
            survey_name_de_de: new FormControl(''),
            changeAnswer: new FormControl(''),
            viewAnswered: new FormControl('')
        });

        /**
         * Formulario editQuestion
         */
        this.formEditQuestion = this.fb.group({
            'main_title_pt_br': [''],
            'main_title_en_us': [''],
            'main_title_es_es': [''],
            'main_title_fr_fr': [''],
            'main_title_de_de': [''],
            'title_pt_br': [''],
            'title_en_us': [''],
            'title_es_es': [''],
            'title_fr_fr': [''],
            'title_de_de': [''],
            'infobooth_pt_br': [''],
            'infobooth_en_us': [''],
            'infobooth_es_es': [''],
            'infobooth_fr_fr': [''],
            'infobooth_de_de': [''],
            'radioTypeQuestion': [null],
            'points': [null]
            // 'radioTypeGraphic': [null]
        });

        /**
         * Formulario create New Question
         */
        this.formNewQuestion = this.fb.group({
            'main_title_pt_br': [''],
            'main_title_en_us': [''],
            'main_title_es_es': [''],
            'main_title_fr_fr': [''],
            'main_title_de_de': [''],
            'title_pt_br': [''],
            'title_en_us': [''],
            'title_es_es': [''],
            'title_fr_fr': [''],
            'title_de_de': [''],
            'infobooth_pt_br': [''],
            'infobooth_en_us': [''],
            'infobooth_es_es': [''],
            'infobooth_fr_fr': [''],
            'infobooth_de_de': [''],
            'radioTypeQuestion': [null],
            'points': [null]
            // 'radioTypeGraphic': [null]
        });

        this.listNewQuestion.push(new Answer());

        // //Seta o icone inicial
        let initialIcon = {
            icon: PathIcons.icon_survey,
            family: 'material-icons'
        }
        this.setIcon(initialIcon);

        this.getSurveyOptions();

        this.dbSurvey.getQuestions(this.moduleId, this.surveyId, (list) => {
            this.questions = list;
            this.loaderQuestions = Array(this.questions.length).fill(false);
        })
    }

    ngOnInit() {
        $("label.icon-selector").click(function () {
            $("#dropdownMenuButton").dropdown("toggle");
        });

        this.getEvent();
        this.getUserLanguage()
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventTimezone = event.timezone;
            this.eventLanguage = event.language  // load event language
            this.languages = event.languages;
            this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
        })
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $('.dropdown-menu').removeClass('show');
    }

    getSurveyOptions() {
        this.dbSurvey.getSurvey(this.moduleId, this.surveyId, (survey) => {
            this.survey = survey;
            this.survey.weight !== undefined ? this.activeWeight = this.survey.weight : this.survey.weight = false;
            this.survey.marker !== undefined ? this.activeMarker = this.survey.marker : this.survey.marker = false;
            this.forceComplete = (this.survey.forceComplete) ? this.survey.forceComplete : false;

            if(this.survey.minPoints === undefined || this.survey.minPoints === null ){
                this.showForMinPoints = false
            } else{
                this.showForMinPoints = true;
                this.minPoints = survey.minPoints
            }
            this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
                this.groups = [];
                this.groups = response['groups'];
                if(this.survey.groups !== undefined) {
                    this.selectedGroups = Array.from(new Set([...this.groups].filter(i => this.survey.groups.includes(i.uid))))
                    this.groupesVisibility = this.selectedGroups.length > 0 ;
                } else {
                    this.groupesVisibility = false;
                }
            });
            this.setValuesSurvey();
        })
    }

    setValuesSurvey() {
        
        


        if (this.survey.type != TypeSurvey.Pattern) {
            this.surveyLinkedToSession = true;
            this.surveyType = this.survey.type;

            this.changeSessionType();

            switch (this.surveyType) {
                case TypeSurvey.ScheduleModule:
                    this.scheduleModuleSurveyEdit = this.survey.module_id;
                    break;

                case TypeSurvey.SessionTrack:
                    this.scheduleModuleTrackSurveyEdit = this.survey.module_id;
                    this.specificTrackVisibility = true;
                    this.loadTracks(this.scheduleModuleTrackSurveyEdit);

                    for (let trackId of this.survey.references) {
                        this.dbSession.getTrackModule(this.scheduleModuleTrackSurveyEdit, trackId, (track) => {
                            let notFound = true;
                            for (let aux of this.selectedTracks) {
                                if (aux.uid == track.uid) {
                                    notFound = false;
                                }
                            }

                            if (notFound == true) {
                                this.selectedTracks.push(track);
                            }

                        })
                    }

                    break;

                case TypeSurvey.SpecificGroup:
                    this.specificGroupVisibility = true;
                    this.loadGroups();

                    for (let groupId of this.survey.references) {
                        //marcador
                        this.dbGroup.searchModulesAndGroups(this.eventId, (data) => {
                            let groups = data['groups'];

                            let notFound = true;
                            for (let group of groups) {
                                if (group.uid == groupId) {

                                    for (let aux of this.selectedGroups) {
                                        if (aux.uid == group.uid) {
                                            notFound = false;
                                        }
                                    }

                                    if (notFound) {
                                        this.selectedGroups.push(group);
                                    }

                                    break;
                                }
                            }
                        })

                    }
                    break;

                case TypeSurvey.SpecificSession:
                    this.scheduleModuleSessionSurveyEdit = this.survey.module_id;
                    this.SelectedSessionVisibility = true;
                    this.loadSessions(this.scheduleModuleSessionSurveyEdit);

                    for (let sessionId of this.survey.references) {
                        this.dbSession.getSession(sessionId, this.eventId, (session: Session) => {

                            let notFound = true;
                            for (let aux of this.selectedSessions) {
                                if (aux.uid == session.uid) {
                                    notFound = false;
                                }
                            }

                            if (notFound == true) {
                                this.selectedSessions.push(session)
                            }
                        })
                    }

                    break;
            }
        }

        this.formGroup.patchValue({
            survey_name_pt_br: this.survey.title.PtBR,
            survey_name_en_us: this.survey.title.EnUS,
            survey_name_es_es: this.survey.title.EsES,
            survey_name_fr_fr: this.survey.title.FrFR,
            survey_name_de_de: this.survey.title.DeDE,
            changeAnswer: this.survey.change_answer,
            viewAnswered: this.survey.view_answered
        })
    }

    updateSurvey(data) {
        let survey = new Survey();
        survey.icon = this.selectedIcon;
        survey.iconFamily = this.selectedIconFamily;
        let aux = new NameModule(data.survey_name_pt_br, data.survey_name_en_us, data.survey_name_es_es, data.survey_name_fr_fr, data.survey_name_de_de);
        survey.title = this.formatIdioma.updateObjectMultiLanguages(this.survey.title, aux, this.eventLanguage);
        survey.change_answer = data.changeAnswer;
        survey.view_answered = data.viewAnswered;
        survey.type = this.surveyType;
        survey.weight = this.activeWeight;
        survey.marker = this.activeMarker;
        survey.forceComplete = this.forceComplete;
        survey.totalQuestions = this.questions.length;
        survey.minPoints = this.showForMinPoints ?  (this.minPoints >= 0 ? this.minPoints : null) : null ;

        if(this.groupesVisibility && this.selectedGroups !== null && this.selectedGroups.length > 0 ){
            survey.groups =  [...this.selectedGroups].map(g => g.uid)
        }

        switch (survey.type) {
            case TypeSurvey.Pattern:
                survey.module_id = null;
                survey.references = null;
                break;

            case TypeSurvey.AllSessions:
                survey.module_id = null;
                survey.references = null;
                break;

            case TypeSurvey.ScheduleModule:
                survey.module_id = this.scheduleModuleSurveyEdit;
                survey.references = null;
                break;

            /* case TypeSurvey.SessionTrack:
                survey.module_id = this.scheduleModuleTrackSurveyEdit;
                this.dbSurvey.forSurveyReferences(this.selectedTracks, this.surveyId, this.moduleId);
                // this.daoSurvey.forSurveyTracksUids(this.selectedTracks, this.surveyId, this.eventId, this.scheduleModuleTrackSurveyEdit);
                break; */

            case TypeSurvey.SpecificSession:
                survey.module_id = this.scheduleModuleSessionSurveyEdit;
                this.dbSurvey.forSurveyReferences(this.selectedSessions, this.surveyId, this.moduleId);
                // this.daoSurvey.forSurveySessionsUids(this.selectedSessions, this.surveyId, this.eventId);
                break;

            /* case TypeSurvey.SpecificGroup:
                survey.module_id = null;
                this.dbSurvey.forSurveyReferences(this.selectedGroups, this.surveyId, this.moduleId);
                // this.daoSurvey.forSurveyGroupsUids(this.selectedGroups, this.surveyId, this.eventId);
                break; */
        }

        this.dbSurvey.updateSurvey(this.moduleId, this.surveyId, survey, (data) => {
            if (data == true) {
                this.successSwal.fire();

                setTimeout(() => {
                    this.router.navigate([`/event/${this.eventId}/interactivity/survey/${this.moduleId}`]);
                }, 500);
            }
        });

    }

    /**
     * Abre Collapse da questao por index
     */
    openQuestion(index) {
        /**
         * get info answers for index
         */
        this.editQuestionIndex = index;
        this.editQuestionShow = true;
        this.allowShowAddQuestion = false;
        for (const i in this.questions) {
            if (i == index) {
                this.questionInfo(this.questions[i]);
                this.loadAnswer(this.questions[i].uid);
                window.scroll({
                    top: 160,
                    behavior: 'smooth'
                })
                break;
            }
        }
    }

    resetEditQuestion() {
        this.editQuestionShow = false;
        this.allowShowAddQuestion = true;
        this.formEditQuestion.reset();
    }

    /***
     * Delete question
     */
    deleteQuestion(index) {
        this.loaderQuestions[index] = true;

        // setTimeout(() => {
        //   this.loaderQuestions[index] = false;
        // }, 3000);
        if (this.questions.length > 1) {
            const deleteId = this.questions[index].uid;
            this.dbSurvey.removeQuestion(this.moduleId, this.surveyId, deleteId, (status) => {
                if (status.code == 200) {
                    this.questions.splice(index, 1);
                    this.loaderQuestions[index] = false;
                    this.loaderQuestions.splice(index, 1);
                    this.successSwal.fire();
                }
            })
        } else {
            this.errorDeleteLastQuestion.fire();
            this.loaderQuestions[index] = false;
        }
    }

    /**
     * Pega os valores da Questions
     */
    questionInfo(data) {
        switch (data.type) {
            case 'oneselect':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = true;
                this.blockQuestionPointsVisibilityEdit = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = true;
                this.blockQuestionPointsVisibilityEdit = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'evaluation':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;

            case 'dissertative':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;

            case 'date':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;
        }
        this.questionId = data.uid;
        this.formEditQuestion.reset();
        this.formEditQuestion.patchValue({
            main_title_pt_br: data.mainTitle.PtBR,
            main_title_en_us: data.mainTitle.EnUS,
            main_title_es_es: data.mainTitle.EsES,
            main_title_fr_fr: data.mainTitle.FrFR,
            main_title_de_de: data.mainTitle.DeDE,
            title_pt_br: data.title.PtBR,
            title_en_us: data.title.EnUS,
            title_es_es: data.title.EsES,
            title_fr_fr: data.title.FrFR,
            title_de_de: data.title.DeDE,
            infobooth_pt_br: data.infobooth.PtBR,
            infobooth_en_us: data.infobooth.EnUS,
            infobooth_es_es: data.infobooth.EsES,
            infobooth_fr_fr: data.infobooth.FrFR,
            infobooth_de_de: data.infobooth.DeDE,
            radioTypeQuestion: data.type,
            points: data.points !== undefined ? data.points : null
            // radioTypeGraphic: data.graphic,
        });

    }

    changeTypeQuestionEdit() {
        let value = this.formEditQuestion.get('radioTypeQuestion').value;

        switch (value) {
            case 'oneSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = true;
                this.blockQuestionPointsVisibilityEdit = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = true;
                this.blockQuestionPointsVisibilityEdit = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'evaluation':
                // this.blockGraphicTypevisibilityEdit = true;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;

            case 'dissertative':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;

            case 'date':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;

            case 'document':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;
        }
    }

    changeTypeQuestionCreate() {
        let value = this.formNewQuestion.get('radioTypeQuestion').value;

        switch (value) {
            case 'oneSelect':
                // this.blockGraphicTypevisibilityCreate = true;
                this.blockOptionsAnswersVisibilityCreate = true;
                this.blockQuestionPointsVisibilityCreate = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityCreate = true;
                this.blockOptionsAnswersVisibilityCreate = true;
                this.blockQuestionPointsVisibilityCreate = false;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'evaluation':
                // this.blockGraphicTypevisibilityCreate = true;
                this.blockOptionsAnswersVisibilityCreate = false;
                this.blockQuestionPointsVisibilityCreate = true;
                break;

            case 'dissertative':
                // this.blockGraphicTypevisibilityCreate = false;
                this.blockOptionsAnswersVisibilityCreate = false;
                this.blockQuestionPointsVisibilityCreate = true;
                break;

            case 'date':
                // this.blockGraphicTypevisibilityCreate = false;
                this.blockOptionsAnswersVisibilityCreate = false;
                this.blockQuestionPointsVisibilityCreate = true;
                break;

            case 'document':
                // this.blockGraphicTypevisibilityEdit = false;
                this.blockOptionsAnswersVisibilityEdit = false;
                this.blockQuestionPointsVisibilityEdit = true;
                break;
        }
    }

    /**
     * Pega os valores das Answers na DAO
     */
    loadAnswer(questionId) {
        this.dbSurvey.getAnswers(this.moduleId, this.surveyId, questionId, (list) => {
            this.answers = [];
            this.listAuxInput = [];
            for (const data of list) {
                this.listAuxInput.push(data);
                this.answers.push(data);
            }

            this.carryInputsAnswer(this.answers);
            this.inputValueAnswer(this.listAuxInput);
        })

    }

    /**
     * Carrega os valores das answers no input
     */
    inputValueAnswer(data) {
        let index = 0;
        this.listAnswers = [];

        for (const aux of data) {
            this.listAnswers[index] = aux;
            index++;
        }
    }

    /**
     * Cria os clones de inputs do formulario Answer
     */
    cloneInputAnswers() {
        this.listAnswers.push(new Answer());
    }

    /**
     * Delete input answers
     */
    getDeleteInputAnswers(index) {
        const deleteId = this.listAnswers[index].uid;

        if (deleteId !== undefined) {
            var questionId = this.questionId;
            var deleteAnswer = [questionId, deleteId];
            this.listDeleteInputAnswers.push(deleteAnswer);
            this.listAnswers.splice(index, 1);
        }
    }

    deleteInputAnswers() {
        for (let aux of this.listDeleteInputAnswers) {
            var questionId = aux[0];
            var deleteId = aux[1];

            this.dbSurvey.deleteAnswer(this.moduleId, this.surveyId, questionId, deleteId, (status) => {

            });
        }
    }

    /**
     *  Carrega os clones dos inputs de acordo com o total de respostas que o formulario possui
     */
    carryInputsAnswer(data) {
        let cont = data.length;
        let print = 1;
        while (cont >= 1) {
            if (print < data.length) {
                this.cloneInputAnswers();
                print++;
            }
            cont--;
        }
    }

    /**
     * Submit formulario Edit Question
     */
    editQuestion(data) {
        this.errorTitleEdit = false;
        this.errorSelectQuestionEdit = false;
        this.errorOptionAnswerEdit = false;

        const newQuestion = new Question();
        let auxMainTitle = new NameModule(data.main_title_pt_br, data.main_title_en_us, data.main_title_es_es, data.main_title_fr_fr, data.main_title_de_de);
        let auxTitle = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
        let auxInfobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
        newQuestion.mainTitle = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].mainTitle, auxMainTitle, this.eventLanguage)
        newQuestion.title = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].title, auxTitle, this.eventLanguage)
        newQuestion.infobooth = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].infobooth, auxInfobooth, this.eventLanguage)
        newQuestion.type = data.radioTypeQuestion;
        newQuestion.points = data.points;

        newQuestion.mainTitle = this.formatIdioma.fillEmptyLanguage(newQuestion.mainTitle, this.eventLanguage);
        newQuestion.title = this.formatIdioma.fillEmptyLanguage(newQuestion.title, this.eventLanguage);

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            for (const answer of this.listAnswers) {
                newQuestion.answers.push(answer);
            }
        }


        //Caso o usuário não tenha inserido o titulo da pergunta
        if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == "" &&
            newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == "" &&
            newQuestion.title.DeDE == "")) {
            this.errorTitleEdit = true;
        }

        if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
            this.errorSelectQuestionEdit = true;
        }

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listAnswers) {
                if (!(answer.answer == undefined) && !(answer.answer == null) && (answer.answer.PtBR != ""
                    || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                    || answer.answer.DeDE != "")) {
                    find = true;
                }
            }

            if (find == false) {
                this.errorOptionAnswerEdit = true;
            }
        }

        if (!(this.errorTitleEdit || this.errorSelectQuestionEdit || this.errorOptionAnswerEdit)) {
            this.dbSurvey.updateQuestion(this.moduleId, this.surveyId, this.questionId, newQuestion, this.eventTimezone, (status) => {
                this.questions[this.editQuestionIndex].mainTitle = newQuestion.mainTitle;
                this.questions[this.editQuestionIndex].title = newQuestion.title;
                this.questions[this.editQuestionIndex].infobooth = newQuestion.infobooth;
                this.questions[this.editQuestionIndex].type = newQuestion.type;
                this.questions[this.editQuestionIndex].points = newQuestion.points;

                this.editQuestionShow = false;
                this.addQuestionShow = false;
                this.allowShowAddQuestion = true;
            });

            this.deleteInputAnswers();

            this.allowShowAddQuestion = true;
        }

    }


    //----------------------------------
    //         Create New Question
    //----------------------------------

    /**
     * Cria os clones de inputs do formulario Answer
     */
    cloneInputNewQuestion() {
        this.listNewQuestion.push(new Answer());
    }

    /**
     * Delete input answers
     */
    deleteInputNewQuestion(index) {
        this.listNewQuestion.splice(index, 1);
    }

    /**
     * Create new Question
     */
    newQuestion(data) {
        this.errorTitleCreate = false;
        this.errorSelectQuestionCreate = false;
        this.errorOptionAnswerCreate = false;

        let newQuestion = new Question();
        newQuestion.mainTitle = new NameModule(data.main_title_pt_br, data.main_title_en_us, data.main_title_es_es, data.main_title_fr_fr, data.main_title_de_de);
        newQuestion.title = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
        newQuestion.infobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
        newQuestion.type = data.radioTypeQuestion;
        newQuestion.points = data.points;

        newQuestion.mainTitle = this.formatIdioma.fillEmptyLanguage(newQuestion.mainTitle, this.eventLanguage);
        newQuestion.title = this.formatIdioma.fillEmptyLanguage(newQuestion.title, this.eventLanguage);
        newQuestion.infobooth = this.formatIdioma.fillEmptyLanguage(newQuestion.infobooth, this.eventLanguage);
        newQuestion.infobooth = this.replaceLinkInfooboth(newQuestion.infobooth);

        //Caso o usuário não tenha inserido o titulo da pergunta
        if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == ""
            && newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == ""
            && newQuestion.title.DeDE == "")) {
            this.errorTitleCreate = true;
        }

        if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
            this.errorSelectQuestionCreate = true;
        }

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listNewQuestion) {
                if (answer.answer != undefined && answer.answer != null && (answer.answer.PtBR != ""
                    || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                    || answer.answer.DeDE != "")) {

                    find = true;
                }
            }

            if (find == false) {
                this.errorOptionAnswerCreate = true;
            }
        }



        if (!(this.errorTitleCreate || this.errorSelectQuestionCreate || this.errorOptionAnswerCreate)) {
            if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                let cont = 0;
                for (let aux of this.listNewQuestion) {
                    let answer = new Answer();

                    answer.answer = aux.answer;
                    aux.weight == undefined ? answer.weight = null : answer.weight = aux.weight;
                    aux.marker == undefined ? answer.marker = null : answer.marker = aux.marker;

                    if (answer.answer !== undefined && answer.answer !== null && (answer.answer.PtBR != ""
                        || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                        || answer.answer.DeDE != "")) {

                        newQuestion.answers.push(answer)
                    }

                    if (cont == this.listNewQuestion.length - 1) {
                        this.dbSurvey.createOneQuestions(this.moduleId, this.surveyId, newQuestion, this.eventTimezone, (data) => {
                            if (data.status == true) {
                                newQuestion.uid = data.questionId;
                                this.questions.push(newQuestion);
                                this.loaderQuestions.push(false);
                            }

                            this.editQuestionShow = false;
                            this.addQuestionShow = false;
                            this.allowShowAddQuestion = true;
                        });
                    }

                    cont++;
                }
            } else {
                this.dbSurvey.createOneQuestions(this.moduleId, this.surveyId, newQuestion, this.eventTimezone, (data) => {
                    if (data.status == true) {
                        newQuestion.uid = data.questionId;
                        this.questions.push(newQuestion);
                        this.loaderQuestions.push(false);
                    }

                    this.editQuestionShow = false;
                    this.addQuestionShow = false;
                    this.allowShowAddQuestion = true;
                });
            }


            //Clear form new question, close modal and show/hide blocks
            this.formNewQuestion.reset();
            // this.blockGraphicTypevisibilityCreate = true;
            this.blockOptionsAnswersVisibilityCreate = true;
            this.blockQuestionPointsVisibilityCreate = false;
            $('#modalSurvey').modal('toggle');

            this.listNewQuestion = [];
            this.listNewQuestion.push(new Answer());
            this.addQuestionShow = false;

        }

    }

    cancelNewQuestion() {

        //Clear form new question, close modal and show/hide blocks
        $(function () {
            $('#form-questions').each(function () {
                this.reset();
                $("input[name=newRadioTypeQuestion]").prop('checked', false);
                // $("input[name=radioTypeGraphic]").prop('checked', false);
                // $('#answer-options').hide('slow');
                // $('#chart-options').hide('slow');
            });
        })

        this.listNewQuestion = [];
        this.listNewQuestion.push(new Answer());
        this.addQuestionShow = false;
    }




    load() {
        this.dbEvent.getModulesSchedule(this.eventId, (data) => {
            this.scheduleModules = data;
        });
    }

    // LOAD ALL TRACKS OF SCHEDULE MODULE 
    private loadTracks(moduleId) {
        this.dbSession.getModuleTracks(moduleId, (tracks) => {
            // this.dao.tracksSessionEvent(moduleId, (tracks) => {
            this.tracks = [];
            this.group = [];
            this.group = tracks;
            for (const track of tracks) {
                this.tracks.push(track);
            }
        });
    }

    // receive group name and return index of the group  to get id group
    findIdTrack(track: Track) {
        const index = this.tracks.map(function (e) {
            return new GetNameTrack().transform(e, [this.userLanguage, this.eventLanguage]);
        }).indexOf(new GetNameTrack().transform(track, [this.userLanguage, this.eventLanguage]));
        return index;
    }

    // autocomplete
    filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.query !== "") {
            this.filteredList = this.tracks.filter(function (el) {
                let elementStr = new GetNameTrack().transform(el, [this.userLanguage, this.eventLanguage]);
                return elementStr.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));

        } else {
            this.filteredList = [];
        }
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    select(item) {
        if (this.selectedTracks.length > 0) {
            const index = this.selectedTracks.indexOf(item);
            if (index == -1) {
                this.selectedTracks.push(item);
            }
        } else {
            this.selectedTracks.push(item);
        }

        this.query = '';
        this.filteredList = [];
    }

    remove(item) { // remove group from display list
        this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
    }



    loadSessions(moduleId) {
        this.dbSession.getSessionsModule(moduleId, (list: Array<Session>) => {
            this.sessions = [];
            this.session = list;

            this.sessionsNames = [];
            for (const session of list) {
                this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]))
                // this.sessions.push(session);
            }
        })
    }

    // autocomplete
    filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.querySession !== "") {
            this.filteredSession = this.session.filter(function (el) {

                // search session using user language
                switch (this.userLanguage) {
                    case ('pt_BR'):
                        return el.name.PtBR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('en_US'):
                        return el.name.EnUS.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('es_ES'):
                        return el.name.EsES.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('fr_FR'):
                        return el.name.FrFR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('de_DE'):
                        return el.name.DeDE.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
                }
            }.bind(this));

        } else {
            this.filteredSession = [];
        }

    }

    // receive session name and return index of the session to get id session
    findIdSession(session: Session) {
        const nameSession = session.name
        let index = -1

        // search session using user language
        switch (this.userLanguage) {
            case ('pt_BR'):
                index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                break

            case ('en_US'):
                index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                break

            case ('es_ES'):
                index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                break

            case ('fr_FR'):
                index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                break

            case ('de_DE'):
                index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                break
        }

        // search session using principal event language
        if (index <= -1) {
            switch (this.eventLanguage) {
                case ('pt_BR'):
                    index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                    break

                case ('en_US'):
                    index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                    break

                case ('es_ES'):
                    index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                    break

                case ('fr_FR'):
                    index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                    break

                case ('de_DE'):
                    index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                    break
            }
        }

        return index;
    }



    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectSession(item) {
        if (this.selectedSessions.length > 0) {
            const index = this.selectedSessions.map(function (e) { return e.uid; }).indexOf(item.uid);

            if (index == -1) {
                this.selectedSessions.push(item);
            }
        } else {
            this.selectedSessions.push(item);
        }

        this.querySession = '';
        this.filteredSession = [];
    }

    removeSession(item) { // remove group from display list
        this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }

    loadGroups() {
        this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
            this.groups = [];
            this.groups = response['groups'];
        });
    }

    // autocomplete
    filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.queryGroup !== "") {
            this.filteredGroup = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredGroup = [];
        }
    }

    // receive session name and return index of the session to get id session
    findIdGroup(group: Group) {
        const index = this.groups.map(function (e) { return e.name; }).indexOf(group.name);
        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectGroup(item) {
        if (this.selectedGroups.length > 0) {
            const index = this.selectedGroups.indexOf(item);
            if (index == -1) {
                this.selectedGroups.push(item);
            }
        } else {
            this.selectedGroups.push(item);
        }

        this.queryGroup = '';
        this.filteredGroup = [];
    }

    removeGroup(item) { // remove group from display list
        this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }

    //verifica se o survey será vinculado ou não a uma sessão
    changeTypeSurvey() {
        if (this.surveyLinkedToSession) {
            //caso o survey sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
            this.surveyType = TypeSurvey.AllSessions;
        } else {
            this.surveyLinkedToSession = false;
            this.selectedSessions = [];
            this.surveyType = TypeSurvey.Pattern;

            this.SelectedSessionVisibility = false;
            this.tracksAttendeeVisibility = false;
            this.scheduleModuleTrackVisibility = false;
            this.scheduleModuleSessionVisibility = false;
            this.specificTrackVisibility = false;
            this.specificGroupVisibility = false;
        }
    }

    //caso o survey seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o surveyType
    changeSessionType() {
        switch (this.surveyType) {
            case 'AllSessions':
                this.surveyType = TypeSurvey.AllSessions;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;

                //Esvazia o array de sessões selecionadas posteriormente.
                this.selectedSessions = [];
                break;

            case 'ScheduleModule':
                this.surveyType = TypeSurvey.ScheduleModule;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = true;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SessionTrack':
                this.surveyType = TypeSurvey.SessionTrack;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.scheduleModuleTrackVisibility = true;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificSession':
                this.surveyType = TypeSurvey.SpecificSession;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = true;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificGroup':
                this.surveyType = TypeSurvey.SpecificGroup;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = true;
                this.loadGroups();
                break;
        }
    }

    moduleSelectedChange() {
        this.selectedGroups = [];
        // this.selectedSessions = [];
        this.selectedTracks = [];

        var trackId = this.scheduleModuleTrackSurveyEdit;
        var sessionId = this.scheduleModuleSessionSurveyEdit;
        var specificModule = this.scheduleModuleSurveyEdit;

        if (trackId == undefined || trackId == null || trackId == '') {
            this.specificTrackVisibility = false;
        } else {
            this.specificTrackVisibility = true;
            this.loadTracks(trackId);
        }

        if (sessionId == undefined || sessionId == null || sessionId == '') {
            this.SelectedSessionVisibility = false;
        } else {
            this.SelectedSessionVisibility = true;
            this.loadSessions(sessionId);
        }
    }

    resetValuesForm() {
        this.surveyLinkedToSession = false;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.specificTrackVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificGroupVisibility = false;

        // tracks
        this.tracks = [];
        this.group = []; // array de ids
        this.query = ''; // string digitada pelo usuário
        this.filteredList = []; // lista de sugestões exibidas
        this.selectedTracks = []; // array com os tracks selecionados

        // sessions
        this.sessions = [];
        this.sessionsNames = [];
        this.session = [];
        this.querySession = ''; // string digitada pelo usuário
        this.filteredSession = []; // lista de sugestões exibidas
        this.selectedSessions = []; // array com os tracks selecionados

        // groups
        this.groups = [];
        this.groupsNames = [];
        this.ManagerGroup = []; // array de ids
        this.queryGroup = ''; // string digitada pelo usuário
        this.filteredGroup = []; // lista de sugestões exibidas
        this.selectedGroups = []; // array com os tracks selecionados

        this.scheduleModules = [];
        this.surveyType = TypeSurvey.Pattern;
        this.scheduleModuleSurveyEdit = null;
        this.scheduleModuleTrackSurveyEdit = null;
        this.scheduleModuleSessionSurveyEdit = null;
    }

    cancel() {
        this.location.back();
    }

    replaceLinkInfooboth(data) {
        data['PtBR'] == null ? data['PtBR'] = '' : data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EnUS'] == null ? data['EnUS'] = '' : data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EsES'] == null ? data['EsES'] = '' : data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['FrFR'] == null ? data['FrFR'] = '' : data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['DeDE'] == null ? data['DeDE'] = '' : data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

        return data;
    }

}
