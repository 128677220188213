import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Group } from 'src/app/models/group';
import {
  AppointmentRule,
  AppointmentRuleGroups,
  AppointmentTimeSlotStatus,
  AppointmentTimeSlotStatusChangeEvent,
  AppointmentTimeSlotWithGroupsAndRule
} from 'src/app/models/appointments';
import { DbAppointments } from 'src/app/providers/database/db-appointments';

@Component({
  selector: 'app-list-time-slots',
  templateUrl: './list-time-slots.component.html',
  styleUrls: ['./list-time-slots.component.scss']
})

export class ListTimeSlotsComponent implements OnInit {
  private _timeSlotsRules: AppointmentRule[] = [];
  timeSlotsWithGroups: AppointmentTimeSlotWithGroupsAndRule[] = [];
  selectedTimeSlotsWithGroups: AppointmentTimeSlotWithGroupsAndRule[] = [];

  AppointmentTimeSlotStatus = AppointmentTimeSlotStatus;

  timeSlotsSelection: boolean[];
  selectedAllInput = false;

  ruleFilter = 'all';

  @Input()
  set timeSlotsRules(timeSlotsRules: AppointmentRule[]) {
    this._timeSlotsRules = timeSlotsRules || [];
    this.timeSlotsWithGroups = [];
    this._timeSlotsRules.forEach(rule =>
      this.timeSlotsWithGroups.push(
        ...rule.slots.getAllIntervals()
          .filter(timeSlot => timeSlot.status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED)
          .map(timeSlot => timeSlot.getStatusWithOverridenStatusFromOtherSlots(rule.slots.disabledTimeSlots))
          .map(timeSlot =>
            new AppointmentTimeSlotWithGroupsAndRule(
              timeSlot,
              rule.groups,
              rule,
            )
          ))
    );
    this.timeSlotsSelection = this.selectedTimeSlotsWithGroups.map(() => false);
    this.filterRule();
  }
  get timeSlotsRules() {
    return this._timeSlotsRules;
  }

  @Input()
  groups: Group[];

  locale: string;

  @Output()
  timeSlotStatusChange = new EventEmitter<AppointmentTimeSlotStatusChangeEvent>();

  @Output()
  selectRule = new EventEmitter<AppointmentRule>();

  currentPage;

  constructor(
    private dbAppointments: DbAppointments,
  ) {
  }

  async ngOnInit() {
    this.locale = this.dbAppointments.locale;
  }

  getGroupName(groupId: string) {
    const group = this.groups.find(g => g.uid === groupId);
    return group ? group.name : 'Unknown group';
  }

  async updateTimeSlotStatus(timeSlots: AppointmentTimeSlotWithGroupsAndRule[], enable: boolean) {
    let status: AppointmentTimeSlotStatus;
    if (!enable) {
      status = AppointmentTimeSlotStatus.TIME_SLOT_DISABLED;
    } else {
      status = AppointmentTimeSlotStatus.TIME_SLOT_ENABLED;
    }
    this.timeSlotStatusChange.emit({ timeSlots, status });
  }

  removeSelected() {
    const timeSlotsToDelete = this.selectedTimeSlotsWithGroups.filter((t, i) => this.timeSlotsSelection[i]);
    this.updateTimeSlotStatus(timeSlotsToDelete, false);
  }

  getGroupsRulesString(groups: AppointmentRuleGroups[]) {
    return groups.map(g => this.getGroupName(g.applicantGroupId) + ' - ' + this.getGroupName(g.invitedGroupId)).join('\n');
  }

  onSelectRule(timeSlot: AppointmentTimeSlotWithGroupsAndRule) {
    this.selectRule.emit(AppointmentRule.fromDatabase(timeSlot.sourceRule.toDatabaseFormat()));
  }

  selectAll() {
    this.timeSlotsSelection = this.selectedTimeSlotsWithGroups.map(() => this.selectedAllInput);
  }

  filterRule() {
    this.selectedTimeSlotsWithGroups = this.timeSlotsWithGroups.filter(ts =>
      this.ruleFilter === 'all'
      || ts.sourceRule.uid === this.ruleFilter
    );
    this.timeSlotsSelection = this.selectedTimeSlotsWithGroups.map(() => false);
  }
  pageChanged($event) {
    this.currentPage = $event;
  }

}
