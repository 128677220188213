import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbQuizProvider } from 'src/app/providers/database/db-quiz';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';

type AOA = Array<Array<any>>;
declare let $: any;

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmptySwal') public errorEmptySwal: SwalComponent;
    @ViewChild('clearAllResultsQuizSwal') public clearAllResultsQuizSwal: SwalComponent;
    @ViewChild('clearQuizSwal') public clearQuizSwal: SwalComponent;
    @ViewChild('removeQuizSwal') public removeQuizSwal: SwalComponent;
    @ViewChild('clearQuestionSwal') public clearQuestionSwal: SwalComponent;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    }

    onReorderShow: boolean = false;
    loaderOrder: boolean = false;
    quizView: boolean = true;
    moduleId: string = null
    eventId: string = null;
    eventTimezone: string = null;
    loader: boolean = true;
    public quizs: Array<any> = [];
    public quizRemoveId;
    quizRemoveIndex;
    public quizClearId;
    public questionClearId;

    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string;
    public exportLanguage: string = null;
    public quizExportId: string = null;
    public questionExportId: string = null;
    public typeExport: string = null;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private db: DbQuizProvider,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private dragula: DragulaService,
        private luxon: LuxonService) {
        // get module id
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        dragula.createGroup('bag-quiz', this.dragulaOptions);
    }

    ngAfterContentChecked() {
        let auxRoute = this.router.url;
        if (auxRoute.includes('create-quiz') || auxRoute.includes('edit-quiz')) {
            this.quizView = false;
        } else {
            this.quizView = true;
        }
    }

    ngOnInit() {
        // start dragula reorder bag
        this.dragula.dropModel('bag-quiz').subscribe((value: any) => {
            this.onReorder(value);
        });

        this.db.getQuizs(this.moduleId, (quizs: Array<any>) => {
            this.quizs = [];
            this.quizs = quizs;
            this.loader = false;
        })

        this.getEventTimezone();
        this.getUserLanguage();
        this.getEvent();
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy('bag-quiz');
    }


    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.quizs = order.targetModel;
        for (let i = 0; i < (this.quizs).length; ++i) {
            this.quizs[i].order = i;
        }
    }


    saveNewOrder() {
        this.loaderOrder = true;

        this.db.changeOrder(this.moduleId, this.quizs, (result) => {
            if (result == true) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }

            this.loaderOrder = false;
            this.onReorderShow = false;
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.exportLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    getEventTimezone() {
        this.dbEvent.getTimezone(this.eventId, (timezone) => {
            this.eventTimezone = timezone;
        })
    }

    changeVisibilityQuiz(quizId, visibility) {
        this.db.changeVisibility(this.moduleId, quizId, !visibility);
    }

    async changeVisibilityQuestion(quizId, questionId, visibility, quizIndex, questionIndex) {
        try {
            await this.db.changeVisibilityQuestion(this.moduleId, quizId, questionId, !visibility);

            if (visibility) {
                this.quizs[quizIndex].questions[questionIndex].visibility = false;
            } else {
                this.quizs[quizIndex].questions[questionIndex].visibility = true;
            }
        } catch (error) {
            this.errorSwal.fire();
        }
    }

    enableAll() {
        for (let quiz of this.quizs) {
            this.db.changeVisibility(this.moduleId, quiz.uid, true);
        }
    }

    disableAll() {
        for (let quiz of this.quizs) {
            this.db.changeVisibility(this.moduleId, quiz.uid, false);
        }
    }

    expandirQuestions(index, quizz) {
        (quizz.expand) ? quizz.expand = false : quizz.expand = true;
        let aux = '.hidden';
        aux = aux.concat(index);

        if (this.quizs[index].questions.length > 0) {
            this.showAndHiddenCollapse(aux);
        } else {

            this.db.getQuestions(this.moduleId, this.quizs[index].uid, (questions) => {
                this.quizs[index].questions = questions;

                setTimeout(() => {
                    this.showAndHiddenCollapse(aux);
                }, 1000);
            })

        }
    }

    /**
     * Hide or show collapse based on a query
     * @param query 
     */
    showAndHiddenCollapse(query) {
        if ($(query).hasClass('hideAll')) {
            $(query).removeClass('hideAll');
            $(query).show();
        } else {
            $(query).addClass('hideAll');
            $(query).hide();
        }
    }

    getRemoveQuestion(quizId, questionId, index) {

    }

    removeQuestion() {

    }

    getRemoveQuiz(quizId, index) {
        this.quizRemoveId = quizId;
        this.quizRemoveIndex = index;
        this.removeQuizSwal.fire();
    }

    removeQuiz() {
        this.loader = true;
        this.db.removeQuiz(this.moduleId, this.quizRemoveId, (data) => {
            if (data.code == 200) {
                this.successSwal.fire();
                this.quizs.splice(this.quizRemoveIndex, 1);
                this.loader = false;
            }
        })
    }

    /**
     * Clear all results of quiz
     */
    clearAllResults() {
        let listQuizsIds = [];
        this.loader = true;
        for (let quiz of this.quizs) {
            listQuizsIds.push(quiz.uid);
        }

        this.db.clearAllQuizs(this.eventId, this.moduleId, listQuizsIds).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    /**
     * Temp data and launch swal
     * @param quiz 
     */
    getQuizClear(quiz) {
        this.quizClearId = quiz.uid;
        this.clearQuizSwal.fire();
    }

    /**
     * Clear result of a quiz
     */
    clearQuiz() {
        this.loader = true;

        this.db.clearQuiz(this.eventId, this.moduleId, this.quizClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    getQuestionClear(quizId, questionId) {
        this.quizClearId = quizId;
        this.questionClearId = questionId;
        this.clearQuestionSwal.fire();
    }

    /**
     * Clear question
     */
    clearQuestion() {
        this.loader = true;
        this.db.clearQuestion(this.moduleId, this.quizClearId, this.questionClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    exportData() {
        $('#exportModal').modal('hide');
        console.log("Type: ", this.typeExport);
        switch (this.typeExport) {
            case 'quiz':
                this.exportOneQuiz(this.quizExportId, this.exportLanguage)
                break;

            case 'question':
                this.exportQuestion(this.quizExportId, this.questionExportId, this.exportLanguage)
                break;

            case 'allQuiz':
                this.exportQuizs(this.exportLanguage)
                break;
        }
    }

    exportQuizs(language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        let dataExportQuiz: Array<any> = [];
        let cont = 0;
        for (let quiz of this.quizs) {
            this.prepareExportQuiz(quiz.uid, language, (data) => {

                if (data !== null) {
                    dataExportQuiz.push(data)
                }

                if (cont == this.quizs.length - 1) {
                    $('#exportLoading').modal('toggle');
                    if (dataExportQuiz.length > 0) {
                        this.export(dataExportQuiz, 'Quizs');
                    } else {
                        this.errorEmptySwal.fire();
                    }
                }

                cont++;
            })

        }
    }

    exportOneQuiz(quizId, language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        this.prepareExportQuiz(quizId, language, (data) => {
            let dataExportQuiz: Array<any> = [];

            if (data !== null) {
                dataExportQuiz.push(data)
                $('#exportLoading').modal('toggle');
                this.export(dataExportQuiz, 'Quiz');
            } else {
                $('#exportLoading').modal('toggle');
                this.errorEmptySwal.fire();
            }
        })
    }

    exportQuestion(quizId, questionId, language) {
        let dataExport = [];
        dataExport = [[
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportQuestion(this.moduleId, quizId, questionId, language, (data) => {
            for (let result of data.result) {
                dataExport.push(this.prepareResult(result));
            }

            let dataExportQuestion = [dataExport];
            this.export(dataExportQuestion, 'Question')
        })
    }

    prepareExportQuiz(quizId, language, onResolve) {

        let dataExportQuiz = [];
        dataExportQuiz = [[
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportQuizs(this.moduleId, quizId, language, (data) => {
            if (data.code == 200) {
                let listResult = data.result;

                if (listResult.length <= 0) {
                    onResolve(null);
                }
                let cont = 0;
                for (let result of listResult) {
                    dataExportQuiz.push(this.prepareResult(result));

                    if (cont == listResult.length - 1) {
                        onResolve(dataExportQuiz);
                    }
                    cont++;
                }
            } else {
                $('#exportLoading').modal('toggle');
                this.errorSwal.fire();
            }
        })

    }

    prepareResult(result) {
        let array = [];
        array[0] = result.question;
        array[1] = result.name;
        array[2] = result.email;
        array[3] = result.answer;
        array[4] = result.marker;

        let date = this.luxon.convertTimestampToDate(result.timestamp)
        let day = '0' + date.day;
        let month = '0' + date.month;
        let year = date.year;
        let hours = date.hour;
        let minutes = '0' + date.minute;
        let seconds = '0' + date.second;

        array[5] = day.substr(-2) + '/' + month.substr(-2) + '/' + year + ' - ' + hours + ':' +
            minutes.substr(-2) + ':' + seconds.substr(-2);

        return array;

    }

    export(listExport, fileName) {
        let totalFlaps = listExport.length;
        let result: Array<any> = [];

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        for (let i = 0; i < totalFlaps; i++) {

            const wscols: XLSX.ColInfo[] = [
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { hidden: false } // hide column
            ];

            /* At 96 PPI, 1 pt = 1 px */
            const wsrows: XLSX.RowInfo[] = [
                { hpx: 25 }, // "pixels"
            ];

            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(listExport[i]);

            /* TEST: column props */
            ws['!cols'] = wscols;

            /* TEST: row props */
            ws['!rows'] = wsrows;

            result[i] = ws;


            XLSX.utils.book_append_sheet(wb, result[i], 'Sheet' + (i + 1));
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), fileName + '.xlsx');
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
}
