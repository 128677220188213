    import { Injectable } from '@angular/core';
    import { AuthService } from '../auth/auth.service';
    import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {
    public displayName: string = null;
    public email: string = null;
    public photoUrl: string = null;
    public userType: number;
    public userId: string = null;
    public clientId: string = null;
    public userModuleId: string = null;
    public language: string = null;
    constructor
        (
            private auth: AuthService,
            private aFirestore: AngularFirestore
        ) {
    }

    loadService(onResolve) {
        this.auth.current((userAuth) => {
            if (userAuth) {
                this.aFirestore.firestore.collection('users')
                    .doc(userAuth['uid'])
                    .onSnapshot((value) => {
                        let user = value.data();
                        this.displayName = user.name;
                        localStorage.setItem('clientName', this.displayName);
                        this.email = user.email;
                        this.photoUrl = user.photoUrl;
                        this.userType = user.type;
                        this.userId = user.uid;
                        this.userModuleId = user.moduleId;
                        this.language = user.language;
                        this.clientId = user.clientId;
                        onResolve(true);
                    });
            }
        });
    }

    // get the language of the user.
    getLanguage(onResolve) {
        this.auth.current((userAuth) => {
            if (userAuth) {
                this.aFirestore.firestore.collection('users').doc(userAuth['uid']).onSnapshot((snapshot) => {
                    const data = snapshot.data().language

                    if (typeof data !== 'undefined' && data !== null) {
                        this.language = data
                    }

                    onResolve(this.language)
                }, err => {
                    console.log(err)
                })
            }
        })
    }
}