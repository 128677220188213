import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';

@Injectable({
    providedIn: 'root'
})

export class DbLogsProvider {
    public headers;
    public requestOptions;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    generateLog(log: Object) {
        this.http.post(PathApi.baseUrl + PathApi.dbLogsCreateLog, log, this.requestOptions)
            .subscribe((_) => { })
    }

    getLog(logUid: string, onResolve) {
        this.http.post(PathApi.baseUrl + PathApi.dbLogsGetLog, logUid, this.requestOptions)
            .subscribe((log) => {
                onResolve(log['result']);
            }), err => {
                onResolve(err)
            };
    }

    getAllLogs(onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbLogsGetAllLogs, this.requestOptions)
            .subscribe((log) => {
                onResolve(log['result']);
            }), err => {
                onResolve(err)
            };
    }

}