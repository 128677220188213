import { Component, NgZone, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { EmailProvider } from 'src/app/providers/email/email.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DestinationType } from 'src/app/enums/type-destination-email';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbMailingProvider } from 'src/app/providers/database/db-mailing';
import { Email } from 'src/app/models/email';
import Swal from 'sweetalert2';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

@Component({
    selector: 'app-edit-email',
    templateUrl: './edit-email.component.html',
    styleUrls: ['./edit-email.component.scss']
})

export class EditEmailComponent implements OnInit {

    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;

    @ViewChild('successEmailSwal') public successEmailSwal: SwalComponent;
    @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;

    eventId: string;
    emailId: string;
    eventTitle: string;
    principalEventLanguage = ''

    editedEmail;
    title: string;
    object: string;
    sender: string;
    destination: string;

    groups = []
    selectedGroups = []
    showGroups: boolean;
    q: string = '';

    emailTestDestination = '';
    extractedTestEmails: string[] = [];

    attendees = []
    speakers = []

    errorEmailTitle: boolean;
    errorEmailObject: boolean;
    errorEmailDestination:boolean ;
    errorEmailSender:boolean ;
    errorEmailEmpty: boolean;
    errorEmailsTest: boolean;

    savingLoader: boolean
    emailBodyLoaded: boolean = false;

    language: string = "EnUS";

    constructor(
        private route: ActivatedRoute,
        private dbEvents: DbEventsProvider,
        private dbMailing: DbMailingProvider,
        private router: Router,
        private dbGroups: DbGroupsProvider,
        private emailSvc: EmailProvider,
        private zone: NgZone,
        private SLanguage: FormatedEventLanguageService,
        private translateService: TranslateService
    ){
        this.eventId = this.route.parent.params['_value']['uid'];
        this.emailId = this.route.snapshot.params["emailId"];

        this.dbEvents.getEvent(this.eventId, ev => {
            this.eventTitle = ev.title
        })
    }

    async ngOnInit(){
        this.language = this.SLanguage.getFormatedLanguage();

        const mail = await this.dbMailing.getEmail(this.eventId, this.emailId)
        this.editedEmail = mail;
        console.log(mail)
        this.emailBodyLoaded = true;
        this.title = mail.title;
        this.object = mail.object;
        this.sender = mail.sender;
        if(mail.groups.length > 0) {
            this.dbGroups.getGroupsOfEvent(this.eventId, (result) => {
                this.groups = result.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0) ;
                this.showGroups = true;
                this.selectedGroups = [...this.groups].filter(gr => mail.groups.includes(gr.uid))
                this.destination = mail.destinationType
            })
        }
        if(mail.destinationType !== -1){
            this.destination = mail.destinationType
        }
        this.attendees = await this.dbEvents.getAttendeesEvent(this.eventId)
        this.speakers = await this.dbEvents.getSpeakersEvent(this.eventId)
    }

    editorLoaded(){
        const design = this.editedEmail.design ? JSON.parse(this.editedEmail.design) : {}
        setTimeout(() => {
            this.emailEditor.loadDesign(design)
        },500)
        // this.emailEditor.editor.loadDesign(design)
    }

    sendEmail(type: string){
        this.errorEmailTitle = false;
        this.errorEmailObject = false;
        this.errorEmailSender = false;
        this.errorEmailDestination = false;
        this.errorEmailEmpty = false;
        this.errorEmailsTest = false;

        if(!this.title.trim()){
            this.errorEmailTitle = true;
            return;
        }
        if(!this.object.trim()){
            this.errorEmailObject = true;
            return;
        }
        if(!this.sender.trim()){
            this.errorEmailSender = true;
            return;
        }
        if(this.destination == '-1' && (type === 'email-test' || type === 'normal')){
            this.errorEmailDestination = true;
            return;
        }
        if(type === 'email-test'  && this.extractedTestEmails.length === 0){
            this.errorEmailsTest = true;
            return;
        }
        this.emailEditor.editor.exportHtml(async (data) => {
            const rows = data.design.body.rows;
            const design = JSON.stringify(data.design)

            if(!rows.every(r => r.columns.every(c => c.contents.length === 0)) ){ // check if there is at least one component
                let receivers = '';
                let attendeesEmail = []
                let speakersEmail = []
                let des: DestinationType ;
                switch (Number(this.destination)) {
                    case DestinationType.ALL:
                        attendeesEmail = [...this.attendees].map(a => a.email)
                        speakersEmail = [...this.speakers].map(s => s.email)
                        des = DestinationType.ALL
                        break;
                    case DestinationType.GROUPS:
                        des = DestinationType.GROUPS
                        attendeesEmail = [...this.attendees].filter(a => Object.keys(a.groups).some(k => [...this.selectedGroups].map(x=>x.uid).includes(k))).map(_ => _.email)
                        speakersEmail = [...this.speakers].filter(a => Object.keys(a.groups).some(k => [...this.selectedGroups].map(x=>x.uid).includes(k))).map(_ => _.email)
                        break;
                    case DestinationType.CONNECTED_USERS:
                        des = DestinationType.CONNECTED_USERS
                        attendeesEmail = [...this.attendees].filter(a => a.firstAccess === false).map(a => a.email)
                        speakersEmail = [...this.speakers].filter(a => a.firstAccess === true).map(s => s.email)
                        break;
                    case DestinationType.NOT_CONNECTED_USERS:
                        des = DestinationType.NOT_CONNECTED_USERS
                        attendeesEmail = [...this.attendees].filter(a => !(a.firstAccess === false)).map(a => a.email)
                        speakersEmail = [...this.speakers].filter(a => !(a.firstAccess === true)).map(s => s.email)
                        break;
                }
                receivers = [...attendeesEmail, ...speakersEmail].filter(x => x).join(',')
    
    
                const fakeDomainName = this.eventTitle.toLowerCase().split(' ').filter(x => (x >= 'a' && x <= 'z') || (x >= '0' && x <= '9')).join('')
                const from = `${this.sender} <noreplay@${fakeDomainName}.com>`;
                let emailRequest = {
                    title: this.object,
                    body: data.html,
                    email: '',
                    tag: this.eventId,
                    from: from
                };
                
                if(type === 'email-test') {
                    const emailDB = new Email(this.title, this.object, this.eventId, DestinationType.SPECIFIC)
                    emailDB.status = 1
                    emailDB.isTest = true;
                    emailDB.receivers = this.extractedTestEmails.join(',');
                    emailDB.design = design

                    
                    emailRequest.email = emailDB.receivers

                    Swal.fire(this.translateService.instant('comp.mailing.swal_waiting'))
                    Swal.showLoading()
    
                    this.emailSvc.sendEmailToUser(emailRequest, async (res) => {
                        emailDB.mailGunId = res['mailgunID'] ? res['mailgunID'] : ''
                        await this.dbMailing.saveEmail(this.eventId,emailDB,data.html,'send')

                        if (res['result']) {
                            // const valueAdd = await this.dbMailing.createEmail(this.eventId,emailDB, emailReq.body, design)
                            Swal.hideLoading()
                            this.successEmailSwal.fire()
                            this.zone.run(() => {
                                this.redirectToList()
                            })
                            this.selectedGroups = [];
                        } else {
                            console.log('error sending mail')
                            this.errorEmailSwal.fire();
                        }
                    })
                } else if(type === 'normal'){
                    const email = new Email(this.title,this.object, this.eventId,des)
                    email.uid = this.editedEmail.uid
                    email.sender = this.sender
                    email.receivers = receivers
                    email.groups = [...this.selectedGroups].map(x=>x.uid)
                    email.design = design
                    email.status = 1

                    console.log(email)

                    Swal.fire(this.translateService.instant('comp.mailing.swal_adding'))
                    Swal.showLoading()
                    
                    emailRequest.email = email.receivers
                    
                    this.emailSvc.sendEmailToUser(emailRequest, async (res) => {
                        email.mailGunId = res['mailgunID'] ? res['mailgunID'] : ''
                        // await this.dbMailing.editEmail(this.eventId, email)
                        await this.dbMailing.saveEmail(this.eventId,email,data.html,'send')

                        this.selectedGroups = [];
                        if (res['result']) {
                            this.successEmailSwal.fire()
                            Swal.hideLoading()
                            this.zone.run(() => {
                                this.redirectToList()
                            })
                        } else {
                            console.log('error sending mail')
                            this.errorEmailSwal.fire();
                        }
                    })
                }

            }
        })
    }

    saveEmail(){
        this.emailEditor.editor.exportHtml(async data => {
            let email = new Email(this.title, this.object, this.eventId)
            email.status = 0
            email.destinationType = Number(this.destination) == -1 ? -1 : (Number(this.destination) as DestinationType);
            this.savingLoader = true;
            email.uid = this.editedEmail.uid
            
            email.design = JSON.stringify(data.design)
            // update 
            const res = await this.dbMailing.saveEmail(this.eventId, email, data.html)
            this.savingLoader = false
            if(res){
                this.successEmailSwal.fire()
            } else {
                this.errorEmailSwal.fire()
            }
        })
    }
    
    testEmailsChanged(){
        this.extractedTestEmails = this.emailTestDestination.split(';').filter(e => EMAIL_REGEX.test(e.trim()))
    }

    chooseGroup(uid){
        if(this.selectedGroups.every(x => x.uid !== uid))
            this.selectedGroups.push([...this.groups].filter(x => x.uid === uid)[0])
        this.q = ""
    }

    removeGroup(gr){
        this.selectedGroups = [...this.selectedGroups].filter(x => x.uid !== gr.uid)
    }

    selectDestinationType(){
        this.showGroups = (this.destination === DestinationType.GROUPS.toString());
    }

    redirectToList(){
        this.router.navigate([`/event/${this.eventId}/mailing`]);
    }

    getDate(date: number){
        return this.language !== "FrFR" ? moment(date * 1000).format("YYYY/MM/DD - HH:mm") : moment(date * 1000).format('DD/MM/YYYY - HH:mm')
    }
}
