import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})

export class FormatedEventLanguageService {
    public language: string

    constructor(
        private translate: TranslateService
    ) {
        this.language = environment.platform.defaultLanguage;
    }

    /**
     * @param lang
     * @returns converts the lang parameter to the event's main event event.
     */
    convertLangFormat(lang) {
        switch (lang) {
            case 'en_US' || 'en-US': {
                this.language = 'EnUS';
                break;
            }
            case 'es_ES' || 'es-ES': {
                this.language = 'EsES';
                break;
            }
            case 'fr_FR' || 'fr-FR': {
                this.language = 'FrFR';
                break;
            }
            case 'de_DE' || 'de-DE': {
                this.language = 'DeDE';
                break;
            }

            default: {
                this.language = 'PtBR'
            }
        }
    }

    convertLang(lang) {
        switch (lang) {
            case 'en_US' || 'en-US': {
                return ('EnUS');
                break;
            }
            case 'es_ES' || 'es-ES': {
                return ('EsES');
                break;
            }
            case 'fr_FR' || 'fr-FR': {
                return ('FrFR');
                break;
            }
            case 'de_DE' || 'de-DE': {
                return ('DeDE');
                break;
            }

            default: {
                return ('PtBR')
            }
        }
    }

    getFormatedLanguage() {
        let lang = this.translate.getDefaultLang();

        switch (lang) {
            case 'en_US' || 'en-US': {
                return ('EnUS');
            }
            case 'es_ES' || 'es-ES': {
                return ('EsES');
            }
            case 'fr_FR' || 'fr-FR': {
                return ('FrFR');
            }
            case 'de_DE' || 'de-DE': {
                return ('DeDE');
            }

            default: {
                return ('EnUS');
            }
        }
    }

    /**
     * Receives a string in any of these formats: en_US, EnUS and returns in this format: en-US
     * @param language
     * @returns a string formatted in dash-case
     */
    getLanguageFormatedInDashCase(language: string) {
        const pascalCase: RegExp = /^[A-Z][a-z][A-Z]{2}$/;
        const snakeCase: RegExp = /^[a-z]{2}_[A-Z]{2}$/;
        const dashCase: RegExp = /^[a-z]{2}-[A-Z]{2}$/;

        if (language.match(dashCase)) {
            return language;
        }

        if (language.match(pascalCase)) {
            return language.slice(0, 2).toLocaleLowerCase() + '-' + language.slice(2).toUpperCase();
        }

        if (language.match(snakeCase)) {
            return language.replace('_', '-');
        }
    }
}
