import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "src/app/providers/global/global.service";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { Group } from "src/app/models/group";
import { DbAppointments } from "src/app/providers/database/db-appointments";
import * as luxon from "luxon";
import {
    AppointmentRule,
    AppointmentRuleGroups,
    AppointmentTimeSlot,
    AppointmentTimeSlotStatus,
    AppointmentTimeSlotStatusChangeEvent,
} from "src/app/models/appointments";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { saveAs } from "file-saver";
import { ModuleAppointments } from "src/app/models/modules/module-appointments";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-appointments",
    templateUrl: "./appointments.component.html",
    styleUrls: ["./appointments.component.scss"],
})
export class AppointmentsComponent implements OnInit {
    public module: ModuleAppointments;

    // get the language of the user.
    public userLanguage: string;
    eventId: string;
    moduleId: string;
    loader = true;

    groups: Group[] = [];
    event: any;
    eventTimezone: string;
    rules: AppointmentRule[] = [];

    rule: AppointmentRule = new AppointmentRule(
        null,
        [new AppointmentRuleGroups(null, null)],
        false
    );
    slotsCount = 0;
    locale: string;
    ruleEditorOpen = false;

    appointments: AppointmentTimeSlot[];

    @ViewChild("deleteSwal") public deleteSwal: SwalComponent;
    @ViewChild("confirmDeleteSwal") public confirmDeleteSwal: SwalComponent;
    @ViewChild("deleteRuleSwal") public deleteRuleSwal: SwalComponent;
    @ViewChild("confirmDeleteRuleSwal")
    public confirmDeleteRuleSwal: SwalComponent;
    @ViewChild("confirmRuleSavedSwal")
    public confirmRuleSavedSwal: SwalComponent;

    get canAddGroupRule() {
        return (
            this.rule.groups &&
            this.rule.groups.length > 0 &&
            this.rule.groups[this.rule.groups.length - 1].applicantGroupId &&
            this.rule.groups[this.rule.groups.length - 1].invitedGroupId
        );
    }

    get canSubmitRule() {
        return (
            this.rule.slots &&
            this.rule.slots.startDateTime &&
            this.rule.slots.endDateTime &&
            this.rule.slots.minutesInterval &&
            this.slotsCount > 0 &&
            this.rule.groups &&
            !this.rule.groups.some(
                (gr) => !gr.applicantGroupId || !gr.invitedGroupId
            )
        );
    }

    constructor(
        private route: ActivatedRoute,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        private dbGroups: DbGroupsProvider,
        private dbAppointments: DbAppointments,
        private translate: TranslateService
    ) {
        this.moduleId = this.route.params["_value"]["moduleId"];
        this.eventId = this.route.parent.params["_value"]["uid"];
        this.locale = this.dbAppointments.locale;
    }

    async ngOnInit(): Promise<void> {
        this.event = await this.getEvent();
        this.module = await this.dbAppointments
            .getModule(this.eventId, this.moduleId)
            .pipe(take(1))
            .toPromise();
        this.module.allowEmailNotif = this.module.allowEmailNotif ? this.module.allowEmailNotif : false;

        this.userLanguage = await this.getUserLanguage();
        this.eventTimezone = this.event.timezone
            .match(/\((UTC[^\)]*)\)/gm)[0]
            .replace("(", "")
            .replace(")", "");
        await this.loadGroups();
        this.dbAppointments
            .getTimeSlotsRules(this.eventId, this.moduleId)
            .subscribe((rules) => {
                this.rules = rules;
                this.loader = false;
            });
        this.dbAppointments
            .getAllAppointments(this.eventId, this.moduleId)
            .subscribe((appointments) => {
                this.appointments = appointments;
                this.loader = false;
            });
    }

    getDateTimeWithEventZone(isoDateTime: string) {
        return luxon.DateTime.fromISO(isoDateTime, {
            zone: this.eventTimezone,
        });
    }

    getEvent() {
        return new Promise((resolve, reject) => {
            this.dbEvent.getEvent(this.eventId, (event) => {
                resolve(event);
            });
        });
    }

    private async loadGroups() {
        return new Promise(async (resolve, reject) => {
            const groupModule = await this.dbGroups.getGroupEvent(this.eventId);
            const typeOrder = groupModule["orderGroups"];
            this.dbGroups.getGroups(groupModule.uid, typeOrder, (groups) => {
                this.groups = [
                    {
                        uid: AppointmentRuleGroups.ALL_USERS_ID,
                        name: this.translate.instant(
                            "comp.appointments.rule_editor.all_users_group"
                        ),
                    } as Group,
                    ...groups,
                ];
                this.rule.groups = [new AppointmentRuleGroups(null, null)];
                resolve(null);
            });
        });
    }

    async changeTimeSlotStatus(event: AppointmentTimeSlotStatusChangeEvent) {
        if (event.status === AppointmentTimeSlotStatus.TIME_SLOT_DISABLED) {
            const result = await this.deleteSwal.fire();
            if (!result.value) {
                return;
            }
        }
        await this.dbAppointments.setTimeSlotStatus(
            this.eventId,
            this.moduleId,
            event.timeSlots,
            event.status
        );
        if (event.status === AppointmentTimeSlotStatus.TIME_SLOT_DISABLED) {
            this.confirmDeleteSwal.fire();
        }
    }

    editRule(rule: AppointmentRule) {
        console.log(rule);
        this.ruleEditorOpen = true;
        this.rule = rule;
    }

    // get the language of the user.
    getUserLanguage() {
        return new Promise<string>((resolve, reject) => {
            this.global.getLanguage((language: string) => {
                resolve(language);
            });
        });
    }

    toggleRuleEditor() {
        this.ruleEditorOpen = !this.ruleEditorOpen;
        if (!this.ruleEditorOpen) {
            this.rule = new AppointmentRule(
                null,
                [new AppointmentRuleGroups(null, null)],
                false
            );
        }
    }

    async submitRule(rule: AppointmentRule) {
        if (rule.uid) {
            await this.dbAppointments.updateTimeSlotsRule(
                rule,
                this.eventId,
                this.moduleId
            );
            this.ruleEditorOpen = false;
        } else {
            await this.dbAppointments.createTimeSlotsRule(
                rule,
                this.eventId,
                this.moduleId
            );
        }
        this.rule = new AppointmentRule(
            null,
            [new AppointmentRuleGroups(null, null)],
            false
        );
        this.confirmRuleSavedSwal.fire();
    }

    async deleteRule(rule: AppointmentRule) {
        if (!rule.uid) {
            return;
        }
        const result = await this.deleteRuleSwal.fire();
        if (!result.value) {
            return;
        }
        await this.dbAppointments.deleteRule(
            rule.uid,
            this.eventId,
            this.moduleId
        );
        this.ruleEditorOpen = false;
        this.confirmDeleteRuleSwal.fire();
        this.rule = new AppointmentRule(
            null,
            [new AppointmentRuleGroups(null, null)],
            false
        );
    }

    saveAppointmentsExport(appointmentsExport: Blob) {
        saveAs(appointmentsExport, "appointments.xlsx");
    }

    changeAutoHidePendingAppointmentsAvailability() {
        this.module.autoHidePendingAppointmentsAvailability = !this.module
            .autoHidePendingAppointmentsAvailability;
        this.dbAppointments.updateModuleSettings(
            this.eventId,
            this.moduleId,
            "autoHidePendingAppointmentsAvailability",
            this.module.autoHidePendingAppointmentsAvailability
        );
    }

    changeHideAppointmentsOnTimeSlotDelete() {
        this.module.hideAppointmentsOnTimeSlotDelete = !this.module
            .hideAppointmentsOnTimeSlotDelete;
        this.dbAppointments.updateModuleSettings(
            this.eventId,
            this.moduleId,
            "hideAppointmentsOnTimeSlotDelete",
            this.module.hideAppointmentsOnTimeSlotDelete
        );
    }

    /**
     * Changing allowing feedback permission
     */
    changeAllowFeedback() {
        this.module.allowFeedback = !this.module.allowFeedback;
        this.dbAppointments.updateModuleSettings(
            this.eventId,
            this.moduleId,
            "allowFeedback",
            this.module.allowFeedback
        );
    }

    /**
     * Changing allowing feedback permission
     */
    changeAllowModificationFeedback() {
        this.module.allowModificationFeedback = !this.module
            .allowModificationFeedback;
        this.dbAppointments.updateModuleSettings(
            this.eventId,
            this.moduleId,
            "allowModificationFeedback",
            this.module.allowModificationFeedback
        );
    }

    changeallowEmailNotif(){
        this.module.allowEmailNotif = !this.module.allowEmailNotif
        this.dbAppointments.updateModuleSettings(
            this.eventId,
            this.moduleId,
            "allowEmailNotif",
            this.module.allowEmailNotif
        );
    }
}
