import { NameModule } from "./name-module";

export class eventCustomField {
    uid: string;
    name: NameModule;
    type: String;
    exibicao: String;
    edicao: string;
    value: any;
    order: number;
    icon: string;
    iconFamily: string;
    answerOptions: { [key: string]: NameModule };
    required: boolean;
    unique_edit: boolean;
    hide_field: boolean;


    constructor(name: NameModule, type: string, exibicao: string, edicao: string) {
        // this.uid = null;
        this.name = new NameModule(name.PtBR, name.EnUS, name.EsES, name.FrFR, name.DeDE);
        this.type = type;
        this.exibicao = exibicao;
        this.edicao = edicao;
        // this.value = '';
        this.required = false;
        this.unique_edit = false;
        this.hide_field = false;
    }
}