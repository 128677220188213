import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleCheckin extends Module {
    typeVision: number;
    access_group: Array<string>; // uid of the group that is allowed access to the module
    selected: boolean;
    visOnlyForLoggedUser?: boolean;


    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.CHECKIN, eventId, order, false, true, true)
        this.typeVision = typeVision;
        this.access_group = null;
        this.selected = true;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}
