import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/models/group';
import { AppointmentRuleGroups } from 'src/app/models/appointments';

@Component({
  selector: 'app-appointments-rule-groups-selection',
  templateUrl: './appointments-rule-groups-selection.component.html',
  styleUrls: ['./appointments-rule-groups-selection.component.scss']
})

export class AppointmentsRuleGroupsSelectionComponent implements OnInit {

  @Input()
  groups: Group[];

  @Input() groupRule: AppointmentRuleGroups = new AppointmentRuleGroups(null, null);
  @Input() canDelete: boolean;

  @Output()
  groupRuleChange = new EventEmitter<AppointmentRuleGroups>();
  @Output()
  delete = new EventEmitter();

  constructor() {
  }

  async ngOnInit() {
  }

  onDelete() {
    this.delete.emit();
  }

  onChange() {
    this.groupRuleChange.emit(this.groupRule);
  }

  getGroupById(groupId: string) {
    return this.groups.find(g => g.uid === groupId);
  }
}
