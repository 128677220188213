import {Question} from './quiz-question';
import { NameModule } from './name-module';

export class Quiz{
    title: NameModule;
    imgCapa: string = "";
    type: string;
    // max_responses: number;
    change_answer: boolean;
    active_timer: boolean;
    timer_questions: number;
    visibility: boolean;
    view_answered: boolean;
    icon: string;
    iconFamily: string;
    uid: string;
    question: Array<Question> = [];
    module_id: String; 
    references: any;
    order: number;
    weight: boolean;
    marker: boolean;
    totalQuestions: number;
    questionsNumberVisibility: boolean;
    includeImgToAnswers: boolean;
}
