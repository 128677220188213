import { Component, OnInit } from '@angular/core';
import { DbAdminTermsProvider } from 'src/app/providers/database/db-admin-terms';
import sweet from 'sweetalert2';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  providers: [DbAdminTermsProvider]
})
export class TermsOfUseComponent implements OnInit {
  terms: any = null;

  constructor(private dbTerms: DbAdminTermsProvider) {
    this.dbTerms.getTermsOfUse((data) => {
      this.terms = data;
    });
  }

  ngOnInit() {

  }

  saveTerms() {
    const terms = this.terms.replace(/href="/g, 'class="wysiwyg-link" href="');
    this.dbTerms.saveTermsOfUse(terms, (result) => {
    });
  }

}
