import { Module } from "./module";
import { Location } from "./../location";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";


export class ModuleLocation extends Module{
    locations: Array<Location>;
    orderLocations: string;
    visOnlyForLoggedUser?: boolean;

    constructor(name: NameModule, icon: string, eventId: string, visOnlyForLoggedUser?: boolean){
        super(name, icon, TypeModule.LOCATION, eventId, null, false, null, true)
        this.locations = [];
        this.orderLocations = 'asc';
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }

}