import { NameModule } from "./name-module";

export class Answer {
    uid: string;
    answer: NameModule;
    correct: boolean = false;
    weight: number;
    marker: string;
    createdAt: number;
    img: any;

    constructor() {
        this.answer = new NameModule('', '', '', '', '');
    }
}
