import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbAskQuestionProvider } from 'src/app/providers/database/db-ask-question';
import { Router, ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { Session } from 'src/app/models/session';
import { Question } from 'src/app/models/ask-question';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { take } from 'rxjs/operators';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';

declare let $: any;
type AOA = Array<Array<any>>;

interface loadingStatus {
    visibility: boolean,
    moderation: boolean,
    anonymous: boolean
}

@Component({
    selector: 'app-ask-question',
    templateUrl: './ask-question.component.html',
    styleUrls: ['./ask-question.component.scss']
})
@NgModule({
    declarations: [
        GetNameSession
    ],
})
export class AskQuestionComponent implements OnInit {
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmptySwal') public errorEmptySwal: SwalComponent;
    @ViewChild('notFountSwal') public notFountSwal: SwalComponent;
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('deleteAskQuestionSwal') public deleteAskQuestionSwal: SwalComponent;
    @ViewChild('clearQuestionSwal') public clearQuestionSwal: SwalComponent;
    @ViewChild('clearAllQuestionSwal') public clearAllQuestionSwal: SwalComponent;
    @ViewChild('clearQuestionSessionSwal') public clearQuestionSessionSwal: SwalComponent;
    @ViewChild('clearAllQuestionSessionSwal') public clearAllQuestionSessionSwal: SwalComponent;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    }

    loader: boolean = false;
    onReorderShow: boolean = false;
    loaderOrder: boolean = false;

    public userLanguage: string // get the language of the user.
    public eventLanguage: string // get the language of the event.
    languages: Languages = null //event languages
    activeLanguage: string = null;

    moduleId: string = null
    eventId: string = null;
    nameError: boolean;
    public sessions: Array<Session> = [];

    createQuestionName: NameModule = new NameModule('', '', '', '', '');
    createQuestionVisibility: boolean = true;
    createQuestionModerate: boolean = false;
    createQuestionAnonymous: boolean = true;

    editQuestionId: string = null;
    editQuestionIndex: number = null;
    editQuestionName: NameModule = new NameModule('', '', '', '', '');
    editQuestionVisibility: boolean = true;
    editQuestionAnonymous: boolean = false;
    editQuestionModerate: boolean = false;
    editQuestionOrder: number = null;

    deleteQuestionId: string = null;
    deleteQuestionIndex: number = null;

    clearQuestionId: string = null;
    clearQuestionSessionId: string = null;
    sessionModuleId: string = null;

    questions: Array<Question> = [];

    ExportQuestions: AOA = [[]];
    data: any;

    loaderCreate: boolean = false;

    sessionsLoading: loadingStatus = {
        visibility: false,
        moderation: false,
        anonymous: false
    }

    generalLoading: loadingStatus = {
        visibility: false,
        moderation: false,
        anonymous: false
    }

    constructor(
        private router: Router,
        private dbAskQuestion: DbAskQuestionProvider,
        private dbSchedule: DbScheduleProvider,
        private route: ActivatedRoute,
        private luxon: LuxonService,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        private dragula: DragulaService,
        private dt: DateTimeService
    ) {
    }

    ngOnInit() {
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        console.log("Module id gett: ", this.moduleId);
        this.getEvent()

        this.dragula.createGroup('bag-questions', this.dragulaOptions);
        // start dragula reorder bag
        this.dragula.dropModel('bag-questions').subscribe((value: any) => {
            this.onReorder(value);
        });

        this.getSessionsEvent();
        this.getQuestion();
        this.getUserLanguage()
        this.getEventLanguage()
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy('bag-questions');
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.questions = order.targetModel;
        for (let i = 0; i < (this.questions).length; ++i) {
            this.questions[i].order = i;
        }
    }

    saveNewOrder() {
        this.loaderOrder = true;

        this.dbAskQuestion.changeOrder(this.moduleId, this.questions, (result) => {
            if (result == true) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }

            this.loaderOrder = false;
            this.onReorderShow = false;
        })
    }

    // load user language
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    // load event language
    getEventLanguage() {
        this.dbEvent.getLanguagePrincipalEvent(this.eventId, (language) => {
            this.eventLanguage = language
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventLanguage = event.language;
            this.activeLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    getSessionsEvent() {
        this.dbSchedule.getSessionsEvent(this.eventId, (sessions) => {
            for (let session of sessions) {
                session.date = this.dt.getDate(
                    this.dt.convertTimeZone(session.date, this.dt.deviceTimeZone, "en-US")
                );

                session.startTime = this.dt.getTime(
                    this.dt.convertTimeZone(session.startTime, this.dt.deviceTimeZone, "en-US")
                );

                if (
                    session.endTime !== "" &&
                    session.endTime !== null &&
                    typeof session.endTime !== "undefined"
                ) {
                    session.endTime = this.dt.getTime(
                        this.dt.convertTimeZone(session.endTime, this.dt.deviceTimeZone, "en-US")
                    );
                }

                this.sessions.push(session);
            }
        })
    }

    getQuestion() {
        this.dbAskQuestion.getQuestions(this.moduleId, (questions) => {
            this.questions = questions;
        })
    }

    /**
     * @description invert the value of the provided field (boolean is expected) from a provided firebase firestore DocumentData
     * @param doc "question" or "session"
     * @param field 
     */
    reverseDocFieldStatus(doc: any, docType: string, field: string): void {
        let path: string;

        switch (docType) {
            case "question":
                path = `modules/${this.moduleId}/items/${doc.uid}`;
                break;
            case "session":
                path = `events/${this.eventId}/sessions/${doc.uid}`;
                break;
            default:
                return;
        }
        
        const obj: Object = {};
        obj[field] = !doc[field];
        
        this.dbAskQuestion
            .singleDocUpdate(path, obj)
            .then(() => { doc[field] = !doc[field]; })
            .catch((error) => { console.error(error) });
    }

    /**
     * @description change the value of the field 'visibility' inside all "instances of the module ask-questions" 
     * @param status true: enable, false: disable
     */
    changeGeneralVisibility(status: boolean) {
        this.generalLoading.visibility = true;
        this.dbAskQuestion.bulkUpdate(`modules/${this.moduleId}/items`, { visibility: status })
            .then(() => {
                this.questions.map((question) => { question.visibility = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.generalLoading.visibility = false; });
    }

    /**
     * @description change the value of the field 'moderate' inside all "instances of the module ask-questions" 
     * @param status true: enable, false: disable
     */
    changeGeneralModerate(status: boolean) {
        this.generalLoading.moderation = true;
        this.dbAskQuestion.bulkUpdate(`modules/${this.moduleId}/items`, { moderate: status })
            .then(() => {
                this.questions.map((question) => { question.moderate = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.generalLoading.moderation = false; });
    }

    /**
     * @description change the value of the field 'anonymous' inside all "instances of the module ask-questions" 
     * @param status true: enable, false: disable
     */
    changeGeneralAnonymous(status: boolean) {
        this.generalLoading.anonymous = true;
        this.dbAskQuestion.bulkUpdate(`modules/${this.moduleId}/items`, { anonymous: status })
            .then(() => {
                this.questions.map((question) => { question.anonymous = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.generalLoading.anonymous = false; });
    }

    /**
     * @description enable or disable the module 'askQuestions' inside all sessions, i.e. change the value of 
     * the field 'askQuestion' inside all sessions firebase firestore DocumentData 
     * @param status true: enable, false: disable
     */
    changeSessionsVisibility(status: boolean) {
        this.sessionsLoading.visibility = true;
        this.dbAskQuestion.bulkUpdate(`events/${this.eventId}/sessions`, { askQuestion: status })
            .then(() => {
                this.sessions.map((session) => { session.askQuestion = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.sessionsLoading.visibility = false });
    }

    /**
     * @description change the value of the field 'askModerate' inside all sessions firebase firestore DocumentData 
     * @param status true: enable, false: disable
     */
    changeSessionsModerate(status: boolean) {
        this.sessionsLoading.moderation = true;
        this.dbAskQuestion.bulkUpdate(`events/${this.eventId}/sessions`, { askModerate: status })
            .then(() => {
                this.sessions.map((session) => { session.askModerate = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.sessionsLoading.moderation = false });
    }

    /**
     * @description change the value of the field 'anonymous' inside all sessions firebase firestore DocumentData 
     * @param status true: enable, false: disable
     */
    changeSessionsAnonymous(status: boolean) {
        this.sessionsLoading.anonymous = true;
        this.dbAskQuestion.bulkUpdate(`events/${this.eventId}/sessions`, { anonymous: status })
            .then(() => {
                this.sessions.map((session) => { session.anonymous = status; });
            })
            .catch((error) => { console.error(error); })
            .finally(() => { this.sessionsLoading.anonymous = false });
    }

    createAsk() {
        const question: Question = {
            uid: null,
            name: this.fillEmptyLanguage(this.createQuestionName),
            visibility: this.createQuestionVisibility,
            moderate: this.createQuestionModerate,
            order: this.questions.length,
            anonymous: this.createQuestionAnonymous
        }

        this.dbAskQuestion.makeQuestion(this.moduleId, question, (question) => {
            this.questions.push(question);
            $('#newAsk').modal('toggle');
            this.clearForm();
            this.successSwal.fire();
        })
    }

    getEditAsk(questionId, index) {
        if (this.questions[index].uid == questionId) {
            this.editQuestionIndex = index;
            let name = this.questions[index].name;
            this.editQuestionName = new NameModule(name['PtBR'], name['EnUS'], name['EsES'], name['FrFR'], name['DeDE']);
            this.editQuestionModerate = this.questions[index].moderate;
            this.editQuestionVisibility = this.questions[index].visibility;
            this.editQuestionOrder = this.questions[index].order;
            this.editQuestionId = questionId;

            $('#editAsk').modal('toggle');
        }
    }

    editAsk() {
        const question: Question = {
            uid: this.editQuestionId,
            name: this.editQuestionName,
            visibility: this.editQuestionVisibility,
            moderate: this.editQuestionModerate,
            order: this.editQuestionOrder,
            anonymous: this.editQuestionAnonymous
        };

        this.dbAskQuestion.makeQuestion(this.moduleId, question, (question) => {
            if (question != null) {
                this.questions[this.editQuestionIndex] = question;
                $('#editAsk').modal('toggle');
                this.successSwal.fire();
            } else {
                $('#editAsk').modal('toggle');
                this.errorSwal.fire();
            }  
        });
    }

    fillEmptyLanguage(languagesObj) {
        let mainLanguageStr = null;

        switch (this.eventLanguage) {
            case 'pt_BR':
                mainLanguageStr = languagesObj['PtBR'];
                break;

            case 'en_US':
                mainLanguageStr = languagesObj['EnUS'];
                break;

            case 'es_ES':
                mainLanguageStr = languagesObj['EsES'];
                break;

            case 'fr_FR':
                mainLanguageStr = languagesObj['FrFR'];
                break;

            case 'de_DE':
                mainLanguageStr = languagesObj['DeDE'];
                break;
        }

        if (languagesObj['PtBR'] == '') {
            languagesObj['PtBR'] = mainLanguageStr;
        }

        if (languagesObj['EnUS'] == '') {
            languagesObj['EnUS'] = mainLanguageStr;
        }

        if (languagesObj['EsES'] == '') {
            languagesObj['EsES'] = mainLanguageStr;
        }

        if (languagesObj['FrFR'] == '') {
            languagesObj['FrFR'] = mainLanguageStr;
        }

        if (languagesObj['DeDE'] == '') {
            languagesObj['DeDE'] = mainLanguageStr;
        }

        return languagesObj;
    }

    getDeleteQuestion(questionId, index) {
        this.deleteQuestionId = questionId;
        this.deleteQuestionIndex = index;
        this.deleteAskQuestionSwal.fire();
    }

    /**
     * Delete an item for ask a question
     */
    deleteQuestion() {
        this.dbAskQuestion.deleteQuestion(this.moduleId, this.deleteQuestionId).subscribe((data) => {
            if (data) {
                this.questions.splice(this.deleteQuestionIndex, 1);
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        })
    }

    getQuestionClear(question) {
        this.clearQuestionId = question.uid;
        this.clearQuestionSwal.fire();
    }

    getQuestionClearSession(session) {
        this.clearQuestionSessionId = session.uid;
        this.sessionModuleId = session.moduleId;
        this.clearQuestionSessionSwal.fire();
    }

    clearQuestion() {
        this.dbAskQuestion.clearResultQuestion(this.moduleId, this.clearQuestionId).subscribe((data) => {
            console.log("Data: ", data);
            if (data) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        })
    }

    clearQuestionSession() {
        this.dbAskQuestion.clearResultQuestionSession(this.eventId, this.sessionModuleId, this.clearQuestionSessionId).subscribe((data) => {
            if (data) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        })
    }

    /**
     * Launch modal for all clear sessions questions
     */
    launchSwalClearAllSessionQuestion() {
        this.clearAllQuestionSessionSwal.fire();
    }

    /**
     * Clear all sessions questions votes
     */
    clearAllSessionQuestionsResults() {
        let listSessions = [];
        this.loader = true;
        for (let session of this.sessions) {
            listSessions.push({
                uid: session.uid,
                moduleId: session.moduleId
            });
        }
        this.dbAskQuestion.clearAllSessionsQuestionsResults(this.eventId, listSessions).pipe(
            take(1)
        ).subscribe((data) => {
            console.log("Data: ", data);
            this.successSwal.fire();
            this.loader = false;
        }, (error) => {
            this.errorSwal.fire();
            this.loader = false;
        })
    }

    /**
     * Launch modal for all clear item questions
     */
    launchSwalClearAllQuestion() {
        this.clearAllQuestionSwal.fire();
    }

    /**
     * Clear all questions votes
     */
    clearAllQuestionsResults() {
        let listQuestionsIds = [];
        this.loader = true;
        for (let question of this.questions) {
            listQuestionsIds.push(question.uid);
        }
        this.dbAskQuestion.clearAllQuestionsResults(this.moduleId, listQuestionsIds).pipe(
            take(1)
        ).subscribe((data) => {
            this.successSwal.fire();
            this.loader = false;
        }, (error) => {
            this.errorSwal.fire();
            this.loader = false;
        })
    }

    clearForm() {
        this.createQuestionName = new NameModule('', '', '', '', '');
    }

    clearFormEdit() {
        this.createQuestionName = new NameModule('', '', '', '', '');
    }

    prepareExportReportGeral(item) {
        $('#exportLoading').modal('toggle');

        this.dbAskQuestion.exportAskQuestionGeral(this.moduleId, item.uid, (data) => {
            $('#exportLoading').modal('toggle');

            if (data.code == 200) {
                this.ExportQuestions = [[
                    "Nome",
                    "Email",
                    "Questão",
                    "Votos",
                    "Data"
                ]];

                let result = data.result;

                result = result.sort(function (a, b) {
                    if (a.totalLikes < b.totalLikes) {
                        return 1;
                    }
                    if (a.totalLikes > b.totalLikes) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });

                result.forEach(element => {
                    this.ExportQuestions.push(this.prepareResult(element));
                });

                this.exportReport();

            } else if (data.status == 404) {
                this.notFountSwal.fire();

            } else {
                this.errorSwal.fire();
            }
        })
    }

    prepareExportReportSession(session) {
        $('#exportLoading').modal('toggle');

        this.dbAskQuestion.exportAskQuestion(session.moduleId, session.uid, (data) => {
            $('#exportLoading').modal('toggle');

            if (data.code == 200) {
                this.ExportQuestions = [[
                    "Nome",
                    "Email",
                    "Questão",
                    "Votos",
                    "Data"
                ]];

                let result = data.result;

                result = result.sort(function (a, b) {
                    if (a.totalLikes < b.totalLikes) {
                        return 1;
                    }
                    if (a.totalLikes > b.totalLikes) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });

                result.forEach(element => {
                    this.ExportQuestions.push(this.prepareResult(element));
                });

                this.exportReport();

            } else if (data.status == 404) {
                this.notFountSwal.fire();

            } else {
                this.errorSwal.fire();
            }
        })
    }

    prepareResult(data) {
        let date = new Date(data.timestamp * 1000);
        let day = '0' + date.getDate();
        let month = '0' + date.getMonth() + 1;
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = '0' + date.getMinutes();
        let seconds = '0' + date.getSeconds();

        let formattedDate = day.substr(-2) + '/' + month.substr(-2) + '/' + year + ' - ' + hours + ':' +
            minutes.substr(-2) + ':' + seconds.substr(-2);

        let array = [
            data.userName,
            data.userEmail,
            data.question,
            data.totalLikes,
            formattedDate
        ];

        return array;
    }

    exportReport() {
        const wscols: XLSX.ColInfo[] = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { hidden: false } // hide column
        ];

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.ExportQuestions);

        /* TEST: column props */
        ws['!cols'] = wscols;

        /* TEST: row props */
        ws['!rows'] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), 'ask_question.xlsx');

        this.data = null;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
}
