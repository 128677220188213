import { Component, OnInit, ViewChild } from '@angular/core';
import { TypeLogin } from 'src/app/enums/type-login';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { Container } from 'src/app/models/container';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-create-container',
  templateUrl: './create-client-container.component.html',
  styleUrls: ['./create-client-container.component.scss']
})
export class CreateClientContainerComponent implements OnInit {

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;

  loginType: TypeLogin = 0;
  clients: Array<any> = [];
  container: Container = {
    uid: null,
    clientId: null,
    appName: null,
    loginPage: {
      type: TypeLogin.WITH_EMAIL_CONFIRM,
      eventId: null,
    },
    loginOptions: {
      fbLoginBtn: false,
      gLoginBtn: false,
      registeBtn: false,
      shortcodeBtn: true,
    },
    logo: null,
    logoSize: 'invent-logo',
    login1stBackground: '',
    login2ndBackground: '',
    color: null,
    termsOfUse: '',
    privacyTerms: '',
    valid_btn_bg_color: '#0ACB15',
    valid_btn_color: '#ffffff',
    login_btn_bg_color: '#3C11DD',
    login_btn_color: '#FFFFFF',
    text_color_1st_login: '#ffffff',
    text_color_2nd_login_desktop: '#201157',
    text_color_2nd_login_mobile: '#ffffff',
    label_login_color: '#908B99',
    color_blue_default: '#2973A2',
    color_blue_default_contrast: '#ffffff',
    color_blue_default_shade: '#2083c0',
    color_blue_default_tint: '#639EBC'
  };
  displaySelectEvent: boolean = false;
  keyword = 'name';
  eventLoader: boolean = false;
  saveLoader: boolean = false;
  events: Array<Event> = [];
  allowPublicOptions: boolean = false
  blankField: boolean = false;
  nameFile: string = null;
  firstImgBackgroundName: string = null;
  secondImgBackgroundName: string = null;
  displayImage: any = null;
  displayFirstImgBackground: any = null;
  displaySecondImgBackground: any = null;
  selectedEvent: Event = null;
  noEvents: boolean = false;
  blockBtnSave: boolean = false;
  clientId: string = null;
  clientName: string = '';
  constructor(
    private dbAdmin: DbAdminUserProvider,
    private dbEvents: DbEventsProvider,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.clientId = this.route.snapshot.params['clientId'];
    this.clientName = localStorage.getItem('clientName');

    this.container.clientId = this.clientId;
    this.container.clientName = this.clientName;
  }

  ngOnInit() {

  }

  // get image from input file
  getPicture($ev) {
    this.nameFile = $ev.target.value;
    this.displayImage = $ev.srcElement.files[0]
  }

  // get background images from input file
  getBackgrounds($ev, order) {
    if(order === '1st') {
      this.firstImgBackgroundName = $ev.target.value;
      this.displayFirstImgBackground = $ev.srcElement.files[0]
    }
    else if(order === '2nd'){
      this.secondImgBackgroundName = $ev.target.value;
      this.displaySecondImgBackground = $ev.srcElement.files[0]
    }
  }

  changeLoginType(ev) {
    this.loginType = ev.target.value;
    if (this.loginType != TypeLogin.PUBLIC_EVENT_HOME) { this.displaySelectEvent = false; this.noEvents = false; }
  }

  // when client is selected on input
  selectEvent(item) {
    // do something with selected item
    this.container.clientId = item.uid;
    this.container.clientName = item.name;
  }

  // when input is cleared
  inputCleared() {
    this.container.clientId = null;
    this.container.loginPage.type = null;
  }

  loginTypeEventChange() {
    if (this.container.loginPage.type == TypeLogin.PUBLIC_EVENT_HOME) {
      this.eventLoader = true;
      this.getEvents();
    } else {
      this.events = [];
      this.blockBtnSave = false;
      this.eventLoader = false;
      this.displaySelectEvent = false;
      this.container.loginPage.eventId = null;
      this.allowPublicOptions = false;
      this.container.loginOptions.fbLoginBtn = false;
      this.container.loginOptions.gLoginBtn = false;
    }
  }

  selectEventClient() {
    this.dbEvents.getEvent(this.container.loginPage.eventId, (event: Event) => {
      this.selectedEvent = event;
      if (event.visibility) {
        this.allowPublicOptions = true;
      }
    });
  }

  getEvents() {
    this.dbEvents.getEventsByClient(this.container.clientId, (events: Array<Event>) => {
      if (events.length >= 1) {
        this.events = events;
        this.displaySelectEvent = true;
        this.eventLoader = false;
      } else {
        this.blockBtnSave = true;
        this.noEvents = true;
        this.displaySelectEvent = false;
        this.eventLoader = false;
      }
    });
  }

  createContainer() {
    this.blankField = false;
    if (this.container.clientId !== null
      && this.container.appName !== null
      && this.container.loginPage.type !== null
      && this.displayImage !== null
      && this.displayFirstImgBackground !== null
      && this.displaySecondImgBackground !== null
      && this.container.logoSize !== null
    ) {
      this.saveLoader = true;
      this.container.clientId = this.clientId;
      this.dbAdmin.createContainer(this.container, this.displayImage,this.displayFirstImgBackground, this.displaySecondImgBackground, (status) => {
        if (status) {
          this.successSwal.fire();
          this.saveLoader = false;
        } else {
          this.errorSwal.fire();
          this.saveLoader = false;
        }
      })
    } else {
      this.blankField = true;
    }
  }

  redirectList() {
    this.router.navigate([`/dash/containers/${this.clientId}`]);
  }
}
