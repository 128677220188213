import { Module } from "../../../../models/modules/module";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DragulaService } from "ng2-dragula";
import { DragulaOptions } from "dragula";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DbManagerModuleProvider } from "src/app/providers/database/db-manager-module";
import { ActivatedRoute, Router } from "@angular/router";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { AvailableModules } from "src/app/models/available-modules";
import { TypeModule } from "src/app/enums/type-module";
import { ModuleAttendee } from "src/app/models/modules/module-attendees";
import { PathIcons } from "src/app/paths/path-icons";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { ModuleSpeaker } from "src/app/models/modules/module-speaker";
import { ModuleWidget } from "src/app/models/modules/module-widget";
import { TypeVisionModule } from "src/app/enums/type-vision-module";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { Group } from "src/app/models/group";
import { ModuleDocuments } from "src/app/models/modules/module-documents";
import { ModuleGallery } from "src/app/models/modules/module-gallery";
import { MenuIcons, icons } from "../../../../models/menu-icons";
import { DbPersonalScheduleProvider } from "src/app/providers/database/db-personal-schedule";
import { ModulePersonalAgenda } from "src/app/models/modules/module-personal-agenda";
import { DbSurveyProvider } from "src/app/providers/database/db-survey";
import { ModuleGeneric } from "src/app/models/modules/module-generic";
import { ModuleFeedNews } from "src/app/models/modules/module-feed-news";
import { Event } from "../../../../models/event";
import { ModuleInfobooth } from "src/app/models/modules/module-infoobooth";
import { ModuleCustomPage } from "src/app/models/modules/module-custom-page";
import { DbQuizProvider } from "src/app/providers/database/db-quiz";
import { DbTrainingProvider } from "src/app/providers/database/db-training";
import { DbAskQuestionProvider } from "src/app/providers/database/db-ask-question";
import { ModuleExternalLink } from "src/app/models/modules/module-external-link";
import { NameModule } from "src/app/models/name-module";
import { GlobalService } from "src/app/providers/global/global.service";
import { TranslateService } from "@ngx-translate/core";
import { FormatedEventLanguageService } from "src/app/providers/formated-event-language/formated-event-language.service";
import { ModuleGroupDiscussion } from "src/app/models/modules/module-group-discussion";

declare var $: any;

@Component({
    selector: "app-manage-modules",
    templateUrl: "./manage-modules.component.html",
    styleUrls: ["./manage-modules.component.scss"],
})
export class ManageModulesComponent implements OnInit {
    iconsImg: MenuIcons[] = icons;
    customIconFile = null;
    base64CustomIcon: string = null;
    imageType: string = null;
    selectedIcon: string;
    selectedIconFamily: string;
    selectedIconCreate: string = null;
    selectedIconCreateFamily: string;
    selectedEditIcon: string;
    selectedEditIconFamily: string;
    modules: Array<any> = [];

    availableModules: AvailableModules = new AvailableModules();

    moduleRemove: any = null;
    onReorderShow: boolean = false;

    loaderGeneral: boolean = false;
    loaderEditModule: boolean = false;
    loaderCreateModule: boolean = false;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    };

    eventId: string = this.route.parent.params["_value"]["uid"];
    event: Event = null;
    managerModuleId = this.route.params["_value"]["moduleId"];
    public managerModule = null;

    // array of event languages.
    languages = [
        {
            value: "pt_BR",
            name: this.translateService.instant("comp.event_info.portuguese"),
        },
        {
            value: "en_US",
            name: this.translateService.instant("comp.event_info.english"),
        },
        {
            value: "es_ES",
            name: this.translateService.instant("comp.event_info.spanish"),
        },
        {
            value: "fr_FR",
            name: this.translateService.instant("comp.event_info.french"),
        },
        {
            value: "de_DE",
            name: this.translateService.instant("comp.event_info.german"),
        },
    ];

    // CREATE MODULE
    public formGroupCreate: FormGroup;

    // EDIT MODULE
    public formGroupEdit: FormGroup;

    typeChosenModule: number = -1;
    visionChosenCreateModule: number = null;

    // types modules
    module_event = TypeModule.EVENT;
    module_attendee = TypeModule.ATTENDEE;
    module_speaker = TypeModule.SPEAKER;
    module_schedule = TypeModule.SCHEDULE;
    module_widget = TypeModule.WIDGETS;
    module_survey = TypeModule.SURVEY;
    module_quiz = TypeModule.QUIZ;
    module_training = TypeModule.TRAINING;
    module_ask_question = TypeModule.ASK_QUESTION;
    module_documents = TypeModule.DOCUMENT;
    module_gallery = TypeModule.GALLERY;
    module_news_feed = TypeModule.NEWS_FEED;
    module_infobooth = TypeModule.INFOBOOTH;
    module_custom_pages = TypeModule.CUSTOM_PAGE;
    module_checkin = TypeModule.CHECKIN;
    module_maps = TypeModule.MAPS;
    module_ranking = TypeModule.RANKING;
    module_interactivity = TypeModule.INTERACTIVITY;
    module_personal_schedule = TypeModule.PERSONALSCHEDULE;
    module_external_link = TypeModule.EXTERNAL_LINK;
    module_gamification = TypeModule.GAMING;
    module_group_discussion = TypeModule.NETWORKING;

    // types vision module
    vision_global = TypeVisionModule.GLOBAL_VISION;
    vision_divided_by_groups = TypeVisionModule.DIVIDED_BY_GROUPS;
    vision_group_vision = TypeVisionModule.GROUP_VISION;
    vision_group_access_permission = TypeVisionModule.GROUP_ACCESS_PERMISSION;
    widgetVisForLoggedUsers = false;
    widgetEditVisForLoggedUsers: boolean;

    // groups
    moduleGroupId: string = null;
    groups: Array<Group> = [];

    // GROUPS FILTER
    selectedGroupCreate: Array<Group> = [];
    filteredListGroupsCreate = [];
    queryGroupCreate = "";
    errorFormGroupCreate: boolean = false;

    selectedGroupEdit: Array<Group> = [];
    filteredListGroupsEdit = [];
    queryGroupEdit = "";
    errorFormGroupEdit: boolean = false;

    errorRequiredModuleName: boolean = false;
    errorRequiredTypeModule: boolean = false;
    errorRequiredIconModule: boolean = false;
    errorRequiredExternalLink: boolean = false;

    moduleEdit;
    typeModuleEdit = null;
    visionModuleEdit = null;

    homePage: string = null;
    loaderChangeHome: boolean = false;
    moduleType: string = "null";
    moduleTypeVision: string = "-1";

    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;

    // survey
    listSurveys: Array<any> = [];
    loaderSurvey: boolean = false;
    surveyRedirectSelect: string = "-1";
    surveyRequiredSelect: boolean = false;
    moduleIdSurvey: string = null;

    // quiz
    listQuizes: Array<any> = [];
    moduleIdQuiz: string = null;
    quizRedirectSelect: string = "-1";
    quizRequiredSelect: boolean = false;

    // training
    listTrainings: Array<any> = [];
    moduleIdTraining: string = null;
    trainingRedirectSelect: string = "-1";
    trainingRequiredSelect: boolean = false;

    // ask question
    listQuestions: Array<any> = [];
    moduleIdAskQuestion: string = null;
    askQuestionRedirectSelect: string = "-1";
    askQuestionRequiredSelect: boolean = false;

    constructor(
        private dragula: DragulaService,
        private dbModule: DbManagerModuleProvider,
        private dbGroups: DbGroupsProvider,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private dbPersonalSchedule: DbPersonalScheduleProvider,
        private dbSurvey: DbSurveyProvider,
        private dbQuiz: DbQuizProvider,
        private dbTraining: DbTrainingProvider,
        private dbAskQuestion: DbAskQuestionProvider,
        private translateService: TranslateService,
        public formatedLanguage: FormatedEventLanguageService
    ) {
        dragula.createGroup("bag-manage-module", this.dragulaOptions);

        // Initializes formGroup, formGroupCreate
        this.formGroupCreate = this.fb.group({
            module_name_pt_br: [""],
            module_name_en_us: [""],
            module_name_es_es: [""],
            module_name_fr_fr: [""],
            module_name_de_de: [""],
            external_link: [null],
            open_link_behavior: "in-app",
        });

        this.formGroupEdit = fb.group({
            module_name_pt_br: [""],
            module_name_en_us: [""],
            module_name_es_es: [""],
            module_name_fr_fr: [""],
            module_name_de_de: [""],
            module_external_link: [null],
            open_link_behavior: "in-app",
        });
    }

    ngOnInit() {
        this.getManagerModule();
        this.getEvent();
        this.getModules();
        this.getGroups();

        // start dragula reorder bag
        this.dragula.dropModel("bag-manage-module").subscribe((value: any) => {
            this.onReorder(value);
        });
    }

    // get manager module
    getManagerModule() {
        this.dbModule.getModule(this.managerModuleId, (module) => {
            this.managerModule = module;
        });
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            if (typeof event !== "undefined" && event !== null) {
                this.event = event;
                this.homePage = event.homePage;
                this.changedHomePage = this.homePage;
                this.availableModules = event.availableModules; // modules availables to add
                this.formatedLanguage.convertLangFormat(event.language);
                console.log(event.visibility);

                // sort array of languages by placing the main language at the top.
                this.sortArrayLanguages();
            }
        });
    }

    // sort array of languages by placing the main language at the top.
    sortArrayLanguages() {
        const index = this.languages
            .map(function (e) {
                return e["value"];
            })
            .indexOf(this.event.language);

        // put the main language at index zero of the array.
        if (index > 0) {
            const aux = this.languages[0];
            this.languages[0] = this.languages[index];
            this.languages[index] = aux;
        }
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.modules = order.targetModel;
        for (let i = 0; i < this.modules.length; ++i) {
            this.modules[i].order = i;
        }
    }

    // save new order of modules in Firestore DB
    saveChanges() {
        this.loaderGeneral = true;
        console.log("Modules: ", this.modules);
        this.dbModule
            .updateModules(this.modules)
            .then((success) => {
                this.onReorderShow = false;
                this.loaderGeneral = false;

                /* case success remove display of btn to save new order and display modal success*/
                this.successSwal.fire();
            })
            .catch((error) => {
                this.onReorderShow = false;
                this.loaderGeneral = false;

                // case error, display modal error
                this.errorSwal.fire();
            });
    }

    // modules of the event
    getModules() {
        this.dbModule.getModules(this.eventId, (modules) => {
            this.modules = [];
            for (let module of modules) {
                if (module.type !== TypeModule.SESSION_FEEDBACK) {
                    this.modules.push(module);
                }
            }
            this.getModuleLocation();
        });
    }

    getModuleLocation() {
        this.dbEvent.getModuleLocation(this.eventId, (moduleLocation) => {
            this.modules.push(moduleLocation);
        });
    }

    // load groups event
    getGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (data) => {
            this.groups = data.groups;
            this.moduleGroupId = data.moduleId;
        });
    }

    // create module
    async createModule(data) {
        this.errorRequiredModuleName = false;
        this.errorRequiredTypeModule = false;
        this.errorRequiredIconModule = false;
        this.errorRequiredExternalLink = false;
        let isValid = true;

        // form data validation

        // verify the name
        if (
            this.event.languages.PtBR &&
            (data.module_name_pt_br === null ||
                data.module_name_pt_br.length === "")
        ) {
            this.errorRequiredModuleName = true;
            isValid = false;
        }

        if (
            this.event.languages.EnUS &&
            (data.module_name_en_us === null ||
                data.module_name_en_us.length === "")
        ) {
            this.errorRequiredModuleName = true;
            isValid = false;
        }

        if (
            this.event.languages.EsES &&
            (data.module_name_es_es === null ||
                data.module_name_es_es.length === "")
        ) {
            this.errorRequiredModuleName = true;
            isValid = false;
        }

        if (
            this.event.languages.FrFR &&
            (data.module_name_fr_fr === null ||
                data.module_name_fr_fr.length === "")
        ) {
            this.errorRequiredModuleName = true;
            isValid = false;
        }

        if (
            this.event.languages.DeDE &&
            (data.module_name_de_de === null ||
                data.module_name_de_de.length === "")
        ) {
            this.errorRequiredModuleName = true;
            isValid = false;
        }

        // checks the module type
        if (this.typeChosenModule <= -1 || this.typeChosenModule === null) {
            this.errorRequiredTypeModule = true;
            isValid = false;
        }

        // check the icon
        if (this.selectedIconCreate === null) {
            this.errorRequiredIconModule = true;
            isValid = false;
        }

        // if the module type is external link, check the link.
        if (this.typeChosenModule === TypeModule.EXTERNAL_LINK) {
            if (data.external_link === null || data.external_link === "") {
                this.errorRequiredExternalLink = true;
                isValid = false;
            }
        }

        if (isValid) {
            this.loaderCreateModule = true;

            let newModule = null;
            let order = this.modules.length;
            // const name = data.module_name;
            const name = new NameModule(
                data.module_name_pt_br,
                data.module_name_en_us,
                data.module_name_es_es,
                data.module_name_fr_fr,
                data.module_name_de_de
            );
            const icon = this.selectedIconCreate;
            let access_groups = {};
            let error = false;

            // if no type of view is chosen, by default the module receives the global view
            if (this.visionChosenCreateModule === null) {
                this.visionChosenCreateModule = this.vision_global;
            }

            // receive the access groups and add them to a json object
            for (const group of this.selectedGroupCreate) {
                const groupId = group.uid;
                access_groups[groupId] = group;
                // adds the group module
                access_groups[groupId].moduleId = this.moduleGroupId;
            }

            // module attendee
            if (this.typeChosenModule === this.module_attendee) {
                newModule = new ModuleAttendee(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
                newModule["allow_chat"] = this.event.allow_chat;
            }

            // module schedule
            if (this.typeChosenModule === this.module_schedule) {
                newModule = new ModuleSchedule(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;

                // verifies whether the personal agenda module already exists in the event. otherwise, create a new module.
                const totalModulesAgendaPersonal =
                    await this.dbPersonalSchedule.contModulesPersonalAgendaEvent(
                        this.eventId
                    );
                if (totalModulesAgendaPersonal <= 0) {
                    const modulePersonalAgenda = new ModulePersonalAgenda(
                        new NameModule(
                            "Agenda Pessoal",
                            "Personal agenda",
                            "Agenda personal",
                            "Agenda personnel",
                            "Persönliche Agenda"
                        ),
                        PathIcons.icon_calendar_today,
                        this.eventId,
                        order + 1,
                        this.widgetVisForLoggedUsers
                    );
                    this.dbModule.createModule(modulePersonalAgenda);
                }
            }

            //module speaker
            if (this.typeChosenModule === this.module_speaker) {
                newModule = new ModuleSpeaker(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module widget
            if (this.typeChosenModule === this.module_widget) {
                newModule = new ModuleWidget(
                    name,
                    icon,
                    this.eventId,
                    this.visionChosenCreateModule,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module documents
            if (this.typeChosenModule === this.module_documents) {
                newModule = new ModuleDocuments(
                    name,
                    icon,
                    this.eventId,
                    this.visionChosenCreateModule,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module gallery
            if (this.typeChosenModule === this.module_gallery) {
                newModule = new ModuleGallery(
                    name,
                    icon,
                    this.eventId,
                    this.visionChosenCreateModule,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module news feed
            if (this.typeChosenModule === this.module_news_feed) {
                newModule = new ModuleFeedNews(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module infobooth
            if (this.typeChosenModule === this.module_infobooth) {
                newModule = new ModuleInfobooth(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module custom pages
            if (this.typeChosenModule === this.module_custom_pages) {
                newModule = new ModuleCustomPage(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            // module external link
            if (this.typeChosenModule === this.module_external_link) {
                const behavior = data.open_link_behavior; // do we open external link in-app or using sf
                const link = `${data.external_link}`;
                newModule = new ModuleExternalLink(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    link,
                    behavior,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            //module survey - specific survey
            if (this.typeChosenModule === this.module_survey) {
                newModule = new ModuleGeneric(
                    name,
                    icon,
                    this.eventId,
                    order,
                    this.surveyRedirectSelect,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
                if (this.surveyRedirectSelect == "-1") {
                    error = true;
                    this.surveyRequiredSelect = true;
                }
            }

            //module quiz - specific quiz
            if (this.typeChosenModule === this.module_quiz) {
                newModule = new ModuleGeneric(
                    name,
                    icon,
                    this.eventId,
                    order,
                    this.quizRedirectSelect,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
                if (this.quizRedirectSelect == "-1") {
                    error = true;
                    this.quizRequiredSelect = true;
                }
            }

            //module training - specific training
            if (this.typeChosenModule === this.module_training) {
                newModule = new ModuleGeneric(
                    name,
                    icon,
                    this.eventId,
                    order,
                    this.trainingRedirectSelect,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
                if (this.trainingRedirectSelect == "-1") {
                    error = true;
                    this.trainingRequiredSelect = true;
                }
            }

            //module askQuestion - specific askQuestion
            if (this.typeChosenModule === this.module_ask_question) {
                newModule = new ModuleGeneric(
                    name,
                    icon,
                    this.eventId,
                    order,
                    this.askQuestionRedirectSelect,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
                if (this.askQuestionRedirectSelect == "-1") {
                    error = true;
                    this.askQuestionRequiredSelect = true;
                }
            }

            //module group discussion
            if (this.typeChosenModule === this.module_group_discussion) {
                newModule = new ModuleGroupDiscussion(
                    name,
                    icon,
                    this.visionChosenCreateModule,
                    this.eventId,
                    order,
                    this.widgetVisForLoggedUsers
                );
                newModule.access_groups = access_groups;
                newModule["iconFamily"] = this.selectedIconCreateFamily;
            }

            if (newModule !== null && error == false) {
                this.dbModule
                    .createModule(newModule)
                    .then((success) => {
                        this.loaderCreateModule = false;
                        $("#addNew").modal("toggle");
                        this.successSwal.fire();
                        this.clearModalCreateModule();
                        this.getModules();
                    })
                    .catch((error) => {
                        this.loaderCreateModule = false;
                        this.errorSwal.fire();
                    });
            } else {
                this.loaderCreateModule = false;
            }
        }
    }

    switchVisibility(module: any) {
        module.visOnlyForLoggedUser = !module.visOnlyForLoggedUser;
        this.dbModule.updateModule(module).then((success) => {
            // console.log(success);
        });
    }

    clearModalCreateModule() {
        // reset formGroupCreate
        this.formGroupCreate.reset();

        // reset icon
        this.selectedIconCreate = null;
        this.selectedIconCreateFamily = null;

        // reset selects
        this.moduleType = "null";
        this.moduleTypeVision = "-1";

        // reset groups
        this.selectedGroupCreate = [];
        this.filteredListGroupsCreate = [];
        this.queryGroupCreate = "";

        // case custom icon imported
        this.customIconFile = null;
        this.imageType = null;
        this.base64CustomIcon = null;
        (<HTMLInputElement>document.getElementById("selecao-arquivo")).value =
            "";
    }

    setIconCreate(index) {
        this.selectedIconCreate = this.iconsImg[index].icon;
        this.selectedIconCreateFamily = this.iconsImg[index].family;
        this.base64CustomIcon = null;
        this.customIconFile = null;
        this.imageType = null;
        $(".dropdown-menu").removeClass("show");
    }

    // receives the type of module chosen by the user.
    selectTypeModule(option) {
        this.typeChosenModule = parseInt(option);

        if (this.typeChosenModule == this.module_survey) {
            this.loaderSurvey = true;
            this.getSurveys();
        } else if (this.typeChosenModule == this.module_quiz) {
            this.loaderSurvey = true;
            this.getQuiz();
        } else if (this.typeChosenModule == this.module_training) {
            this.loaderSurvey = true;
            this.getTraining();
        } else if (this.typeChosenModule == this.module_ask_question) {
            this.loaderSurvey = true;
            this.getAskQuestions();
        }
        // clears modal data

        this.selectedGroupCreate = [];
        this.filteredListGroupsCreate = [];
        this.queryGroupCreate = "";
    }

    // receives the type of view chosen by the user.
    selectVisonModuleCreate(option) {
        this.visionChosenCreateModule = parseInt(option);

        // clears modal data
        this.selectedGroupCreate = [];
        this.filteredListGroupsCreate = [];
        this.queryGroupCreate = "";
    }

    selectVisionModuleEdit(option) {
        this.visionModuleEdit = parseInt(option);
        if (this.visionModuleEdit === this.vision_group_access_permission) {
            this.selectedGroupEdit = [];

            for (let index in this.moduleEdit.access_groups) {
                this.selectedGroupEdit.push(
                    this.moduleEdit.access_groups[index]
                );
            }
        }
    }

    // use custom icon image
    importCustomIcon($ev) {
        this.customIconFile = null;
        this.imageType = null;
        this.base64CustomIcon = null;
        this.customIconFile = $ev.srcElement.files[0];
        this.imageType = this.customIconFile.type;
        if (this.customIconFile !== null) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.customIconFile);
        }
    }

    // handle image to base64 to display for use before upload to storage
    handleReaderLoaded(e) {
        let imgType: string;

        if (this.imageType == "image/gif") {
            imgType = "data:image/gif;base64,";
        } else if (this.imageType == "image/png") {
            imgType = "data:image/png;base64,";
        } else if (this.imageType == "image/jpg") {
            imgType = "data:image/jpg;base64,";
        } else {
            imgType = "data:image/jpeg;base64,";
        }
        this.base64CustomIcon = imgType + btoa(e.target.result);
        this.selectedIconCreate = this.base64CustomIcon;
        this.selectedIconCreateFamily = "custom-icon";
        this.selectedEditIcon = this.base64CustomIcon;
        this.selectedEditIconFamily = "custom-icon";
    }

    setIcon(index: number) {
        this.selectedEditIcon = this.iconsImg[index].icon;
        this.selectedEditIconFamily = this.iconsImg[index].family;
        this.base64CustomIcon = null;
        this.imageType = null;
        this.customIconFile = null;
        $(".dropdown-menu").removeClass("show");
    }

    // get module for edit
    getModuleEdit(module) {
        this.moduleEdit = module;

        // takes the general module information that will be displayed in modal edit module.
        this.selectedEditIcon = this.moduleEdit["icon"];
        this.selectedEditIconFamily = this.moduleEdit["iconFamily"];
        if (this.selectedEditIconFamily == "custom-icon") {
            this.base64CustomIcon = this.moduleEdit["icon"];
        }

        // get type module
        this.typeModuleEdit = this.moduleEdit["type"];

        // start formGroupEdit
        let url = null;
        if (this.moduleEdit.type === TypeModule.EXTERNAL_LINK) {
            // url = this.moduleEdit["link"].split("https://")[1];
            url = this.moduleEdit["link"];
        }

        this.formGroupEdit.patchValue({
            module_name_pt_br: this.moduleEdit["name"]["PtBR"],
            module_name_en_us: this.moduleEdit["name"]["EnUS"],
            module_name_es_es: this.moduleEdit["name"]["EsES"],
            module_name_fr_fr: this.moduleEdit["name"]["FrFR"],
            module_name_de_de: this.moduleEdit["name"]["DeDE"],
            module_external_link: [url],
            open_link_behavior: module.open_link_behavior,
        });

        // verifies that the module type has visionGroup.
        if (
            this.typeModuleEdit !== this.module_event &&
            this.typeModuleEdit !== this.module_personal_schedule
        ) {
            // picks up the access modules
            if (
                typeof this.moduleEdit["access_groups"] !== "undefined" &&
                this.moduleEdit["access_groups"] !== null
            ) {
                this.selectedGroupEdit = [];

                for (const uid in module.access_groups) {
                    this.selectedGroupEdit.push(module.access_groups[uid]);
                }
            } else {
                this.selectedGroupEdit = [];
            }
            // takes the typeVision of the module
            if (
                typeof this.moduleEdit["typeVision"] !== "undefined" &&
                this.moduleEdit["typeVision"] !== null
            ) {
                this.visionModuleEdit = this.moduleEdit["typeVision"];
            } else {
                this.visionModuleEdit = this.vision_global;
            }

            // Visibility for logged users
            // if (
            //     typeof this.moduleEdit["visOnlyForLoggedUser"] !== "undefined" &&
            //     this.moduleEdit["visOnlyForLoggedUser"] !== null
            // ) {
            //     this.widgetEditVisForLoggedUsers = this.moduleEdit["visOnlyForLoggedUser"];
            // } else {
            //     this.widgetEditVisForLoggedUsers = false;
            // }
        }
        this.widgetEditVisForLoggedUsers = this.moduleEdit[
            "visOnlyForLoggedUser"
        ]
            ? this.moduleEdit["visOnlyForLoggedUser"]
            : false;
    }

    setEditIcon(index: number) {
        this.selectedEditIcon = this.iconsImg[index].icon;
        this.selectedEditIconFamily = this.iconsImg[index].family;
        $(".dropdown-menu").removeClass("show");
    }

    // edit module
    editModule(data) {
        this.loaderEditModule = true;

        // external link type module.
        if (this.moduleEdit.type === TypeModule.EXTERNAL_LINK) {
            if (data.module_external_link) {
                this.moduleEdit["link"] = `${data.module_external_link}`;
                this.moduleEdit["open_link_behavior"] = data.open_link_behavior;
                this.moduleEdit["viewBackOffice"] = this.moduleEdit["link"];
                this.moduleEdit["viewApp"] = this.moduleEdit["link"];
            }
        }

        // get the general information about modal edit module.
        this.moduleEdit["name"]["PtBR"] = data.module_name_pt_br;
        this.moduleEdit["name"]["EnUS"] = data.module_name_en_us;
        this.moduleEdit["name"]["EsES"] = data.module_name_es_es;
        this.moduleEdit["name"]["FrFR"] = data.module_name_fr_fr;
        this.moduleEdit["name"]["DeDE"] = data.module_name_de_de;

        this.moduleEdit["icon"] = this.selectedEditIcon;
        this.moduleEdit["iconFamily"] = this.selectedEditIconFamily;

        // verifies that the module type has group views.
        if (
            this.typeModuleEdit !== this.module_event &&
            this.typeModuleEdit !== this.module_personal_schedule
        ) {
            // get type vision.
            this.moduleEdit["typeVision"] = this.visionModuleEdit;

            // get access_groups
            this.moduleEdit["access_groups"] = {};

            if (
                this.moduleEdit["typeVision"] ===
                this.vision_group_access_permission
            ) {
                for (const group of this.selectedGroupEdit) {
                    this.moduleEdit["access_groups"][group.uid] = group;
                }
            }
        }

        // visibility for logged users
        this.moduleEdit.visOnlyForLoggedUser = this.widgetEditVisForLoggedUsers;

        // update module
        this.dbModule
            .updateModule(this.moduleEdit)
            .then((success) => {
                this.getModules();
                this.loaderEditModule = false;
                this.customIconFile = null;
                this.imageType = null;
                this.base64CustomIcon = null;
                (<HTMLInputElement>(
                    document.getElementById("selecao-arquivo")
                )).value = "";
                $("#edit").modal("toggle");
                this.successSwal.fire();
            })
            .catch((err) => {
                this.loaderEditModule = false;
                this.errorSwal.fire();
            });
    }

    // get module for delete
    getModuleRemove(module) {
        this.moduleRemove = module;
    }

    // remove module
    removeModule() {
        this.dbModule.removeModule(this.moduleRemove).then((_) => {
            this.getModules();
        });
    }

    // clear form after create or case cancel add
    clearForm() {}

    // leaves the module's habilitedBackoffice attribute to true
    enableBackoffice(index) {
        this.modules[index].habilitedBackOffice = true;
        this.onReorderShow = true;
    }

    // Disables Enabled Module Backoffice Enables
    disableBackoffice(index) {
        this.modules[index].habilitedBackOffice = false;
        this.onReorderShow = true;
    }

    // leaves the module's habilitedApp attribute to true
    enableApp(index) {
        this.modules[index].habilitedApp = true;
        this.onReorderShow = true;
    }

    // Disables Enabled Module app Enables
    disableApp(index) {
        this.modules[index].habilitedApp = false;
        this.onReorderShow = true;
    }

    // filter groups
    filterGroupsCreate() {
        if (this.queryGroupCreate !== "") {
            this.filteredListGroupsCreate = this.groups.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroupCreate.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroupsCreate = [];
        }
    }

    filterGroupsEdit() {
        if (this.queryGroupEdit !== "") {
            this.filteredListGroupsEdit = this.groups.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroupEdit.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroupsEdit = [];
        }
    }

    // select track from filtered list
    selectGroupCreate(item) {
        if (this.selectedGroupCreate.length > 0) {
            const index = this.selectedGroupCreate.indexOf(item);
            if (index == -1) {
                this.selectedGroupCreate.push(item);
            }
        } else {
            this.selectedGroupCreate.push(item);
        }
        this.queryGroupCreate = "";
        this.filteredListGroupsCreate = [];
    }

    selectGroupEdit(item) {
        if (this.selectedGroupEdit.length > 0) {
            const index = this.selectedGroupEdit
                .map(function (e) {
                    return e.uid;
                })
                .indexOf(item.uid);

            if (index == -1) {
                this.selectedGroupEdit.push(item);
            }
        } else {
            this.selectedGroupEdit.push(item);
        }
        this.queryGroupEdit = "";
        this.filteredListGroupsEdit = [];
    }

    // remove selected location
    removeGroupCreate(item) {
        this.selectedGroupEdit.splice(this.selectedGroupEdit.indexOf(item), 1);
    }

    removeGroupEdit(item) {
        this.selectedGroupEdit.splice(this.selectedGroupEdit.indexOf(item), 1);
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy("bag-manage-module");
    }

    changedHomePage: string = null;
    changeHomePage() {
        this.loaderChangeHome = true;
        this.dbModule.changeHomePage(
            this.eventId,
            this.changedHomePage,
            (status) => {
                if (!status) {
                    // error
                    this.errorSwal.fire();
                    this.loaderChangeHome = false;
                } else {
                    $("#settings").modal("toggle");
                    this.homePage = this.changedHomePage;
                    this.successSwal.fire();
                    this.loaderChangeHome = false;
                }
            }
        );
    }

    getSurveys() {
        this.dbSurvey.getSurveyModuleAndSurveys(this.eventId, (result) => {
            this.moduleIdSurvey = result["moduleId"];
            this.listSurveys = result["surveys"];
            this.loaderSurvey = false;
        });
    }

    getQuiz() {
        this.dbQuiz.getQuizModuleAndQuizs(this.eventId, (result) => {
            this.moduleIdQuiz = result["moduleId"];
            this.listQuizes = result["quizs"];
            this.loaderSurvey = false;
        });
    }

    getTraining() {
        this.dbTraining.getTrainingModuleAndTrainings(
            this.eventId,
            (result) => {
                this.moduleIdTraining = result["moduleId"];
                this.listTrainings = result["trainings"];
                this.loaderSurvey = false;
            }
        );
    }

    selectNameOfQuestionsByLanguage(questions, language) {
        if (questions.length <= 0) {
            return questions;
        }

        if (language) {
            // Formatting ISO Language Code from pt_BR to PtBR
            language =
                language.charAt(0).toUpperCase() +
                language.slice(1).replace(/_/g, "").replace(/-/g, "");
        }

        const filteredAskQuestions = questions.map((question) => {
            return {
                ...question,
                name: question["name"][language] ?? question["name"]["EnUS"],
            };
        });

        return filteredAskQuestions;
    }

    getAskQuestions() {
        this.dbAskQuestion.getQuestionModuleAndAskItems(
            this.eventId,
            (result) => {
                this.moduleIdAskQuestion = result["moduleId"];
                this.listQuestions = this.selectNameOfQuestionsByLanguage(
                    result["questions"],
                    this.global.language
                );
                this.loaderSurvey = false;
            }
        );
    }

    // redireciona para o path do modulo passado como param, com exceção do modulo event (tipo == 12)
    accessModules(module: any) {
        if (module.type != 12) {
            this.router.navigate([module.viewBackOffice]);
        }
    }
}
