// modules
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
    AngularFirestoreModule,
    SETTINGS,
} from "@angular/fire/firestore";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AgmCoreModule } from "@agm/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { UiSwitchModule } from "ngx-ui-switch";
import { TagCloudModule } from "angular-tag-cloud-module";

// services
import { AuthService } from "../app/providers/auth/auth.service";
import { AuthGuardService } from "./providers/auth-guard/auth.guard";
import { LuxonService } from "./providers/luxon/luxon.service";
import { StorageService } from "./providers/storage/storage.service";
import { PlataformRoutersService } from "./providers/plataform-routes/plataform-routers.service";

// components
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";

import { environment } from "../environments/environment";

//pipes
// import { FilterPipe } from './pipes/filter.pipe';
import { GlobalService } from "./providers/global/global.service";
import { DragulaModule } from "ng2-dragula";
import { EmailProvider } from "./providers/email/email.service";
import { RegexProvider } from "./providers/regex/regex.service";
import { WherebyService } from "./providers/whereby/whereby.service";

import { AngularFireStorageModule } from "@angular/fire/storage";
import { RealtimeCheckinComponent } from "./realtime-checkin/realtime-checkin.component";
import { AvatarModule } from "ngx-avatar";
import { SharedModule } from "./shared/shared.module";
import { NotifiicationDateService } from "./providers/luxon/notification-date.service";
import { AskQuestionSessionModerateComponent } from "./ask-question-session-moderate/ask-question-session-moderate.component";
import { AskQuestionSessionResultComponent } from "./ask-question-session-result/ask-question-session-result.component";
import { QuizResultComponent } from "./quiz-result/quiz-result.component";
import { AskQuestionModerateComponent } from "./ask-question-moderate/ask-question-moderate.component";
import { AskQuestionResultComponent } from "./ask-question-result/ask-question-result.component";
import { WordCloudResultComponent } from "./word-cloud-result/word-cloud-result.component";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { RealtimeRankingComponent } from "./realtime-ranking/realtime-ranking.component";
import { TrainingResultComponent } from "./training-result/training-result.component";
import { FormatedEventLanguageService } from "./providers/formated-event-language/formated-event-language.service";
import { NgxPaginationModule } from "ngx-pagination";

// Ngrx part
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './shared/reducers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntegrationsService } from "./providers/integrations/integrations.service";
import { PrizeDrawResultComponent } from './prize-draw-result/prize-draw-result.component';

// translate module config
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RealtimeCheckinComponent,
        AskQuestionSessionModerateComponent,
        AskQuestionSessionResultComponent,
        QuizResultComponent,
        AskQuestionModerateComponent,
        AskQuestionResultComponent,
        WordCloudResultComponent,
        RealtimeRankingComponent,
        TrainingResultComponent,
        PrizeDrawResultComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        SweetAlert2Module.forRoot({}),
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyBIJS3RDjIkWMa4lSqZeAyqpK3y0C-9nuo",
            libraries: ["places"],
        }),
        DragulaModule.forRoot(),
        ImageCropperModule,
        AvatarModule,
        SharedModule,
        UiSwitchModule,
        TagCloudModule,
        NgxPaginationModule,

        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        StoreDevtoolsModule.instrument(),
        BrowserAnimationsModule,
    ],
    providers: [
        Title,
        AuthService,
        AuthGuardService,
        StorageService,
        LuxonService,
        NotifiicationDateService,
        PlataformRoutersService,
        GlobalService,
        { provide: SETTINGS, useValue: {} },
        EmailProvider,
        RegexProvider,
        WherebyService,
        IntegrationsService,
        FormatedEventLanguageService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
