import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";


export class ModuleSelfCheckin extends Module {
    public visOnlyForLoggedUser: boolean;
    selected: boolean;
    constructor(name: NameModule, icon: string, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.SELF_CHECKIN, eventId, order, false, true, true)
        this.selected = true;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}
