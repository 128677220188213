import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendee } from 'src/app/models/attendees';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../../../providers/auth/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { eventCustomFieldAnswerOption } from 'src/app/models/eventCustomFieldAnswerOption';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Languages } from 'src/app/models/languages';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { TypeUser } from 'src/app/enums/typeUser';
import { ModuleAttendee } from 'src/app/models/modules/module-attendees';
import { DbDocuments } from 'src/app/providers/database/db-documents';
import { NameModule } from 'src/app/models/name-module';
import { DBCustomFieldsProvider } from "src/app/providers/database/db-custom-fields.service"

declare let $: any;

@Component({
    selector: 'app-create-attendee',
    templateUrl: './create-attendee.component.html',
    styleUrls: ['./create-attendee.component.scss']
})

export class CreateAttendeeComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
    @ViewChild('errorEmailAdmSwal') public errorEmailAdmSwal: SwalComponent;
    @ViewChild('errorEmailSpeakerSwal') public errorEmailSpeakerSwal: SwalComponent;
    @ViewChild('errorIdentifierSwal') public errorIdentifierSwal: SwalComponent;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    formValidation: FormGroup;
    public eventId: string = null;
    public moduleId: string = null;
    private moduleName: any = null
    loader: boolean = false;
    // cropperSettings: CropperSettings;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;

    public listCustomFields: DocumentData[] = [];
    cfAnswerOptns = {};
    // public fieldsCustomOptions;


    // GROUPS FILTER
    listGroup: Array<Group>
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = '';
    errorFormGroup: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<any> = [];
    selectedDocument: Array<any> = [];
    filteredListDocuments = [];
    queryDocument = '';
    errorFormDocument: boolean = false;

    // DOOCUMENTS VISIBILITY
    public docChoice: string;

    event: any;
    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string
    principalEventLanguageFormated: string = 'PtBR';
    customUser: any;


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbAttendee: DbAttendeesProvider,
        private dbSpeaker: DbSpeakersProvider,
        private dbEvent: DbEventsProvider,
        private router: Router,
        private aFirestore: AngularFirestore,
        private auth: AuthService,
        private dbGroups: DbGroupsProvider,
        private dbUser: DbAdminUserProvider,
        private dbDocuments: DbDocuments,
        private SCustomFields: DBCustomFieldsProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        this.formValidation = fb.group({
            'photoUrl': [null],
            'id': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
            'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
            'emailRecovery': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'title_ptBR': [""],
            'title_enUS': [""],
            'title_esES': [""],
            'title_frFR': [""],
            'title_deDE': [""],
            'company': [null, Validators.compose([Validators.maxLength(50)])],
            'website': [null, Validators.compose([Validators.maxLength(200)])],
            'facebook': [null, Validators.compose([Validators.maxLength(200)])],
            'instagram': [null, Validators.compose([Validators.maxLength(200)])],
            'linkedin': [null, Validators.compose([Validators.maxLength(200)])],
            'twitter': [null, Validators.compose([Validators.maxLength(200)])],
            'description_ptBR': [""],
            'description_enUS': [""],
            'description_esES': [""],
            'description_frFR': [""],
            'description_deDE': [""],
            'groups': [null],
            'documents': [null]
        });
    }

    ngOnInit() {
        this.getCustomFields();
        this.getGroups();
        this.startEvent();
        this.getModule();
        this.getDocuments();
        // this.dbEvent.getEvent(this.eventId, (event) => {
        //     if (event) {
        //         this.docChoice = event['docVisibility'];
        //     }
        // });
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
            this.languages = event.languages;
        });
    }

    getCustomFields() {
        this.SCustomFields.getCustomFields(this.eventId, this.moduleId)
            .then((customFields) => {
                customFields.forEach((cf) => {
                    if (cf.type == 's') {
                        this.cfAnswerOptns[cf.uid] = cf.answerOptions;
                    }
                })
                this.listCustomFields = customFields;
            });
    }

    getModule() {
        this.dbAttendee.attendeeModule(this.moduleId, (module: ModuleAttendee) => {
            if (typeof module !== 'undefined' && module !== null)
                this.moduleName = module.name;
        })
    }


    getGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = result['groups'];
        });
    }

    getDocuments() {
        this.dbDocuments.getEventDocuments(this.eventId, (documents: Array<any>) => {
            this.listDocument = [];
            this.listDocument = documents;
        });
    }


    createAttendee(attendeeForm) {
        this.loader = true;
        let name = attendeeForm.name.trim();
        attendeeForm.email = attendeeForm.email.toLowerCase();
        let attendee = new Attendee(name, attendeeForm.email, 5, this.eventId);

        if (attendeeForm.emailRecovery) {
            attendee.$emailRecovery = attendeeForm.emailRecovery.toLowerCase();
        } else {
            attendeeForm.emailRecovery = attendeeForm.emailRecovery;
        }

        attendee.$identifier = attendeeForm.id;
        attendee.$description = {
            PtBR: attendeeForm.description_ptBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EnUS: attendeeForm.description_enUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EsES: attendeeForm.description_esES.replace(/href="/g, 'class="wysiwyg-link" href="'),
            FrFR: attendeeForm.description_frFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            DeDE: attendeeForm.description_deDE.replace(/href="/g, 'class="wysiwyg-link" href="'),
        };
        attendee.$company = attendeeForm.company;
        attendee.$title = {
            PtBR: attendeeForm.title_ptBR,
            EnUS: attendeeForm.title_enUS,
            EsES: attendeeForm.title_esES,
            FrFR: attendeeForm.title_frFR,
            DeDE: attendeeForm.title_deDE,
        };
        attendee.$website = attendeeForm.website;
        attendee.$facebook = attendeeForm.facebook;
        attendee.$instagram = attendeeForm.instagram;
        attendee.$linkedin = attendeeForm.linkedin;
        attendee.$twitter = attendeeForm.twitter;
        attendee.$queryName = name.toUpperCase();
        attendee.$phone = null;
        attendee.$createdAt = Date.now() / 1000 | 0;
        attendee.$checkinStatus = false
        attendee.$moduleId = this.moduleId;
        attendee.$module = { uid: this.moduleId, name: this.moduleName };

        // groups
        if (this.selectedGroup.length > 0) {
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                attendee.$groups[group['uid']] = Object.assign({}, group)
            }
        }

        // documents
        if (this.selectedDocument.length > 0) {
            // Initialize the docVisibility field for the first time a document is added to the event
            // 'Profil' is the default choice
            if (this.docChoice === undefined || this.docChoice === '') {
                this.docChoice = 'Profil';
            }

            attendee.$userDocVisibility = this.docChoice;

            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                attendee.$documents[document['uid']] = Object.assign({}, document)
            }
        }
        attendee.$language = this.event.language

        const userEmail = attendeeForm.email;
        this.dbAttendee.createAttendee(
            this.eventId,
            this.moduleId,
            attendee,
            this.listCustomFields,
            userEmail,
            this.finalFile
        ).then(() => {
            this.loader = false;
            this.successSwal.fire();
        }).catch((error) => {
            console.error(error);
            this.loader = false;
            this.errorSwal.fire();
        });
    }

    /**
     * On upload image file
     * @param $event
     */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle');
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }

    redirectList() {
        this.router.navigate([`/event/${this.eventId}/attendees/${this.moduleId}`]);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroups = [];
        }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = '';
        this.filteredListGroups = [];
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }


    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(function (el) {
                return el.name[this.principalEventLanguageFormated].toLowerCase().indexOf(this.queryDocument.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument.indexOf(item);
            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }
        this.queryDocument = '';
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeDocument(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    updateDocVisibility() {
        // Initialize the docVisibility field for the first time a document is added to the event
        // 'Profil' is the default choice

        console.log(this.docChoice)

        if (!this.docChoice || this.docChoice === undefined || this.docChoice === '') {
            this.docChoice = 'Profil';
        }

        this.dbAttendee.updateModuleDocumentsVisibility(this.moduleId, { docVisibility: this.docChoice }, (status) => {
            if (status === true) {
                console.log('success');
            } else {
                console.log('failed to update docVisibility');
            }
        });
    }
}
