import { Component, OnInit, NgModule, OnDestroy } from '@angular/core';
import { DbAdminUserProvider } from '../../providers/database/db-admin-user';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})

@NgModule({
    declarations: [FilterPipe],
})

export class ClientComponent implements OnInit, OnDestroy {
    clientsSubscription: Subscription;
    public listUsers = [];
    public p: number = 1;
    public deleteUserId: string;
    deleteUserIndex: number;
    loader: boolean = true;
    userId: string = null;
    typeOrder: string = 'asc';
    term: string = '';

    constructor(private dbAdminUser: DbAdminUserProvider, private global: GlobalService) {

        this.global.loadService(() => {
            this.userId = this.global.userId;
            this.loadOrder().then(() => {
                this.getClients();
            });
        })
    }

    ngOnInit() {
        // this.dbAdminUser.setClaims();
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        if (this.clientsSubscription && !this.clientsSubscription.closed) {
            this.clientsSubscription.unsubscribe();
        }
    }

    loadOrder() {
        return new Promise((resolve) => {
            this.userId = this.global.userId;

            this.dbAdminUser.getUser(this.userId, (data) => {
                let user = data.result;

                if (user.clientsOrder !== undefined && user.clientsOrder !== null && user.clientsOrder !== '') {
                    this.typeOrder = user.clientsOrder;
                }

                resolve(true);
            })
        })
    }

    getClients() {
        if (this.clientsSubscription && !this.clientsSubscription.closed) {
            this.clientsSubscription.unsubscribe();
        }
        this.clientsSubscription = this.dbAdminUser.getClients(this.typeOrder).subscribe((users) => {
            this.listUsers = users;
            this.loader = false;
        });
    }

    getUserRemove(userId, index) {
        this.deleteUserId = userId;
        this.deleteUserIndex = index;
    }

    changeOrder() {
        this.dbAdminUser.changeOrderClients(this.userId, this.typeOrder, (data) => {
            if (data == true) {
                this.getClients();
            }
        })
    }

    removeUser() {
        this.loader = true;
        this.dbAdminUser.removeClient(this.deleteUserId, (data) => {
            console.log(data)
            if (data == true) {
                this.listUsers.splice(this.deleteUserIndex, 1);
                this.loader = false;
            } else {
                this.loader = false;
            }
        })
    }

}
