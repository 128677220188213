/**
 * By default it was set to use the "Europe / Paris" zone as the default for all dates. That goes for Api too.
    backoffice
 */

import { Injectable } from "@angular/core";
import { DateTime, Settings } from "luxon";
import { IDate } from "src/app/shared/interfaces/date.interfaces";
import { environment } from "../../../environments/environment";

@Injectable()
export class LuxonService {
    constructor() {
        //  Settings.defaultZoneName = "Europe/Paris"
        // Settings.defaultZoneName = "America/Sao_Paulo"
        Settings.defaultZoneName = environment.platform.defaultTimezone;
    }

    /* cria data com a fuso horário do timezane 
    
    return object DateTime
    */
    createDate(
        year: string,
        month: string,
        day: string,
        hour: string,
        minute: string,
        seconds: string
    ) {
        const aux =
            year +
            "-" +
            month +
            "-" +
            day +
            "T" +
            hour +
            ":" +
            minute +
            ":" +
            seconds;
        return DateTime.fromISO(aux);
    }

    /**
     * Convert a timestamp seconds to iso string date
     * @param timestamp
     */
    convertTimestampToISo(timestamp: number) {
        return DateTime.fromSeconds(timestamp).toISO();
    }

    // cria timestamp
    createTimeStamp(date: DateTime) {
        return date.valueOf() / 1000;
    }

    // converte timestamp para DateTime
    convertTimestampToDate(timestamp: number) {
        const aux = DateTime.fromMillis(timestamp * 1000);
        return aux;
    }

    // converte um DateTime para uuma string com em português do Brasil (DD de MÊS)
    convertDateToStringTitlePortBrazil(date: DateTime) {
        var f = { year: "numeric", month: "long", day: "numeric" };
        return date.setLocale("pt-BR").toLocaleString(f as any);
    }

    // converte um  DateTime para uma string do formato dd/mm/aaaa
    convertDateToStringIsNotUSA(date: DateTime): any {
        return date.toLocaleString({
            day: "numeric",
            month: "numeric",
            year: "numeric",
        });
    }

    // converte um DateTime para uuma string com em português do Brasil
    // convertDateToStringTitlePortBrazil(date: DateTime) {
    //     var f = { year: "numeric", month: "long", day: "numeric" };
    //     return date.setLocale("pt-BR").toLocaleString(f);
    // }

    // converte um  DateTime para uma string do formato dd/mm/aaaa
    convertDateToStringIsUSA(date: DateTime): any {
        const year = date.year;
        const month = date.month;
        const day = date.day;

        return month + "/" + day + "/" + year;
    }

    // converte um DateTime em uma string no formato yyyy-mm-aa
    convertDateToStringYearMonthDay(date: DateTime) {
        return date.toISODate();
    }

    // retorna a hora e o minuto da data
    dateTime(date: DateTime) {
        return date.toLocaleString({
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
    }

    // retorna a hora, inuto e os segundos da data.
    dateTimeWithSeconds(date: DateTime) {
        return date.toLocaleString({
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
    }

    dateTimeUSA(date: DateTime): any {
        let aux = date.setLocale("en-US");
        return aux.toLocaleString(DateTime.TIME_SIMPLE);
    }

    // return current time
    currentTime(): DateTime {
        let now = DateTime.local();
        return now;
    }

    /**
     * Create a DateTime object from IDate object
     * @param date
     */
    createDateTime(date: IDate): DateTime {
        if (date.zone == "local") {
            return DateTime.local(
                date.year,
                date.month,
                date.day,
                date.hour,
                date.minute,
                date.second,
                date.millisecond
            );
        } else {
            return DateTime.fromObject(date);
        }
    }

    /**
     * Return formated date base on the format passed
     * @param date
     * @param format
     */
    formatDate(date: DateTime, format: string, locale: string) {
        return date.setLocale(locale).toFormat(format);
    }

    /**
     * Get iso from IDate object
     * @param date
     */
    getIsoFromDate(date: IDate) {
        if (date.zone == "local") {
            return DateTime.local(
                date.year,
                date.month,
                date.day,
                date.hour,
                date.minute,
                date.second,
                date.millisecond
            ).toISO();
        } else {
            return DateTime.fromObject(date).toISO();
        }
    }

    /**
     * Get timestamp from IDate object
     * @param date
     */
    getTimestampFromDate(date: IDate) {
        if (date.zone == "local") {
            return (
                DateTime.local(
                    date.year,
                    date.month,
                    date.day,
                    date.hour,
                    date.minute,
                    date.second,
                    date.millisecond
                ).valueOf() / 1000
            );
        } else {
            return DateTime.fromObject(date).valueOf() / 1000;
        }
    }

    /**
     * Get string date from IDate object
     * @param date
     */
    getStringDateFromDate(date: IDate) {
        if (date.zone == "local") {
            return DateTime.local(
                date.year,
                date.month,
                date.day,
                date.hour,
                date.minute,
                date.second,
                date.millisecond
            ).toFormat("yyyy-MM-dd");
        } else {
            return DateTime.fromObject(date).toFormat("yyyy-MM-dd");
        }
    }

    /**
     * Get string time from IDate object
     * @param date
     */
    getStringTimeFromDate(date: IDate) {
        if (date.zone == "local") {
            return DateTime.local(
                date.year,
                date.month,
                date.day,
                date.hour,
                date.minute,
                date.second,
                date.millisecond
            ).toFormat("HH:mm:ss.SSS");
        } else {
            return DateTime.fromObject(date).toFormat("HH:mm:ss.SSS");
        }
    }

    /**
     * Get gmt date
     * @param date
     */
    getGmtDate(date: string) {
        return DateTime.fromISO(date).toFormat("yyyy-MM-ddTHH:mm:ssZ");
    }
}
