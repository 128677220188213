import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../providers/auth/auth.service";
import { PathApi } from "../../paths/path-api";
import { AngularFirestore, DocumentData } from "@angular/fire/firestore";
import { Speaker } from "src/app/models/speakers";
import firebase from 'firebase/app';
import { StorageService } from "src/app/providers/storage/storage.service";
import { DbAdminUserProvider } from "./db-admin-user";
import { TypeModule } from "src/app/enums/type-module";
import { async } from "q";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { Scheduler } from "rxjs";
import { Session } from "src/app/models/session";

@Injectable({
    providedIn: "root",
})
export class DbSpeakersProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private aFirestore: AngularFirestore,
        private storage: StorageService,
        private dbUser: DbAdminUserProvider
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", "application/json");
        this.headers.append("Content-Type", "application/json");
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore();
    }

    speakerModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((doc) => {
                let module = doc.data();
                onResolve(module);
            })
            .catch((err) => onResolve(err));
    }

    // load evnt speakers
    getSpeakersEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .get()
            .then((snapshot) => {
                const list = [];

                snapshot.forEach((childSnanapshot) => {
                    const speaker = childSnanapshot.data();
                    list.push(speaker);
                });

                onResolve(list);
            });
    }

    getSpeakers(eventId: string, typeOrder: string) {
        const ref = this.aFirestore.firestore
            .collection(`events/${eventId}/speakers`);

        let refQ: firebase.firestore.Query<firebase.firestore.DocumentData>;
        switch (typeOrder) {
        case "asc": //a-z
            refQ = ref.orderBy("queryName", "asc");
            break;
        case "desc": //z-a
            refQ = ref.orderBy("queryName", "desc");
            break;
        case "oldest": //antiho-recente
            refQ = ref.orderBy("createdAt", "asc");
            break;
        case "recent": //recente-antigo
            refQ = ref.orderBy("createdAt", "desc");
            break;
        case "id": //ID
            refQ = ref.orderBy("identifier");
            break;
        default:
            return Promise.reject(`Invalid value for 'typeOrder' param`);
        }
        return new Promise<DocumentData[]>((resolve, reject) => {
            refQ.get()
                .then(r => resolve(r.docs.map(doc => doc.data())))
                .catch(err => reject(err))
        });
    }

    getSpeaker(eventId, moduleId, speakerId, onResolve) {
        let firestore = this.aFirestore.firestore;

        firestore
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .doc(speakerId)
            .get()
            .then((data) => {
                let result = {
                    code: 200,
                    message: "success",
                    result: data.data(),
                };

                onResolve(result);
            })
            .catch((error) => {});
    }

    // get speaker object using a promise function.
    getSpeakerPromise(eventId: string, speakerId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;
            const ref = db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speakerId);

            ref.get().then((snapshot) => {
                const speaker = snapshot.data();
                resolve(speaker);
            });
        });
    }

    verifyExistSpeakerByEmail(eventId: string, email: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("email", "==", email)
            .get()
            .then((data) => {
                let docNotExist = true;
                data.forEach((doc) => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {});
    }

    verifyExistSpeakerByIdentifier(eventId, identifier, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach((doc) => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {});
    }

    verifyIdentifierValidBySpeaker(eventId, identifier, speakerId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach((doc) => {
                    if (doc.exists) {
                        let speaker = doc.data();

                        if (speakerId == speaker.uid) {
                            onResolve(true);
                        } else {
                            onResolve(false);
                        }
                    }
                });

                if (docNotExist) {
                    onResolve(true);
                }
            })
            .catch((error) => {});
    }

    verifyEmailValidBySpeaker(email, speakerId, onResolve) {
        if (email !== "" && email !== null && email !== undefined) {
            let db = this.aFirestore.firestore;

            db.collection("users")
                .where("email", "==", email)
                .get()
                .then((data) => {
                    if (data.size == 0) {
                        onResolve(true);
                    }

                    data.forEach((element) => {
                        let user = element.data();

                        if (user.uid == speakerId) {
                            onResolve(true);
                        } else {
                            onResolve(false);
                        }
                    });
                });
        } else {
            onResolve(true);
        }
    }

    async createSpeaker(
        eventId: string,
        moduleId: string,
        speaker: Speaker,
        listCustomFields: DocumentData[],
        userEmail: string,
        profilePhoto: string,
    ) {
        speaker.$moduleId = moduleId;
        const batch = this.db.batch();

        const fetchUser = (await this.db
            .collection('users')
            .where("email", "==", userEmail)
            .get());
        
        if (!fetchUser) {
            return Promise.reject();
        }

        if (fetchUser.size > 0) {
            const uid = fetchUser.docs[0].id;
            speaker.$uid = uid;
            let tmp: any = {
                events: firebase.firestore.FieldValue.arrayUnion(eventId),
                speakerModules: firebase.firestore.FieldValue.arrayUnion(moduleId)
            }

            if (
                fetchUser.docs[0].data().firstAccess == true &&
                !speaker.$emailRecovery
            ) {
                tmp['emailRecovery'] = speaker.$emailRecovery;
            }

            const refUser = this.db.collection('users').doc(speaker.$uid);
            batch.update(refUser, tmp);
        } else {
            let refCollUser;
            if (!speaker.$uid) {
                refCollUser = this.db.collection('users').doc();
                speaker.$uid = refCollUser.id;
            } else {
                refCollUser = this.db.collection('users').doc(speaker.$uid);
            }
            const userAttr = this.generateUserObject(speaker, eventId, moduleId);
            const auxUser: any = Object.assign({}, userAttr);
            batch.set(refCollUser, auxUser);
        }

        const refSpeaker = this.db
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .doc(speaker.$uid);
            
        const refSpeakerMod = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('speakers')
            .doc(speaker.$uid);
            
        const data: any = Object.assign({}, speaker);
            
        batch.set(refSpeaker, data);
        batch.set(refSpeakerMod, data);

        for (let cf of listCustomFields) {
            const refCF = refSpeaker.collection('customFields').doc(cf.uid);
            const refCFMod = refSpeakerMod.collection('customFields').doc(cf.uid);

            const tmp = {
                id: cf.uid,
                type: cf.type,
                answer: (!cf.answer) ? null : cf.answer   // type: 'text' -> string, type: 's' -> answerOption.id
            }

            batch.set(refCF, tmp);
            batch.set(refCFMod, tmp);
        }

        if (profilePhoto) {
            this.storage.profilePictureSpeakers(
                profilePhoto, 
                eventId, 
                speaker.$uid, 
                (url) => {
                    batch.update(refSpeaker, { photoUrl: url });
                    batch.update(refSpeakerMod, { photoUrl: url });
                }
            );
        }

        return batch.commit();
    }

    generateUserObject(data, eventId, moduleId) {
        const dataFormat = {
            uid: data.uid,
            name: data.name,
            queryName: data.name.toLowerCase(),
            type: data.type,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            emailRecovery: "",
            phone: "",
            events: firebase.firestore.FieldValue.arrayUnion(eventId),
            speakerModules: null,
            firstAccess: true, //sempre true
        };

        if (data.phone != undefined) {
            dataFormat.phone = data.phone;
        }
        if (data.email != undefined) {
            dataFormat.email = data.email;
        }
        if (data.language != undefined) {
            dataFormat.language = data.language;
        }
        if (data.description != undefined) {
            dataFormat.description = data.description;
        }
        if (data.photoUrl != undefined) {
            dataFormat.photoUrl = data.photoUrl;
        }
        if (data.company != undefined) {
            dataFormat.company = data.company;
        }
        if (data.title != undefined) {
            dataFormat.title = data.title;
        }
        if (data.emailRecovery != undefined) {
            dataFormat.emailRecovery = data.emailRecovery;
        }

        dataFormat.events = firebase.firestore.FieldValue.arrayUnion(eventId);
        dataFormat.speakerModules = firebase.firestore.FieldValue.arrayUnion(
            moduleId
        );

        // if (data.firstAccess != undefined) {
        //     dataFormat.firstAccess = data.firstAccess;
        // }
        return dataFormat;
    }

    async editSpeaker(
        eventId,
        moduleId,
        speaker,
        customFields,
        photoProfile,
        emailChanged
    ) {
        speaker = Object.assign({}, speaker);

        const batch = this.db.batch();
        const refUser = this.db.collection('users').doc(speaker.uid);
        const refSpeaker = this.db
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .doc(speaker.uid);
            
        const refSpeakerMod = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('speakers')
            .doc(speaker.uid);
        
        if (emailChanged) {
            const p = new Promise<any>((res) => {
                this.auth.updateUserAuth(speaker.uid, speaker, (response) => {
                    res(response.result);
                });
            });
            const res = await p;
            if (!res) {
                return Promise.reject();
            }

            if (speaker.emailRecovery !== '') {
                batch.update(refUser, {
                    email: speaker.email,
                    emailRecovery: speaker.emailRecovery
                });
            } else {
                batch.update(refUser, { email: speaker.email });
            }
        } else {
            if (speaker.emailRecovery !== '') {
                batch.update(refUser, { emailRecovery: speaker.emailRecovery });
            }
        }

        for (let cf of customFields) {
            const refCF = refSpeaker.collection('customFields').doc(cf.id);
            const refCFMod = refSpeakerMod.collection('customFields').doc(cf.id);

            const tmp = { answer: (!cf.answer) ? null : cf.answer };

            batch.update(refCF, tmp);
            batch.update(refCFMod, tmp);
        }

        if (photoProfile) {
            const p = new Promise<string>((res, rej) => {
                this.storage.profilePictureSpeakers(
                    photoProfile,
                    eventId,
                    speaker.uid,
                    (url) => {
                        if (url == null) { rej(); }
                        else { res(url); }
                    }
                );
            });
            const url = await p;
            if (!url) {
                return Promise.reject(url);
            }
            speaker.photoUrl = url;
        }

        batch.update(refSpeaker, speaker);
        batch.update(refSpeakerMod, speaker);

        return Promise.all([
            batch.commit(),
            this.updateSessions(eventId, speaker)
        ]);
    }

    verifySpeakerInUsers(email: string) {
        return new Promise((resolve) => {
            this.db
                .collection("users")
                .where("email", "==", email)
                .get()
                .then((values) => {
                    if (values.size >= 1) {
                        values.forEach((element) => {
                            resolve(element.data());
                        });
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    /**
     * Update attendees field value
     * @param eventId
     * @param moduleId
     * @param attendeesField
     */
    updateAttendeesField(
        eventId: string,
        moduleId: string,
        attendeesField: string
    ) {
        let db = this.aFirestore.firestore;

        const ref1 = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        const ref2 = db.collection("modules").doc(moduleId);

        let batch = this.db.batch();

        batch.update(ref1, { attendeesField: attendeesField });
        batch.update(ref2, { attendeesField: attendeesField });

        batch
            .commit()
            .then((_) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    removeSpeakers(eventId, moduleId, listRemoveUids) {
        // remove speakers. Activates trigger:dbSpeakersDelete
        return this.http.post(
            PathApi.baseUrl + PathApi.dbBulkDataFlowRecDocDel, 
            { 
                refs: listRemoveUids.reduce((acc, id) => {
                    return [
                        ...acc,
                        `events/${eventId}/speakers/${id}`,
                        `modules/${moduleId}/speakers/${id}`
                    ]
                }, [])
            },
            this.requestOptions
        ).toPromise();
    }

    removeSpeakerAllEvents(userId, onResolve) {
        let refUser = this.db.collection("users").doc(userId);

        refUser.get().then((data) => {
            let user = data.data();

            let batch = this.db.batch();
            for (let moduleId of user.speakerModules) {
                let refModule = this.db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .doc(user.uid);
                batch.delete(refModule);
            }

            for (let eventId of user.events) {
                let refEvent = this.db
                    .collection("events")
                    .doc(eventId)
                    .collection("speakers")
                    .doc(user.uid);
                batch.delete(refEvent);
            }

            this.auth.removeUserAuth(user.uid, (data) => {
                if (data.result == true) {
                    batch
                        .commit()
                        .then(() => {
                            onResolve(true);
                        })
                        .catch((err) => {
                            onResolve(err);
                        });
                }
            });
        });
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }

    changeOrderItems(eventId: string, moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder });
        db.collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    // ======================================================================== //

    updateModule(moduleId: string, fieldsCustom) {
        let db = this.aFirestore.firestore;

        let ref = db.collection("modules").doc(moduleId);

        ref.update({ fieldsCustom: fieldsCustom });
    }

    // ======================================================================== //

    // ================================= CONFIG MODULE ====================================
    getAllowedEditProfile(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((data) => {
                let aux = data.data();
                let result = aux.allowedEditProfile;

                onResolve(result);
            });
    }

    changeAllowedEditProfile(moduleId, status, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .update({
                allowedEditProfile: status,
            })
            .then(() => {
                onResolve("success");
            })
            .catch(() => {
                onResolve("error");
            });
    }

    // ==================================================================================

    importSpeakers(
        eventId: string,
        moduleId: string,
        speakers: Array<Speaker>,
        groupModuleId,
        language,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            speakers: speakers,
            groupModuleId: groupModuleId,
            eventLanguage: language,
        };
        this.http
            .post(
                PathApi.baseUrl + PathApi.dbSpeakerImportSpeaker,
                body,
                this.requestOptions
            )
            .subscribe((result) => {
                onResolve(result);
            });
    }

    exportSpeakers(moduleId: string, typeOrder: string, onResolve) {
        this.http
            .get(
                PathApi.baseUrl +
                    PathApi.dbSpeakerExportSpeakers +
                    "?moduleId=" +
                    moduleId +
                    "&typeOrder=" +
                    typeOrder,
                this.requestOptions
            )
            .subscribe((data) => {
                onResolve(data);
            });
    }

    getSpeakersFieldOptions(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux["fields"]);
            });
    }

    changeRequiredField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].required = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeUniqueEditField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeHideField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeRequiredFieldCustom(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].required = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeUniqueEditFieldCustom(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeHideFieldCustom(
        eventId: string,
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeOrderCustoms(moduleId: string, allFields, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .update({
                fieldsCustom: allFields,
            })
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }

    // ************************************************************* SESSIONS  **************************************************************************************
    
    // vpdate the sessions that the speaker has
    async updateSessions(eventId: string, speaker: any) {
        const batch = this.aFirestore.firestore.batch();

        speaker = Object.assign({}, speaker);
        const speakerId = speaker.uid;

        // Lists all schedule modules of the event.
        const modules = await this.getModulesSheduleEvent(eventId);
        if (!modules) { return Promise.reject(); }

        for (const module of modules) {
            const moduleScheduleId = module.uid;

            // lists the sessions of the module that has the speaker.
            const sessions = await this.getSessionsOfSpeaker(
                speakerId,
                moduleScheduleId
            );
            if (!sessions) { return Promise.reject(); }

            let ref;
            for (const session of sessions) {
                const sessionId = session.uid;
                session.speakers[speakerId] = speaker;

                ref = this.aFirestore.firestore.doc(`events/${eventId}/sessions/${sessionId}`)
                batch.update(ref, session);

                // ref = this.aFirestore.firestore.doc(`modules/${moduleScheduleId}/sessions/${sessionId}`)
                // batch.update(ref, session);
            }
        }
        return batch.commit();
    }

    getSession(sessionId: string, moduleId: string) {
        return new Promise((resolve) => {
            const ref = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions")
                .doc(sessionId);

            ref.onSnapshot((snapshot) => {
                const session = snapshot.data();
                resolve(session);
            });
        });
    }

    modulePersonalAgendaEvent(eventId) {
        return new Promise((resolve) => {
            let module = null;
            const ref = this.db
                .collection("events")
                .doc(eventId)
                .collection("modules")
                .where("type", "==", TypeModule.PERSONALSCHEDULE);

            ref.get().then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data();
                });

                resolve(module);
            });
        });
    }

    // Lists all schedule modules of the event.
    getModulesSheduleEvent(eventId: string): Promise<Array<ModuleSchedule>> {
        return new Promise((resolve) => {
            const list = [];

            const ref = this.db
                .collection("events")
                .doc(eventId)
                .collection("modules")
                .where("type", "==", TypeModule.SCHEDULE);

            ref.get().then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    list.push(childSnapshot.data());
                });

                resolve(list);
            });
        });
    }

    // lists the sessions of the module that has the speaker.
    getSessionsOfSpeaker(
        speakerId: string,
        moduleScheduleId: string
    ): Promise<Array<Session>> {
        return new Promise((resolve) => {
            const list = [];
            const speaker = `speakers.${speakerId}`;

            const ref = this.db
                .collection("modules")
                .doc(moduleScheduleId)
                .collection("sessions")
                .orderBy(speaker);

            ref.get().then((snapshot) => {
                snapshot.forEach((element) => {
                    list.push(element.data());
                });

                resolve(list);
            });
        });
    }

    takeChangesInModule(moduleId: string, eventId: string, changes) {
        let db = this.aFirestore.firestore;

        let refModule = db.collection("modules").doc(moduleId);
        let refEventModule = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);

        refModule.update(changes);
        refEventModule.update(changes);
    }

    resetAccount(speaker: Speaker) {
        return new Promise((resolve, reject) => {
            this.auth.verifyEmailAuth(speaker["email"]).then((response) => {
                if (response["code"] == 0) {
                    // user exist
                    resolve(0);
                    this.reconfigureUser(response["uid"], speaker).then(
                        (snap) => {
                            if (snap) {
                                resolve(0);
                            } else {
                                reject(2);
                            }
                        }
                    );
                } else if (response["code"] == 1) {
                    // user doesn't exist
                    resolve(1);
                } else if (response["code"] == 2) {
                    // error to find e-mail
                    reject(2);
                }
            });
        });
    }

    reconfigureUser(authUid: string, speaker: Speaker) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let oldUid;
            let user = null;

            db.collection("users")
                .where("email", "==", speaker["email"])
                .get()
                .then((snapshot) => {
                    if (snapshot.size >= 1) {
                        snapshot.forEach((element) => {
                            if (user == null) {
                                user = element.data();
                                let aux = user;
                                oldUid = user.uid;

                                let eventsId = aux.events;
                                let data = {
                                    name: aux.name,
                                    type: aux.type,
                                    email: aux.email,
                                    photoUrl: aux.photoUrl,
                                    createdAt: aux.createdAt,
                                    company: aux.company,
                                    title: aux.title,
                                    description: aux.description,
                                    recoveryEmail: aux.recoveryEmail,
                                    uid: authUid,
                                    events: aux.events,
                                    speakerModules: aux.speakerModules,
                                    firstAccess: false,
                                    language: aux.language,
                                    edited_profile: false,
                                };

                                let userObj = this.userModelDataSpeaker(data);
                                let cont = 0;
                                for (let idEvent of eventsId) {
                                    let batch = null;
                                    batch = db.batch();
                                    // apgar no path users antigo

                                    let oldUserRef = db
                                        .collection("users")
                                        .doc(oldUid);
                                    batch.delete(oldUserRef);

                                    // adicionar no path users o novo
                                    let newUserRef = db
                                        .collection("users")
                                        .doc(authUid);
                                    batch.set(newUserRef, userObj);

                                    let oldSpeakerRef = db
                                        .collection("events")
                                        .doc(idEvent)
                                        .collection("speakers")
                                        .doc(oldUid);
                                    let newSpeakerRef = db
                                        .collection("events")
                                        .doc(idEvent)
                                        .collection("speakers")
                                        .doc(authUid);

                                    let listCustomField = [];
                                    oldSpeakerRef
                                        .collection("customFields")
                                        .get()
                                        .then((data) => {
                                            data.forEach((doc) => {
                                                let custom = doc.data();
                                                listCustomField.push(custom);
                                            });
                                        });

                                    oldSpeakerRef.get().then((data) => {
                                        let speaker = data.data();
                                        speaker.uid = authUid;
                                        speaker.firstAccess = false;
                                        let oldModulesSpeakerRef = db
                                            .collection("modules")
                                            .doc(speaker.moduleId)
                                            .collection("speakers")
                                            .doc(oldUid);
                                        let newModulesSpeakerRef = db
                                            .collection("modules")
                                            .doc(speaker.moduleId)
                                            .collection("speakers")
                                            .doc(authUid);
                                        // apagar no path events -> speakers
                                        batch.delete(oldSpeakerRef);
                                        // apagar no path modules -> speakers antigo
                                        batch.delete(oldModulesSpeakerRef);

                                        // adicionar no path events -> speakers
                                        batch.set(newSpeakerRef, speaker);
                                        // adicionar no path modules -> speakers novo
                                        batch.set(
                                            newModulesSpeakerRef,
                                            speaker
                                        );

                                        batch
                                            .commit()
                                            .then((batchOk) => {
                                                let batchCustom = db.batch();
                                                for (let custom of listCustomField) {
                                                    let refCustomEventSpeaker = newSpeakerRef
                                                        .collection(
                                                            "customFields"
                                                        )
                                                        .doc(custom.uid);
                                                    let refCustomModuleSpeaker = newModulesSpeakerRef
                                                        .collection(
                                                            "customFields"
                                                        )
                                                        .doc(custom.uid);

                                                    batchCustom.set(
                                                        refCustomEventSpeaker,
                                                        custom
                                                    );
                                                    batchCustom.set(
                                                        refCustomModuleSpeaker,
                                                        custom
                                                    );
                                                }

                                                batchCustom
                                                    .commit()
                                                    .then(() => {});

                                                if (
                                                    cont ==
                                                    eventsId.length - 1
                                                ) {
                                                    resolve(true);
                                                }
                                                cont++;
                                            })
                                            .catch((batchError) => {
                                                // remove da autenticação caso dê erro no banco
                                                console.error(batchError);
                                                // admin.auth().deleteUser(authUid);
                                                reject(false);
                                            });
                                    });
                                }
                            }
                        });
                    }
                });
        });
    }

    userModelDataSpeaker(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: user.events,
            speakerModules: user.speakerModules,
            firstAccess: null,
            uid: "",
            edited_profile: false,
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        userFormat.events = user.events;

        userFormat.speakerModules = user.speakerModules;

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }

    editFilterVisibility(moduleId: string, status: boolean,type: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleRef = db.collection('modules').doc(moduleId);
        const obj = {}
        if(type === 'company'){
            obj['allowFilterCompany'] = status
        } else if(type === 'post') {
            obj['allowFilterPost'] = status
        }
        moduleRef.update(obj).then(() => {
            onResolve({type: type, value: true});
        })
        .catch(() => {
            onResolve({type: type, value: false});
        });
    }

    editPropVisibility(moduleId: string, prop: string, status: any, onResolve) {
        let db = this.aFirestore.firestore;
        const obj = {}
        obj[prop] = status
        db.collection('modules').doc(moduleId)
            .update(obj)
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }
}
