export class AttendeeFieldsProperties {
    required: boolean;
    hide_field: boolean;
    unique_edit: boolean;

    constructor(required: boolean, unique_edit: boolean, hide_field: boolean) {
        this.required = required;
        this.hide_field =
            this.unique_edit = unique_edit;
    }

}