import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleSchedule extends Module {
    sessions: Array<any>;
    typeVision: number;
    habiliedPersonal: boolean; //controls whether module is enabled in the personal calendar
    habilitedLimit: boolean; //enable limit of participan for session
    allowVisio: boolean;
    allowNextPrevBtn: boolean;
    selected: boolean;
    trackName: any;
    access_groups: any; // uid of the group that is allowed access to the module
    oratorsField: string;
    showHeader: boolean;
    visOnlyForLoggedUser: boolean;
    showSpeakers: boolean;
    showDescription: boolean;
    showCustomFields: boolean;
    icsDownloadable: boolean;
    enableTrackFilter: boolean;
    subscribeSessionBtn: string;
    unsubscribeSessionBtn: string;
    timezoneType: string;

    constructor(
        name: NameModule,
        icon: string,
        typeVision: number,
        eventId: string,
        order: number,
        visOnlyForLoggedUser: boolean = false
    ) {
        super(name, icon, TypeModule.SCHEDULE, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.habiliedPersonal = false;
        this.habilitedLimit = false;
        this.allowVisio = false;
        this.allowNextPrevBtn = false;
        this.access_groups = {};
        this.selected = true;
        this.trackName = {
            PtBR: 'Tracks',
            EnUS: 'Tracks',
            EsES: 'Tracks',
            FrFR: 'Tracks',
            DeDE: 'Tracks'
        }
        this.sessions = [];
        this.showHeader = true;
        this.visOnlyForLoggedUser = (visOnlyForLoggedUser == undefined) ? false : visOnlyForLoggedUser;
        this.showSpeakers = false;
        this.showDescription = false;
        this.showCustomFields = false;
        this.icsDownloadable = false;
        this.enableTrackFilter = false;
        this.subscribeSessionBtn = "";
        this.unsubscribeSessionBtn = "";
        this.timezoneType = "event";
        this.oratorsField = "";
    }
}
