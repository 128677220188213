import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DbAdminUserProvider } from '../../../providers/database/db-admin-user';
import { User } from '../../../models/user';
import { DbLogsProvider } from 'src/app/providers/database/db-logs';
import { Log } from 'src/app/models/log';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Name } from 'src/app/models/name';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../providers/auth/auth.service';
import { PathApi } from 'src/app/paths/path-api';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { IfStmt } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {

  formValidation: FormGroup;
  loader: boolean = false;
  validPassword: boolean = null;
  showErrorPassword: boolean = false;
  oldUser = null;
  newUser = null;
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
  @ViewChild('confirmChangeToClientSwal') public confirmChangeToClientSwal: SwalComponent;
  @ViewChild('errorPermissionSwal') public errorPermissionSwal: SwalComponent;
  @ViewChild('confirmChangeUserTypeSwal') public confirmChangeUserTypeSwal: SwalComponent;
  @ViewChild('alreadyExistsSwal') public alreadyExistsSwal: SwalComponent;

  userRemoveId: string = null;
  userRemoveType: number = null;
  userRemoveTypeStr: string = null;

  constructor(private router: Router,
    fb: FormBuilder,
    private dbAdminUser: DbAdminUserProvider,
    private dbLogs: DbLogsProvider,
    private global: GlobalService,
    private auth: AuthService,
    private dbAttendee: DbAttendeesProvider,
    private dbSpeaker: DbSpeakersProvider,
    private translateService: TranslateService) {
    this.formValidation = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
      'password': [null, Validators.compose([Validators.required])],
      'language': ['pt_BR', Validators.compose([Validators.required])],
      'company': [null],
      'title': [null],
      'description': [null],
    })
  }

  ngOnInit() {
  }

  ngDoCheck() {
    this.validatePassword();
  }

  validatePassword() {
    let password = this.formValidation.get('password').value;
    let regex = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^a-zA-Z]).{8,50}/;
    let regexSymbol = /[-!$%^&*#@()_+|~=`{}\[\]:";'<>?,.\/]/;

    let found;
    let foundSymbol;
    if (password !== null && password !== undefined && password !== '') {
      found = password.match(regex);
      foundSymbol = password.match(regexSymbol);


      if (found !== null && foundSymbol !== null) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
        this.showErrorPassword = true;
      }
    } else {
      this.validPassword = false;
      this.showErrorPassword = false;
    }
  }

  checkEmailCreateClient() {
    let data = this.formValidation.value;

    this.dbAdminUser.getUserByEmail(data.email, (user) => {
      if(user === null) {
        this.userRemoveId = null;
        this.userRemoveType = null;
        this.userRemoveTypeStr = null;
        this.createClient();

      } else {

        if(user.type === TypeUser.SUPERGOD) {
          if(this.global.userType === TypeUser.SUPERGOD) {
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_supergod');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.GOD) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD) {
            // Pergunta se o usuário deseja transformar o GOD em CLIENT
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_god');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.CLIENT) {
          //Mensagem informando que o usuário já é um CLIENT
          this.alreadyExistsSwal.fire();

        } else if(user.type === TypeUser.EMPLOYEE) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD || this.global.userType === TypeUser.CLIENT) {
            // Pergunta se o usuário deseja transformar o EMPLOYEE em CLIENT
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_employee');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.SPEAKER) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_speaker');
          this.confirmChangeUserTypeSwal.fire();

        } else if(user.type === TypeUser.ATTENDEE) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_attendee');
          this.confirmChangeUserTypeSwal.fire();
        
        }
      }
    })
  }

  confirmChangeUserType() {
    if(this.userRemoveType == TypeUser.SUPERGOD || this.userRemoveType == TypeUser.GOD 
      || this.userRemoveType == TypeUser.CLIENT || this.userRemoveType == TypeUser.EMPLOYEE) {
      
      this.auth.removeUserAuth(this.userRemoveId, (data) => {
        if(data.result === true) {
          this.createClient();
        }
      });

    } else if(this.userRemoveType == TypeUser.ATTENDEE) {
      
      this.dbAttendee.removeAttendeeAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.createClient();
        }
      })

    } else if(this.userRemoveType == TypeUser.SPEAKER) {
      
      this.dbSpeaker.removeSpeakerAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.createClient();
        }
      })
    }
    
  }

  createClient() {

    let data = this.formValidation.value;

    if (this.validPassword) {

      this.loader = true;
      let user = new User(data.name, data.email);
      user.$photoUrl = "";
      user.$language = data.language;
      user.$password = data.password;
      user.$description = data.description;
      user.$type = 2;
      user.$createdAt = null;
      user.$emailRecovery = "";
      user.$title = data.title;
      user.$company = data.company;

      //verifica se o email já existe
      this.auth.verifyEmailDb(data.email)
        .then((data) => {
          if (data['result'] == "email-not-found") { //se o email não existir cria o user
            this.dbAdminUser.createUser(user, (data) => {
              if (data.code == 201) {
                if (data['result'].uid !== null && data['result'].uid !== undefined && data['result'].uid !== '') {
                  // display success modal
                  this.successSwal.fire();

                  // make log object
                  let log: Log = new Log(
                    Date.now(),
                    'create',
                    this.global.userId,
                    this.global.displayName,
                    data['result'].uid,
                    data['result'].displayName,
                    'user');
                  log.userName = this.global.displayName;
                  log.userType = 2;
                  // generete log - function
                  this.dbLogs.generateLog(log);
                }
              } else {
                this.errorSwal.fire();
              }

              this.loader = false;
            })
            // o usuário já existe, confirmar transformação em cliente
          } else if (data['result']['uid'] !== null && data['result']['uid'] !== undefined) {
            this.oldUser = data['result'];
            this.newUser = user;
            this.confirmChangeToClientSwal.fire();
          } else if (data['message'] == 'error') { // caso o retorno seja um erro
            this.errorEmailSwal.fire();
            this.loader = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  changeUserToClient() {
    this.dbAdminUser.deleteAndRemakeUser(this.oldUser, this.newUser, (status) => {
      if (status['message'] == 'success') {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }

  redirectList() {
    this.router.navigate(['/dashboard/client']);
  }

}
