import { AttendeeFieldsProperties } from "./attendee-fields-properties";

export class AttendeeFields {
    name: AttendeeFieldsProperties;
    company: AttendeeFieldsProperties;
    title: AttendeeFieldsProperties;
    phone: AttendeeFieldsProperties;
    email: AttendeeFieldsProperties;
    site: AttendeeFieldsProperties;
    linkedin: AttendeeFieldsProperties;
    facebook: AttendeeFieldsProperties;
    instagram: AttendeeFieldsProperties;
    twitter: AttendeeFieldsProperties;
    description: AttendeeFieldsProperties;

    constructor() {
        this.name = Object.assign({}, new AttendeeFieldsProperties(true, false, false));
        this.company = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.title = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.phone = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.email = Object.assign({}, new AttendeeFieldsProperties(true, false, true));
        this.site = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.linkedin = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.facebook = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.instagram = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.twitter = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
        this.description = Object.assign({}, new AttendeeFieldsProperties(false, false, false));
    }

}