import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppointmentTimeSlot } from "src/app/models/appointments";
import { DbAppointments } from "src/app/providers/database/db-appointments";
import * as XLSX from "xlsx";

@Component({
    selector: "app-list-appointments",
    templateUrl: "./list-appointments.component.html",
    styleUrls: ["./list-appointments.component.scss"],
})
export class ListAppointmentsComponent implements OnInit {
    @Input()
    appointments: AppointmentTimeSlot[] = [];

    @Output()
    export = new EventEmitter<Blob>();

    locale: string;

    currentPage;

    constructor(private dbAppointments: DbAppointments) {}

    async ngOnInit() {
        this.locale = this.dbAppointments.locale;
    }

    onExport() {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(
            this.appointments.map((a) => ({
                startDate: a.startDateTime.toString(),
                endDate: a.endDateTime.toString(),
                duration: a.duration,
                applicant: a.applicant.name,
                applicantNotation: a.applicant.notation
                    ? a.applicant.notation
                    : "",
                applicantCommentary: a.applicant.commentary
                    ? a.applicant.commentary
                    : "",
                invited: a.invited.name,
                invitedNotation: a.invited.notation ? a.invited.notation : "",
                invitedCommentary: a.invited.commentary
                    ? a.invited.commentary
                    : "",
                subject: a.subject,
            }))
        );
        XLSX.utils.book_append_sheet(wb, ws, "Appointments");
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        this.export.emit(new Blob([this.s2ab(wbout)]));
    }

    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
}
