import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbCustomPagesProvider } from 'src/app/providers/database/db-custom-pages';
import { PageInfobooth } from 'src/app/models/page-infobooth'
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Location } from '@angular/common';
import { MenuIcons, icons } from 'src/app/models/menu-icons';
import { EmailEditorComponent } from 'angular-email-editor';

declare var $: any;

@Component({
  selector: 'app-custom-page-create',
  templateUrl: './custom-page-create.component.html',
  styleUrls: ['./custom-page-create.component.scss']
})

export class CustomPageCreateComponent implements OnInit {
  iconsImg: MenuIcons[] = icons;
  selectedIcon: string;
  base64CustomIcon: string = null;
  selectedIconFamily: string;
  selectedIconCreate: string = null
  selectedIconCreateFamily: string;
  selectedEditIcon: string;
  selectedEditIconFamily: string;
  customIconFile = null;
  imageType: string = null;

  // bloc editor
  emailEditorActive = false;
  editedContent;
  pageContent: any;

  emptyTemplate = {
    counters: { u_column: 1, u_row: 1, u_content_text: 1, u_content_heading: 1 },
    body: {
      rows: [
        {
          cells: [1],
          columns: [
            {
              contents: [],
              values: {
                backgroundColor: "#FFFFFF",
                padding: "0px",
                border: {},
                _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: "#FFFFFF",
            columnsBackgroundColor: "#FFFFFF",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            padding: "0px",
            hideDesktop: false,
            _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      values: {
        textColor: "#000000",
        backgroundColor: "#FFFFFF",
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        contentWidth: "1200px",
        contentAlign: "center",
        fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
        preheaderText: "",
        linkStyle: {
          body: true,
          linkColor: "#0000ee",
          linkHoverColor: "#0000ee",
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
      },
    },
    schemaVersion: 6,
  };
  


  public module = null

  // get the language of the user.
  public userLanguage: string

  htmlContent = {
    PtBR: '',
    EnUS: '',
    EsES: '',
    FrFR: '',
    DeDE: ''
  };
  title = {
    PtBR: '',
    EnUS: '',
    EsES: '',
    FrFR: '',
    DeDE: ''
  };

  eventId = this.route.parent.params['_value']['uid']
  moduleId = this.route.snapshot.params['moduleId']

  public formGroup: FormGroup;

  loader: boolean
  moduleName: string = null;
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  languages: Languages = null //event languages
  event: any;
  principalEventLanguageFormated: string = 'PtBR';
  activeCreateLanguage: string = 'PtBR';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dbCustomPage: DbCustomPagesProvider,
    private global: GlobalService,
    private dbEvent: DbEventsProvider,
    public location: Location
  ) { }

  ngOnInit() {
    this.getModule();
    this.getUserLanguage();
    this.startEvent();
  }

  startEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.event = event;
      this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
      console.log(this.principalEventLanguageFormated);
      this.activeCreateLanguage = this.principalEventLanguageFormated;
      this.languages = event.languages;
    });
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  getModule() {
    this.dbCustomPage.getModule(this.moduleId, (module) => {
      this.module = module;
    });
  }

  errorPrincipalLanguageBlank: boolean = false;
  createPage() {

    let page = new PageInfobooth()
    this.errorPrincipalLanguageBlank = false;
    page.moduleId = this.moduleId
    page.eventId = this.eventId
    page.title = this.title
    page.icon = this.selectedIconCreate
    page.emailEditorActive = this.emailEditorActive;
    if(this.selectedIconCreateFamily) page.iconFamily = this.selectedIconCreateFamily

    const contentHtml = {
      PtBR: this.htmlContent.PtBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
      EnUS: this.htmlContent.EnUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
      EsES: this.htmlContent.EsES.replace(/href="/g, 'class="wysiwyg-link" href="'),
      FrFR: this.htmlContent.FrFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
      DeDE: this.htmlContent.DeDE.replace(/href="/g, 'class="wysiwyg-link" href="')
    }

    page.htmlContent = contentHtml;

    if (this.emailEditorActive) {
      // Bloc editor used
      this.loader = true
      // update 
      this.emailEditor.editor.exportHtml((data) => {
        const design = JSON.stringify(data.design);
        let htmlContent = data.chunks.body;
        // htmlContent = htmlContent.trim();
        page.pageHTMLContent = htmlContent;
        page.pageContent = design;

        if (page.title[this.principalEventLanguageFormated] !== '') {
            for (let lang in page.title) {
              if (page.title[lang] == '') {
                page.title[lang] = page.title[this.principalEventLanguageFormated];
              }
            }
            this.dbCustomPage.getTotalPagesModule(this.moduleId, (total) => {
              page.order = total;

              this.dbCustomPage.createPage(page, ()=>{}).then(() => {
                this.successSwal.fire();
                this.loader = false;
              }).catch((error) => {
                console.log(error)
                this.errorSwal.fire()
                this.loader = false
              })
            })
        } else {
            this.errorPrincipalLanguageBlank = true;
        }
      })
      this.loader = !this.loader
    } else {
      if (page.title[this.principalEventLanguageFormated] !== '' && 
          page.htmlContent[this.principalEventLanguageFormated] !== '') {
        this.loader = true
        for (let lang in page.title) {
          if (page.title[lang] == '') {
            page.title[lang] = page.title[this.principalEventLanguageFormated];
          }
  
          if (page.htmlContent[lang] == '') {
            page.htmlContent[lang] = page.htmlContent[this.principalEventLanguageFormated];
          }
        }
        this.dbCustomPage.getTotalPagesModule(this.moduleId, (total) => {
          page.order = total;
  
          this.dbCustomPage.createPage(page, ()=>{}).then(() => {
            this.successSwal.fire();
            this.loader = false;
            // this.location.back();
          }).catch((error) => {
            console.log(error)
            this.errorSwal.fire()
            this.loader = false
          })
        })
      } else {
        this.errorPrincipalLanguageBlank = true;
      }
    }    
  }

  redirectList() {
    this.router.navigate(['/event/' + this.eventId + '/custom-pages/' + this.moduleId]);
  }

  convertLangFormat(lang) {
    let formatedLang;
    switch (lang) {
      case 'pt_BR': {
        formatedLang = 'PtBR'
        break;
      }
      case 'en_US': {
        formatedLang = 'EnUS';
        break;
      }
      case 'es_ES': {
        formatedLang = 'EsES';
        break;
      }
      case 'fr_FR': {
        formatedLang = 'FrFR';
        break;
      }
      case 'de_DE': {
        formatedLang = 'DeDE';
        break;
      }
    }
    return formatedLang;
  }

  editorLoaded(){
    const design = this.pageContent ? JSON.parse(this.pageContent) 
                    : this.emptyTemplate;

    setTimeout(() => {
        this.emailEditor.loadDesign(design)
    },500)
  }

  setIconCreate(index) {
    this.selectedIconCreate = this.iconsImg[index].icon;
    this.selectedIconCreateFamily = this.iconsImg[index].family;
    this.base64CustomIcon = null;
    this.customIconFile = null;
    this.imageType = null;
    $('.dropdown-menu').removeClass('show');
  }

  // use custom icon image
  importCustomIcon($ev) {
    this.customIconFile = null;
    this.imageType = null;
    this.base64CustomIcon = null;
    this.customIconFile = $ev.srcElement.files[0];
    this.imageType = this.customIconFile.type;
    if (this.customIconFile !== null) {
        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.customIconFile);
    }
  }
  // handle image to base64 to display for use before upload to storage
  handleReaderLoaded(e) {
    let imgType: string;

    if (this.imageType == 'image/gif') {
        imgType = 'data:image/gif;base64,';
    } else if (this.imageType == 'image/png') {
        imgType = 'data:image/png;base64,';
    } else if (this.imageType == 'image/jpg') {
        imgType = 'data:image/jpg;base64,';
    } else {
        imgType = 'data:image/jpeg;base64,';
    }
    this.base64CustomIcon = imgType + btoa(e.target.result);
    this.selectedIconCreate = this.base64CustomIcon;
    this.selectedIconCreateFamily = 'custom-icon';
    this.selectedEditIcon = this.base64CustomIcon;
    this.selectedEditIconFamily = 'custom-icon';
  }
}
