import { Injectable } from "@angular/core";

@Injectable()

export class PathStorage {
    static profile_pictures = '/profile-pictures';
    static profile_pictures_attendees = '/profile-pictures-attendees';
    static profile_pictures_speakers = '/profile-pictures-speakers';
    static sponsors_picture = '/sponsors-picture';
    static stands_picture = '/stands-picture';
    static widgets = '/widgets';
    static locations = '/locations';
    static gallery = '/gallery';
    static documents = '/documents';
    static customPages = '/custom-pages';
    static event_visual = '/event/visual';
    static notifications = '/notifications';
    static news_feed = '/news_feed';
    static maps = '/maps';
    static quiz = '/quiz';
    static feedback = '/feedback';
    static ask_question_module = '/ask_question_module';
    static survey_module = '/survey_module';
    static quiz_module = '/quiz_module';
    static training = '/training';
    static containers = '/containers';
    static widget_bgs: '/widgets-bgs'
}
