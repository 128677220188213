import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { Widget } from 'src/app/models/widget.model';
import { StorageService } from '../storage/storage.service';
import { ModuleWidget } from 'src/app/models/modules/module-widget';

@Injectable({
    providedIn: 'root'
})

export class DbWidgetsProvider {
    public headers;
    public requestOptions;

    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private aFirestore: AngularFirestore,
        private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getWidgetByModule(moduleId: string, onResolve) {
        let firestore = this.aFirestore.firestore;
        firestore.collection('modules')
            .doc(moduleId)
            .collection('widgets')
            .orderBy('order', 'asc')
            .get()
            .then((data) => {
                let arrayAux = [];

                data.forEach(doc => {
                    arrayAux.push(doc.data());
                });
                onResolve(arrayAux);
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    getAllWidgets(onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbWidgetsGetAll, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            }), err => { onResolve(err) }
    }

    createWidget(widget: Widget, onResolve, addForBoth?: boolean, globalGridArea?: string) {
        let firestore = this.aFirestore.firestore;
        
        if(addForBoth){
            const dupWidget = {...widget}
            dupWidget.gridArea = widget.gridArea === null ? globalGridArea : null; 
            // console.log('dupWidget : ',dupWidget);
            const batch = firestore.batch();
            const refWidget = firestore.collection('modules').doc(widget.moduleId).collection('widgets').doc()
            const refDupWidget = firestore.collection('modules').doc(widget.moduleId).collection('widgets').doc()
            
            widget.uid = refWidget.id
            dupWidget.uid = refDupWidget.id
            dupWidget.order = widget.order + 1;

            console.log('originWidget :',widget)
            console.log('dupWidget ',dupWidget)
            
            batch.set(refWidget, Object.assign({}, widget))
            batch.set(refDupWidget, Object.assign({}, dupWidget))

            batch.commit().then((_) => {
                onResolve({
                    message: 'success',
                    result: refDupWidget.id
                });
            })
            .catch((err) => {
                onResolve({
                    message: 'error',
                    result: err
                })
            })

        }
        else {

        

        // reference to widgets by module
        let widgetsByModuleRef = firestore.collection('modules').doc(widget.moduleId).collection('widgets').doc();
        let data = {
            uid: widgetsByModuleRef.id,
            title: {
                pt_BR: widget.title['pt_BR'],
                en_US: widget.title['en_US'],
                es_ES: widget.title['es_ES'],
                fr_FR: widget.title['fr_FR'],
                de_DE: widget.title['de_DE']
            },
            proportion: widget.proportion,
            order: widget.order,
            isImage: widget.isImage,
            image: widget.image,
            backgroundColor: widget.backgroundColor,
            borderColor: widget.borderColor,
            borderEnabled: widget.borderEnabled,
            backgroundEnabled: widget.backgroundEnabled,
            storageId: widget.storageId,
            eventId: widget.eventId,
            moduleId: widget.moduleId,
            borderSize: widget.borderSize,
            route: widget.route,
            isExtern: widget.isExtern,
            open_link_behavior: widget.open_link_behavior,
            iconFill: widget.iconFill,
            subtitleTxtColor: widget.subtitleTxtColor,
            subtitleBgColor: widget.subtitleBgColor,
            typeVision: widget.typeVision,
            widgetVisForLoggedUsers: widget.widgetVisForLoggedUsers,
            widgetVisForUnloggedUsers: widget.widgetVisForUnloggedUsers,
            groups: widget.groups,
            gridArea: widget.gridArea,
            iframeImg: widget.iframeImg,
            iframeSrc: widget.iframeSrc !== undefined ? widget.iframeSrc : ''
        }
        console.log(data)
        widgetsByModuleRef.set(data)
            .then((_) => {
                onResolve({
                    message: 'success',
                    result: widgetsByModuleRef.id
                });
            })
            .catch((err) => {
                onResolve({
                    message: 'error',
                    result: err
                })
            })
        }
    }

    editWidget(widget: Widget, onResolve) {
        let firestore = this.aFirestore.firestore;
        // reference to widgets by module
        let widgetsByModuleRef = firestore.collection('modules').doc(widget.moduleId).collection('widgets').doc(widget.uid);

        let data = {
            uid: widget.uid,
            title: {
                pt_BR: widget.title['pt_BR'],
                en_US: widget.title['en_US'],
                es_ES: widget.title['es_ES'],
                fr_FR: widget.title['fr_FR'],
                de_DE: widget.title['fr_FR']
            },
            proportion: widget.proportion,
            order: widget.order,
            isImage: widget.isImage,
            image: widget.image,
            backgroundColor: widget.backgroundColor,
            borderColor: widget.borderColor,
            borderEnabled: widget.borderEnabled,
            backgroundEnabled: widget.backgroundEnabled,
            storageId: widget.storageId,
            eventId: widget.eventId,
            moduleId: widget.moduleId,
            borderSize: widget.borderSize,
            route: widget.route,
            isExtern: widget.isExtern,
            iconFill: widget.iconFill,
            subtitleTxtColor: widget.subtitleTxtColor,
            subtitleBgColor: widget.subtitleBgColor,
            typeVision: widget.typeVision,
            open_link_behavior: widget.open_link_behavior,
            groups: widget.groups,
            widgetVisForLoggedUsers: widget.widgetVisForLoggedUsers,
            widgetVisForUnloggedUsers: widget.widgetVisForUnloggedUsers,
            iframeImg: widget.iframeImg,
            iframeSrc: widget.iframeSrc
        }

        widgetsByModuleRef.update(data)
            .then((_) => {
                onResolve({
                    message: 'success',
                    result: _
                })
            })
            .catch((err) => {
                onResolve({
                    message: 'error',
                    result: err
                })
            })
    }

    removeWidget(moduleId: string, widgetId: string, onResolve) {
        let firestore = this.aFirestore.firestore;
        // reference to widgets by module
        let widgetsByModuleRef = firestore.collection('modules').doc(moduleId).collection('widgets').doc(widgetId);

        widgetsByModuleRef
            .delete()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                console.log(e);
                onResolve(false);
            });
    }

    changeOrderWidgets(widgets: Array<Widget>, onResolve) {
        let firestore = this.aFirestore.firestore;
        let size = widgets.length;
        let cont = 0;

        if (size >= 1) {
            widgets.forEach(element => {
                // reference to widgets by module
                let widgetsByModuleRef = firestore.collection('modules').doc(element.moduleId).collection('widgets').doc(element.uid);

                widgetsByModuleRef.update(element)
                    .then((_) => {
                        if (cont == size - 1) {
                            onResolve(true);
                        }
                        cont++;

                    })
                    .catch((error) => {
                        if (cont == size - 1) {
                            onResolve(false);
                        }
                        cont++;

                    })
            });
        } else {
            onResolve(true);
        }
    }

    async updateModule(eventId: string, moduleId: string, module: ModuleWidget, image: any, imageDesktop: any,onResolve) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();
        if (image !== null && image !== undefined) {
            module.moduleBackgroundImage = image;
            module = Object.assign({}, module);
            batch.update(refModule, module);
            batch.update(refEvent, module);

            return await batch
                .commit()
                .then(_ => onResolve(true))
                .catch((e) => {
                    console.error(e);
                    onResolve(false);
                });
        } else  if (imageDesktop !== null && imageDesktop !== undefined) {
            module.moduleBackgroundImageDesktop = imageDesktop;
            module = Object.assign({}, module);
            batch.update(refModule, module);
            batch.update(refEvent, module);

            return await batch
                .commit()
                .then(_ => onResolve(true))
                .catch((e) => {
                    console.error(e);
                    onResolve(false);
                });
        } else {
            module = Object.assign({}, module);
            batch.update(refModule, module);
            batch.update(refEvent, module);
            batch
                .commit()
                .then(_ => onResolve(true))
                .catch((e) => {
                    console.error(e);
                    onResolve(false);
                });
        }
    }
}
