import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/reducers';
import { take } from 'rxjs/operators';
import { getAttendeesAnalytics, getFeedsAnalytics, getGeneralAnalytics, getUsersAnalytics } from 'src/app/shared/selectors/analytics.selectors';
import * as jsPDF from 'jspdf';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-general-analytics',
    templateUrl: './general-analytics.component.html',
    styleUrls: ['./general-analytics.component.scss']
})
export class GeneralAnalyticsComponent implements OnInit {
    public userLanguage: string

    eventId: string;
    event: Event = null;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    loader: boolean = true;
    isLoadingPDF: boolean = false;

    // ATTENDEES INFOS
    totalAccess: number = 0;
    totalUniqueUsers: number = 0;
    profileEdited: number = 0;
    blockUserInfos: boolean = false;

    // MODULES INFOS
    loaderModules: boolean = true;
    modules: Array<any> = [];

    bestConsultedModules = [];
    totalAttendees: number = 0;
    totalAttendeesEngaged: number = 0;
    totalFeedsComments: number = 0;
    totalFeedsLikes: number = 0;
    totalFeedsPosts: number = 0;
    totalScreenViews: number = 0;
    averageScreenViews: number = 0;

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private dbAttendees: DbAttendeesProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.eventId = this.route.snapshot.params['uid'];
        this.getUserLanguage();

        Promise.all([
            this.getTotalAttendees(),
            this.getTotalAttendeesEngaged(),
            this.getFeedAnalytics(),
            this.getGeneralAnalytics(),
            this.getUsersAnalytics(),
            this.getEvent(),
        ])
            .catch((err) => console.error(err))
            .finally(() => this.loader = false);
    }

    getTotalAttendees() {
        return new Promise<void>((resolve) => {
            this.dbAnalytics.getAttendeesModulesAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getAttendeesAnalytics).subscribe((attendeesAnalytics) => {
                    this.totalAttendees = attendeesAnalytics.totalAttendees;
                    resolve();
                })
            });
        })
    }

    getTotalAttendeesEngaged() {
        return new Promise<void>((resolve) => {
            this.dbAttendees.getAttendeesByEvent(this.eventId, (attendees) => {
                this.totalAttendeesEngaged = 0;
                attendees.forEach((attendee) => {
                    if (attendee.engagementPoints || attendee.points > 0) this.totalAttendeesEngaged++;
                })
                resolve();
            });
        })
    }

    getFeedAnalytics() {
        return new Promise<void>((resolve) => {
            this.dbAnalytics.getFeedsAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getFeedsAnalytics).subscribe((feedsAnalytics) => {
                    this.totalFeedsPosts = feedsAnalytics.totalFeedsPosts;
                    this.totalFeedsLikes = feedsAnalytics.totalFeedsLikes;
                    this.totalFeedsComments = feedsAnalytics.totalFeedsComments;
                    resolve();
                })
            });
        })
    }

    getGeneralAnalytics() {
        return new Promise<void>((resolve) => {
            this.dbAnalytics.getGeneralAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getGeneralAnalytics).subscribe((generalAnalytics) => {
                    this.totalAccess = (generalAnalytics && generalAnalytics.totalAccess) ? generalAnalytics.totalAccess : 0;
                    this.profileEdited = (generalAnalytics && generalAnalytics.totalProfilEdition) ? generalAnalytics.totalProfilEdition : 0;
                    this.modules = (generalAnalytics && generalAnalytics.bestConsultedModules) ? generalAnalytics.bestConsultedModules : [];
                    this.bestConsultedModules = (generalAnalytics && generalAnalytics.bestConsultedModules) ? generalAnalytics.bestConsultedModules : [];
                    resolve();
                })
            });
        })
    }

    getUsersAnalytics() {
        return new Promise<void>((resolve) => {
            this.dbAnalytics.getUsersAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getUsersAnalytics).subscribe((usersAnalytics) => {
                    this.totalUniqueUsers = (usersAnalytics && usersAnalytics.totalUniqueUsers) ? usersAnalytics.totalUniqueUsers : 0;
                    resolve()
                })
            })
        })
    }

    getEvent() {
        return new Promise<void>((resolve) => {
            this.dbEvent.getEvent(this.eventId, (event) => {
                this.event = event;
                this.formatedLanguage.convertLangFormat(event.language);
                resolve();
            });
        })
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    /**
     * Export analytics of tracking
     */
    exportAll() {
        this.loader = true;
        this.dbAnalytics.exportGeneralAnalytics(this.eventId, this.userLanguage).subscribe(() => {
            this.loader = false;
        }, (error) => {
            this.loader = false;
        })
    }

    async exportGeneralAnalyticsPDF() {
        try {
            this.isLoadingPDF = true;
            const pdf = new jsPDF();

            /* ---- HEADER-BEGIN ---- */
            // title
            pdf.setFontSize(24);
            pdf.setTextColor(40);
            pdf.text(this.event.title, 14, 15);
            pdf.setFontSize(18);
            pdf.text(this.translateService.instant('comp.analytics.analytics_report'), 14, 25);
            pdf.setFontSize(12);
            pdf.setTextColor(100);
            pdf.text(this.translateService.instant('comp.analytics.report_description'), 14, 32);
            // ceu-app logo
            const isLogoAdded = await this.addImageToPDF(pdf, '/assets/images/ceuApp.png', 180, 0, 25, 25);
            if (!isLogoAdded) throw new Error('Failed to add logo to PDF');
            /* ---- HEADER-END ---- */

            /* ---- BLUE CONTAINER SETTINGS ---- */
            const containerWidth = 180;
            const containerHeight = 92;
            const containerX = 14;
            const containerY = 35;
            const containerRadius = 5;

            /* ---- BLUE CONTAINER DRAWING ---- */
            pdf.setFillColor(47, 115, 162);
            pdf.setDrawColor(47, 115, 162);
            pdf.roundedRect(containerX, containerY, containerWidth, containerHeight, containerRadius, containerRadius, 'FD');

            /* ---- WHITE CARD SETTINGS ---- */
            const cardSize = 38; // width and height are the same
            const cardMargin = 5;
            const cardRadius = 5;
            let currentCardX = containerX + cardMargin;
            let currentCardY = containerY + cardMargin;

            this.totalScreenViews = this.bestConsultedModules.reduce((acc, currentModule) => acc + currentModule.total_access, 0);
            this.averageScreenViews = (this.totalAttendees > 0) ? Math.round(this.totalScreenViews / this.totalAttendees) : 0;

            /* ---- WHITE CARD DATA ---- */
            const dataCards = [
                { title: this.translateService.instant('comp.analytics.menu.attendees'), value: `+${this.totalAttendees}`, imgSrc: '/assets/images/activeAttendees.png' },
                { title: this.translateService.instant('comp.analytics.attendees.attendees_engaged'), value: `+${this.totalAttendeesEngaged}`, imgSrc: '/assets/images/activeAttendees.png' },
                { title: this.translateService.instant('comp.analytics.screens_viewed'), value: `+${this.totalScreenViews}`, imgSrc: '/assets/images/screenViews.png' },
                { title: this.translateService.instant('comp.analytics.average_screens_viewed'), value: `+${this.averageScreenViews}`, imgSrc: '/assets/images/averageScreenViews.png' },
                { title: this.translateService.instant('comp.analytics.posts'), value: `+${this.totalFeedsPosts}`, imgSrc: '/assets/images/post.png' },
                { title: this.translateService.instant('comp.analytics.likes'), value: `+${this.totalFeedsLikes}`, imgSrc: '/assets/images/like.png' },
                { title: this.translateService.instant('comp.analytics.feeds.comments'), value: `+${this.totalFeedsComments}`, imgSrc: '/assets/images/comment.png' },
                { title: this.translateService.instant('comp.analytics.event_metrics'), imgSrc: !this.event.logo.url ? '/assets/images/ceuApp.png' : this.event.logo.url },
            ];

            /* ---- DRAWING EACH WHITE CARD ---- */
            for (let i = 0; i < dataCards.length; i++) {
                /* ---- KEEP WHITE CARDS IN THE BLUE CONTAINER ---- */
                if (currentCardX + cardSize > containerX + containerWidth - cardMargin) {
                    currentCardX = containerX + cardMargin;
                    currentCardY += cardSize + cardMargin;
                }
                /* ---- DRAW THE WHITE CARD ---- */
                pdf.setFillColor(255);
                pdf.roundedRect(currentCardX, currentCardY, cardSize, cardSize, cardRadius, cardRadius, 'F');

                /* ---- IMAGE SETTINGS ---- */
                const imgWidth = 15;
                const imgHeight = 15;
                const imgX = currentCardX + (cardSize - imgWidth) / 2;
                const imgY = currentCardY + (cardSize - imgHeight) / 4;
                const isCardImageAdded = await this.addImageToPDF(pdf, dataCards[i].imgSrc, imgX, imgY, imgWidth, imgHeight);
                if (!isCardImageAdded) throw new Error('Failed to add card images to PDF');

                /* ---- TITLE SETTINGS ---- */
                pdf.setFontSize(10);
                pdf.setFontStyle('bold');
                pdf.setTextColor(0);
                if (dataCards[i].title.includes('\n')) {
                    const title1 = dataCards[i].title.split('\n', 2)[0].trim();
                    const title2 = dataCards[i].title.split('\n', 2)[1].trim();
                    pdf.text(title1, currentCardX + (cardSize / 2) - (pdf.getTextWidth(title1) / 2), imgY + imgHeight + 4);
                    pdf.text(title2, currentCardX + (cardSize / 2) - (pdf.getTextWidth(title2) / 2), imgY + imgHeight + 8);
                } else {
                    pdf.text(dataCards[i].title, currentCardX + (cardSize / 2) - (pdf.getTextWidth(dataCards[i].title) / 2), imgY + imgHeight + 5);
                }

                /* ---- VALUE SETTINGS ---- */
                if (dataCards[i].value) {
                    pdf.setFontSize(20);
                    pdf.text(dataCards[i].value, currentCardX + (cardSize / 2) - (pdf.getTextWidth(dataCards[i].value) / 2), imgY + imgHeight + 15);
                }

                currentCardX += cardSize + cardMargin;
            }

            /* ---- TABLE SETTINGS ---- */
            const tableWidth = 180;
            const tableX = 14;
            const tableY = 140;
            const tableLineHeight = 10;
            let tableCurrentY = tableY;

            /* ---- TABLE HEADER ---- */
            pdf.setFillColor(47, 115, 162);
            pdf.setTextColor(255);
            pdf.setFontStyle('bold');
            pdf.setFontSize(14);
            pdf.rect(tableX, tableCurrentY, tableWidth, tableLineHeight * 1.5, 'F');
            pdf.text(this.translateService.instant('comp.analytics.general.modules_access'), tableX + 5, tableCurrentY + tableLineHeight);
            tableCurrentY += tableLineHeight * 1.5;

            /* ---- TABLE ROWs ---- */
            pdf.setLineWidth(0.1);
            pdf.setDrawColor(255);
            this.bestConsultedModules.forEach(module => {
                if (tableCurrentY > 285) {
                    tableCurrentY = 5;
                    pdf.addPage()
                };
                pdf.setFillColor(255);
                pdf.rect(tableX, tableCurrentY, tableWidth, tableLineHeight * 1.5, 'F');
                pdf.setTextColor(0);
                pdf.setFontStyle('normal');
                pdf.text(module?.name[this.formatedLanguage.language], tableX + 10, tableCurrentY + tableLineHeight);
                pdf.text(`${module.total_access} acessos`, tableX + tableWidth - pdf.getTextWidth(`${module.total_access} acessos`) - 10, tableCurrentY + tableLineHeight);
                tableCurrentY += tableLineHeight * 1.25;
            });

            // open in browser --> window.open(pdf.output('bloburl'), '_blank');
            pdf.save('analytics.pdf');
        } catch (error) {
            console.error(error);
            this.errorSwal.fire();
        } finally {
            this.isLoadingPDF = false;
        }
    }

    addImageToPDF(pdf: jsPDF, imgSrc: string, x: number, y: number, width: number, height: number) {
        return new Promise<boolean>((resolve) => {
            const img = new Image();
            img.src = imgSrc;
            img.onload = () => {
                pdf.addImage(img, 'PNG', x, y, width, height);
                resolve(true);
            };
            img.onerror = () => {
                resolve(false);
            }
        });
    };
}
