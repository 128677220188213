import { Component, OnInit, ViewChild, NgModule, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { Speaker } from 'src/app/models/speakers';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { EmailProvider } from '../../../../../providers/email/email.service';
import { OrderType } from 'src/app/paths/orderType';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RegexProvider } from 'src/app/providers/regex/regex.service';
import { TranslateService } from '@ngx-translate/core';
import { eventCustomField } from '../../../../../models/eventCustomField';
import { eventCustomFieldAnswerOption } from '../../../../../models/eventCustomFieldAnswerOption';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { MenuIcons, icons } from 'src/app/models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { DBCustomFieldsProvider } from 'src/app/providers/database/db-custom-fields.service';
import { NameModule } from 'src/app/models/name-module';
import { UUID } from 'angular2-uuid';
import { TypeUser } from 'src/app/enums/typeUser';

declare let $: any;
type AOA = Array<Array<any>>;

@Component({
    selector: 'app-speakers',
    templateUrl: './speakers.component.html',
    styleUrls: ['./speakers.component.scss']
})

@NgModule({
    declarations: [FilterPipe],
})
export class SpeakersComponent implements OnInit {
    public module = null

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('notReconfiguredSwal') public notReconfiguredSwal: SwalComponent;
    @ViewChild('confirmResetAccount') public confirmResetAccount: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('notExport') public notExport: SwalComponent;
    @ViewChild('successEmailSwal') public successEmailSwal: SwalComponent;
    @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
    @ViewChild('successPasswordSwal') public successPasswordSwal: SwalComponent;
    @ViewChild('errorPasswordSwal') public errorPasswordSwal: SwalComponent;
    @ViewChild('inputFile') public inputFile: ElementRef;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    }

    onReorderShow: boolean = false;

    public p: number = 1;
    public deleteSpeakerId: string;
    deleteSpeakerIndex: number;
    public listSpeakers: Array<Speaker> = [];
    public allListSpeakers: Array<Speaker> = [];
    public loader: boolean = true;
    public eventId: string = null;
    public moduleId: string = null;
    public emailsSpeaker: string = null;
    public emailTitle: string = null;
    public emailBody: string = null;
    public speakerObjEmail: Speaker;
    public speakerRemoveId;
    public selectedAllInput: boolean = false;
    term: string = '';
    typeOrder: string = null;
    public speakerSelected = [];

    public allowedEditProfileVal;


    //CUSTOM FIELDS
    // Custom Field - Globais.
    errorFormCustomCreate: boolean;
    errorFormCustomEdit: boolean;
    formCustom: FormGroup;
    formCustomEdit: FormGroup;
    hideElement: boolean = false; // habilita ou desabilita o campo de edicao do Custom field
    listCustomField: any[] = []; // array list custom field speaker.
    // editCustomField: eventCustomField;
    showTypeCustom: boolean = false; // mostra ou oculta o campo para criação de opções de resposta
    showTypeCustomEdit: boolean = false; // mostra ou oculta o campo para criação de opções de resposta do edit
    cfAnsOpts = { [UUID.UUID()]: new NameModule('','','','','')}; // custom field answer options
    edCfAnsOpts = {};   // edited cf answer options
    cfInpAddAnswerOp = "";
    ldr = {
        btnSvCFChanges: false,
    };

    // EXCEL PROPERTIES
    identifier: number = 0;
    name: number = 1;
    email: number = 2;
    emailRecovery: number = 3;
    title: number = 4
    company: number = 5;
    description: number = 6;
    picture: number = 7;
    website: number = 8;
    facebook: number = 9;
    twitter: number = 10;
    linkedin: number = 11;
    instagram: number = 12;
    groups: number = 13;
    documents: number = 14;
    attendees: number = 15;
    customField: number = 16;

    // EXCEL PROPOERTIES TRANSLATED
    translatedIdentifier: number = 0;
    translatedTitle: number = 1;
    translatedDescription: number = 2;
    customFieldTranslated: number = 3;

    data: any = null;
    dataImportPtBR: any = null;
    dataImportEnUS: any = null;
    dataImportEsES: any = null;
    dataImportFrFR: any = null;
    dataImportDeDE: any = null;
    exportSpeakers: AOA = null;
    newSpeakers: Array<Speaker>; // new Speakers.
    messageErrors: Array<String>; // array with error messages.
    nameFile: string = null; // excel input change name
    theError: boolean = false; // display error messages
    theErrorApi: boolean = false; // display error after send data to API
    theErrorApiDb: boolean = false; // display error after send data to API and have DB error
    successMsg: boolean = false; // display success message
    loaderImport: boolean = false; // loader after click button import
    someSpeakersExcelDownload: Array<Speaker> = []; // speakers with errors to download excel
    dataExportSpeakers: AOA = null; // data export all speakers
    dataExportSpeakers_PtBR: AOA = null;
    dataExportSpeakers_EnUS: AOA = null;
    dataExportSpeakers_EsES: AOA = null;
    dataExportSpeakers_FrFR: AOA = null;
    dataExportSpeakers_DeDE: AOA = null;
    speakerErrorsApi: Array<Speaker> = [];
    editProfileVal: boolean = false;
    requiredEditProfileVal: boolean = false;
    requiredField: boolean = false;
    loaderBtnCustomField: boolean = false;
    loaderEditCustomField: boolean = false;
    fieldOptions;
    fieldOptionsCustom = null;
    moduleGroupId: string = null;
    public totalSpeakerUpdated: number = 0;
    public totalSpeakerCreated: number = 0;
    public totalSpeakerFail: number = 0;
    listGroup = [];
    groupFilter: string = 'all';
    invalidCustomFields: Array<any> = [];
    invalidGroups: Array<any> = [];
    invalidAttendees: Array<any> = [];
    theErrorGroup: boolean = false;
    theErrorAttendees: boolean = false;
    invalidDocuments: Array<any> = [];
    theErrorDocuments: boolean = false;
    theErrorCustom: boolean = false;
    loaderDeleteCustom: boolean = false;
    uidCustomFieldDelete: string = null;
    indexCustomFieldDelete: number;
    // deleteCustomId: string = null;
    // deleteCustomIndex: number = null;

    loaderIndividualEmail: boolean = false;
    errorEmailTitle: boolean = false;
    errorEmailBody: boolean = false;
    speakerResetPasswordEmails: any = null;
    speakerResetPass: Speaker = null;
    event: any;

    first_access_language: string = null;

    //icons custom fields
    iconsImg: MenuIcons[] = icons;
    selectedIcon: string = PathIcons.icon_quiz;
    selectedIconFamily: string = 'material-icons';
    selectedIconEdit: string = PathIcons.icon_quiz;
    selectedIconFamilyEdit: string = 'material-icons';

    // get the language of the user.
    public userLanguage: string
    languages: Languages = null //event languages
    activeCustomFieldLanguage: string = 'PtBR';
    principalEventLang: string = 'PtBR';

    attendeesField: string = "";

    allowFilterCompany: boolean;
    allowFilterPost: boolean;
    showLetters: boolean;
    emailVisibilty : boolean;

    disableFilterComVisibility = false; // prevent multi click on filterVisibility
    disableFilterPostVisibility = false; // prevent multi click on filterVisibility
    disableLettreVisibility = false; // prevent multi click on lettersVisibility
    msgCustomFieldError: string;

    companyFilterText: string = ''
    postFilterText: string = ''
    disabledCompanyFilterText: boolean = false
    disabledPostFilterText: boolean = false

    constructor(
        private route: ActivatedRoute,
        private dbSpeaker: DbSpeakersProvider,
        private emailSvc: EmailProvider,
        private regex: RegexProvider,
        private translateService: TranslateService,
        private fb: FormBuilder,
        private dbGroups: DbGroupsProvider,
        private dbEvent: DbEventsProvider,
        private dragula: DragulaService,
        private global: GlobalService,
        private zone: NgZone,
        private SCustomFields: DBCustomFieldsProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        
        dragula.createGroup('bag-custom', this.dragulaOptions);

        this.hideElement = false; // habilita ou desabilita o campo de edicao do Custom field

        // Custom field - Iniciando Formulário para edição e criação.
        this.formCustom = fb.group({ // criando formCustom para pegar as informações do formulário custom field
            'name': this.fb.group({
                'PtBR': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'EnUS': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'EsES': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'FrFR': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'DeDE': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
            }),
            'group_type': ['text'],
            'group_exibicao': ['1'],
            'group_edicao': ['2']
        });

        this.formCustomEdit = fb.group({ // criando formCustom para pegar as informações do formulário custom field
            'name': this.fb.group({
                'PtBR': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'EnUS': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'EsES': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'FrFR': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                'DeDE': ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
            }),
            'type': [null],
            'group_exibicao': [null],
            'group_edicao': [null],
            'uid': [null],
        });
    }

    ngOnInit() {
        // start dragula reorder bag
        this.dragula.dropModel('bag-custom').subscribe((value: any) => {
            this.onReorder(value);
        });
        this.getUserLanguage();
        this.getAllowedEditProfile();
        this.getModule();
        this.loadFieldsOptions();
        this.getCustomFields();
        this.getEvent();

        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            $(document).on('click', ' .dropdown-menu', function (e) {
                e.stopPropagation();
            });
            this.listGroup = [];
            this.listGroup = result['groups'];
            this.moduleGroupId = result['moduleId'];
        });
    }

    ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
        if (this.moduleId !== this.route.params['_value']['moduleId']) {
            this.zone.run(() => {
                this.moduleId = this.route.params['_value']['moduleId']
                this.fieldOptionsCustom = null;
                this.listCustomField = [];
                this.getUserLanguage();
                this.getAllowedEditProfile();
                this.getModule();
                this.getCustomFields();
                this.loadFieldsOptions();

                this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
                    $(document).on('click', ' .dropdown-menu', function (e) {
                        e.stopPropagation();
                    });
                    this.listGroup = [];
                    this.listGroup = result['groups'];
                    this.moduleGroupId = result['moduleId'];
                });
            })
        }
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy('bag-custom');
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $('.dropdown-menu-custom').removeClass('show');
    }

    setIconEdit(item) {
        this.selectedIconEdit = item.icon;
        this.selectedIconFamilyEdit = item.family;
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.requiredEditProfileVal = event.required_edit_profile;
            this.principalEventLang = this.convertLangFormat(this.event.language);
            this.activeCustomFieldLanguage = this.convertLangFormat(this.event.language);
            this.languages = event.languages;
        });
    }

    // get the language of the user.
    userLanguageFormat: string = 'PtBR';
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
            this.userLanguageFormat = this.convertLangFormat(language);
        })
    }


    // update order of modules
    onReorder(order: any): void {
        // this.onReorderShow = true;
        // this.listCustomField = order.targetModel;
        // for (let i = 0; i < (this.listCustomField).length; ++i) {
        //     let customId = this.listCustomField[i].uid;
        //     this.fieldOptionsCustom[customId].order = i;
        // }
        this.onReorderShow = true;
        this.listCustomField = order.targetModel;
        for (let i = 0; i < this.listCustomField.length; ++i) {
            this.listCustomField[i].order = i;
        }
    }



    filterSpeakerGroup() {
        if (this.groupFilter == 'all') {
            this.listSpeakers = this.allListSpeakers;
        } else {
            this.listSpeakers = [];
            for (let speaker of this.allListSpeakers) {
                for (let group of speaker.$groups) {
                    if (group.uid == this.groupFilter) {
                        this.listSpeakers.push(speaker);
                    }
                }
            }
        }
    }

    loadFieldsOptions() {
        this.dbSpeaker.getSpeakersFieldOptions(this.moduleId, (fields) => {
            this.fieldOptions = fields;
        });
    }

    updateFieldRequired(field, status) {
        this.dbSpeaker.changeRequiredField(this.moduleId, field, status, this.fieldOptions);
    }

    updatUniqueEditField(field, status) {
        this.dbSpeaker.changeUniqueEditField(this.moduleId, field, status, this.fieldOptions);
    }

    updateHideField(field, status) {
        this.dbSpeaker.changeHideField(this.moduleId, field, status, this.fieldOptions);
    }

    saveCustomFieldsChanges() {
        this.ldr.btnSvCFChanges = true;
        this.SCustomFields.updateCustomFieldsOrder(
            this.eventId, 
            this.moduleId, 
            this.listCustomField
        ).catch(error => console.error(error))
        .finally(() => { this.ldr.btnSvCFChanges = false; });
    }

    getModule() {
        this.dbSpeaker.speakerModule(this.moduleId, (module) => {
            this.module = module

            this.typeOrder = module['orderUsers'];
            this.allowFilterCompany = module['allowFilterCompany'] !== undefined ? module['allowFilterCompany'] : false;
            this.allowFilterPost = module['allowFilterPost'] !== undefined ? module['allowFilterPost'] : false;
            this.showLetters = module['showLetters'] !== undefined ? module['showLetters'] : true;
            this.emailVisibilty = module['emailVisibilty'] !== undefined ? module['emailVisibilty'] : false;
            this.companyFilterText = this.module['companyFilterText'] ? this.module['companyFilterText'] : ''
            this.postFilterText = this.module['postFilterText'] ? this.module['postFilterText'] : ''

            if (!module.firstAccessCodeLanguage) {
                this.first_access_language = this.event.language;
                this.changeFirstAccessLanguage();
            } else {
                this.first_access_language = module.firstAccessCodeLanguage;
            }
            this.attendeesField = module.attendeesField;
            this.loadSpeakers();
        });
    }

    loadSpeakers() {
        this.loader = true;
        this.listSpeakers = [];
        this.allListSpeakers = [];
        this.dbSpeaker.getSpeakers(
            this.eventId, 
            this.typeOrder
        ).then(speakers => {
            speakers.forEach(spk => {
                this.listSpeakers.push(this.instantiateSpeaker(spk));
                this.allListSpeakers.push(this.instantiateSpeaker(spk));
            })
            this.msgCustomFieldError = '';
        }).catch(err => {
            console.error(err);
            this.msgCustomFieldError = this.translateService
                .instant('global.customField_create_error');
        }).finally(() => { this.loader = false; })
    }

    instantiateSpeaker(data) {

        let name = data.name;
        let speaker = new Speaker(name, 5, this.eventId);

        speaker.$queryName = data.queryName;
        speaker.$email = data.email;
        speaker.$uid = data.uid;
        speaker.$emailRecovery = data.emailRecovery;
        speaker.$identifier = data.id;
        speaker.$description = data.description;
        speaker.$company = data.company;
        speaker.$title = data.title;
        speaker.$photoUrl = data.photoUrl;
        speaker.$website = data.website;
        speaker.$facebook = data.facebook;
        speaker.$instagram = data.instagram;
        speaker.$linkedin = data.linkedin;
        speaker.$twitter = data.twitter;
        speaker.$createdAt = data.createdAt;
        speaker.$firstAccess = data.firstAccess;
        let groups = [];
        let documents = [];

        for (const uid in data.groups) {
            groups.push(data.groups[uid]);
        }
        speaker.$groups = groups;

        for (const uid in data.documents) {
            documents.push(data.documents[uid]);
        }
        speaker.$documents = documents;

        return speaker;
    }

    getSpeakerForSendEmail(speaker: Speaker) {
        this.speakerObjEmail = speaker;
        if (speaker['emailRecovery'] !== null) {
            this.emailsSpeaker = speaker['name'] + ' ' + speaker['name'] + ' <' + speaker['email'] + '; ' + speaker['emailRecovery'] + '>';
        } else {
            this.emailsSpeaker = speaker['name'] + ' ' + speaker['name'] + ' <' + speaker['email'] + '>';
        }
    }

    sendEmailToSpeaker() {
        let speakerEmails = [];
        if (this.speakerObjEmail['emailRecovery'] !== null) {
            speakerEmails = [this.speakerObjEmail['email'], this.speakerObjEmail['emailRecovery']];
        } else {
            speakerEmails = [this.speakerObjEmail['email']];
        }

        let email = {
            title: this.emailTitle,
            body: this.emailBody,
            email: speakerEmails
        };

        if (this.emailTitle !== null) {
            if (this.emailBody !== null) {
                this.loaderIndividualEmail = true;
                this.emailSvc.sendEmailToUser(email, (data) => {
                    this.loaderIndividualEmail = false;
                    if (data['result'] == true) {
                        this.successEmailSwal.fire();
                        $('#sendEmailUser').modal('toggle');
                        this.emailTitle = null;
                        this.emailBody = null;
                        this.speakerObjEmail = null;
                    } else {
                        this.errorEmailSwal.fire();
                    }
                })
            } else {
                // body required
                this.errorEmailBody = true;
            }
        } else {
            // title required
            this.errorEmailTitle = true;
        }
    }


    getUserResetPassword(speaker: Speaker) {
        this.speakerResetPass = speaker;
        if (speaker['emailRecovery'] !== null) {
            this.speakerResetPasswordEmails = [speaker['email'], speaker['emailRecovery']];
        } else {
            this.speakerResetPasswordEmails = [speaker['email']];
        }
    }

    resetUserPassword() {
        $('#exportLoading').modal('show');
        this.emailSvc.sendEmailResetPassword(this.speakerResetPasswordEmails, this.translateService.instant('global.reset_pass_email_subject'), this.translateService.instant('global.reset_pass_email_password_title'), this.speakerResetPass['uid'], (status) => {
            if (status['result'] == true) {
                $('#exportLoading').modal('toggle');
                this.successPasswordSwal.fire();
            } else {
                $('#exportLoading').modal('toggle');
                this.errorPasswordSwal.fire();
            }
        });
    }

    changeOrder() {
        this.dbSpeaker.changeOrderItems(this.eventId, this.moduleId, this.typeOrder, (data) => {
            if (data == true) {
                this.loadSpeakers();
            }
        })
    }

    orderSpeakers() {
        let aux;
        switch (this.typeOrder) {
            case OrderType.A_Z:
                aux = this.listSpeakers;
                this.listSpeakers = [];
                this.listSpeakers = aux.sort(function (a, b) {
                    if (a.$queryName < b.$queryName) { return -1; }
                    if (a.$queryName > b.$queryName) { return 1; }
                    return 0;
                })
                break;

            case OrderType.Z_A:
                aux = this.listSpeakers;
                this.listSpeakers = [];
                this.listSpeakers = aux.sort(function (a, b) {
                    if (a.$queryName < b.$queryName) { return 1; }
                    if (a.$queryName > b.$queryName) { return -1; }
                    return 0;
                })
                break;

            case OrderType.recentFirst:
                aux = this.listSpeakers;
                this.listSpeakers = [];
                this.listSpeakers = aux.sort((a, b) => b.$createdAt - a.$createdAt); // For descending sort
                break;

            case OrderType.oldFirst:
                aux = this.listSpeakers;
                this.listSpeakers = [];
                this.listSpeakers = aux.sort((a, b) => a.$createdAt - b.$createdAt); // For ascending sort
                break;
        }
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.listSpeakers.length; i++) {
                this.speakerSelected[this.listSpeakers[i]['uid']] = true;
            }
        } else {
            for (let i = 0; i < this.listSpeakers.length; i++) {
                this.speakerSelected[this.listSpeakers[i]['uid']] = false;
            }
        }
    }

    getRemoveSpeaker(speakerId, index) {
        this.speakerRemoveId = speakerId;
    }

    removeSpeaker() {
        this.loader = true;
        let listRemove = [this.speakerRemoveId];

        this.dbSpeaker.removeSpeakers(
            this.eventId, 
            this.moduleId, 
            listRemove
        ).then(() => {
            this.speakerRemoveId = null
            this.loadSpeakers()
            this.successSwal.fire();
        }).catch(err => {
            console.error(err);
            this.errorSwal.fire();
            this.loader = false;
        });
    }

    speakerIndexById(speakerId) {
        return this.listSpeakers.map(function (e) { return e.$uid; }).indexOf(speakerId);
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];
        // let listRemoveIndexes = [];
        for (let i = 0; i < this.listSpeakers.length; i++) {
            if (this.speakerSelected[this.listSpeakers[i].$uid] == true) {
                listRemove.push(this.listSpeakers[i].$uid);
                // listRemoveIndexes.push(i);
            }
        }

        this.dbSpeaker.removeSpeakers(
            this.eventId, 
            this.moduleId, 
            listRemove
        ).then(() => {
            for (let i = 0; i < this.speakerSelected.length; i++) {
                this.speakerSelected[this.listSpeakers[i].$uid] = false;
            }
            this.selectedAllInput = false;
            this.loadSpeakers();
            this.successSwal.fire();
        }).catch(err => {
            console.error(err);
            this.errorSwal.fire();
        });
    }

    // ------------------------------------------------------------------------
    // TODOS OS MÉTODOS E ACESSO AO BANCO RELACIONADO AO CUSTOM FIELD SPEAKER.
    // ------------------------------------------------------------------------

    getCustomFields() {
        return new Promise<void>((res, rej) => {
            this.SCustomFields.getCustomFields(this.eventId, this.moduleId)
                .then((customFields) => {
                    this.listCustomField = customFields;
                    res();
                }).catch((error) => rej(error))
        });
    }

    /**
     * Update attendees field
     */
    attendeesFieldChanged() {
        this.dbSpeaker.updateAttendeesField(this.eventId, this.moduleId, this.attendeesField);
    }

    changeCustomExibition() {
        let exibicaoValue = this.formCustom.value.group_exibicao;
        let ctrlEdicao = this.formCustom.get('group_edicao');

        if (exibicaoValue == '3') {
            this.formCustom.controls['group_edicao'].setValue('3');
            ctrlEdicao.disable()
        } else {
            ctrlEdicao.enable();
        }
    }

    //mostra ou oculta o bloco de adicionar opções de resposta do custom field
    changeTypeCustom() {
        //pega o valor do campo relacionado ao tipo do campo
        let value = this.formCustom.controls.group_type.value;

        //caso o valor do campo seja 'texto' oculta o campo e caso seja 'select' exibe
        if (value == 'text') {
            this.showTypeCustom = false;
        } else {
            this.showTypeCustom = true;
        }
    }

    //mostra ou oculta o bloco de adicionar opções de resposta do custom field
    changeTypeCustomEdit() {
        //pega o valor do campo relacionado ao tipo do campo
        let value = this.formCustomEdit.controls.type.value;

        //caso o valor do campo seja 'texto' oculta o campo e caso seja 'select' exibe
        if (value == 'text') {
            this.showTypeCustomEdit = false;
        } else {
            this.showTypeCustomEdit = true;
            this.addNewOptionCustomEdit();
        }
    }

    changeGroupExibicao() {
        if (this.formCustomEdit.controls.group_exibicao.value == '3') {
            this.formCustomEdit.get('group_edicao').disable();
        } else {
            this.formCustomEdit.get('group_edicao').enable();
        }
    }

    addNewOptionCustom() {
        this.cfAnsOpts[UUID.UUID()] = new NameModule("", "", "", "", "");
    }

    addNewOptionCustomEdit() {
        if (!this.cfInpAddAnswerOp.trim()) { return; }

        const value =  new NameModule("", "", "", "", "");
        value[this.activeCustomFieldLanguage] = this.cfInpAddAnswerOp;
        const optionName = this.customFieldInstantiateName(value);
        if (!optionName.status) {
            console.error("An error occurred in AttendeesComponent.customFieldInstantiateName(...)");
            return;
        }

        this.edCfAnsOpts[UUID.UUID()] = optionName.names;
        this.cfInpAddAnswerOp = "";
    }

    getDeleteInputOption(index) {
        delete this.cfAnsOpts[index];
    }

    getDeleteInputOptionEdit(index) {
        delete this.edCfAnsOpts[index];
    }

    customFieldNameRequired: boolean = false;
    customFieldOptionNameRequired: boolean = false;
    customFieldCreateAllow: boolean = true;

    createCustomField() {
        this.customFieldNameRequired = false;
        this.customFieldOptionNameRequired = false;
        this.loaderBtnCustomField = true;
        this.errorFormCustomCreate = false;
        this.customFieldCreateAllow = true;

        const data = this.formCustom.getRawValue();
        const customName = this.customFieldInstantiateName(data.name);
        if (!customName.status) {
            this.customFieldNameRequired = true;
            this.loaderBtnCustomField = false;
            return;
        }

        const customField = new eventCustomField( // Criando o Objeto para enviar ao banco.
            customName.names,
            data.group_type,
            data.group_exibicao,
            data.group_edicao
        );
        customField.icon = this.selectedIcon;
        customField.iconFamily = this.selectedIconFamily;
        customField.order = this.listCustomField.length;

        if (customField.type == "s") {   // select one answer
            customField.answerOptions = {};
            for (const id in this.cfAnsOpts) {
                // verify if at least one possible answer has any character in it
                const answerName = this.validateAnswerValue(this.cfAnsOpts[id]);
                if (!answerName.status) {
                    this.customFieldCreateAllow = false;
                    this.customFieldOptionNameRequired = true;
                    this.loaderBtnCustomField = false;
                    continue;
                }
                customField.answerOptions[id] = Object.assign({}, answerName.answer);
            }
            if (Object.keys(customField.answerOptions).length == 0) {
                $("#warningSelectOption").modal("show");
                return;
            }
        }

        this.SCustomFields.createCustomField(
            this.eventId,
            this.moduleId,
            customField
        ).then((customFieldData) => {
            let checkExist = this.checkIndexExists(
                this.listCustomField,
                customFieldData.uid
            );
            if (checkExist == -1) {
                this.listCustomField.push(customFieldData);
                //exibe alerta de sucesso
                this.successSwal.fire();
            }
            // this.listCustomField.push(customFieldData)
            this.loaderBtnCustomField = false;
            //reseta o form
            this.formCustom.patchValue({
                name: {
                    PtBR: "",
                    EnUS: "",
                    EsES: "",
                    FrFR: "",
                    DeDE: "",
                },
                // group_type: 'text',
                // group_exibicao: '1',
                // group_edicao: '2'
            });

            // this.formCustom.get('group_edicao').enable();

            //reseta a lista de respostas
            this.cfAnsOpts = { [UUID.UUID()]: new NameModule('','','','','')};
            //oculta o bloco de respostas
            // this.showTypeCustom = false;
        }).catch((error) => {
            console.error(error);
            this.loaderBtnCustomField = false;
            this.errorSwal.fire();
        });
    }

    checkIndexExists(array, itemId) {
        return array
            .map(function (e) {
                return e.uid;
            })
            .indexOf(itemId);
    }

    customFieldInstantiateName(names) {
        let obj = {
            status: 1,
            names: null
        }
        let principalLanguage = this.convertLangFormat(this.event.language);
        if (names[principalLanguage] == '') {
            obj.status = 0;
            // error, blank name - required name
        } else {
            for (let lang in names) {
                if (lang !== principalLanguage) {
                    if (names[lang] == '') {
                        names[lang] = names[principalLanguage]
                    }
                }
            }
            obj.status = 1;
            obj.names = names;
        }
        return obj;
    }

    customFieldInstantiateOptionNames(answer) {
        let obj = {
            status: 1,
            answer: null
        }
        let principalLanguage = this.convertLangFormat(this.event.language);

        if (answer[principalLanguage] == '') {
            obj.status = 0;
        } else {
            for (let lang in answer) {
                if (answer[lang] !== principalLanguage) {
                    if (answer[lang] == '') {
                        answer[lang] = answer[principalLanguage];
                    }
                }
            }
            obj.status = 1;
            obj.answer = answer;
        }
        return obj;
    }

    /**
     * @returns an object with two parms, status (boolean) that gives the 
     * result of the validation; and answer (NameModule) with all languages 
     * with some value, i.e., if the field of a language is empty, it receives
     * the value of the event main language
     */
    validateAnswerValue(answer: NameModule) {
        const principalLanguage = this.convertLangFormat(this.event.language);

        if (answer[principalLanguage] == "") {
            return { status: false, answer: null };
        }

        for (let lang in answer) {
            if (
                answer[lang] !== principalLanguage &&
                answer[lang].trim() == ""
            ) {
                answer[lang] = answer[principalLanguage];
            }
        }
        return { status: true, answer: answer }
    }

    // ativa a visualização do campo de edição custom field
    getEditCustom(index: string) {
        // this.editCustomField = this.listCustomField[index];
        this.selectedIconEdit = this.listCustomField[index].icon;
        this.selectedIconFamilyEdit = this.listCustomField[index].iconFamily;

        const aux = `.hidden${index}`;
        if ($(aux).is(":visible")) {
            $(aux).hide();
        } else {
            $(".hideAll").hide();
            $(aux).show();
        }

        const customField = this.listCustomField[index];
        this.formCustomEdit.patchValue({
            uid: customField.uid,
            name: {
                PtBR: customField.name["PtBR"],
                EnUS: customField.name["EnUS"],
                EsES: customField.name["EsES"],
                FrFR: customField.name["FrFR"],
                DeDE: customField.name["DeDE"],
            },
            type: customField.type,
            group_exibicao: customField.exibicao,
            group_edicao: customField.edicao,
        });

        if (customField.type == "s") {
            this.showTypeCustomEdit = true;
            this.edCfAnsOpts = Object.assign({}, customField.answerOptions)
        } else {
            this.showTypeCustomEdit = false;
        }

        if (customField.exibicao == "3") {
            this.formCustomEdit.get("group_edicao").disable();
        } else {
            this.formCustomEdit.get("group_edicao").enable();
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    // Editar o custom field selecionado.
    customFieldNameRequiredEdit: boolean = false;
    editCustomField(
        data: any,
        cf: eventCustomField,
        index: number
    ) {
        this.loaderEditCustomField = true;
        this.customFieldNameRequiredEdit = false;

        let toBeUpdated = {};
        if (
            data.name[this.activeCustomFieldLanguage] != 
            cf.name[this.activeCustomFieldLanguage]
        ) {
            const customName = this.customFieldInstantiateName(data.name);
            if (!customName.status) {
                this.customFieldNameRequiredEdit = true;
                return;
            }
            toBeUpdated['name'] = customName.names;
        }
        if (data.group_exibicao != cf.exibicao) {
            toBeUpdated['exibicao'] = data.group_exibicao;
        }
        if (data.group_edicao != cf.edicao || data.group_edicao == undefined) {
            if (data.group_exibicao == '3') {
                // if data.group_exibicao was changed to '3', data.group_edicao
                // has value 'undefined'
                toBeUpdated['edicao'] = '3';
            } else {
                toBeUpdated['edicao'] = data.group_edicao;
            }
        }

        if (cf.type == 's') {
            const ansOpts = Object.keys(cf.answerOptions);
            const edAnsOpts = Object.keys(this.edCfAnsOpts);
            if (
                ansOpts.length != edAnsOpts.length || 
                ansOpts.some((opt, i) => opt != edAnsOpts[i])
            ) { // check if both arrays are equal
                toBeUpdated["answerOptions"] = {};
                for (const id in this.edCfAnsOpts) {
                    const answerName = this.validateAnswerValue(this.edCfAnsOpts[id]);
                    if (!answerName.status) {
                        continue;
                    }
                    toBeUpdated["answerOptions"][id] = Object.assign({}, answerName.answer)
                }
                if (Object.keys(toBeUpdated["answerOptions"]).length == 0) {
                    delete toBeUpdated["answerOptions"];
                }
            }
        }

        this.SCustomFields.updateCustomField(
            this.eventId,
            this.moduleId,
            cf.uid,
            toBeUpdated
        ).then(() => {
            for (const [key, value] of Object.entries(toBeUpdated)) {
                cf[key] = value;
            }

            this.listCustomField[index] = cf;
            $(".hideAll").hide();
            this.successSwal.fire();
        }).catch((error) => {
            console.error(error);
            this.errorSwal.fire();
        }).finally(() => {
            this.loaderEditCustomField = false;
        });
    }

    getDeleteCustomField(customField, index) {
        this.uidCustomFieldDelete = customField.uid;
        this.indexCustomFieldDelete = index;
    }

    async removeCustomField() {
        this.loaderDeleteCustom = true;
        this.SCustomFields.deleteCustomField(
            this.eventId, 
            this.moduleId, 
            this.uidCustomFieldDelete, 
            TypeUser.ATTENDEE
        ).then(async () => {
            await this.getCustomFields();
            this.successSwal.fire();
            this.loaderDeleteCustom = false;
        }).catch((error) => {
            console.error(error);
            this.errorSwal.fire();
            this.loaderDeleteCustom = false;
        });
    }

    getAllowedEditProfile() {
        this.dbSpeaker.getAllowedEditProfile(this.moduleId, (data) => {
            this.allowedEditProfileVal = data;
        })
    }

    changeAllowedEditProfile() {
        let newValue;

        if (this.allowedEditProfileVal) {
            newValue = false;
        } else {
            newValue = true;
        }

        this.dbSpeaker.changeAllowedEditProfile(this.moduleId, newValue, (data) => {
            if (data == "success") {
                this.allowedEditProfileVal = newValue;
            } else {
                this.errorSwal.fire();
            }
        });
    }

    filterCustomEditAttendee(itemList: eventCustomField[]): eventCustomField[] {
        let result: eventCustomField[] = [];
        //your filter logic here
        for (let custom of itemList) {
            if (custom.edicao != '3') {
                result.push(custom);
            }
        }

        return result;
    }



    /******************************************************** EXCEL IMPORT **************************************************************/

    /* GET EXCEL AND CONVERT TO JSON DATA */
    sheetValidFormat: boolean = false;
    onFileChange(evt: any) {
        this.preCheckError = false;
        this.sheetValidFormat = false;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
            this.messageErrors = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                let sheetAuxName: string = wb.SheetNames[i];
                let wsAux: XLSX.WorkSheet = wb.Sheets[sheetAuxName]
                let sheetAuxData = <AOA>(XLSX.utils.sheet_to_json(wsAux, { header: 1 }).filter(o => !Object.keys(o).every(k => !o[k])));
                if (sheetAuxName.includes('Principal - ')) {
                    this.sheetValidFormat = true;
                }
                if (sheetAuxName !== 'Principal - ' + this.event.language) {
                    this.preCheckImport(sheetAuxData, sheetAuxName)

                    if (sheetAuxName == 'pt-BR') {
                        this.dataImportPtBR = sheetAuxData;
                    }
                    if (sheetAuxName == 'en-US') {
                        this.dataImportEnUS = sheetAuxData;
                    }
                    if (sheetAuxName == 'es-ES') {
                        this.dataImportEsES = sheetAuxData;
                    }
                    if (sheetAuxName == 'fr-FR') {
                        this.dataImportFrFR = sheetAuxData;
                    }
                    if (sheetAuxName == 'de-DE') {
                        this.dataImportDeDE = sheetAuxData;
                    }
                };
            }
            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }).filter(o => !Object.keys(o).every(k => !o[k])));
        };
        reader.readAsBinaryString(target.files[0]);
    }

    preCheckError: boolean = false;
    preCheckImport(data, tabName) {
        if (data.length > 0) {
            let row = 1;
            for (const element of data) {
                if (this.ignoreRowEmpty(element)) {
                    if (typeof element[this.identifier] !== 'undefined') {
                        // converte ids números para strings
                        if (typeof element[this.identifier] === 'number') {
                            const aux: number = element[this.identifier];
                            element[this.identifier] = aux.toString();
                        }
                    } else {
                        const column = this.checkLetterCols(this.identifier);
                        this.messageErrors.push(tabName + ' -> ' + column + row + ": " + ' ' + this.translateService.instant('global.required_id'));
                        this.preCheckError = true;
                    }
                    row++;
                }
            }
        }
    }

    invalidFormatSheetMsg: string = null;
    checkImport() {
        this.invalidFormatSheetMsg = null;
        this.loaderImport = true;
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.successMsg = false;
        this.newSpeakers = [];
        let validRows: number = 0;
        this.messageErrors = [];

        if (!this.preCheckError) {
            if (this.data !== null) {
                if (this.sheetValidFormat) {
                    let row = 1;
                    for (const element of this.data) {
                        // IGNORE EMPTY ROWS
                        if (this.ignoreRowEmpty(element)) {
                            validRows++;
                            if (row > 1) {
                                const newSpeakers = new Speaker(null, 4, this.eventId);
                                // VALIDATE IDENTIFIERS
                                if (typeof element[this.identifier] !== 'undefined') {
                                    // converte ids números para strings
                                    if (typeof element[this.identifier] === 'number') {
                                        const aux: number = element[this.identifier];
                                        element[this.identifier] = aux.toString();
                                    }
                                    newSpeakers.$identifier = element[this.identifier];
                                    this.checkNumberRowIds(newSpeakers.$identifier, row);
                                } else {
                                    const column = this.checkLetterCols(this.identifier);
                                    this.messageErrors.push('Principal -> ' + column + row + ": " + ' ' + this.translateService.instant('global.required_id'));
                                }

                                if (typeof element[this.email] !== 'undefined' && element[this.email] !== '') {
                                    if (this.regex.validateEmail(element[this.email])) {
                                        newSpeakers.$email = this.regex.removeAccents(element[this.email]);
                                        newSpeakers.$email = newSpeakers.$email.toLowerCase();
                                        this.checkNumberRowEmail(newSpeakers.$email, row);
                                    } else {
                                        const column = this.checkLetterCols(this.email);
                                        this.messageErrors.push('Principal -> ' + column + row + ":" + ' ' + this.translateService.instant('global.invalid_email'));
                                    }
                                }

                                if (typeof element[this.emailRecovery] !== 'undefined' && element[this.emailRecovery] !== '') {
                                    if (this.regex.validateEmail(element[this.emailRecovery])) {
                                        newSpeakers.$emailRecovery = this.regex.removeAccents(element[this.emailRecovery]);
                                        newSpeakers.$emailRecovery = newSpeakers.$emailRecovery.toLowerCase();
                                    } else {
                                        const column = this.checkLetterCols(this.emailRecovery);
                                        this.messageErrors.push('Principal -> ' + column + row + ":" + ' ' + this.translateService.instant('global.invalid_email'));
                                    }
                                }

                                // VALIDATE NAME

                                if (typeof element[this.name] !== 'undefined') {
                                    let name = element[this.name].toString();
                                    name.trim();
                                    newSpeakers.$name = name;
                                    newSpeakers.$queryName = name.toUpperCase();
                                } else {
                                    const column = this.checkLetterCols(this.name);
                                    this.messageErrors.push('Principal -> ' + column + row + ": " + ' ' + this.translateService.instant('global.alert_name'));
                                }

                                let indexPrincipalLanguage = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.data);
                                if (indexPrincipalLanguage > -1) {
                                    // this.checkCustomFieldImport(this.data[0], this.data[indexPrincipalLanguage], this.principalEventLang, this.customField);
                                }

                                let indexTranslatedPtBr = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.dataImportPtBR);
                                if (indexTranslatedPtBr > -1 && this.principalEventLang !== 'PtBR') {
                                    let fieldNamesPtBR = this.dataImportPtBR[0];
                                    newSpeakers.$title['PtBR'] = this.dataImportPtBR[indexTranslatedPtBr][this.translatedTitle];
                                    newSpeakers.$description['PtBR'] = this.dataImportPtBR[indexTranslatedPtBr][this.translatedDescription].replace(/href="/g, 'class="wysiwyg-link" href="');
                                    // this.checkCustomFieldImport(fieldNamesPtBR, this.dataImportPtBR[indexTranslatedPtBr], 'PtBR', this.customFieldTranslated);
                                }

                                let indexTranslatedEnUs = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.dataImportEnUS);
                                if (indexTranslatedEnUs > -1 && this.principalEventLang !== 'EnUS') {
                                    let fieldNamesEnUS = this.dataImportEnUS[0];
                                    newSpeakers.$title['EnUS'] = this.dataImportEnUS[indexTranslatedEnUs][this.translatedTitle];
                                    newSpeakers.$description['EnUS'] = (this.dataImportEnUS[indexTranslatedEnUs][this.translatedDescription]) ? this.dataImportEnUS[indexTranslatedEnUs][this.translatedDescription].replace(/href="/g, 'class="wysiwyg-link" href="') : "";
                                    // this.checkCustomFieldImport(fieldNamesEnUS, this.dataImportEnUS[indexTranslatedEnUs], 'EnUS', this.customFieldTranslated);
                                }

                                let indexTranslatedEsEs = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.dataImportEsES);
                                if (indexTranslatedEsEs > -1 && this.principalEventLang !== 'EsES') {
                                    let fieldNamesEsES = this.dataImportEsES[0];
                                    newSpeakers.$title['EsES'] = this.dataImportEsES[indexTranslatedEsEs][this.translatedTitle];
                                    newSpeakers.$description['EsES'] = this.dataImportEsES[indexTranslatedEsEs][this.translatedDescription].replace(/href="/g, 'class="wysiwyg-link" href="');
                                    // this.checkCustomFieldImport(fieldNamesEsES, this.dataImportEsES[indexTranslatedEsEs], 'EsES', this.customFieldTranslated);
                                }

                                let indexTranslatedFrFr = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.dataImportFrFR);
                                if (indexTranslatedFrFr > -1 && this.principalEventLang !== 'FrFR') {
                                    let fieldNamesFrFR = this.dataImportFrFR[0];
                                    newSpeakers.$title['FrFR'] = this.dataImportFrFR[indexTranslatedFrFr][this.translatedTitle];
                                    newSpeakers.$description['FrFR'] = this.dataImportFrFR[indexTranslatedFrFr][this.translatedDescription].replace(/href="/g, 'class="wysiwyg-link" href="');
                                    // this.checkCustomFieldImport(fieldNamesFrFR, this.dataImportFrFR[indexTranslatedFrFr], 'FrFR', this.customFieldTranslated);
                                }
                                let indexTranslatedDeDe = this.compareIdentifierSpeaker(newSpeakers['identifier'], this.dataImportDeDE);
                                if (indexTranslatedDeDe > -1 && this.principalEventLang !== 'DeDE') {
                                    let fieldNamesDeDE = this.dataImportDeDE[0];
                                    newSpeakers.$title['DeDE'] = this.dataImportDeDE[indexTranslatedDeDe][this.translatedTitle];
                                    newSpeakers.$description['DeDE'] = this.dataImportDeDE[indexTranslatedDeDe][this.translatedDescription].replace(/href="/g, 'class="wysiwyg-link" href="');
                                    // this.checkCustomFieldImport(fieldNamesDeDE, this.dataImportDeDE[indexTranslatedDeDe], 'DeDE', this.customFieldTranslated);
                                }

                                if (typeof element[this.title] !== 'undefined') {
                                    newSpeakers.$title[this.principalEventLang] = element[this.title];
                                }

                                if (typeof element[this.company] !== 'undefined') {
                                    newSpeakers.$company = element[this.company];
                                }

                                if (typeof element[this.description] !== 'undefined') {
                                    newSpeakers.$description[this.principalEventLang] = element[this.description].replace(/href="/g, 'class="wysiwyg-link" href="');
                                }

                                if (typeof element[this.picture] !== 'undefined') {
                                    newSpeakers.$photoUrl = element[this.picture];
                                }

                                if (typeof element[this.website] !== 'undefined') {
                                    newSpeakers.$website = element[this.website];
                                }

                                if (typeof element[this.facebook] !== 'undefined') {
                                    newSpeakers.$facebook = element[this.facebook];
                                }

                                if (typeof element[this.twitter] !== 'undefined') {
                                    newSpeakers.$twitter = element[this.twitter];
                                }

                                if (typeof element[this.linkedin] !== 'undefined') {
                                    newSpeakers.$linkedin = element[this.linkedin];
                                }

                                if (typeof element[this.instagram] !== 'undefined') {
                                    newSpeakers.$instagram = element[this.instagram];
                                }

                                if (typeof element[this.groups] !== 'undefined') {
                                    newSpeakers.$groups = element[this.groups];
                                }

                                if (typeof element[this.documents] !== 'undefined') {
                                    newSpeakers.$documents = element[this.documents];
                                }

                                if (typeof element[this.attendees] !== 'undefined') {
                                    newSpeakers.$attendees = element[this.attendees];
                                }

                                newSpeakers.$language = this.event['language'];
                                newSpeakers.$customField = [];
                                newSpeakers.$customField = this.allCustoms;
                                this.newSpeakers.push(newSpeakers);
                                this.allCustoms = [];

                                if (this.messageErrors.length > 0) {
                                    this.loaderImport = false;
                                    this.theError = true;
                                    this.theErrorApi = false;
                                    this.successMsg = false;
                                    this.theErrorApiDb = false;
                                    this.sheetValidFormat = false;
                                }
                                this.clearInputFile(); // limpa o input do arquivo.
                            }
                        }
                        row++;
                    }

                    if (this.newSpeakers.length == validRows - 1 && this.theError == false) {
                        this.importSpeakers();
                    }
                } else {
                    this.loaderImport = false;
                    this.theError = false;
                    this.theErrorApi = false;
                    this.successMsg = false;
                    this.theErrorApiDb = false;
                    this.invalidFormatSheetMsg = this.translateService.instant('global.invalid_spreadsheet');
                }
            } else {
                this.loaderImport = false;
                this.theError = true;
                this.messageErrors.push(this.translateService.instant('global.no_file_selected'));
            }
        } else {
            this.loaderImport = false;
            this.theError = true;
            this.theErrorApi = false;
            this.successMsg = false;
            this.theErrorApiDb = false;
        }


    }

    allCustoms: Array<any> = [];
    // checkCustomFieldImport(fieldNames, element, language, customPosition) {
    //     if (this.allCustoms.length == 0) {
    //         let indexCustomAnswer = 0;
    //         for (let i = customPosition; i < fieldNames.length; i++) {
    //             let index = this.verifyCustomField(this.listCustomField, fieldNames[i], language);
    //             let custom;

    //             if (index !== -1) {
    //                 custom = this.instantiateCustomField(this.listCustomField[index].uid, this.listCustomField[index]);
    //                 if (typeof element[customPosition + indexCustomAnswer] !== 'undefined') {
    //                     let answer = element[customPosition + indexCustomAnswer];
    //                     if (custom.type == 'select') {
    //                         custom.value = answer;
    //                     } else {
    //                         custom.textValue[language] = answer;
    //                     }
    //                 } else {
    //                     custom.value = null;
    //                     custom.textValue[language] = '';
    //                 }
    //             } else {
    //                 custom = null;
    //                 let msgCustomError = this.translateService.instant('global.customfield_name_error_import') + fieldNames[i];
    //                 let indexError = this.verifyCustomFieldErrorExist(msgCustomError);
    //                 if (indexError == -1) {
    //                     this.messageErrors.push(msgCustomError);
    //                 }
    //             }

    //             if (custom !== null) this.allCustoms.push(custom);
    //             indexCustomAnswer++;
    //         }

    //         // add others custom fields case in the sheet do not have all
    //         for (let customField of this.listCustomField) {
    //             let index = this.verifyCustomFieldIndex(this.allCustoms, customField.uid);
    //             if (index == -1) {
    //                 customField.value = null;
    //                 customField.textValue[language] = '';
    //                 this.allCustoms.push(this.instantiateCustomField(customField.uid, customField));
    //             }
    //         }
    //     } else {
    //         let indexCustomAnswer = 0;
    //         for (let i = customPosition; i < fieldNames.length; i++) {
    //             let index = this.verifyCustomField(this.allCustoms, fieldNames[i], language);
    //             let custom;

    //             if (index !== -1) {
    //                 custom = this.instantiateCustomField(this.allCustoms[index].uid, this.allCustoms[index]);
    //                 if (typeof element[customPosition + indexCustomAnswer] !== 'undefined') {
    //                     let answer = element[customPosition + indexCustomAnswer];
    //                     if (custom.type == 'select') {
    //                         custom.value = answer;
    //                     } else {
    //                         custom.textValue[language] = answer;
    //                     }
    //                 } else {
    //                     custom.value = null;
    //                     custom.textValue[language] = '';
    //                 }
    //             } else {
    //                 custom = null;
    //                 let msgCustomError = this.translateService.instant('global.customfield_name_error_import') + fieldNames[i];
    //                 let indexError = this.verifyCustomFieldErrorExist(msgCustomError);
    //                 if (indexError == -1) {
    //                     this.messageErrors.push(msgCustomError);
    //                 }
    //             }

    //             if (custom !== null) {
    //                 this.allCustoms[index]['textValue'][language] = custom.textValue[language];
    //                 this.allCustoms[index]['value'] = custom.value;
    //             }
    //             indexCustomAnswer++;
    //         }

    //         // add others custom fields case in the sheet do not have all
    //         for (let customField of this.allCustoms) {
    //             let index = this.verifyCustomFieldIndex(this.allCustoms, customField.uid);
    //             if (index == -1) {
    //                 customField.value = null;
    //                 customField.textValue[language] = '';
    //                 this.allCustoms.push(this.instantiateCustomField(customField.uid, customField));
    //             }
    //         }
    //     }
    //     return this.allCustoms;
    // }

    importSpeakers() {
        // call API to import speakers 
        this.dbSpeaker.importSpeakers(this.eventId, this.moduleId, this.newSpeakers, this.moduleGroupId, this.principalEventLang, (status) => {
            if (status['message'] == 'success') { // import success
                if (status['result']['emailDouble'].length == 0 && status['result']['idDouble']?.length == 0) {
                    this.theError = false;
                    this.theErrorApi = false;
                    this.theErrorApiDb = false;
                    this.loaderImport = false;
                    this.successMsg = true;
                    this.messageErrors = [];
                    this.speakerErrorsApi = [];
                    this.loadSpeakers();
                    if (status['result']['success'] !== undefined) { this.totalSpeakerCreated = status['result']['success']?.length; }
                    if (status['result']['updateds'] !== undefined) { this.totalSpeakerUpdated = status['result']['updateds']?.length; }
                    if (status['result']['fail']?.length >= 1) {
                        this.speakerErrorsApi = status['result']['fail'];
                        this.totalSpeakerFail = status['result']['fail']?.length
                        this.theErrorApi = true;
                    }
                    if (status['result']['failGroups']?.length >= 1) {
                        this.invalidGroups = status['result']['failGroups'];
                        this.theErrorGroup = true;
                    }
                    if (status['result']['failAttendees']?.length >= 1) {
                        this.invalidAttendees = status['result']['failAttendees'];
                        this.theErrorAttendees = true;
                    }
                    if (status['result']['failDocuments']?.length >= 1) {
                        this.invalidDocuments = status['result']['failDocuments'];
                        this.theErrorDocuments = true;
                    }
                    if (status['result']['emailInAttendees']?.length >= 1) {
                        for (let speaker of status['result']['emailInAttendees']) {
                            this.speakerErrorsApi.push(speaker);
                            this.messageErrors.push(speaker['email'] + ' - ' + this.translateService.instant('global.import_speaker_error_email_attendee'));
                        }
                        this.theError = true;
                    }

                    if (status['result']['failCustomField'].length >= 1) {
                        this.invalidCustomFields = status['result']['failCustomField'];
                        this.theErrorCustom = true;
                    }
                } else if (status['result']['emailDouble'].length > 0 && status['result']['idDouble']?.length == 0) {
                    // speakers with double e-mail in event
                    this.theError = false;
                    this.theErrorApiDb = false;
                    this.loaderImport = false;
                    this.successMsg = false;
                    this.theErrorApi = true;
                    this.speakerErrorsApi = status['result']['emailDouble'];
                    this.messageErrors = [];
                    for (let speaker of status['result']['emailDouble']) {
                        this.speakerErrorsApi.push(speaker);

                        if (speaker.type <= 3) {
                            this.messageErrors.push(speaker['email'] + ' - ' + this.translateService.instant('global.email_invalid_speaker'));
                        } else {
                            this.messageErrors.push(speaker['email'] + ' - ' + this.translateService.instant('global.email_double'));
                        }
                    }
                    for (let speakerFail of status['result']['fail']) {
                        this.speakerErrorsApi.push(speakerFail);
                        this.messageErrors.push(speakerFail['email'] + ' - ' + this.translateService.instant('comp.speakers.speaker_failed_msg'));
                    }
                    this.loadSpeakers();
                } else if (status['result']['idDouble'].length > 0 && status['result']['emailDouble']?.length == 0) {
                    // speakers with double identifier in event
                    this.theError = false;
                    this.theErrorApiDb = false;
                    this.loaderImport = false;
                    this.successMsg = false;
                    this.theErrorApi = true;
                    this.messageErrors = [];
                    for (let speaker of status['result']['idDouble']) {
                        this.speakerErrorsApi.push(speaker);
                        this.messageErrors.push(speaker['identifier'] + ' - ' + this.translateService.instant('global.identifier_double'));
                    }
                    for (let speakerFail of status['result']['fail']) {
                        this.speakerErrorsApi.push(speakerFail);
                        this.messageErrors.push(speakerFail['email'] + ' - ' + this.translateService.instant('comp.speakers.speaker_failed_msg'));
                    }
                    this.loadSpeakers();
                } else if (status['result']['emailDouble'].length == 0 && status['result']['idDouble'].length == 0 && status['result']['success'].length == 0) {
                    // the API did not respond correctly so did not import participants
                    this.theError = false;
                    this.successMsg = false;
                    this.loaderImport = false;
                    this.theErrorApi = false;
                    this.theErrorApiDb = true;
                    this.messageErrors = [];
                    this.loadSpeakers();
                }
            }
        });
    }

    compareIdentifierSpeaker(identifierSpeaker, translatedSpeakers) {
        let aux = this.translatedIdentifier;
        if (translatedSpeakers !== null && translatedSpeakers !== undefined) {
            return translatedSpeakers.map(function (e) { return e[aux] }).indexOf(identifierSpeaker);
        } else {
            return -1;
        }
    }

    verifyCustomFieldIndex(arrayCustom, customId) {
        if (arrayCustom !== null) {
            return arrayCustom.map(function (e) { return e.uid; }).indexOf(customId)
        } else {
            return 1;
        }
    }

    verifyCustomField(arrayCustom, nameCustom, language) {
        return arrayCustom.map((e) => { return e.name[language]; }).indexOf(nameCustom);
    }

    verifyCustomFieldErrorExist(newError) {
        return this.messageErrors.map(function (e) { return e; }).indexOf(newError);
    }

    /** IGNORE EMPTY ROWS */
    ignoreRowEmpty(row: any) {
        if (row.length < 1 || this.checkRowEmpty(row)) {
            return false;
        }
        return true;
    }

    /**VERIFY IF ROW IS EMPTY */
    checkRowEmpty(row: any) {
        for (const cel of row) {
            if (typeof cel !== 'undefined' && cel.length > 0) {
                return false;
            }
        }
        return true;
    }

    /* CHECK IF HAVE DOUBLE IDENTIFIERS */
    checkNumberRowIds(identifier: string, rowId: number) {
        let cont = 2;
        for (const speaker of this.newSpeakers) {
            if (speaker.$identifier === identifier) {
                const column = this.checkLetterCols(this.identifier);
                this.messageErrors.push(this.translateService.instant('global.equal_id') + ' ' + column + cont + ' ' + this.translateService.instant('global.and') + ' ' + column + rowId);
            }
            cont++;
        }
    }

    /* RETURN EXCEL COLUMN LETTER OF PARAMETER */
    checkLetterCols(column: number) {
        let letter = "";
        switch (column) {
            case this.identifier: {
                letter = "A";
                break;
            }
            case this.name: {
                letter = "B";
                break;
            }
            case this.email: {
                letter = "C";
                break;
            }

            case this.emailRecovery: {
                letter = "D";
                break;
            }

            case this.groups: {
                letter = "M";
                break;
            }

            case this.documents: {
                letter = 'N';
                break;
            }
        }
        return letter;
    }

    //VERIFICA SE EXISTE REPETIÇÃO DE E-MAILS NO EXCEL*/
    checkNumberRowEmail(email: string, rowId: number) {
        let cont = 2;
        for (const speaker of this.newSpeakers) {
            if (speaker.$email === email) {
                const column = this.checkLetterCols(this.email);
                this.messageErrors.push(this.translateService.instant('global.equal_email') + ' ' + column + cont + ' ' + this.translateService.instant('global.and') + ' ' + column + rowId);
            }
            cont++;
        }
    }

    // CLEAR INPUT AFTER IMPORT DATA
    clearInputFile() {
        this.inputFile.nativeElement.value = '';
        this.nameFile = null;
    }

    clearMessages() {
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.theErrorGroup = false;
        this.theErrorAttendees = false;
        this.theErrorDocuments = false;
        this.messageErrors = [];
        this.successMsg = false;
        this.speakerErrorsApi = [];
        this.sheetValidFormat = false;
        this.invalidFormatSheetMsg = null;
    }

    downloadSpeakersWithErrors() {
        this.someSpeakersExcelDownload;
    }

    // EXPORTA O EXCEL COM OS SPEAKERS
    async exportAllSpeakers() {
        this.dataExportSpeakers = [];
        this.dataExportSpeakers_DeDE = [];
        this.dataExportSpeakers_EnUS = [];
        this.dataExportSpeakers_EsES = [];
        this.dataExportSpeakers_FrFR = [];
        this.dataExportSpeakers_PtBR = [];

        this.dataExportSpeakers = [[
            'Identifier*',
            'Name*',
            'E-mail',
            'Recovery e-mail',
            'Title',
            'Company',
            'Description',
            'Photo (URL)',
            'Site',
            'Facebook',
            'Twitter',
            'LinkedIn',
            'Instagram',
            'Groups',
            'Documents',
            (this.attendeesField) ? this.attendeesField : 'Attendees'
        ]];

        this.dataExportSpeakers_DeDE = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EnUS = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EsES = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_FrFR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_PtBR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];

        // ADD NAME OF CUSTOM FIELD AT TOP
        for (const custom of this.listCustomField) {
            this.dataExportSpeakers[0].push(custom['name'][this.principalEventLang]);
        }

        // ET DATA OF ALL SPEAKERS
        await this.dbSpeaker.exportSpeakers(this.moduleId, this.typeOrder, async (speakersArray) => {
            if (speakersArray['message'] == 'success' && speakersArray['result'].length > 0) {
                let cont = 0;
                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                for (let i = 0; i < speakersArray['result'].length; i++) {
                    let speaker = speakersArray['result'][i];
                    let row: any;

                    row = await this.preparesSpeakersExport(speaker);
                    await this.dataExportSpeakers.push(row);

                    let row_PtBR: any;
                    let row_EnUS: any;
                    let row_EsES: any;
                    let row_FrFR: any;
                    let row_DeDE: any;

                    if (this.languages.PtBR) {
                        if (i == 0) {
                            let customFieldArray = this.getCustomFieldNameSheetTitle(speakersArray['result'], 'PtBR');
                            for (let custom of customFieldArray) {
                                this.dataExportSpeakers_PtBR[0].push(custom);
                            }
                        }
                        row_PtBR = await this.prepareSpeakersTraslatedExport(speaker, 'PtBR');
                        await this.dataExportSpeakers_PtBR.push(row_PtBR);
                    }

                    if (this.languages.EnUS) {
                        if (i == 0) {
                            let customFieldArray = this.getCustomFieldNameSheetTitle(speakersArray['result'], 'EnUS');
                            for (let custom of customFieldArray) {
                                this.dataExportSpeakers_EnUS[0].push(custom);
                            }
                        }
                        row_EnUS = await this.prepareSpeakersTraslatedExport(speaker, 'EnUS');
                        await this.dataExportSpeakers_EnUS.push(row_EnUS);
                    }

                    if (this.languages.EsES) {
                        if (i == 0) {
                            let customFieldArray = this.getCustomFieldNameSheetTitle(speakersArray['result'], 'EsES');
                            for (let custom of customFieldArray) {
                                this.dataExportSpeakers_EsES[0].push(custom);
                            }
                        }
                        row_EsES = await this.prepareSpeakersTraslatedExport(speaker, 'EsES');
                        await this.dataExportSpeakers_EsES.push(row_EsES);
                    }

                    if (this.languages.FrFR) {
                        if (i == 0) {
                            let customFieldArray = this.getCustomFieldNameSheetTitle(speakersArray['result'], 'FrFR');
                            for (let custom of customFieldArray) {
                                this.dataExportSpeakers_FrFR[0].push(custom);
                            }
                        }
                        row_FrFR = await this.prepareSpeakersTraslatedExport(speaker, 'FrFR');
                        await this.dataExportSpeakers_FrFR.push(row_FrFR);
                    }

                    if (this.languages.DeDE) {
                        if (i == 0) {
                            let customFieldArray = this.getCustomFieldNameSheetTitle(speakersArray['result'], 'DeDE');
                            for (let custom of customFieldArray) {
                                this.dataExportSpeakers_DeDE[0].push(custom);
                            }
                        }
                        row_DeDE = await this.prepareSpeakersTraslatedExport(speaker, 'DeDE');
                        await this.dataExportSpeakers_DeDE.push(row_DeDE);
                    }

                    if (cont == speakersArray['result'].length - 1) {
                        const wscols: XLSX.ColInfo[] = this.returnColmns();

                        /* At 96 PPI, 1 pt = 1 px */
                        const wsrows: XLSX.RowInfo[] = [
                            { hpx: 30 }, // "pixels"
                        ];

                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'Principal - ' + this.event.language);

                        if (this.languages.PtBR && this.principalEventLang !== 'PtBR') {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_PtBR);
                            /* TEST: column props */
                            ws['!cols'] = wscols;
                            /* TEST: row props */
                            ws['!rows'] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, 'pt-BR');
                        }

                        if (this.languages.EnUS && this.principalEventLang !== 'EnUS') {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EnUS);
                            /* TEST: column props */
                            ws['!cols'] = wscols;
                            /* TEST: row props */
                            ws['!rows'] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, 'en-US');
                        }

                        if (this.languages.EsES && this.principalEventLang !== 'EsES') {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EsES);
                            /* TEST: column props */
                            ws['!cols'] = wscols;
                            /* TEST: row props */
                            ws['!rows'] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, 'es-ES');
                        }

                        if (this.languages.FrFR && this.principalEventLang !== 'FrFR') {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_FrFR);
                            /* TEST: column props */
                            ws['!cols'] = wscols;
                            /* TEST: row props */
                            ws['!rows'] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, 'fr-FR');
                        }

                        if (this.languages.DeDE && this.principalEventLang !== 'DeDE') {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_DeDE);
                            /* TEST: column props */
                            ws['!cols'] = wscols;
                            /* TEST: row props */
                            ws['!rows'] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, 'de-DE');
                        }

                        /* save to file */
                        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                        saveAs(new Blob([this.s2ab(wbout)]), 'speakers.xlsx');
                        this.data = null;
                        $('#exportLoading').modal('toggle')
                    }
                    cont++;
                }

            } else {
                let cont = 0;
                for (let custom of this.listCustomField) {
                    this.dataExportSpeakers[0].push(custom['name'][this.principalEventLang]);
                    this.dataExportSpeakers_PtBR[0].push(custom['name']['PtBR']);
                    this.dataExportSpeakers_EnUS[0].push(custom['name']['EnUS']);
                    this.dataExportSpeakers_EsES[0].push(custom['name']['EsES']);
                    this.dataExportSpeakers_FrFR[0].push(custom['name']['FrFR']);
                    this.dataExportSpeakers_DeDE[0].push(custom['name']['DeDE']);
                    cont++;
                }

                if (cont == this.listCustomField.length) {
                    /* generate workbook and add the worksheet */
                    const wb: XLSX.WorkBook = XLSX.utils.book_new();
                    const wscols: XLSX.ColInfo[] = this.returnColmns();

                    /* At 96 PPI, 1 pt = 1 px */
                    const wsrows: XLSX.RowInfo[] = [
                        { hpx: 30 }, // "pixels"
                    ];

                    // PRINCIPAL DATA SHEET

                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'Principal - ' + this.event.language);

                    if (this.languages.PtBR && this.principalEventLang !== 'PtBR') {
                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_PtBR);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'pt-BR');
                    }

                    if (this.languages.EnUS && this.principalEventLang !== 'EnUS') {
                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EnUS);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'en-US');
                    }

                    if (this.languages.EsES && this.principalEventLang !== 'EsES') {
                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EsES);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'es-ES');
                    }

                    if (this.languages.FrFR && this.principalEventLang !== 'FrFR') {
                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_FrFR);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'fr-FR');
                    }

                    if (this.languages.DeDE && this.principalEventLang !== 'DeDE') {
                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_DeDE);
                        /* TEST: column props */
                        ws['!cols'] = wscols;
                        /* TEST: row props */
                        ws['!rows'] = wsrows;
                        XLSX.utils.book_append_sheet(wb, ws, 'de-DE');
                    }
                    /* save to file */
                    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                    saveAs(new Blob([this.s2ab(wbout)]), 'speakers.xlsx');
                    this.data = null;
                    $('#exportLoading').modal('toggle')
                }
            }
        })

    }

    getCustomFieldNameSheetTitle(speakersArray, language) {
        let customArray = [];

        for (let custom of speakersArray[0]['customField']) {
            customArray.push(custom['name'][language]);
        }

        if (customArray.length == speakersArray[0]['customField'].length) {
            return customArray;
        }
    }

    returnColmns() {
        let array = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            // { hidden: false } // hide column
        ];
        return array;
    }

    // PREPARA UM REGISTRO DE DADOS SECUNDÁRIOS TRADUZIDOS PARA EXPORTAÇÃO
    prepareSpeakersTraslatedExport(speaker, lang) {
        const row = [];
        if (speaker) {
            row.push(speaker.identifier);
            row.push(speaker.title[lang]);
            if (speaker.description[lang] !== null && speaker.description[lang] !== undefined) {
                row.push(speaker.description[lang]);
            } else {
                row.push('');
            }
            for (const custom of speaker.customField) {
                if (custom.type == 'text') {
                    row.push(custom.textValue[lang]);
                } else {
                    row.push(custom.value[this.userLanguageFormat]);
                }
            }
        }
        return row;
    }
    // PREPARA UM REGISTRO DE SPEAKER PARA EXPORTAÇÃO
    preparesSpeakersExport(speaker) {
        const row = [];

        row.push(speaker.identifier);
        row.push(speaker.name);
        row.push(speaker.email);
        row.push(speaker.emailRecovery);
        row.push(speaker.title[this.principalEventLang])
        row.push(speaker.company);
        if (speaker.description[this.principalEventLang] !== null && speaker.description[this.principalEventLang] !== undefined && speaker.description[this.principalEventLang] !== '') {
            row.push(speaker.description[this.principalEventLang]);
        } else {
            row.push('');
        }
        row.push(speaker.photoUrl);
        row.push(speaker.website);
        row.push(speaker.facebook);
        row.push(speaker.twitter);
        row.push(speaker.linkedin);
        row.push(speaker.instagram);
        row.push(this.preparedGroupExport(speaker.groups));
        row.push(this.preparedDocumentExport(speaker.documents));
        row.push((speaker.attendees) ? speaker.attendees : "");
        for (const custom of speaker.customField) {
            if (custom.type == 'text') {
                row.push(custom.textValue[this.principalEventLang]);
            } else {
                row.push(custom.value[this.principalEventLang]);
            }
        }
        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }


    preparedGroupExport(list: any) {
        let myDatas = "";
        let i = 1;
        if (list !== null && list !== undefined) {
            let array = Object.values(list)
            for (const data of array) {
                if (i < array.length) {
                    myDatas += data['identifier'] + ";";
                } else {
                    myDatas += data['identifier'];
                }
                i++;
            }

            if (list.length <= 0) {
                myDatas = null;
            }
        }
        return myDatas;
    }

    preparedDocumentExport(list: any) {
        let myDatas = "";
        let i = 1;
        if (list !== null && list !== undefined) {
            let array = Object.values(list)
            for (const data of array) {
                if (i < array.length) {
                    myDatas += data['identifier'] + ";";
                } else {
                    myDatas += data['identifier'];
                }
                i++;
            }

            if (list.length <= 0) {
                myDatas = null;
            }
        }
        return myDatas;
    }

    async downloadSpeakersImportError() {
        this.dataExportSpeakers = [];
        this.dataExportSpeakers_DeDE = [];
        this.dataExportSpeakers_EnUS = [];
        this.dataExportSpeakers_EsES = [];
        this.dataExportSpeakers_FrFR = [];
        this.dataExportSpeakers_PtBR = [];

        this.dataExportSpeakers = [[
            'Identifier*',
            'Name*',
            'E-mail',
            'Recovery e-mail',
            'Title',
            'Company',
            'Description',
            'Photo (URL)',
            'Site',
            'Facebook',
            'Twitter',
            'LinkedIn',
            'Instagram',
            'Groups',
            'Documents'
        ]];

        this.dataExportSpeakers_DeDE = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EnUS = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EsES = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_FrFR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_PtBR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];

        // PEGA OS DADOS DE CADA PARTICIPANTE
        let cont = 0;
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        for (let i = 0; i < this.speakerErrorsApi.length; i++) {
            let speaker = this.speakerErrorsApi[i];
            let row: any;
            row = await this.preparesSpeakersExport(speaker);
            await this.dataExportSpeakers.push(row);
            let row_PtBR: any;
            let row_EnUS: any;
            let row_EsES: any;
            let row_FrFR: any;
            let row_DeDE: any;

            if (this.languages.PtBR) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(this.speakerErrorsApi, 'PtBR');
                    for (let custom of customFieldArray) {
                        this.dataExportSpeakers_PtBR[0].push(custom);
                    }
                }
                row_PtBR = await this.prepareSpeakersTraslatedExport(speaker, 'PtBR');
                await this.dataExportSpeakers_PtBR.push(row_PtBR);
            }

            if (this.languages.EnUS) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(this.speakerErrorsApi, 'EnUS');
                    for (let custom of customFieldArray) {
                        this.dataExportSpeakers_EnUS[0].push(custom);
                    }
                }
                row_EnUS = await this.prepareSpeakersTraslatedExport(speaker, 'EnUS');
                await this.dataExportSpeakers_EnUS.push(row_EnUS);
            }

            if (this.languages.EsES) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(this.speakerErrorsApi, 'EsES');
                    for (let custom of customFieldArray) {
                        this.dataExportSpeakers_EsES[0].push(custom);
                    }
                }
                row_EsES = await this.prepareSpeakersTraslatedExport(speaker, 'EsES');
                await this.dataExportSpeakers_EsES.push(row_EsES);
            }

            if (this.languages.FrFR) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(this.speakerErrorsApi, 'FrFR');
                    for (let custom of customFieldArray) {
                        this.dataExportSpeakers_FrFR[0].push(custom);
                    }
                }
                row_FrFR = await this.prepareSpeakersTraslatedExport(speaker, 'FrFR');
                await this.dataExportSpeakers_FrFR.push(row_FrFR);
            }

            if (this.languages.DeDE) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(this.speakerErrorsApi, 'DeDE');
                    for (let custom of customFieldArray) {
                        this.dataExportSpeakers_DeDE[0].push(custom);
                    }
                }
                row_DeDE = await this.prepareSpeakersTraslatedExport(speaker, 'DeDE');
                await this.dataExportSpeakers_DeDE.push(row_DeDE);
            }
            if (cont == this.speakerErrorsApi.length - 1) {
                const wscols: XLSX.ColInfo[] = this.returnColmns();

                /* At 96 PPI, 1 pt = 1 px */
                const wsrows: XLSX.RowInfo[] = [
                    { hpx: 30 }, // "pixels"
                ];

                /* generate worksheet */
                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers);
                /* TEST: column props */
                ws['!cols'] = wscols;
                /* TEST: row props */
                ws['!rows'] = wsrows;
                XLSX.utils.book_append_sheet(wb, ws, 'Principal - ' + this.event.language);

                // ADDITIONAL DATA TRANSLATED
                if (this.languages.PtBR && this.principalEventLang !== 'PtBR') {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_PtBR);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'pt-BR');
                }

                if (this.languages.EnUS && this.principalEventLang !== 'EnUS') {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EnUS);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'en-US');
                }

                if (this.languages.EsES && this.principalEventLang !== 'EsES') {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EsES);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'es-ES');
                }

                if (this.languages.FrFR && this.principalEventLang !== 'FrFR') {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_FrFR);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'fr-FR');
                }

                if (this.languages.DeDE && this.principalEventLang !== 'DeDE') {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_DeDE);
                    /* TEST: column props */
                    ws['!cols'] = wscols;
                    /* TEST: row props */
                    ws['!rows'] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, 'de-DE');
                }

                /* save to file */
                const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                saveAs(new Blob([this.s2ab(wbout)]), 'import_error.xlsx');
                this.data = null;
            }
            cont++;
        }

    }

    exportTemplate() {
        this.dataExportSpeakers = [];
        this.dataExportSpeakers_DeDE = [];
        this.dataExportSpeakers_EnUS = [];
        this.dataExportSpeakers_EsES = [];
        this.dataExportSpeakers_FrFR = [];
        this.dataExportSpeakers_PtBR = [];

        this.dataExportSpeakers = [[
            'Identifier*',
            'Name*',
            'E-mail',
            'Recovery e-mail',
            'Title',
            'Company',
            'Description',
            'Photo (URL)',
            'Site',
            'Facebook',
            'Twitter',
            'LinkedIn',
            'Instagram',
            'Groups',
            'Documents'
        ]];

        this.dataExportSpeakers_DeDE = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EnUS = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_EsES = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_FrFR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];
        this.dataExportSpeakers_PtBR = [[
            'Identifier*',
            'Title',
            'Description'
        ]];

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        const wscols: XLSX.ColInfo[] = this.returnColmns();

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 30 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers);
        /* TEST: column props */
        ws['!cols'] = wscols;
        /* TEST: row props */
        ws['!rows'] = wsrows;
        XLSX.utils.book_append_sheet(wb, ws, 'Principal - ' + this.event.language);

        for (let custom of this.listCustomField) {
            this.dataExportSpeakers_PtBR[0].push(custom.name.PtBR);
            this.dataExportSpeakers_EnUS[0].push(custom.name.EnUS);
            this.dataExportSpeakers_EsES[0].push(custom.name.EsES);
            this.dataExportSpeakers_FrFR[0].push(custom.name.FrFR);
            this.dataExportSpeakers_DeDE[0].push(custom.name.DeDE);
        }
        // ADDITIONAL DATA TRANSLATED
        if (this.languages.PtBR && this.principalEventLang !== 'PtBR') {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_PtBR);
            /* TEST: column props */
            ws['!cols'] = wscols;
            /* TEST: row props */
            ws['!rows'] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, 'pt-BR');
        }

        if (this.languages.EnUS && this.principalEventLang !== 'EnUS') {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EnUS);
            /* TEST: column props */
            ws['!cols'] = wscols;
            /* TEST: row props */
            ws['!rows'] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, 'en-US');
        }

        if (this.languages.EsES && this.principalEventLang !== 'EsES') {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_EsES);
            /* TEST: column props */
            ws['!cols'] = wscols;
            /* TEST: row props */
            ws['!rows'] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, 'es-ES');
        }

        if (this.languages.FrFR && this.principalEventLang !== 'FrFR') {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_FrFR);
            /* TEST: column props */
            ws['!cols'] = wscols;
            /* TEST: row props */
            ws['!rows'] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, 'fr-FR');
        }

        if (this.languages.DeDE && this.principalEventLang !== 'DeDE') {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportSpeakers_DeDE);
            /* TEST: column props */
            ws['!cols'] = wscols;
            /* TEST: row props */
            ws['!rows'] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, 'de-DE');
        }
        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), 'template_speakers.xlsx');
        this.data = null;
    }

    instantiateCustomField(uid: string, data: any) {
        const custom = new eventCustomField(data.name, data.type, data.exibicao, data.edicao);
        if (typeof uid !== 'undefined') {
            custom.uid = uid;
        }
        return custom;

    }

    editProfile() { }
    requiredEditProfile($ev) {
        this.dbEvent.changeRequiredProfileEdit(this.eventId, $ev);
    }
    changeFirstAccessLanguage() {
        this.dbSpeaker.takeChangesInModule(this.moduleId, this.eventId, { firstAccessCodeLanguage: this.first_access_language });
    }

    userResetReference: Speaker = null;
    resetUserAccountProblem() {
        $("#exportLoading").modal('show');
        this.dbSpeaker.resetAccount(this.userResetReference)
            .then((response) => {
                if (response == 0) {
                    this.userResetReference = null;
                    setTimeout(() => {
                        $("#exportLoading").modal('toggle');
                        this.successSwal.fire();
                    }, 200);
                } else if (response == 1) {
                    this.userResetReference = null;
                    this.notReconfiguredSwal.fire();
                    setTimeout(() => {
                        $("#exportLoading").modal('toggle');
                    }, 150);
                }
            })
            .catch((error) => {
                this.userResetReference = null;
                this.errorSwal.fire();
                setTimeout(() => {
                    $("#exportLoading").modal('toggle');
                }, 150);
            });
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    /**
     * Allow or block filter on list speaker
     * @param $ev 
     */
    allowFilterChanged($ev, type: string) {
        let value = null;
        if(type === 'company'){
            value = this.allowFilterCompany = $ev;
            this.disableFilterComVisibility = true;
        } else if(type === 'post') {
            value = this.allowFilterPost =  $ev;
            this.disableFilterPostVisibility = true;
        }
        this.dbSpeaker.editFilterVisibility(this.moduleId, value,type, (result) => {
            if(!result.value) {
                this.allowFilterCompany = (result.type === 'company') && !this.allowFilterCompany
                this.allowFilterPost = (result.type === 'post') && !this.allowFilterPost
            } 
            this.disableFilterComVisibility = false;
            this.disableFilterPostVisibility = false;
        });
    }
    propertyChanged($ev, property: string) {
        let value = null;
        if(property === 'showLetters'){
            value = this.showLetters = $ev;
            this.disableLettreVisibility = true;
        } else if(property === 'emailVisibilty') {
            value = this.emailVisibilty = $ev
        }

        this.dbSpeaker.editPropVisibility(this.moduleId, property, value, (result) => {
            if(!result && property == 'showLetters') {
                this.showLetters = !this.showLetters
            } else if (!result && property == 'emailVisibilty'){
                this.emailVisibilty = !this.emailVisibilty
            }
            this.disableLettreVisibility = false;
        });
    }

    setTexts(type: string){
        if(type === 'company'){
            this.companyFilterText = this.companyFilterText.trim()
            this.disabledCompanyFilterText = true
            this.dbSpeaker.editPropVisibility(
                this.moduleId,
                'companyFilterText',
                this.companyFilterText,
                (result) => {
                    this.disabledCompanyFilterText = false
                })
        } else if(type === 'post'){
            this.postFilterText = this.postFilterText.trim()
            this.disabledPostFilterText = true
            this.dbSpeaker.editPropVisibility(
                this.moduleId,
                'postFilterText',
                this.postFilterText,
                (result) => {
                    this.disabledPostFilterText = false
                })
        }
    }
}
