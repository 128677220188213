export class Location {
    public uid: string;
    public identifier: string;
    public name: string;
    public image: string;
    public description: string;
    public moduleId: string;
    public eventId: string;
    public createdAt: Number;
    public order: number;
    public timezone: string;


    constructor() {
        this.uid = "";
        this.identifier = null;
        this.name = "";
        this.image = "";
        this.description = "";
        this.moduleId = "";
        this.eventId = "";
        this.order = 0;
        this.timezone = "";
    }
}