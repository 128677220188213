import { Component, OnInit, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Survey } from '../../../../../../models/survey';
import { Question } from '../../../../../../models/question';
import { Answer } from '../../../../../../models/answer';
import { Group } from '../../../../../../models/group';
import { Session } from '../../../../../../models/session';
import { Track } from '../../../../../../models/track';
import { TypeSurvey } from '../../../../../../models/type-survey';
import { DbSurveyProvider } from '../../../../../../providers/database/db-survey';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { GlobalService } from 'src/app/providers/global/global.service';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { NameModule } from 'src/app/models/name-module';
import { Languages } from 'src/app/models/languages';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';

declare let $: any;

@NgModule({
    declarations: [
        GetNameSession
    ],
})


@Component({
    selector: 'app-create-survey',
    templateUrl: './create-survey.component.html',
    styleUrls: ['./create-survey.component.scss']
})

export class CreateSurveyComponent implements OnInit {
    // get the language of the user.
    public userLanguage: string
    // get the language of the event.
    public eventLanguage: string

    languages: Languages = null //event languages
    activeLanguage: string = null;

    term;
    surveyModuleId: string = null;
    moduleId: string = null
    eventId: string = null;
    eventTimezone: string = null;

    // public maxResponses = false;
    // QuestionMaxResponses = false;
    hideAddQuestion = true;

    public surveyName: NameModule = new NameModule('', '', '', '', '');
    public changeAnswers: boolean = false;
    public viewAnswered: boolean = false;
    public listQuestions: Array<Question> = [];
    public listAnswers: Array<Answer> = [new Answer()];
    public questionMainTitle: NameModule = new NameModule('', '', '', '', '');
    public questionTitle: NameModule = new NameModule('', '', '', '', '');
    public questionInfobooth: NameModule = new NameModule('', '', '', '', '');
    public questionPoints: number = null;
    public questionType: string = 'oneSelect';
    // public chartType: string = "";
    public operation: string = "Criar";
    public indexEdit: number = null;
    public searchFor: string = 'Pattern';
    public totalSurveys: number = null;


    // public chartBlockVisibility: boolean = true;
    public answersOptionsBlockVisibility: boolean = true;
    public activeMarker: boolean = false;
    public activeWeight: boolean = false;

    // Force complete survey
    forceComplete: boolean = false;

    // Show Survey for specific groups
    groupesVisibility: boolean = false;

    // Show Survey for specific groups
    showForMinPoints: boolean = false;

    minPoints: number

    // Errors
    public msgError = false;
    public errorLevantamento = false;
    public errorNoQuestions = false;
    public errorTitle = false;
    public errorSelectQuestion = false;
    public errorOptionAnswer = false;
    // public errorOptionGraphic = false;
    iconsImg: MenuIcons[] = icons;
    selectedIcon: string = PathIcons.icon_survey;
    selectedIconFamily: string = 'material-icons';

    loader: boolean = false;


    // ============================================ TIPO DE LEVANTAMENTO ====================================================

    public surveyType: string = TypeSurvey.Pattern;

    surveyLinkedToSession: boolean = false;
    SelectedSessionVisibility: boolean = false;
    tracksAttendeeVisibility: boolean = false;
    specificTrackVisibility: boolean = false;
    scheduleModuleVisibility: boolean = false;
    scheduleModuleTrackVisibility: boolean = false;
    scheduleModuleSessionVisibility: boolean = false;
    specificGroupVisibility: boolean = false;

    // tracks
    tracks: Array<Track>;
    public group = []; // array de ids
    public query = ''; // string digitada pelo usuário
    public filteredList = []; // lista de sugestões exibidas
    public selectedTracks = []; // array com os tracks selecionados

    // sessions
    sessions: Array<Session>;
    sessionsNames: Array<any>;
    public session = [];
    public querySession = ''; // string digitada pelo usuário
    public filteredSession = []; // lista de sugestões exibidas
    public selectedSessions = []; // array com os tracks selecionados

    // groups
    groups: Array<Group>;
    groupsNames: Array<any>;
    public ManagerGroup = []; // array de ids
    public queryGroup = ''; // string digitada pelo usuário
    public filteredGroup = []; // lista de sugestões exibidas
    public selectedGroups = []; // array com os tracks selecionados

    scheduleModules: Array<any> = [];
    //====================================== FIM TIPO DE LEVANTAMENTO ==============================================

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private db: DbSurveyProvider,
        private dbEvent: DbEventsProvider,
        private dbSession: DbScheduleProvider,
        private dbGroup: DbGroupsProvider,
        private global: GlobalService,
        private formatIdioma: FormatedMultiIdiomaService
    ) {
        // get module id
        this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.surveyModuleId = this.route.parent.params['_value']['moduleId'];
        this.load();
    }

    ngOnInit() {
        $("label.icon-selector").click(function () {
            $("#dropdownMenuButton").dropdown("toggle");
        });

        this.getEvent();
        this.getUserLanguage()
        this.getTotalSurveys()
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    getTotalSurveys() {
        this.db.getTotalSurveys(this.surveyModuleId, (data) => {
            this.totalSurveys = data;
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventTimezone = event.timezone;
            // load event language
            this.eventLanguage = event.language
            this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    changeTypeQuestion() {
        if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
            this.answersOptionsBlockVisibility = true;
            // this.chartBlockVisibility = true;

        } else if (this.questionType == 'evaluation') {
            this.answersOptionsBlockVisibility = false;
            // this.chartBlockVisibility = true;

        } else if (this.questionType == 'dissertative' || this.questionType == 'date') {
            this.answersOptionsBlockVisibility = false;
            // this.chartBlockVisibility = false;
        }
    }

    addAnswerOption() {
        let answer = new Answer();
        this.listAnswers.push(answer);
    }

    removeAnswerOption(index) {
        this.listAnswers.splice(index, 1);
    }

    addQuestion() {
        this.errorTitle = false;
        this.errorSelectQuestion = false;
        // this.errorOptionGraphic = false;
        this.errorOptionAnswer = false;
        this.msgError = false;

        //Caso o usuário não tenha inserido o titulo da pergunta
        this.questionMainTitle = this.formatIdioma.fillEmptyLanguage(this.questionMainTitle, this.eventLanguage);

        //Caso o usuário não tenha inserido o titulo da pergunta
        let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.questionTitle, this.eventLanguage);
        this.questionTitle = this.formatIdioma.fillEmptyLanguage(this.questionTitle, this.eventLanguage);
        if (this.questionTitle == undefined || this.questionTitle == null || !mainLanguage) {
            this.msgError = true;
            this.errorTitle = true;
        }

        this.questionInfobooth = this.formatIdioma.fillEmptyLanguage(this.questionInfobooth, this.eventLanguage);
        this.questionInfobooth = this.replaceLinkInfooboth(this.questionInfobooth);

        if (this.questionType == undefined || this.questionType == null || this.questionType == "") {
            this.msgError = true;
            this.errorSelectQuestion = true;
        }

        if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listAnswers) {
                let mainLanguageAnswer = this.formatIdioma.mainLanguageIsFilled(answer.answer, this.eventLanguage);
                answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
                if (answer.answer != undefined && answer.answer != null && mainLanguageAnswer) {
                    find = true;
                }
            }

            if (find == false) {
                this.msgError = true;
                this.errorOptionAnswer = true;
            }
        }

        if (this.msgError == false) {
            this.sendQuestion();
        }
    }

    sendQuestion() {
        let question = new Question();
        question.mainTitle = this.questionMainTitle;
        question.title = this.questionTitle;
        question.infobooth = this.questionInfobooth;
        question.type = this.questionType;
        question.points = this.questionPoints;
        // question.graphic = this.chartType;

        if (question.type == "oneSelect" || question.type == "multipleSelect") {
            let answers: Array<Answer> = [];
            for (let answer of this.listAnswers) {
                if (answer.answer != undefined) {
                    answers.push(answer);
                }
            }

            question.answers = answers;
        }

        if (this.operation == "Criar") {
            this.listQuestions.push(question);
        } else if (this.operation == "Editar") {
            this.listQuestions[this.indexEdit] = question;
        }

        this.resetQuestion();
    }

    getEditQuestion(index) {
        this.indexEdit = index;
        this.listAnswers = this.listQuestions[index].answers;
        this.questionMainTitle = this.listQuestions[index].mainTitle;
        this.questionTitle = this.listQuestions[index].title;
        this.questionInfobooth = this.listQuestions[index].infobooth;
        this.questionType = this.listQuestions[index].type;
        this.questionPoints = this.listQuestions[index].points;
        // this.chartType = this.listQuestions[index].graphic;
        this.operation = "Editar";
    }


    resetQuestion() {
        this.listAnswers = [new Answer()];
        this.questionMainTitle = new NameModule('', '', '', '', '');
        this.questionTitle = new NameModule('', '', '', '', '');
        this.questionInfobooth = new NameModule('', '', '', '', '');
        this.questionType = "";
        this.questionPoints = null;
        // this.chartType = "";
        this.operation = "Criar";
    }

    removeQuestion(index) {
        this.listQuestions.splice(index, 1);
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $('.dropdown-menu').removeClass('show');
    }

    createSurvey() {
        let scheduleModuleId = $("#scheduleModuleSurvey").val();
        let trackModuleId = $("#scheduleModuleTrackSurvey").val();
        let sessionModuleId = $("#scheduleModuleSessionSurvey").val();
        let iconSurvey = this.selectedIcon;
        let iconFamilySurvey = this.selectedIconFamily;
        this.loader = true;

        this.errorLevantamento = false;
        this.errorNoQuestions = false;

        let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.surveyName, this.eventLanguage);
        if (this.surveyName == undefined || this.surveyName == null || !mainLanguage) {
            this.errorLevantamento = true;
            this.loader = false;
        }
        else {

            this.surveyName = this.formatIdioma.fillEmptyLanguage(this.surveyName, this.eventLanguage);

            if (this.listQuestions.length < 1) {
                this.errorNoQuestions = true;
                this.loader = false;
            } else {
                const survey = new Survey();
                survey.title = new NameModule(this.surveyName.PtBR, this.surveyName.EnUS, this.surveyName.EsES, this.surveyName.FrFR, this.surveyName.DeDE);
                survey.type = this.searchFor;
                survey.icon = iconSurvey;
                survey.iconFamily = iconFamilySurvey;
                // survey.max_responses = maxResponses;
                survey.change_answer = this.changeAnswers;
                survey.view_answered = this.viewAnswered;
                survey.visibility = true;
                survey.module_id = this.moduleId;
                survey.question = this.listQuestions;
                survey.order = this.totalSurveys;
                survey.weight = this.activeWeight;
                survey.marker = this.activeMarker;
                survey.forceComplete = this.forceComplete;
                survey.totalQuestions = this.listQuestions.length;
                
                
                survey.minPoints = this.showForMinPoints ?  (this.minPoints >= 0 ? this.minPoints : null) : null ;
                
                if(this.groupesVisibility && this.selectedGroups !== null && this.selectedGroups.length > 0 ){
                    survey.groups = [...this.selectedGroups].map(g => g.uid) 
                }

                switch (survey.type) {
                    case TypeSurvey.Pattern:
                        this.db.createSurvey(this.surveyModuleId, survey, "", null, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        });
                        break;

                    case TypeSurvey.AllSessions:
                        this.db.createSurvey(this.surveyModuleId, survey, "", null, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        })
                        break;

                    case TypeSurvey.ScheduleModule:
                        survey.module_id = scheduleModuleId;
                        this.db.createSurvey(this.surveyModuleId, survey, scheduleModuleId, null, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        });
                        break;

                    /* case TypeSurvey.SessionTrack:
                        survey.module_id = trackModuleId;
                        this.db.createSurvey(this.surveyModuleId, survey, trackModuleId, this.selectedTracks, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        });
                        break; */

                    case TypeSurvey.SpecificSession:
                        survey.module_id = sessionModuleId;
                        this.db.createSurvey(this.surveyModuleId, survey, sessionModuleId, this.selectedSessions, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        });
                        break;

                    /* case TypeSurvey.SpecificGroup:
                        this.db.createSurvey(this.surveyModuleId, survey, null, this.selectedGroups, this.eventTimezone, (data) => {
                            if (data) {
                                this.returnSurveyList();
                            }

                            this.loader = false;
                        });
                        break; */
                }
            }
        }
    }


    returnSurveyList() {
        this.router.navigate([`/event/${this.eventId}/interactivity/survey/${this.surveyModuleId}`]);
    }


    //======================================== SURVEY TYPE ====================================================

    //verifica se o quiz será vinculado ou não a uma sessão
    changeTypeSurvey() {
        if ($("#surveyLinkedToSession").is(":checked")) {
            this.surveyLinkedToSession = true;

            //caso o quiz sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
            this.surveyType = TypeSurvey.AllSessions;
        } else {
            this.surveyLinkedToSession = false;
            this.selectedSessions = [];
            this.surveyType = TypeSurvey.Pattern;

            this.SelectedSessionVisibility = false;
            this.tracksAttendeeVisibility = false;
            this.scheduleModuleTrackVisibility = false;
            this.scheduleModuleSessionVisibility = false;
            this.specificTrackVisibility = false;
            this.specificGroupVisibility = false;
        }
    }


    //caso o quiz seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o quizType
    changeSessionType() {
        switch (this.searchFor) {
            case 'AllSessions':
                this.surveyType = TypeSurvey.AllSessions;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;

                //Esvazia o array de sessões selecionadas posteriormente.
                this.selectedSessions = [];
                break;

            case 'ScheduleModule':
                this.surveyType = TypeSurvey.ScheduleModule;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = true;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SessionTrack':
                this.surveyType = TypeSurvey.SessionTrack;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.scheduleModuleTrackVisibility = true;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificSession':
                this.surveyType = TypeSurvey.SpecificSession;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = true;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificGroup':
                this.loadGroups();
                this.surveyType = TypeSurvey.SpecificGroup;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = true;
                break;
        }
    }


    moduleSelectedChange() {
        var track = $("#scheduleModuleTrackSurvey").val();
        var session = $("#scheduleModuleSessionSurvey").val();
        var specificModule = $("#scheduleModuleSurvey").val();

        if (track == undefined || track == null || track == '') {
            this.specificTrackVisibility = false;
        } else {
            this.specificTrackVisibility = true;
            this.moduleId = track;
            this.loadTracks();
        }

        if (session == undefined || session == null || session == '') {
            this.SelectedSessionVisibility = false;
        } else {
            this.SelectedSessionVisibility = true;
            this.moduleId = session;
            this.loadSessions();
        }
    }


    load() {
        this.dbEvent.getModulesSchedule(this.eventId, (data) => {
            this.scheduleModules = data;
        });
    }


    // LOAD ALL TRACKS OF SCHEDULE MODULE 
    private loadTracks() {
        this.dbSession.getModuleTracks(this.moduleId, (tracks) => {
            this.tracks = [];
            this.tracks = tracks;
        })
    }

    // receive group name and return index of the group  to get id group
    findIdTrack(nameGroup: string) {
        const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
        return index;
    }

    // autocomplete
    filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.query !== "") {
            this.filteredList = this.tracks.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredList = [];
        }
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    select(item) {
        if (this.selectedTracks.length > 0) {
            const index = this.selectedTracks.indexOf(item);
            if (index == -1) {
                this.selectedTracks.push(item);
            }
        } else {
            this.selectedTracks.push(item);
        }
        this.query = '';
        this.filteredList = [];
    }

    remove(item) { // remove group from display list
        this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
    }



    loadSessions() {
        this.dbSession.getSessionsModule(this.moduleId, (list: Array<Session>) => {
            this.sessions = [];
            this.session = list;


            this.sessionsNames = [];
            for (const session of list) {
                this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]));
            }
        })
    }

    // autocomplete
    filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.querySession !== "") {
            this.filteredSession = this.sessionsNames.filter(function (el) {
                return el.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredSession = [];
        }
    }

    // receive session name and return index of the session to get id session
    findIdSession(nameSession: string) {
        let index = -1

        // search session using user language
        switch (this.userLanguage) {
            case ('pt_BR'):
                index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                break

            case ('en_US'):
                index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                break

            case ('es_ES'):
                index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                break

            case ('fr_FR'):
                index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                break

            case ('de_DE'):
                index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                break
        }

        // search session using principal event language
        if (index <= -1) {
            switch (this.eventLanguage) {
                case ('pt_BR'):
                    index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                    break

                case ('en_US'):
                    index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                    break

                case ('es_ES'):
                    index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                    break

                case ('fr_FR'):
                    index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                    break

                case ('de_DE'):
                    index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                    break
            }
        }

        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectSession(item) {
        const index = this.findIdSession(item);
        if (index > -1) {
            this.selectedSessions.push(this.session[index]);
        }
        this.querySession = '';
        this.filteredSession = [];
    }

    removeSession(item) { // remove group from display list
        this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
    }

    loadGroups() {
        this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
            this.groups = [];
            this.groups = response['groups'];
        });
    }

    // autocomplete
    filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.queryGroup !== "") {
            this.filteredGroup = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredGroup = [];
        }
    }

    // receive group name and return index of the group to get id group
    findIdGroup(nameGroup: string) {
        const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectGroup(item) {
        if (this.selectedGroups.length > 0) {
            const index = this.selectedGroups.indexOf(item);
            if (index == -1) {
                this.selectedGroups.push(item);
            }
        } else {
            this.selectedGroups.push(item);
        }
        this.queryGroup = '';
        this.filteredGroup = [];
    }

    removeGroup(item) { // remove group from display list
        this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }


    cancel() {
        this.location.back();
    }

    replaceLinkInfooboth(data) {
        data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

        return data;
    }

    onSelect(event){
        this.groupesVisibility && (this.groups === undefined || this.groups === null) && this.loadGroups();
    }
}
