import { Module } from "./module";
import { Session } from "../session";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";


export class ModulePersonalAgenda extends Module{
    sessions: Array<Session>;
    visOnlyForLoggedUser: boolean;
    constructor(name: NameModule, icon: string, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.PERSONALSCHEDULE, eventId, order, false, true, true)
        this.sessions = [];
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }     }
}