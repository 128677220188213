import { Component, OnInit, ViewChild } from '@angular/core';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { TypeUser } from 'src/app/enums/typeUser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare let $: any;

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [DbAdminUserProvider, StorageService, GlobalService]
})
export class ProfileComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;

    imageChangedEvent: any;
    croppedImage: any;

    formValidation: FormGroup;
    photoBase64: string = null;
    nameUser: string = null;
    urlPhoto: string = null;
    type: number = null;
    changePassActive: boolean = false;
    userId: string = null;
    loader: boolean = false;
    imageType: string;
    btnSaveClicked: boolean = false;
    notEdit: boolean = true;
    // cropperSettings: CropperSettings;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;
    passwordNotMatch: boolean = false;
    currentPasswordInvalid: boolean = false;
    constructor(
        private auth: AuthService,
        private dbAdminUser: DbAdminUserProvider,
        private route: ActivatedRoute,
        fb: FormBuilder,
        private storage: StorageService,
        private global: GlobalService) {
        this.userId = this.route.snapshot.params['uid'];
        this.formValidation = fb.group({
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(60)])],
            'password': [null],
            'confirmPassword': [null],
            'activePassword': [null],
            'language': [null, Validators.compose([Validators.required])],
            'urlPhoto': [null],
            'description': [null],
            'type': [null]
        });
    }

    ngOnInit() {
        this.loadProfile();
    }


    /**
    * On upload image file
    * @param $event 
    */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }


    /**
     * Load profile
     */
    loadProfile() {
        this.dbAdminUser.getUserProfile(this.userId, (user) => {
            this.nameUser = user.name;
            this.urlPhoto = user.photoUrl;
            this.type = user.type;

            this.formValidation.patchValue({
                name: user.name,
                email: user.email,
                language: user.language,
                photoUrl: user.photoUrl,
                description: user.description,
            });

            if (user.type == TypeUser.SUPERGOD) {
                this.formValidation.patchValue({ type: 'SUPERGOD' });
            } else if (user.type == TypeUser.GOD) {
                this.formValidation.patchValue({ type: 'GOD' });
            }
        });
    }

    /**
     * VERIFY IF USER CHANGE OR NOT THE PASSWORD AND REDIRECT TO CORRECT UPDATE FUNCTION
     * @param data
     */    async saveProfile(data) {
        this.loader = true;
        this.passwordNotMatch = false;
        this.currentPasswordInvalid = false;

        if (this.finalFile !== null) {
            await this.uploadPictureStorage(this.finalFile, this.userId);
        }

        let user = new User(data.name, data.email);
        user.$uid = this.userId;
        user.$description = data.description;
        user.$language = data.language;
        user.$type = this.type;
        user.$photoUrl = this.urlPhoto;

        if (data.password !== null && data.password !== undefined) {
            this.auth.checkLoginUser(data.email, data.activePassword, (status) => {
                if (status == true) {
                    if (data.password == data.confirmPassword) {
                        user.$password = data.password;
                        this.dbAdminUser.updateUser(this.userId, user, (data) => {
                            if (data['message'] == 'success') {
                                this.successSwal.fire();
                                this.loader = false;
                                this.btnSaveClicked = true;
                                this.notEdit = false;
                                this.global.loadService((_) => { });
                            } else {
                                this.btnSaveClicked = false;
                                this.notEdit = true;
                            }
                        });

                    } else {
                        // password do not match
                        this.passwordNotMatch = true;
                        this.loader = false;
                    }
                } else {
                    // current password invalid
                    this.currentPasswordInvalid = true;
                    this.loader = false;
                }
            });
        } else {
            this.dbAdminUser.updateUser(this.userId, user, (data) => {
                if (data['message'] == 'success') {
                    this.successSwal.fire();
                    this.loader = false;
                    this.btnSaveClicked = true;
                    this.notEdit = false;
                    this.global.loadService((_) => { });
                } else {
                    this.btnSaveClicked = false;
                    this.notEdit = true;
                }
            });
        }
    }

    uploadPictureStorage(file, userId: string) {
        return new Promise((resolve, reject) => {
            this.storage.profilePicture(file, userId, (url) => {
                if (url) {
                    this.urlPhoto = url;
                    resolve(this.urlPhoto);
                } else {
                    this.urlPhoto = null;
                    reject(this.urlPhoto);
                }
            });

        });
    }

    // CASE CANCEL PASSWORD CHANGE, CLEAR PASSWORD INPUT - !!!!VERY IMPORTANT!!!!!
    resetPass() {
        this.formValidation.patchValue({
            password: null
        });
    }

    // ERROR VALIDATIONS FORM
    get activePassword() {
        return this.formValidation.get('activePassword');
    }
    get password() {
        return this.formValidation.get('password');
    }
    get confirmPassword() {
        return this.formValidation.get('confirmPassword');
    }
    get name() {
        return this.formValidation.get('name');
    }

    get email() {
        return this.formValidation.get('email');
    }
    get language() {
        return this.formValidation.get('language');
    }

    ngOnDestroy() {
        if (this.btnSaveClicked == false && this.notEdit == false) {
            this.storage.delete(this.userId);
        }
    }

}
