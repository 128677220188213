import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/providers/global/global.service';

@Component({
  selector: 'app-change-lang',
  templateUrl: './change-lang.component.html',
  styleUrls: ['./change-lang.component.scss']
})
export class ChangeLangComponent implements OnInit {
  language: string = null;
  interval: any;
  constructor(private global: GlobalService) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      if (this.global.language !== null) {
        this.language = this.global.language;
        clearInterval(this.interval);
      }
    }, 1000)
  }
  changeLanguage(language: string) {

  }
}
