import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { StorageService } from '../storage/storage.service';
import { Notification } from '../../models/notifications';

@Injectable({
    providedIn: 'root'
})
export class DbNotificationsProvider {
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, body: {} };
    }

    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((snapshot) => {
                onResolve(snapshot.data);
            })
    }

    async getAllNotifications(eventId: string) {
        const ref1 =  this.aFirestore.firestore
            .collection(`scheduled_notifications`)
            .where(`eventId`, `==`, eventId)
            .orderBy(`createdAt`, `desc`);

        const ref2 = this.aFirestore.firestore
            .collection(`events/${eventId}/notifications`)
            .orderBy(`createdAt`, `desc`);

        const result = await Promise.all([ref1.get(), ref2.get()]);
        if (!result) return Promise.reject();

        const fn = (acc, crr) => [...acc, crr.data()];
        return Promise.resolve(
            [...result[0].docs, ...result[1].docs].reduce(fn, [])
        );
    }

    getNotification(eventId: string, notificationId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('notifications')
            .doc(notificationId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    changeOrderItems(eventId: string, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .update({ notificationsOrder: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    private sendEmail(
        to: string, 
        subject: string, 
        content: string, 
        html: string
    ) {
        return this.http.post(
            PathApi.baseUrl + PathApi.emailsSend,
            {
                to: to,
                subject: subject,
                text: content,
                html: html
            }, 
            this.requestOptions
        ).toPromise();
    }

    createNotification(
        eventId: string, 
        moduleId: string, 
        notif: Notification,
    ) {
        let ref: DocumentReference;
        const proms = [];
        notif.eventId = eventId;
        if (notif.scheduled) {
            ref = this.aFirestore.firestore
                .collection(`scheduled_notifications`).doc();
        } else {
            if (notif.type & 2) {   // only push-notification
                const body = {
                    title: notif.headings[notif.principal_language],
                    msgBody: notif.contents[notif.principal_language],
                    topics: (notif.groups_ids && notif.groups_ids.length > 0) ?
                        notif.groups_ids : [eventId]
                };
                proms.push(
                    this.http.post(
                        PathApi.baseUrl + PathApi.dbNotificationsSendPush,
                        body, 
                        this.requestOptions
                    ).toPromise()
                );
                if (notif.type == 2) return proms[proms.length - 1];
            } else if (notif.type & 4) {
                proms.push(
                    this.sendEmail(
                        notif.email_to,
                        notif.headings[notif.principal_language],
                        notif.contents[notif.principal_language],
                        notif.descriptions[notif.principal_language]
                    )
                );
                if (notif.type == 4) return proms[proms.length - 1];
            }
            ref = this.aFirestore.firestore
                .collection(`events/${eventId}/notifications`).doc();
        }
        notif.uid = ref.id;
        notif.moduleId = moduleId;
        notif = Object.assign({}, notif);
        delete notif.scheduled;
        delete notif.email_to;

        return Promise.all([ref.set(notif), ...proms]);
    }

    createMultipleNotification(eventId: string, moduleId: string, notifications: Notification[]) {
        let db = this.aFirestore.firestore;
        const batch = db.batch();
        notifications.forEach(n => {
            let doc = db.collection('events').doc(eventId).collection('notifications').doc();
            n.uid = doc.id;
            n.moduleId = moduleId;
            n.eventId = eventId;

            n = Object.assign({}, n);
            batch.set(doc, n)
        })
        return batch.commit();
    }

    deleteNotification(eventId: string, notificationId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('notifications').doc(notificationId);
        ref.delete()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    async removeNotifications(moduleId, listRemoveUids, onResolve) {
        let newList = this.breakArray(listRemoveUids, 400);

        let cont = 0;
        for (let list of newList) {
            await this.removeListNotifications(moduleId, list)
                .then((data) => {
                    if (cont == newList.length - 1) {
                        onResolve(true);
                    }

                    cont++;
                })
                .catch((err) => {
                    if (cont == newList.length - 1) {
                        onResolve(err);
                    }

                    cont++;
                })
        }

    }

    removeListNotifications(eventId, listRemoveUids) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;

            const batch = db.batch()

            // remove notifications. Activates trigger
            for (const notificationId of listRemoveUids) {
                const ref1 = db.collection('events').doc(eventId).collection('notifications').doc(notificationId)
                batch.delete(ref1)
            }

            // Commit the batch
            batch.commit().then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }
}