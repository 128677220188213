/**
Object that represents the  languages of the event.
 */

export class Languages {
    DeDE: boolean
    EnUS: boolean
    EsES: boolean
    FrFR: boolean
    PtBR: boolean

    constructor () {
        this.DeDE = false
        this.EnUS = false
        this.EsES = false
        this.FrFR = false
        this.PtBR = false
    }
}