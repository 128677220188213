import { DbAttendeesProvider } from './../providers/database/db-attendees';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DbScheduleProvider } from '../providers/database/db-schedule';
import { GlobalService } from '../providers/global/global.service';
import { Subscription } from 'rxjs';
import { FormatedEventLanguageService } from '../providers/formated-event-language/formated-event-language.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';


@Component({
    selector: 'app-ask-question-session-result',
    templateUrl: './ask-question-session-result.component.html',
    styleUrls: ['./ask-question-session-result.component.scss']
})
export class AskQuestionSessionResultComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    eventId: string = null;
    moduleId: string = null;
    sessionId: string = null;
    questions: Array<any> = [];

    refQuestions: any = null;
    refQuestionsCollection: AngularFirestoreCollection<any> = null;

    refVotes: any = null;
    refVotesCollection: AngularFirestoreCollection<any> = null;
    sessionName: string = null;
    loader: boolean = true;
    language: string = "EnUS";

    constructor(
        private route: ActivatedRoute,
        private afs: AngularFirestore,
        private dbSchedule: DbScheduleProvider,
        private dbAttendee: DbAttendeesProvider,
        private global: GlobalService,
        private SLanguage: FormatedEventLanguageService,
        private translateService: TranslateService
    ) {
        this.eventId = this.route.snapshot.params['eventId'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.sessionId = this.route.snapshot.params['sessionId'];

        this.getQuestionsSession();
    }

    ngOnInit() {
        this.language = this.SLanguage.getFormatedLanguage();
        this.dbSchedule.getSession(this.sessionId, this.eventId, (session) => {
            this.sessionName = session.name[this.language];
        })
    }

    /**
     * Unsubscribe subscriptions on destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * Get questions for the session
     */
    getQuestionsSession() {
        this.refQuestionsCollection = this.afs
            .collection('modules')
            .doc(this.moduleId)
            .collection('sessions')
            .doc(this.sessionId)
            .collection('questions', ref => ref.orderBy('createdAt').where('visibility', "==", true));


        this.subscriptions.push(this.refQuestionsCollection.valueChanges().subscribe(async (data: any) => {
            let listQuestions = [];

            if (data.length <= 0) {
                this.questions = [];
            }
            console.log(data)
            const askersIds = Array.from(new Set([...data].map(q => q.userId)));
            const allAttendees = await this.dbAttendee.getAttendeesFromArrayIdsPromise(this.eventId, askersIds)

            this.questions = [...data].map(q => {
                const attendee = [...allAttendees].filter(_ => _.uid == q.userId)[0]
                const name = attendee ? attendee.name : `${this.translateService.instant('global.anonymous_attendee')} (${askersIds.indexOf(q.userId) + 1})`
                q.userId = name;
                this.refVotesCollection = this.refQuestionsCollection
                .doc(q.uid)
                .collection('votes');
                this.subscriptions.push(this.refVotesCollection.valueChanges().subscribe((votes: any) => {
                    let totalVotes = votes.length;
                    console.log(q.uid,' totalVotes', totalVotes)
                    q.totalVotes = totalVotes;
                    this.sortQuestion()
                }))
                // return {...q};
                return q;
            });
            this.questions = [...this.questions].sort((a,b) => a.totalVotes < b.totalVotes ? 1 : a.totalVotes > b.totalVotes ? -1 : 0 )
            this.loader = false
            
            // data.forEach(element => {
            //     let question = element;
            //     console.log('question: ', question)
            //     this.refVotesCollection = this.refQuestionsCollection
            //         .doc(question.uid)
            //         .collection('votes');
            //     this.dbAttendee.getAttendee(this.eventId, this.moduleId, element.userId, (attendee) => {
            //         // question.name = 'attendee.result.name';
            //         console.log('attendee', attendee)
            //         // question.name = attendee.result.name;
            //         let dateCreated = element.createdAt;
            //         dateCreated = new Date(dateCreated * 1000);
            //         question.createdAt = dateCreated;
                

            //         this.subscriptions.push(this.refVotesCollection.valueChanges().subscribe((data: any) => {
            //             let totalVotes = data.length;

            //             question.totalVotes = totalVotes;

            //             let index = this.checkIndexExists(listQuestions, question);

            //             if (index >= 0) {
            //                 listQuestions[index] = question;
            //             } else {
            //                 listQuestions.push(question);
            //             }

            //             listQuestions = listQuestions.sort(function (a, b) {
            //                 if (a.totalVotes < b.totalVotes) {
            //                     return 1;
            //                 }
            //                 if (a.totalVotes > b.totalVotes) {
            //                     return -1;
            //                 }
            //                 // a must be equal to b
            //                 return 0;
            //             });

            //             this.questions = listQuestions;
            //             this.loader = false;
            //         }))
            //     })
            // });
        }))
    }

    /**
     * Check if index exist
     * @param array 
     * @param item 
     */
    checkIndexExists(array, item) {
        return array.map(function (e) { return e.uid; }).indexOf(item.uid);
    }

    /**
     * Convert language format
     * @param lang 
     */
    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    getDate(dateNumber){
        return this.language !== "FrFR" ? moment(dateNumber * 1000).format("YYYY/MM/DD - HH:mm") : moment(dateNumber * 1000).format('DD/MM/YYYY - HH:mm')
    }
    sortQuestion(){
        this.questions.sort((a, b) => {
            return a.totalVotes > b.totalVotes
            ? -1
            : a.totalVotes < b.totalVotes
            ? 1
            : (a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
        });
    }
}
