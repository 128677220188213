/**
 * By default it was set to use the "Europe / Paris" zone as the default for all dates. That goes for Api too.
 */


import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';

@Injectable()

export class NotifiicationDateService {
    constructor() {

    }

    // get date in year-month-day / hour:minute
    convertToRFC(date, time, gmt) {
        let arrayDate = date.split("-");
        let arrayTime = time.split(":");
        let year = parseInt(arrayDate[0]);
        let month = parseInt(arrayDate[1]);
        let day = parseInt(arrayDate[2]);
        let hour = parseInt(arrayTime[0]);
        let minutes = parseInt(arrayTime[1]);

        let zone = this.getZoneFromGMT(gmt);

        // return date in RFC2822;
        return DateTime.local(year, month, day, hour, minutes).setZone(zone, { keepLocalTime: true }).toRFC2822();
    }

    getTimeStampFromDateNow(date, gmt) {
        // let zone = this.getZoneFromGMT(gmt);
        // let fDate = date.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
        // let dateArray = fDate.split(/[/: ]/);
        // let year = parseInt(dateArray[2]);
        // let month = parseInt(dateArray[1]);
        // let day = parseInt(dateArray[0]);
        // let hour = parseInt(dateArray[3]);
        // let minutes = parseInt(dateArray[4]);
        // return DateTime.local(year, month, day, hour, minutes).setZone(zone, { keepLocalTime: true }).toSeconds();
        let zone = this.getZoneFromGMT(gmt);
        let fDate = DateTime.local().setZone('America/Sao_Paulo')['c'];
        let year = fDate['year'];
        let month = fDate['month'];
        let day = fDate['day'];
        let hour = fDate['hour'];
        let minutes = fDate['minute'];
        let seconds = fDate['second'];
        let milliseconds = fDate['millisecond'];
        return DateTime.local().toMillis();
        // return DateTime.local(year, month, day, hour, minutes, seconds, milliseconds).setZone(zone, { keepLocalTime: true }).toMillis();
    }

    /**
     * 
     * @param instant date time ISO string without the timezone 
     * (yyyy-mm-ddThh:MM:ss), e.g., 2024-05-20T17:50:00
     * @param timezone 
     * @returns a timestamp of the instant and timezone passed as params
     */
    mkTimestamp(instant: string, timezone: string) {
        return DateTime.fromISO(instant, { zone: timezone }).toMillis();
    }

    getZoneFromGMT(gmt) {
        let zone;

        switch (gmt) {
            case '(UTC-10) Honolulu, Hawaii':
                zone = 'Pacific/Honolulu';
                break;
            case '(UTC-7) Seattle, Washington, Phoenix, Arizona, Los Angeles, Las Vegas, Portland':
                zone = 'America/Phoenix';
                break;
            case '(UTC-6) Costa Rica':
                zone = 'America/Costa_Rica';
                break;
            case '(UTC-6) El Salvador':
                zone = 'America/El_Salvador';
                break;
            case '(UTC-6) Guatemala':
                zone = 'America/Guatemala';
                break;
            case '(UTC-6) México City, Chiapas, Guanajuato, Nuevo León, San Luis Potosí, Tamaulipas':
                zone = 'America/Mexico_City';
                break;
            case '(UTC-6) Colombia':
                zone = 'America/Bogota';
                break;
            case '(UTC-6) Ecuador':
                zone = 'America/Guayaquil';
                break;
            case '(UTC-5) Quintana Roo':
                zone = 'America/Cancun';
                break;
            case '(UTC-5) Panama':
                zone = 'America/Panama';
                break;
            case '(UTC-5) Peru':
                zone = 'America/Lima';
                break;
            case '(UTC-5) Birmingham, Chicago, New Orleans, Minneapolis, Oklahoma City':
                zone = 'America/Chicago';
                break;
            case '(UTC-4) Bahamas':
                zone = 'America/Nassau';
                break;
            case '(UTC-4) Bolivia':
                zone = 'America/La_Paz';
                break;
            case '(UTC-4) Dominican Republic':
                zone = 'America/Port_of_Spain';
                break;
            case '(UTC-4) Washington, Atlanta, Indianapolis, Boston, Detroit,  New York, Philadelphia':
                zone = 'America/New_York';
                break;
            case '(UTC-4) Venezuela':
                zone = 'America/Caracas';
                break;
            case '(UTC-3) Argentina':
                zone = 'America/Buenos_Aires';
                break;
            case '(UTC-3) Brasilia, Salvador, Fortaleza, Belo Horizonte, Rio de Janeiro, Porto Alegre, São Paulo':
                zone = 'America/Sao_Paulo';
                break;
            case '(UTC-3) Paraguay':
                zone = 'America/Asuncion';
                break;
            case '(UTC-3) Uruguay':
                zone = 'America/Montevideo';
                break;
            case '(UTC-1) Cape Verde':
                zone = 'Atlantic/Cape_Verde';
                break;
            case '(UTC-1) Azores':
                zone = 'Atlantic/Azores';
                break;
            case '(UTC+0) Ghana':
                zone = 'Africa/Accra';
                break;
            case '(UTC+0) Iceland':
                zone = 'Atlantic/Reykjavik';
                break;
            case '(UTC+0) Ireland':
                zone = 'Europe/Dublin';
                break;
            case '(UTC+0) Morocco':
                zone = 'Africa/Accra';
                break;
            case '(UTC+0) Lisboa, Braga, Coimbra, Madeira, Porto, Vila Real':
                zone = 'Europe/Lisbon';
                break;
            case '(UTC+0) Las Palmas, Santa Cruz de Tenerife':
                zone = 'Atlantic/Canary';
                break;
            case '(UTC+0) Togo':
                zone = 'Atlantic/Faroe';
                break;
            case '(UTC+0) United Kingdom':
                zone = 'Europe/London';
                break;
            case '(UTC+1) Albania':
                zone = 'Europe/Tirane';
                break;
            case '(UTC+1) Algeria':
                zone = 'Africa/Algiers';
                break;
            case '(UTC+1) Andorra':
                zone = 'Europe/Andorra';
                break;
            case '(UTC+1) Angola':
                zone = 'Africa/Luanda';
                break;
            case '(UTC+1) Austria':
                zone = 'Europe/Vienna';
                break;
            case '(UTC+1) Belgium':
                zone = 'Europe/Brussels';
                break;
            case '(UTC+1) Cameroon':
                zone = 'Africa/Douala';
                break;
            case '(UTC+1) Croatia':
                zone = 'Europe/Belgrade';
                break;
            case '(UTC+1) Czech Republic':
                zone = 'Europe/Prague';
                break;
            case '(UTC+1) Denmark':
                zone = 'Europe/Copenhagen';
                break;
            case '(UTC+1) France':
                zone = 'Europe/Paris';
                break;
            case '(UTC+1) Germany':
                zone = 'Europe/Berlin';
                break;
            case '(UTC+1) Hungary':
                zone = 'Europe/Budapest';
                break;
            case '(UTC+1) Italy':
                zone = 'Europe/Rome';
                break;
            case '(UTC+1) Liechtenstein':
                zone = 'Europe/Vaduz';
                break;
            case '(UTC+1) Luxembourg':
                zone = 'Europe/Luxembourg';
                break;
            case '(UTC+1) Nigeria':
                zone = 'Africa/Lagos';
                break;
            case '(UTC+1) Norway':
                zone = 'Europe/Oslo';
                break;
            case '(UTC+1) Poland':
                zone = 'Europe/Warsaw';
                break;
            case '(UTC+1) Serbia':
                zone = 'Europe/Belgrade';
                break;
            case '(UTC+1) Slovakia':
                zone = 'Europe/Bratislava';
                break;
            case '(UTC+1) Slovenia':
                zone = 'Europe/Ljubljana';
                break;
            case '(UTC+1) Madrid, Barcelona, Córdoba, Girona, Granada, Navarra, València':
                zone = 'Europe/Madrid';
                break;
            case '(UTC+1) Sweden':
                zone = 'Europe/Stockholm';
                break;
            case '(UTC+1) Switzerland':
                zone = 'America/Indiana/Vevay';
                break;
            case '(UTC+1) Tunisia':
                zone = 'Africa/Tunis';
                break;
            case '(UTC+2) Finland':
                zone = 'Europe/Helsinki';
                break;
            case '(UTC+2) Israel':
                zone = 'Asia/Jerusalem';
                break;
            case '(UTC+2) Romania':
                zone = 'Europe/Bucharest';
                break;
            case '(UTC+2) South Africa':
                zone = 'Africa/Johannesburg';
                break;
            case '(UTC+2) Ukraine':
                zone = 'Europe/Kiev';
                break;
            case '(UTC+2) Egypt':
                zone = 'Africa/Cairo';
                break;
            case '(UTC+3) Iraq':
                zone = 'Asia/Baghdad';
                break;
            case '(UTC+3) Kuwait':
                zone = 'Asia/Kuwait';
                break;
            case '(UTC+3) Saudi Arabia':
                zone = 'Asia/Riyadh';
                break;
            case '(UTC+3) Qatar':
                zone = 'Asia/Qatar';
                break;
            case '(UTC+3) Moscow, Adygeya, Volgograd':
                zone = 'Europe/Moscow';
                break;
            case '(UTC+4) Samara':
                zone = 'Europe/Samara';
                break;
            case '(UTC+4) United Arab Emirates':
                zone = 'Asia/Dubai';
                break;
            case '(UTC+5.5) India':
                zone = 'Asia/Kolkata';
                break;
            case '(UTC+6) Tianjin, Xinjiang, Xizang':
                zone = 'Asia/Urumqi';
                break;
            case '(UTC+7) Jakarta, Pontianak':
                zone = 'Asia/Jakarta';
                break;
            case '(UTC+7) Thailand':
                zone = 'Asia/Macau';
                break;
            case '(UTC+8) Western Australia':
                zone = 'Australia/Perth';
                break;
            case '(UTC+8) Beijing, Guizhou, Hong Kong, Shaanxi, Shanghai':
                zone = 'Asia/Shanghai';
                break;
            case '(UTC+8) Hong Kong':
                zone = 'Asia/Hong_Kong';
                break;
            case '(UTC+8) Singapore':
                zone = 'Asia/Singapore';
                break;
            case '(UTC+8.5) North Korea':
                zone = 'Asia/Pyongyang';
                break;
            case '(UTC+9) Japan':
                zone = 'Asia/Tokyo';
                break;
            case '(UTC+9) South Korea':
                zone = 'Asia/Seoul';
                break;
        }

        return zone;
    }
}