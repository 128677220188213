import { IDate } from '../shared/interfaces/date.interfaces';
import { DescriptionsSession } from './description-session';
import { NameSession } from './name-session';
import { UUID } from 'angular2-uuid';

export class Session {
    uid: string;
    identifier: number; // id usado para importar e exportar.
    name: NameSession;
    date: any;
    newDate: IDate;
    startTime: any;
    newStartTime: IDate;
    endTime: any;
    newEndTime: IDate;
    anonymous: boolean;

    moduleId: string;
    eventId: string;
    descriptions: DescriptionsSession;

    locations;
    tracks;
    groups;
    attendees;
    speakers;
    documents;
    customFields;

    // personal schedule control
    limitAttendees: number; //limit of participants who will be able to have the session on the personal agenda

    askQuestion = false;
    askModerate = false;

    visio: any;

    videoPlayer: {
        type: string,
        url: string,
        id: string
    }

    constructor() {
        this.uid = '';
        this.identifier = 0;
        this.name = new NameSession('', '', '', '', '');
        this.date = '';
        this.newDate = null;
        this.newStartTime = null;
        this.newEndTime = null;
        this.startTime = '';
        this.endTime = '';
        this.moduleId = '';
        this.eventId = '';
        this.anonymous = true;
        this.descriptions = new DescriptionsSession();
        this.limitAttendees = 0;

        this.locations = {};
        this.tracks = {};
        this.groups = {};
        this.attendees = {};
        this.speakers = {};
        this.documents = {};
        this.customFields = {}

        this.videoPlayer = {
            type: "",
            url: "",
            id: UUID.UUID()
        };
    }
}
