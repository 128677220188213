import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbDocuments } from 'src/app/providers/database/db-documents';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { UUID } from 'angular2-uuid';
import { Document } from '../../../../../models/document';
import { TranslateService } from '@ngx-translate/core';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/providers/global/global.service';
import { NameModule } from 'src/app/models/name-module';
import { Languages } from 'src/app/models/languages';
import { environment } from 'src/environments/environment';

declare let $: any;
@Component({
    selector: 'app-documents-folder',
    templateUrl: './documents-folder.component.html',
    styleUrls: ['./documents-folder.component.scss']
})
export class DocumentsFolderComponent implements OnInit {
    public module = null

    // get the language of the user.
    public userLanguage: string
    public userLanguageFormated: string = environment.platform.defaultLanguage;

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    eventId: string;
    moduleId: string;
    folderId: string;
    translateMsg: string;

    documents: Array<Document> = [];
    documentDeleteId: string = null;
    documentStorageId: string = null;
    documentEdit: Document;
    newName: NameModule = new NameModule('', '', '', '', '');
    emptyName: boolean = false;
    loaderEdit: boolean = false;

    selectedAllInput: boolean = false;
    documentsSelected: Array<boolean> = [];
    typeOrder: string = null;

    event: Event;
    invalidSize: boolean = false;
    term: string;

    eventLanguage: string   // get the language of the event.
    languages: Languages = null //event languages
    activeLanguage: string = null;

    constructor(
        private route: ActivatedRoute,
        private dbDocuments: DbDocuments,
        private storage: StorageService,
        private translateService: TranslateService,
        private dbEvent: DbEventsProvider,
        private location: Location,
        private global: GlobalService,
        private DbDocuments: DbDocuments,
    ) {
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.parent.params['_value']['moduleId'];
        this.folderId = this.route.snapshot.params['folderId'];
        this.translateMsg = this.translateService.instant('comp.documents.dragdrop_msg');
    }

    ngOnInit() {
        this.getUserLanguage();
        this.startEvent();
        this.getModule();
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
            this.eventLanguage = event.language;
            this.activeLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        });
    }

    getFolder() {
        this.dbDocuments.getFolder(this.moduleId, this.folderId, (folder) => {
            if (folder !== undefined) {
                this.typeOrder = folder['orderDocuments'];
                this.getDocuments();
            }
        })
    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    getDocuments() {
        this.dbDocuments.getDocuments(this.moduleId, this.folderId, this.typeOrder, this.userLanguageFormated, (documents: Array<Document>) => {
            this.documents = [];
            this.documents = documents;
        });
    }

    getModule() {
        this.DbDocuments.getModule(this.moduleId, (module) => {
            if (typeof module !== 'undefined' && module !== null) {
                this.module = module;
            }
        });
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
            this.userLanguageFormated = this.convertLangFormat(this.userLanguage);
            this.getFolder();
        })
    }

    changeOrder() {
        this.dbDocuments.changeOrderItems(this.moduleId, this.folderId, this.typeOrder, (data) => {
            if (data == true) {
                this.getDocuments();
            }
        })
    }

    async upload(fileList: FileList) {
        this.invalidSize = false;

        // check identifieer
        if (typeof fileList[0].name === 'undefined' || fileList[0].name === null || fileList[0].name === '') {
            this.invalidSize = true;
        } else {
            // indentifier
            let identifier = fileList[0].name
            let data = identifier
            let i = 1;
            let flag = false

            while (!flag) {
                let pos = this.documents.map(function (e) { return e.identifier; }).indexOf(data);

                if (pos > -1) {
                    let period = identifier.lastIndexOf('.');
                    let aux = identifier.substring(0, period);
                    let type = identifier.substring(period + 1);
                    data = `${aux}(${i}).${type}`

                    i++
                } else {
                    flag = true
                    identifier = data
                }
            }

            // file
            const file = fileList[0];

            let period = identifier.lastIndexOf('.');
            let type = identifier.substring(period + 1);

            let name: NameModule = new NameModule(identifier, identifier, identifier, identifier, identifier);
            let docStorageId = UUID.UUID();

            // VERIFICA O TAMANHO DA IMAGEM
            if (file.size <= this.event.documents_file_limit) {
                this.storage.documentFile(file, this.eventId, this.moduleId, this.folderId, docStorageId, type, (url) => {
                    console.log("Url: ", url);
                    let document = new Document(identifier, name, this.eventId, this.moduleId, this.folderId, type);
                    document.url = url;
                    document.storageId = docStorageId;
                    document.type = type;
                    document.createdAt = Date.now() / 1000 | 0;
                    this.dbDocuments.saveDocument(this.moduleId, this.eventId, this.folderId, document, (status) => {
                        if (!status) {
                            this.storage.deleteDocument(this.eventId, this.moduleId, this.folderId, docStorageId);
                        }
                    });
                });
            } else {
                this.invalidSize = true;
            }
        }
    }

    onUploadError(error) {
        console.log("Error: ", error);
    }

    getDocEdit(doc: Document, name: NameModule) {
        this.documentEdit = doc;
        this.newName = name;
    }

    editName() {
        this.emptyName = false;
        let mainLanguageIsFilled = this.mainLanguageIsFilled(this.newName);

        if (this.newName !== null && this.newName !== undefined && mainLanguageIsFilled) {
            this.loaderEdit = true;
            this.dbDocuments.editDocumentName(this.moduleId, this.eventId, this.folderId, this.documentEdit, this.newName, (status) => {
                if (!status) {
                    this.errorSwal.fire();
                    this.loaderEdit = false;
                } else {
                    $('#editDocument').modal('toggle');
                    this.loaderEdit = false;
                    this.successSwal.fire();
                }
            })
        } else {
            // name is empty / required 
            this.emptyName = true;
        }
    }

    getDocumentDelete(uid: string, storageId: string) {
        this.documentDeleteId = uid;
        this.documentStorageId = storageId;
    }

    deleteDocument() {
        this.dbDocuments.deleteDocument(this.eventId, this.moduleId, this.folderId, this.documentDeleteId, this.documentStorageId, (status) => {
            if (!status) {
                this.errorSwal.fire();
            } else {
                this.successSwal.fire();
            }
        })
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.documents.length; i++) {
                this.documentsSelected[this.documents[i]['uid']] = true;
            }
        } else {
            for (let i = 0; i < this.documents.length; i++) {
                this.documentsSelected[this.documents[i]['uid']] = false;
            }
        }
    }

    removeSelected() {
        // this.loader = true;
        let listRemove = [];
        let listImgStorageRemove = [];
        // let listRemoveIndexes = [];

        for (let i = 0; i < this.documents.length; i++) {
            if (this.documentsSelected[this.documents[i].uid] == true) {
                listRemove.push(this.documents[i].uid);
                listImgStorageRemove.push(this.documents[i].storageId);
                // listRemoveIndexes.push(i);
            }
        }


        this.dbDocuments.deleteDocuments(this.eventId, this.folderId, this.moduleId, listRemove, listImgStorageRemove, (data) => {
            if (data == true) {
                // remove attendee from array
                // let cont = 0;
                // for (let j of listRemoveIndexes) {
                //   this.documents.splice(j - cont, 1);

                //   cont++;
                // }

                //remove all selected box
                for (let j = 0; j < this.documentsSelected.length; j++) {
                    this.documentsSelected[this.documents[j].uid] = false;
                }

                // this.loader = false;
                this.getDocuments();
                this.selectedAllInput = false;
                this.successSwal.fire();

            } else {
                // this.loader = false;
                this.errorSwal.fire();
            }
        })
    }

    mainLanguageIsFilled(languagesObj) {
        switch (this.eventLanguage) {
            case 'pt_BR':
                if (languagesObj['PtBR'] !== '') {
                    return true;
                }
                break;

            case 'en_US':
                if (languagesObj['EnUS'] !== '') {
                    return true;
                }
                break;

            case 'es_ES':
                if (languagesObj['EsES'] !== '') {
                    return true;
                }
                break;

            case 'fr_FR':
                if (languagesObj['FrFR'] !== '') {
                    return true;
                }
                break;

            case 'de_DE':
                if (languagesObj['DeDE'] !== '') {
                    return true;
                }
                break;
        }

        return false;
    }

    back() {
        this.location.back();
    }
}
