import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { AppState } from 'src/app/shared/reducers';
import * as moment from 'moment';
import { getSpeakersAnalytics } from 'src/app/shared/selectors/analytics.selectors';

@Component({
    selector: 'app-speakers-analytics',
    templateUrl: './speakers-analytics.component.html',
    styleUrls: ['./speakers-analytics.component.scss']
})
export class SpeakersAnalyticsComponent implements OnInit {
    loader: boolean = true;

    event: any;
    eventId: string;
    userLanguage: string;

    totalSpeakersModules: number = 0;
    totalSpeakers: number = 0;

    speakersModules: any[] = [];
    speakersModuleId: any;
    speakersForSelectedModule: any[] = [];

    currentPageSpeakers: number = 1;

    numberOfItemsPerPage: number = 10;

    sortType: string = "id";

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics.getSpeakersModulesAnalytics(this.eventId).pipe(take(1)).subscribe((speakersModules) => {
                this.speakersModules = speakersModules;

                this.store.select(getSpeakersAnalytics).subscribe((speakersAnalytics) => {
                    this.totalSpeakersModules = (speakersAnalytics && speakersAnalytics.totalSpeakersModules) ? speakersAnalytics.totalSpeakersModules : 0;
                    this.totalSpeakers = (speakersAnalytics && speakersAnalytics.totalSpeakers) ? speakersAnalytics.totalSpeakers : 0;
                })

                this.loader = false;
            });
        });
    }

    /**
     * Export analytics of speakers
     */
    exportAll() {
        // this.loader = true;
        // this.dbAnalytics.exportSpeakersAnalytics(this.eventId).subscribe(() => {
        //     this.loader = false;
        // }, (error) => {
        //     this.loader = false;
        // })
    }

    /**
     * Export speakers module analytics
     */
    exportSpeakersForModule() {
        this.loader = true;
        this.dbAnalytics.exportSpeakersForModuleAnalytics(this.eventId, this.speakersModuleId, this.userLanguage).subscribe((result) => {
            this.loader = false;
        }, (error) => {
            console.log("Error: ", error);
            this.loader = false;
        })
    }

    /**
     * Change selected module
     */
    selectedModuleChange() {
        this.loader = true;
        this.dbAnalytics.getSpeakersForModule(this.eventId, this.speakersModuleId).subscribe((speakers) => {
            console.log("speakers: ", speakers);
            this.sortType = 'desc-startTime';
            this.speakersForSelectedModule = speakers;

            this.loader = false;

        }, (error) => {
            console.log("Error: ", error);
            this.loader = false;
        })
    }

    /**
     * Convert date to human read
     * @param date 
     */
    convertDate(date: number) {
        return (moment.unix(date).format('DD/MM/YYYY - HH:mm'));
    }

    /**
     * Get name for selected schedule
     */
    getSelectedSpeakersModuleName() {
        let name = this.speakersModules.filter((module) => module.uid == this.speakersModuleId)[0].name[this.userLanguage];
        return ((name) ? name : "");
    }

    /**
     * Sorting speakers
     * @param type 
     */
    sortingBy(type: string) {
        if (this.speakersForSelectedModule && this.speakersForSelectedModule.length > 0) {
            switch (type) {
                case 'identifier':
                    if (this.sortType == 'desc-identifier') {
                        this.sortType = 'asc-identifier';
                        this.speakersForSelectedModule.sort((a, b) => (a.identifier < b.identifier) ? 1 : (a.identifier > b.identifier) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-identifier';
                        this.speakersForSelectedModule.sort((a, b) => (a.identifier > b.identifier) ? 1 : (a.identifier < b.identifier) ? -1 : 0);
                    }
                    break;
                case 'name':
                    if (this.sortType == 'desc-name') {
                        this.sortType = 'asc-name';
                        this.speakersForSelectedModule.sort((a, b) => (a.name < b.name) ? 1 : (a.name > b.name) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-name';
                        this.speakersForSelectedModule.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0);
                    }
                    break;
                case 'email':
                    if (this.sortType == 'desc-email') {
                        this.sortType = 'asc-email';
                        this.speakersForSelectedModule.sort((a, b) => (a.email < b.email) ? 1 : (a.email > b.email) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-email';
                        this.speakersForSelectedModule.sort((a, b) => (a.email > b.email) ? 1 : (a.email < b.email) ? -1 : 0);
                    }
                    break;
            }
        }
    }
}
