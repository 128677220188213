import { AnalyticsActionsTypes, AnalyticsActionsUnion } from '../actions/analytics.actions';
import { IAnalyticsState } from '../interfaces/analytics.interfaces';

export const initialState: IAnalyticsState = {
    general: null,
    attendees: null,
    chats: null,
    documents: null,
    feeds: null,
    galleries: null,
    schedules: null,
    speakers: null,
    tracking: null,
    users: null,
    visios: null
}

export function analyticsReducer(state = initialState, action: AnalyticsActionsUnion): IAnalyticsState {
    switch (action.type) {

        /******************************************/
        /*********   General analytics   **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateGeneralAnalytics: {
            return ({
                ...state,
                general: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalAccess: {
            return ({
                ...state,
                general: {
                    ...state.general,
                    totalAccess: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalProfilEdition: {
            return ({
                ...state,
                general: {
                    ...state.general,
                    totalProfilEdition: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateBestConsultedModules: {
            return ({
                ...state,
                general: {
                    ...state.general,
                    bestConsultedModules: action.payload
                }
            })
        }



        /******************************************/
        /*********  Attendees analytics  **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateAttendeesAnalytics: {
            return ({
                ...state,
                attendees: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalAttendeesModulesAnalytics: {
            return ({
                ...state,
                attendees: {
                    ...state.attendees,
                    totalAttendeesModules: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalAttendeesAnalytics: {
            return ({
                ...state,
                attendees: {
                    ...state.attendees,
                    totalAttendees: action.payload
                }
            })
        }




        /******************************************/
        /***********  Chats analytics  ************/
        /******************************************/
        case AnalyticsActionsTypes.UpdateChatsAnalytics: {
            return ({
                ...state,
                chats: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalChats: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalChats: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalChatsUsers: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalChatsUsers: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalMessagesChats: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalMessagesOnChats: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalDiscussionGroups: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalDiscussionGroups: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalDiscussionGroupsUsers: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalDiscussionGroupsUsers: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalMessagesDiscussionGroups: {
            return ({
                ...state,
                chats: {
                    ...state.chats,
                    totalMessagesOnDiscussionGroups: action.payload
                }
            })
        }




        /******************************************/
        /*********  Documents analytics  **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateDocumentsAnalytics: {
            return ({
                ...state,
                documents: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateBestDocuments: {
            return ({
                ...state,
                documents: {
                    ...state.documents,
                    bestDocuments: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalDocuments: {
            return ({
                ...state,
                documents: {
                    ...state.documents,
                    totalDocuments: action.payload
                }
            })
        }


        /******************************************/
        /*********    Feeds analytics    **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateFeedsAnalytics: {
            return ({
                ...state,
                feeds: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalFeedsPosts: {
            return ({
                ...state,
                feeds: {
                    ...state.feeds,
                    totalFeedsPosts: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalFeedsLikes: {
            return ({
                ...state,
                feeds: {
                    ...state.feeds,
                    totalFeedsLikes: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalFeedsComments: {
            return ({
                ...state,
                feeds: {
                    ...state.feeds,
                    totalFeedsComments: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateBestLikedFeeds: {
            return ({
                ...state,
                feeds: {
                    ...state.feeds,
                    bestLikedFeeds: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateBestCommentedFeeds: {
            return ({
                ...state,
                feeds: {
                    ...state.feeds,
                    bestCommentedFeeds: action.payload
                }
            })
        }



        /******************************************/
        /*********  Galleries analytics  **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateGalleriesAnalytics: {
            return ({
                ...state,
                galleries: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateBestImages: {
            return ({
                ...state,
                galleries: {
                    ...state.galleries,
                    bestImages: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalImages: {
            return ({
                ...state,
                galleries: {
                    ...state.galleries,
                    totalImages: action.payload
                }
            })
        }



        /******************************************/
        /*********    Feeds analytics    **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateTrackingAnalytics: {
            return ({
                ...state,
                tracking: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateUserRoadTrackingAnalytics: {
            return ({
                ...state,
                tracking: {
                    ...state.tracking,
                    userRoadTracking: action.payload
                }
            })
        }



        /******************************************/
        /*********  Schedules analytics  **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateSchedulesAnalytics: {
            return ({
                ...state,
                schedules: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalSchedulesModulesAnalytics: {
            return ({
                ...state,
                schedules: {
                    ...state.schedules,
                    totalSchedulesModules: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalSchedulesSessionsAnalytics: {
            return ({
                ...state,
                schedules: {
                    ...state.schedules,
                    totalSessionsSchedules: action.payload
                }
            })
        }



        /******************************************/
        /*********  Speakers analytics  **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateSpeakersAnalytics: {
            return ({
                ...state,
                speakers: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalSpeakersModulesAnalytics: {
            return ({
                ...state,
                speakers: {
                    ...state.speakers,
                    totalSpeakersModules: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalSpeakersAnalytics: {
            return ({
                ...state,
                speakers: {
                    ...state.speakers,
                    totalSpeakers: action.payload
                }
            })
        }



        /******************************************/
        /*********    Users analytics    **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateUsersAnalytics: {
            return ({
                ...state,
                users: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalUniqueUsers: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    totalUniqueUsers: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateCurrentConnectedAttendees: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    currentConnectedAttendees: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateCurrentConnectedSpeakers: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    currentConnectedSpeakers: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalUsersByModule: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    totalUsersByModules: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalAttendees: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    totalAttendees: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalSpeakers: {
            return ({
                ...state,
                users: {
                    ...state.users,
                    totalSpeakers: action.payload
                }
            })
        }



        /******************************************/
        /*********   Visios analytics    **********/
        /******************************************/
        case AnalyticsActionsTypes.UpdateVisiosAnalytics: {
            return ({
                ...state,
                visios: action.payload
            })
        }
        case AnalyticsActionsTypes.UpdateTotalPIM: {
            return ({
                ...state,
                visios: {
                    ...state.visios,
                    totalPIM: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalRoomsForEvent: {
            return ({
                ...state,
                visios: {
                    ...state.visios,
                    totalRoomsForEvent: action.payload
                }
            })
        }
        case AnalyticsActionsTypes.UpdateTotalRoomsBetween2: {
            return ({
                ...state,
                visios: {
                    ...state.visios,
                    totalRoomsBetween2: action.payload
                }
            })
        }

        default: {
            return (state);
        }
    }
}