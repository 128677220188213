import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Group } from "src/app/models/group";
import { Router, ActivatedRoute } from "@angular/router";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";
import { RegexProvider } from "src/app/providers/regex/regex.service";
import { GlobalService } from "src/app/providers/global/global.service";
import { NameGroup } from "src/app/enums/name-group";
import { DbEventsProvider } from "src/app/providers/database/db.events";

declare let $: any;
type AOA = Array<Array<any>>;
@Component({
    selector: "app-groups",
    templateUrl: "./groups.component.html",
    styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent implements OnInit {
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("notExport") public notExport: SwalComponent;
    @ViewChild("inputFile") public inputFile: ElementRef;

    public p: number = 1;
    public selectedAllInput: boolean = false;
    term: string = "";
    groups: Array<Group> = [];
    groupsSelected: Array<boolean> = [];
    eventId: string = this.route.parent.params["_value"]["uid"];
    moduleId: string = this.route.params["_value"]["moduleId"];
    loader: boolean = true;
    typeOrder: string = null;

    groupModule;
    groupName: string = null;
    groupEdit: Group = null; //object used to record the group
    nGroupBgColor: string = "transparent";
    nGroupTxtColor: string = "#000000";
    groupEditId: string = null;
    groupEditIdentifier: string = null;
    deleteGroupId: string = null;
    nameError: boolean = false;
    idError: boolean = false;
    idRequiredError: boolean = false;
    nameErrorEdit: boolean = false;
    idErrorEdit: boolean = false;
    idRequiredErrorEdit: boolean = false;

    // export groups
    dataExportGroups: AOA = null;
    data: any = null;
    loaderEdit: boolean = false;
    loaderCreate: boolean = false;
    hex;

    // import group
    nameFile: string = null;
    loaderImport: boolean = false;
    importError: boolean = false;
    checkImportError: boolean = false;
    importSuccess: boolean = false;
    messageErrors: Array<any> = [];
    newGroups: Array<Group> = [];
    totalCreated: number = 0;
    totalUpdated: number = 0;
    totalFail: number = 0;
    failArray: Array<Group> = [];
    identifier: number = 0;
    name: number = 1;
    txtColor: number = 2;
    bgColor: number = 3;

    // get the language of the user.
    public userLanguage: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dbGroups: DbGroupsProvider,
        private translateService: TranslateService,
        private regex: RegexProvider,
        private dbEvent: DbEventsProvider
    ) {
        this.dbGroups.getGroupModule(this.moduleId, (module) => {
            this.groupModule = module;
        });
    }

    module: any = null;
    ngOnInit() {
        this.dbGroups.getGroupModule(this.moduleId, (module) => {
            if (typeof module !== "undefined" && module !== null) {
                this.module = module;
                this.typeOrder = module["orderGroups"];
                this.loadGroups();
            }
        });
        this.getEvent();
    }

    event: any = null;
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    loadGroups() {
        this.dbGroups.getGroups(this.moduleId, this.typeOrder, (groups) => {
            this.groups = [];
            this.groups = groups;
            this.loader = false;
        });
    }

    changeOrder() {
        this.dbGroups.changeOrderItems(
            this.moduleId,
            this.typeOrder,
            (data) => {
                if (data == true) {
                    this.loadGroups();
                }
            }
        );
    }

    createGroup() {
        this.nameError = false;
        this.idError = false;
        this.idRequiredError = false;
        this.loaderCreate = true;

        if (
            this.groupName != "" &&
            this.groupName != null &&
            this.groupName != undefined
        ) {
            let groupIdentifier: string = this.newIdentifierGroup(
                this.groupName
            );
            const group = new Group(
                groupIdentifier,
                this.moduleId,
                this.eventId,
                this.groupName,
                this.nGroupBgColor,
                this.nGroupTxtColor,
                false
            );

            this.dbGroups.newGroup(this.moduleId, this.eventId, group)
                .then(() => {
                    this.clearForm();
                    $("#newGroup").modal("toggle");
                    this.successSwal.fire();
                    this.loaderCreate = false;
                }).catch(err => {
                    console.error(err);
                    this.errorSwal.fire();
                    this.loaderCreate = false;
                })
        } else {
            this.nameError = true;
            this.loaderCreate = false;
        }
    }

    /**
    creates an identifier for the group.
    @param name  group's name
  */

    newIdentifierGroup(name: string): string {
        let flag = false;
        let aux = name;
        let cont = 1;

        while (!flag) {
            const index = this.groups
                .map(function (e) {
                    return e.identifier;
                })
                .indexOf(aux);

            if (index === -1) {
                flag = true;
            } else {
                aux = `${name}${cont}`;
            }

            cont++;
        }

        return aux;
    }

    getEditGroup(group: Group) {
        this.groupEdit = group;
        this.nGroupBgColor = group.bgColor;
        this.nGroupTxtColor = group.txtColor;
        this.groupName = group.name;
        this.groupEditId = group.uid;
        this.groupEditIdentifier = group.identifier;
    }

    editGroup() {
        this.nameError = false;
        this.idError = false;
        this.idRequiredError = false;

        this.groupEdit.bgColor = this.nGroupBgColor;
        this.groupEdit.txtColor = this.nGroupTxtColor;
        this.groupEdit.name = this.groupName;
        this.groupEdit.queryName = this.groupName.toUpperCase();
        this.groupEdit.uid = this.groupEditId;
        this.groupEdit.identifier = this.groupEditIdentifier;

        if (
            this.groupName != "" &&
            this.groupName != null &&
            this.groupName != undefined
        ) {
            this.loaderEdit = true;

            this.dbGroups.editGroup(
                this.moduleId,
                this.eventId,
                this.groupEdit,
                (status) => {
                    if (status == true) {
                        this.clearForm();
                        $("#editGroup").modal("toggle");
                        this.loaderEdit = false;
                        this.successSwal.fire();
                    } else {
                        this.loaderEdit = false;
                        this.errorSwal.fire();
                    }
                }
            );
        } else {
            this.idErrorEdit = true;
        }
    }

    getDeleteGroup(uid: string) {
        this.deleteGroupId = uid;
    }

    deleteGroup() {
        this.loader = true;
        this.dbGroups.deleteGroup(
            this.moduleId,
            this.deleteGroupId,
            (status) => {
                if (status == true) {
                    this.successSwal.fire();
                    this.loader = false;
                } else {
                    this.errorSwal.fire();
                    this.loader = false;
                }
            }
        );
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.groups.length; i++) {
                if (!this.groups[i].multiLanguage) {
                    this.groupsSelected[this.groups[i]["uid"]] = true;
                }
            }
        } else {
            for (let i = 0; i < this.groups.length; i++) {
                this.groupsSelected[this.groups[i]["uid"]] = false;
            }
        }
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];
        // let listRemoveIndexes = [];
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groupsSelected[this.groups[i].uid] == true) {
                listRemove.push(this.groups[i].uid);
                // listRemoveIndexes.push(i);
            }
        }

        this.dbGroups.removeGroups(this.moduleId, listRemove, (data) => {
            if (data) {
                //remove all selected box
                for (let i = 0; i < this.groupsSelected.length; i++) {
                    this.groupsSelected[this.groups[i].uid] = false;
                }

                // this.loader = false;
                this.loadGroups();
                this.selectedAllInput = false;
                this.successSwal.fire();
            } else {
                this.loader = false;
                this.errorSwal.fire();
            }
        });
    }

    clearForm() {
        this.groupName = null;
        this.nGroupBgColor = "transparent";
        this.nGroupTxtColor = "#000000";
    }

    /********************************* EXPORT ALL GROUPS ************************/
    exportAllGroups() {
        this.dataExportGroups = [];
        this.dataExportGroups = [
            ["Identifier*", "Name*", "Text color", "Background color"],
        ];

        let cont = 0;
        if (this.groups.length > 0) {
            for (let group of this.groups) {
                if (!group.multiLanguage) {
                    let row: any;
                    row = this.prepareGroupsExport(group);
                    this.dataExportGroups.push(row);
                }

                if (cont == this.groups.length - 1) {
                    const wscols: XLSX.ColInfo[] = [
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                    ];

                    /* At 96 PPI, 1 pt = 1 px */
                    const wsrows: XLSX.RowInfo[] = [
                        { hpx: 25 }, // "pixels"
                    ];

                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportGroups
                    );

                    /* TEST: column props */
                    ws["!cols"] = wscols;

                    /* TEST: row props */
                    ws["!rows"] = wsrows;

                    /* generate workbook and add the worksheet */
                    const wb: XLSX.WorkBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                    /* save to file */
                    const wbout: string = XLSX.write(wb, {
                        bookType: "xlsx",
                        type: "binary",
                    });
                    saveAs(new Blob([this.s2ab(wbout)]), "groups.xlsx");
                    this.data = null;
                    setTimeout(() => {
                        $("#exportLoadingGroup").modal("toggle");
                    }, 1000);
                } else {
                    // setTimeout(() => {
                    //   $('#exportLoadingGroup').modal('toggle');
                    // }, 1000);
                    // this.errorSwal.fire();
                }
                cont++;
            }
        } else {
            // setTimeout(() => {
            //   $('#exportLoadingGroup').modal('toggle');
            // }, 1000);
            // this.notExport.fire();
            const wscols: XLSX.ColInfo[] = [
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
            ];

            /* At 96 PPI, 1 pt = 1 px */
            const wsrows: XLSX.RowInfo[] = [
                { hpx: 25 }, // "pixels"
            ];

            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportGroups
            );

            /* TEST: column props */
            ws["!cols"] = wscols;

            /* TEST: row props */
            ws["!rows"] = wsrows;

            /* generate workbook and add the worksheet */
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            /* save to file */
            const wbout: string = XLSX.write(wb, {
                bookType: "xlsx",
                type: "binary",
            });
            saveAs(new Blob([this.s2ab(wbout)]), "groups.xlsx");
            this.data = null;
            setTimeout(() => {
                $("#exportLoadingGroup").modal("toggle");
            }, 1000);
        }
    }

    // PREPARA UM REGISTRO DE GRUPOS PARA EXPORTAÇÃO
    prepareGroupsExport(group) {
        const row = [];

        row.push(group.identifier);
        row.push(group.name);
        row.push(group.txtColor);
        row.push(group.bgColor);

        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    exportTemplate() {
        this.dataExportGroups = [];
        this.dataExportGroups = [
            ["Identifier*", "Name*", "Text color", "Background color"],
        ];

        const wscols: XLSX.ColInfo[] = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
        ];

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportGroups
        );

        /* TEST: column props */
        ws["!cols"] = wscols;

        /* TEST: row props */
        ws["!rows"] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "template_groups.xlsx");
        this.data = null;
    }

    /********************************* IMPORT GROUPS ************************/

    /* GET EXCEL AND CONVERT TO JSON DATA */
    onFileChange(evt: any) {
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error("Cannot use multiple files");
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
        };
        reader.readAsBinaryString(target.files[0]);
    }

    checkImport() {
        this.loaderImport = true;
        this.messageErrors = [];
        this.importError = false;
        this.checkImportError = false;
        this.newGroups = [];
        let validRows: number = 0;

        if (this.data.length > 0) {
            let row = 1;
            for (const element of this.data) {
                // IGNORE EMPTY ROWS
                if (this.ignoreRowEmpty(element)) {
                    validRows++;

                    if (row > 1) {
                        const newGroup = new Group(
                            null,
                            this.moduleId,
                            this.eventId,
                            "",
                            null,
                            null,
                            false
                        );

                        // VALIDATE IDENTIFIERS
                        if (typeof element[this.identifier] !== "undefined") {
                            if (
                                element[this.identifier] === NameGroup.DeDE ||
                                element[this.identifier] === NameGroup.EsES ||
                                element[this.identifier] === NameGroup.EnUS ||
                                element[this.identifier] === NameGroup.FrFR ||
                                element[this.identifier] === NameGroup.PtBR
                            ) {
                                const column = this.checkLetterCols(
                                    this.identifier
                                );
                                this.messageErrors.push(
                                    column +
                                        row +
                                        ": " +
                                        " " +
                                        this.translateService.instant(
                                            "global.invalid_identifier"
                                        )
                                );
                            }

                            newGroup.identifier = element[
                                this.identifier
                            ].toString();

                            this.checkNumberRowIds(newGroup.identifier, row);
                        } else {
                            const column = this.checkLetterCols(
                                this.identifier
                            );
                            this.messageErrors.push(
                                column +
                                    row +
                                    ": " +
                                    " " +
                                    this.translateService.instant(
                                        "global.required_id"
                                    )
                            );
                        }

                        // VALIDATE NAME
                        if (typeof element[this.name] !== "undefined") {
                            newGroup.name = element[this.name].toString();
                            newGroup.queryName = newGroup.name.toLocaleUpperCase();
                        } else {
                            const column = this.checkLetterCols(this.name);

                            this.messageErrors.push(
                                column +
                                    row +
                                    ": " +
                                    " " +
                                    this.translateService.instant(
                                        "global.alert_name"
                                    )
                            );
                        }

                        // VALIDATE HEXADECIMAL TEXT COLOR
                        if (typeof element[this.txtColor] !== "undefined") {
                            if (element[this.txtColor] == "transparent") {
                                newGroup.txtColor = element[this.txtColor];
                            } else {
                                if (
                                    this.regex.verifyHex(element[this.txtColor])
                                ) {
                                    newGroup.txtColor = element[this.txtColor];
                                } else {
                                    const column = this.checkLetterCols(
                                        this.txtColor
                                    );
                                    this.messageErrors.push(
                                        column +
                                            row +
                                            ":" +
                                            " " +
                                            this.translateService.instant(
                                                "comp.groups.invalid_hexadecimal"
                                            )
                                    );
                                }
                            }
                        } else {
                            newGroup.txtColor = "#000000";
                        }

                        // VALIDATE HEXADECIMAL BACKGROUND COLOR
                        if (typeof element[this.bgColor] !== "undefined") {
                            if (element[this.bgColor] == "transparent") {
                                newGroup.bgColor = element[this.bgColor];
                            } else {
                                if (
                                    this.regex.verifyHex(element[this.bgColor])
                                ) {
                                    newGroup.bgColor = element[this.bgColor];
                                } else {
                                    const column = this.checkLetterCols(
                                        this.bgColor
                                    );
                                    this.messageErrors.push(
                                        column +
                                            row +
                                            ":" +
                                            " " +
                                            this.translateService.instant(
                                                "comp.groups.invalid_hexadecimal"
                                            )
                                    );
                                }
                            }
                        } else {
                            newGroup.bgColor = "transparent";
                        }

                        this.newGroups.push(newGroup);

                        if (this.messageErrors.length > 0) {
                            this.loaderImport = false;
                            this.checkImportError = true;
                        }
                        this.clearInputFile(); // limpa o input do arquivo.
                    }
                }

                row++;
            }

            if (
                this.newGroups.length == validRows - 1 &&
                this.checkImportError == false
            ) {
                this.importGroup();
            }
        } else {
            this.loaderImport = false;
            this.messageErrors.push("global.no_file_selected");
        }
    }

    importGroup() {
        this.dbGroups.importGroups(
            this.moduleId,
            this.eventId,
            this.newGroups,
            (status) => {
                if (status["result"]["fail"].length == 0) {
                    if (status["result"]["created"] !== undefined) {
                        this.totalCreated = status["result"]["created"].length;
                    }
                    if (status["result"]["updated"] !== undefined) {
                        this.totalUpdated = status["result"]["updated"].length;
                    }
                    this.importSuccess = true;
                    this.loaderImport = false;
                    // this.successSwal.fire();
                } else {
                    this.failArray = status["result"]["fail"];
                    if (status["result"]["fail"] !== undefined) {
                        this.totalFail = status["result"]["fail"].length;
                    }
                    this.importError = true;
                    this.loaderImport = false;
                }
            }
        );
    }

    // CLEAR INPUT AFTER IMPORT DATA
    clearInputFile() {
        this.inputFile.nativeElement.value = "";
        this.nameFile = null;
    }

    clearMessages() {
        this.importError = false;
        this.checkImportError = false;
        this.messageErrors = [];
        this.importSuccess = false;
        this.data = [];
        this.loaderImport = false;
        this.totalCreated = 0;
        this.totalUpdated = 0;
        this.totalFail = 0;
        this.newGroups = [];
    }

    /** IGNORE EMPTY ROWS */
    ignoreRowEmpty(row: any) {
        if (row.length < 1 || this.checkRowEmpty(row)) {
            return false;
        }
        return true;
    }

    /**VERIFY IF ROW IS EMPTY */
    checkRowEmpty(row: any) {
        for (const cel of row) {
            if (typeof cel !== "undefined" && cel.length > 0) {
                return false;
            }
        }
        return true;
    }

    /* CHECK IF HAVE DOUBLE IDENTIFIERS */
    checkNumberRowIds(identifier: any, rowId: number) {
        let cont = 2;
        for (const group of this.newGroups) {
            if (group.identifier === identifier) {
                const column = this.checkLetterCols(this.identifier);
                this.messageErrors.push(
                    this.translateService.instant("global.equal_id") +
                        " " +
                        column +
                        cont +
                        " " +
                        this.translateService.instant("global.and") +
                        " " +
                        column +
                        rowId
                );
            }
            cont++;
        }
    }

    /* RETURN EXCEL COLUMN LETTER OF PARAMETER */
    checkLetterCols(column: number) {
        let letter = "";
        switch (column) {
            case this.identifier: {
                letter = "A";
                break;
            }
            case this.name: {
                letter = "B";
                break;
            }
            case this.txtColor: {
                letter = "C";
                break;
            }

            case this.bgColor: {
                letter = "D";
                break;
            }
        }
        return letter;
    }

    async downloadImportError() {
        this.dataExportGroups = [];
        this.dataExportGroups = [
            ["Identifier*", "Name*", "Text color*", "Background color*"],
        ];

        // PEGA OS DADOS DE CADA PARTICIPANTE
        let cont = 0;
        for (let i = 0; i < this.failArray.length; i++) {
            let group = this.failArray[i];

            if (!group.multiLanguage) {
                let row: any;
                row = await this.prepareGroupsExport(group);
                await this.dataExportGroups.push(row);
            }

            if (cont == this.failArray.length - 1) {
                const wscols: XLSX.ColInfo[] = [
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                ];

                /* At 96 PPI, 1 pt = 1 px */
                const wsrows: XLSX.RowInfo[] = [
                    { hpx: 25 }, // "pixels"
                ];

                /* generate worksheet */
                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                    this.dataExportGroups
                );

                /* TEST: column props */
                ws["!cols"] = wscols;

                /* TEST: row props */
                ws["!rows"] = wsrows;

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                /* save to file */
                const wbout: string = XLSX.write(wb, {
                    bookType: "xlsx",
                    type: "binary",
                });
                saveAs(new Blob([this.s2ab(wbout)]), "group_errors.xlsx");
                this.data = null;
            }
        }

        cont++;
    }
}
