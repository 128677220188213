import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TypeLogin } from 'src/app/enums/type-login';
import { Container } from 'src/app/models/container';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { ActivatedRoute, Router } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'app-client-edit-container',
  templateUrl: './edit-client-container.component.html',
  styleUrls: ['./edit-client-container.component.scss']
})
export class EditClientContainerComponent implements OnInit {

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;

  containerId: string = null;
  loginType: TypeLogin = 0;
  clients: Array<any> = [];
  container: Container = {
    uid: null,
    clientId: null,
    appName: null,
    loginPage: {
      type: TypeLogin.WITH_EMAIL_CONFIRM,
      eventId: null,
    },
    loginOptions: {
      fbLoginBtn: false,
      gLoginBtn: false,
      registeBtn: false,
      shortcodeBtn: true,
    },
    logo: null,
    logoSize: 'invent-logo',
    login1stBackground: '',
    login2ndBackground: '',
    color: null,
    termsOfUse: '',
    privacyTerms: '',
    valid_btn_bg_color: '',
    valid_btn_color: '',
    login_btn_bg_color: '',
    login_btn_color: '',
    text_color_1st_login: '',
    text_color_2nd_login_desktop: '',
    text_color_2nd_login_mobile: '',
    label_login_color: '',
    color_blue_default: '',
    color_blue_default_contrast: '',
    color_blue_default_shade: '',
    color_blue_default_tint: '',
  };
  displaySelectEvent: boolean = false;
  keyword = 'name';
  eventLoader: boolean = false;
  saveLoader: boolean = false;
  events: Array<Event> = [];
  allowPublicOptions: boolean = false
  blankField: boolean = false;
  nameFile: string = null;
  firstImgBackgroundName: string = null;
  secondImgBackgroundName: string = null;
  displayImage: any = null;
  displayFirstImgBackground: any = null;
  displaySecondImgBackground: any = null;
  selectedEvent: Event = null;
  noEvents: boolean = false;
  blockBtnSave: boolean = false;

  constructor(
    private dbAdmin: DbAdminUserProvider,
    private dbEvents: DbEventsProvider,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.containerId = this.route.snapshot.params['containerId'];
  }

  ngOnInit() {
    this.dbAdmin.getContainer(this.containerId, (container: Container) => {
      this.container = container;
    });
  }

  // get image from input file
  getPicture($ev) {
    this.nameFile = $ev.target.value;
    this.displayImage = $ev.srcElement.files[0]
  }

  // get background images from input file
  getBackgrounds($ev, order) {
    if(order === '1st') {
      this.firstImgBackgroundName = $ev.target.value;
      this.displayFirstImgBackground = $ev.srcElement.files[0]
    }
    else if(order === '2nd'){
      this.secondImgBackgroundName = $ev.target.value;
      this.displaySecondImgBackground = $ev.srcElement.files[0]
    }
  }

  changeLoginType(ev) {
    this.loginType = ev.target.value;
  }

  loginTypeEventChange() {
    if (this.container.loginPage.type == TypeLogin.PUBLIC_EVENT_HOME) {
      this.eventLoader = true;
      this.getEvents();
    } else {
      this.events = [];
      this.eventLoader = false;
      this.blockBtnSave = false;
      this.displaySelectEvent = false;
      this.container.loginPage.eventId = null;
      this.allowPublicOptions = false;
      this.container.loginOptions.fbLoginBtn = false;
      this.container.loginOptions.gLoginBtn = false;
    }
  }

  selectEventClient() {
    this.dbEvents.getEvent(this.container.loginPage.eventId, (event: Event) => {
      this.selectedEvent = event;
      if (event.visibility) {
        this.allowPublicOptions = true;
      }
    });
  }

  getEvents() {
    this.dbEvents.getEventsByClient(this.container.clientId, (events: Array<Event>) => {
      if (events.length >= 1) {
        this.events = events;
        this.displaySelectEvent = true;
        this.eventLoader = false;
      } else {
        this.noEvents = true;
        this.blockBtnSave = true;
        this.displaySelectEvent = false;
        this.eventLoader = false;
      }
    });
  }

  updateContainer() {
    this.blankField = false;
    if (this.container.clientId !== null
      && this.container.appName !== null
      && this.container.loginPage.type !== null
      && (this.container.logo !== null || this.displayImage !== null)
      && (this.container.login1stBackground !== null || this.displayFirstImgBackground !== null)
      && (this.container.login2ndBackground !== null || this.displaySecondImgBackground !== null)
      && this.container.logoSize !== null
    ) {
      this.saveLoader = true;
      this.dbAdmin.updateContainer(this.container, this.displayImage,this.displayFirstImgBackground,this.displaySecondImgBackground, (status) => {
        if (status) {
          this.successSwal.fire();
          this.saveLoader = false;
        } else {
          this.errorSwal.fire();
          this.saveLoader = false;
        }
      })
    } else {
      this.blankField = true;
    }
  }

  redirectList() {
    this.router.navigate([`/dash/containers/${this.container.clientId}`]);
  }
}
