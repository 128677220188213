import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../storage/storage.service';
import { Notification } from '../../models/notifications';
import { Email } from 'src/app/models/email';

@Injectable({
    providedIn: 'root'
})
export class DbMailingProvider {
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, body: {} };
    }

    getEmails(eventId: string): Promise<any[]>{
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            db.collection('events').doc(eventId).collection('emails').orderBy('createdAt','desc').get().then((snapshot) => {
                resolve([...snapshot.docs].map(x => x.data()));
            })
        })
    }

    deleteEmail(eventId: string, emailId: string): Promise<boolean>{
        let db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('emails').doc(emailId)
        return new Promise((resolve, reject) => {
            ref.delete().then(() => resolve(true)).catch(e => resolve(false))
        })
    }

    async createEmail(eventId: string, email: Email, emailBody: string, design: string): Promise<any>{

        let db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('emails').doc()
        email.uid = ref.id;
        email.createdAt = Date.now() / 1000 | 0;
        email = Object.assign({design}, email)
        try {
            await ref.set(email)
            const maxBytes = 1_048_487
            const num = Math.ceil(emailBody.length / maxBytes)
            const batch = db.batch();
            for(let i = 0 ; i < num ; i++){
                const refEmailBody = db.collection('events').doc(eventId).collection('emails').doc(email.uid).collection('bodies').doc()
                const body = {
                    uid: refEmailBody.id,
                    text: emailBody.substring(i * maxBytes,maxBytes)
                }
                batch.set(refEmailBody, body);
            }
            await batch.commit()
            return new Promise( (resolve, reject) => {
                resolve({value: true, id: email.uid });
            })
            
        } catch (error) {
            console.log(error)
            return new Promise( (resolve, reject) => {
                resolve({value: false});
            })
        }
    }

    async deleteMultipleEmails(eventId: string, emailIds: string[]){
        let db = this.aFirestore.firestore;
        const batch = db.batch();
        for(let i = 0 ; i < emailIds.length ;i++){
            const ref = db.collection('events').doc(eventId).collection('emails').doc(emailIds[i])
            batch.delete(ref)
        }
        try {
            await batch.commit() 
            return Promise.resolve(true)  
        } catch (error) {
            return Promise.resolve(false)   
        }
    }

    async saveEmail(eventId: string, email: Email, emailBody: string, type?: string): Promise<boolean>{
        let db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('emails').doc(email.uid)
        email.deliveredAt = type === 'send' ? Date.now() / 1000 | 0 : 0
        email = Object.assign({}, email)
        try {
            const maxBytes = 1_048_487
            const num = Math.ceil(emailBody.length / maxBytes)
            const batch = db.batch();

            const res = await db.collection('events').doc(eventId).collection('emails').doc(email.uid).collection('bodies').get();
            const bodies = [...res.docs].map(x => x.data())
            console.log('bodies',bodies)
            for(let i = 0 ; i < bodies.length ;i++){
                const ref = db.collection('events').doc(eventId).collection('emails').doc(email.uid).collection('bodies').doc(bodies[i].uid)
                batch.delete(ref)
            }
            for(let i = 0 ; i < num ; i++){
                const refEmailBody = db.collection('events').doc(eventId).collection('emails').doc(email.uid).collection('bodies').doc()
                const body = {
                    uid: refEmailBody.id,
                    text: emailBody.substring(i * maxBytes,maxBytes)
                }
                batch.set(refEmailBody, body);
            }
            await batch.commit()
            await ref.update(email)

            return new Promise((resolve, reject) => {
                resolve(true)
            })
        } catch (error) {
            console.log(error)
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        }
    }
    
    async getEmail(eventId: string,emailId: string){
        let db = this.aFirestore.firestore;
        const res = await db.collection('events').doc(eventId).collection('emails').doc(emailId).get()
        const obj  = res.data()
        const results = await db.collection('events').doc(eventId).collection('emails').doc(emailId).collection('bodies').get();
        return Object.assign(obj,{
            emailBody: [...results.docs].map(x => x.data().text).join('')
        });
    }

    async editEmail(eventId: string, email: Email, emailBody: string){
        const db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('emails').doc(email.uid)
        email.deliveredAt = Date.now() / 1000 | 0
        email = Object.assign({}, email)
        delete email.uid;
        try {
            await ref.update(email)
            return Promise.resolve(true)
        } catch (error) {
            console.log(error)
            return Promise.resolve(false)
        }
    }

    async duplicateEmail(eventId: string, emailId: string, emailTitle: string){
        const db = this.aFirestore.firestore;
        const res = await db.collection('events').doc(eventId).collection('emails').doc(emailId).get()
        let emailObj  = res.data()
        emailObj.title = emailTitle
        emailObj.createdAt = Date.now() / 1000 | 0 
        emailObj.deliveredAt = 0
        emailObj.status = 0
        const ref = db.collection('events').doc(eventId).collection('emails').doc()
        emailObj.uid = ref.id;

        try {
            await ref.set(emailObj)
            return Promise.resolve({value: true, id: emailObj.uid})
        } catch (error) {
            console.log(error)
            return Promise.resolve({value: false, id: null})
        }
    }
}