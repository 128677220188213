import {Question} from './question';
import { NameModule } from './name-module';

export class Survey{
    title: NameModule;
    type: string;
    // max_responses: number;
    change_answer: boolean;
    visibility: boolean;
    view_answered: boolean;
    icon: string;
    iconFamily: string;
    uid: string;
    question: Array<Question> = [];
    module_id: String; 
    references: any;
    order: number;
    weight: boolean;
    marker: boolean;
    forceComplete: boolean;
    totalQuestions: number;
    groups: Array<string> = [];
    minPoints: number;
}
