import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { DbAnalyticsProvider } from "src/app/providers/database/db-analytics";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { FormatedEventLanguageService } from "src/app/providers/formated-event-language/formated-event-language.service";
import { GlobalService } from "src/app/providers/global/global.service";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/reducers";
import { getSchedulesAnalytics } from "src/app/shared/selectors/analytics.selectors";
@Component({
    selector: "app-schedules-analytics",
    templateUrl: "./schedules-analytics.component.html",
    styleUrls: ["./schedules-analytics.component.scss"],
})
export class SchedulesAnalyticsComponent implements OnInit {
    loader: boolean = true;

    event: any;
    eventId: string;
    userLanguage: string;

    totalSchedulesModules: number = 0;
    totalSessionsSchedules: number = 0;

    schedules: any[] = [];
    scheduleSelectedId: any;
    sessionsForSelectedSchedule: any[] = [];

    currentPageSessions: number = 1;

    numberOfItemsPerPage: number = 10;

    sortType: string = "id";

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage();
            this.getEvent();
        });
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        });
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics
                .getSchedulesModulesAnalytics(this.eventId)
                .pipe(take(1))
                .subscribe((schedulesModules) => {
                    this.schedules = schedulesModules;
                    console.log("Scheules: ", this.schedules);
                    this.store
                        .select(getSchedulesAnalytics)
                        .subscribe((scheduleAnalytics) => {
                            this.totalSchedulesModules =
                                scheduleAnalytics &&
                                scheduleAnalytics.totalSchedulesModules
                                    ? scheduleAnalytics.totalSchedulesModules
                                    : 0;
                            this.totalSessionsSchedules =
                                scheduleAnalytics &&
                                scheduleAnalytics.totalSessionsSchedules
                                    ? scheduleAnalytics.totalSessionsSchedules
                                    : 0;
                        });

                    this.loader = false;
                });
        });
    }

    /**
     * Export analytics of schedules
     */
    exportAll() {
        // this.loader = true;
        // this.dbAnalytics.exportSchedulesAnalytics(this.eventId).subscribe(() => {
        //     this.loader = false;
        // }, (error) => {
        //     this.loader = false;
        // })
    }

    /**
     * Export schedule analytics
     */
    exportSchedule() {
        this.loader = true;
        this.dbAnalytics
            .exportScheduleAnalytics(
                this.eventId,
                this.scheduleSelectedId,
                this.userLanguage
            )
            .subscribe(
                (result) => {
                    this.loader = false;
                },
                (error) => {
                    console.log("Error: ", error);
                    this.loader = false;
                }
            );
    }

    /**
     * Change selected schedule
     */
    selectedScheduleChange() {
        this.loader = true;
        this.dbAnalytics
            .getSessionsForSelectedSchedule(
                this.eventId,
                this.scheduleSelectedId
            )
            .subscribe(
                (scheduleSessions) => {
                    this.sortType = "desc-startTime";
                    this.sessionsForSelectedSchedule = scheduleSessions;

                    this.loader = false;
                },
                (error) => {
                    this.loader = false;
                }
            );
    }

    /**
     * Convert date to human read
     * @param date
     */
    convertDate(date: number) {
        return moment.unix(date).format("DD/MM/YYYY - HH:mm");
    }

    /**
     * Get name for selected schedule
     */
    getSelectedScheduleName() {
        let name = this.schedules.filter(
            (schedule) => schedule.uid == this.scheduleSelectedId
        )[0].name[this.userLanguage];
        return name ? name : "";
    }

    /**
     * Sorting sessions
     * @param type
     */
    sortingBy(type: string) {
        if (
            this.sessionsForSelectedSchedule &&
            this.sessionsForSelectedSchedule.length > 0
        ) {
            switch (type) {
                case "id":
                    if (this.sortType == "desc-id") {
                        this.sortType = "asc-id";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.uid < b.uid ? 1 : a.uid > b.uid ? -1 : 0
                        );
                    } else {
                        this.sortType = "desc-id";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.uid > b.uid ? 1 : a.uid < b.uid ? -1 : 0
                        );
                    }
                    break;
                case "name":
                    if (this.sortType == "desc-name") {
                        this.sortType = "asc-name";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.name[this.userLanguage] <
                            b.name[this.userLanguage]
                                ? 1
                                : a.name[this.userLanguage] >
                                  b.name[this.userLanguage]
                                ? -1
                                : 0
                        );
                    } else {
                        this.sortType = "desc-name";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.name[this.userLanguage] >
                            b.name[this.userLanguage]
                                ? 1
                                : a.name[this.userLanguage] <
                                  b.name[this.userLanguage]
                                ? -1
                                : 0
                        );
                    }
                    break;
                case "startDate":
                    if (this.sortType == "desc-startDate") {
                        this.sortType = "asc-startDate";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.startTime < b.startTime
                                ? 1
                                : a.startTime > b.startTime
                                ? -1
                                : 0
                        );
                    } else {
                        this.sortType = "desc-startDate";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.startTime > b.startTime
                                ? 1
                                : a.startTime < b.startTime
                                ? -1
                                : 0
                        );
                    }
                    break;
                case "endDate":
                    if (this.sortType == "desc-endDate") {
                        this.sortType = "asc-endDate";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.endTime < b.endTime
                                ? 1
                                : a.endTime > b.endTime
                                ? -1
                                : 0
                        );
                    } else {
                        this.sortType = "desc-endDate";
                        this.sessionsForSelectedSchedule.sort((a, b) =>
                            a.endTime > b.endTime
                                ? 1
                                : a.endTime < b.endTime
                                ? -1
                                : 0
                        );
                    }
                    break;
            }
        }
    }
}
