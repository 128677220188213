import { Module } from '../modules/module';
import { TypeModule } from 'src/app/enums/type-module';
import { NameModule } from '../name-module';

export class ModuleExternalLink extends Module {
    link: string
    typeVision: number
    open_link_behavior: string;
    access_groups: any; //uid of the group that is allowed access to the module
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, link: string, open_link_behavior: string, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.EXTERNAL_LINK, eventId, order, true, true, true)
        this.link = link
        this.open_link_behavior = open_link_behavior;
        this.typeVision = typeVision
        this.access_groups = {}
        this.link = link;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}
