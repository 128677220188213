import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import * as moment from 'moment';

@Component({
    selector: 'app-tracking-analytics',
    templateUrl: './tracking-analytics.component.html',
    styleUrls: ['./tracking-analytics.component.scss']
})
export class TrackingAnalyticsComponent implements OnInit {
    loader: boolean = true;

    search: string = "";

    event: any;
    eventId: string;
    userLanguage: string;
    eventLanguage: string;

    users: any[] = [];
    emailsFound: string[] = [];

    selectedUser: any;
    selectedUserRoadTrack: any[] = [];

    currentPageRoadUser: number = 1;

    numberOfItemsPerPage: number = 10;

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.eventLanguage = this.formatedLanguage.convertLang(event.language);
            this.formatedLanguage.convertLangFormat(event.language);

            this.dbAnalytics.getUsersForTrackingAnalytics(this.eventId).subscribe((users) => {
                this.users = users;
                this.loader = false;
            })
        });
    }

    /**
     * Get track name
     * @param track 
     */
    getTrackName(track) {
        if (track.type == 12) {
            return ((track.nameOfTrack) ? track.nameOfTrack : 'None');
        } else {
            return ((track.nameOfTrack && this.eventLanguage && track.nameOfTrack[this.eventLanguage]) ? track.nameOfTrack[this.eventLanguage] : 'None');
        }
    }

    /**
     * Export analytics of tracking
     */
    exportAll() {
        this.loader = true;
        this.dbAnalytics.exportTrackingAnalytics(this.eventId, this.userLanguage).subscribe(() => {
            this.loader = false;
        }, (error) => {
            console.log("Error: ", error);
            this.loader = false;
        })
    }

    /**
     * Filter emails on search bar
     */
    filterEmails() {
        this.emailsFound = [];
        if (!this.search || this.search == "") {
            return;
        }

        this.users.filter((user) => user.email.startsWith(this.search)).forEach((user) => {
            if (!this.emailsFound.includes(user.email)) {
                this.emailsFound.push(user.email);
            }
        })
    }

    /**
     * Get trackings for user with specific email
     * @param email 
     */
    getUserRoadTracking(email: string) {
        this.loader = true;
        this.selectedUser = this.users.find((user) => user.email == email);
        this.dbAnalytics.getUserRoadTrackingWithEmail(this.eventId, email).subscribe((userRoadTracking) => {
            this.selectedUserRoadTrack = userRoadTracking;

            this.loader = false;

            // Reset search
            this.emailsFound = [];
            this.search = "";

        }, (error) => {
            this.loader = false;
        })
    }

    /**
     * Convert date to human read
     * @param date 
     */
    convertDate(date: string) {
        return (moment(date).format('DD/MM/YYYY - HH:mm'));
    }

    /**
     * Expor tracking road user
     */
    exportTrackingRoadUser() {
        this.loader = true;
        this.dbAnalytics.exportTrackingRoadUserAnalytics(this.eventId, this.selectedUser.email, this.userLanguage).subscribe(() => {
            this.loader = false;
        }, (error) => {
            this.loader = false;
        })
    }
}
