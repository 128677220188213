import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { map } from 'rxjs/operators';
// import { Attendee } from 'src/app/models/attendees';
import { PathApi } from 'src/app/paths/path-api';
// import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
// import * as firebase from "firebase/app";
import { Group } from "../../models/group";
import { Checkin } from "../../models/checkin";
import { TypeVisionCheckin } from '../../enums/type-vision-checkin';
import { DbGroupsProvider } from '../database/db-groups';
import { DbCheckinProvider } from '../database/db-checkin';
import { SSL_OP_NETSCAPE_REUSE_CIPHER_CHANGE_BUG } from 'constants';

@Injectable({
    providedIn: 'root'
})
export class SymplaIntegrationService {
    customFields: any[] = null;
    attendeesData: any[] = null;

    constructor(
        private httpClient: HttpClient,
        private SGroup: DbGroupsProvider,
        private SCheckin: DbCheckinProvider,
        private fstore: AngularFirestore
    ) {}

    private async getSymplaGroup(eventId: string) {
        const gmod = await this.SGroup.getGroupEvent(eventId);
        if (!gmod) return Promise.reject();

        let rgroup;
        
        const fetchGroup = await this.fstore.firestore.doc(
            `events/${eventId}/modules/${gmod.uid}/groups/sympla`
        ).get();

        if (!fetchGroup) {
            return Promise.reject();
        } else if (fetchGroup.exists) {
            rgroup = fetchGroup.data();
        } else {
            const group = new Group(
                "sympla",
                gmod.uid,
                eventId,
                "sympla",
                "transparent",
                "#000000",
                false
            );
            group.uid = "sympla_group";
    
            rgroup = await this.SGroup.newGroup(gmod.uid, eventId, group);
            if (!rgroup) return Promise.reject();
        }
        return Promise.resolve(rgroup);
    }

    private async getSymplaCheckin(eventId: string, rgroup: any) {
        const checkinMod = await this.SCheckin.getModuleSnapshot(eventId);
        if (!checkinMod) return Promise.reject();

        let rcheckin;

        const fetchCehckin = await this.fstore.firestore.doc(
            `modules/${checkinMod.id}/checkins/sympla_checkin`
        ).get();
        if (!fetchCehckin) {
            return Promise.reject();
        } else if (fetchCehckin.exists) {
            rcheckin = fetchCehckin.data();
        } else {
            const checkin = new Checkin(
                `Sympla Integration Checkin`,
                0,
                TypeVisionCheckin.GROUP_VISION,
                TypeVisionCheckin.GLOBAL_VISION
            );
            checkin.groups[rgroup.uid] = rgroup;
            checkin.eventId = eventId;
            checkin.moduleId = checkinMod.id;
            checkin.uid = "sympla_checkin";
    
            rcheckin = (await this.SCheckin
                .newCheckin(checkinMod.id, eventId, checkin));
            
            if (!rcheckin) return Promise.reject();
        }
        return Promise.resolve(rcheckin);
    }

    public getEvents(apiToken: string) {
        return this.httpClient.get(
            "https://api.sympla.com.br/public/v4/events",
            { headers: { "s_token": apiToken } }
        ).toPromise();
    }

    private async getAttendees(apiToken: string, symplaEventId: number) {
        const request = (page: number = 1) => {
            return this.httpClient.get(
                `https://api.sympla.com.br/public/v3/events/${symplaEventId}/participants`,
                { 
                    headers: { "s_token": apiToken },
                    params: {
                        'fields': "first_name,last_name,email,event_id,id", 
                        'page_size': "100", // default 100
                        "page": `${page}`
                    }
                }
            ).toPromise();
        }

        const firstPage = await request();
        if (!firstPage) {
            return Promise.reject();
        } else if (firstPage['pagination']['total_page'] > 1) {
            const requests: any[] = [];
            for (let i = 1; i <= firstPage['pagination']['total_page']; i++) {
                requests.push(request(i));
            }
            const promise = new Promise<any>(async (resolve, reject) => {
                const req = await Promise.all(requests);
                if (!req) {
                    reject();
                } else {
                    resolve([firstPage, ...req]);
                }
            });
            return promise;
        }
        return Promise.resolve([firstPage]);


        // const params = new HttpParams();
        // params.set('page_size', "1");
        // // params.set('page', `${page}`);
        // this.httpClient.get(
        //     `https://api.sympla.com.br/public/v3/events/${symplaEventId}/participants`,
        //     { 
        //         headers: { "s_token": apiToken },
        //         params: params
        //     }
        // ).pipe(map((result) => {
        //     // console.log(`result['data']: ${JSON.stringify(result['data'])}`)
        //     // const attendees: Array<any> = result['data'];
        //     // return attendees.map((attendee) => {
        //     //     const tmp = new Attendee(
        //     //         `${attendee.first_name} ${attendee.last_name}`,
        //     //         attendee.email,
        //     //         5,
        //     //         eventId
        //     //     );
        //     //     tmp.$uid = `SYMPLAeid${attendee.event_id}uid${attendee.id}`;
        //     //     return tmp;
        //     // })
        // })).toPromise();
    }

    /**
     * @returns a json object like with the fallowing properties: (status: "200"
     * or "400" or "500"), (message: string), (result: array of created attendee IDs 
     * (on success) or array of type Attendee with the those not created (on 
     * failure with code 500))
     */
    private createAttendees(eventId: string, attendeePage: any[], attendeeModuleId: string, eventLanguage: string) {
        const headers = new HttpHeaders();
        headers.append("Accept", 'application/json');
        headers.append("Content-Type", 'application/json');
        const requestOptions = { headers: headers };

        const body = {
            eventId: eventId,
            attendeePage: attendeePage,
            attendeeModuleId: attendeeModuleId,
            language: eventLanguage
        }
        return this.httpClient.post(PathApi.baseUrl + "dbAttendeesSymplaImport", body, requestOptions).toPromise()
    }

    public async importSymplaAttendees(
        apiToken: string, 
        symplaEventId: number,
        eventId: string,
        attendeeModuleId: string,
        eventLanguage: string
    ) {
        const result = await this.getAttendees(apiToken, symplaEventId);
        if (!result) return Promise.reject();

        const attendeePages = result.map(r => r["data"]);

        return Promise.all(attendeePages.map((attendeePage) => {
            return this.createAttendees(
                eventId,
                attendeePage,
                attendeeModuleId,
                eventLanguage
            )
        }));
    }

    private saveIntegrationData(
        eventId: string, 
        token: string, 
        symplaEventId: number,
        symplaEventName: string,
        attendeeModuleId: string
    ) {
        return new Promise<boolean>((resolve, reject) => {
            this.fstore.firestore.doc(`events/${eventId}`).update({ 
                integrations: { 
                    sympla: { 
                        eventId: symplaEventId,
                        eventName: symplaEventName,
                        token: token,
                        attendeeModuleId: attendeeModuleId
                    }
                }
            }).then(() => resolve(true)).catch(err => reject(err))
        });   
    }

    public rmIntegrationData(eventId: string) {
        return new Promise<boolean>((resolve, reject) => {
            const ref = this.fstore.firestore.doc(`events/${eventId}`);
            ref.get().then((snap) => {
                const data = snap.data();
                if (data && data.integrations) {
                    delete data.integrations.sympla;
                    ref.update({ integrations: data.integrations })
                        .then(() => resolve(true))
                        .catch(err => reject(err))
                } else {
                    reject(`No value to be remnoved`);
                }
            })
        });
    }

    public async crIntegration(
        eventId: string,
        symplaEventId: number,
        symplaEventName: string,
        apiToken: string,
        attendeeModuleId: string
    ) {
        const rgroup = await this.getSymplaGroup(eventId);
        if (!rgroup) return Promise.reject();

        const rcheckin = await this.getSymplaCheckin(eventId,rgroup);
        if (!rcheckin) return Promise.reject();
        
        return this.saveIntegrationData(
            eventId,
            apiToken,
            symplaEventId,
            symplaEventName,
            attendeeModuleId
        );
    }

    checkin(eventId: string, ticket: string) {
        const headers = new HttpHeaders();
        headers.append("Accept", 'application/json');
        headers.append("Content-Type", 'application/json');
        const requestOptions = { headers: headers };

        const body = {
            eventId: eventId,
            ticket: ticket,
        }
        console.log(JSON.stringify(body))
        return this.httpClient.post(PathApi.baseUrl + "dbCheckinSymplaCheckin", body, requestOptions).toPromise();
    }

    // public async importAttendees(
    //     page: number, 
    //     pageLength: number, 
    //     attendeesData: JSON[], 
    //     attendeeModuleId: string,
    //     eventId: string,
    //     language: string
    // ) {
    //     if (!this.customFields || ) {
    //         const customFields = await this.firestore
    //             .collection(`modules/${attendeeModuleId}/customFields`)
    //             .get()
    //             .toPromise();

    //         if (!customFields) { return Promise.reject(); }
    //         this.customFields = customFields[0].docs.map(custom => custom.data());
    //     }

    //     if (!this.attendeesData) {
    //         const attendees = this.firestore
    //             .collection(`events/${eventId}/attendees`)
    //             .get()
    //             .toPromise();
    //     }

    //     firebase.default.firestore.FieldValue.arrayUnion()

    // }
}
