import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class FormatedMultiIdiomaService {

    constructor() {

    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR';
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    fillEmptyLanguage(languagesObj, eventLanguage) {
        let mainLanguageStr = null;

        switch (eventLanguage) {
            case 'pt_BR':
                mainLanguageStr = languagesObj['PtBR'];
                break;

            case 'en_US':
                mainLanguageStr = languagesObj['EnUS'];
                break;

            case 'es_ES':
                mainLanguageStr = languagesObj['EsES'];
                break;

            case 'fr_FR':
                mainLanguageStr = languagesObj['FrFR'];
                break;

            case 'de_DE':
                mainLanguageStr = languagesObj['DeDE'];
                break;
        }

        if (languagesObj['PtBR'] == '') {
            languagesObj['PtBR'] = mainLanguageStr;
        }

        if (languagesObj['EnUS'] == '') {
            languagesObj['EnUS'] = mainLanguageStr;
        }

        if (languagesObj['EsES'] == '') {
            languagesObj['EsES'] = mainLanguageStr;
        }

        if (languagesObj['FrFR'] == '') {
            languagesObj['FrFR'] = mainLanguageStr;
        }

        if (languagesObj['DeDE'] == '') {
            languagesObj['DeDE'] = mainLanguageStr;
        }

        return languagesObj;
    }

    mainLanguageIsFilled(languagesObj, eventLanguage) {
        switch (eventLanguage) {
            case 'pt_BR':
                if (languagesObj['PtBR'] !== '') {
                    return true;
                }
                break;

            case 'en_US':
                if (languagesObj['EnUS'] !== '') {
                    return true;
                }
                break;

            case 'es_ES':
                if (languagesObj['EsES'] !== '') {
                    return true;
                }
                break;

            case 'fr_FR':
                if (languagesObj['FrFR'] !== '') {
                    return true;
                }
                break;

            case 'de_DE':
                if (languagesObj['DeDE'] !== '') {
                    return true;
                }
                break;
        }

        return false;
    }

    updateObjectMultiLanguages(oldObj, newObj, eventLanguage) {
        let principalLanguage = this.convertLangFormat(eventLanguage)
        let oldStr = oldObj[principalLanguage];
        let newStr = newObj[principalLanguage];

        if (oldStr != newStr) {
            if (oldStr == newObj['PtBR'] && principalLanguage != 'PtBR') {
                newObj['PtBR'] = newStr;
            }

            if (oldStr == newObj['EnUS'] && principalLanguage != 'EnUS') {
                newObj['EnUS'] = newStr;
            }

            if (oldStr == newObj['EsES'] && principalLanguage != 'EsES') {
                newObj['EsES'] = newStr;
            }

            if (oldStr == newObj['FrFR'] && principalLanguage != 'FrFR') {
                newObj['FrFR'] = newStr;
            }

            if (oldStr == newObj['DeDE'] && principalLanguage != 'DeDE') {
                newObj['DeDE'] = newStr;
            }

            return newObj;
        }

        return newObj;
    }
}