import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { GroupDiscussionsService } from 'src/app/providers/group-discussions/group-discussions.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GroupDiscussion } from 'src/app/models/group-discussion';
import { GlobalService } from 'src/app/providers/global/global.service';
import { map, take } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';

@Component({
    selector: 'app-group-discussions',
    templateUrl: './group-discussions.component.html',
    styleUrls: ['./group-discussions.component.scss']
})
export class GroupDiscussionsComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    loader: boolean = false;
    eventId: string;
    moduleId: string;
    q: string;
    selected: string[];
    currentPage: number;
    orderOptions: any[];
    currentOrderOption: string;
    userLanguage: string;
    filename: string;
    prepareResult: any;
    importResult: any;
    importing: boolean;
    routeParamsSubscription: Subscription;
    module$: Observable<any>;
    event$: Observable<any>;
    groupDiscussions$: Observable<GroupDiscussion[]>;

    constructor(
        private route: ActivatedRoute,
        private gdService: GroupDiscussionsService,
        private global: GlobalService,
        private dbGroups: DbGroupsProvider
    ) {
        this.selected = [];
        this.q = '';
        this.currentPage = 0;
        this.orderOptions = [
            { text: 'global.a_z', value: 'asc' },
            { text: 'global.z_a', value: 'desc' },
            { text: 'global.latest', value: 'latest' },
            { text: 'global.oldest', value: 'oldest' }
        ];
        this.importing = false;
        this.currentOrderOption = 'asc';
        this.importResult = null;
    }

    ngOnInit(): void {
        this.getUserLanguage();

        this.routeParamsSubscription = this.routeParams().subscribe((p) => {
            this.eventId = p.eventId;
            this.moduleId = p.moduleId;
            this.groupDiscussions$ = this.gdService.GroupDiscussions(
                this.eventId,
                this.moduleId
            );
            this.module$ = this.gdService.module(this.moduleId);
            this.event$ = this.gdService.event(this.eventId);
        });
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription.unsubscribe();
    }

    routeParams() {
        return combineLatest(
            this.route.paramMap,
            this.route.parent.paramMap
        ).pipe(
            map((pms: ParamMap[]) => {
                return {
                    moduleId: pms[0].get('moduleId'),
                    eventId: pms[1].get('uid')
                };
            })
        );
    }

    setSetting(setting: string, value: boolean) {
        return this.gdService.setSetting(this.moduleId, setting, !value);
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });
    }

    async onEnableOrDisable(groupId: string, disabled: boolean) {
        try {
            this.gdService.enableOrDisable(this.eventId, groupId, disabled);
        } catch (e) {
            console.log(e);
        }
    }

    onSelectAll(evt, gds: GroupDiscussion[]): void {
        if (evt.target.checked) {
            this.selected = gds.map((gd) => gd.id);
        } else {
            this.selected = [];
        }
    }

    async onRemoveSelected(): Promise<void> {
        try {
            await this.gdService.deleteSelected(this.eventId, this.selected);

            this.selected = [];
        } catch (e) {
            console.log(e);
        }
    }

    onChecked(id: string) {
        const idx = this.selected.indexOf(id);

        if (~idx) {
            this.selected.splice(idx, 1);
        } else {
            this.selected.push(id);
        }
    }

    onOrderChange(evt): void {
        this.currentOrderOption = evt.target.value;
    }

    onSearch(evt): void {
        this.q = evt.target.value;
    }

    /**
     * Delete conversation messages
     * @param id 
     */
    onDeleteConvMessages(id: string) {
        this.loader = true;
        this.gdService.deleteConvMessages(this.eventId, id).subscribe((result) => {
            this.loader = false;
            this.successSwal.fire();
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        });
    }

    /**
     * Delete all conv messages of all groups
     */
    deleteAllConvMessages() {
        this.loader = true;
        this.groupDiscussions$.pipe(take(1)).subscribe((groups) => {
            groups.forEach((group, index) => {
                this.gdService.deleteConvMessages(this.eventId, group.id).subscribe((result) => {
                    if (index == groups.length - 1) {
                        this.loader = false;
                        this.successSwal.fire();
                    }
                }, (error) => {
                    this.loader = false;
                    this.errorSwal.fire();
                });
            })
        })

    }

    async onDelete(id: string): Promise<void> {
        try {
            await this.gdService.delete(this.eventId, id);
        } catch (e) {
            console.log(e);
        }
    }

    async onDownloadTemplate() {
        try {
            await this.gdService.exportTemplate();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Export conversation messages
     * @param groupId 
     */
    onExportConvMessages(groupId: string) {
        this.loader = true;
        this.gdService.exportConvMessages(this.eventId, groupId).subscribe((result) => {
            this.loader = false;
            this.successSwal.fire();
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    async onExport(gds: GroupDiscussion[]) {
        try {
            const groups = await this.dbGroups.getGroupsOfEventPromise(this.eventId)
            
            gds.map(gd => {
                gd.groups = gd.groups.length > 0 && [...gd.groups].map(g => {
                    return groups.filter(x => x.uid === g).map(x=> x.identifier)[0]
                })
                return gd
            })

                await this.gdService.export(gds);
        } catch (e) {
            console.log(e);
        }
    }

    async onImport() {
        try {
            this.importing = true;
            this.importResult = await this.gdService.import(
                this.prepareResult.ready
            );
        } catch (e) {
            this.importResult.internalError = 'global.import_some_data_04';
        } finally {
            this.importing = false;
        }
    }

    onPrepare() {
        this.importResult = null;
        this.prepareResult = null;
    }

    onFileChange(evt: any) {
        const target: DataTransfer = <DataTransfer>evt.target;
        const reader: FileReader = new FileReader();

        if (!target.files.length) {
            return;
        }

        const file = target.files[0];

        this.filename = file.name;

        reader.onload = async (e: any) => {
            try {
                this.importing = true;
                this.prepareResult = await this.gdService.prepareImportation(
                    e.target.result
                );
            } catch (e) {
                this.prepareResult.internalError = 'global.import_some_data_04';
            } finally {
                this.importing = false;
            }
        };

        reader.readAsBinaryString(file);
    }
}
