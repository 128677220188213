import { Module } from "../modules/module";
import { TypeModule } from "../../enums/type-module";
import { NameModule } from "../name-module";

export class ModuleAppointments extends Module {
    typeVision: number;
    selected: boolean;
    autoHidePendingAppointmentsAvailability = true;
    hideAppointmentsOnTimeSlotDelete = true;
    allowFeedback: boolean = true;
    allowModificationFeedback: boolean = false;
    visOnlyForLoggedUser: boolean;
    allowEmailNotif: boolean = false;

    constructor(
        name: NameModule,
        icon: string,
        typeVision: number,
        eventId: string,
        order: number,
        visOnlyForLoggedUser?: boolean
    ) {
        super(
            name,
            icon,
            TypeModule.APPOINTMENTS,
            eventId,
            order,
            false,
            true,
            true
        );
        this.typeVision = typeVision;
        this.selected = true;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}
