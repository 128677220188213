import { Component, OnInit, ViewChild } from '@angular/core';
import { DbAdminUserProvider } from '../../../providers/database/db-admin-user';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from 'src/app/models/user';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Name } from 'src/app/models/name';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { IfStmt } from '@angular/compiler';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-supergod-edit',
  templateUrl: './supergod-edit.component.html',
  styleUrls: ['./supergod-edit.component.scss'],
  providers: [DbAdminUserProvider]
})
export class SupergodEditComponent implements OnInit {

  formValidation: FormGroup;
  public userId: string;
  loader: boolean = false;
  validPassword: boolean = null;
  showErrorPassword: boolean = false;

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild('errorPermissionSwal') public errorPermissionSwal: SwalComponent;
  @ViewChild('confirmChangeUserTypeSwal') public confirmChangeUserTypeSwal: SwalComponent;
  @ViewChild('alreadyExistsSwal') public alreadyExistsSwal: SwalComponent;

  userRemoveId: string = null;
  userRemoveType: number = null;
  userRemoveTypeStr: string = null;
  
  constructor(private dbAdminUser: DbAdminUserProvider,
    private route: ActivatedRoute,
    fb: FormBuilder, 
    private router: Router,
    private auth: AuthService,
    private global: GlobalService,
    private dbAttendee: DbAttendeesProvider,
    private dbSpeaker: DbSpeakersProvider,
    private translateService: TranslateService) {
    this.userId = this.route.snapshot.params['uid'];

    this.formValidation = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
      'password': [null, Validators.compose([Validators.maxLength(30)])],
      'language': ['pt_BR', Validators.compose([Validators.required])],
      'description': [null],
    })
  }

  ngOnInit() {
    this.getUser();
  }

  ngDoCheck() {
    this.validatePassword();
  }

  validatePassword() {
    let password = this.formValidation.get('password').value;
    let regex = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^a-zA-Z]).{8,50}/;
    let regexSymbol = /[-!$%^&*#@()_+|~=`{}\[\]:";'<>?,.\/]/;

    let found;
    let foundSymbol;
    if (password !== null && password !== undefined && password !== '') {
      found = password.match(regex);
      foundSymbol = password.match(regexSymbol);


      if (found !== null && foundSymbol !== null) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
        this.showErrorPassword = true;
      }
    } else {
      this.validPassword = false;
      this.showErrorPassword = false;
    }
  }

  getUser() {
    console.log("edit:", this.userId)
    this.dbAdminUser.getUser(this.userId, (data) => {
      this.loadValuesUser(data.result);
    })
  }

  checkEmailEditSupergod() {
    let data = this.formValidation.value;

    this.dbAdminUser.getUserByEmail(data.email, (user) => {
      if(user === null) {
        this.userRemoveId = null;
        this.userRemoveType = null;
        this.userRemoveTypeStr = null;
        this.editUser();
      } else {

        if(user.type === TypeUser.SUPERGOD) {
          this.userRemoveId = null;
          this.userRemoveType = null;
          this.userRemoveTypeStr = null;
          this.editUser();

        } else if(user.type === TypeUser.GOD) {
          if(this.global.userType === TypeUser.SUPERGOD) {
            // Pergunta se o usuário deseja transformar o GOD em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_god');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
            
          }

        } else if(user.type === TypeUser.CLIENT) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD) {
            // Pergunta se o usuário deseja transformar o CLIENT em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_client');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.EMPLOYEE) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD || this.global.userType === TypeUser.CLIENT) {
            // Pergunta se o usuário deseja transformar o EMPLOYEE em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_employee');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.SPEAKER) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_speaker');
          this.confirmChangeUserTypeSwal.fire();

        } else if(user.type === TypeUser.ATTENDEE) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_attendee');
          this.confirmChangeUserTypeSwal.fire();
        
        }
      }
    })
  }

  confirmChangeUserType() {
    if(this.userRemoveType == TypeUser.SUPERGOD || this.userRemoveType == TypeUser.GOD 
      || this.userRemoveType == TypeUser.CLIENT || this.userRemoveType == TypeUser.EMPLOYEE) {
      
      this.auth.removeUserAuth(this.userRemoveId, (data) => {
        if(data.result === true) {
          this.editUser();
        }
      });

    } else if(this.userRemoveType == TypeUser.ATTENDEE) {
      
      this.dbAttendee.removeAttendeeAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.editUser();
        }
      })

    } else if(this.userRemoveType == TypeUser.SPEAKER) {
      
      this.dbSpeaker.removeSpeakerAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.editUser();
        }
      })
    }
    
  }

  editUser() {
    let userData = this.formValidation.value;

    this.loader = true;
    let user = new User(userData.name, userData.email);
    user.$language = userData.language;
    user.$description = userData.description;
    user.$type = 0;

    if (userData.password != null && userData.password != undefined && userData.password != '') {
      if (this.validPassword) {
        user.$password = userData.password;

        this.dbAdminUser.updateUser(this.userId, user, (data) => {
          let responseStatus = data.code;

          if (responseStatus == 200) {
            this.loader = false;
            this.successSwal.fire();
          } else {
            this.loader = false;
            this.errorSwal.fire();
          }

        })
      }
    } else {
      this.dbAdminUser.updateUser(this.userId, user, (data) => {
        let responseStatus = data.code;

        if (responseStatus == 200) {
          this.loader = false;
          this.successSwal.fire();
        } else {
          this.loader = false;
          this.errorSwal.fire();
        }
      })
    }

  }

  loadValuesUser(user) {
    this.formValidation.patchValue({
      name: user.name,
      email: user.email,
      language: user.language,
      description: user.description
    })
  }

  redirectList() {
    this.router.navigate(['/dashboard/supergod']);
  }
}
