import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CloudOptions, ZoomOnHoverOptions } from "angular-tag-cloud-module";
import { DbWordCloudProvider } from "../providers/database/db-word-cloud";
import { Word } from "./@types/word";

@Component({
    selector: "app-word-cloud-result",
    templateUrl: "./word-cloud-result.component.html",
    styleUrls: ["./word-cloud-result.component.scss"],
})
export class WordCloudResultComponent implements OnInit {
    eventId: string = null;
    moduleId: string = null;
    cloudId: string = null;
    words: Word[] = [];

    constructor(
        private route: ActivatedRoute,
        private dbWordCloud: DbWordCloudProvider
    ) {
        this.eventId = this.route.snapshot.params["eventId"];
        this.moduleId = this.route.snapshot.params["moduleId"];
        this.cloudId = this.route.snapshot.params["cloudId"];
    }

    ngOnInit() {
        this.getWords();
    }

    getWords() {
        this.dbWordCloud
            .getWords(this.moduleId, this.cloudId)
            .then((snapshot) => {
                const answers = <string[]>(
                    snapshot.docs.map((doc) => doc.data().answer)
                );
                this.createCloud(answers);
            })
            .catch((err) => console.error(err));
    }

    createCloud(answers: string[]) {
        this.words = [];
        for (const answer of answers) {
            const answerIndex = this.words.findIndex(
                (word) => word.text.toLowerCase() === answer.toLowerCase()
            );
            if (answerIndex !== -1) {
                this.words[answerIndex].weight += 1;
            } else {
                this.words.push({ text: answer, weight: 1 });
            }
        }
    }

    /**
     * Dados de config. modulo
     */
    options: CloudOptions = {
        overflow: false,
        randomizeAngle: true,
        width: 1,
        height: 400,
    };

    zoomOnHoverOptions: ZoomOnHoverOptions = {
        scale: 1.5,
        transitionTime: 1.2,
        delay: 0.3,
    };
}
