import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { TranslateService } from '@ngx-translate/core';
import { DbGallery } from 'src/app/providers/database/db-gallery';
import { Image } from 'src/app/models/image';
import { StorageService } from '../../../../../providers/storage/storage.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Event } from 'src/app/models/event';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/providers/global/global.service';
import { RegexProvider } from 'src/app/providers/regex/regex.service';
import { PathStorage } from 'src/app/paths/path-storage';

@Component({
    selector: 'app-gallery-folder',
    templateUrl: './gallery-folder.component.html',
    styleUrls: ['./gallery-folder.component.scss']
})
export class GalleryFolderComponent implements OnInit {
    public module = null
    allowChangeGrid: boolean = true;
    allowDownload = false;
    allowDownloadAll = false;
    grid: any = '-1';
    // get the language of the user.
    public userLanguage: string

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    eventId: string;
    moduleId: string;
    folderId: string;

    dropImgConfig: DropzoneConfigInterface = {
        // Change this to your upload POST address:
        url: 'https://us-central1-high5event-a48b5.cloudfunctions.net/utilitiesDropzoneUpload',
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        autoReset: 15000
    }

    invalidSize: boolean = false;
    translateMsg: string;
    images: Array<Image> = [];
    imgDeleteId: string = null;
    imgStorageId: string = null;
    selectedAllInput: boolean = false;
    imagesSelected: Array<boolean> = [];
    typeOrder: string = null;

    event: Event;
    loader: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private dbGallery: DbGallery,
        private storage: StorageService,
        private dbEvent: DbEventsProvider,
        private location: Location,
        private global: GlobalService,
        private regex: RegexProvider
    ) {
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.parent.params['_value']['moduleId'];
        this.folderId = this.route.snapshot.params['folderId'];
        console.log(this.route.snapshot);
        this.translateMsg = this.translateService.instant('comp.gallery.dragdrop_msg');
    }

    ngOnInit() {
        this.dbGallery.getFolder(this.moduleId, this.folderId, (folder) => {
            if (folder.allowDownload !== null && folder.allowDownload !== undefined) this.allowDownload = folder.allowDownload;
            if (folder.allowDownloadAll !== null && folder.allowDownloadAll !== undefined) this.allowDownloadAll = folder.allowDownloadAll;
            if (folder.allowGrid !== null && folder.allowGrid !== undefined) this.allowChangeGrid = folder.allowGrid;
            if (folder.grid !== null && folder.grid !== undefined) this.grid = folder.grid;
            if (folder !== undefined) {
                this.typeOrder = folder['orderGallery'];
                this.getGalleryImages();
            } else {
                this.loader = false;
            }
        });

        this.getModule()
        this.getUserLanguage()
        this.startEvent();
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    getModule() {
        this.dbGallery.getModule(this.moduleId, (module) => {
            if (typeof module !== 'undefined' && module !== null) {
                this.module = module;
            }
        });
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    getGalleryImages() {
        this.dbGallery.getImages(this.moduleId, this.folderId, this.typeOrder, (images: Array<Image>) => {
            this.images = [];
            this.images = images;
            this.loader = false;
        });
    }

    changeOrder() {
        this.dbGallery.changeOrderItems(this.moduleId, this.folderId, this.typeOrder, (data) => {
            if (data == true) {
                this.getGalleryImages();
            }
        })
    }

    upload(fileList: FileList) {
        this.invalidSize = false;
        // arquivo
        const file = fileList[0];
        let name = fileList[0].name;
        let aux = name.split('.');
        const imgName = aux[0].toString().toUpperCase();
        const finalName = this.regex.removeAccents(imgName);
        const type = aux[1];
        let imgId = UUID.UUID();

        // VERIFICA O TAMANHO DA IMAGEM
        if (file.size <= this.event.gallery_file_limit) {
            this.storage.uploadImg(file, this.eventId, this.moduleId, this.folderId, imgId, PathStorage.gallery, (url) => {
                let image = new Image();
                image.name = finalName;
                image.url = url;
                image.storageId = imgId;
                image.type = type;
                image.createdAt = Date.now() / 1000 | 0;
                this.dbGallery.saveImage(this.eventId, this.moduleId, this.folderId, image, (status) => {
                    if (!status) {
                        this.storage.deleteImg(this.eventId, this.moduleId, this.folderId, imgId, PathStorage.gallery);
                    }
                });
            });
        } else {
            this.invalidSize = true;
        }

    }

    getImageDelete(uid: string, storageId: string) {
        this.imgDeleteId = uid;
        this.imgStorageId = storageId;
    }

    deleteImage() {
        this.dbGallery.deleteImage(this.eventId, this.moduleId, this.folderId, this.imgDeleteId, this.imgStorageId, (status) => {
            if (!status) {
                this.errorSwal.fire();
            } else {
                this.successSwal.fire();
            }
        })
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.images.length; i++) {
                this.imagesSelected[this.images[i]['uid']] = true;
            }
        } else {
            for (let i = 0; i < this.images.length; i++) {
                this.imagesSelected[this.images[i]['uid']] = false;
            }
        }
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];
        let listImgStorageRemove = [];
        // let listRemoveIndexes = [];
        for (let i = 0; i < this.images.length; i++) {
            if (this.imagesSelected[this.images[i].uid] == true) {
                listRemove.push(this.images[i].uid);
                listImgStorageRemove.push(this.images[i].storageId);
                // listRemoveIndexes.push(i);
            }
        }

        this.dbGallery.deleteImages(this.eventId, this.moduleId, this.folderId, listRemove, listImgStorageRemove, (data) => {
            if (data == true) {
                // remove attendee from array
                // let cont = 0;
                // for (let j of listRemoveIndexes) {
                //   this.images.splice(j - cont, 1);

                //   cont++;
                // }

                //remove all selected box
                for (let j = 0; j < this.imagesSelected.length; j++) {
                    this.imagesSelected[this.images[j].uid] = false;
                }

                this.selectedAllInput = false;
                this.getGalleryImages();
                this.successSwal.fire();
                // this.loader = false;

            } else {
                this.selectedAllInput = false;
                this.errorSwal.fire();
                this.loader = false;
            }
        })
    }

    changeGridFormat(status) {
        let grid = (status.target.value == 'true')
        this.dbGallery.changeFolderGridFormat(this.moduleId, this.folderId, grid);
    }

    allowChangeGridFormat(status) {
        this.dbGallery.allowChangeFolderGridFormat(this.moduleId, this.folderId, status);
    }

    allowDownloadSave(status) {
        this.dbGallery.updateModuleFolder(this.moduleId, this.folderId, 'allowDownload', status);
    }

    allowDownloadAllSave(status) {
        this.dbGallery.updateModuleFolder(this.moduleId, this.folderId, 'allowDownloadAll', status);
    }

    back() {
        this.location.back();
    }

}
