import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { DbClientEmployeeProvider } from 'src/app/providers/database/db-client-employee';
import { GlobalService } from 'src/app/providers/global/global.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';


@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})

@NgModule({
  declarations: [FilterPipe], 
})

export class EmployeesComponent implements OnInit {
  listEmployees: Array<any> = [];
  loader: boolean = true;
  public p: number = 1;
  employeeRmDelete: string = null;
  employeeRmIndex: number = null;
  userId: string = null; 
  term: string = '';
  typeOrder: string = 'asc';

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  constructor(
    private dbClientEmployee: DbClientEmployeeProvider,
    private global: GlobalService,
    private route: ActivatedRoute,
    private dbAdminUser: DbAdminUserProvider,
  ) { 
    this.userId = this.route.snapshot.params['userId'];
  }

  ngOnInit() {
    this.getEmployees();

    this.global.loadService(() => {
      this.userId = this.global.userId;
      this.loadOrder().then(() => {
        this.getEmployees(); 
      });
    })
  }

  loadOrder() {
    return new Promise((resolve) => {
      this.userId = this.global.userId;
      
      this.dbAdminUser.getUser(this.userId, (data) => {
        let user = data.result;

        if(user.employeesOrder !== undefined && user.employeesOrder !== null && user.employeesOrder !== '') {
          this.typeOrder = user.employeesOrder;
        }

        resolve(true);
      })
    })
  }

  getEmployees() {
    this.dbClientEmployee.getEmployees(this.userId, this.typeOrder, (employees) => {
      this.listEmployees = [];
      this.listEmployees = employees;
      this.loader = false;
    });
  }

  changeOrder() {
    console.log('changeOrder')
    this.dbAdminUser.changeOrderEmployees(this.userId, this.typeOrder, (data) => {
      if (data == true) {
        this.getEmployees();
      }
    })
  }

  getEmployeeRemove(employee, i) {
    this.employeeRmDelete = employee;
    this.employeeRmIndex = i;
  }

  removeEmployee() {
    this.dbClientEmployee.deleteEmployee(this.employeeRmDelete, (status) => {
      if (!status) {
        this.listEmployees.splice(this.employeeRmIndex, 1);
        this.successSwal.fire();
      } else {
        this.errorSwal.fire();
      }
    });
  }

}
