import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { AppState } from 'src/app/shared/reducers';
import * as moment from 'moment';
import { getAttendeesAnalytics } from 'src/app/shared/selectors/analytics.selectors';

@Component({
    selector: 'app-attendees-analytics',
    templateUrl: './attendees-analytics.component.html',
    styleUrls: ['./attendees-analytics.component.scss']
})
export class AttendeesAnalyticsComponent implements OnInit {
    loader: boolean = true;

    event: any;
    eventId: string;
    userLanguage: string;

    totalAttendeesModules: number = 0;
    totalAttendees: number = 0;

    attendeesModules: any[] = [];
    attendeesModuleId: any;
    attendeesForSelectedModule: any[] = [];

    currentPageAttendees: number = 1;

    numberOfItemsPerPage: number = 10;

    sortType: string = "id";

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics.getAttendeesModulesAnalytics(this.eventId).pipe(take(1)).subscribe((attendeesModules) => {
                this.attendeesModules = attendeesModules;

                this.store.select(getAttendeesAnalytics).subscribe((attendeesAnalytics) => {
                    this.totalAttendeesModules = (attendeesAnalytics && attendeesAnalytics.totalAttendeesModules) ? attendeesAnalytics.totalAttendeesModules : 0;
                    this.totalAttendees = (attendeesAnalytics && attendeesAnalytics.totalAttendees) ? attendeesAnalytics.totalAttendees : 0;
                })

                this.loader = false;
            });
        });
    }

    /**
     * Export analytics of schedules
     */
    exportAll() {
        // this.loader = true;
        // this.dbAnalytics.exportSchedulesAnalytics(this.eventId).subscribe(() => {
        //     this.loader = false;
        // }, (error) => {
        //     this.loader = false;
        // })
    }

    /**
     * Export attendees module analytics
     */
    exportAttendeesForModule() {
        this.loader = true;
        this.dbAnalytics.exportAttendeesForModuleAnalytics(this.eventId, this.attendeesModuleId, this.userLanguage).subscribe((result) => {
            this.loader = false;
        }, (error) => {
            console.log("Error: ", error);
            this.loader = false;
        })
    }

    /**
     * Change selected module
     */
    selectedModuleChange() {
        this.loader = true;
        this.dbAnalytics.getAttendeesForModule(this.eventId, this.attendeesModuleId).subscribe((attendees) => {
            console.log("Attendees: ", attendees);
            this.sortType = 'desc-startTime';
            this.attendeesForSelectedModule = attendees;

            this.loader = false;

        }, (error) => {
            console.log("Error: ", error);
            this.loader = false;
        })
    }

    /**
     * Convert date to human read
     * @param date 
     */
    convertDate(date: number) {
        return (moment.unix(date).format('DD/MM/YYYY - HH:mm'));
    }

    /**
     * Get name for selected schedule
     */
    getSelectedAttendeesModuleName() {
        let name = this.attendeesModules.filter((module) => module.uid == this.attendeesModuleId)[0].name[this.userLanguage];
        return ((name) ? name : "");
    }

    /**
     * Sorting attendees
     * @param type 
     */
    sortingBy(type: string) {
        if (this.attendeesForSelectedModule && this.attendeesForSelectedModule.length > 0) {
            switch (type) {
                case 'identifier':
                    if (this.sortType == 'desc-identifier') {
                        this.sortType = 'asc-identifier';
                        this.attendeesForSelectedModule.sort((a, b) => (a.identifier < b.identifier) ? 1 : (a.identifier > b.identifier) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-identifier';
                        this.attendeesForSelectedModule.sort((a, b) => (a.identifier > b.identifier) ? 1 : (a.identifier < b.identifier) ? -1 : 0);
                    }
                    break;
                case 'name':
                    if (this.sortType == 'desc-name') {
                        this.sortType = 'asc-name';
                        this.attendeesForSelectedModule.sort((a, b) => (a.name < b.name) ? 1 : (a.name > b.name) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-name';
                        this.attendeesForSelectedModule.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0);
                    }
                    break;
                case 'email':
                    if (this.sortType == 'desc-email') {
                        this.sortType = 'asc-email';
                        this.attendeesForSelectedModule.sort((a, b) => (a.email < b.email) ? 1 : (a.email > b.email) ? -1 : 0);
                    } else {
                        this.sortType = 'desc-email';
                        this.attendeesForSelectedModule.sort((a, b) => (a.email > b.email) ? 1 : (a.email < b.email) ? -1 : 0);
                    }
                    break;
            }
        }
    }
}
