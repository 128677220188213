import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentRuleTimeSlots } from 'src/app/models/appointments';
import * as luxon from 'luxon';
import { DbAppointments } from 'src/app/providers/database/db-appointments';

@Component({
  selector: 'app-appointments-rule-time-slots',
  templateUrl: './appointments-rule-time-slots.component.html',
  styleUrls: ['./appointments-rule-time-slots.component.scss']
})

export class AppointmentsRuleTimeSlotsComponent implements OnInit {

  private _timeSlotsRule: AppointmentRuleTimeSlots;
  private _eventTimeZone: string;

  @Input()
  set timeSlotsRule(timeSlotsRule: AppointmentRuleTimeSlots) {
    this._timeSlotsRule = timeSlotsRule
      || new AppointmentRuleTimeSlots(
        luxon.DateTime.local().set({ hour: 9, minute: 0, second: 0 }).setZone(this.eventTimezone),
        luxon.DateTime.local().set({ hour: 17, minute: 0, second: 0 }).setZone(this.eventTimezone),
        30,
        [],
        null
      );
    this.selectedDate = this.timeSlotsRule.startDateTime.toISODate();
  }
  get timeSlotsRule() {
    return this._timeSlotsRule;
  }

  @Input()
  set eventTimezone(eventTimeZone: string) {
    this._eventTimeZone = eventTimeZone;
    this.timeSlotsRule.startDateTime = this.timeSlotsRule.startDateTime.setZone(eventTimeZone);
    this.timeSlotsRule.endDateTime = this.timeSlotsRule.endDateTime.setZone(eventTimeZone);
    this.selectedDate = this.timeSlotsRule.startDateTime.toISODate();
  }
  get eventTimezone() {
    return this._eventTimeZone;
  }

  @Output()
  timeSlotsRuleChange = new EventEmitter<AppointmentRuleTimeSlots>();
  @Output()
  slotsCount = new EventEmitter<number>();

  availableStartingTimes: any[];
  availableEndingTimes: any[];
  availableDurations = [5, 10, 15, 20, 25, 30, 40, 45, 60, 90, 120];

  locale: string;


  get selectedDate() {
    return this.timeSlotsRule && this.timeSlotsRule.startDateTime ?
      this.timeSlotsRule.startDateTime.toISODate() :
      luxon.DateTime.local().set({ hour: 9, minute: 0, second: 0 }).setZone(this.eventTimezone).toISODate();
  }

  set selectedDate(date: string) {
    this.availableStartingTimes = [];
    this.availableEndingTimes = [];
    for (let i = 0; i < 48; i++) {
      let hour = this.getDateTimeWithEventZone(date);
      hour = hour.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      hour = hour.plus({ minutes: i * 30 });
      this.availableStartingTimes.push(hour);
      this.availableEndingTimes.push(hour);
      if (hour.toISOTime() === this.timeSlotsRule.startDateTime.toISOTime()) {
        this.timeSlotsRule.startDateTime = hour;
      }
      if (hour.toISOTime() === this.timeSlotsRule.endDateTime.toISOTime()) {
        this.timeSlotsRule.endDateTime = hour;
      }
    }
    this.onChange();
  }

  constructor(
    private dbAppointments: DbAppointments,
  ) {
    this.locale = this.dbAppointments.locale;
  }

  async ngOnInit() {
    if (!this.timeSlotsRule) {
      this.timeSlotsRule = new AppointmentRuleTimeSlots(
        this.getDateTimeWithEventZone(luxon.DateTime.local().toISODate())
          .set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
        this.getDateTimeWithEventZone(luxon.DateTime.local().toISODate())
          .set({ hour: 17, minute: 0, second: 0, millisecond: 0 }),
        30,
        [],
        null,
      );
      this.onChange();
      this.selectedDate = this.timeSlotsRule.startDateTime.toISODate();
    }
  }

  onChange() {
    this.timeSlotsRuleChange.emit(this.timeSlotsRule);
    this.slotsCount.emit(this.timeSlotsRule.getAllIntervals().length);
  }

  getDateTimeWithEventZone(isoDateTime: string) {
    return luxon.DateTime.fromISO(isoDateTime, { zone: this.eventTimezone });
  }

  compareTime(time1: luxon.DateTime, time2: luxon.DateTime) {
    if (time1 == null || time2 == null) {
      return false;
    }
    return time1.toISOTime() === time2.toISOTime();
  }

}
