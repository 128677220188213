import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Map } from 'src/app/models/map';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})

export class DbMapsProvider {
    constructor(
        private afs: AngularFirestore,
        private storage: StorageService
    ) { }

    getModule(moduleId: string, onResolve) {
        let db = this.afs.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((snapshot) => {
                onResolve(snapshot.data());
            });
    }

    updateModuleOrder(moduleId: string, typeOrder: string) {
        let db = this.afs.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .update({ orderMaps: typeOrder });
    }

    getMaps(moduleId: string, typeOrder: string, onResolve) {
        let db = this.afs.firestore;
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = db.collection('modules').doc(moduleId).collection('maps')
                    .orderBy('name', 'asc');
                break;

            case 'desc': //z-a
                ref = db.collection('modules').doc(moduleId).collection('maps')
                    .orderBy('name', 'desc');
                break;

            case 'oldest'://antiho-recente
                ref = db.collection('modules').doc(moduleId).collection('maps')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('maps')
                    .orderBy('createdAt', 'desc');
                break;
        }

        let array = [];
        ref.onSnapshot((data) => {
            array = [];
            data.forEach(doc => {
                array.push(doc.data());
            });
            onResolve(array);
        });
    }

    createMap(obj, moduleId: string, onResolve) {
        let db = this.afs.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('maps').doc();
        let map = new Map;

        map.uid = ref.id;
        map.moduleId = moduleId;
        map.name = obj.name;
        map.storageId = obj.storageId;
        map.address = obj.address;
        map.image = obj.image;
        map.createdAt = obj.createdAt;
        map.type = obj.type;
        map = Object.assign({}, map);

        ref
            .set(map)
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    editMap(obj, moduleId: string, onResolve) {
        let db = this.afs.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('maps').doc(obj.uid);
        let map = new Map;

        map.name = obj.name;
        map.storageId = obj.storageId;
        map.address = obj.address;
        map.image = obj.image;
        map.type = obj.type;
        map = Object.assign({}, map);

        ref
            .update(map)
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    removeMap(map, moduleId: string, eventId: string, onResolve) {
        let db = this.afs.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('maps').doc(map.uid);
        if (map.storageId !== null && map.storageId !== '' && map.storageId !== undefined) {
            this.storage.deleteMap(map.storageId, moduleId, eventId);
        }
        ref.delete()
            .then((_) => {

                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }
}