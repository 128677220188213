import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from '../providers/database/db.events';
import { DbRankingProvider } from '../providers/database/db-ranking';

@Component({
  selector: 'app-realtime-ranking',
  templateUrl: './realtime-ranking.component.html',
  styleUrls: ['./realtime-ranking.component.scss']
})
export class RealtimeRankingComponent implements OnInit {
  public eventId: string = null;
  public event: any = null;
  public attendees: Array<any> = [];
  public loader: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private dbEvent: DbEventsProvider,
    private dbRanking: DbRankingProvider,
    private zone: NgZone
  ) {
    this.eventId = this.route.snapshot.params['eventId'];
  }

  ngOnInit() {
    this.startEvent();
    this.startRanking();
  }

  startEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.event = event;
    });
  }

  startRanking() {
    this.dbRanking.getAttendeeRanking(this.eventId, (attendees) => {
      this.zone.run(() => {
        this.attendees = this.sortAttendeesByPoints(attendees);
        this.loader = false;
      })
    });
  }

  attendeesWithPoints() {
    let attendeesWithPoints = this.attendees.filter(attendee => this.getAttendeeEngagementPoints(attendee) > 0 || attendee.points > 0);
    return this.sortAttendeesByPoints(attendeesWithPoints);
  }

  sortAttendeesByPoints(attendees) {
    attendees = attendees.sort((a, b) => { // sorting by name (asc)
        return a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1;
    })

    return attendees.sort((a, b) => {
        const aPoints = this.getAttendeeEngagementPoints(a) + a.points;
        const bPoints = this.getAttendeeEngagementPoints(b) + b.points;
        return aPoints > bPoints ? -1 : 1;
    })
  }

  getAttendeeEngagementPoints(attendee) {
    if (!attendee.engagementPoints) return 0;
    return Object.values<number>(attendee.engagementPoints).reduce((acc: number, cur: number) => acc + cur, 0);
  }

}
