import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { PathApi } from 'src/app/paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { NotifiicationDateService } from '../luxon/notification-date.service';
import { Subscription } from 'rxjs';
// const axios = require('axios');


@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private secondaryAuth: firebase.auth.Auth;

    public headers;
    public requestOptions;
    public authState;
    public tokenId: string = null;
    public userUid: string = null;
    public db;

    constructor(
        private auth: AngularFireAuth,
        private http: HttpClient,
        public router: Router,
        private aFirestore: AngularFirestore,
        private storage: StorageService,
        private luxon: NotifiicationDateService
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
        this.db = this.aFirestore.firestore;
    }

    // verify if e-mail exists in Firebase Authentication

    verifyEmailAuth(email: string) {
        return new Promise((resolve) => {
            let body = {
                email: email
            }
            this.http.post(PathApi.baseUrl + PathApi.authVerifyEmailExists, body, this.requestOptions)
                .subscribe((data) => {
                    if (data['result']['uid'] !== undefined && data['result']['uid'] !== null) {
                        // user exists
                        resolve({ code: 0, uid: data['result']['uid'] });
                    } else {
                        // user doesn't exist
                        resolve({ code: 1 });
                    }
                }), err => {
                    // error to fetch email
                    resolve({ code: 2 });
                }
        })
    }

    // Verify if e-mail exists in Firestore Database
    verifyEmailDb(email: string) {
        return new Promise((resolve, reject) => {
            this.db
                .collection('users') // users collection
                .where('email', '==', email) // find DOC using e-mail (index)
                .get()
                .then((snapshot) => {
                    let find = 0;
                    let aux;
                    snapshot.forEach(doc => {
                        aux = doc.data();
                        find = 1;
                    });

                    if (find == 1) {
                        resolve({
                            code: 200,
                            message: 'success',
                            result: aux
                        });
                    } else {
                        resolve({
                            code: 200,
                            message: 'success',
                            result: 'email-not-found'
                        });
                    }
                })
                .catch((error) => {
                    reject({
                        code: 404,
                        message: 'error',
                        result: error
                    })
                })
        })
        // in return, verify if is user first access, to redirect to make password
    }


    /**
       * Helper function to check if user is logged in
       * @returns {Observable<boolean>}
       * @memberof AuthService
       */
    isAuthenticated(): Observable<boolean> {
        return new Observable((subscriber) => {
            this.auth.authState.subscribe((user) => {
                subscriber.next(user !== null && user !== undefined);
            });
        });
    }

    /**
      * Takes email and password and attempts to log user in firebase
      * @param {string} email
      * @param {string} password
      * @param onResolve true if login was successful
      * @memberof AuthService
      */
    loginUser(email: string, password: string) {
        return new Promise<string>((resolve, reject) => {
            this.auth.signInWithEmailAndPassword(email, password).then((credentials) => {
                credentials.user.getIdToken().then((token) => {
                    this.tokenId = token;
                    localStorage.setItem('idToken', token);

                    resolve(token);
                }).catch((error) => {
                    console.log(error);
                    reject();
                });
            }).catch((error) => {
                console.log(error)
                reject();
            });
        });
    }

    checkLoginUser(email: string, password: string, onResolve) {
        this.auth.signInWithEmailAndPassword(email, password).then((_) => {
            onResolve(true);
            // this.auth.user.subscribe(())
            
            
            // .getIdToken().then((idToken: string) => {
            //     this.tokenId = idToken;
            //     localStorage.setItem('idToken', idToken);
            // });
        }).catch((__) => {
            onResolve(false);
        });
    }

    /**
     * Attempts to log the user out from firebase
     * @memberof AuthService
     */
    logoutUser() {
        this.auth.signOut();
        localStorage.removeItem('idToken');
        this.tokenId = null;
        
        this.router.navigate(['/']).then((success) => {
            location.reload();
        }).catch((err) => { })

        // location.reload();
    }


    /**
    * T returns the id of the user's token
    * @memberof AuthService
    */
    getIdToken() {
        return new Promise((resolve, reject) => {
            if (this.tokenId == null) {
                const subscription: Subscription = this.auth.user.subscribe((user) => {
                    user.getIdToken().then((token) => {
                        this.tokenId = token;
                        resolve(token);
                    }).catch((error) => {
                        console.log(error);
                        reject();
                    }).finally(() => { subscription.unsubscribe(); })
                });
            }
            else { resolve(this.tokenId); }
        });
    }


    /**
     *  returns the user's custom authentication data
     * @memberof AuthService
     */
    claimsUser(onResolve) {
        this.getIdToken().then((idToken: string) => {
            return this.http.post(
                PathApi.baseUrl + PathApi.authClaimsUser,
                { idToken: idToken },
                this.requestOptions
            ).subscribe((claims: any) => { onResolve(claims['result']); })
        });
    }

    /**
   *  returns the type user's custom authentication 
  * @memberof AuthService
   */
    claimsTypeUser() {
        return new Promise((resolve) => {
            this.getIdToken().then((idToken: string) => {
                // request post
                return this.http.post(
                    PathApi.baseUrl + PathApi.authClaimsUser,
                    { idToken: idToken },
                    this.requestOptions
                ).subscribe(
                    (claims: any) => {
                        const data = {
                            uid: claims['result']['uid'],
                            type: claims['result']['type']
                        }

                        resolve(data)
                    }
                )
            });
        })

    }


    checkSecondary() {
        if (this.secondaryAuth === null || this.secondaryAuth === undefined) {
            this.secondaryAuth = firebase.initializeApp(environment.firebase, 'secondary').auth();
        }
    }

    logoutSecondaryUser() {
        this.secondaryAuth.signOut();
    }

    createUser(user) {
        return new Promise((resolve, reject) => {
            this.checkSecondary();
            this.secondaryAuth.createUserWithEmailAndPassword(user.email, user.password).then((newUser) => {
                const uid = newUser.user.uid;;
                user.uid = newUser.user.uid;
                const body = {
                    uid: uid,
                    type: user.type
                }
                this.http.post(PathApi.baseUrl + PathApi.authSetUserClaims, body, this.requestOptions)
                    .subscribe((_) => { });
                this.db
                    .collection("users").doc(uid).set({
                        name: user.name,
                        email: user.email,
                        language: user.language,
                        description: user.description,
                        type: user.type,
                        uid: uid,
                        photoUrl: user.photoUrl,
                        createdAt: this.luxon.getTimeStampFromDateNow(new Date(), '(UTC-3) Brasilia, Salvador, Fortaleza, Belo Horizonte, Rio de Janeiro, Porto Alegre, São Paulo'),
                        emailRecovery: user.emailRecovery,
                        title: user.title,
                        company: user.company,
                        firstAccess: false
                    }).then((data) => {
                        resolve({
                            code: 201,
                            message: 'success',
                            result: user
                        });
                    }).catch((err) => {
                        //remove da autenticação caso dê erro no banco
                        this.removeUserAuth(user.uid, (data) => { })
                        reject({
                            code: 500,
                            message: 'error',
                            result: err
                        });
                    });
            })
                .catch((err) => {
                    console.log(err);
                    reject({
                        code: 500,
                        message: 'error',
                        result: err
                    });
                })
        });

    }


    createEmployee(user, photo) {
        return new Promise((resolve, reject) => {
            this.checkSecondary();
            this.secondaryAuth.createUserWithEmailAndPassword(user.email, user.password).then((newUser) => {
                const uid = newUser.user.uid;;
                user.uid = newUser.user.uid;
                const body = {
                    uid: uid,
                    type: user.type
                }
                this.http.post(PathApi.baseUrl + PathApi.authSetUserClaims, body, this.requestOptions)
                    .subscribe((_) => { });
                if (photo !== null) {
                    this.storage.profilePicture(photo, uid, (url) => {
                        this.db
                            .collection("users").doc(uid).set({
                                name: user.name,
                                email: user.email,
                                language: user.language,
                                description: user.description,
                                type: 3,
                                uid: uid,
                                photoUrl: url,
                                createdAt: this.luxon.getTimeStampFromDateNow(new Date(), '(UTC-3) Brasilia, Salvador, Fortaleza, Belo Horizonte, Rio de Janeiro, Porto Alegre, São Paulo'),
                                title: user.title,
                                company: user.company,
                                firstAccess: false,
                                clientId: user.clientId
                            }).then((data) => {
                                // console.log(data);
                                resolve(user);
                            }).catch((err) => {
                                //remove da autenticação caso dê erro no banco
                                this.removeUserAuth(user.uid, (data) => { })
                                console.log(err);
                                reject(err);
                            });
                    })
                } else {
                    this.db
                        .collection("users").doc(uid).set({
                            name: user.name,
                            email: user.email,
                            language: user.language,
                            description: user.description,
                            type: 3,
                            uid: uid,
                            photoUrl: null,
                            createdAt: this.luxon.getTimeStampFromDateNow(new Date(), '(UTC-3) Brasilia, Salvador, Fortaleza, Belo Horizonte, Rio de Janeiro, Porto Alegre, São Paulo'),
                            title: user.title,
                            company: user.company,
                            firstAccess: false,
                            clientId: user.clientId
                        }).then((data) => {
                            // console.log(data);
                            resolve(user);
                        }).catch((err) => {
                            //remove da autenticação caso dê erro no banco
                            this.removeUserAuth(user.uid, (data) => { })
                            console.log(err);
                            reject(err);
                        });
                }
            })
                .catch((err) => {
                    console.log(err);
                    reject(err)
                })
        });

    }

    updateUserAuth(userId: string, user, onResolve) {
        this.http.put(PathApi.baseUrl + PathApi.authUpdateUser, {
            uid: userId,
            user: user
        }, this.requestOptions)
            .subscribe((success) => {
                onResolve(success);
            });
    }

    removeUserAuth(uid: string, onResolve) {
        console.log('remove user auth')
        this.requestOptions.params.userId = uid;

        this.http.delete(PathApi.baseUrl + PathApi.authDeleteUser, this.requestOptions).subscribe((data) => {
            onResolve(data)
        });
    }

    public authenticated(): boolean {
        let storage = localStorage.getItem('idToken');
        if (this.tokenId === undefined && storage !== null) {
            this.tokenId = storage;
        }
        return this.tokenId !== undefined;
    }

    public current(onResolve) {

        this.auth.authState.subscribe((auth) => {
            onResolve(auth);
        });

    }

    recoveryPassword(email: string) {
        return new Promise((resolve, reject) => {
            this.auth.sendPasswordResetEmail(email).then((send) => {
                resolve(true)
            })
                .catch((_) => { reject(false) });
        });
    }

    updateUserPassword(theUser, newPassword: string) {
        return new Promise((resolve, reject) => {
            theUser.updatePassword(newPassword).then((success) => {
                theUser.getIdToken(true).then((token) => {
                    resolve(true);
                }).catch((tokenerr) => console.log(tokenerr));
            }).catch((error) => {
                console.log();
                reject(error);
            })
        })
    }

    checkAuthEmail(email, onResolve) {
        this.auth.fetchSignInMethodsForEmail(email).then((status) => {
            onResolve(status);
        })
            .catch((err) => {
                onResolve(err);
            });
    }
}
