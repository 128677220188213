/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from '../../../models/event';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { MapsAPILoader } from '@agm/core';
import { GlobalService } from "src/app/providers/global/global.service";

declare let google: any;
declare let $: any;
import { FormGroup, Validators, FormBuilder, FormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';
import { EventFieldsVisibility } from 'src/app/models/event-fields-visibility';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { NameGroup } from 'src/app/enums/name-group';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DbManagerModuleProvider } from 'src/app/providers/database/db-manager-module';
import * as timezones from 'timezones.json'
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';

@Component({
    selector: 'app-client-event-info',
    templateUrl: './client-event-info.component.html',
    styleUrls: ['./client-event-info.component.scss']
})

export class ClientEventInfoComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    visibility;
    eventId: string;
    eventEdit: Event = null;
    formValidation: FormGroup;
    testComplete;
    eventFields: EventFieldsVisibility;
    // timezones
    timezones: Array<string> = [];
    visibilities: Array<boolean> = [];
    languages = [];
    loader: boolean = true;
    loaderBtn: boolean = false;
    avaiableDescriptionsToSelect;
    // maps
    @ViewChild("search") public searchElement: ElementRef;
    addressGoogle = '';
    // Variable Forms Error
    dateError: boolean;
    startDatePastError: boolean
    endDatePastError: boolean
    updateEventError: boolean

    newSelectedLanguage: string = '-1';

    defaultAppUrl: string = environment.platform.defaultAppUrl;

    allow_language: boolean = false; //multi language 

    interactivityModuleId: string = null;
    answerOffline: boolean = null;
    attendeeModules: Array<any> = [];
    principalEventLanguage: string = '';

    constructor(
        private route: ActivatedRoute,
        private dbEvents: DbEventsProvider,
        private dbModules: DbManagerModuleProvider,
        private dbGroup: DbGroupsProvider,
        private luxon: LuxonService,
        private translateService: TranslateService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private fb: FormBuilder,
        public global: GlobalService,
        private dt: DateTimeService
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        console.log(this.global.userType);
    }

    ngOnInit() {
        //list of all available timezones
        timezones.default.forEach(tz => {this.timezones.push(tz.text)});


        // languages
        this.languages = [
            { value: "pt_BR", name: this.translateService.instant('comp.event_info.portuguese') },
            { value: "en_US", name: this.translateService.instant('comp.event_info.english') },
            { value: "es_ES", name: this.translateService.instant('comp.event_info.spanish') },
            { value: "fr_FR", name: this.translateService.instant('comp.event_info.french') },
            { value: "de_DE", name: this.translateService.instant('comp.event_info.german') }
        ]

        // form
        this.formValidation = this.fb.group({
            'title': [this.translateService.instant('comp.new_event.event_name'), Validators.compose([Validators.required])],
            'language': [{ value: '', name: '' }, Validators.compose([Validators.required])],
            'shortcode': [this.translateService.instant('comp.new_event.event_shortcode'), Validators.compose([Validators.required])],
            'site': [''],
            'startDate': ['dd/mm/aaaa', Validators.compose([Validators.required])],
            'endDate': ['dd/mm/aaaa', Validators.compose([Validators.required])],
            'timezone': ['', Validators.compose([Validators.required])],
            'visibility': ['', Validators.compose([Validators.required])],
            'default_attendee_module': [''],
            'google_btn': [false],
            'facebook_btn': [false],
            'twitter_btn': [false],
            'microsoft_btn': [false],
            'yahoo_btn': [false],
            'placeAddress': [''],
            'placeName': [''],
            'emailSupport': [''],
            'pt_BR': [null],
            'en_US': [null],
            'es_ES': [null],
            'fr_FR': [null],
            'de_DE': [null]
        });
    }

    ngAfterViewInit() {
        this.loadEvents()
        this.loadConfigInteractivity();
    }

    loadConfigInteractivity() {
        this.dbEvents.getModuleInteractivity(this.eventId, (module) => {
            this.interactivityModuleId = module.uid;
            if (module.answerOffline === undefined) {
                this.answerOffline = false;
            } else {
                this.answerOffline = module.answerOffline;
            }
        })
    }

    changeInteractivityConfig() {
        setTimeout(() => {
            this.dbEvents.changeInteractivityAnswerConfig(this.eventId, this.interactivityModuleId, this.answerOffline, (data) => {
                if (!data) {
                    this.answerOffline = !this.answerOffline;
                }
            })
        }, 1000);
    }

    getAttendeesModules() {
        this.dbModules.getAttendeeModulesByEvent(this.eventId, (modules) => {
            this.attendeeModules = modules;
        })
    }

    // load the event
    loadEvents() {
        this.dbEvents.getEvent(this.eventId, (event: Event) => {
            this.ngZone.run(() => {
                if (event) {
                    this.principalEventLanguage = this.convertLangFormat(event.language);
                    this.getAttendeesModules()

                    // get event
                    this.eventEdit = event;
                    if (this.eventEdit.publicOptions == undefined) {
                        this.eventEdit.publicOptions = {
                            google_btn: false,
                            facebook_btn: false,
                            twitter_btn: false,
                            microsoft_btn: false,
                            yahoo_btn: false,
                        }
                    }

                    if (this.eventEdit.allowProfileQR == undefined || this.eventEdit.allowProfileQR == null) {
                        this.eventEdit.allowProfileQR = true;
                    }

                    if (this.eventEdit.allowLogin == undefined || this.eventEdit.allowLogin == null) {
                        this.eventEdit.allowLogin = true;
                    }
                    if (this.eventEdit.allowRegistration == undefined || this.eventEdit.allowRegistration == null) {
                        this.eventEdit.allowRegistration = true;
                    }

                    if (this.eventEdit.firstRegistration == undefined || this.eventEdit.firstRegistration == null) {
                        this.eventEdit.firstRegistration = true;
                    }
                    //multi language 
                    this.allow_language = this.eventEdit.allow_language

                    // sort array of languages by placing the main language at the top.
                    this.sortArrayLanguages()

                    if (event.eventFields === undefined) {
                        event['eventFields'] = Object.assign({}, new EventFieldsVisibility());
                    }

                    this.eventFields = event.eventFields;

                    // get infos event
                    this.formValidation.patchValue({
                        title: event.title,
                        language: this.getLanguage(event.language),
                        shortcode: event.shortcode,
                        site: event.site,
                        startDate: this.dt.formatDate(event.startDate),
                        endDate: this.dt.formatDate(event.endDate),
                        timezone: this.dt.findTzText(event.timezone),
                        visibility: event.visibility,
                        placeAddress: event.placeAddress,
                        placeName: event.placeName,
                        emailSupport: event.emailSupport,
                        default_attendee_module: event.default_attendee_module,
                        pt_BR: event.description.pt_BR,
                        en_US: event.description.en_US,
                        es_ES: event.description.es_ES,
                        fr_FR: event.description.fr_FR,
                        de_DE: event.description.de_DE
                    });

                    this.loadPlacesAutocomplete();
                }

                this.loader = false;
            });
        });
    }

    async updateEvent() {
        this.loaderBtn = true;
        let validation = true;
        this.dateError = false;
        this.startDatePastError = false;
        this.endDatePastError = false;
        this.updateEventError = false;

        this.eventEdit.title = this.formValidation.value.title
        this.eventEdit.site = this.formValidation.value.site
        this.eventEdit.timezone = this.dt.findTzIdentifier(this.formValidation.value.timezone);
        this.eventEdit.visibility = this.formValidation.value.visibility;
        this.eventEdit.emailSupport = this.formValidation.value.emailSupport;
        this.eventEdit.eventFields = this.eventFields;
        this.eventEdit.default_attendee_module = this.formValidation.value.default_attendee_module;

        if (this.addressGoogle !== '') {
            this.eventEdit.placeAddress = this.addressGoogle;
        } else {
            this.eventEdit.placeAddress = this.formValidation.value.placeAddress;
        }
        this.eventEdit.placeName = this.formValidation.value.placeName;


        // descriptions
        // Checks if the language is enabled.
        if (this.eventEdit.languages['PtBR']) {
            this.eventEdit.description['pt_BR'] = this.formValidation.value.pt_BR !== null ? this.formValidation.value.pt_BR.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        } else {
            this.eventEdit.description['pt_BR'] = null
        }

        if (this.eventEdit.languages['EnUS']) {
            this.eventEdit.description['en_US'] = this.formValidation.value.en_US !== null ? this.formValidation.value.en_US.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        } else {
            this.eventEdit.description['en_US'] = null
        }

        if (this.eventEdit.languages['EsES']) {
            this.eventEdit.description['es_ES'] = this.formValidation.value.es_ES !== null ? this.formValidation.value.es_ES.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        } else {
            this.eventEdit.description['es_ES'] = null
        }

        if (this.eventEdit.languages['FrFR']) {
            this.eventEdit.description['fr_FR'] = this.formValidation.value.fr_FR !== null ? this.formValidation.value.fr_FR.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        } else {
            this.eventEdit.description['fr_FR'] = null
        }

        if (this.eventEdit.languages['DeDE']) {
            this.eventEdit.description['de_DE'] = this.formValidation.value.de_DE !== null ? this.formValidation.value.de_DE.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
        } else {
            this.eventEdit.description['de_DE'] = null
        }

        const auxSDate: Array<string> = this.formValidation.value.startDate.split('-');    // [year, month, day]
        const startDate: number = this.dt.makeDateTp(auxSDate[0], auxSDate[1], auxSDate[2]);

        const auxEDate: Array<string> = this.formValidation.value.endDate.split('-');    // [year, month, day]
        const endDate: number = this.dt.makeDateTp(auxEDate[0], auxEDate[1], auxEDate[2]);

        const currentDate: number = Date.now();

        // start date after closure date    
        if (startDate > endDate) {
            validation = false;
            this.dateError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (startDate < currentDate) {
            validation = false;
            this.startDatePastError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (endDate < currentDate) {
            validation = false;
            this.endDatePastError = true;
            this.loader = false;
        }

        this.eventEdit.startDate = this.dt.dbTime(startDate);
        this.eventEdit.endDate = this.dt.dbTime(endDate);

        // update event
        this.dbEvents.updateEvent(this.eventEdit, (data) => {
            if (data == true) {
                this.successSwal.fire();
                this.loaderBtn = false;
                this.loadEvents();
            } else {
                this.errorSwal.fire();
                this.loaderBtn = false;
            }
        })
    }

    // sort array of languages by placing the main language at the top.
    sortArrayLanguages() {
        const index = this.languages.map(function (e) { return e['value']; }).indexOf(this.eventEdit['language']);

        // put the main language at index zero of the array.
        if (index > 0) {
            const aux = this.languages[0]
            this.languages[0] = this.languages[index]
            this.languages[index] = aux;
        }
    }

    // change the main language of the event
    changePrincipalLanguage() {
        let aux = this.formValidation.value.language['value'];

        // set the primary language
        switch (aux) {
            case 'pt_BR': {
                this.eventEdit['language'] = 'pt_BR'
                this.eventEdit['languages']['PtBR'] = true

                if (!this.formValidation.value.pt_BR) {
                    this.formValidation.value.pt_BR = ''
                }

                break;
            }

            case 'en_US': {
                this.eventEdit['language'] = 'en_US'
                this.eventEdit['languages']['EnUS'] = true

                if (!this.formValidation.value.en_US) {
                    this.formValidation.value.en_US = ''
                }

                break;
            }

            case 'es_ES': {
                this.eventEdit['language'] = 'es_ES'
                this.eventEdit['languages']['EsES'] = true

                if (!this.formValidation.value.es_ES) {
                    this.formValidation.value.es_ES = ''
                }

                break;
            }

            case 'fr_FR': {
                this.eventEdit['language'] = 'fr_FR'
                this.eventEdit['languages']['FrFR'] = true

                if (!this.formValidation.value.fr_FR) {
                    this.formValidation.value.fr_FR = ''
                }

                break;
            }

            case 'de_DE': {
                this.eventEdit['language'] = 'de_DE'
                this.eventEdit['languages']['DeDE'] = true

                if (!this.formValidation.value.de_DE) {
                    this.formValidation.value.de_DE = ''
                }

                break;
            }
        }


        // sort array of languages by placing the main language at the top.
        this.sortArrayLanguages()

        // Checks if multi-language is enabled.
        // If multi-language has been disabled, set all secondary languages to false.
        if (!this.allow_language) {
            if (this.eventEdit['language'] != 'pt_BR') {
                this.eventEdit['languages']['PtBR'] = false // disable the language
            }

            if (this.eventEdit['language'] != 'en_US') {
                this.eventEdit['languages']['EnUS'] = false // disable the language
            }

            if (this.eventEdit['language'] != 'es_ES') {
                this.eventEdit['languages']['EsES'] = false
            }

            if (this.eventEdit['language'] != 'fr_FR') {
                this.eventEdit['languages']['FrFR'] = false
            }

            if (this.eventEdit['language'] != 'de_DE') {
                this.eventEdit['languages']['DeDE'] = false
            }
        }
    }

    // get a language and enable it on the eventEdit.languages attribute
    addLanguageSecondary(language: string) {
        // check language
        switch (language) {
            case 'pt_BR': {
                this.eventEdit.languages.PtBR = true  // enable the language
                this.formValidation.value.pt_BR = '' // enable language description
                break;
            }

            case 'en_US': {
                this.eventEdit.languages.EnUS = true  // enable the language
                this.formValidation.value.en_US = '' // enable language description
                break;
            }

            case 'es_ES': {
                this.eventEdit.languages.EsES = true  // enable the language
                this.formValidation.value.es_ES = ''  // enable language description
                break;
            }

            case 'fr_FR': {
                this.eventEdit.languages.FrFR = true // enable the language
                this.formValidation.value.fr_FR = '' // enable language description
                break;
            }

            case 'de_DE': {
                this.eventEdit.languages.DeDE = true // enable the language
                this.formValidation.value.de_DE = '' // enable language description
                break;
            }
        }
    }

    // get a language and disable it in the eventEdit.languages  attribute
    removeLanguageSecondary(language: string) {
        // check language
        switch (language) {
            case 'pt_BR': {
                this.eventEdit.languages.PtBR = false // disable the language

                // disable language description
                this.formValidation.patchValue({
                    'pt_BR': null
                })

                break;
            }

            case 'en_US': {
                this.eventEdit.languages.EnUS = false // disable the language

                // disable language description
                this.formValidation.patchValue({
                    'en_US': null
                })


                break;
            }

            case 'es_ES': {
                this.eventEdit.languages.EsES = false // disable the language

                // disable language description
                this.formValidation.patchValue({
                    'es_ES': null
                })

                break;
            }

            case 'fr_FR': {
                this.eventEdit.languages.FrFR = false  // disable the language

                // disable language description
                this.formValidation.patchValue({
                    'fr_FR': null
                })

                break;
            }

            case 'de_DE': {
                this.eventEdit.languages.DeDE = false // disable the language

                // disable language description
                this.formValidation.patchValue({
                    'de_DE': null
                })

                break;
            }
        }

    }

    addDescription;
    clearAddDescription() {
        this.newSelectedLanguage = '-1';
    }

    loadPlacesAutocomplete() {
        this.mapsAPILoader.load().then(
            () => {
                setTimeout(() => {
                    let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: ["address"] });
                    autocomplete.addListener("place_changed", () => {
                        this.ngZone.run(() => {
                            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                            if (place.geometry === undefined || place.geometry === null) {
                                return;
                            }
                            this.addressGoogle = place.formatted_address;
                        })
                    })
                }, 1200);
            }
        )
    }

    getLanguage(language) {
        if (language === "pt_BR") {
            return { value: "pt_BR", name: this.translateService.instant('comp.event_info.portuguese') }
        } else if (language === 'en_US') {
            return { value: "en_US", name: this.translateService.instant('comp.event_info.english') }
        } else if (language === "es_ES") {
            return { value: "es_ES", name: this.translateService.instant('comp.event_info.spanish') }
        } else if (language === "fr_FR") {
            return { value: "fr_FR", name: this.translateService.instant('comp.event_info.french') }
        } else if (language === "de_DE") {
            return { valeu: "de_DE", name: this.translateService.instant('comp.event_info.german') }
        }
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }
}
