import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { TypeUser } from '../../enums/typeUser'

@Injectable({
    providedIn: 'root'
})
export class DBCustomFieldsProvider {
    constructor(
        private SAFirestore: AngularFirestore
    ) { }

    public async getCustomFields(
        eventId: string, 
        moduleId: string
    ): Promise<DocumentData[]> {
        const ref = this.SAFirestore.firestore.collection(
            `events/${eventId}/modules/${moduleId}/customFields`
        );
        const result = await ref.orderBy("order").get();
        if (!result) {
            return Promise.reject();
        }
        return Promise.resolve(result.docs.map((doc) => doc.data()));
    }

    public async getUserCfsAnswers(
        eventId: string, 
        userId: string, 
        userType: TypeUser
    ): Promise<DocumentData[]> {
        let path: string;
        switch (userType) {
        case TypeUser.ATTENDEE:
            path = `events/${eventId}/attendees/${userId}/customFields`;
            break;
        case TypeUser.SPEAKER:
            path = `events/${eventId}/speakers/${userId}/customFields`;
            break;
        default:
            return Promise.reject();
        }

        const ref = this.SAFirestore.firestore.collection(path);
        const promise = new Promise<any>((res, rej) => {
            ref.get().then((result) => {
                res(result.docs.map(doc => doc.data()))
            }).catch(error => rej(error))
        });
        return promise;
    }

    /**
     * 
     * @param eventId 
     * @param moduleId 
     * @param customFields array with all custom fields data
     * @returns 
     */
    public updateCustomFieldsOrder(
        eventId: string, 
        moduleId: string, 
        customFields: any[]
    ) {
        const batch = this.SAFirestore.firestore.batch();

        for (const customField of customFields) {
            // const ref1 = this.db.doc(`modules/${moduleId}/customFields/${customField.uid}`);
            const ref2 = this.SAFirestore.firestore.doc(
                `events/${eventId}/modules/${moduleId}/customFields/${customField.uid}`
            );
            // batch.update(ref1, { order: customField.order});
            batch.update(ref2, { order: customField.order });
        }
        return batch.commit();
    }

    async updateCustomField(
        eventId: string,
        moduleId: string,
        customFieldId: string,
        data: any
    ) {
        const batch = this.SAFirestore.firestore.batch();
        const ref = this.SAFirestore.firestore.doc(
            `events/${eventId}/modules/${moduleId}/customFields/${customFieldId}`
        );
        batch.update(ref, data);
        
        return batch.commit();
    }

    /**
     * 
     * @param eventId 
     * @param moduleId 
     * @param customField 
     * @param listOptions 
     * @returns 
     */
    async createCustomField(
        eventId: string, 
        moduleId: string, 
        customField: any
    ) {
        let ref;
        if (!customField.uid) {
            ref = this.SAFirestore.firestore.collection(
                `events/${eventId}/modules/${moduleId}/customFields`
            ).doc();
            customField.uid = ref.id;
        } else {
            ref = this.SAFirestore.firestore.collection(
                `events/${eventId}/modules/${moduleId}/customFields`
            ).doc(customField.uid);
        }
        customField = Object.assign({}, customField);
        customField.name = Object.assign({}, customField.name);

        const promise = new Promise<any>((resolve, reject) => {
            ref.set(customField)
                .then(() => resolve(customField))
                .catch(error => reject(error));
        });
        return promise;
    }

    public async deleteCustomField(
        eventId: string, 
        moduleId: string, 
        customFieldId: string,
        userType: TypeUser
    ) {
        let path;
        switch (userType) {
        case TypeUser.ATTENDEE:
            path = `events/${eventId}/attendees`;
            break;
        case TypeUser.SPEAKER:
            path = `events/${eventId}/speakers`;
            break;
        default:
            return Promise.reject();
        }

        const result = await this.SAFirestore.firestore.collection(path).get();
        if (!result) { return Promise.reject(); }

        const ids = result.docs.map(doc => doc.id);

        const batch = this.SAFirestore.firestore.batch();
        let ref = this.SAFirestore.firestore.doc(
            `events/${eventId}/modules/${moduleId}/customFields/${customFieldId}`
        );
        batch.delete(ref);

        ids.forEach((id) => {
            ref = this.SAFirestore.firestore.doc(
                `${path}/${id}/customFields/${customFieldId}`
            );
            batch.delete(ref)
        });

        return batch.commit();
    }
}