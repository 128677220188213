export class WidgetIcons {
    public name: string;
    public path: string;
    public changeColor: boolean;
    public position: string;
}

// let totalSize = 77;
// let aux: WidgetIcons[];
// for (let i = 0; i < totalSize; i++) {
//     aux.push({
//         name: 'icon ' + i,
//         path: i + '.svg'
//     });
// }

export const icons: WidgetIcons[] = [
    { name: "icon 1", path: "icon-01.svg", changeColor: true, position: '' },
    { name: "icon 2", path: "icon-02.svg", changeColor: true, position: '' },
    { name: "icon 3", path: "icon-03.svg", changeColor: true, position: '' },
    { name: "icon 4", path: "icon-04.svg", changeColor: true, position: '' },
    { name: "icon 5", path: "icon-05.svg", changeColor: true, position: '' },
    { name: "icon 6", path: "icon-06.svg", changeColor: true, position: '' },
    { name: "icon 7", path: "icon-07.svg", changeColor: true, position: '' },
    { name: "icon 8", path: "icon-08.svg", changeColor: true, position: '' },
    { name: "icon 9", path: "icon-09.svg", changeColor: true, position: '' },
    { name: "icon 10", path: "icon-10.svg", changeColor: true, position: '' },
    { name: "icon 11", path: "icon-11.svg", changeColor: true, position: '' },
    { name: "icon 12", path: "icon-12.svg", changeColor: true, position: '' },
    { name: "icon 13", path: "icon-13.svg", changeColor: true, position: '' },
    { name: "icon 14", path: "icon-14.svg", changeColor: true, position: '' },
    { name: "icon 15", path: "icon-15.svg", changeColor: true, position: '' },
    { name: "icon 16", path: "icon-16.svg", changeColor: true, position: '' },
    { name: "icon 17", path: "icon-17.svg", changeColor: true, position: '' },
    { name: "icon 18", path: "icon-18.svg", changeColor: true, position: '' },
    { name: "icon 19", path: "icon-19.svg", changeColor: true, position: '' },
    { name: "icon 20", path: "icon-20.svg", changeColor: true, position: '' },
    { name: "icon 21", path: "icon-21.svg", changeColor: true, position: '' },
    { name: "icon 22", path: "icon-22.svg", changeColor: true, position: '' },
    { name: "icon 23", path: "icon-23.svg", changeColor: true, position: '' },
    { name: "icon 24", path: "icon-24.svg", changeColor: true, position: '' },
    { name: "icon 25", path: "icon-25.svg", changeColor: true, position: '' },
    { name: "icon 26", path: "icon-26.svg", changeColor: true, position: '' },
    { name: "icon 27", path: "icon-27.svg", changeColor: true, position: '' },
    { name: "icon 28", path: "icon-28.svg", changeColor: true, position: '' },
    { name: "icon 29", path: "icon-29.svg", changeColor: true, position: '' },
    { name: "icon 30", path: "icon-30.svg", changeColor: true, position: '' },
    { name: "icon 31", path: "icon-31.svg", changeColor: true, position: '' },
    { name: "icon 32", path: "icon-32.svg", changeColor: true, position: '' },
    { name: "icon 33", path: "icon-33.svg", changeColor: true, position: '' },
    { name: "icon 34", path: "icon-34.svg", changeColor: true, position: '' },
    { name: "icon 35", path: "icon-35.svg", changeColor: true, position: '' },
    { name: "icon 36", path: "icon-36.svg", changeColor: true, position: '' },
    { name: "icon 37", path: "icon-37.svg", changeColor: true, position: '' },
    { name: "icon 38", path: "icon-38.svg", changeColor: true, position: '' },
    { name: "icon 39", path: "icon-39.svg", changeColor: true, position: '' },
    { name: "icon 40", path: "icon-40.svg", changeColor: true, position: '' },
    { name: "icon 41", path: "icon-41.svg", changeColor: true, position: '' },
    { name: "icon 42", path: "icon-42.svg", changeColor: true, position: '' },
    { name: "icon 43", path: "icon-43.svg", changeColor: true, position: '' },
    { name: "icon 44", path: "icon-44.svg", changeColor: true, position: '' },
    { name: "icon 45", path: "icon-45.svg", changeColor: true, position: '' },
    { name: "icon 46", path: "icon-46.svg", changeColor: true, position: '' },
    { name: "icon 47", path: "icon-47.svg", changeColor: true, position: '' },
    { name: "icon 48", path: "icon-48.svg", changeColor: true, position: '' },
    { name: "icon 49", path: "icon-49.svg", changeColor: true, position: '' },
    { name: "icon 50", path: "icon-50.svg", changeColor: true, position: '' },
    { name: "icon 51", path: "icon-51.svg", changeColor: true, position: '' },
    { name: "icon 52", path: "icon-52.svg", changeColor: true, position: '' },
    { name: "icon 53", path: "icon-53.svg", changeColor: true, position: '' },
    // { name: "icon 54", path: "icon-54.svg" , changeColor: tru, position: ''e},
    // { name: "icon 55", path: "icon-55.svg" , changeColor: tru, position: ''e},
    // { name: "icon 56", path: "icon-56.svg" , changeColor: tru, position: ''e},
    // { name: "icon 57", path: "icon-57.svg" , changeColor: tru, position: ''e},
    // { name: "icon 58", path: "icon-58.svg" , changeColor: tru, position: ''e},
    // { name: "icon 59", path: "icon-59.svg" , changeColor: tru, position: ''e},
    // { name: "icon 60", path: "icon-60.svg" , changeColor: tru, position: ''e},
    // { name: "icon 61", path: "icon-61.svg" , changeColor: tru, position: ''e},
    // { name: "icon 62", path: "icon-62.svg" , changeColor: tru, position: ''e},
    // { name: "icon 63", path: "icon-63.svg" , changeColor: tru, position: ''e},
    // { name: "icon 64", path: "icon-64.svg" , changeColor: tru, position: ''e},
    // { name: "icon 65", path: "icon-65.svg" , changeColor: tru, position: ''e},
    // { name: "icon 66", path: "icon-66.svg" , changeColor: tru, position: ''e},
    // { name: "icon 67", path: "icon-67.svg" , changeColor: tru, position: ''e},
    // { name: "icon 68", path: "icon-68.svg" , changeColor: tru, position: ''e},
    // { name: "icon 69", path: "icon-69.svg" , changeColor: tru, position: ''e},
    // { name: "icon 70", path: "icon-70.svg" , changeColor: tru, position: ''e},
    // { name: "icon 71", path: "icon-71.svg" , changeColor: tru, position: ''e},
    // { name: "icon 72", path: "icon-72.svg" , changeColor: tru, position: ''e},
    // { name: "icon 73", path: "icon-73.svg" , changeColor: tru, position: ''e},
    // { name: "icon 74", path: "icon-74.svg" , changeColor: tru, position: ''e},
    // { name: "icon 75", path: "icon-75.svg" , changeColor: tru, position: ''e},
    // { name: "icon 76", path: "icon-76.svg" , changeColor: tru, position: ''e},
    // { name: "icon 77", path: "icon-77.svg" }
]