import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { DbCheckinProvider } from 'src/app/providers/database/db-checkin';
import { ActivatedRoute } from '@angular/router';
import { Attendee } from 'src/app/models/attendees';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { Checkin } from 'src/app/models/checkin';
import { OrderType } from 'src/app/paths/orderType';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';
import { TypeVisionCheckin } from 'src/app/enums/type-vision-checkin';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
declare let $: any;

@Component({
  selector: 'app-checkin-status',
  templateUrl: './checkin-status.component.html',
  styleUrls: ['./checkin-status.component.scss'],
  providers: [DbCheckinProvider]
})

export class CheckinStatusComponent implements OnInit {
  // get the language of the user.
  public userLanguage: string

  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  eventId: string = null;
  moduleId: string = null;
  checkinId: string = null;
  attendees: Array<Attendee> = [];
  allAttendees: Array<Attendee> = [];
  loaderStatus: boolean = true;
  changeActive: boolean = false;
  pP: number = 1;
  termS;
  groupFilter: string = 'all';
  moduleFilter: any = 'all';
  listGroup = [];
  moduleGroupId: string;
  listModule = [];
  typeOrder: string = null;
  typeVision: number = TypeVisionCheckin.GLOBAL_VISION
  groups = {}

  refCheckin: any = null;
  checkinAttendeesCollection: AngularFirestoreCollection<any> = null;

  constructor(
    private location: Location,
    private dbCheckin: DbCheckinProvider,
    private route: ActivatedRoute,
    private dbGroups: DbGroupsProvider,
    private afs: AngularFirestore,
    private global: GlobalService,
    private dbEvent: DbEventsProvider,
    private dbAttendee: DbAttendeesProvider,
    public formatedLanguage: FormatedEventLanguageService
  ) {
    this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
    this.moduleId = this.route.parent.params['_value']['moduleId'];
    this.checkinId = this.route.snapshot.params['checkinId'];
  }

  ngOnInit() {
    this.getUserLanguage()
    this.getCheckin()
    this.getEvent()
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event: Event) => {
      if (typeof event !== 'undefined' && event !== null) {
        this.formatedLanguage.convertLangFormat(event.language);
        this.getModulesAttendees()
      }
    });
  }


  /** 
  *get all event participant modules.
  *@param eventId
  *@returns onResolve
  */
  getModulesAttendees() {
    this.dbAttendee.getModulesAttendeeEvent(this.eventId, (modules) => {
      this.listModule = []

      // sort the name of the modules.
      const language = this.formatedLanguage.language
      this.listModule = modules.sort(function (a, b) {
        if (a['name'][`${language}`] < b['name'][`${language}`]) { return -1; }
        if (a['name'][`${language}`] > b['name'][`${language}`]) { return 1; }
        return 0;
      });
    })
  }

  /** 
  *Loads check-in attendees.
  */
  getAttendees() {
    this.dbCheckin.getAttendeesWithCheckin(
      this.eventId,
      this.moduleId,
      this.checkinId,
      this.typeOrder,
      this.typeVision,
      this.groups,
      (attendees: Array<Attendee>) => {
        this.attendees = [];
        this.allAttendees = [];

        for (let attendee of attendees) {
          let groups = [];
          for (const uid in attendee['groups']) {
            groups.push(attendee['groups'][uid]);
          }
          attendee['groups'] = groups;

          this.attendees.push(attendee);
          this.allAttendees.push(attendee);
        }

        this.loaderStatus = false;
      });
  }

  // get checkin.
  getCheckin() {
    this.dbCheckin.getCheckin(this.moduleId, this.checkinId, (checkin: Checkin) => {
      this.typeOrder = checkin.typeOrder;

      if (checkin.typeVisionListing) this.typeVision = checkin.typeVisionListing;
      if (checkin.groups) this.groups = checkin.groups;
      if (this.typeOrder !== null) this.getAttendees();

      // load groups
      if (this.typeVision === TypeVisionCheckin.GLOBAL_VISION) {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
          $(document).on('click', ' .dropdown-menu', function (e) {
            e.stopPropagation();
          });
          this.listGroup = [];
          this.listGroup = result['groups'];
          this.moduleGroupId = result['moduleId'];
        });
      } else {
        this.listGroup = []

        for (const uid in checkin.groups) {
          const group = checkin.groups[uid]
          this.moduleGroupId = group.moduleId
          this.listGroup.push(group)
        }
      }
    })
  }


  filterAttendeeGroup() {
    if (this.groupFilter == 'all') {
      this.attendees = this.allAttendees;
    } else {
      this.attendees = [];
      for (let attendee of this.allAttendees) {
        if (attendee['groups'] !== undefined) {
          for (let group of attendee['groups']) {
            if (group.uid == this.groupFilter) {
              this.attendees.push(attendee);
            }
          }
        }
      }
    }
  }

  filterAttendeeModule() {
    if (this.moduleFilter == 'all') {
      this.attendees = this.allAttendees;
    } else {
      this.attendees = [];
      for (let attendee of this.allAttendees) {
        if (attendee['module'].uid == this.moduleFilter) {
          this.attendees.push(attendee);
        }
      }
    }
  }

  /** 
    *Sorts the list of participants.
  */

  changeOrder() {
    let aux = this.attendees;
    let auxOrder;
    this.dbCheckin.changeAttendeeOrderInCheckin(this.moduleId, this.checkinId, this.typeOrder);

    switch (this.typeOrder) {
      case OrderType.A_Z:
        this.attendees = [];
        this.attendees = aux.sort(function (a, b) {
          if (a['queryName'] < b['queryName']) { return -1; }
          if (a['queryName'] > b['queryName']) { return 1; }
          return 0;
        });
        break;

      case OrderType.Z_A:
        this.attendees = [];
        this.attendees = aux.sort(function (a, b) {
          if (a['queryName'] < b['queryName']) { return 1; }
          if (a['queryName'] > b['queryName']) { return -1; }
          return 0;
        })
        break;

      case 'present':
        this.attendees = [];
        this.attendees = aux.sort(function (a, b) {
          if (a['queryName'] < b['queryName']) { return -1; }
          if (a['queryName'] > b['queryName']) { return 1; }
          return 0;
        });
        auxOrder = this.attendees;
        this.attendees = [];
        this.attendees = auxOrder.sort(function (a, b) {
          if (a['checkinStatus'] == true && b['checkinStatus'] == false) { return -1; }
          if (a['checkinStatus'] == false && b['checkinStatus'] == true) { return 1; }
          return 0;
        });

        break;
      case 'away':
        this.attendees = [];
        this.attendees = aux.sort(function (a, b) {
          if (a['queryName'] < b['queryName']) { return -1; }
          if (a['queryName'] > b['queryName']) { return 1; }
          return 0;
        });
        auxOrder = this.attendees;
        this.attendees = [];
        this.attendees = auxOrder.sort(function (a, b) {
          if (a['checkinStatus'] == false && b['checkinStatus'] == true) { return -1; }
          if (a['checkinStatus'] == true && b['checkinStatus'] == false) { return 1; }
          return 0;
        });
        break;
    }
  }

  back() {
    this.location.back();
  }



  /** 
    *change attendee Checkin status.
    *@param attendee
    *@param moduleId
    *@param status
  */

  changeUserCheckin(attendee: any, moduleId: string, status: boolean) {
    this.changeActive = true;
    const userId = attendee.uid

    this.dbCheckin.changeUserCheckin(this.moduleId, this.checkinId, userId, moduleId, this.eventId, status, (check) => {
      if (check) {
        attendee.checkinStatus = status
        this.changeOrder()
      } else {
        this.errorSwal.fire();
      }

      this.changeActive = false;
    });
  }

}
