import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})

export class DbInfoboothProvider {
    private db: firebase.firestore.Firestore

    constructor() {
        this.db = firebase.firestore()
    }

    getModule(moduleId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId);

        ref
            .get()
            .then((result) => {
                onResolve(result.data());
            });
    }

    // load the infobooth module page
    getPageModule(moduleId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId).collection('pages')

        ref.get().then((snapshot) => {
            let page = null

            snapshot.forEach((childSnapshot) => {
                page = childSnapshot.data()
            })

            onResolve(page)
        })
    }

    // Refresh infobooth module page
    updatePage(page) {
        return new Promise((resolve, reject) => {
            const pageId = page.uid
            const moduleId = page.moduleId
            const eventId = page.eventId

            const batch = this.db.batch()

            const ref1 = this.db.collection('events')
                .doc(eventId)
                .collection('modules')
                .doc(moduleId)
                .collection('pages')
                .doc(pageId)
            
            const ref2 = this.db.collection('modules')
                .doc(moduleId)
                .collection('pages')
                .doc(pageId)

            batch.update(ref1, Object.assign({}, page))
            batch.update(ref2, Object.assign({}, page))

            batch.commit().then(() => {
                resolve(true)
            }).catch((error) => {
                console.error(error)
                reject(false)
            })
        })
    }

}