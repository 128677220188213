import { NameModule } from "./../../../../../models/name-module";
import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { eventCustomField } from "./../../../../../models/eventCustomField";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormArray,
    FormControl,
} from "@angular/forms";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";

// models
import { Session } from "src/app/models/session";
import { Location } from "src/app/models/location";
import { Event } from "src/app/models/event";

// providers
import { DbScheduleProvider } from "src/app/providers/database/db-schedule";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DbLocationsProvider } from "src/app/providers/database/db-locations";
import { LuxonService } from "src/app/providers/luxon/luxon.service";
import { Track } from "src/app/models/track";
import { Group } from "src/app/models/group";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { DbSpeakersProvider } from "src/app/providers/database/db-speakers";
import { Speaker } from "src/app/models/speakers";
import { DbDocuments } from "src/app/providers/database/db-documents";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "src/app/providers/global/global.service";
import { WherebyService } from "src/app/providers/whereby/whereby.service";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";

import * as moment from "moment";

import { videoPlayersTypes } from "../../../../../models/video-players-types";
import { IDate } from "src/app/shared/interfaces/date.interfaces";
import { IntegrationsService } from "src/app/providers/integrations/integrations.service";
import {
    IIntegration,
    IZoom,
} from "src/app/shared/interfaces/integrations.interface";
import { from } from "rxjs";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";

@Component({
    selector: "app-edit-schedule",
    templateUrl: "./edit-schedule.component.html",
    styleUrls: ["./edit-schedule.component.scss"],
})
export class EditScheduleComponent implements OnInit {
    public eventId: string = this.route.parent.params["_value"]["uid"];
    public moduleId: string = this.route.snapshot.params["moduleId"];
    public sessionId: string = this.route.snapshot.params["scheduleId"];

    module: ModuleSchedule;

    formValidation: FormGroup;
    public languages = [];
    loader;

    @ViewChild("successSwal") public successSwal: SwalComponent;

    // automcomplete locations
    listLocations: Array<Location>;
    selectedLocations: Array<Location> = [];
    filteredListLocations = [];
    queryLocation = "";

    // automcomplete tracks
    listTracks: Array<Track>;
    selectedTracks: Array<Track> = [];
    filteredListTracks = [];
    queryTrack = "";
    errorFormTrack: boolean = false;

    createSessionError;
    errorFormLocation;
    timeError;

    identifier = 0;

    // GROUPS FILTER
    listGroup: Array<Group>;
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = "";
    errorFormGroup: boolean = false;

    // GROUPS visio FILTER

    listGroupVisio: Array<Group>;
    selectedGroupVisio: Array<Group> = [];
    filteredListGroupsVisio = [];
    queryGroupVisio = "";
    errorFormGroupVisio: boolean = false;
    showGroupVisio: boolean = false;

    // SPEAKERS FILTER
    listSpeaker: Array<Speaker>;
    selectedSpeaker = [];
    filteredListSpeakers = [];
    querySpeaker = "";
    errorFormSpeaker: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<Speaker>;
    selectedDocument = [];
    filteredListDocuments = [];
    queryDocument = "";
    errorFormDocument: boolean = false;

    // CUSTOM FIELDS
    listCustomFields: any[] = [];
    selectedCustomFields = [];
    filteredCustomFields = [];
    queryCustomFields = "";
    errorFormCustomFields: boolean = false;

    sessionEdit: Session = new Session();
    public event: Event;
    principalEventLangFormated: string = "PtBR";
    public userLanguage: string; // get the language of the user.

    datePast: boolean = false; //Boolean variable that checks if the session date is in the past

    // WHEREBY
    visioForm: FormGroup;
    wherebyError: string[] = [];
    activationVisioUpdated: boolean = false;

    playersTypes: string[] = videoPlayersTypes;
    errorFormVideoUrl: boolean = false;

    // Integrations
    integration: IIntegration;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dbSchedule: DbScheduleProvider,
        private dbLocation: DbLocationsProvider,
        private dbEvent: DbEventsProvider,
        private luxon: LuxonService,
        private dbGroups: DbGroupsProvider,
        private dbSpeaker: DbSpeakersProvider,
        private dbDocuments: DbDocuments,
        private translateService: TranslateService,
        private global: GlobalService,
        private SWhereby: WherebyService,
        private SIntegrations: IntegrationsService,
        private afs: AngularFirestore,
        private dt: DateTimeService
    ) {
        this.loader = true;
        this.createSessionError = false;
        this.errorFormLocation = false;
        this.errorFormSpeaker = false;
        this.timeError = false;
        this.datePast = false;

        this.visioForm = this.fb.group({
            type: new FormControl("whereby"),
            name: new FormControl(""),
            url: new FormControl(""),
            hostUrl: new FormControl(""),
            startDate: new FormControl(moment().toISOString().split("T")[0]),
            endDate: new FormControl(
                moment().add(1, "year").toISOString().split("T")[0]
            ),
            mode: new FormControl("group"),
            zoomIntegrationId: new FormControl(null),
            allowChatOnVisio: new FormControl(true),
            allowScreenShareOnVisio: new FormControl(true),
            allowLeaveOnVisio: new FormControl(true),
            activated: new FormControl(true),
        });

        // languages
        this.languages = [
            {
                value: "pt_BR",
                name: this.translateService.instant(
                    "comp.event_info.portuguese"
                ),
            },
            {
                value: "en_US",
                name: this.translateService.instant("comp.event_info.english"),
            },
            {
                value: "es_ES",
                name: this.translateService.instant("comp.event_info.spanish"),
            },
            {
                value: "fr_FR",
                name: this.translateService.instant("comp.event_info.french"),
            },
            {
                value: "de_DE",
                name: this.translateService.instant("comp.event_info.german"),
            },
        ];

        this.formValidation = fb.group({
            date: [null, Validators.compose([Validators.required])],
            locations: [null],
            startTime: [
                this.minDate("start"),
                Validators.compose([Validators.required]),
            ],
            finalTime: [this.minDate("end")],
            speakers: [null],
            tracks: [null],
            groups: [null],
            documents: [null],
            customFields: [null],
            totalAttendees: [0],
            videoPlayerType: [""],
            videoPlayerUrl: [""],
            descriptionlanguages_de_DE: [""],
            descriptionlanguages_en_US: [""],
            descriptionlanguages_es_ES: [""],
            descriptionlanguages_fr_FR: [""],
            descriptionlanguages_pt_BR: [""],
        });

        this.getScheduleModule();

        this.dbSchedule.getSession(
            this.sessionId,
            this.eventId,
            (sessionEdit: Session) => {
                this.loader = false;

                if (
                    typeof sessionEdit !== "undefined" &&
                    sessionEdit !== null
                ) {
                    this.sessionEdit = sessionEdit;
                    this.identifier = sessionEdit.identifier;
                    if (this.sessionEdit.visio) {
                        this.visioForm.patchValue({
                            type: this.sessionEdit.visio.type
                                ? this.sessionEdit.visio.type
                                : "whereby",
                            name:
                                this.sessionEdit.visio.type == "zoom"
                                    ? this.sessionEdit.visio.roomName
                                    : "",
                            url: this.sessionEdit.visio.roomUrl
                                ? this.sessionEdit.visio.roomUrl
                                : "",
                            hostUrl: this.sessionEdit.visio.hostRoomUrl
                                ? this.sessionEdit.visio.hostRoomUrl
                                : "",
                            mode: this.sessionEdit.visio.roomMode
                                ? this.sessionEdit.visio.roomMode
                                : "normal",
                            startDate: this.sessionEdit.visio.startDate
                                ? this.sessionEdit.visio.startDate.split("T")[0]
                                : moment().toISOString().split("T")[0],
                            endDate: this.sessionEdit.visio.endDate
                                ? this.sessionEdit.visio.endDate.split("T")[0]
                                : moment()
                                      .add(1, "year")
                                      .toISOString()
                                      .split("T")[0],
                            zoomIntegrationId:
                                this.sessionEdit.visio.zoomIntegrationId &&
                                this.sessionEdit.visio.zoomIntegrationId
                                    ? this.sessionEdit.visio.zoomIntegrationId
                                    : null,
                            allowChatOnVisio: this.sessionEdit.visio
                                ? this.sessionEdit.visio.allowChatOnVisio
                                : true,
                            allowScreenShareOnVisio: this.sessionEdit.visio
                                ? this.sessionEdit.visio.allowScreenShareOnVisio
                                : true,
                            allowLeaveOnVisio: this.sessionEdit.visio
                                ? this.sessionEdit.visio.allowLeaveOnVisio
                                : true,
                            activated: this.sessionEdit.visio
                                ? this.sessionEdit.visio.activated
                                : true,
                        });
                    }
                    

                    // init formValidation
                    this.formValidation.patchValue({
                        date: (typeof this.sessionEdit.date === "string" && this.sessionEdit.date !== "") ? 
                            this.sessionEdit.date.substring(0, 10) :
                            "",

                        startTime: this.dt.getTime(this.dt.convertTimeZone(sessionEdit.startTime, this.dt.deviceTimeZone, "en-US"), true),
                        finalTime: (sessionEdit.endTime == undefined || sessionEdit.endTime == "") ? "" : 
                            this.dt.getTime(this.dt.convertTimeZone(sessionEdit.endTime, this.dt.deviceTimeZone, "en-US"), true),
                        
                        totalAttendees: this.sessionEdit.limitAttendees,
                        videoPlayerType:
                            this.sessionEdit.videoPlayer &&
                            this.sessionEdit.videoPlayer.type
                                ? this.sessionEdit.videoPlayer.type
                                : "disabled",
                        videoPlayerUrl:
                            this.sessionEdit.videoPlayer &&
                            this.sessionEdit.videoPlayer.url
                                ? this.sessionEdit.videoPlayer.url
                                : "",
                        descriptionlanguages_de_DE:
                            this.sessionEdit.descriptions.DeDE,
                        descriptionlanguages_en_US:
                            this.sessionEdit.descriptions.EnUS,
                        descriptionlanguages_es_ES:
                            this.sessionEdit.descriptions.EsES,
                        descriptionlanguages_fr_FR:
                            this.sessionEdit.descriptions.FrFR,
                        descriptionlanguages_pt_BR:
                            this.sessionEdit.descriptions.PtBR,
                    });

                    this.global.getLanguage((language) => {
                        this.userLanguage = language;
                        this.principalEventLangFormated =
                            this.convertLangFormat(this.userLanguage);
                    });

                    // get event
                    this.dbEvent.getEvent(this.eventId, (event: Event) => {
                        this.event = event;

                        // sort array of languages by placing the main language at the top.
                        this.sortArrayLanguages();

                        // Initializes the formControl of session titles.
                        if (this.event.languages.PtBR) {
                            this.formValidation.addControl(
                                "title_pt_br",
                                new FormControl(
                                    this.sessionEdit.name.PtBR,
                                    Validators.compose([
                                        Validators.required,
                                        Validators.maxLength(1000),
                                    ])
                                )
                            );
                        } else {
                            this.formValidation.addControl(
                                "title_pt_br",
                                new FormControl(this.sessionEdit.name.PtBR)
                            );
                        }

                        if (this.event.languages.EnUS) {
                            this.formValidation.addControl(
                                "title_en_us",
                                new FormControl(
                                    this.sessionEdit.name.EnUS,
                                    Validators.compose([
                                        Validators.required,
                                        Validators.maxLength(1000),
                                    ])
                                )
                            );
                        } else {
                            this.formValidation.addControl(
                                "title_en_us",
                                new FormControl(this.sessionEdit.name.EnUS)
                            );
                        }

                        if (this.event.languages.EsES) {
                            this.formValidation.addControl(
                                "title_es_es",
                                new FormControl(
                                    this.sessionEdit.name.EsES,
                                    Validators.compose([
                                        Validators.required,
                                        Validators.maxLength(1000),
                                    ])
                                )
                            );
                        } else {
                            this.formValidation.addControl(
                                "title_es_es",
                                new FormControl(this.sessionEdit.name.EsES)
                            );
                        }

                        if (this.event.languages.FrFR) {
                            this.formValidation.addControl(
                                "title_fr_fr",
                                new FormControl(
                                    this.sessionEdit.name.FrFR,
                                    Validators.compose([
                                        Validators.required,
                                        Validators.maxLength(1000),
                                    ])
                                )
                            );
                        } else {
                            this.formValidation.addControl(
                                "title_fr_fr",
                                new FormControl(this.sessionEdit.name.FrFR)
                            );
                        }

                        if (this.event.languages.DeDE) {
                            this.formValidation.addControl(
                                "title_de_de",
                                new FormControl(
                                    this.sessionEdit.name.DeDE,
                                    Validators.compose([
                                        Validators.required,
                                        Validators.maxLength(1000),
                                    ])
                                )
                            );
                        } else {
                            this.formValidation.addControl(
                                "title_de_de",
                                new FormControl(this.sessionEdit.name.DeDE)
                            );
                        }
                    });

                    // locations
                    this.selectedLocations = [];
                    for (const uid in sessionEdit.locations) {
                        this.selectedLocations.push(sessionEdit.locations[uid]);
                    }

                    // sort by the order field.
                    this.selectedLocations.sort(function (a, b) {
                        return a.order - b.order;
                    });

                    // tracks
                    this.selectedTracks = [];
                    for (const uid in sessionEdit.tracks) {
                        this.selectedTracks.push(sessionEdit.tracks[uid]);
                    }

                    // groups
                    this.selectedGroup = [];
                    for (const uid in sessionEdit.groups) {
                        this.selectedGroup.push(sessionEdit.groups[uid]);
                    }

                    // speakers
                    this.selectedSpeaker = [];
                    for (const uid in sessionEdit.speakers) {
                        this.selectedSpeaker.push(sessionEdit.speakers[uid]);
                    }

                    // documents
                    this.selectedDocument = [];
                    for (const uid in this.sessionEdit.documents) {
                        this.selectedDocument.push(
                            this.sessionEdit.documents[uid]
                        );
                    }

                    // custom fields
                    this.selectedCustomFields = [];
                    for (const uid in this.sessionEdit.customFields) {
                        this.selectedCustomFields.push(
                            this.sessionEdit.customFields[uid]
                        );
                    }
                }
            }
        );

        this.dbLocation.getLocationsEvent(this.eventId, "asc")
            .then((locations) => { this.listLocations = locations; });

        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = [];
            this.listGroup = result["groups"];
            this.listGroupVisio = [];
            this.listGroupVisio = result["groups"];
            if (
                this.sessionEdit.visio &&
                this.sessionEdit.visio.groupsVisioHost
            ) {
                let uids = Object.keys(this.sessionEdit.visio.groupsVisioHost);

                if (uids && uids.length > 0) {
                    uids.forEach((uid) => {
                        let group = this.listGroupVisio.find(
                            (group) => group.uid == uid
                        );

                        if (group) {
                            this.selectedGroupVisio.push(group);
                        }
                    });
                }
            }
        });

        this.dbSchedule.getModuleTracks(
            this.moduleId,
            (tracks: Array<Track>) => {
                this.listTracks = [];
                this.listTracks = tracks;
            }
        );

        this.dbSpeaker.getSpeakersEvent(
            this.eventId,
            (speakers: Array<Speaker>) => {
                this.listSpeaker = [];
                this.listSpeaker = speakers;
            }
        );

        this.dbDocuments.getEventDocuments(this.eventId, (documents) => {
            this.listDocument = [];
            this.listDocument = documents;
        });

        this.SIntegrations.getIntegration(this.eventId).subscribe(
            (integration) => {
                this.integration = integration;
            }
        );

        this.getCustomFieldsFromModule((customs) => {
            if (customs) {
                this.listCustomFields = this.listCustomFields;
                console.log(this.listCustomFields);
            }
        });
    }

    ngOnInit() {}

    /**
     * Min date for datepicker
     */
    minDate(startEnd: string) {
        return startEnd == "start"
            ? moment().format("YYYY-MM-DD")
            : moment().add(1, "day").format("YYYY-MM-DD");
    }

    /**
     * Getting schedule module
     */
    getScheduleModule() {
        this.dbSchedule.getScheduleModule(
            this.moduleId,
            (module: ModuleSchedule) => {
                this.module = module;
            }
        );
    }

    getCustomFieldsFromModule(onResolve) {
        const customFieldsRef = this.afs.collection("modules", (ref) =>
            ref.where("eventId", "==", this.eventId)
        );
        let customFieldsArray = [];

        customFieldsRef.get().subscribe(async (modules) => {
            if (modules.docs.length > 0) {
                let i = 0;
                modules.forEach(async (module: any) => {
                    let customPromise;

                    if (module.exists) {
                        let customs = [];
                        customPromise = new Promise((resolve, reject) => {
                            this.afs
                                .collection("modules")
                                .doc(module.data().uid)
                                .collection("customFields")
                                .get()
                                .subscribe((customFields) => {
                                    if (customFields.docs.length > 0) {
                                        customFields.forEach((customField) => {
                                            customs.push(customField.data());
                                        });
                                        if (customs.length > 0) {
                                            resolve(customs);
                                        } else {
                                            reject(
                                                "Erreur : no collection match to the query !"
                                            );
                                        }
                                    }
                                });
                        });
                    }
                    const temp = await customPromise;
                    // console.log(temp)
                    if (temp.length > 0) {
                        this.listCustomFields.push(temp);
                    }
                });
                onResolve(this.listCustomFields);
            }
        });
    }

    getAllAttendees() {}
    // sort array of languages by placing the main language at the top.
    sortArrayLanguages() {
        const index = this.languages
            .map(function (e) {
                return e.value;
            })
            .indexOf(this.event.language);

        // put the main language at index zero of the array.
        if (index > 0) {
            const aux = this.languages[0];
            this.languages[0] = this.languages[index];
            this.languages[index] = aux;
        }
    }

    /**
     * Get zoom integration
     * @param integrationId
     */
    getZoomIntegrationById(integrationId: string): IZoom {
        let zoomIntegration = this.integration.zoom.filter(
            (zoom) => zoom.id == integrationId
        );
        return zoomIntegration ? zoomIntegration[0] : null;
    }

    /**
     * Update session
     * @param form
     */
    async updateSession(form) {
        if (!this.event) {
            this.createSessionError = true;
            return;
        }

        let validation = true;

        this.createSessionError = false;
        this.errorFormLocation = false;
        this.errorFormTrack = false;
        this.errorFormSpeaker = false;
        this.errorFormDocument = false;
        this.errorFormVideoUrl = false;
        this.timeError = false;
        this.datePast = false;

        this.loader = true;

        const auxDate = form.date.split("-");   // [year, month, day]
        this.sessionEdit.date = this.dt.createDateInTZ(
            auxDate[0], 
            auxDate[1], 
            auxDate[2],
            "00", 
            "00",
            this.event.timezone
        ).toISO();

        // this.sessionEdit.newDate = {
        //     day: parseInt(auxDate[2]),
        //     month: parseInt(auxDate[1]),
        //     year: parseInt(auxDate[0]),
        //     hour: 0,
        //     minute: 0,
        //     second: 0,
        //     millisecond: 0,
        //     zone: "local",
        // };

        const auxSTime: string[] = form.startTime.split(":");   // [hours, minutes, seconds]
        const startTime: number = this.dt.makeDateTp(auxDate[0], auxDate[1], auxDate[2], auxSTime[0], auxSTime[1], auxSTime[2]);
        this.sessionEdit.startTime = this.dt.createDateInTZ(
            auxDate[0], 
            auxDate[1], 
            auxDate[2],
            auxSTime[0], 
            auxSTime[1],
            this.event.timezone
        ).toISO();

        // this.sessionEdit.newStartTime = {
        //     day: parseInt(auxDate[2]),
        //     month: parseInt(auxDate[1]),
        //     year: parseInt(auxDate[0]),
        //     hour: parseInt(auxSTime[0]),
        //     minute: parseInt(auxSTime[1]),
        //     second: 0,
        //     millisecond: 0,
        //     zone: "local"
        // };

        // if the end time is filled.
        if (form.finalTime !== null && form.finalTime !== "") {
            const auxETime: string[] = form.finalTime.split(":");   // [hours, minutes, seconds]
            const endTime: number = this.dt.makeDateTp(auxDate[0], auxDate[1], auxDate[2], auxETime[0], auxETime[1], auxETime[2]);

            if (endTime < startTime) {
                validation = false;
                this.loader = false;
                this.timeError = true;
            }

            this.sessionEdit.endTime = this.dt.createDateInTZ(
                auxDate[0], 
                auxDate[1], 
                auxDate[2],
                auxETime[0], 
                auxETime[1],
                this.event.timezone
            ).toISO();

            // this.sessionEdit.newEndTime = {
            //     day: parseInt(auxDate[2]),
            //     month: parseInt(auxDate[1]),
            //     year: parseInt(auxDate[0]),
            //     hour: parseInt(auxETime[0]),
            //     minute: parseInt(auxETime[1]),
            //     second: 0,
            //     millisecond: 0,
            //     zone: "local"
            // };

        } 
        // else {
        //     this.sessionEdit.newEndTime = null;
        // }

        // titles
        if (
            typeof form.title_pt_br !== "undefined" &&
            form.title_pt_br !== null
        ) {
            this.sessionEdit.name.PtBR = form.title_pt_br;
        } else {
            this.sessionEdit.name.PtBR = form.title_pt_br;
        }

        if (
            typeof form.title_en_us !== "undefined" &&
            form.title_en_us !== null
        ) {
            this.sessionEdit.name.EnUS = form.title_en_us;
        } else {
            this.sessionEdit.name.EnUS = form.title_en_us;
        }

        if (
            typeof form.title_es_es !== "undefined" &&
            form.title_es_es !== null
        ) {
            this.sessionEdit.name.EsES = form.title_es_es;
        } else {
            this.sessionEdit.name.EsES = form.title_es_es;
        }

        if (
            typeof form.title_fr_fr !== "undefined" &&
            form.title_fr_fr !== null
        ) {
            this.sessionEdit.name.FrFR = form.title_fr_fr;
        } else {
            this.sessionEdit.name.FrFR = form.title_fr_fr;
        }

        if (
            typeof form.title_de_de !== "undefined" &&
            form.title_de_de !== null
        ) {
            this.sessionEdit.name.DeDE = form.title_de_de;
        } else {
            this.sessionEdit.name.DeDE = form.title_de_de;
        }

        this.sessionEdit.locations = {};
        if (form.locations.length > 0) {
            this.errorFormLocation = true;
            validation = false;
            this.loader = false;
        } else {
            let contOrder = 0;

            for (let index in this.selectedLocations) {
                const location = this.selectedLocations[index];
                location.order = contOrder;
                this.sessionEdit.locations[location.uid] = Object.assign(
                    {},
                    location
                );

                contOrder++;
            }
        }

        this.sessionEdit.documents = {};
        if (form.documents) {
            this.errorFormDocument = true;
            validation = false;
        } else {
            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                this.sessionEdit.documents[document.uid] = Object.assign(
                    {},
                    document
                );
            }
        }

        this.sessionEdit.customFields = {};
        if (form.customFields) {
            this.errorFormCustomFields = true;
            validation = false;
        } else {
            for (let index in this.selectedCustomFields) {
                const custom = this.selectedCustomFields[index];
                this.sessionEdit.customFields[custom.uid] = Object.assign(
                    {},
                    custom
                );
            }
        }

        this.sessionEdit.tracks = {};
        if (this.selectedTracks.length > 0) {
            for (let index in this.selectedTracks) {
                const track = this.selectedTracks[index];
                this.sessionEdit.tracks[track["uid"]] = Object.assign(
                    {},
                    track
                );
            }
        }

        this.sessionEdit.groups = {};
        if (this.selectedGroup.length > 0) {
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                this.sessionEdit.groups[group["uid"]] = Object.assign(
                    {},
                    group
                );
            }
        }

        this.sessionEdit.speakers = {};
        if (form.speakers) {
            this.errorFormSpeaker = true;
            validation = false;
        } else {
            for (let index in this.selectedSpeaker) {
                const speaker = this.selectedSpeaker[index];
                this.sessionEdit.speakers[speaker.uid] = Object.assign(
                    {},
                    speaker
                );
            }
        }

        // total attendees
        if (
            form.totalAttendees !== null &&
            form.totalAttendees !== "" &&
            typeof form.totalAttendees !== "undefined"
        ) {
            this.sessionEdit.limitAttendees = form.totalAttendees;
        }

        // descriptions
        this.sessionEdit.descriptions.PtBR =
            form.descriptionlanguages_pt_BR.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        this.sessionEdit.descriptions.EnUS =
            form.descriptionlanguages_en_US.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        this.sessionEdit.descriptions.DeDE =
            form.descriptionlanguages_de_DE.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        this.sessionEdit.descriptions.EsES =
            form.descriptionlanguages_es_ES.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        this.sessionEdit.descriptions.FrFR =
            form.descriptionlanguages_fr_FR.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );

        // checks if the start date is in the past
        let now = new Date();
        let dayNow: any = now.getDate().toString();
        let monthNow: any = now.getMonth() + 1;
        monthNow.toString();
        const yearNow: any = now.getFullYear().toString();
        let hourNow: any = now.getHours().toString();
        let minuteNow: any = now.getMinutes().toString();

        if (dayNow < 10) {
            dayNow = `0${dayNow}`;
        }

        if (monthNow < 10) {
            monthNow = `0${monthNow}`;
        }

        if (minuteNow < 10) {
            minuteNow = `0${minuteNow}`;
        }

        if (hourNow < 10) {
            hourNow = `0${hourNow}`;
        }

        const timeStampNow = this.luxon.createTimeStamp(
            this.luxon.createDate(
                yearNow,
                monthNow,
                dayNow,
                hourNow,
                minuteNow,
                "00"
            )
        );

        // Video player part
        const videoUrl = (form.videoPlayerUrl) ? form.videoPlayerUrl.trim() : null;
        if (form.videoPlayerType != "disabled") {
            if (!videoUrl) {
                validation = false;
                this.errorFormVideoUrl = true;
            }
            this.sessionEdit.videoPlayer.type = form.videoPlayerType;
            this.sessionEdit.videoPlayer.url = videoUrl;
        } else {
            this.sessionEdit.videoPlayer = null;
        }

        // if (this.sessionEdit.startTime < timeStampNow) {
        //     validation = false
        //     this.datePast = true
        // }

        this.wherebyError = [];

        if (validation) {
            try {
                // Create room whereby if needed
                let startDateArray = this.visioForm.value.startDate
                    ? this.visioForm.value.startDate.split("-")
                    : [];
                let startDate =
                    startDateArray.length > 0
                        ? this.luxon
                              .createDate(
                                  startDateArray[0],
                                  startDateArray[1],
                                  startDateArray[2],
                                  "00",
                                  "00",
                                  "00"
                              )
                              .toISO()
                        : null;
                let endDateArray = this.visioForm.value.endDate
                    ? this.visioForm.value.endDate.split("-")
                    : [];
                let endDate =
                    endDateArray.length > 0
                        ? this.luxon
                              .createDate(
                                  endDateArray[0],
                                  endDateArray[1],
                                  endDateArray[2],
                                  "00",
                                  "00",
                                  "00"
                              )
                              .toISO()
                        : null;

                if (!this.sessionEdit.visio) {
                    this.sessionEdit.visio = {};
                }

                if (
                    this.event.allow_visio &&
                    this.visioForm.value.name &&
                    this.visioForm.value.name != "" &&
                    startDate < endDate
                ) {
                    // if (!startDate || startDate < this.luxon.currentTime().toISO()) {
                    //     this.wherebyError.push('start-date-required-or-invalid');
                    // }
                    // if (!endDate || endDate < this.luxon.currentTime().toISO()) {
                    //     this.wherebyError.push('end-date-required-or-invalid');
                    // }

                    // if (startDate > endDate) {
                    //     this.wherebyError.push('start-superior-end');
                    // }

                    // if (this.wherebyError.length > 0) {
                    //     this.createSessionError = true;
                    //     this.loader = false;
                    //     return;
                    // }

                    let resp: any = null;

                    if (this.visioForm.value.type == "whereby") {
                        if (
                            this.sessionEdit.visio &&
                            this.sessionEdit.visio.type == "whereby" &&
                            this.sessionEdit.visio.meetingId
                        ) {
                            await this.SWhereby.deleteMeeting(
                                this.sessionEdit.visio.meetingId
                            );
                        }

                        let meeting: any = {
                            roomNamePrefix:
                                "/" +
                                (this.visioForm.value.name
                                    ? this.visioForm.value.name
                                    : this.sessionEdit.visio.roomName
                                    ? this.sessionEdit.visio.roomName
                                    : "default"),
                            roomMode: "group",
                            startDate: startDate ? startDate : null,
                            endDate: endDate ? endDate : null,
                            fields: ["hostRoomUrl"],
                        };

                        resp = await this.SWhereby.createMeeting(meeting);
                    } else if (this.visioForm.value.type == "zoom") {
                        let meeting: any = {
                            topic: this.visioForm.value.name,
                            start_time: this.luxon.getGmtDate(startDate),
                            duration: moment(endDate).diff(
                                moment(startDate),
                                "minutes"
                            ),
                            userId: this.getZoomIntegrationById(
                                this.visioForm.value.zoomIntegrationId
                            ).email,
                            jwt: this.getZoomIntegrationById(
                                this.visioForm.value.zoomIntegrationId
                            ).jwt,
                        };
                        if (
                            this.sessionEdit.visio &&
                            this.sessionEdit.visio.type == "zoom" &&
                            this.sessionEdit.visio.meetingId
                        ) {
                            resp = await this.SWhereby.updateZoomMeeting({
                                meetingId: this.sessionEdit.visio.meetingId,
                                ...meeting,
                            });
                        } else {
                            resp = await this.SWhereby.createZoomMeeting(
                                meeting
                            );
                        }
                    }

                    this.sessionEdit.visio = {
                        type: this.visioForm.value.type,
                        zoomIntegrationId:
                            this.visioForm.value.type == "zoom"
                                ? this.getZoomIntegrationById(
                                      this.visioForm.value.zoomIntegrationId
                                  ).id
                                : "",
                        password:
                            this.visioForm.value.type == "zoom" &&
                            this.sessionEdit.visio.password
                                ? this.sessionEdit.visio.password
                                : "",
                        meetingId:
                            resp && resp.body && resp.body.meetingId
                                ? resp.body.meetingId
                                : this.sessionEdit.visio.meetingId
                                ? this.sessionEdit.visio.meetingId
                                : "",
                        roomUrl:
                            resp && resp.body && resp.body.roomUrl
                                ? resp.body.roomUrl
                                : this.sessionEdit.visio.roomUrl
                                ? this.sessionEdit.visio.roomUrl
                                : "",
                        hostRoomUrl:
                            resp && resp.body && resp.body.hostRoomUrl
                                ? resp.body.hostRoomUrl
                                : this.sessionEdit.visio.hostRoomUrl
                                ? this.sessionEdit.visio.hostRoomUrl
                                : "",
                        startDate: startDate ? startDate : null,
                        endDate: endDate ? endDate : null,
                        roomMode: "group",
                        roomName: this.visioForm.value.name
                            ? this.visioForm.value.name
                            : this.sessionEdit.visio.roomName
                            ? this.sessionEdit.visio.roomName
                            : "default",
                        allowChatOnVisio: this.visioForm.value.allowChatOnVisio,
                        allowScreenShareOnVisio:
                            this.visioForm.value.allowScreenShareOnVisio,
                        allowLeaveOnVisio:
                            this.visioForm.value.allowLeaveOnVisio,
                        activated:
                            this.visioForm.value.activated == undefined
                                ? false
                                : this.visioForm.value.activated,
                    };
                }

                this.sessionEdit.visio.groupsVisioHost = {};

                if (this.selectedGroupVisio.length > 0) {
                    for (let index in this.selectedGroupVisio) {
                        const group = this.selectedGroupVisio[index];
                        this.sessionEdit.visio.groupsVisioHost[group["uid"]] =
                            Object.assign({}, group);
                    }
                }
                this.loader = false;
                this.dbSchedule.updateSession(this.sessionEdit, (success) => {
                    if (success) {
                        this.loader = false;
                        this.successSwal.fire();
                    } else {
                        this.createSessionError = true;
                        this.loader = false;
                    }
                });
            } catch (error) {
                console.log("Error: ", error);
                this.createSessionError = true;
                this.loader = false;
            }
        } else {
            this.loader = false;
        }
    }

    redirectList() {
        if (!this.activationVisioUpdated) {
            this.router.navigate([
                "/event/" + this.eventId + "/schedule/" + this.moduleId,
            ]);
        } else {
            this.activationVisioUpdated = false;
        }
    }

    // autocomplete locations
    filterLocations() {
        //  funÃ§Ã£o usa a query variÃ¡vel para filtrar tracks, entÃ£o ela armazena o resultado no filteredList.
        if (this.queryLocation !== "") {
            this.filteredListLocations = this.listLocations.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryLocation.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListLocations = [];
        }
    }

    selectLocation(item) {
        // atribuir o item selecionado para a variÃ¡vel de consulta, a fim de fazÃª-lo aparecer na entrada, e para fazer a lista de sugestÃµes de desaparecer
        const pos = this.selectedLocations
            .map(function (e) {
                return e.uid;
            })
            .indexOf(item.uid);

        if (pos < 0) {
            this.selectedLocations.push(item);
        }

        this.queryLocation = "";
        this.filteredListLocations = [];
    }

    removeLocation(item) {
        // remove valores selecionados
        this.selectedLocations.splice(this.selectedLocations.indexOf(item), 1);
    }

    // autocomplete tracks
    filterTracks() {
        if (this.queryTrack !== "") {
            this.filteredListTracks = this.listTracks.filter(
                function (el) {
                    return (
                        el.name[this.principalEventLangFormated]
                            .toLowerCase()
                            .indexOf(this.queryTrack.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListTracks = [];
        }
    }

    selectTrack(item) {
        const pos = this.selectedTracks
            .map(function (e) {
                return e["uid"];
            })
            .indexOf(item.uid);

        if (pos < 0) {
            this.selectedTracks.push(item);
        }

        this.queryTrack = "";
        this.filteredListTracks = [];
    }

    removeTrack(item) {
        this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroup.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroups = [];
        }
    }

    // Filter groups for visio
    filterGroupsVisio() {
        this.filteredListGroupsVisio = this.listGroupVisio;

        this.filteredListGroupsVisio = this.filteredListGroupsVisio.filter(
            (group) => {
                if (
                    group.name
                        .toLowerCase()
                        .indexOf(this.queryGroupVisio.toLowerCase()) > -1 &&
                    !this.selectedGroupVisio.find(
                        (groupFind) => groupFind.uid == group.uid
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        );
        this.showGroupVisio = true;

        if (this.filteredListGroupsVisio.length == 0) {
            this.showGroupVisio = false;
        }
        // if (this.queryGroupVisio !== "") {
        //     this.filteredListGroupsVisio = this.listGroupVisio.filter(function (el) {
        //         return el.name.toLowerCase().indexOf(this.queryGroupVisio.toLowerCase()) > -1;
        //     }.bind(this));
        // } else {
        //     this.filteredListGroupsVisio = [];
        // }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = "";
        this.filteredListGroups = [];
    }

    // Select group for visio
    selectGroupVisio(item) {
        if (this.selectedGroupVisio.length > 0) {
            const index = this.selectedGroupVisio.indexOf(item);
            if (index == -1) {
                this.selectedGroupVisio.push(item);
            }
        } else {
            this.selectedGroupVisio.push(item);
        }
        this.queryGroupVisio = "";
        this.filterGroupsVisio();
        this.showGroupVisio = false;
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }

    // Remove group for visio
    removeGroupVisio(item) {
        this.selectedGroupVisio.splice(
            this.selectedGroupVisio.indexOf(item),
            1
        );
    }

    /******************* SPEAKERS **********************/

    // filter speakers
    filterSpeakers() {
        if (this.querySpeaker !== "") {
            this.filteredListSpeakers = this.listSpeaker.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.querySpeaker.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListSpeakers = [];
        }
    }

    // select speaker from filtered list
    selectSpeaker(item) {
        if (this.selectedSpeaker.length > 0) {
            const index = this.selectedSpeaker
                .map(function (e) {
                    return e.uid;
                })
                .indexOf(item.uid);

            if (index == -1) {
                this.selectedSpeaker.push(item);
            }
        } else {
            this.selectedSpeaker.push(item);
        }
        this.querySpeaker = "";
        this.filteredListSpeakers = [];
    }

    // remove selected speaker
    removeSelectedSpeakers(item) {
        this.selectedSpeaker.splice(this.selectedSpeaker.indexOf(item), 1);
    }

    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(
                function (el) {
                    return (
                        el.name[this.principalEventLangFormated]
                            .toLowerCase()
                            .indexOf(this.queryDocument.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument
                .map(function (e) {
                    return e.uid;
                })
                .indexOf(item.uid);

            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }

        this.queryDocument = "";
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeSelectedDocuments(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }

    /******************* CUSTOM FIELDS **********************/
    filterCustomFields() {
        if (this.queryCustomFields !== "") {
            this.listCustomFields = [].concat.apply([], this.listCustomFields);
            console.log(this.listCustomFields);
            this.filteredCustomFields = this.listCustomFields.filter(
                function (el) {
                    return (
                        el.name[this.principalEventLangFormated]
                            .toLowerCase()
                            .indexOf(this.queryCustomFields.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select custom fields from filtered list
    selectCustomField(item) {
        if (this.selectedCustomFields.length > 0) {
            const index = this.selectedCustomFields
                .map(function (e) {
                    return e.uid;
                })
                .indexOf(item.uid);

            if (index == -1) {
                this.selectedCustomFields.push(item);
            }
        } else {
            this.selectedCustomFields.push(item);
        }

        this.queryCustomFields = "";
        this.filteredCustomFields = [];
    }

    // remove selected custom fields
    removeSelectedCustomField(item) {
        this.selectedCustomFields.splice(
            this.selectedCustomFields.indexOf(item),
            1
        );
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case "pt_BR": {
                formatedLang = "PtBR";
                break;
            }
            case "en_US": {
                formatedLang = "EnUS";
                break;
            }
            case "es_ES": {
                formatedLang = "EsES";
                break;
            }
            case "fr_FR": {
                formatedLang = "FrFR";
                break;
            }
            case "de_DE": {
                formatedLang = "DeDE";
                break;
            }
        }
        return formatedLang;
    }

    /**
     * Change activation of visio
     */
    async changeActivationVisio() {
        this.createSessionError = false;
        this.errorFormLocation = false;
        this.errorFormTrack = false;
        this.errorFormSpeaker = false;
        this.errorFormDocument = false;
        this.timeError = false;
        this.datePast = false;

        this.loader = true;

        try {
            let newActivation = !this.sessionEdit.visio.activated;
            await this.dbSchedule.updateActivationVisioOnSession(
                this.sessionEdit,
                newActivation
            );
            this.activationVisioUpdated = true;
            this.successSwal.fire();
        } catch (error) {
            this.createSessionError = true;
            this.loader = false;
        }
    }
}
