import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "src/app/providers/global/global.service";
import { DbGamificationProvider } from "src/app/providers/database/db-gamification";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { QRCode } from "src/app/models/gaming-qrcode";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import * as jspdf from "jspdf";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RegexProvider } from "src/app/providers/regex/regex.service";
import { HttpResponse } from "@angular/common/http";
type AOA = Array<Array<any>>;

declare let $: any;

@Component({
    selector: "app-gamification",
    templateUrl: "./gamification.component.html",
    styleUrls: ["./gamification.component.scss"],
})
export class GamificationComponent implements OnInit {
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    // get the language of the user.
    term;
    p;
    public loader: boolean = true;
    public userLanguage: string;
    public eventId: string = null;
    public moduleId: string = null;
    public module = null;
    public codesSelected: Array<any> = [];
    public selectedAllInput: boolean = false;
    public deleteCode: QRCode = null;
    public codes: Array<QRCode> = [];
    public qrCodeEdit: QRCode = null;

    public formValidation: FormGroup;
    public formValidationEdit: FormGroup;
    public formValidationSettings: FormGroup;

    public loaderModal: boolean = false;
    public fillAllFieldsError: boolean = false;
    public typeOrder: string = "asc";
    public qrCodePrintUid: string = null;

    cellNames: string[] = ["Qr code name", "User name", "User email"];

    constructor(
        private route: ActivatedRoute,
        private global: GlobalService,
        private dbGamification: DbGamificationProvider,
        fb: FormBuilder,
        private dbEvent: DbEventsProvider,
        private regex: RegexProvider
    ) {
        this.eventId = this.route.parent.params["_value"]["uid"];
        this.moduleId = this.route.snapshot.params["moduleId"];
        this.formValidation = fb.group({
            title: [null, Validators.compose([Validators.required])],
            points: [null, Validators.compose([Validators.required])],
        });
        this.formValidationEdit = fb.group({
            title: [null, Validators.compose([Validators.required])],
            points: [null, Validators.compose([Validators.required])],
        });

        this.formValidationSettings = fb.group({
            gamingTitle: [""],
            gamingText: [""],
            gamingBtn: [""],
        });
    }

    ngOnInit() {
        this.getUserLanguage();
        this.getModule();
        this.getEvent();
    }

    event: any = null;
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    userLanguageFormat: string = "PtBR";
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
            this.userLanguageFormat = this.convertLangFormat(language);
        });
    }

    getModule() {
        this.dbGamification.getModule(this.moduleId, (module) => {
            this.module = module;
            this.typeOrder = module.typeOrder;
            this.formValidationSettings.patchValue({
                gamingTitle: this.module.gamingTitle
                    ? this.module.gamingTitle
                    : "",
                gamingText: this.module.gamingText
                    ? this.module.gamingText
                    : "",
                gamingBtn: this.module.gamingBtn ? this.module.gamingBtn : "",
            });
            this.getCodes();
        });
    }

    getCodes() {
        this.dbGamification.getCodes(
            this.moduleId,
            this.typeOrder,
            (codes: Array<QRCode>) => {
                this.codes = codes;
                this.loader = false;
            }
        );
    }

    createQRCode(data) {
        this.fillAllFieldsError = false;
        if (data.title && data.points) {
            this.loaderModal = true;
            const qrcode = new QRCode(
                data.title,
                data.points,
                this.moduleId,
                this.eventId
            );
            this.dbGamification.newQR(qrcode, (response) => {
                if (response) {
                    this.successSwal.fire();
                    $("#newQrCode").modal("toggle");
                    this.clearForm();
                    this.loaderModal = false;
                } else {
                    this.errorSwal.fire();
                    this.loaderModal = false;
                }
            });
        } else {
            // necessário preencher os campos;
            this.fillAllFieldsError = true;
            this.loaderModal = false;
        }
    }

    getEditQR(qrcode: QRCode) {
        this.qrCodeEdit = qrcode;
        this.formValidationEdit.patchValue({
            title: qrcode.title,
            points: qrcode.points,
        });
    }

    editQR(data) {
        this.fillAllFieldsError = false;
        if (data.title && data.points) {
            this.loaderModal = true;
            this.qrCodeEdit.title = data.title;
            this.qrCodeEdit.points = data.points;
            this.dbGamification.editQR(this.qrCodeEdit, (response) => {
                if (response) {
                    this.successSwal.fire();
                    $("#editQrCode").modal("toggle");
                    this.clearForm();
                    this.qrCodeEdit = null;
                    this.loaderModal = false;
                } else {
                    this.errorSwal.fire();
                    this.loaderModal = false;
                }
            });
        } else {
            // necessário preencher os campos;
            this.fillAllFieldsError = true;
            this.loaderModal = false;
        }
    }

    changeOrder() {
        this.dbGamification.changeOrder(
            this.typeOrder,
            this.moduleId,
            this.eventId
        );
    }

    clearForm() {
        this.formValidation.reset();
    }

    getDelete(qrcode: QRCode) {
        this.deleteCode = qrcode;
    }

    deleteQRCode() {
        this.loader = true;
        this.dbGamification.deleteQR(this.deleteCode, (response) => {
            if (response) {
                this.successSwal.fire();
                this.loader = false;
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.codes.length; i++) {
                this.codesSelected[this.codes[i]["uid"]] = {
                    uid: this.codes[i].uid,
                    remove: true,
                };
            }
        } else {
            for (let i = 0; i < this.codes.length; i++) {
                this.codesSelected[this.codes[i]["uid"]] = {
                    uid: this.codes[i].uid,
                    remove: false,
                };
            }
        }
    }

    deleteAll() {
        this.loader = true;
        this.dbGamification.deleteAllQRCode(
            this.codesSelected,
            this.eventId,
            this.moduleId,
            (response) => {
                if (response) {
                    this.successSwal.fire();
                    this.loader = false;
                } else {
                    this.errorSwal.fire();
                    this.loader = false;
                }
            }
        );
    }

    printAllQRCodes() {
        $("#printAllQrCodes").modal("show");
        let size = this.codes.length;
        let array = [];
        for (let i = 0; i < size; i++) {
            // get element canvas and make data url string
            let elem = document.getElementById("qrcodesAll" + i);
            const canvas = elem.childNodes[0]
                .childNodes[0] as HTMLCanvasElement;
            const imageData = canvas.toDataURL("image/png").toString();
            // size and position of qrcodes
            let imgWidth = 150;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            array.push({
                name: this.codes[i].title,
                image: imageData,
                width: imgWidth,
                height: imgHeight,
                position: 30,
            });

            if (array.length == size) {
                this.saveAllQrCodes(array);
            }
        }
    }

    saveAllQrCodes(qrcodes) {
        let size = qrcodes.length;
        // start jspdf a4 format in landscape mode using milimiters
        let pdf = new jspdf("l", "mm", "a4"); // A4 size page of PDF
        let pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        let pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        for (let i = 0; i < size; i++) {
            // add title to top
            pdf.setFontType("bold");
            pdf.text(
                qrcodes[i].name,
                pageWidth / 2,
                pageHeight - 190,
                "center"
            );
            // add qrcode image after title
            pdf.addImage(
                qrcodes[i].image,
                "PNG",
                70,
                qrcodes[i].position,
                qrcodes[i].width,
                qrcodes[i].height
            );
            // generate new page
            if (i < size - 1) {
                pdf.addPage();
            }
            if (i == size - 1) {
                pdf.save("qrcodes.pdf"); // Generated PD
                setTimeout(() => {
                    $("#printAllQrCodes").modal("toggle");
                }, 1000);
            }
        }
    }

    printQrCode(code: QRCode) {
        const index = this.codes
            .map(function (e) {
                return e.uid;
            })
            .indexOf(code.uid);
        if (index > -1) {
            let elem = document.getElementById("qrcodesAll" + index);
            const canvas = elem.childNodes[0]
                .childNodes[0] as HTMLCanvasElement;
            const imageData = canvas.toDataURL("image/png").toString();
            // size and position of qrcodes
            let imgWidth = 150;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            let pdf = new jspdf("l", "mm", "a4"); // A4 size page of PDF
            let pageHeight =
                pdf.internal.pageSize.height ||
                pdf.internal.pageSize.getHeight();
            let pageWidth =
                pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
            pdf.setFontType("bold");
            pdf.text(code.title, pageWidth / 2, pageHeight - 190, "center");
            // add qrcode image after title
            pdf.addImage(imageData, "PNG", 70, 30, imgWidth, imgHeight);
            pdf.save(`${code.title}-qrcode.pdf`); // Generated PD
        }
    }

    updateModuleTexts(data) {
        this.loaderModal = true;
        this.dbGamification.updateModuleSettingsTexts(
            this.moduleId,
            this.eventId,
            data,
            (response) => {
                if (response) {
                    this.successSwal.fire();
                    this.loaderModal = false;
                    $("#settingsModal").modal("toggle");
                } else {
                    this.errorSwal.fire();
                    this.loaderModal = false;
                }
            }
        );
    }

    /********************************* EXPORT QRCODES ACCESS ************************/

    async exportQRCodeAccess() {
        try {
            let qrCodes: any = await this.dbGamification
                .exportGamification(this.eventId, this.moduleId)
                .toPromise();
            const wb = XLSX.utils.book_new();

            let ws: XLSX.WorkSheet;
            if (qrCodes && qrCodes.length > 0) {
                let rows = [];
                qrCodes.forEach((qrCode) => {
                    qrCode.attendees.forEach((attendee) => {
                        rows.push([
                            qrCode.title,
                            attendee.identifier,
                            attendee.email,
                        ]);
                    });
                });
                ws = XLSX.utils.aoa_to_sheet([this.cellNames, ...rows]);
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                XLSX.writeFile(wb, "qrcode-scanned.xlsx");
                $("#printAllQrCodes").modal("toggle");
                this.successSwal.fire();
            } else {
                $("#printAllQrCodes").modal("toggle");
                this.errorSwal.fire();
            }
        } catch (error) {
            $("#printAllQrCodes").modal("toggle");
            this.errorSwal.fire();
        }
    }

    // exportQRCodeAccess() {
    // this.dbGamification.exportGamification(
    //     this.eventId,
    //     this.moduleId,
    //     async (qrcodes) => {
    //         /* generate workbook and add the worksheet */
    //         if (qrcodes.length >= 1) {
    //             let cont = 0;
    //             let resultFinal: Array<any> = [];
    //             const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //             qrcodes.forEach((qrcode) => {
    //                 qrcode.attendees.forEach((attendee) => {});
    //             });
    //             for (let i = 0; i < qrcodes.length; i++) {
    //                 await this.getAttendeesScanned(
    //                     qrcodes[i].attendees
    //                 ).then((result: any) => {
    //                     const wscols: XLSX.ColInfo[] = [
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { wpx: 100 }, // "pixels"
    //                         { hidden: false }, // hide column
    //                     ];
    //                     /* At 96 PPI, 1 pt = 1 px */
    //                     const wsrows: XLSX.RowInfo[] = [
    //                         { hpx: 25 }, // "pixels"
    //                     ];
    //                     /* generate worksheet */
    //                     const ws: XLSX.WorkSheet =
    //                         XLSX.utils.aoa_to_sheet(result);
    //                     /* TEST: column props */
    //                     ws["!cols"] = wscols;
    //                     /* TEST: row props */
    //                     ws["!rows"] = wsrows;
    //                     resultFinal[i] = ws;
    //                     let titleSheet = this.regex.removeSpecialCharacters(
    //                         qrcodes[i].title
    //                     );
    //                     let finalTitle;
    //                     if (titleSheet.length > 30) {
    //                         finalTitle = titleSheet.substring(0, 30);
    //                     } else {
    //                         finalTitle = titleSheet;
    //                     }
    //                     XLSX.utils.book_append_sheet(
    //                         wb,
    //                         resultFinal[i],
    //                         finalTitle
    //                     );
    //                     if (cont == qrcodes.length - 1) {
    //                         /* save to file */
    //                         const wbout: string = XLSX.write(wb, {
    //                             bookType: "xlsx",
    //                             type: "binary",
    //                         });
    //                         saveAs(
    //                             new Blob([this.s2ab(wbout)]),
    //                             "qrcode-scanned.xlsx"
    //                         );
    //                         $("#printAllQrCodes").modal("toggle");
    //                     }
    //                     cont++;
    //                 });
    //                 //
    //             }
    //         } else {
    //             $("#printAllQrCodes").modal("toggle");
    //         }
    //     }
    // );
    // }

    getAttendeesScanned(attendees) {
        return new Promise(async (resolve) => {
            let cont = 0;
            let dataExportQRCode = [];
            dataExportQRCode = [["ID", "Name", "E-mail"]];
            if (attendees.length >= 1) {
                for (let attendee of attendees) {
                    let row: any;
                    row = await this.prepareGamificationExport(attendee);
                    await dataExportQRCode.push(row);
                    if (cont == attendees.length - 1) {
                        resolve(dataExportQRCode);
                    }
                    cont++;
                }
            } else {
                resolve(dataExportQRCode);
            }
        });
    }

    // PREPARA UM REGISTRO DE GRUPOS PARA EXPORTAÇÃO
    prepareGamificationExport(attendee) {
        const row = [];

        row.push(attendee.identifier);
        row.push(attendee.name);
        row.push(attendee.email);

        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    returnColmns() {
        let array = [
            { wpx: 230 }, // "pixels"
            { wpx: 230 }, // "pixels"
            { wpx: 230 }, // "pixels"
            { wpx: 230 }, // "pixels"
        ];
        return array;
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case "pt_BR": {
                formatedLang = "PtBR";
                break;
            }
            case "en_US": {
                formatedLang = "EnUS";
                break;
            }
            case "es_ES": {
                formatedLang = "EsES";
                break;
            }
            case "fr_FR": {
                formatedLang = "FrFR";
                break;
            }
            case "de_DE": {
                formatedLang = "DeDE";
                break;
            }
        }
        return formatedLang;
    }
}
