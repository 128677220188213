import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { ResultProps } from 'src/app/client-dashboard/client-event-dashboard/client-modules/interactivity/prize-draw/create-prize-draw/@types/prize-draw-module';
import { TypeModule } from 'src/app/enums/type-module';

@Injectable({
    providedIn: 'root'
})
export class DbPrizeDrawProvider {
    private db: firebase.firestore.Firestore;

    constructor(private aFirestore: AngularFirestore) {

    }

    getPrizeDrawModule(eventId: string, onResolve) {
        const db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.PRIZE_DRAW)
            .get()
            .then((snapshot) => {
                onResolve(
                    snapshot.docs.map((doc) => {
                        return doc.data()
                    })
                )
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    saveResults(eventId: string, moduleId: string, results: ResultProps, onResolve: (res: string) => void) {
        const db = this.aFirestore.firestore;
        const ref = db
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('results')
            .doc();


        results.uid = ref.id;
        ref.set(results)
            .then(() => {
                onResolve(results.uid);
            })
            .catch((err) => {
                console.error(err);
                onResolve(results.uid);
            })
    }

    /**
     * Get a specific prize-draw result from database
     *
     * @return a promise containing a snapshot of the document and the result in the data() method
     */
    getResult(eventId: string, moduleId: string, resultId: string) {
        const db = this.aFirestore.firestore;
        const ref = db
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('results')
            .doc(resultId)

        return ref.get()
    }

    /**
     * Fetch all the results and return a list of results
     *
     * @param eventId
     * @param moduleId
     */
    fetchResults(eventId: string, moduleId: string, onResolve) {
        const db = this.aFirestore.firestore;
        const ref = db
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('results');

        ref.onSnapshot((data) => {
                let resultsList = [];
                data.forEach((element) => {
                    const result = element.data();
                    resultsList.push(result);
                })

                onResolve(resultsList);
            }, (err) => {
                console.error(err);
                onResolve(false);
            })
    }
    /**
     * Delete a specific result
     *
     * @returns A Promise resolved once the document has been successfully deleted
     */
    deleteResult(eventId: string, moduleId: string, resultId: string) {
        const db = this.aFirestore.firestore;
        const ref = db
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('results')
            .doc(resultId)

        return ref.delete()
    }
}
