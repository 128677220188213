import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
//import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/providers/storage/storage.service';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {
  eventId: string;

  menu_color: string = '#2973A2';
  menu_color2: string = '#2973A2';
  menu_text_color: string = '#FFFFFF';
  menu_text_color2: string = '#FFFFFF';
  //active_menu_item: string = '#FFFFFF';
  title_color: string = '#000000';
  text_content_color: string = '#3F3F3F';
  link_color: string = '#4BA4D8';
  btn_text_color: string = '#000000';
  bg_content_color: string = '#FFFFFF';
  bg_general_color: string = '#F6F6F6';
  imgBgFile;
  imgBg: string = '';
  loader: boolean = true;
  saveLoader: boolean = false;
  //selectedFont: string;
  //globalMsg = '';
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
//   fonts = [
//     {key: "Roboto", value:"Roboto"},
//     {key: "Montserrat", value:"Montserrat"},
//     {key: "Open+Sans", value:"Open Sans"},
//     {key: "Lato", value:"Lato"},
//     {key: "Source+Sans+Pro", value:"Source Sans Pro"},
//     {key: "Poppins", value:"Poppins"},
//     {key: "Raleway", value:"Raleway"},
//     {key: "Lora", value:"Lora"},
//     {key: "Noto", value:"Noto"},
//     {key: "Rubik", value:"Rubik"}
//   ]; // not recommended
  //fontLink ;
  //showFontError = false;
  allowBgImg = false;
  bgColor = '';
  constructor(
    private route: ActivatedRoute,
    private dbEvents: DbEventsProvider,
    //private sanitizer: DomSanitizer,
    private storage: StorageService
  ) {
    this.eventId = this.route.parent.params['_value']['uid']
  }

  ngOnInit() {
    this.startEvent();
    //const allFonts = this.fonts.reduce((accumulator, currentValue) => ({key: accumulator.key + '|' + currentValue.key, value: ""})).key;
    //this.fontLink = this.sanitizer.bypassSecurityTrustResourceUrl(`https://fonts.googleapis.com/css?family=${allFonts}:400`)
  }

  allowDegrade: boolean = false;
  startEvent() {
    this.dbEvents.getEvent(this.eventId, (event) => {
      if (event.colors.menu_color.includes('linear')) {
        this.allowDegrade = true;
        let colors = this.separeGradientColors(event.colors.menu_color);
        this.menu_color = colors[0];
        this.menu_color2 = colors[1];
      } else {
        this.menu_color = event.colors.menu_color;
      }
      this.menu_text_color = event.colors.menu_text_color;
      this.menu_text_color2 = event.colors.menu_text_color2 === undefined ? '#ffffff' : event.colors.menu_text_color2;
      //this.active_menu_item = event.colors.active_menu_item === undefined ? '#000000' : event.colors.active_menu_item;
      this.title_color = event.colors.title_color;
      this.text_content_color = event.colors.text_content_color;
      this.link_color = event.colors.link_color;
      this.btn_text_color = event.colors.btn_text_color === undefined ? '#ffffff' : event.colors.btn_text_color;
      this.bg_content_color = event.colors.bg_content_color;
      this.bg_general_color = event.colors.bg_general_color;
      
      this.bgColor = this.bg_content_color.includes('rgba') ?
      this.bg_content_color.split(',').filter((item, i, tab) => i !== tab.length - 1).join(',') + ',1)' :
      this.bg_content_color;

      if(event.colors.img_bg !== undefined && event.colors.img_bg !== null && event.colors.img_bg !== '') {
        this.allowBgImg = true
        this.imgBg = event.colors.img_bg
      }
    //   if(event.font !== undefined && event.font !== null){
    //     this.selectedFont = event.font
    //     document.body.style.setProperty('--custom-font', `'${this.selectedFont.split('+').join(' ')}'`)
    //   } else {
    //     this.selectedFont = 'empty'
    //     // document.body.style.setProperty('--custom-font', 'Open Sans')
    //   }
      //this.globalMsg = event.globalMsg !== undefined ? event.globalMsg : ''
    //   this.selectedFont = event.font !== undefined && event.font !== null ? event.font : 'empty' 
      this.loader = false;
    });
  }

  updateColors() {
    this.saveLoader = true;
    let menu_color;
    if (this.allowDegrade) {
      menu_color = `linear-gradient(to right, ${this.menu_color}, ${this.menu_color2})`
    } else {
      menu_color = this.menu_color;
    }
    let colors = {
      menu_color: menu_color,
      menu_text_color: this.menu_text_color,
      menu_text_color2: this.menu_text_color2,
      //active_menu_item: this.active_menu_item,
      title_color: this.title_color,
      btn_text_color: this.btn_text_color,
      text_content_color: this.text_content_color,
      link_color: this.link_color,
      bg_content_color: this.bg_content_color,
      bg_general_color: this.bg_general_color,
      img_bg: ''
    }
    // console.log(this.selectedFont)
    // if(this.selectedFont === undefined || this.selectedFont === null || this.selectedFont.trim() === 'empty'){
    //   this.errorSwal.fire();
    //   this.saveLoader = false;
    //   this.showFontError = true;
    //   return
    // }

    //this.globalMsg = this.globalMsg.trim()
    if(!this.allowBgImg){
      this.dbEvents.updateEventColors(this.eventId, colors, (status) => {
        if (status == true) {
          this.successSwal.fire();
          this.saveLoader = false;
        } else {
          this.errorSwal.fire();
          this.saveLoader = false;
        }
      });
    }
    if((this.imgBgFile == null || this.imgBgFile == undefined)  && (this.imgBg !== null && this.imgBg !== undefined)){
      this.dbEvents.updateEventColors(this.eventId, colors, (status) => {
        if (status == true) {
          this.successSwal.fire();
          this.saveLoader = false;
        } else {
          this.errorSwal.fire();
          this.saveLoader = false;
        }
      });
    }
    if(this.imgBgFile !== null && this.imgBgFile !== undefined && this.allowBgImg) {
      this.storage.eventBgImg(this.imgBgFile,this.eventId,'event-bg-img').then(url => {
        
        colors.img_bg = (url === null || !this.allowBgImg) ? '' :  url

        this.dbEvents.updateEventColors(this.eventId, colors, (status) => {
          if (status == true) {
            this.successSwal.fire();
            this.saveLoader = false;
          } else {
            this.errorSwal.fire();
            this.saveLoader = false;
          }
        });

      })
    }
  }


  separeGradientColors(str) {
    let hex = [];
    let index = str.indexOf('#');

    while (index > -1) {
      hex.push(this.getHexColors(str))
      str = str.replace('#', '');
      index = str.indexOf('#');
    }

    return hex;
  }

  getHexColors(str) {
    let position = str.indexOf('#');
    let result = str.substring(position, position + 7);

    return result;
  }

//   changeFont(event){
//     this.showFontError = false;
//     if(event.target.value !== 'empty'){
//       this.selectedFont = event.target.value
//       document.body.style.setProperty('--custom-font', `'${this.selectedFont.split('+').join(' ')}'`)
//     }
//   }
  selectBgImage(ev){
    this.imgBgFile = ev.srcElement.files[0];
    const fileName = this.imgBgFile.name;
    const imageType = this.imgBgFile.type;
    if (this.imgBgFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let imgType: string;

        if (imageType == 'image/gif') {
            imgType = 'data:image/gif;base64,';
        } else if (imageType == 'image/png') {
            imgType = 'data:image/png;base64,';
    
        } else if (imageType == 'image/jpg') {
            imgType = 'data:image/jpg;base64,';
    
        } else {
            imgType = 'data:image/jpeg;base64,';
        }
        this.imgBg = imgType + btoa(e.target.result+'');
        (<HTMLInputElement>document.getElementById('customFile')).value = "";
      };
      reader.readAsBinaryString(this.imgBgFile);
    }
  }

}
