import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbSessionFeedbackProvider } from 'src/app/providers/database/db-session-feedback';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { environment } from 'src/environments/environment';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { Languages } from 'src/app/models/languages';
import { GlobalService } from 'src/app/providers/global/global.service';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';

type AOA = Array<Array<any>>;
declare let $: any;

@Component({
    selector: 'app-session-feedback',
    templateUrl: './session-feedback.component.html',
    styleUrls: ['./session-feedback.component.scss']
})
export class SessionFeedbackComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmptySwal') public errorEmptySwal: SwalComponent;
    @ViewChild('clearAllResultsFeedbackSwal') public clearAllResultsFeedbackSwal: SwalComponent;
    @ViewChild('clearFeedbackSwal') public clearFeedbackSwal: SwalComponent;
    @ViewChild('removeFeedbackSwal') public removeFeedbackSwal: SwalComponent;
    @ViewChild('clearQuestionSwal') public clearQuestionSwal: SwalComponent;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    }

    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string;
    public exportLanguage: string = null;
    public feedbackExportId: string = null;
    public questionExportId: string = null;
    public typeExport: string = null;

    onReorderShow: boolean = false;
    loaderOrder: boolean = false;

    feedbackView: boolean = true;
    moduleId: string = null
    eventId: string = null;
    loader: boolean = true;
    public feedbacks: Array<any> = [];
    public feedbackRemoveId;
    feedbackRemoveIndex;
    public feedbackClearId;
    public questionClearId;
    eventLanguage: string = environment.platform.defaultLanguage;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private db: DbSessionFeedbackProvider,
        private dbEvent: DbEventsProvider,
        private dragula: DragulaService,
        private global: GlobalService,
        private luxon: LuxonService
    ) {
        // get module id
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        dragula.createGroup('bag-feedback', this.dragulaOptions);
    }

    ngAfterContentChecked() {
        let auxRoute = this.router.url;
        if (auxRoute.includes('create-session-feedback') || auxRoute.includes('edit-session-feedback')) {
            this.feedbackView = false;
        } else {
            this.feedbackView = true;
        }
    }

    getEventLanguage() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventLanguage = this.convertLangFormat(event.language);
            this.exportLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        });
    }

    ngOnInit() {
        // start dragula reorder bag
        this.dragula.dropModel('bag-feedback').subscribe((value: any) => {
            this.onReorder(value);
        });

        this.db.getFeedbacks(this.moduleId, (feedbacks: Array<any>) => {
            this.feedbacks = [];
            this.feedbacks = feedbacks;
            this.loader = false;
            this.getEventLanguage();
            this.getUserLanguage();
        })
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        })
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy('bag-feedback');
    }


    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.feedbacks = order.targetModel;
        for (let i = 0; i < (this.feedbacks).length; ++i) {
            this.feedbacks[i].order = i;
        }
    }


    saveNewOrder() {
        this.loaderOrder = true;

        this.db.changeOrder(this.moduleId, this.feedbacks, (result) => {
            if (result == true) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }

            this.loaderOrder = false;
            this.onReorderShow = false;
        })
    }

    changeVisibilityFeedback(feedbackId, visibility) {
        this.db.changeVisibility(this.moduleId, feedbackId, !visibility);
    }

    changeVisibilityQuestion(feedbackId, questionId, visibility, feedbackIndex, questionIndex) {
        this.db.changeVisibilityQuestion(this.moduleId, feedbackId, questionId, !visibility);


        if (visibility) {
            this.feedbacks[feedbackIndex].questions[questionIndex].visibility = false;
        } else {
            this.feedbacks[feedbackIndex].questions[questionIndex].visibility = true;
        }
    }

    enableAll() {
        for (let feedback of this.feedbacks) {
            this.db.changeVisibility(this.moduleId, feedback.uid, true);
        }
    }

    disableAll() {
        for (let feedback of this.feedbacks) {
            this.db.changeVisibility(this.moduleId, feedback.uid, false);
        }
    }

    expandirQuestions(index, feedback) {
        (feedback.expand) ? feedback.expand = false : feedback.expand = true;
        let aux = '.hidden';
        aux = aux.concat(index);
        this.showAndHiddenCollapse(aux);
    }

    /**
     * Hide or show collapse based on a query
     * @param query 
     */
    showAndHiddenCollapse(query) {
        if ($(query).hasClass('hideAll')) {
            $(query).removeClass('hideAll');
            $(query).show();
        } else {
            $(query).addClass('hideAll');
            $(query).hide();
        }
    }

    getRemoveQuestion(feedbackId, questionId, index) {

    }

    removeQuestion() {

    }

    getRemoveFeedback(feedbackId, index) {
        this.feedbackRemoveId = feedbackId;
        this.feedbackRemoveIndex = index;
        this.removeFeedbackSwal.fire();
    }

    removeFeedback() {
        this.loader = true;
        this.db.removeFeedback(this.moduleId, this.feedbackRemoveId, (data) => {
            if (data.code == 200) {
                this.successSwal.fire();
                this.feedbacks.splice(this.feedbackRemoveIndex, 1);
                this.loader = false;
            }
        })
    }

    /**
     * Temp data and launch swal
     * @param feedback 
     */
    getFeedbackClear(feedback) {
        this.feedbackClearId = feedback.uid;
        this.clearFeedbackSwal.fire();
    }

    /**
     * Clear all results of feedback
     */
    clearAllResults() {
        let listFeedbacksIds = [];
        this.loader = true;
        for (let feedback of this.feedbacks) {
            listFeedbacksIds.push(feedback.uid);
        }
        this.db.clearAllFeedbacks(this.moduleId, listFeedbacksIds).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    /**
     * Clear result of a feedback
     */
    clearFeedback() {
        this.loader = true;
        this.db.clearFeedback(this.moduleId, this.feedbackClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    /**
     * Temp data and launch swal
     * @param feedbackId 
     * @param questionId 
     */
    getQuestionClear(feedbackId, questionId) {
        this.feedbackClearId = feedbackId;
        this.questionClearId = questionId;
        this.clearQuestionSwal.fire();
    }

    /**
     * Clear a question
     */
    clearQuestion() {
        this.loader = true;
        this.db.clearQuestion(this.moduleId, this.feedbackClearId, this.questionClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    exportData() {
        $('#exportModal').modal('hide');
        switch (this.typeExport) {
            case 'feedback':
                this.exportOneFeedback(this.feedbackExportId, this.exportLanguage)
                break;

            case 'question':
                this.exportQuestion(this.feedbackExportId, this.questionExportId, this.exportLanguage)
                break;

            case 'allFeedbacks':
                this.exportFeedbacks(this.exportLanguage)
                break;
        }
    }

    exportFeedbacks(language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        let dataExportFeedback: Array<any> = [];
        let cont = 0;
        for (let feedback of this.feedbacks) {
            this.prepareExportFeedback(feedback.uid, language, (data) => {

                if (data !== null) {
                    dataExportFeedback.push(data)
                }

                if (cont == this.feedbacks.length - 1) {
                    $('#exportLoading').modal('toggle');
                    if (dataExportFeedback.length > 0) {
                        this.export(dataExportFeedback, 'Feedbacks');
                    } else {
                        this.errorEmptySwal.fire();
                    }
                }

                cont++;
            })

        }
    }

    exportOneFeedback(feedbackId, language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        this.prepareExportFeedback(feedbackId, language, (data) => {
            let dataExportFeedback: Array<any> = [];

            if (data !== null) {
                dataExportFeedback.push(data)
                $('#exportLoading').modal('toggle');
                this.export(dataExportFeedback, 'Feedback');
            } else {
                $('#exportLoading').modal('toggle');
                this.errorEmptySwal.fire();
            }
        })
    }

    exportQuestion(feedbackId, questionId, language) {
        let dataExport = [];
        dataExport = [[
            'Session',
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportQuestion(this.eventId, this.moduleId, feedbackId, questionId, language, (data) => {
            for (let result of data.result) {
                dataExport.push(this.prepareResult(result));
            }

            let dataExportQuestion = [dataExport];
            this.export(dataExportQuestion, 'Question')
        })
    }

    prepareExportFeedback(feedbackId, language, onResolve) {

        let dataExportFeedback = [];
        dataExportFeedback = [[
            'Session',
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportFeedbacks(this.eventId, this.moduleId, feedbackId, language, (data) => {
            if (data.code == 200) {
                let listResult = data.result;

                if (listResult.length <= 0) {
                    onResolve(null);
                }
                let cont = 0;
                for (let result of listResult) {
                    dataExportFeedback.push(this.prepareResult(result));

                    if (cont == listResult.length - 1) {
                        onResolve(dataExportFeedback);
                    }
                    cont++;
                }
            } else {
                $('#exportLoading').modal('toggle');
                this.errorSwal.fire();
            }
        })

    }

    prepareResult(result) {
        let array = [];
        array[0] = result.session;
        array[1] = result.question;
        array[2] = result.name;
        array[3] = result.email;
        array[4] = result.answer;
        array[5] = result.marker;


        let date = this.luxon.convertTimestampToDate(result.timestamp)
        let day = '0' + date.day;
        let month = '0' + date.month;
        let year = date.year;
        let hours = date.hour;
        let minutes = '0' + date.minute;
        let seconds = '0' + date.second;

        array[6] = day.substr(-2) + '/' + month.substr(-2) + '/' + year + ' - ' + hours + ':' +
            minutes.substr(-2) + ':' + seconds.substr(-2);

        return array;

    }

    export(listExport, fileName) {
        let totalFlaps = listExport.length;
        let result: Array<any> = [];

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        for (let i = 0; i < totalFlaps; i++) {

            const wscols: XLSX.ColInfo[] = [
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { hidden: false } // hide column
            ];

            /* At 96 PPI, 1 pt = 1 px */
            const wsrows: XLSX.RowInfo[] = [
                { hpx: 25 }, // "pixels"
            ];

            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(listExport[i]);

            /* TEST: column props */
            ws['!cols'] = wscols;

            /* TEST: row props */
            ws['!rows'] = wsrows;

            result[i] = ws;


            XLSX.utils.book_append_sheet(wb, result[i], 'Sheet' + (i + 1));
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), fileName + '.xlsx');
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }
}
