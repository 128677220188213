import { Participant } from './participant';
import { Message } from './message';
import firebase from 'firebase/app'

export class GroupDiscussion {
    id: string;
    title: string;
    description: string;
    eventId: string;
    moduleId: string;
    createdAt: firebase.firestore.Timestamp;
    groupCreator: any;
    participants: Participant[];
    visibility: boolean;
    disabled: boolean;
    visio: boolean;
    link_sessions: string;
    sessions: any[];
    activeParticipants: string[];
    lastMessage: Message;
    groups: string[];

    constructor(
        groupCreator: any,
        id: string = '',
        title: string = '',
        description: string = '',
        eventId: string = '',
        moduleId: string = '',
        createdAt: firebase.firestore.Timestamp = null,
        participants: Participant[] = [],
        visibility: boolean = true,
        disabled: boolean = false,
        visio: boolean = true,
        link_sessions: string = 'all',
        sessions: any[] = [],
        activeParticipants: string[] = [],
        lastMessage: Message = null,
        groups: string[] = []
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.eventId = eventId;
        this.moduleId = moduleId;
        this.createdAt = createdAt;
        this.groupCreator = groupCreator;
        this.participants = participants;
        this.visibility = visibility;
        this.disabled = disabled;
        this.visio = visio;
        this.link_sessions = link_sessions;
        this.sessions = sessions;
        this.activeParticipants = activeParticipants;
        this.lastMessage = lastMessage;
        this.groups = groups;
    }
}
