import { Component, OnInit } from '@angular/core';
import { DbPrizeDrawProvider } from '../providers/database/db-prize-draw';
import { ActivatedRoute } from '@angular/router';
import { ResultProps } from '../client-dashboard/client-event-dashboard/client-modules/interactivity/prize-draw/create-prize-draw/@types/prize-draw-module';
import { GlobalService } from '../providers/global/global.service';
import { FormatedEventLanguageService } from '../providers/formated-event-language/formated-event-language.service';

@Component({
  selector: 'app-prize-draw-result',
  templateUrl: './prize-draw-result.component.html',
  styleUrls: ['./prize-draw-result.component.scss']
})
export class PrizeDrawResultComponent implements OnInit {
    public isLoading: boolean = true;
    public eventId: string;
    public moduleId: string;
    public resultId: string;
    public result: ResultProps;
    public formattedResultDate: string;
    public userLanguage: string;
    public interval: number;

    constructor(
        private route: ActivatedRoute,
        private formatedLanguage: FormatedEventLanguageService,
        private dbPrizeDraw: DbPrizeDrawProvider,
        private global: GlobalService,
    ) { }

    ngOnInit(): void {
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['eventId'];
        this.moduleId = this.route.pathFromRoot[1]['params']['_value']['moduleId'];
        this.resultId = this.route.pathFromRoot[1]['params']['_value']['resultId'];

        Promise.all([
            this.getUserLanguage(),
            this.getResult()
        ]).then(() => {
            this.isLoading = false;
            if (this.result.hasCountdown) {
                this.startCountdown();
            }
        });
    }

    // get the language of the user.
    getUserLanguage(): Promise<void> {
        return new Promise((resolve) => {
            this.global.getLanguage((language: string) => {
                this.userLanguage = this.formatedLanguage.getLanguageFormatedInDashCase(language);
                resolve();
            })
        })
    }

    getResult(): Promise<void> {
        return new Promise((resolve) => {
            const snapshot = this.dbPrizeDraw.getResult(this.eventId, this.moduleId, this.resultId);
            snapshot.then((result) => {
                this.result = <ResultProps>result.data();

                const dateTimeFormatOptions = {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                } as Intl.DateTimeFormatOptions

                this.formattedResultDate = new Intl.DateTimeFormat(this.userLanguage, dateTimeFormatOptions).format(new Date(this.result.resultDate))

                resolve();
            })
        })
    }

    startCountdown() {
        this.interval = 10;
        const countdown = setInterval(() => {
            if(this.interval > 0) {
                this.interval -= 1;
            } else {
                this.result.hasCountdown = false;
                clearInterval(countdown);
            }
        }, 1000)
    }
}
