import { Component, OnInit } from '@angular/core';
import { Container } from 'src/app/models/container';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-containers',
  templateUrl: './client-containers.component.html',
  styleUrls: ['./client-containers.component.scss']
})
export class ClientContainersComponent implements OnInit {
  loader: boolean = true;
  term: string = '';
  p: number = 1;
  containers: Array<Container> = [];
  userId: string = null;
  constructor(
    private route: ActivatedRoute,
    private dbAdmin: DbAdminUserProvider
  ) {
    this.userId = this.route.snapshot.params['userId'];
  }

  ngOnInit() {
    this.dbAdmin.getContainersClient(this.userId, (containers: Array<Container>) => {
      this.containers = containers;
      this.loader = false;
    });
  }

}
