import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore, DocumentData, DocumentSnapshot } from '@angular/fire/firestore';
import { Attendee } from 'src/app/models/attendees';
import firebase from 'firebase/app';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { TypeModule } from 'src/app/enums/type-module';
import { Checkin } from 'src/app/models/checkin';
import { NameModule } from 'src/app/models/name-module';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DbAttendeesProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;
    public documentOnlyOnProfil: boolean;
    public documentProfilNParticipants: boolean;



    constructor(private auth: AuthService, private http: HttpClient, private aFirestore: AngularFirestore, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {} };

        this.db = firebase.firestore();
    }

    attendeeModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((doc) => {
                let module = doc.data();
                onResolve(module);
            })
            .catch((err) => onResolve(err));
    }

    getAttendeesByEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('attendees');

        ref
            .onSnapshot((snapshot) => {
                let attendees = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        attendees.push(element.data());
                    });
                }
                onResolve(attendees);
            });
    }

    /**
     * Get attendees for event
     * @param eventId 
     */
    getAttendeesForEvent(eventId: string) {
        return (this.aFirestore.collection('events')
            .doc(eventId)
            .collection<Attendee>('attendees')
            .valueChanges()
        )
    }

    getAttendees(eventId, typeOrder) {
        const ref = this.aFirestore.firestore
            .collection(`events/${eventId}/attendees`);
        
        let refQ: firebase.firestore.Query<firebase.firestore.DocumentData>;
        switch (typeOrder) {
        case 'asc': //a-z
            refQ = ref.orderBy('queryName', 'asc');
            break;
        case 'desc': //z-a
            refQ = ref.orderBy('queryName', 'desc');
            break;
        case 'oldest'://antiho-recente
            refQ = ref.orderBy('createdAt', 'asc');
            break;
        case 'recent': //recente-antigo
            refQ = ref.orderBy('createdAt', 'desc');
            break;
        case 'id': //ID
            refQ = ref.orderBy('identifier');
            break;
        }
        return new Promise<DocumentData[]>((resolve, reject) => {
            refQ.get()
                .then(result => resolve(result.docs.map(doc => doc.data())))
                .catch(err => reject(err))
        });
    }

    getAttendee(eventId, moduleId, attendeeId, onResolve) {
        let firestore = this.aFirestore.firestore;

        firestore.collection("events").doc(eventId).collection("attendees").doc(attendeeId).onSnapshot((data) => {
            let result = {
                code: 200,
                message: 'success',
                result: data.data()
            };

            onResolve(result)
        })
    }

    async getAttendeesFromArrayIdsPromise(eventId: string, attendeeIds: string[]) : Promise<any[]>{
        let firestore = this.aFirestore.firestore;
        const length = Math.ceil(attendeeIds.length / 10);
        const promises: Array<Promise<any>> = []
        for(let i = 0 ; i < length ; i++){
            promises.push(firestore.collection("events").doc(eventId).collection("attendees").where('uid','in',[...attendeeIds].splice(i*10,10)).get())
        }
        const tab = await Promise.all(promises);
        return tab.map(ref => [...ref.docs].map(_ => _.data())).reduce((acc, curr) => [...acc].concat(curr), []) 
    }

    /**
    *get all event participant modules.
    *@param eventId
    *@returns onResolve
  */
    getModulesAttendeeEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        const modules = []

        const ref = db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.ATTENDEE)
        ref.onSnapshot((snapshot) => {
            snapshot.forEach((element) => {
                const module = element.data()
                modules.push(module)
            })

            onResolve(modules)
        })
    }


    verifyExistAttendeeByEmail(eventId: string, email: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('attendees').where("email", "==", email).get()
            .then((data) => {
                let docNotExist = true;
                data.forEach(doc => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {

            })
    }

    verifyExistAttendeeByIdentifier(eventId, identifier, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('attendees').where("identifier", "==", identifier).get()
            .then((data) => {
                let docNotExist = true;

                data.forEach(doc => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                })

                if (docNotExist == true) {
                    onResolve(false)
                }
            })
            .catch((error) => {

            })
    }

    verifyIdentifierValidByAttendee(eventId, identifier, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events')
            .doc(eventId)
            .collection('attendees')
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach(doc => {
                    if (doc.exists) {
                        let attendee = doc.data();

                        if (attendeeId !== attendee.uid) {
                            onResolve(false);
                        } else {
                            onResolve(true);
                        }
                    }
                })

                if (docNotExist) {
                    onResolve(true);
                }
            })
            .catch((error) => {

            })
    }


    verifyEmailValidByAttendee(email, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('users')
            .where("email", "==", email)
            .get()
            .then((data) => {

                if (data.size == 0) {
                    onResolve(true);
                }

                data.forEach(element => {
                    let user = element.data();

                    if (user.uid !== attendeeId) {
                        onResolve(false)

                    } else {
                        onResolve(true);
                    }
                });
            })
    }


    async createAttendee(
        eventId: string, 
        moduleId: string, 
        attendee: Attendee, 
        listCustomFields: DocumentData[],
        userEmail: string, 
        profilePhoto: string
    ) {
        attendee.$moduleId = moduleId;
        const batch = this.db.batch();

        const fetchUser = (await this.db
            .collection('users')
            .where("email", "==", userEmail)
            .get());
        
        if (!fetchUser) {
            return Promise.reject();
        }

        if (fetchUser.size > 0) {
            const uid = fetchUser.docs[0].id;
            attendee.$uid = uid;
            let tmp: any = {
                events: firebase.firestore.FieldValue.arrayUnion(eventId),
                attendeeModules: firebase.firestore.FieldValue.arrayUnion(moduleId)
            }

            if (
                fetchUser.docs[0].data().firstAccess == true &&
                !attendee.$emailRecovery
            ) {
                tmp['emailRecovery'] = attendee.$emailRecovery;
            }

            const refUser = this.db.collection('users').doc(attendee.$uid);
            batch.update(refUser, tmp);
        } else {
            let refCollUser;
            if (!attendee.$uid) {
                refCollUser = this.db.collection('users').doc();
                attendee.$uid = refCollUser.id;
            } else {
                refCollUser = this.db.collection('users').doc(attendee.$uid);
            }
            const userAttr = this.generateUserObject(attendee, eventId, moduleId);
            const auxUser: any = Object.assign({}, userAttr);
            batch.set(refCollUser, auxUser);
        }

        const refAttendee = this.db
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .doc(attendee.$uid);
            
        const refAttendeeMod = this.db
            .collection('modules') 
            .doc(moduleId)
            .collection('attendees')
            .doc(attendee.$uid);
            
        const data: any = Object.assign({}, attendee);
            
        batch.set(refAttendee, data);
        batch.set(refAttendeeMod, data);

        // const checkins = await this.getCheckinsEvent(eventId);
        // for (const checkin of checkins) {
        //     const checkinId = checkin.uid;
        //     const moduleCheckinId = checkin.moduleId;

        //     data.checkinStatus = false;
        //     const ref = this.db
        //         .collection('modules')
        //         .doc(moduleCheckinId)
        //         .collection('checkins')
        //         .doc(checkinId)
        //         .collection('attendees')
        //         .doc(attendee.$uid);
            
        //     batch.set(ref, data);
        // }

        for (let cf of listCustomFields) {
            const refCF = refAttendee.collection('customFields').doc(cf.uid);
            const refCFMod = refAttendeeMod.collection('customFields').doc(cf.uid);

            const tmp = {
                id: cf.uid,
                type: cf.type,
                answer: (!cf.answer) ? null : cf.answer   // type: 'text' -> string, type: 's' -> answerOption.id
            }

            batch.set(refCF, tmp);
            batch.set(refCFMod, tmp);
        }

        if (profilePhoto) {
            const p = new Promise<string>((res, rej) => {
                this.storage.profilePictureAttendees(
                    profilePhoto, 
                    eventId, 
                    attendee.$uid, 
                    (url) => {
                        if (url == null) { 
                            rej();
                        } 
                        else { 
                            res(url); 
                        }
                    }
                );
            });
            const url = await p;
            if (!url) {
                return Promise.reject(url);
            }
            attendee.$photoUrl = url;
        }
        batch.update(refAttendee, {photoUrl: attendee.$photoUrl});
        batch.update(refAttendeeMod, {photoUrl: attendee.$photoUrl});


        return batch.commit();
    }

    generateUserObject(data, eventId, moduleId) {
        let dataFormat = {
            uid: data.uid,
            name: data.name,
            queryName: data.name.toLowerCase(),
            type: data.type,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            phone: "",
            emailRecovery: "",
            events: data.events,
            attendeeModules: data.attendeeModules,
            firstAccess: true //sempre true
        };

        if (data.email != undefined) {
            dataFormat.email = data.email;
        }

        if (data.language != undefined) {
            dataFormat.language = data.language;
        }

        if (data.description != undefined) {
            dataFormat.description = data.description;
        }

        if (data.photoUrl != undefined) {
            dataFormat.photoUrl = data.photoUrl;
        }

        if (data.company != undefined) {
            dataFormat.company = data.company;
        }

        if (data.title != undefined) {
            dataFormat.title = data.title;
        }

        if (data.phone != undefined) {
            dataFormat.phone = data.phone;
        }

        if (data.emailRecovery != undefined) {
            dataFormat.emailRecovery = data.emailRecovery;
        }

        dataFormat.events = firebase.firestore.FieldValue.arrayUnion(eventId)

        dataFormat.attendeeModules = firebase.firestore.FieldValue.arrayUnion(moduleId);

        return dataFormat;
    }

    // updating documents visibility choices
    updateModuleDocumentsVisibility(moduleId: string, field: any, onResolve) {
        const ref = this.db.collection('modules')
                            .doc(moduleId)

        ref.update(field)
            .then(() => {
                onResolve(true)
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    // updating documents visibility choices
    updateUserDocumentsVisibility(eventId: string, moduleId: string, attendeeId: string, field: any, onResolve) {
        const ref1 = this.db.collection('events')
                            .doc(eventId)
                            .collection('attendees')
                            .doc(attendeeId);

        const ref2 = this.db.collection('modules')
                            .doc(moduleId)
                            .collection('attendees')
                            .doc(attendeeId);
                            
        ref1.update(field)
            .then(() => {
                ref2.update(field).then(() => {
                    onResolve(true);
                })
                .catch((error) => {
                    onResolve(error);
                });
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    async editAttendee(
        eventId, 
        moduleId, 
        attendee, 
        customFields, 
        photoProfile, 
        emailChanged
    ) {
        attendee = Object.assign({}, attendee);

        const batch = this.db.batch();

        const refUser = this.db.collection('users').doc(attendee.uid);
        const refAttendee = this.db
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .doc(attendee.uid);
            
        const refAttendeeMod = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('attendees')
            .doc(attendee.uid);
        
        if (emailChanged) {
            const p = new Promise<any>((res) => {
                this.auth.updateUserAuth(attendee.uid, attendee, (response) => { 
                    res(response.result);
                });
            });
            const res = await p;
            if (!res) {
                return Promise.reject();
            }

            if (attendee.emailRecovery !== '') {
                batch.update(refUser, { 
                    email: attendee.email, 
                    emailRecovery: attendee.emailRecovery 
                });
            } else {
                batch.update(refUser, { email: attendee.email });
            }
        } else {
            if (attendee.emailRecovery !== '') {
                batch.update(refUser, { emailRecovery: attendee.emailRecovery });
            }
        }

        for (let cf of customFields) {
            const refCF = refAttendee.collection('customFields').doc(cf.id);
            const refCFMod = refAttendeeMod.collection('customFields').doc(cf.id);

            const tmp = { answer: (!cf.answer) ? null : cf.answer };

            batch.update(refCF, tmp);
            batch.update(refCFMod, tmp);
        }

        // get event checkins
        // const checkins = await this.getCheckinsEvent(eventId);
        // for (const checkin of checkins) {
        //     const checkinId = checkin.uid
        //     const moduleCheckinId = checkin.moduleId

        //     const checkinStatus = await this.getCheckinStatus(
        //         attendee.uid, 
        //         checkinId, 
        //         moduleCheckinId
        //     );

        //     attendee.checkinStatus = (checkinStatus) ? true : false;

        //     const ref = this.db
        //         .collection('modules')
        //         .doc(moduleCheckinId)
        //         .collection('checkins')
        //         .doc(checkinId)
        //         .collection('attendees')
        //         .doc(attendee.uid);
            
        //     batch.update(ref, attendee)
        // }

        if (photoProfile) {
            const p = new Promise<string>((res, rej) => {
                this.storage.profilePictureAttendees(
                    photoProfile, 
                    eventId, 
                    attendee.uid, 
                    (url) => {
                        if (url == null) { rej();} 
                        else { res(url); }
                    }
                );
            });
            const url = await p;
            if (!url) {
                return Promise.reject(url);
            }
            attendee.photoUrl = url;
        }

        batch.update(refAttendee, attendee);
        batch.update(refAttendeeMod, attendee);

        return batch.commit();
    }

    removeAttendees(eventId, moduleId, listRemoveUids) {
        return this.http.post(
            PathApi.baseUrl + PathApi.dbBulkDataFlowRecDocDel, 
            { 
                refs: listRemoveUids.reduce((acc, id) => {
                    return [
                        ...acc,
                        `events/${eventId}/attendees/${id}`,
                        `modules/${moduleId}/attendees/${id}`
                    ]
                }, [])
            },
            this.requestOptions
        ).toPromise();
    }



    removeAttendeeAllEvents(userId, onResolve) {
        let refUser = this.db.collection('users').doc(userId);

        refUser.get()
            .then((data) => {
                let user = data.data();

                let batch = this.db.batch();
                for (let moduleId of user.attendeeModules) {
                    let refModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(user.uid);
                    batch.delete(refModule);
                }

                for (let eventId of user.events) {
                    let refEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(user.uid);
                    batch.delete(refEvent);
                }

                this.auth.removeUserAuth(user.uid, (data) => {
                    if (data.result == true) {

                        batch.commit()
                            .then(() => {
                                onResolve(true);
                            })
                            .catch((err) => {
                                onResolve(err)
                            })
                    }
                })

            })
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }


    changeOrderItems(eventId: string, moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ orderUsers: typeOrder })
        db.collection("events").doc(eventId).collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    importAttendees(eventId: string, moduleId: string, nameModule: NameModule, attendees: Array<Attendee>, groupModuleId, onResolve) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            nameModule: nameModule,
            attendees: attendees,
            groupModuleId: groupModuleId
        }
        this.http.post(PathApi.baseUrl + PathApi.dbAttendeeImportAttendee, body, this.requestOptions)
            .subscribe((result) => {
                onResolve(result)
            });
    }

    takeChangesInModule(moduleId: string, eventId: string, changes) {
        let db = this.aFirestore.firestore;

        let refModule = db.collection('modules').doc(moduleId);
        let refEventModule = db.collection('events').doc(eventId).collection('modules').doc(moduleId);

        refModule.update(changes);
        refEventModule.update(changes);
    }

    // ================================= CONFIG MODULE ====================================

    getAllowedEditProfile(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('modules').doc(moduleId).get()
            .then((data) => {
                let aux = data.data();
                let result = aux.allowedEditProfile;

                onResolve(result)
            })
    }

    changeAllowedEditProfile(moduleId, eventId, status, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('modules').doc(moduleId).update({
            allowedEditProfile: status
        })
        db.collection('modules').doc(moduleId).update({
            allowedEditProfile: status
        }).then(() => {
            onResolve("success");
        }).catch(() => {
            onResolve("error");
        })
    }



    // ================================== EXPORT ATTENDEES =================================

    public listAttendees = [];
    public errorExport = false;
    exportAttendees(moduleId: string, typeOrder: string, onResolve) {
        this.requestOptions.firstGet = true;
        this.requestOptions.lastDocument = null;
        this.listAttendees = [];

        this.getAttendeesExport(moduleId, typeOrder, (data) => {

            if (!this.errorExport) {
                let result = {
                    code: 200,
                    message: 'success',
                    result: this.listAttendees
                }

                onResolve(result);
            } else {
                let error = {
                    code: 404,
                    message: 'error',
                    result: null
                }

                onResolve(error)
            }
        })
    }


    getAttendeesExport(moduleId: string, typeOrder: string, onResolve) {
        let sizeArray = null;

        this.http.post(PathApi.baseUrl + PathApi.dbAttendeesExportAttendees + '?moduleId=' + moduleId + '&typeOrder=' +
            typeOrder, this.requestOptions)
            .subscribe((data) => {
                console.log(data['code'])
                //console.log(data['result'])
                if (data['code'] == 200) {
                    this.listAttendees = this.listAttendees.concat(data['result'])
                    sizeArray = data['result'].length;
                    this.requestOptions.firstGet = false;
                    this.requestOptions.lastDocument = data['lastDocument'];

                    if (sizeArray < 500) {
                        onResolve(data['result'])
                    } else {
                        this.getAttendeesExport(moduleId, typeOrder, onResolve);
                    }
                } else {
                    this.errorExport = true;
                }
            })
    }

    getAttendeesFieldOptions(moduleId: string, onResolve) { // TODO
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux['fields']);
            });
    }

    changeRequiredField(eventId: string, moduleId: string, field: string, value: boolean, allFields) { // TODO
        let db = this.aFirestore.firestore;

        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        allFields[field].required = value;

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }

    changeUniqueEditField(eventId: string, moduleId: string, field: string, value: boolean, allFields) { // TODO
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;
        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }

    changeHideField(eventId: string, moduleId: string, field: string, value: boolean, allFields) {  // TODO
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }

    /**
     * Change chat allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeChatUse(eventId: string, moduleId: string, status: boolean) {
        let db = this.aFirestore.firestore;
        let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        eventRef.update({ allow_chat: status });
        moduleRef.update({ allow_chat: status });
    }

    /**
     * Change chat allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeFilterVisibility(moduleId: string, status: boolean,type: string, onResolve) {
        let db = this.aFirestore.firestore;
        // let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        const obj = {}
        if(type === 'company'){
            obj['allowFilterCompany'] = status
        } else if(type === 'post') {
            obj['allowFilterPost'] = status
        } else if (type === 'customs') {
            obj['allowFilterCustomFields'] = status
        } else {
            obj[type] = status
        }
        moduleRef.update(obj).then(() => {
            onResolve({type: type, value: true});
        })
        .catch(() => {
            onResolve({type: type, value: false});
        });
    }

    editPropVisibility(moduleId: string, prop: string, status: any, onResolve) {
        let db = this.aFirestore.firestore;
        const obj = {}
        obj[prop] = status
        db.collection('modules').doc(moduleId)
            .update(obj)
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }

    /**
     * Change visio allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeVisioUse(eventId: string, moduleId: string, status: boolean) {
        let db = this.aFirestore.firestore;
        let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        eventRef.update({ allow_visio: status });
        moduleRef.update({ allow_visio: status });
    }

    resetAccount(attendee: Attendee) {
        return new Promise((resolve, reject) => {
            this.auth.verifyEmailAuth(attendee['email'])
                .then((response) => {
                    if (response['code'] == 0) {
                        // user exist
                        resolve(0);
                        this.reconfigureUser(response['uid'], attendee)
                            .then((snap) => {
                                if (snap) {
                                    resolve(0);
                                } else {
                                    reject(2);
                                }
                            })
                    } else if (response['code'] == 1) {
                        // user doesn't exist
                        resolve(1);
                    } else if (response['code'] == 2) {
                        // error to find e-mail
                        reject(2);
                    }
                })
        })
    }

    reconfigureUser(authUid: string, attendee: Attendee) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let oldUid;
            let user = null;

            db
                .collection("users")
                .where('email', '==', attendee['email'])
                .get()
                .then((snapshot) => {
                    if (snapshot.size >= 1) {
                        snapshot.forEach(element => {
                            if (user == null) {
                                user = element.data();
                                let aux = user;
                                oldUid = user.uid;

                                let eventsId = aux.events;
                                let data = {
                                    name: aux.name,
                                    type: aux.type,
                                    email: aux.email,
                                    photoUrl: aux.photoUrl,
                                    createdAt: aux.createdAt,
                                    company: aux.company,
                                    title: aux.title,
                                    description: aux.description,
                                    recoveryEmail: aux.recoveryEmail,
                                    phone: null,
                                    uid: authUid,
                                    events: aux.events,
                                    attendeeModules: aux.attendeeModules,
                                    firstAccess: false,
                                    language: aux.language
                                }

                                let userObj = this.userModelData(data);
                                let cont = 0;
                                for (let idEvent of eventsId) {
                                    let batch = null;
                                    batch = db.batch();

                                    // apgar no path users antigo
                                    let oldUserRef = db.collection("users").doc(oldUid);
                                    batch.delete(oldUserRef);

                                    // adicionar no path users o novo
                                    let newUserRef = db.collection("users").doc(authUid);
                                    batch.set(newUserRef, userObj);

                                    let oldAttendeeRef = db.collection("events").doc(idEvent).collection("attendees").doc(oldUid);
                                    let newAttendeeRef = db.collection("events").doc(idEvent).collection("attendees").doc(authUid);

                                    let listCustomField = [];
                                    oldAttendeeRef.collection('customFields').get().then((data) => {
                                        data.forEach(doc => {
                                            let custom = doc.data();
                                            listCustomField.push(custom);
                                        });
                                    })

                                    oldAttendeeRef.get().then((data) => {
                                        let attendee = data.data();
                                        attendee.uid = authUid;
                                        attendee.firstAccess = false;
                                        let oldModulesAttendeeRef = db.collection("modules").doc(attendee.moduleId).collection('attendees')
                                            .doc(oldUid);
                                        let newModulesAttendeeRef = db.collection("modules").doc(attendee.moduleId).collection('attendees')
                                            .doc(authUid);

                                        // apagar no path events -> attendes
                                        batch.delete(oldAttendeeRef);
                                        // apagar no path modules -> attendees antigo
                                        batch.delete(oldModulesAttendeeRef);

                                        // adicionar no path events -> attendees
                                        batch.set(newAttendeeRef, attendee);
                                        // adicionar no path modules -> attendees novo
                                        batch.set(newModulesAttendeeRef, attendee);

                                        batch.commit().then((batchOk) => {
                                            let batchCustom = db.batch();
                                            for (let custom of listCustomField) {
                                                let refCustomEventAttendee = newAttendeeRef.collection('customFields').doc(custom.uid);
                                                let refCustomModuleAttendee = newModulesAttendeeRef.collection('customFields').doc(custom.uid);

                                                batchCustom.set(refCustomEventAttendee, custom);
                                                batchCustom.set(refCustomModuleAttendee, custom);
                                            }

                                            batchCustom.commit().then(() => {
                                            })

                                            if (cont == eventsId.length - 1) {
                                                resolve(true);
                                            }
                                            cont++;
                                        }).catch((batchError) => {
                                            // remove da autenticação caso dê erro no banco
                                            // admin.auth().deleteUser(authUid);

                                            reject(false);
                                        })
                                    });
                                }
                            }
                        });
                    }
                });
        })

    }

    userModelData(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: null,
            attendeeModules: null,
            firstAccess: null,
            uid: "",
            edited_profile: false
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        if (user.events != undefined) {
            userFormat.events = user.events;
        }

        userFormat.attendeeModules = user.attendeeModules

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }



    /**
    * get all checks in of the event.
    * @param {string} eventId
    * @returns Promise<Checkin[]>
    */

    getCheckinsEvent(eventId: string): Promise<Checkin[]> {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore
            let checkins = []

            // get checkins module
            db.collection('events')
                .doc(eventId)
                .collection('modules')
                .where('type', '==', TypeModule.CHECKIN)
                .get()
                .then((snapshot) => {
                    if (snapshot.size <= 0)
                        resolve([])

                    // get all module checkins.
                    snapshot.forEach((element) => {
                        const module = element.data()
                        const moduleId = module.uid

                        db.collection('modules')
                            .doc(moduleId)
                            .collection('checkins')
                            .get()
                            .then((childSnapshot) => {
                                childSnapshot.forEach((element) => {
                                    const checkin = element.data()
                                    checkins.push(checkin)
                                })

                                resolve(checkins)
                            })
                    })
                })
        })
    }


    /**
     * get participant checkin status.
     * @param {string} attendeeId
     * @param {string} checkinId
     * @param {string} checkinModuleId
     * @returns string
     */

    getCheckinStatus(attendeeId: string, checkinId: string, checkinModuleId: string) {
        return (
            this.aFirestore.doc(
                `modules/${checkinModuleId}/checkins/${checkinId}/attendees/${attendeeId}`
            ).get().pipe(map((snap: DocumentSnapshot<any>) => {
                if (!snap || !snap.data()) return undefined;
                return snap.data().checkinStatus;
            })).toPromise()
        )
    }
}
