import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DbWordCloudProvider } from "src/app/providers/database/db-word-cloud";
import { ActivatedRoute, Router } from "@angular/router";
import { WordCloud } from "src/app/models/word-cloud";
import { TranslateService } from "@ngx-translate/core";

type AOA = Array<Array<any>>;
declare let $: any;

@Component({
    selector: "app-word-cloud",
    templateUrl: "./word-cloud.component.html",
    styleUrls: ["./word-cloud.component.scss"],
})
export class WordCloudComponent implements OnInit {
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("clearWordCloudSwal") public clearWordCloudSwal: SwalComponent;
    @ViewChild("removeWordCloudSwal") public removeWordCloudSwal: SwalComponent;
    dataExportWords: AOA = [[]];
    data: any;
    wordClouds: Array<WordCloud> = [];
    eventId: string = null;
    moduleId: string = null;
    cloudEditId: string = null;
    cloudRemoveId: string = null;
    cloudClearId: string = null;

    formNewCloud = new FormGroup({
        title: new FormControl(null),
        subtitle: new FormControl(null),
        max_characters: new FormControl(null),
        visibility: new FormControl(null),
        enableWordSuggestions: new FormControl("true"),
    });

    formEditCloud = new FormGroup({
        title: new FormControl(null),
        subtitle: new FormControl(null),
        max_characters: new FormControl(null),
        enableWordSuggestions: new FormControl("true"),
    });

    constructor(
        private route: ActivatedRoute,
        private dbWordCloud: DbWordCloudProvider,
        private router: Router,
        private translateService: TranslateService
    ) {
        this.moduleId = this.route.snapshot.params["moduleId"];
        this.eventId = this.route.pathFromRoot[1]["params"]["_value"]["uid"];
    }

    ngOnInit() {
        this.fetchWordCloud();
    }

    fetchWordCloud() {
        this.dbWordCloud.fetchWordCloud(this.moduleId)
            .then((snapshot) => {
                this.wordClouds = <WordCloud[]>snapshot.docs.map((doc) => doc.data());
            })
            .catch((err) => {
                this.wordClouds = [];
                console.error(err);
            })
    }

    toggleVisibility(cloud: WordCloud, status?: boolean) {
        cloud['visibility'] = status ?? !cloud['visibility'];
        this.dbWordCloud.toggleWordCloudVisibility(this.moduleId, cloud['uid'], cloud['visibility'])
            .catch((err) => {
                    cloud['visibility'] = !cloud['visibility'];
                    console.error(err);
                }
            )
    }

    setAllWordCloudVisibility(status: boolean) {
        Promise.all(
            this.wordClouds.map((wordCloud) => this.toggleVisibility(wordCloud, status))
        )
        .then(() => this.successSwal.fire())
        .catch((err) => {
            console.error(err);
            this.errorSwal.fire();
        })
    }

    clearResults() {
        this.dbWordCloud.clearResult(this.moduleId, this.cloudClearId)
            .subscribe({
                next: () => this.successSwal.fire(),
                error: (err) => {
                    console.error(err)
                    this.errorSwal.fire();
                },
            })
    }

    newCloud() {
        const cloud: WordCloud = new WordCloud();
        const aux = this.formNewCloud.value;

        cloud._title = aux.title;
        cloud._subtitle = aux.subtitle;
        cloud._max_characters = aux.max_characters;
        cloud._visibility = true;
        cloud._enableWordSuggestions = aux.enableWordSuggestions == 'true';
        this.dbWordCloud.createWordCloud(this.moduleId, cloud)
            .then(() => {
                this.fetchWordCloud();
                this.successSwal.fire()
            })
            .catch((err) => {
                console.error(err)
                this.errorSwal.fire();
            })
    }

    getCloudEdit(cloud) {
        this.cloudEditId = cloud.uid;

        this.formEditCloud.patchValue({
            title: cloud["title"],
            subtitle: cloud["subtitle"],
            max_characters: cloud["max_characters"],
            enableWordSuggestions: cloud["enableWordSuggestions"] ?? 'false',
        });
    }

    getClearCloud(cloud) {
        this.cloudClearId = cloud.uid;
        this.clearWordCloudSwal.fire();
    }

    getRemoveCloud(cloud) {
        this.cloudRemoveId = cloud.uid;
        this.removeWordCloudSwal.fire();
    }

    editCloud() {
        const cloudEdit: WordCloud = new WordCloud();
        cloudEdit._title = this.formEditCloud.value.title;
        cloudEdit._subtitle = this.formEditCloud.value.subtitle;
        cloudEdit._max_characters = this.formEditCloud.value.max_characters;
        cloudEdit._enableWordSuggestions = this.formEditCloud.value.enableWordSuggestions == 'true';

        this.dbWordCloud.editWordCloud(this.moduleId, this.cloudEditId, cloudEdit)
            .then(() => {
                this.fetchWordCloud();
                this.successSwal.fire();
            })
            .catch((err) => {
                console.error(err);
                this.errorSwal.fire();
            })
    }

    removeCloud() {
        this.dbWordCloud.deleteWordCloud(this.moduleId, this.cloudRemoveId)
            .subscribe({
                next: () => {
                    this.fetchWordCloud();
                    this.successSwal.fire();
                },
                error: (err) => {
                    console.error(err)
                    this.errorSwal.fire();
                },
            })
    }

    prepareExportReport(cloud) {
        this.dataExportWords = [];

        this.dbWordCloud.exportCloud(this.moduleId, cloud.uid)
            .subscribe({
                next: (data) => {
                    if (data['result'].length > 0) {
                        this.dataExportWords = data['result'];
                        this.dataExportWords.unshift([
                            this.translateService.instant("global.name"),
                            this.translateService.instant("global.email"),
                            this.translateService.instant("global.word"),
                        ]);

                        this.exportReport();
                    } else {
                        //sem dados pra exportar
                    }
                },
                error: (err) => {
                    console.error(err)
                    this.errorSwal.fire();
                },
            })
    }

    // export data in excel
    exportReport() {
        const wscols: XLSX.ColInfo[] = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { hidden: false }, // hide column
        ];

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportWords
        );

        /* TEST: column props */
        ws["!cols"] = wscols;

        /* TEST: row props */
        ws["!rows"] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "word_cloud.xlsx");

        this.data = null;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    openPage(page) {
        this.router.navigate([page, { eventId: this.eventId }]);
    }
}
