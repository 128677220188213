import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleSponsor extends Module {
    typeVision: number;
    selected: boolean;
    categories: string[] = ["sponsor"];
    sortBy: string = "asc";

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number) {
        super(name, icon, TypeModule.SPONSOR, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.selected = true;
    }
}
