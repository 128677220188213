import { NameModule } from './../../../../../models/name-module';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Speaker } from 'src/app/models/speakers';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../../../providers/auth/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { eventCustomFieldAnswerOption } from 'src/app/models/eventCustomFieldAnswerOption';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbDocuments } from 'src/app/providers/database/db-documents';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Languages } from 'src/app/models/languages';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { TypeUser } from 'src/app/enums/typeUser';
import { Attendee } from 'src/app/models/attendees';
import { Subscription } from 'rxjs';
import { DocumentData } from '@angular/fire/firestore';
import { DBCustomFieldsProvider } from '../../../../../providers/database/db-custom-fields.service';

declare let $: any;

@Component({
    selector: 'app-create-speaker',
    templateUrl: './create-speaker.component.html',
    styleUrls: ['./create-speaker.component.scss']
})

export class CreateSpeakerComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
    @ViewChild('errorEmailAttendeeSwal') public errorEmailAttendeeSwal: SwalComponent;
    @ViewChild('errorEmailAdmSwal') public errorEmailAdmSwal: SwalComponent;
    @ViewChild('errorIdentifierSwal') public errorIdentifierSwal: SwalComponent;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    formValidation: FormGroup;
    public eventId: string = null;
    public moduleId: string = null;
    module: any;
    loader: boolean = false;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;

    cfAnswerOptns = {};
    public listCustomFields: DocumentData[] = [];
    // GROUPS FILTER

    listGroup: Array<Group> = [];
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = '';
    errorFormGroup: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<any> = [];
    selectedDocument: Array<any> = [];
    filteredListDocuments = [];
    queryDocument = '';
    errorFormDocument: boolean = false;
    event: any;
    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string
    principalEventLangFormated: string = 'PtBR';

    listAttendees: Array<Attendee>
    selectedAttendee = [];
    filteredListAttendees = [];
    queryAttendee = '';

    attendeesField: string = "";
    fieldsCustomOptions: any;
    principalEventLanguageFormated: any;
    allLanguages = new NameModule('PtBR', 'EnUS', 'EsES', 'FrFR', 'DeDE');


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbSpeaker: DbSpeakersProvider,
        private dbAttendee: DbAttendeesProvider,
        private router: Router,
        private auth: AuthService,
        private dbGroups: DbGroupsProvider,
        private dbDocuments: DbDocuments,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private dbUser: DbAdminUserProvider,
        private SCustomFields: DBCustomFieldsProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        this.formValidation = fb.group({
            'photoUrl': [null],
            'id': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
            'email': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'emailRecovery': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'title_ptBR': [""],
            'title_enUS': [""],
            'title_esES': [""],
            'title_frFR': [""],
            'title_deDE': [""],
            'company': [null, Validators.compose([Validators.maxLength(200)])],
            'website': [null, Validators.compose([Validators.maxLength(200)])],
            'facebook': [null, Validators.compose([Validators.maxLength(200)])],
            'instagram': [null, Validators.compose([Validators.maxLength(200)])],
            'linkedin': [null, Validators.compose([Validators.maxLength(200)])],
            'twitter': [null, Validators.compose([Validators.maxLength(200)])],
            'description_ptBR': [""],
            'description_enUS': [""],
            'description_esES': [""],
            'description_frFR': [""],
            'description_deDE': [""],
            'groups': [null],
            'documents': [null],
            'attendees': [null]
        });
    }

    ngOnInit() {
        this.getCustomFields();
        this.getModule();
        this.startEvent()
        this.getAttendeesForEvent();
        this.getGroups();
        this.getDocuments();
        this.startEvent();
        this.getUserLanguage();
    }

    

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
            this.principalEventLangFormated = this.convertLangFormat(this.userLanguage);
        })
    }

    getModule() {
        this.dbSpeaker.speakerModule(this.moduleId, (module) => {
            this.module = module;
            this.attendeesField = module.attendeesField;
            console.log("Module: ", module);
        });
    }

    getAttendeesForEvent() {
        this.subscriptions.push(this.dbAttendee.getAttendeesForEvent(this.eventId).subscribe((attendees) => {
            this.listAttendees = [];
            this.listAttendees = attendees;
        }))
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
            this.languages = event.languages;
        });
    }


    fillOtherLanguages(item: NameModule) {
        item.DeDE = item[this.principalEventLanguageFormated];
        item.EnUS = item[this.principalEventLanguageFormated];
        item.EsES = item[this.principalEventLanguageFormated];
        item.FrFR = item[this.principalEventLanguageFormated];
        item.PtBR = item[this.principalEventLanguageFormated];
    }

    getGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = [];
            this.listGroup = result['groups'];
        });
    }

    getDocuments() {
        this.dbDocuments.getEventDocuments(this.eventId, (documents: Array<any>) => {
            this.listDocument = [];
            this.listDocument = documents;
        });
    }

    getCustomFields() {
        this.SCustomFields.getCustomFields(this.eventId, this.moduleId)
            .then((customFields) => {
                customFields.forEach((cf) => {
                    if (cf.type == 's') {
                        this.cfAnswerOptns[cf.uid] = cf.answerOptions;
                    }
                })
                this.listCustomFields = customFields;
            });
    }

    createSpeaker(speakerForm) {
        this.loader = true;
        const name = speakerForm.name.trim();
        const speaker = new Speaker(name, 4, this.eventId);

        speaker.$email = (speakerForm.email) ?
            speakerForm.email.toLowerCase() : speakerForm.email;

        speaker.$emailRecovery = (speakerForm.emailRecovery) ? 
            speakerForm.emailRecovery.toLowerCase() : speakerForm.emailRecovery;

        speaker.$identifier = speakerForm.id;
        speaker.$description = {
            PtBR: speakerForm.description_ptBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EnUS: speakerForm.description_enUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EsES: speakerForm.description_esES.replace(/href="/g, 'class="wysiwyg-link" href="'),
            FrFR: speakerForm.description_frFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            DeDE: speakerForm.description_deDE.replace(/href="/g, 'class="wysiwyg-link" href="'),
        };
        speaker.$company = speakerForm.company;
        speaker.$title = {
            PtBR: speakerForm.title_ptBR,
            EnUS: speakerForm.title_enUS,
            EsES: speakerForm.title_esES,
            FrFR: speakerForm.title_frFR,
            DeDE: speakerForm.title_deDE,
        };
        speaker.$website = speakerForm.website;
        speaker.$facebook = speakerForm.facebook;
        speaker.$instagram = speakerForm.instagram;
        speaker.$linkedin = speakerForm.linkedin;
        speaker.$twitter = speakerForm.twitter;
        speaker.$phone = null;
        speaker.$createdAt = Date.now() / 1000 | 0;
        // speaker.$firstAccess = null;
        speaker.$moduleId = this.moduleId;

        if (this.selectedGroup.length > 0) {    // groups
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                speaker.$groups[group['uid']] = Object.assign({}, group)
            }
        }

        if (this.selectedAttendee.length > 0) {
            for (let index in this.selectedAttendee) {
                const attendee = this.selectedAttendee[index]
                speaker.$attendees[attendee.uid] = Object.assign({}, attendee)
            }
        }

        speaker.$language = this.event.language

        if (this.selectedDocument.length > 0) { // documents
            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                speaker.$documents[document['uid']] = Object.assign({}, document)
            }
        }
        
        const userEmail = speakerForm.email;
        this.dbSpeaker.createSpeaker(
            this.eventId, 
            this.moduleId, 
            speaker, 
            this.listCustomFields,
            userEmail, 
            this.finalFile
        ).then(() => {
            this.loader = false;
            this.successSwal.fire();
        }).catch((error) => {
            this.loader = false;
            this.errorSwal.fire();
        });
    }

    /**
     * Filter attendees
     */
    filterAttendees() {
        if (this.queryAttendee !== "") {
            this.filteredListAttendees = this.listAttendees.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryAttendee.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListAttendees = [];
        }
    }

    /**
     * Select an attendee
     * @param item 
     */
    selectAttendee(item) {
        if (this.selectedAttendee.length > 0) {
            const index = this.selectedAttendee.indexOf(item);
            if (index == -1) {
                this.selectedAttendee.push(item);
            }
        } else {
            this.selectedAttendee.push(item);
        }
        this.queryAttendee = '';
        this.filteredListAttendees = [];
    }

    /**
     * Remove selected attendee
     * @param item 
     */
    removeSelectedAttendee(item) {
        this.selectedAttendee.splice(this.selectedAttendee.indexOf(item), 1);
    }

    /**
     * On upload image file
     * @param $event 
     */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }

    redirectList() {
        this.router.navigate([`/event/${this.eventId}/speakers/${this.moduleId}`]);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroups = [];
        }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = '';
        this.filteredListGroups = [];
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }




    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(function (el) {
                return el.name[this.principalEventLangFormated].toLowerCase().indexOf(this.queryDocument.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument.indexOf(item);
            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }
        this.queryDocument = '';
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeDocument(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }
}
