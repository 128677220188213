export class DescriptionEvent {
    en_US: string
    pt_BR: string
    es_ES: string
    fr_FR: string
    de_DE: string

    constructor() {
        this.en_US = null
        this.pt_BR= null
        this.es_ES= null
        this.fr_FR= null
        this.de_DE = null
    }
}