import { async } from "@angular/core/testing";
import { GetNameModule } from "./../../pipes/get-name-module";
//     Huge file for going to specific part write in search the following

//     -> general analytics 'General Analytics'
//     -> attendees analytics 'Attendees Analytics'
//     -> chats analytics 'Chats Analytics'
//     -> feeds analytics 'Feeds Analytics'
//     -> documents analytics 'Documents Analytics'
//     -> galleries analytics 'Galleries Analytics'
//     -> schedules analytics 'Schedules Analytics'
//     -> speakers analytics 'Speakers Analytics'
//     -> trackings analytics Trackings Analytics
//     -> users analytics 'Users Analytics'
//     -> visios analytics 'Visios Analytics'

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map, switchMap } from "rxjs/operators";
import { of, forkJoin, Subscription, zip, from } from "rxjs";
import * as XLSX from "xlsx";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/reducers";
import {
    UpdateGeneralAnalytics,
    UpdateBestConsultedModules,
    UpdateTotalAccess,
    UpdateTotalProfilEdition,
    UpdateUsersAnalytics,
    UpdateTotalAttendees,
    UpdateTotalSpeakers,
    UpdateTotalUniqueUsers,
    UpdateCurrentConnectedAttendees,
    UpdateCurrentConnectedSpeakers,
    UpdateVisiosAnalytics,
    UpdateTotalRoomsForEvent,
    UpdateTotalPIM,
    UpdateTotalRoomsBetween2,
    UpdateDocumentsAnalytics,
    UpdateBestDocuments,
    UpdateGalleriesAnalytics,
    UpdateBestImages,
    UpdateTotalDocuments,
    UpdateTotalImages,
    UpdateChatsAnalytics,
    UpdateTotalChats,
    UpdateTotalMessagesChats,
    UpdateTotalDiscussionGroups,
    UpdateTotalMessagesDiscussionGroups,
    UpdateTotalDiscussionGroupsUsers,
    UpdateTotalChatsUsers,
    UpdateFeedsAnalytics,
    UpdateTotalFeedsPosts,
    UpdateBestLikedFeeds,
    UpdateBestCommentedFeeds,
    UpdateTotalFeedsLikes,
    UpdateTotalFeedsComments,
    UpdateTrackingAnalytics,
    UpdateUserRoadTrackingAnalytics,
    UpdateSchedulesAnalytics,
    UpdateTotalSchedulesModulesAnalytics,
    UpdateTotalSchedulesSessionsAnalytics,
    UpdateAttendeesAnalytics,
    UpdateTotalAttendeesModulesAnalytics,
    UpdateTotalAttendeesAnalytics,
    UpdateSpeakersAnalytics,
    UpdateTotalSpeakersModulesAnalytics,
    UpdateTotalSpeakersAnalytics,
} from "src/app/shared/actions/analytics.actions";
import { Post } from "src/app/models/post";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as _ from "lodash";

@Injectable({
    providedIn: "root",
})
export class DbAnalyticsProvider {
    subscriptionConnectedAttendees: Subscription;
    subscriptionConnectedSpeakers: Subscription;

    attendees_analytics_cell_names = [
        "Identifier",
        "Name",
        "Email",
        "Poste",
        "Company",
        "First access",
        "Total access",
        "Total time on",
    ];

    attendees_tracks_analytics_cell_names = [
        "Attendee id",
        "Attendee identifier",
        "Attendee name",
        "Attendee email",
        "Attendee position",
        "Attendee company",
        "Profil id",
        "Profil name",
        "Track start date",
    ];

    chats_analytics_cell_names = [
        "Chat id",
        "Chat type",
        "Number of members",
        "Number of message",
        "Id member 1",
        "Name member 1",
        "Email member 1",
        "Poste member 1",
        "Enterprise member 1",
        "Id member 2",
        "Name member 2",
        "Email member 2",
        "Poste member 2",
        "Enterprise member 2",
    ];

    documents_analytics_cell_names = [
        "Document id",
        "Document name",
        "URL",
        "Total access",
    ];
    documents_open_analytics_cell_names = [
        "Document id",
        "Document name",
        "URL",
        "User id",
        "User identifier",
        "User email",
        "User name",
        "User poste",
        "User company",
        "Access at",
    ];

    general_analytics_cell_names = ["Module", "Total access", "Unique access"];

    timeTracking_analytics_cell_names = [
        "User id",
        "Username",
        "User email",
        "Track start date",
        "Track end date",
        "Total time (seconds)",
        "Total time",
    ];

    feeds_analytics_cell_names = [
        "Id",
        "Module",
        "Author",
        "Post",
        "Post image",
        "Date",
        "Likes",
        "Comments",
    ];

    galleries_analytics_cell_names = ["Image name", "URL", "Total access"];

    schedule_analytics_cell_names = [
        "Id",
        "Name",
        "Date",
        "Start time",
        "End time",
        "Total acces",
        "Unique access",
        "Total time in seconds",
    ];

    speakers_analytics_cell_names = [
        "Identifier",
        "Name",
        "Email",
        "Poste",
        "Company",
        "First access",
        "Total access",
        "Total time on",
    ];

    speakers_tracks_analytics_cell_names = [
        "Speaker id",
        "Speaker identifier",
        "Speaker name",
        "Speaker email",
        "Speaker position",
        "Speaker company",
        "Profil id",
        "Profil name",
        "Track start date",
    ];

    schedule_session_analytics_cell_names = [
        "User id",
        "User identifier",
        "User name",
        "User email",
        "User position",
        "User company",
        "Session id",
        "Session name",
        "Date",
        "Start time",
        "End time",
        "Track start date",
        "Track end date",
        "Total time in seconds",
    ];

    tracking_road_users_analytics_cell_names = [
        "User id",
        "User email",
        "User name",
        "Type number",
        "Type name",
        "Track id",
        "Track name",
        "Track at",
    ];

    users_analytics_cell_names = [
        "User id",
        "user identifier",
        "User name",
        "User email",
        "Edit profile",
        "User accessed",
        "Total access",
        "First access",
        "Last access",
        "Android",
        "Ios",
        "Web",
        "Total time in seconds",
    ];

    visio_analytics_cell_names = [
        "User id",
        "User identifier",
        "User email",
        "Session id",
        "Session name",
        "Access at",
    ];

    constructor(
        private afs: AngularFirestore,
        private store: Store<AppState>,
        private https: HttpClient
    ) {}

    /**************************************/
    /********** General analytics *********/
    /**************************************/

    /**
     * Get all general analytics
     * @param eventId
     */
    getGeneralAnalytics(eventId: string) {
        let obsArray = [];
        let obsModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules", (ref) =>
                ref.orderBy("total_access", "desc").limit(5)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsModules);

        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendees);

        let obsTotalAccess = this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("total-user-access")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsTotalAccess);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateGeneralAnalytics({
                            totalAccess: 0,
                            totalProfilEdition: 0,
                            bestConsultedModules: [],
                        })
                    );
                    return of(false);
                }

                // Update best consulted modules
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(
                        new UpdateBestConsultedModules(result[0])
                    );
                } else {
                    this.store.dispatch(new UpdateBestConsultedModules([]));
                }

                // Update total profil edition
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalProfilEdition(
                            result[1].filter(
                                (attendee) => attendee.edited_profile == true
                            ).length
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalProfilEdition(0));
                }

                // Update total access
                if (result[2] && result[2].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalAccess(result[2].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalAccess(0));
                }

                return of(true);
            })
        );
    }

    /**
     * Export analytics for general
     * @param eventId
     */
    exportGeneralAnalytics(eventId: string, userLanguage: string) {
        let obsArray = [];

        // Get modules
        let obsModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules", (ref) => ref.orderBy("total_access", "desc"))
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsModules);

        return forkJoin(obsArray).pipe(
            switchMap((modulesAnalytics: any[]) => {
                const ws_name = "General analytics";
                const rows = modulesAnalytics[0]
                    .filter(
                        (module) =>
                            module.type != 29 &&
                            module.type != 7 &&
                            module.type != 33 &&
                            module.type != 36 &&
                            module.type != 23
                    )
                    .map((moduleAnalytics) => {
                        return [
                            moduleAnalytics.name &&
                            moduleAnalytics.name[userLanguage]
                                ? moduleAnalytics.name[userLanguage]
                                : "",
                            moduleAnalytics.total_access
                                ? moduleAnalytics.total_access
                                : 0,
                            moduleAnalytics.usersAccessIds
                                ? moduleAnalytics.usersAccessIds.length
                                : 0,
                        ];
                    });

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([
                    this.general_analytics_cell_names,
                    ...rows,
                ]);

                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, "general-analytics.xlsx");

                return of(true);
            })
        );
    }

    /**
     * Export analytics for time tracking
     * @param eventId
     */
    exportAllTimeSessionAnalytics(eventId: string, userLanguage: string) {
        let obsArray = [];

        // Get infos
        let obsModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("timeTrackingSession", (ref) =>
                ref.orderBy("startTime", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsModules);

        let totalTimeNumber = 0;

        return forkJoin(obsArray).pipe(
            switchMap(async (timeAnalytics: any[]) => {
                const ws_name = "Time Tracking Analytics";
                const rows = timeAnalytics[0].map((moduleAnalytics) => {
                    return new Promise(async (resolve, reject) => {
                        // fetching user name & email
                        const userData = await this.getUser(
                            moduleAnalytics.userId
                        );
                        if (userData) {
                            // adding his session's time
                            totalTimeNumber +=
                                moduleAnalytics.endTime -
                                moduleAnalytics.startTime;
                            const start = new Date(
                                moduleAnalytics.startTime * 1000
                            );
                            const end = new Date(
                                moduleAnalytics.endTime * 1000
                            );

                            const empty = "";
                            const startDateString = empty.concat(
                                start.getDate() < 10
                                    ? "0" + start.getDate().toString()
                                    : start.getDate().toString(),
                                "/",
                                start.getMonth() < 10
                                    ? "0" + (start.getMonth() + 1).toString()
                                    : (start.getMonth() + 1).toString(),
                                "/",
                                start.getFullYear().toString(),
                                " - ",
                                start.getHours() < 10
                                    ? "0" + start.getHours().toString()
                                    : start.getHours().toString(),
                                ":",
                                start.getMinutes() < 10
                                    ? "0" + start.getMinutes().toString()
                                    : start.getMinutes().toString(),
                                ":",
                                start.getSeconds() < 10
                                    ? "0" + start.getSeconds().toString()
                                    : start.getSeconds().toString()
                            );

                            const endDateString = empty.concat(
                                end.getDate() < 10
                                    ? "0" + end.getDate().toString()
                                    : end.getDate().toString(),
                                "/",
                                end.getMonth() < 10
                                    ? "0" + (end.getMonth() + 1).toString()
                                    : (end.getMonth() + 1).toString(),
                                "/",
                                end.getFullYear().toString(),
                                " - ",
                                end.getHours() < 10
                                    ? "0" + end.getHours().toString()
                                    : end.getHours().toString(),
                                ":",
                                end.getMinutes() < 10
                                    ? "0" + end.getMinutes().toString()
                                    : end.getMinutes().toString(),
                                ":",
                                end.getSeconds() < 10
                                    ? "0" + end.getSeconds().toString()
                                    : end.getSeconds().toString()
                            );

                            resolve([
                                moduleAnalytics.userId,
                                userData.name,
                                userData.email,
                                startDateString,
                                endDateString,
                                moduleAnalytics.endTime -
                                    moduleAnalytics.startTime, // time elapsed
                            ]);
                        } else {
                            reject();
                            console.log("Failed to fetch user...");
                        }
                    });
                });

                const rowsValue: any = await Promise.all(rows);

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([
                    this.timeTracking_analytics_cell_names,
                    ...rowsValue,
                ]);

                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, "Time-Tracking-Analytics.xlsx");

                return of(true);
            })
        );
    }

    // storing time tracking information to the database
    storeTrakingTimeToDB(data: any) {
        const eventRef = this.afs.collection("events").doc(data.eventId);
        const timerSessionRef = eventRef.collection("timeTrackingSession");

        timerSessionRef
            .add(data)
            .then((value) => {
                return { msg: "success" };
            })
            .catch((err) => {
                return { msg: "failed" };
            });
    }

    // GetUser (with userId)
    getUser(userId: string): any {
        return new Promise((resolve, reject) => {
            this.afs
                .collection("users")
                .doc(userId)
                .get()
                .subscribe((user: any) => {
                    if (user) {
                        const userName = user.data().name;
                        const userEmail = user.data().email;
                        //console.log(userName, userEmail)
                        const data = {
                            name: userName,
                            email: userEmail,
                        };
                        resolve(data);
                    } else {
                        reject(new Error());
                    }
                });
        });
    }

    /**************************************/
    /********* Attendees analytics ********/
    /**************************************/

    /**
     * Get attendees modules for analytic
     * @param eventId
     */
    getAttendeesModulesAnalytics(eventId: string) {
        let obsArray = [];

        let obsAttendeesModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules", (ref) => ref.where("type", "==", 2))
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendeesModules);

        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendees);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateAttendeesAnalytics({
                            totalAttendeesModules: 0,
                            totalAttendees: 0,
                        })
                    );
                    return of([]);
                } else {
                    // Update total schedules modules
                    if (result[0] && result[0].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalAttendeesModulesAnalytics(
                                result[0].length
                            )
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalAttendeesModulesAnalytics(0)
                        );
                    }

                    // Update total sessions
                    if (result[1] && result[1].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalAttendeesAnalytics(result[1].length)
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalAttendeesAnalytics(0)
                        );
                    }

                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Get all attendees for selected module
     * @param eventId
     * @param moduleId
     */
    getAttendeesForModule(eventId: string, moduleId: string) {
        let obsArray = [];

        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees", (ref) =>
                ref.where("moduleId", "==", moduleId).orderBy("name", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendees);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    return of([]);
                } else {
                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Export attendees for module analytics
     * @param eventId
     * @param moduleId
     * @param userLanguage
     */
    exportAttendeesForModuleAnalytics(
        eventId: string,
        moduleId: string,
        userLanguage: string
    ) {
        return this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees", (ref) =>
                ref.where("moduleId", "==", moduleId).orderBy("name", "desc")
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((users) => {
                    let obsUsersArray = [];
                    let chunkedUsers = _.chunk(users, 50);

                    chunkedUsers.forEach((chunk) => {
                        obsUsersArray.push(
                            this.https.post(
                                environment.platform.apiBaseUrl +
                                    "/analyticsExportUsersAnalytics",
                                {
                                    eventId: eventId,
                                    users: chunk,
                                }
                            )
                        );
                    });

                    return forkJoin(obsUsersArray).pipe(
                        switchMap((usersAnalytics: any) => {
                            const ws_name = "Attendees analytics";
                            const rows = usersAnalytics
                                .flat()
                                .sort((a, b) =>
                                    a.email > b.email
                                        ? 1
                                        : a.email < b.email
                                        ? -1
                                        : 0
                                )
                                .map((userAnalytics) => [
                                    userAnalytics.identifier
                                        ? userAnalytics.identifier
                                        : "",
                                    userAnalytics.name
                                        ? userAnalytics.name
                                        : "",
                                    userAnalytics.email
                                        ? userAnalytics.email
                                        : "",
                                    userAnalytics.title &&
                                    userAnalytics.title[userLanguage]
                                        ? userAnalytics.title[userLanguage]
                                        : "",
                                    userAnalytics.company
                                        ? userAnalytics.company
                                        : "",
                                    !userAnalytics.firstAccess ? "Yes" : "No",
                                    userAnalytics.total_access
                                        ? userAnalytics.total_access
                                        : 0,
                                    userAnalytics.totalTimeOn
                                        ? userAnalytics.totalTimeOn
                                        : 0,
                                ]);

                            const wb = XLSX.utils.book_new();
                            const ws = XLSX.utils.aoa_to_sheet([
                                this.attendees_analytics_cell_names,
                                ...rows,
                            ]);

                            XLSX.utils.book_append_sheet(wb, ws, ws_name);

                            // Tracks sheet
                            const ws_tracks_name = "Details";
                            const rows_tracks = [];

                            usersAnalytics.flat().forEach((user) => {
                                if (
                                    user.trackings &&
                                    user.trackings.length > 0
                                ) {
                                    user.trackings.forEach((track) => {
                                        if (track.type == 12) {
                                            rows_tracks.push([
                                                track.userId
                                                    ? track.userId
                                                    : "None",
                                                track.userIdentifier
                                                    ? track.userIdentifier
                                                    : "None",
                                                track.userName
                                                    ? track.userName
                                                    : "None",
                                                track.userEmail
                                                    ? track.userEmail
                                                    : "None",
                                                user && user.title[userLanguage]
                                                    ? user.title[userLanguage]
                                                    : "None",
                                                user && user.company
                                                    ? user.company
                                                    : "None",
                                                track.idOfTrack
                                                    ? track.idOfTrack
                                                    : "None",
                                                track.nameOfTrack &&
                                                track.nameOfTrack
                                                    ? track.nameOfTrack
                                                    : "None",
                                                track.trackAt
                                                    ? moment(
                                                          track.trackAt
                                                      ).format(
                                                          "DD/MM/YYYY - HH:mm:ss"
                                                      )
                                                    : "None",
                                            ]);
                                        }
                                    });
                                }
                            });

                            const ws_tracks = XLSX.utils.aoa_to_sheet([
                                this.attendees_tracks_analytics_cell_names,
                                ...rows_tracks,
                            ]);

                            XLSX.utils.book_append_sheet(
                                wb,
                                ws_tracks,
                                ws_tracks_name
                            );

                            XLSX.writeFile(wb, "attendees-analytics.xlsx");

                            return of(true);
                        })
                    );
                })
            );
    }

    /**************************************/
    /********** Chats analytics ***********/
    /**************************************/

    getChatsAnalytics(eventId: string) {
        let obsArray = [];

        // Get chats
        let obsChats = this.afs
            .collection("events")
            .doc(eventId)
            .collection("chats")
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((chats: any[]) => {
                    // Get total messages for each chats
                    let obsMessagesOnChats = [];

                    chats.forEach((chat) => {
                        obsMessagesOnChats.push(
                            this.afs
                                .collection("events")
                                .doc(eventId)
                                .collection("chats")
                                .doc(chat.uid)
                                .collection("messages")
                                .get()
                                .pipe(map((docs) => docs.size))
                        );
                    });

                    return forkJoin(obsMessagesOnChats).pipe(
                        switchMap((results: any[]) => {
                            let groupsMessages = [];
                            let chatsMessages = [];

                            let groupsUsers = [];
                            let chatsUsers = [];

                            chats.forEach((chat, index) => {
                                if (!chat.group) {
                                    let chatMembers = Object.keys(chat.members);

                                    chatMembers.forEach((chatMember) => {
                                        if (!chatsUsers.includes(chatMember)) {
                                            chatsUsers.push(chatMember);
                                        }
                                    });
                                    chatsMessages.push(results[index]);
                                } else {
                                    let groupMembers = Object.keys(
                                        chat.members
                                    );
                                    groupMembers.forEach((groupMember) => {
                                        if (
                                            !groupsUsers.includes(groupMember)
                                        ) {
                                            groupsUsers.push(groupMember);
                                        }
                                    });
                                    groupsMessages.push(results[index]);
                                }
                            });

                            return of([
                                chats.filter((chat) => !chat.group),
                                chatsUsers.length,
                                chatsMessages.reduce((a, b) => a + b, 0),
                                chats.filter((chat) => chat.group),
                                groupsUsers.length,
                                groupsMessages.reduce((a, b) => a + b, 0),
                            ]);
                        })
                    );
                })
            );
        obsArray.push(obsChats);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateChatsAnalytics({
                            totalChats: 0,
                            totalChatsUsers: 0,
                            totalMessagesOnChats: 0,
                            totalDiscussionGroups: 0,
                            totalDiscussionGroupsUsers: 0,
                            totalMessagesOnDiscussionGroups: 0,
                        })
                    );
                    return of(false);
                }

                // Update total chats
                if (result[0] && result[0][0] && result[0][0].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalChats(result[0][0].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalChats(0));
                }

                // Update total chats users
                if (result[0] && result[0][1]) {
                    this.store.dispatch(
                        new UpdateTotalChatsUsers(result[0][1])
                    );
                } else {
                    this.store.dispatch(new UpdateTotalChatsUsers(0));
                }

                // Update total messages on chats
                if (result[0] && result[0][2]) {
                    this.store.dispatch(
                        new UpdateTotalMessagesChats(result[0][2])
                    );
                } else {
                    this.store.dispatch(new UpdateTotalMessagesChats(0));
                }

                // Update total discussion groups
                if (result[0] && result[0][3] && result[0][3].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalDiscussionGroups(result[0][3].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalDiscussionGroups(0));
                }

                // Update total discussion groups users
                if (result[0] && result[0][4]) {
                    this.store.dispatch(
                        new UpdateTotalDiscussionGroupsUsers(result[0][4])
                    );
                } else {
                    this.store.dispatch(
                        new UpdateTotalDiscussionGroupsUsers(0)
                    );
                }

                // Update total messages on discussion groups
                if (result[0] && result[0][5]) {
                    this.store.dispatch(
                        new UpdateTotalMessagesDiscussionGroups(result[0][5])
                    );
                } else {
                    this.store.dispatch(
                        new UpdateTotalMessagesDiscussionGroups(0)
                    );
                }

                return of(true);
            })
        );
    }

    /**
     * Export analytics for chats
     * @param eventId
     */
    exportChatsAnalytics(eventId: string, userLanguage: string) {
        let obsArray = [];

        // Get chats
        let obsChats = this.afs
            .collection("events")
            .doc(eventId)
            .collection("chats")
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((chats: any[]) => {
                    let obsChatsArray = [];
                    let chunkedChats = _.chunk(chats, 300);

                    chunkedChats.forEach((chunk) => {
                        obsChatsArray.push(
                            this.https.post(
                                environment.platform.apiBaseUrl +
                                    "/analyticsExportChatsAnalytics",
                                {
                                    eventId: eventId,
                                    chats: chunk,
                                }
                            )
                        );
                    });

                    return forkJoin(obsChatsArray).pipe(
                        switchMap((chatsAnalytics: any) => {
                            return of(
                                chatsAnalytics.flat().map((chat) => {
                                    return {
                                        id: chat.uid ? chat.uid : "",
                                        type: !chat.group ? "Chat" : "Group",
                                        numberOfMembers: chat.numberOfMembers
                                            ? chat.numberOfMembers
                                            : 0,
                                        numberOfMessages: chat.numberOfMessages
                                            ? chat.numberOfMessages
                                            : 0,
                                        identifier1:
                                            chat.membersData &&
                                            chat.membersData[0] &&
                                            chat.membersData[0].identifier
                                                ? chat.membersData[0].identifier
                                                : "",
                                        name1:
                                            chat.membersData &&
                                            chat.membersData[0] &&
                                            chat.membersData[0].name
                                                ? chat.membersData[0].name
                                                : "",
                                        email1:
                                            chat.membersData &&
                                            chat.membersData[0] &&
                                            chat.membersData[0].email
                                                ? chat.membersData[0].email
                                                : "",
                                        poste1:
                                            chat.membersData &&
                                            chat.membersData[0] &&
                                            chat.membersData[0].poste &&
                                            chat.membersData[0].poste[
                                                userLanguage
                                            ]
                                                ? chat.membersData[0].poste[
                                                      userLanguage
                                                  ]
                                                : "",
                                        company1:
                                            chat.membersData &&
                                            chat.membersData[0] &&
                                            chat.membersData[0].company
                                                ? chat.membersData[0].company
                                                : "",
                                        identifier2:
                                            chat.membersData &&
                                            chat.membersData[1] &&
                                            chat.membersData[1].identifier
                                                ? chat.membersData[1].identifier
                                                : "",
                                        name2:
                                            chat.membersData &&
                                            chat.membersData[1] &&
                                            chat.membersData[1].name
                                                ? chat.membersData[1].name
                                                : "",
                                        email2:
                                            chat.membersData &&
                                            chat.membersData[1] &&
                                            chat.membersData[1].email
                                                ? chat.membersData[1].email
                                                : "",
                                        poste2:
                                            chat.membersData &&
                                            chat.membersData[1] &&
                                            chat.membersData[1].poste &&
                                            chat.membersData[1].poste[
                                                userLanguage
                                            ]
                                                ? chat.membersData[1].poste[
                                                      userLanguage
                                                  ]
                                                : "",
                                        company2:
                                            chat.membersData &&
                                            chat.membersData[1] &&
                                            chat.membersData[1].company
                                                ? chat.membersData[1].company
                                                : "",
                                    };
                                })
                            );
                        })
                    );
                })
            );
        obsArray.push(obsChats);

        return forkJoin(obsArray).pipe(
            switchMap((chatsAnalytics: any[]) => {
                const ws_name = "Chats analytics";
                const rows = chatsAnalytics[0]
                    .sort((a, b) =>
                        a.type > b.type ? 1 : a.type < b.type ? -1 : 0
                    )
                    .map((chatAnalytics) => {
                        return [
                            chatAnalytics.id,
                            chatAnalytics.type,
                            chatAnalytics.numberOfMembers,
                            chatAnalytics.numberOfMessages,
                            chatAnalytics.identifier1,
                            chatAnalytics.name1,
                            chatAnalytics.email1,
                            chatAnalytics.poste1,
                            chatAnalytics.company1,
                            chatAnalytics.identifier2,
                            chatAnalytics.name2,
                            chatAnalytics.email2,
                            chatAnalytics.poste2,
                            chatAnalytics.company2,
                        ];
                    });

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([
                    this.chats_analytics_cell_names,
                    ...rows,
                ]);

                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, "chats-analytics.xlsx");

                return of(true);
            })
        );
    }

    /**************************************/
    /********** Feeds analytics ***********/
    /**************************************/

    /**
     * Getting feeds anlytics
     * @param eventId
     */
    getFeedsAnalytics(eventId: string) {
        let obsArray = [];

        let obsFeedsPosts = this.afs
            .collection("events")
            .doc(eventId)
            .collection("feed-posts")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsFeedsPosts);

        let obsBestLikedFeeds = this.afs
            .collection("events")
            .doc(eventId)
            .collection("feed-posts", (ref) =>
                ref.orderBy("totalLikes", "desc").limit(5)
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((posts: Post[]) => {
                    let modulesIds = [];
                    posts.forEach((post) => {
                        if (!modulesIds.includes(post.moduleId)) {
                            modulesIds.push(post.moduleId);
                        }
                    });

                    let obsFeedsModules = [];
                    modulesIds.forEach((id) => {
                        obsFeedsModules.push(
                            this.afs
                                .collection("modules")
                                .doc(id)
                                .get()
                                .pipe(map((doc) => doc.data()))
                        );
                    });

                    return forkJoin(obsFeedsModules).pipe(
                        switchMap((result: any[]) => {
                            result.forEach((module) => {
                                posts.forEach((post) => {
                                    if (post.moduleId == module.uid) {
                                        post.moduleName = module.name;
                                    }
                                });
                            });
                            return of(posts);
                        })
                    );
                })
            );
        obsArray.push(obsBestLikedFeeds);

        let obsBestCommentedFeeds = this.afs
            .collection("events")
            .doc(eventId)
            .collection("feed-posts", (ref) =>
                ref.orderBy("totalComments", "desc").limit(5)
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((posts: Post[]) => {
                    let modulesIds = [];
                    posts.forEach((post) => {
                        if (!modulesIds.includes(post.moduleId)) {
                            modulesIds.push(post.moduleId);
                        }
                    });

                    let obsFeedsModules = [];
                    modulesIds.forEach((id) => {
                        obsFeedsModules.push(
                            this.afs
                                .collection("modules")
                                .doc(id)
                                .get()
                                .pipe(map((doc) => doc.data()))
                        );
                    });

                    return forkJoin(obsFeedsModules).pipe(
                        switchMap((result: any[]) => {
                            result.forEach((module) => {
                                posts.forEach((post) => {
                                    if (post.moduleId == module.uid) {
                                        post.moduleName = module.name;
                                    }
                                });
                            });
                            return of(posts);
                        })
                    );
                })
            );
        obsArray.push(obsBestCommentedFeeds);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateFeedsAnalytics({
                            totalFeedsPosts: 0,
                            totalFeedsLikes: 0,
                            totalFeedsComments: 0,
                            bestLikedFeeds: [],
                            bestCommentedFeeds: [],
                        })
                    );
                    return of(false);
                }

                // Update total feeds posts
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalFeedsPosts(result[0].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalFeedsPosts(0));
                }

                // Update total feeds likes
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalFeedsLikes(
                            result[0]
                                .map((post) => post.totalLikes)
                                .reduce((a, b) => a + b, 0)
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalFeedsLikes(0));
                }

                // Update total feeds comments
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalFeedsComments(
                            result[0]
                                .map((post) => post.totalComments)
                                .reduce((a, b) => a + b, 0)
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalFeedsComments(0));
                }

                // Update best liked feeds
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(new UpdateBestLikedFeeds(result[1]));
                } else {
                    this.store.dispatch(new UpdateBestLikedFeeds([]));
                }

                // Update best commented feeds
                if (result[2] && result[2].length > 0) {
                    this.store.dispatch(
                        new UpdateBestCommentedFeeds(result[2])
                    );
                } else {
                    this.store.dispatch(new UpdateBestCommentedFeeds([]));
                }

                return of(true);
            })
        );
    }

    /**
     * Export analytics for feeds
     * @param eventId
     */
    exportFeedsAnalytics(eventId: string, userLanguage: string) {
        let obsArray = [];

        // Get feeds
        let obsFeeds = this.afs
            .collection("events")
            .doc(eventId)
            .collection("feed-posts")
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((posts: Post[]) => {
                    let modulesIds = [];
                    posts.forEach((post) => {
                        if (!modulesIds.includes(post.moduleId)) {
                            modulesIds.push(post.moduleId);
                        }
                    });

                    let obsFeedsModules = [];
                    modulesIds.forEach((id) => {
                        obsFeedsModules.push(
                            this.afs
                                .collection("modules")
                                .doc(id)
                                .get()
                                .pipe(map((doc) => doc.data()))
                        );
                    });

                    return forkJoin(obsFeedsModules).pipe(
                        switchMap((result: any[]) => {
                            result.forEach((module) => {
                                posts.forEach((post) => {
                                    if (post.moduleId == module.uid) {
                                        post.moduleName =
                                            module.name[userLanguage];
                                    }
                                });
                            });
                            return of(posts);
                        })
                    );
                })
            );
        obsArray.push(obsFeeds);

        return forkJoin(obsArray).pipe(
            switchMap((feedsAnalytics: any[]) => {
                console.log("Feed analytics: ", feedsAnalytics);
                const ws_name = "Feeds analytics";
                const rows = feedsAnalytics[0]
                    .sort((a, b) =>
                        a.moduleName[userLanguage] > b.moduleName[userLanguage]
                            ? 1
                            : a.moduleName[userLanguage] <
                              b.moduleName[userLanguage]
                            ? -1
                            : 0
                    )
                    .map((feedAnalytic) => {
                        return [
                            feedAnalytic.uid,
                            feedAnalytic.moduleName,
                            feedAnalytic.creator && feedAnalytic.creator.name
                                ? feedAnalytic.creator.name
                                : "",
                            feedAnalytic.description,
                            feedAnalytic.img && feedAnalytic.img.url
                                ? feedAnalytic.img.url
                                : "",
                            moment
                                .unix(feedAnalytic.date)
                                .format("DD/MM/YYYY - HH:mm"),
                            feedAnalytic.totalLikes
                                ? feedAnalytic.totalLikes
                                : 0,
                            feedAnalytic.totalComments
                                ? feedAnalytic.totalComments
                                : 0,
                        ];
                    });

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([
                    this.feeds_analytics_cell_names,
                    ...rows,
                ]);

                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, "feeds-analytics.xlsx");

                return of(true);
            })
        );
    }

    /**************************************/
    /******** Documents analytics *********/
    /**************************************/

    /**
     * Get documents analytics
     * @param eventId
     */
    getDocumentsAnalytics(eventId: string) {
        let obsArray = [];

        let obsBestDocuments = this.afs
            .collection("events")
            .doc(eventId)
            .collection("documents", (ref) =>
                ref.orderBy("total_access", "desc").limit(5)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsBestDocuments);

        let obsTotalDocs = this.afs
            .collection("events")
            .doc(eventId)
            .collection("documents")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsTotalDocs);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateDocumentsAnalytics({
                            bestDocuments: [],
                            totalDocuments: 0,
                        })
                    );
                    return of(false);
                }

                // Update best documents
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(new UpdateBestDocuments(result[0]));
                } else {
                    this.store.dispatch(new UpdateBestDocuments([]));
                }

                // Update total documents
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalDocuments(result[1].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalDocuments(0));
                }

                return of(true);
            })
        );
    }

    /**
     * Export all documents analytics
     * @param eventId
     */
    exportDocumentsAnalytics(eventId: string, userLanguage: string) {
        // Type open document 72

        let obsArray = [];

        let obsDocumentsAccess = this.afs
            .collection("events")
            .doc(eventId)
            .collection("documents", (ref) =>
                ref.orderBy("total_access", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsDocumentsAccess);

        let obsTrackingOpenDoc = this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("tracking-road-user", (ref) =>
                ref.where("type", "==", 72)
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((trackings) => {
                    let idsUsers = [];
                    trackings.forEach((tracking) => {
                        if (!idsUsers.includes(tracking.userId)) {
                            idsUsers.push(tracking.userId);
                        }
                    });

                    let obsUserArray = [];
                    idsUsers.forEach((idUser) => {
                        obsUserArray.push(
                            this.afs
                                .collection("events")
                                .doc(eventId)
                                .collection("attendees")
                                .doc(idUser)
                                .get()
                                .pipe(map((doc) => doc.data()))
                        );
                    });

                    return forkJoin(obsUserArray).pipe(
                        switchMap((users: any[]) => {
                            return of(
                                trackings.map((tracking) => {
                                    let userFind = users
                                        .filter((user) => user)
                                        .find(
                                            (user) =>
                                                user &&
                                                user.uid == tracking.userId &&
                                                user.type == 5
                                        );

                                    if (userFind) {
                                        tracking.userData = {
                                            id: userFind.uid
                                                ? userFind.uid
                                                : "",
                                            name: userFind.name
                                                ? userFind.name
                                                : "",
                                            identifier: userFind.identifier
                                                ? userFind.identifier
                                                : "",
                                            email: userFind.email
                                                ? userFind.email
                                                : "",
                                            poste:
                                                userFind.title &&
                                                userFind.title[userLanguage]
                                                    ? userFind.title[
                                                          userLanguage
                                                      ]
                                                    : "",
                                            company: userFind.company
                                                ? userFind.company
                                                : "",
                                        };
                                    } else {
                                        tracking.userData = null;
                                    }

                                    return tracking;
                                })
                            );
                        })
                    );
                })
            );
        obsArray.push(obsTrackingOpenDoc);

        return forkJoin(obsArray).pipe(
            switchMap((documentsAnalytics: any[]) => {
                const wb = XLSX.utils.book_new();

                const wsDocumentsAccessName = "Documents analytics";
                let documentsAccess = documentsAnalytics[0];
                const rowsAccess = documentsAccess.map((documentAccess) => [
                    documentAccess.uid,
                    documentAccess.name[userLanguage],
                    documentAccess.url,
                    documentAccess.total_access,
                ]);

                const wsDocumentsAccess = XLSX.utils.aoa_to_sheet([
                    this.documents_analytics_cell_names,
                    ...rowsAccess,
                ]);
                XLSX.utils.book_append_sheet(
                    wb,
                    wsDocumentsAccess,
                    wsDocumentsAccessName
                );

                const wsDocumentsTrackingOpenDocName = "Documents open";
                let documentsTracking = documentsAnalytics[1];
                const rowsTracking = documentsTracking
                    .filter((documentAccess) => {
                        return documentsAnalytics[0].find(
                            (document) =>
                                document.uid == documentAccess.idOfTrack
                        )
                            ? true
                            : false;
                    })
                    .sort((a, b) =>
                        a.nameOfTrack[userLanguage] >
                        b.nameOfTrack[userLanguage]
                            ? 1
                            : a.nameOfTrack[userLanguage] <
                              b.nameOfTrack[userLanguage]
                            ? -1
                            : 0
                    )
                    .map((documentAccess) => {
                        let documentFind = documentsAnalytics[0].find(
                            (document) =>
                                document.uid == documentAccess.idOfTrack
                        );
                        return [
                            documentFind.uid,
                            documentFind.name[userLanguage],
                            documentFind.url,
                            documentAccess.userData
                                ? documentAccess.userData.uid
                                : "",
                            documentAccess.userData
                                ? documentAccess.userData.identifier
                                : "",
                            documentAccess.userData
                                ? documentAccess.userData.email
                                : "",
                            documentAccess.userData
                                ? documentAccess.userData.name
                                : "",
                            documentAccess.userData
                                ? documentAccess.userData.poste
                                : "",
                            documentAccess.userData
                                ? documentAccess.userData.company
                                : "",
                            moment(documentAccess.trackAt).format(
                                "DD/MM/YYYY - HH:mm:ss"
                            ),
                        ];
                    });

                const wsDocumentsTracking = XLSX.utils.aoa_to_sheet([
                    this.documents_open_analytics_cell_names,
                    ...rowsTracking,
                ]);
                XLSX.utils.book_append_sheet(
                    wb,
                    wsDocumentsTracking,
                    wsDocumentsTrackingOpenDocName
                );

                XLSX.writeFile(wb, "documents-analytics.xlsx");

                return of(true);
            })
        );
    }

    /**************************************/
    /******** Galleries analytics *********/
    /**************************************/

    /**
     * Get galleries analytics
     * @param eventId
     */
    getGalleriesAnalytics(eventId: string) {
        let obsArray = [];

        let obsBestImages = this.afs
            .collection("events")
            .doc(eventId)
            .collection("gallery-images", (ref) =>
                ref.orderBy("total_access", "desc").limit(5)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsBestImages);

        let obsTotalImages = this.afs
            .collection("events")
            .doc(eventId)
            .collection("gallery-images")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsTotalImages);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateGalleriesAnalytics({
                            bestImages: [],
                            totalImages: 0,
                        })
                    );
                    return of(false);
                }

                // Update best images
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(new UpdateBestImages(result[0]));
                } else {
                    this.store.dispatch(new UpdateBestImages([]));
                }

                // Update total images
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalImages(result[1].length)
                    );
                } else {
                    this.store.dispatch(new UpdateTotalImages(0));
                }

                return of(true);
            })
        );
    }

    /**
     * Export all galleries analytics
     * @param eventId
     */
    exportGalleriesAnalytics(eventId: string) {
        return this.afs
            .collection("events")
            .doc(eventId)
            .collection("gallery-images", (ref) =>
                ref.orderBy("total_access", "desc")
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((galleriesAccesss) => {
                    const ws_name = "Galleries analytics";
                    const rows = galleriesAccesss.map((galleryAccess) => [
                        galleryAccess.name,
                        galleryAccess.url,
                        galleryAccess.total_access,
                    ]);

                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet([
                        this.galleries_analytics_cell_names,
                        ...rows,
                    ]);

                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, "galleries-analytics.xlsx");

                    return of(true);
                })
            );
    }

    /**************************************/
    /********* Visios analytics ***********/
    /**************************************/

    /**
     * Get visios analytics
     * @param eventId
     */
    getVisiosAnalytics(eventId: string) {
        let obsArray = [];

        let obsTotalRoomsForEvent = this.afs
            .collection("events")
            .doc(eventId)
            .collection("sessions", (ref) =>
                ref.where("visio.meetingId", ">", "")
            )
            .get()
            .pipe(map((docs) => docs.size));
        obsArray.push(obsTotalRoomsForEvent);

        let obsTotalVisioAccess = this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("total-visio-access")
            .get()
            .pipe(map((docs) => docs.size));
        obsArray.push(obsTotalVisioAccess);

        let obsTotalRoomsFor2 = this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("total-visio-for-2")
            .get()
            .pipe(map((docs) => docs.size));
        obsArray.push(obsTotalRoomsFor2);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateVisiosAnalytics({
                            totalPIM: 0,
                            totalRoomsForEvent: 0,
                            totalRoomsBetween2: 0,
                        })
                    );
                    return of(false);
                }

                // Update total rooms for event
                if (result[0]) {
                    this.store.dispatch(
                        new UpdateTotalRoomsForEvent(result[0])
                    );
                } else {
                    this.store.dispatch(new UpdateTotalRoomsForEvent(0));
                }

                // Update total PIM
                if (result[1]) {
                    this.store.dispatch(new UpdateTotalPIM(result[1]));
                } else {
                    this.store.dispatch(new UpdateTotalPIM(0));
                }

                // Update total rooms for 2
                if (result[2]) {
                    this.store.dispatch(
                        new UpdateTotalRoomsBetween2(result[2])
                    );
                } else {
                    this.store.dispatch(new UpdateTotalRoomsBetween2(0));
                }

                return of(true);
            })
        );
    }

    /**
     * Export analytics for visios
     * @param eventId
     */
    exportVisioAnalytics(eventId: string) {
        return this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("total-visio-access")
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((visioAccesss) => {
                    const ws_name = "Visio analytics";
                    const rows = visioAccesss
                        .sort((a, b) =>
                            a.userEmail > b.userEmail
                                ? 1
                                : a.userEmail < b.userEmail
                                ? -1
                                : 0
                        )
                        .map((visioAccess) => [
                            visioAccess.userId,
                            visioAccess.userIdentifier,
                            visioAccess.userEmail,
                            visioAccess.sessionId,
                            visioAccess.sessionName,
                            moment(visioAccess.date).format(
                                "DD/MM/YYYY - HH:mm:ss"
                            ),
                        ]);

                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet([
                        this.visio_analytics_cell_names,
                        ...rows,
                    ]);

                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, "visio-analytics.xlsx");

                    return of(true);
                })
            );
    }

    /**************************************/
    /********* Schedules analytics *********/
    /**************************************/

    /**
     * Get schedules modules for analytic
     * @param eventId
     */
    getSchedulesModulesAnalytics(eventId: string) {
        let obsArray = [];

        let obsSchedulesModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules", (ref) => ref.where("type", "==", 0))
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSchedulesModules);

        let obsSessions = this.afs
            .collection("events")
            .doc(eventId)
            .collection("sessions")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSessions);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateSchedulesAnalytics({
                            totalSchedulesModules: 0,
                            totalSessionsSchedules: 0,
                        })
                    );
                    return of([]);
                } else {
                    // Update total schedules modules
                    if (result[0] && result[0].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalSchedulesModulesAnalytics(
                                result[0].length
                            )
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalSchedulesModulesAnalytics(0)
                        );
                    }

                    // Update total sessions
                    if (result[1] && result[1].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalSchedulesSessionsAnalytics(
                                result[1].length
                            )
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalSchedulesSessionsAnalytics(0)
                        );
                    }

                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Get all sessions for selected schedule
     * @param eventId
     * @param scheduleId
     */
    getSessionsForSelectedSchedule(eventId: string, scheduleId: string) {
        let obsArray = [];

        let obsSessions = this.afs
            .collection("events")
            .doc(eventId)
            .collection("sessions", (ref) =>
                ref
                    .where("moduleId", "==", scheduleId)
                    .orderBy("startTime", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSessions);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    return of([]);
                } else {
                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Export schedule analytics
     * @param eventId
     * @param scheduleId
     * @param userLanguage
     */
    exportScheduleAnalytics(
        eventId: string,
        scheduleId: string,
        userLanguage: string
    ) {
        let obsArray = [];

        let obsScheduleModule = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(scheduleId)
            .get()
            .pipe(map((doc) => doc.data()));
        obsArray.push(obsScheduleModule);

        let obsSessionsForSchedule = this.afs
            .collection("events")
            .doc(eventId)
            .collection("sessions", (ref) =>
                ref.where("moduleId", "==", scheduleId)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSessionsForSchedule);

        let obsUsers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees", (ref) =>
                ref.where("firstAccess", "==", false)
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((attendees) => {
                    let obsAttendeesArray = [];
                    let chunkedAttendees = _.chunk(attendees, 50);

                    chunkedAttendees.forEach((chunk) => {
                        obsAttendeesArray.push(
                            this.https.post(
                                environment.platform.apiBaseUrl +
                                    "/analyticsExportSchedulesForAttendees",
                                {
                                    eventId: eventId,
                                    attendees: chunk,
                                }
                            )
                        );
                    });

                    return forkJoin(obsAttendeesArray).pipe(
                        switchMap((attendeesTracked: any) => {
                            return of(attendeesTracked.flat());
                        })
                    );
                })
            );
        obsArray.push(obsUsers);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // Schedule sheet

                let sessions = result[1].map((session) => {
                    session.totalAccess = 0;
                    session.usersAccessIds = [];
                    return session;
                });
                result[2].forEach((attendee) => {
                    attendee.trackings.forEach((track) => {
                        let session =
                            sessions[
                                sessions.indexOf(
                                    sessions.find(
                                        (session) =>
                                            session.uid == track.idOfTrack
                                    )
                                )
                            ];

                        if (session) {
                            // Update total access
                            session.totalAccess = session.totalAccess + 1;

                            // Update access ids for unique acces
                            let usersAccessIds = session.usersAccessIds;
                            if (!usersAccessIds.includes(attendee.uid)) {
                                usersAccessIds.push(attendee.uid);
                            }
                        }

                        if (track.totalTimeOn && session) {
                            // Update total session time on
                            let totalSessionTimeOn = session.totalTimeOn
                                ? session.totalTimeOn
                                : 0;
                            if (!totalSessionTimeOn) {
                                session.totalTimeOn = track.totalTimeOn;
                            } else {
                                session.totalTimeOn += track.totalTimeOn;
                            }
                        }
                    });
                });

                const wb = XLSX.utils.book_new();

                const ws_schedule_name = "Schedule";
                const rows_schedule = sessions.map((sessionAnalytics) => {
                    return [
                        sessionAnalytics.uid ? sessionAnalytics.uid : "None",
                        sessionAnalytics.name[userLanguage]
                            ? sessionAnalytics.name[userLanguage]
                            : "None",
                        sessionAnalytics.date
                            ? moment
                                  .unix(sessionAnalytics.date)
                                  .format("DD/MM/YYYY")
                            : "None",
                        sessionAnalytics.startTime
                            ? moment
                                  .unix(sessionAnalytics.startTime)
                                  .format("HH:mm")
                            : "None",
                        sessionAnalytics.endTime
                            ? moment
                                  .unix(sessionAnalytics.endTime)
                                  .format("HH:mm")
                            : "None",
                        sessionAnalytics.totalAccess
                            ? sessionAnalytics.totalAccess
                            : 0,
                        sessionAnalytics.usersAccessIds
                            ? sessionAnalytics.usersAccessIds.length
                            : 0,
                        sessionAnalytics.totalTimeOn
                            ? sessionAnalytics.totalTimeOn
                            : 0,
                    ];
                });

                const ws_schedule = XLSX.utils.aoa_to_sheet([
                    this.schedule_analytics_cell_names,
                    ...rows_schedule,
                ]);

                XLSX.utils.book_append_sheet(wb, ws_schedule, ws_schedule_name);

                // Sessions sheet
                const ws_sessions_name = "Sessions";
                const rows_sessions = result[2]
                    .map((attendee) => attendee.trackings)
                    .flat()
                    .filter((track) =>
                        sessions.find(
                            (session) => session.uid == track.idOfTrack
                        )
                    )
                    .map((trackAnalytics) => {
                        let sessionConcerned = sessions.find(
                            (session) => session.uid == trackAnalytics.idOfTrack
                        );
                        let attendee = result[2].find(
                            (attendee) =>
                                attendee.email == trackAnalytics.userEmail
                        );
                        return [
                            trackAnalytics.userId
                                ? trackAnalytics.userId
                                : "None",
                            trackAnalytics.userIdentifier
                                ? trackAnalytics.userIdentifier
                                : "None",
                            trackAnalytics.userName
                                ? trackAnalytics.userName
                                : "None",
                            trackAnalytics.userEmail
                                ? trackAnalytics.userEmail
                                : "None",
                            attendee && attendee.title[userLanguage]
                                ? attendee.title[userLanguage]
                                : "None",
                            attendee && attendee.company
                                ? attendee.company
                                : "None",
                            trackAnalytics.idOfTrack
                                ? trackAnalytics.idOfTrack
                                : "None",
                            trackAnalytics.nameOfTrack &&
                            trackAnalytics.nameOfTrack[userLanguage]
                                ? trackAnalytics.nameOfTrack[userLanguage]
                                : "None",
                            sessionConcerned && sessionConcerned.date
                                ? moment
                                      .unix(sessionConcerned.date)
                                      .format("DD/MM/YYYY")
                                : "None",
                            sessionConcerned && sessionConcerned.startTime
                                ? moment
                                      .unix(sessionConcerned.startTime)
                                      .format("HH:mm:ss")
                                : "None",
                            sessionConcerned && sessionConcerned.endTime
                                ? moment
                                      .unix(sessionConcerned.endTime)
                                      .format("HH:mm:ss")
                                : "None",
                            trackAnalytics.trackAt
                                ? moment(trackAnalytics.trackAt).format(
                                      "DD/MM/YYYY - HH:mm:ss"
                                  )
                                : "None",
                            trackAnalytics.trackAt
                                ? moment(trackAnalytics.trackAt)
                                      .add(
                                          trackAnalytics.totalTimeOn,
                                          "seconds"
                                      )
                                      .format("DD/MM/YYYY - HH:mm:ss")
                                : "None",
                            trackAnalytics.totalTimeOn
                                ? trackAnalytics.totalTimeOn
                                : 0,
                        ];
                    });

                const ws_sessions = XLSX.utils.aoa_to_sheet([
                    this.schedule_session_analytics_cell_names,
                    ...rows_sessions,
                ]);

                XLSX.utils.book_append_sheet(wb, ws_sessions, ws_sessions_name);

                XLSX.writeFile(wb, "schedule-analytics.xlsx");

                return of(true);
            })
        );
    }

    /**************************************/
    /********* Speakers analytics ********/
    /**************************************/

    /**
     * Get speakers modules for analytic
     * @param eventId
     */
    getSpeakersModulesAnalytics(eventId: string) {
        let obsArray = [];

        let obsSpeakersModules = this.afs
            .collection("events")
            .doc(eventId)
            .collection("modules", (ref) => ref.where("type", "==", 1))
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSpeakersModules);

        let obsSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSpeakers);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateSpeakersAnalytics({
                            totalSpeakersModules: 0,
                            totalSpeakers: 0,
                        })
                    );
                    return of([]);
                } else {
                    // Update total schedules modules
                    if (result[0] && result[0].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalSpeakersModulesAnalytics(
                                result[0].length
                            )
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalSpeakersModulesAnalytics(0)
                        );
                    }

                    // Update total sessions
                    if (result[1] && result[1].length > 0) {
                        this.store.dispatch(
                            new UpdateTotalSpeakersAnalytics(result[1].length)
                        );
                    } else {
                        this.store.dispatch(
                            new UpdateTotalSpeakersAnalytics(0)
                        );
                    }

                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Get all speakers for selected module
     * @param eventId
     * @param moduleId
     */
    getSpeakersForModule(eventId: string, moduleId: string) {
        let obsArray = [];

        let obsSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers", (ref) =>
                ref.where("moduleId", "==", moduleId).orderBy("name", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSpeakers);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    return of([]);
                } else {
                    return of(result[0]);
                }
            })
        );
    }

    /**
     * Export speakers for module analytics
     * @param eventId
     * @param moduleId
     * @param userLanguage
     */
    exportSpeakersForModuleAnalytics(
        eventId: string,
        moduleId: string,
        userLanguage: string
    ) {
        return this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers", (ref) =>
                ref.where("moduleId", "==", moduleId).orderBy("name", "desc")
            )
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((users) => {
                    let obsUsersArray = [];
                    let chunkedUsers = _.chunk(users, 50);

                    chunkedUsers.forEach((chunk) => {
                        obsUsersArray.push(
                            this.https.post(
                                environment.platform.apiBaseUrl +
                                    "/analyticsExportUsersAnalytics",
                                {
                                    eventId: eventId,
                                    users: chunk,
                                }
                            )
                        );
                    });

                    return forkJoin(obsUsersArray).pipe(
                        switchMap((usersAnalytics: any) => {
                            const ws_name = "Speakers analytics";
                            const rows = usersAnalytics
                                .flat()
                                .sort((a, b) =>
                                    a.email > b.email
                                        ? 1
                                        : a.email < b.email
                                        ? -1
                                        : 0
                                )
                                .map((userAnalytics) => [
                                    userAnalytics.identifier
                                        ? userAnalytics.identifier
                                        : "",
                                    userAnalytics.name
                                        ? userAnalytics.name
                                        : "",
                                    userAnalytics.email
                                        ? userAnalytics.email
                                        : "",
                                    userAnalytics.title &&
                                    userAnalytics.title[userLanguage]
                                        ? userAnalytics.title[userLanguage]
                                        : "",
                                    userAnalytics.company
                                        ? userAnalytics.company
                                        : "",
                                    !userAnalytics.firstAccess ? "Yes" : "No",
                                    userAnalytics.total_access
                                        ? userAnalytics.total_access
                                        : 0,
                                    userAnalytics.totalTimeOn
                                        ? userAnalytics.totalTimeOn
                                        : 0,
                                ]);

                            const wb = XLSX.utils.book_new();
                            const ws = XLSX.utils.aoa_to_sheet([
                                this.speakers_analytics_cell_names,
                                ...rows,
                            ]);

                            XLSX.utils.book_append_sheet(wb, ws, ws_name);

                            // Tracks sheet
                            const ws_tracks_name = "Details";
                            const rows_tracks = [];

                            usersAnalytics.flat().forEach((user) => {
                                if (
                                    user.trackings &&
                                    user.trackings.length > 0
                                ) {
                                    user.trackings.forEach((track) => {
                                        if (track.type == 12) {
                                            rows_tracks.push([
                                                track.userId
                                                    ? track.userId
                                                    : "None",
                                                track.userIdentifier
                                                    ? track.userIdentifier
                                                    : "None",
                                                track.userName
                                                    ? track.userName
                                                    : "None",
                                                track.userEmail
                                                    ? track.userEmail
                                                    : "None",
                                                user && user.title[userLanguage]
                                                    ? user.title[userLanguage]
                                                    : "None",
                                                user && user.company
                                                    ? user.company
                                                    : "None",
                                                track.idOfTrack
                                                    ? track.idOfTrack
                                                    : "None",
                                                track.nameOfTrack &&
                                                track.nameOfTrack
                                                    ? track.nameOfTrack
                                                    : "None",
                                                track.trackAt
                                                    ? moment(
                                                          track.trackAt
                                                      ).format(
                                                          "DD/MM/YYYY - HH:mm:ss"
                                                      )
                                                    : "None",
                                            ]);
                                        }
                                    });
                                }
                            });

                            const ws_tracks = XLSX.utils.aoa_to_sheet([
                                this.speakers_tracks_analytics_cell_names,
                                ...rows_tracks,
                            ]);

                            XLSX.utils.book_append_sheet(
                                wb,
                                ws_tracks,
                                ws_tracks_name
                            );

                            XLSX.writeFile(wb, "speakers-analytics.xlsx");

                            return of(true);
                        })
                    );
                })
            );
    }

    /**************************************/
    /********* Trackings analytics *********/
    /**************************************/

    /**
     * Get tracking analytics
     * @param eventId
     */
    getTrackingAnalytics(eventId: string) {
        let obsArray = [];

        let obsTrackingRoadUser = this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("tracking-road-user")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsTrackingRoadUser);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateTrackingAnalytics({
                            userRoadTracking: [],
                        })
                    );
                    return of(false);
                }

                // Update tracking road user
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(
                        new UpdateUserRoadTrackingAnalytics(result[0])
                    );
                } else {
                    this.store.dispatch(
                        new UpdateUserRoadTrackingAnalytics([])
                    );
                }

                return of(true);
            })
        );
    }

    /**
     * Get users for tracking road analytics
     * @param eventId
     */
    getUsersForTrackingAnalytics(eventId: string) {
        let obsArray = [];

        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendees);

        let obsSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSpeakers);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    return of([]);
                } else {
                    return of(
                        result[1]
                            .filter(
                                (speaker) =>
                                    !result[0].indexOf(
                                        result[1].find(
                                            (attendee) =>
                                                speaker.email == attendee.email
                                        )
                                    ) && speaker.email
                            )
                            .concat(
                                result[0].filter((attendee) => attendee.email)
                            )
                    );
                }
            })
        );
    }

    /**
     * Get user road tracking for specific user
     * @param eventId
     * @param email
     */
    getUserRoadTrackingWithEmail(eventId: string, email: string) {
        return this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("tracking-road-user", (ref) =>
                ref.where("userEmail", "==", email).orderBy("trackAt", "desc")
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
    }

    /**
     * Export analytics for tracking
     * @param eventId
     */
    exportTrackingAnalytics(eventId: string, userLanguage: string) {
        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees", (ref) =>
                ref.where("firstAccess", "==", false)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));

        let obsSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers", (ref) =>
                ref.where("firstAccess", "==", false)
            )
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));

        return zip(obsAttendees, obsSpeakers).pipe(
            switchMap((resultsUsers) => {
                let usersArray = resultsUsers[0].concat(resultsUsers[1]);

                let obsUsersArray = [];
                let chunkedUsers = _.chunk(usersArray, 300);

                chunkedUsers.forEach((chunk) => {
                    obsUsersArray.push(
                        this.https.post(
                            environment.platform.apiBaseUrl +
                                "analyticsExportTrackingsAnalytics",
                            {
                                eventId: eventId,
                                users: chunk,
                            }
                        )
                    );
                });
                return forkJoin(obsUsersArray).pipe(
                    switchMap((results: any) => {
                        let trackingsRoadUsersAnalytics = [];
                        results.flat().forEach((user) => {
                            if (
                                user &&
                                user.trackings &&
                                user.trackings.length > 0
                            ) {
                                trackingsRoadUsersAnalytics =
                                    trackingsRoadUsersAnalytics.concat(
                                        user.trackings
                                    );
                            }
                        });
                        const ws_name = "Tracking all users analytics";
                        const rows = trackingsRoadUsersAnalytics.map(
                            (trackingAnalytic) => [
                                trackingAnalytic.userId
                                    ? trackingAnalytic.userId
                                    : "",
                                trackingAnalytic.userEmail
                                    ? trackingAnalytic.userEmail
                                    : "",
                                trackingAnalytic.userName
                                    ? trackingAnalytic.userName
                                    : "",
                                trackingAnalytic.type
                                    ? trackingAnalytic.type
                                    : "",
                                trackingAnalytic.typeName
                                    ? trackingAnalytic.typeName
                                    : "",
                                trackingAnalytic.idOfTrack
                                    ? trackingAnalytic.idOfTrack
                                    : "",
                                trackingAnalytic.type == 12 &&
                                trackingAnalytic.nameOfTrack
                                    ? trackingAnalytic.nameOfTrack
                                    : trackingAnalytic.nameOfTrack &&
                                      trackingAnalytic.nameOfTrack[userLanguage]
                                    ? trackingAnalytic.nameOfTrack[userLanguage]
                                    : "",
                                moment(trackingAnalytic.trackAt).format(
                                    "DD/MM/YYYY - HH:mm:ss"
                                ),
                            ]
                        );

                        const wb = XLSX.utils.book_new();
                        const ws = XLSX.utils.aoa_to_sheet([
                            this.tracking_road_users_analytics_cell_names,
                            ...rows,
                        ]);

                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.writeFile(
                            wb,
                            "tracking-all-road-user-analytics.xlsx"
                        );

                        return of(true);
                    })
                );
            })
        );
    }

    /**
     * Export analytics for tracking road user
     * @param eventId
     * @param email
     */
    exportTrackingRoadUserAnalytics(
        eventId: string,
        email: string,
        userLanguage: string
    ) {
        return this.afs
            .collection("analytics")
            .doc(eventId)
            .collection("tracking-road-user", (ref) =>
                ref.where("userEmail", "==", email).orderBy("trackAt", "desc")
            )
            .get()
            .pipe(
                map((docs) => {
                    return docs.docs.map((doc) => {
                        return doc.data();
                    });
                }),
                switchMap((trackingRoadUserAnalytics) => {
                    const ws_name = "Tracking road user analytics";
                    const rows = trackingRoadUserAnalytics.map(
                        (trackingAnalytic) => [
                            trackingAnalytic.userId
                                ? trackingAnalytic.userId
                                : "",
                            trackingAnalytic.userEmail
                                ? trackingAnalytic.userEmail
                                : "",
                            trackingAnalytic.userName
                                ? trackingAnalytic.userName
                                : "",
                            trackingAnalytic.type ? trackingAnalytic.type : "",
                            trackingAnalytic.typeName
                                ? trackingAnalytic.typeName
                                : "",
                            trackingAnalytic.idOfTrack
                                ? trackingAnalytic.idOfTrack
                                : "",
                            trackingAnalytic.type == 12 &&
                            trackingAnalytic.nameOfTrack
                                ? trackingAnalytic.nameOfTrack
                                : trackingAnalytic.nameOfTrack &&
                                  trackingAnalytic.nameOfTrack[userLanguage]
                                ? trackingAnalytic.nameOfTrack[userLanguage]
                                : "",
                            moment(trackingAnalytic.trackAt).format(
                                "DD/MM/YYYY - HH:mm:ss"
                            ),
                        ]
                    );

                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet([
                        this.tracking_road_users_analytics_cell_names,
                        ...rows,
                    ]);

                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, "tracking-road-user-analytics.xlsx");

                    return of(true);
                })
            );
    }

    /**************************************/
    /********** Users analytics ***********/
    /**************************************/

    /**
     * Getting users anlytics
     * @param eventId
     */
    getUsersAnalytics(eventId: string) {
        let obsArray = [];

        let obsAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsAttendees);

        let obsSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .get()
            .pipe(map((docs) => docs.docs.map((doc) => doc.data())));
        obsArray.push(obsSpeakers);

        return forkJoin(obsArray).pipe(
            switchMap((result: any[]) => {
                // If nothing
                if (!result || result.length == 0) {
                    this.store.dispatch(
                        new UpdateUsersAnalytics({
                            totalUniqueUsers: 0,
                            currentConnectedAttendees: 0,
                            currentConnectedSpeakers: 0,
                            totalUsersByModules: [],
                            totalAttendees: 0,
                            totalSpeakers: 0,
                        })
                    );
                    return of(false);
                }

                // Update total attendees
                if (result[0] && result[0].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalAttendees(
                            result[0].filter(
                                (attendee) =>
                                    attendee.last_access !== null &&
                                    attendee.last_access !== undefined
                            ).length
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalAttendees(0));
                }

                // Update total speakers
                if (result[1] && result[1].length > 0) {
                    this.store.dispatch(
                        new UpdateTotalSpeakers(
                            result[1].filter(
                                (speaker) =>
                                    speaker.last_access !== null &&
                                    speaker.last_access !== undefined
                            ).length
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalSpeakers(0));
                }

                // Update total unique users
                if (
                    (result[0] && result[0].length > 0) ||
                    (result[1] && result[1].length > 0)
                ) {
                    let totalAttendees =
                        result[0] && result[0].length > 0
                            ? result[0].filter(
                                  (attendee) =>
                                      attendee.last_access !== null &&
                                      attendee.last_access !== undefined
                              ).length
                            : 0;
                    let totalSpeakers =
                        result[1] && result[1].length > 0
                            ? result[1].filter(
                                  (speaker) =>
                                      speaker.last_access !== null &&
                                      speaker.last_access !== undefined
                              ).length
                            : 0;

                    this.store.dispatch(
                        new UpdateTotalUniqueUsers(
                            totalAttendees + totalSpeakers
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateTotalUniqueUsers(0));
                }

                return of(true);
            })
        );
    }

    /**
     * Get current connected users
     * @param eventId
     */
    getCurrentConnectedUsers(eventId: string) {
        if (
            this.subscriptionConnectedAttendees &&
            !this.subscriptionConnectedAttendees.closed
        ) {
            this.subscriptionConnectedAttendees.unsubscribe();
        }
        this.subscriptionConnectedAttendees = this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees", (ref) =>
                ref.where("connected", "==", true)
            )
            .valueChanges()
            .subscribe((connectedAttendees) => {
                if (connectedAttendees && connectedAttendees.length > 0) {
                    this.store.dispatch(
                        new UpdateCurrentConnectedAttendees(
                            connectedAttendees.length
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateCurrentConnectedAttendees(0));
                }
            });

        if (
            this.subscriptionConnectedSpeakers &&
            !this.subscriptionConnectedSpeakers.closed
        ) {
            this.subscriptionConnectedSpeakers.unsubscribe();
        }
        this.subscriptionConnectedSpeakers = this.afs
            .collection("events")
            .doc(eventId)
            .collection("speakers", (ref) => ref.where("connected", "==", true))
            .valueChanges()
            .subscribe((connectedSpeakers) => {
                if (connectedSpeakers && connectedSpeakers.length > 0) {
                    this.store.dispatch(
                        new UpdateCurrentConnectedSpeakers(
                            connectedSpeakers.length
                        )
                    );
                } else {
                    this.store.dispatch(new UpdateCurrentConnectedSpeakers(0));
                }
            });
    }

    /**
     * Unsubscribe subscriptions for connected attendees and speakers
     */
    unsubscribeCurrentConnectedUsers() {
        if (
            this.subscriptionConnectedAttendees &&
            !this.subscriptionConnectedAttendees.closed
        ) {
            this.subscriptionConnectedAttendees.unsubscribe();
        }
        if (
            this.subscriptionConnectedSpeakers &&
            !this.subscriptionConnectedSpeakers.closed
        ) {
            this.subscriptionConnectedSpeakers.unsubscribe();
        }
    }

    /**
     * Export analytics users
     * @param eventId
     */
    exportUsersAnalytics(eventId: string) {
        return this.afs
            .collection("events")
            .doc(eventId)
            .collection("attendees")
            .get()
            .pipe(
                map((docs) => docs.docs.map((doc) => doc.data())),
                switchMap((users) => {
                    let obsUsersArray = [];
                    let chunkedUsers = _.chunk(users, 50);

                    chunkedUsers.forEach((chunk) => {
                        obsUsersArray.push(
                            this.https.post(
                                environment.platform.apiBaseUrl +
                                    "/analyticsExportUsersAnalytics",
                                {
                                    eventId: eventId,
                                    users: chunk,
                                }
                            )
                        );
                    });

                    return forkJoin(obsUsersArray).pipe(
                        switchMap((usersAnalytics: any) => {
                            const ws_name = "Users analytics";
                            const rows = usersAnalytics
                                .flat()
                                .sort((a, b) =>
                                    a.email > b.email
                                        ? 1
                                        : a.email < b.email
                                        ? -1
                                        : 0
                                )
                                .map((userAnalytics) => [
                                    userAnalytics.uid ? userAnalytics.uid : "",
                                    userAnalytics.identifier
                                        ? userAnalytics.identifier
                                        : "",
                                    userAnalytics.name
                                        ? userAnalytics.name
                                        : "",
                                    userAnalytics.email
                                        ? userAnalytics.email
                                        : "",
                                    userAnalytics.edited_profile ? "Yes" : "No",
                                    !userAnalytics.firstAccess ? "Yes" : "No",
                                    userAnalytics.total_access
                                        ? userAnalytics.total_access
                                        : 0,
                                    userAnalytics.firstAccessDate
                                        ? moment(
                                              userAnalytics.firstAccessDate
                                          ).format("DD/MM/YYYY - HH:mm:ss")
                                        : "",
                                    userAnalytics.last_access
                                        ? moment(
                                              userAnalytics.last_access
                                          ).format("DD/MM/YYYY - HH:mm:ss")
                                        : "",
                                    userAnalytics.platforms &&
                                    userAnalytics.platforms.android
                                        ? "Yes"
                                        : "No",
                                    userAnalytics.platforms &&
                                    userAnalytics.platforms.ios
                                        ? "Yes"
                                        : "No",
                                    userAnalytics.platforms &&
                                    userAnalytics.platforms.web
                                        ? "Yes"
                                        : "No",
                                    userAnalytics.totalTimeOn
                                        ? userAnalytics.totalTimeOn
                                        : 0,
                                ]);

                            const wb = XLSX.utils.book_new();
                            const ws = XLSX.utils.aoa_to_sheet([
                                this.users_analytics_cell_names,
                                ...rows,
                            ]);

                            XLSX.utils.book_append_sheet(wb, ws, ws_name);
                            XLSX.writeFile(wb, "users-analytics.xlsx");
                            return of(true);
                        })
                    );
                })
            );
    }
}
