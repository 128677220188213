import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { TypeModule } from 'src/app/enums/type-module';
import { Question } from '../../../app/models/ask-question';
import { map, switchMap, tap, catchError, take } from 'rxjs/operators';
import { Observable, of, forkJoin, combineLatest } from 'rxjs';
import { ModerateQuestion } from 'src/app/models/moderate-question';
import firebase from 'firebase/app'
import { rejects } from 'assert';

@Injectable({
    providedIn: 'root'
})
export class DbAskQuestionProvider {
    headers: HttpHeaders;
    requestOptions: any;
    db: firebase.firestore.Firestore;

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore
    ) {
        this.headers = new HttpHeaders();
        this.db = aFirestore.firestore;
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    /**
     *
     * @param eventId
     * @param onResolve
     */
    getQuestionModuleAndAskItems(eventId: string, onResolve) {
        let moduleId;
        let ref = this.db
            .collection('events')
            .doc(eventId)
            .collection('modules');
        let listAskQuestions = [];
        ref.where('type', '==', TypeModule.ASK_QUESTION)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach((element) => {
                        moduleId = element.data().uid;
                    });

                    let refQuestion = this.db
                        .collection('modules')
                        .doc(moduleId)
                        .collection('items');

                    refQuestion.get().then((data) => {
                        data.forEach((element) => {
                            let question = element.data();
                            this.getAskItem(
                                moduleId,
                                question.uid,
                                (questions) => {
                                    question.questions = questions;
                                    listAskQuestions.push(question);
                                    if (listAskQuestions.length == data.size) {
                                        onResolve({
                                            questions: listAskQuestions,
                                            moduleId: moduleId
                                        });
                                    }
                                }
                            );
                        });
                    });
                }
            })
            .catch((e) => {
                onResolve(e);
            });
    }

    getAskQuestionModule(eventId: string, onResolve) {
        let ref = this.db
            .collection('events')
            .doc(eventId)
            .collection('modules');
        ref.where('type', '==', TypeModule.ASK_QUESTION)
            .get()
            .then((snapshot) => {
                let auxModule = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach((element) => {
                        auxModule.push(element.data());
                    });
                }
                onResolve(auxModule);
            })
            .catch((e) => {
                console.log();
                onResolve(e);
            });
    }

    getAskItem(moduleId, itemId, onResolve) {
        let ref = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('items')
            .doc(itemId);

        ref.get().then((data) => {
            let item = data.data();

            onResolve(item);
        });
    }

    /**
     * Get item or session for an ask question
     * @param moduleId 
     * @param itemOrSessionId 
     * @param type
     */
    getAskQuestionsItemOrSession(moduleId: string, itemOrSessionId: string, type: string) {
        return (this.aFirestore
            .collection('modules')
            .doc(moduleId)
            .collection((type == 'sessions') ? 'sessions' : 'items')
            .doc(itemOrSessionId)
            .snapshotChanges().pipe(
                map((snapshot) => {
                    return (snapshot.payload.data() as any);
                })
            ))
    }

    /**
     * Get all ask questions for an item or a session
     * @param moduleId 
     * @param itemOrSessionId 
     * @param type
     */
    getAskQuestionsForItemOrSession(moduleId: string, itemOrSessionId: string, type: string) {
        return (this.aFirestore
            .collection('modules')
            .doc(moduleId)
            .collection((type == 'sessions') ? 'sessions' : 'items')
            .doc(itemOrSessionId)
            .collection('questions', ref => ref.orderBy('createdAt'))
            .valueChanges().pipe(
                switchMap((docs: ModerateQuestion[]) => {
                    if (docs && docs.length > 0) {
                        let questions: ModerateQuestion[] = [];
                        let obs = [];
                        for (let i = 0; i < docs.length; i++) {
                            let question = docs[i];
                            questions.push(question);

                            obs.push(this.aFirestore
                                .collection('modules')
                                .doc(moduleId)
                                .collection((type == 'sessions') ? 'sessions' : 'items')
                                .doc(itemOrSessionId)
                                .collection('questions')
                                .doc(question.uid)
                                .collection('votes').valueChanges().pipe(
                                    switchMap((votesArray: any[]) => {
                                        question.totalVotes = (votesArray && votesArray.length > 0) ? votesArray.length : 0;
                                        question.answered = (!question.answered) ? false : question.answered;
                                        question.edit = (!question.edit) ? false : question.edit;
                                        return (of(true));
                                    })
                                ));
                        }
                        return (combineLatest(obs).pipe(
                            switchMap(() => {
                                return (of(questions));
                            })
                        ));
                    } else {
                        return (of([]));
                    }
                })
            )
        )
    }

    getQuestions(moduleId, onResolve) {
        let ref = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('items')
            .orderBy('order', 'asc');

        ref.get().then((data) => {
            let listQuestions = [];
            data.forEach((element) => {
                listQuestions.push(element.data());
            });
            onResolve(listQuestions);
        });
    }

    changeOrder(moduleId, questions, onResolve) {
        let batch = this.db.batch();

        for (let question of questions) {
            let ref = this.db
                .collection('modules')
                .doc(moduleId)
                .collection('items')
                .doc(question.uid);
            batch.update(ref, { order: question.order });
        }

        batch
            .commit()
            .then(() => {
                console.log('success');
                onResolve(true);
            })
            .catch((e) => {
                console.log(e);
                onResolve(false);
            });
    }

    /**
     * @description update the given values ("fields") to all documents present in the collection provide in the "path" param
     * @param path firebase firestore collection path (e.g. "events/\<eventId\>/modules")
     * @param fields an object with the fields and values to be updated
     */
    public bulkUpdate(path: string, fields: Object) {
        const body = {
            path: path,
            fields: fields
        }
        
        return this.http.post(PathApi.baseUrl + 'dbBulkDataFlowUpdate', body, this.requestOptions).toPromise();
    }

    /**
     * @description update the fields of a firebase firestore document 
     * @param path firebase firestore document path (e.g. "events/\<eventId\>")
     * @param fields an object with the fields and values to be updated
     * @returns 
     */
    public singleDocUpdate(path: string, fields: Object): Promise<void> {
        return this.db.doc(path).update(fields)
    }

    getQuestionsSession(moduleId, sessionId, onResolve) {
        let db = this.aFirestore.firestore;

        const refModule = db
            .collection('modules')
            .doc(moduleId)
            .collection('sessions')
            .doc(sessionId)
            .collection('questions');

        //busca as questões ordenadas da mais antiga pra mais nova
        refModule.orderBy('createdAt').onSnapshot((snapshot) => {
            let totalQuestions = snapshot.size;
            let listQuestions = [];

            //passa por todas as questões
            let cont = 0;
            snapshot.forEach((element) => {
                let question = element.data();
                //marca que o usuário que solicitou as questões ainda não curtiu
                question.userLiked = false;

                refModule
                    .doc(question.uid)
                    .collection('votes')
                    .onSnapshot((snapshotVotes) => {
                        //caso a questão tenha votos
                        if (snapshotVotes.size >= 1) {
                            question.totalVotes = snapshotVotes.size;

                            let index = this.checkIndexExists(
                                listQuestions,
                                question
                            );

                            if (index >= 0) {
                                listQuestions[index] = question;
                            } else {
                                listQuestions.push(question);
                            }

                            if (cont == totalQuestions - 1) {
                                // console.log('sort')
                                // listQuestions.sort(function (a, b) {
                                //     if (a.totalVotes < b.totalVotes) {
                                //         return 1;
                                //     }
                                //     if (a.totalVotes > b.totalVotes) {
                                //         return -1;
                                //     }
                                //     // a must be equal to b
                                //     return 0;
                                // });

                                onResolve(listQuestions);
                            }

                            cont++;
                        } else {
                            // caso a questão não tenha votos
                            question.totalVotes = 0;

                            let index = this.checkIndexExists(
                                listQuestions,
                                question
                            );

                            if (index >= 0) {
                                listQuestions[index] = question;
                            } else {
                                listQuestions.push(question);
                            }

                            if (cont == totalQuestions - 1) {
                                // console.log('sort')
                                // listQuestions.sort(function (a, b) {
                                //     if (a.totalVotes < b.totalVotes) {
                                //         return 1;
                                //     }
                                //     if (a.totalVotes > b.totalVotes) {
                                //         return -1;
                                //     }
                                //     // a must be equal to b
                                //     return 0;
                                // });

                                onResolve(listQuestions);
                            }

                            cont++;
                        }
                    });
            });
        });
    }

    /**
     * @description make a question, this is, create or edit a existing one
     * @param eventId 
     * @param moduleId 
     * @param question 
     * @param callback 
     */
    public makeQuestion(
        moduleId: string,
        question: Question,
        callback = (question: Question) => {}
    ) {
        const refCol = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('items');

        question.name = Object.assign({}, question.name);
        let promise: Promise<void>;

        if (question.uid == null) {
            const refDoc = refCol.doc();
            question.uid = refDoc.id;

            promise = refDoc.set(question);
        }
        else {
            const refDoc = refCol.doc(question.uid);
            promise = refDoc.update(question);
        }

        promise.then(() => {
            callback(question);
        })
        .catch((error) => { 
            console.log(error);
            callback(null)
        })
    }

    /**
     * Delete an item for ask a question
     * @param moduleId 
     * @param itemId 
     */
    deleteQuestion(moduleId, itemId) {
        let refItem = this.aFirestore.collection('modules').doc(moduleId).collection('items').doc(itemId).ref;
        return (this.aFirestore
            .collection('modules')
            .doc(moduleId)
            .collection('items')
            .doc(itemId)
            .collection('questions')
            .get().pipe(
                switchMap(async (docs) => {
                    if (docs.size > 0) {

                        let obsArray$ = [];

                        for (let question of docs.docs) {

                            obsArray$.push(this.aFirestore
                                .collection("moduleId")
                                .doc(moduleId)
                                .collection('items')
                                .doc(itemId)
                                .collection('questions')
                                .doc(question.id)
                                .collection('votes')
                                .get().pipe(
                                    switchMap(async (docs) => {
                                        let batch = this.aFirestore.firestore.batch();
                                        if (docs.size > 0) {
                                            for (let iV = 0; iV < docs.docs.length; iV++) {
                                                batch.delete(docs.docs[iV].ref);
                                            }
                                        }

                                        batch.delete(question.ref);
                                        batch.delete(refItem);

                                        await batch.commit();
                                        return (of(true).toPromise());
                                    })
                                )
                            )
                        }

                        return (forkJoin(obsArray$).toPromise());
                    } else {
                        await refItem.delete();
                        return (of(true).toPromise());
                    }
                })
            ))
    }

    /**
     * Clear result for a question of an item
     * @param moduleId 
     * @param itemId 
     */
    clearResultQuestion(moduleId, itemId) {
        return (this.aFirestore
            .collection("moduleId")
            .doc(moduleId)
            .collection('items')
            .doc(itemId)
            .collection('questions')
            .get().pipe(
                switchMap((docs) => {
                    if (docs.size > 0) {

                        let obsArray$ = [];

                        for (let question of docs.docs) {
                            obsArray$.push(this.aFirestore
                                .collection("moduleId")
                                .doc(moduleId)
                                .collection('items')
                                .doc(itemId)
                                .collection('questions')
                                .doc(question.id)
                                .collection('votes')
                                .get().pipe(
                                    switchMap(async (docs) => {
                                        let batch = this.aFirestore.firestore.batch();

                                        if (docs.size > 0) {
                                            for (let iV = 0; iV < docs.docs.length; iV++) {
                                                batch.delete(docs.docs[iV].ref);
                                            }
                                        }

                                        batch.delete(question.ref);
                                        await batch.commit();
                                        return (of(true).toPromise());
                                    })
                                )
                            )
                        }

                        return (forkJoin(obsArray$));
                    } else {
                        return (of(true));
                    }
                })
            ))
    }

    /**
     * Clear questions and votes of an item for a session
     * @param eventId 
     * @param moduleId 
     * @param sessionId 
     */
    clearResultQuestionSession(eventId, moduleId, sessionId) {
        let eventObs = this.aFirestore
            .collection('events')
            .doc(eventId)
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .get().pipe(
                switchMap((docs) => {
                    if (docs.size > 0) {

                        let obsArray$ = [];

                        for (let question of docs.docs) {
                            obsArray$.push(this.aFirestore
                                .collection("events")
                                .doc(eventId)
                                .collection('sessions')
                                .doc(sessionId)
                                .collection('questions')
                                .doc(question.id)
                                .collection('votes')
                                .get().pipe(
                                    switchMap(async (docs) => {
                                        let batch = this.aFirestore.firestore.batch();
                                        if (docs.size > 0) {
                                            for (let iV = 0; iV < docs.docs.length; iV++) {
                                                batch.delete(docs.docs[iV].ref);
                                            }
                                        }

                                        batch.delete(question.ref);
                                        await batch.commit();

                                        return (of(true).toPromise());
                                    })
                                ));
                        }

                        return (forkJoin(obsArray$));
                    } else {
                        return (of(true));
                    }
                })
            )

        let moduleObs = this.aFirestore
            .collection('modules')
            .doc(moduleId)
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .get().pipe(
                switchMap((docs) => {
                    if (docs.size > 0) {
                        let obsArray$ = [];

                        for (let question of docs.docs) {
                            obsArray$.push(this.aFirestore
                                .collection("modules")
                                .doc(moduleId)
                                .collection('sessions')
                                .doc(sessionId)
                                .collection('questions')
                                .doc(question.id)
                                .collection('votes')
                                .get().pipe(
                                    switchMap(async (docs) => {
                                        let batch = this.aFirestore.firestore.batch();
                                        if (docs.size > 0) {
                                            for (let iV = 0; iV < docs.docs.length; iV++) {
                                                batch.delete(docs.docs[iV].ref);
                                            }
                                        }

                                        batch.delete(question.ref);
                                        await batch.commit();
                                        return (of(true).toPromise());
                                    })
                                )
                            )
                        }

                        return (forkJoin(obsArray$));
                    } else {
                        return (of(true));
                    }
                })
            )


        return (forkJoin([eventObs, moduleObs]));
    }

    /**
     * Clear all sessions questions results
     * @param moduleId 
     * @param questionsIds 
     */
    clearAllSessionsQuestionsResults(eventId: string, sessions: Array<any>): Observable<any> {
        let clearSessionsResults$ = [];

        if (!sessions || sessions.length == 0) {
            return (of(true));
        } else {
            for (let session of sessions) {
                clearSessionsResults$.push(this.aFirestore
                    .collection('events')
                    .doc(eventId)
                    .collection('sessions')
                    .doc(session.uid)
                    .collection('questions')
                    .get().pipe(
                        switchMap((docs) => {
                            if (docs.size > 0) {
                                let obsArray$ = [];

                                for (let question of docs.docs) {
                                    obsArray$.push(this.aFirestore
                                        .collection("events")
                                        .doc(eventId)
                                        .collection('sessions')
                                        .doc(session.uid)
                                        .collection('questions')
                                        .doc(question.id)
                                        .collection('votes')
                                        .get().pipe(
                                            switchMap((docs) => {
                                                let batch = this.aFirestore.firestore.batch();
                                                if (docs.size > 0) {
                                                    for (let iV = 0; iV < docs.docs.length; iV++) {
                                                        batch.delete(docs.docs[iV].ref);
                                                    }
                                                }

                                                batch.delete(question.ref);
                                                return (batch.commit());
                                            })
                                        ));
                                }

                                return (forkJoin(obsArray$));
                            } else {
                                return (of(true));
                            }
                        })
                    ))

                clearSessionsResults$.push(this.aFirestore
                    .collection('modules')
                    .doc(session.moduleId)
                    .collection('sessions')
                    .doc(session.uid)
                    .collection('questions')
                    .get().pipe(
                        switchMap((docs) => {
                            if (docs.size > 0) {

                                let obsArray$ = [];

                                for (let question of docs.docs) {
                                    obsArray$.push(this.aFirestore
                                        .collection("modules")
                                        .doc(session.moduleId)
                                        .collection('sessions')
                                        .doc(session.uid)
                                        .collection('questions')
                                        .doc(question.id)
                                        .collection('votes')
                                        .get().pipe(
                                            switchMap((docs) => {
                                                let batch = this.aFirestore.firestore.batch();
                                                if (docs.size > 0) {
                                                    for (let iV = 0; iV < docs.docs.length; iV++) {
                                                        batch.delete(docs.docs[iV].ref);
                                                    }
                                                }

                                                batch.delete(question.ref);
                                                return (batch.commit());
                                            })
                                        )
                                    )
                                }

                                return (forkJoin(obsArray$));
                            } else {
                                return (of(true));
                            }
                        })
                    ))
            }
            return (forkJoin(clearSessionsResults$));
        }
    }

    /**
     * Clear all questions results
     * @param moduleId 
     * @param questionsIds 
     */
    clearAllQuestionsResults(moduleId: string, questionsIds: Array<string>): Observable<any> {
        let questionsArray$ = [];
        if (!questionsIds || questionsIds.length == 0) {
            return (of(true));
        } else {
            for (let questionId of questionsIds) {
                questionsArray$.push(this.aFirestore.collection('modules')
                    .doc(moduleId)
                    .collection('items')
                    .doc(questionId)
                    .collection('questions').get().pipe(
                        switchMap((items) => {
                            if (items.size > 0) {
                                let votesArrays$ = [];

                                for (let item of items.docs) {
                                    votesArrays$.push(this.aFirestore.collection('modules')
                                        .doc(moduleId)
                                        .collection('items')
                                        .doc(questionId)
                                        .collection('questions')
                                        .doc(item.id)
                                        .collection('votes').get().pipe(
                                            switchMap((votes) => {
                                                if (votes.size > 0) {
                                                    for (let vote of votes.docs) {
                                                        vote.ref.delete();
                                                    }
                                                }

                                                return (item.ref.delete());
                                            })
                                        ))
                                }
                                return (forkJoin(votesArrays$));
                            } else {
                                return (of(true));
                            }
                        })
                    ))
            }

            return (forkJoin(questionsArray$));
        }
    }

    checkIndexExists(array, item) {
        return array
            .map(function (e) {
                return e.uid;
            })
            .indexOf(item.uid);
    }

    /**
     * Update visibility for a question on an item or session
     * @param eventId
     * @param moduleId 
     * @param itemOrSessionId 
     * @param questionsIds 
     * @param visibility 
     * @param type 
     */
    updateVisibilityAskQuestionItemOrSession(eventId: string, moduleId: string, itemOrSessionId: string, questionsIds: string[], visibility: boolean, type: string) {
        let batch = this.aFirestore.firestore.batch();
        questionsIds.forEach((questionId) => {
            let docRef = this.aFirestore
                .collection('modules')
                .doc(moduleId)
                .collection((type == 'sessions') ? 'sessions' : 'items')
                .doc(itemOrSessionId)
                .collection('questions')
                .doc(questionId).ref;

            if (type == 'sessions') {
                let docRefEvent = this.aFirestore
                    .collection('events')
                    .doc(eventId)
                    .collection((type == 'sessions') ? 'sessions' : 'items')
                    .doc(itemOrSessionId)
                    .collection('questions')
                    .doc(questionId).ref;

                batch.update(docRefEvent, { visibility: visibility })
            }

            batch.update(docRef, { visibility: visibility });
        })
        return (batch.commit());
    }

    /**
     * Update answered for a question on an item or session
     * @param eventId
     * @param moduleId 
     * @param itemOrSessionId 
     * @param questionsIds 
     * @param answered 
     * @param type 
     */
    updateAnsweredAskQuestionItemOrSession(eventId: string, moduleId: string, itemOrSessionId: string, questionsIds: string[], answered: boolean, type: string) {
        let batch = this.aFirestore.firestore.batch();
        questionsIds.forEach((questionId) => {
            let docRef = this.aFirestore
                .collection('modules')
                .doc(moduleId)
                .collection((type == 'sessions') ? 'sessions' : 'items')
                .doc(itemOrSessionId)
                .collection('questions')
                .doc(questionId).ref;

            if (type == 'sessions') {
                let docRefEvent = this.aFirestore
                    .collection('events')
                    .doc(eventId)
                    .collection((type == 'sessions') ? 'sessions' : 'items')
                    .doc(itemOrSessionId)
                    .collection('questions')
                    .doc(questionId).ref;

                batch.update(docRefEvent, { answered: answered })
            }

            batch.update(docRef, { answered: answered });
        })
        return (batch.commit());
    }

    exportAskQuestion(moduleId, sessionId, onResolve) {
        this.http
            .get(
                PathApi.baseUrl +
                PathApi.dbAskQuestionExport +
                '?moduleId=' +
                moduleId +
                '&sessionId=' +
                sessionId,
                this.requestOptions
            )
            .subscribe(
                (data) => {
                    onResolve(data);
                },

                (err) => {
                    onResolve(err);
                }
            );
    }

    exportAskQuestionGeral(moduleId, itemId, onResolve) {
        this.http
            .get(
                PathApi.baseUrl +
                PathApi.dbAskQuestionExportGeral +
                '?moduleId=' +
                moduleId +
                '&itemId=' +
                itemId,
                this.requestOptions
            )
            .subscribe(
                (data) => {
                    onResolve(data);
                },

                (err) => {
                    onResolve(err);
                }
            );
    }

    /**
     * Delete questions on an item or a session
     * @param moduleId 
     * @param itemOrSessionId 
     * @param questionsIds
     * @param type
     */
    deleteQuestionForItemOrSession(moduleId: string, itemOrSessionId: string, questionsIds: string[], type: string) {
        let obsArray = [];

        if (questionsIds.length == 0) {
            return (of(null));
        }

        questionsIds.forEach((questionId) => {
            let refDocQuestion = this.aFirestore
                .collection('modules')
                .doc(moduleId)
                .collection((type == 'sessions') ? 'sessions' : 'items')
                .doc(itemOrSessionId)
                .collection('questions')
                .doc(questionId).ref;
            obsArray.push(
                this.aFirestore
                    .collection('modules')
                    .doc(moduleId)
                    .collection((type == 'sessions') ? 'sessions' : 'items')
                    .doc(itemOrSessionId)
                    .collection('questions')
                    .doc(questionId)
                    .collection("votes")
                    .get()
                    .pipe(
                        take(1),
                        switchMap((votes) => {
                            let batch = this.aFirestore.firestore.batch();
                            votes.forEach((vote) => {
                                batch.delete(vote.ref);
                            })

                            batch.delete(refDocQuestion);
                            return (batch.commit());
                        })
                    )
            )
        })

        return (forkJoin(obsArray));
    }

    /**
     * Edit question on an item or a session
     * @param moduleId 
     * @param itemOrSessionId 
     * @param questionId 
     * @param question 
     * @param type 
     */
    editQuestionOnItemOrSession(moduleId: string, itemOrSessionId: string, question: ModerateQuestion, type: string) {
        return (
            this.aFirestore
                .collection('modules')
                .doc(moduleId)
                .collection((type == 'sessions') ? 'sessions' : 'items')
                .doc(itemOrSessionId)
                .collection('questions')
                .doc(question.uid)
                .update(question)
        )
    }
}
