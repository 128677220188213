import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DbInfoboothProvider } from 'src/app/providers/database/db-infobooth';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { Location } from '@angular/common';
import { EmailEditorComponent } from 'angular-email-editor';

declare var $: any;

@Component({
    selector: 'app-infobooth',
    templateUrl: './infobooth.component.html',
    styleUrls: ['./infobooth.component.scss']
})

export class InfoboothComponent implements OnInit {
    // get the language of the user.
    public userLanguage: string

    // bloc editor
    emailEditorActive: boolean;
    editedContent;
    pageContent: any;
    emptyTemplate = {
        counters: { u_column: 1, u_row: 1, u_content_text: 1, u_content_heading: 1 },
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [],
                  values: {
                    backgroundColor: "#FFFFFF",
                    padding: "0px",
                    border: {},
                    _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: "#FFFFFF",
                columnsBackgroundColor: "#FFFFFF",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false,
                },
                padding: "0px",
                hideDesktop: false,
                _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            },
          ],
          values: {
            textColor: "#000000",
            backgroundColor: "#FFFFFF",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            contentWidth: "1200px",
            contentAlign: "center",
            fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
            preheaderText: "",
            linkStyle: {
              body: true,
              linkColor: "#0000ee",
              linkHoverColor: "#0000ee",
              linkUnderline: true,
              linkHoverUnderline: true,
            },
            _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
          },
        },
        schemaVersion: 6,
      };

    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;


    public module = null

    htmlContent = {
        PtBR: '',
        EnUS: '',
        EsES: '',
        FrFR: '',
        DeDE: ''
    };
    title = {
        PtBR: '',
        EnUS: '',
        EsES: '',
        FrFR: '',
        DeDE: ''
    };
    eventId: string;
    moduleId: string;

    public formGroup: FormGroup;

    loader: boolean
    pageEdit = null
    event: any;
    languages: Languages = null //event languages
    principalEventLanguageFormated: string = 'PtBR';
    activeCreateLanguage: string = 'PtBR';
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    constructor(
        private route: ActivatedRoute,
        private dbInfobooth: DbInfoboothProvider,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        public location: Location
    ) { }

    ngOnInit() {
        this.loader = true

        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        this.loadModule();
        this.startEvent();
        this.loadPageInfobooth()
        this.getUserLanguage()
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
            this.activeCreateLanguage = this.principalEventLanguageFormated;
            this.languages = event.languages;
        });
    }

    loadModule() {
        this.dbInfobooth.getModule(this.moduleId, (module) => {
            this.module = module;
        })
    }

    ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
        this.eventId = this.route.parent.params['_value']['uid'];

        if (this.moduleId !== this.route.snapshot.params['moduleId']) {
            this.loader = true

            this.moduleId = this.route.snapshot.params['moduleId'];
            this.loadPageInfobooth()
            this.loadModule();
            this.getUserLanguage()
        }
    }

    editorLoaded(){
        const design = this.pageContent ? JSON.parse(this.pageContent) 
                        : this.emptyTemplate;
    
        setTimeout(() => {
            this.emailEditor.loadDesign(design)
        },500)
      }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    loadPageInfobooth() {
        this.dbInfobooth.getPageModule(this.moduleId, (page) => {
            this.loader = false

            this.pageEdit = page;
            this.title = this.pageEdit.title
            this.htmlContent = this.pageEdit.htmlContent
            this.pageContent = page.pageContent;
            this.emailEditorActive = page.emailEditorActive;
        })
    }

    // Refresh infobooth module page
    errorPrincipalLanguageBlank: boolean = false;
    updatePage() {
        console.log("Content: ", this.htmlContent);
        this.errorPrincipalLanguageBlank = false;
        this.pageEdit.title = this.title
        this.pageEdit.emailEditorActive = this.emailEditorActive;

        if (this.pageEdit.title[this.principalEventLanguageFormated] !== '') {
        for (let lang in this.pageEdit.title) {
            if (this.pageEdit.title[lang] == '') {
            this.pageEdit.title[lang] = this.pageEdit.title[this.principalEventLanguageFormated];
            }
        }
        } else {
        this.errorPrincipalLanguageBlank = true;
        return;
        }  

        if (this.emailEditorActive) {
            // Bloc editor used
            // update 
            this.emailEditor.editor.exportHtml((data) => {
                console.log(data)
              const design = JSON.stringify(data.design);
              let htmlContent = data.chunks.body;
              htmlContent = htmlContent.trim();
              this.pageEdit.pageHTMLContent = htmlContent;
              this.pageEdit.pageContent = design;
      
              this.dbInfobooth.updatePage(this.pageEdit).then((success) => {
                this.successSwal.fire();
                this.loader = false;
                return;
                // this.location.back();
              }).catch((error) => {
                this.errorSwal.fire();
                this.loader = false;
              })
            });
          } else {
            // ckeditor user
            this.pageEdit.emailEditorActive = false;
            const contentHtml = {
              PtBR: this.htmlContent.PtBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
              EnUS: this.htmlContent.EnUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
              EsES: this.htmlContent.EsES.replace(/href="/g, 'class="wysiwyg-link" href="'),
              FrFR: this.htmlContent.FrFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
              DeDE: this.htmlContent.DeDE.replace(/href="/g, 'class="wysiwyg-link" href="')
            }
            this.pageEdit.htmlContent = contentHtml;
            // this.pageEdit.emaiEditorActive = this.emailEditorActive false;

            this.dbInfobooth.updatePage(this.pageEdit).then((success) => {
              this.successSwal.fire();
              this.loader = false;
              // this.location.back();
            }).catch((error) => {
                console.log(error)
              this.errorSwal.fire();
              this.loader = false;
            })
          }
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }
}
