import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { GlobalService } from "src/app/providers/global/global.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { TypeUser } from "src/app/enums/typeUser";
import { TypeModule } from "src/app/enums/type-module";
import { FormatedEventLanguageService } from "src/app/providers/formated-event-language/formated-event-language.service";

declare let $: any;
@Component({
    selector: "app-client-event-dashboard",
    templateUrl: "./client-event-dashboard.component.html",
    styleUrls: ["./client-event-dashboard.component.scss"],
})
export class ClientEventDashboardComponent implements OnInit {
    public navLogo: string = environment.platform.navLogo;
    public license: string = environment.platform.license;

    public eventId: string = this.route.parent.params["_value"]["uid"];

    public modulesGeneral = [];

    public currentUserType = -1;
    public displayName: string = null;
    public photoUrl: string = null;
    public userUid: string = null;
    public userType: number;

    event: any = null;

    // modules
    moduleGroup = null;
    managerModules = null;
    moduleNotification = null;

    navActive: string = null;

    // type module
    public module_external_link = TypeModule.EXTERNAL_LINK;
    visibility: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private auth: AuthService,
        public formatedLanguage: FormatedEventLanguageService
    ) {
        this.getEvent();
        this.getModulesGeneral();
        this.getModuleGroup();
        this.getModuleManagerModules();
        this.getModuleNotifications();
    }

    ngAfterContentChecked() {
        this.displayName = this.global.displayName;
        this.photoUrl = this.global.photoUrl;
        this.userUid = this.global.userId;
        this.userType = this.global.userType;

        if (
            this.router.url.includes("info") ||
            this.router.url.includes("legal") ||
            this.router.url.includes("integrations") ||
            this.router.url.includes("public-access") ||
            this.router.url.includes("private-access")
        ) {
            this.navActive = "event";
        } else if (this.router.url.includes("design")) {
            this.navActive = "design";
        } else if (this.router.url.includes("analytics")) {
            this.navActive = "analytics";
        // } else if (this.router.url.includes("mailing")) {
        //     this.navActive = "mailing";
        } else if (this.router.url.includes('hotsite')) {
            this.navActive = "hotsite";
        } else if (this.router.url.includes('engagement')) {
            this.navActive = "engagement";
        } else if (this.router.url.includes('app-translation')) {
            this.navActive = "app-translation";
        } else if (this.router.url.includes('groups')) {
            this.navActive = "groups";
        } else if (this.router.url.includes('notifications')) {
            this.navActive = "notifications";
        } else {
            this.navActive = "modules";
        }
    }

    ngOnInit() {
        const dropdownItems = document.querySelectorAll('.dropdown-content');
        const checkbox = document.getElementById('dropdown-toggle');

        dropdownItems.forEach(item => {
            item.addEventListener('click', function() {
                (checkbox as HTMLInputElement).checked = false;
            });
        });

        $(document).ready(function () {
            // Menu Trigger
            $("#menuToggle").on("click", function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $("body").removeClass("open");
                    if (windowWidth < 760) {
                        $("#left-panel").slideToggle();
                    } else {
                        $("#left-panel").toggleClass("open-menu");
                    }
                } else {
                    $("body").toggleClass("open");
                    $("#left-panel").removeClass("open-menu");
                }
            });

            $(".menu-item-has-children.dropdown").each(function () {
                $(this).on("click", function () {
                    var $temp_text = $(this)
                        .children(".dropdown-toggle")
                        .html();
                    $(this)
                        .children(".sub-menu")
                        .prepend(
                            '<li class="subtitle">' + $temp_text + "</li>"
                        );
                });
            });

            // Load Resize
            $(window).on("load resize", function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $("body").addClass("small-device");
                } else {
                    $("body").removeClass("small-device");
                }
            });
        });
    }

    getModulesGeneral() {
        this.dbEvent.getModulesEvent(this.eventId, (modules) => {
            this.modulesGeneral = [];
            this.modulesGeneral = modules;
        });
    }

    getModuleGroup() {
        this.dbEvent.getModuleManagerGroup(this.eventId, (module) => {
            this.moduleGroup = module;
        });
    }

    getModuleManagerModules() {
        this.dbEvent.getModuleManagerModules(this.eventId, (module) => {
            this.managerModules = module;
        });
    }

    getModuleNotifications() {
        this.dbEvent.getModuleNotification(this.eventId, (module) => {
            this.moduleNotification = module;
        });
    }

    async getEvent() {
        await this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.visibility = event.visibility;
            console.log(this.visibility);
            this.formatedLanguage.convertLangFormat(event.language);
        });
    }

    redirectDashboard() {
        if (
            this.userType == TypeUser.GOD ||
            this.userType == TypeUser.SUPERGOD
        ) {
            this.router.navigate(["/dashboard"]);
        } else {
            this.router.navigate(["/dash"]);
        }
    }

    redirectProfile() {
        if (
            this.userType == TypeUser.GOD ||
            this.userType == TypeUser.SUPERGOD
        ) {
            this.router.navigate([`/dashboard/profile/${this.userUid}`]);
        } else {
            this.router.navigate([`/dash/my-profile/${this.userUid}`]);
        }
    }

    logoutUser() {
        this.auth.logoutUser();
    }
}
