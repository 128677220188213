export class QRCode {
    uid: string;
    title: string;
    points: number;
    moduleId: string;
    eventId: string;
    createdAt: number;
    queryTitle: string;

    constructor(title: string, points: number, moduleId: string, eventId: string) {
        this.uid = "";
        this.title = title;
        this.queryTitle = title.toUpperCase();
        this.points = points;
        this.moduleId = moduleId;
        this.eventId = eventId;
        this.createdAt = null;
    }
}