import {
    Component,
    OnInit,
    NgModule,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DbScheduleProvider } from "src/app/providers/database/db-schedule";
import { Session } from "src/app/models/session";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { LuxonService } from "src/app/providers/luxon/luxon.service";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";
import { DbLocationsProvider } from "src/app/providers/database/db-locations";
import { Location } from "../../../../../models/location";
import { RegexProvider } from "../../../../../providers/regex/regex.service";
import { Track } from "../../../../../models/track";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { GlobalService } from "src/app/providers/global/global.service";
import { FilterPiperSessions } from "src/app/pipes/filter-sessions.pipe";
import { GetNameSession } from "src/app/pipes/get-name-session";
import { Languages } from "src/app/models/languages";
import { WherebyService } from "src/app/providers/whereby/whereby.service";
import { DateTime } from "luxon";
import * as _ from "lodash-es";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";
import * as timezones from 'timezones.json'

declare let $: any;
type AOA = Array<Array<any>>;

@Component({
    selector: "app-schedules",
    templateUrl: "./schedules.component.html",
    styleUrls: ["./schedules.component.scss"],
})
@NgModule({
    declarations: [FilterPiperSessions, GetNameSession],
})
export class SchedulesComponent implements OnInit {
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("deleteSwalTrack") public deleteSwalTrack: SwalComponent;
    @ViewChild("inputFile") public inputFile: ElementRef;
    @ViewChild("inputFileTrack") public inputFileTrack: ElementRef;
    public p: number = 1;
    term: string = "";
    limitForPage: number;

    event: any;
    eventId: string = this.route.parent.params["_value"]["uid"];
    moduleId: string = this.route.params["_value"]["moduleId"];

    public module: ModuleSchedule = null;
    oldSessions: Array<Session>;
    sessions: Array<Session>;
    allListSessions: Array<Session>;
    locations: Array<Location>;
    sessionsChecked = [];

    auxDeleteSession;

    loader: boolean;

    dataImportPrincipal = []; //receives data from main session worksheet to import
    dataImportSessions_DeDE = []; //receive data from session de-DE worksheet to import
    dataImportSessions_EnUS = []; //receive data from session en-US worksheet to import
    dataImportSessions_EsES = []; //receive data from session es-ES worksheet to import
    dataImportSessions_FrFR = []; //receive data from session fr-FR worksheet to import
    dataImportSessions_PtBR = []; //receive data from session pt-BR worksheet to import

    dataImportTrack = [];
    dataImportTrackPtBR = [];
    dataImportTrackEnUS = [];
    dataImportTrackEsES = [];
    dataImportTrackFrFR = [];
    dataImportTrackDeDE = [];

    dataExportSessions: AOA;
    dataExportSessions_DeDE: AOA;
    dataExportSessions_EnUS: AOA;
    dataExportSessions_EsES: AOA;
    dataExportSessions_FrFR: AOA;
    dataExportSessions_PtBR: AOA;

    dataExportTracks: AOA;
    dataExportTracksPtBR: AOA;
    dataExportTracksEnUS: AOA;
    dataExportTracksEsES: AOA;
    dataExportTracksFrFR: AOA;
    dataExportTracksDeDE: AOA;

    nameFile = ".xls, .xlsx, .ods, .fods, .ots";

    loaderImport: boolean = false; // loader after click button import

    // Import Variables
    messageErrors: Array<String>; // array with error messages.
    sessionsErrorsApi: Array<Session> = []; //array with error messages duplicate identifiers in other modules

    theError: boolean = false; // display error messages
    theErrorApi: boolean = false; // display error after send data to API
    theErrorApiDb: boolean = false; // display error after send data to API and have DB error
    successMsg: boolean = false; // display success message

    translatedIdentifierTrack: number = 0;
    translatedNameTrack: number = 1;

    // column EXCEL
    startDate = 2;
    startTime = 3;
    endTime = 4;

    // TRACK IMPORT EXCEL
    trackIdN: number = 0;
    trackNameN: number = 1;
    trackTxtColorN: number = 2;
    trackBgColorN: number = 3;
    newTracks: Array<Track> = [];

    // Translation Variables
    valueTranslation1;
    valueTranslation2;
    valueTranslation3;

    // tracks
    trackIdentifier: string = null;
    trackName = {
        PtBR: "",
        EnUS: "",
        EsES: "",
        FrFR: "",
        DeDE: "",
    };
    nTrackBgColor: string = "#4d4646";
    nTrackTxtColor: string = "#ffffff";
    newTrackLoader: boolean = false;
    trackNameLength: boolean = false;
    trackErrorMsg: boolean = false;
    tracks: Array<Track> = [];
    trackDeleteId: string = null;
    newTrackName = {
        PtBR: "",
        EnUS: "",
        EsES: "",
        FrFR: "",
        DeDE: "",
    };
    trackFilter: string = "all";
    editTrackId: string = null;
    editTrackIndex: string = null;
    editTrackView: boolean = false;
    successMsgTrack: boolean = false;
    theErrorTrack: boolean = false;
    loaderImportTrack: boolean = false;
    importTrackCreated: Array<any> = [];
    importTrackUpdated: Array<any> = [];

    // module
    allowVisioSetting: boolean = false;
    oratorsField: string = "";
    disableOratorsField: boolean = false;

    private sessionTimeImport: XLSX.WorkSheet; //saves the cells that will be used to pick up the dates and times to import.

    public userLanguage: string; // get the language of the user.
    public userLanguageFormated: string = "PtBR";
    public eventLanguage: string; // get the language of the event.
    public languages: Languages; //all languages of the event.
    public activeTrackLanguage: string = "PtBR";
    public principalEventLangFormated: string = "PtBR";
    trackLocation: string;

    subscribeSessionBtn: string = "";
    unsubscribeSessionBtn: string = "";
    disableSubscribe: boolean = false;
    disableUnsubscribe: boolean = false;

    settingsUpdated: boolean = false;

    timezones: Array<string> = [];
    timezone: string;

    constructor(
        private route: ActivatedRoute,
        private dbSchedule: DbScheduleProvider,
        private dbLocation: DbLocationsProvider,
        private dbEvent: DbEventsProvider,
        private luxon: LuxonService,
        public translateService: TranslateService,
        private regex: RegexProvider,
        private SWhereby: WherebyService,
        private dbGroups: DbGroupsProvider,
        private global: GlobalService,
        private dt: DateTimeService
    ) {
        this.term = ""; //filtro
        this.limitForPage = 10;
    }

    ngOnInit() {
        //list of all available timezones
        timezones.default.forEach(tz => {this.timezones.push(tz.text)});

        this.loader = true;
        this.sessions = [];
        this.sessionsChecked = [];
        this.getSessions();
        this.getUserLanguage();
        this.getEvent();
        this.getLocations();
        this.getTracks();
        this.getScheduleModule();
    }

    ngDoCheck() {
        // verifica se houve mudança no parâmetro do idModule
        if (this.moduleId !== this.route.params["_value"]["moduleId"]) {
            this.moduleId = this.route.params["_value"]["moduleId"];
            this.nameFile = ".xls, .xlsx, .ods, .fods, .ots";
            this.sessions = [];
            this.sessions = [];
            this.loader = true;

            this.getUserLanguage();
            this.getEvent();
            this.getSessions();
            this.getScheduleModule();
            this.getLocations();
            this.getTracks();
        }
    }

    getScheduleModule() {
        // get schedule module
        this.dbSchedule.getScheduleModule(
            this.moduleId,
            (module: ModuleSchedule) => { this.module = module; }
        );
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
            this.userLanguageFormated = this.convertLangFormat(
                this.userLanguage
            );
        });
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.eventLanguage = event.language;
            this.allowVisioSetting = event.allow_visio;
            this.languages = event.languages;
            this.principalEventLangFormated = this.convertLangFormat(
                event.language
            );
            this.activeTrackLanguage = this.principalEventLangFormated;
        });
    }

    /**
     * Update orators field
     */
    async oratorsFieldChanged() {
        this.disableOratorsField = true;
        await this.dbSchedule.updateOratorsFieldSchedule(
            this.eventId,
            this.moduleId,
            this.oratorsField
        );
        this.disableOratorsField = false;
    }

    // get sessions
    getSessions() {
        this.dbSchedule.getSessionsModule(
            this.moduleId,
            (sessions: Array<Session>) => {
                this.loader = false;
                this.oldSessions = _.cloneDeep(sessions);
                this.sessions = [];
                this.allListSessions = [];

                // treatment of dates and times
                for (const session of sessions) {
                    session.date = this.dt.getDate(
                        this.dt.convertTimeZone(session.date, this.dt.deviceTimeZone, "en-US")
                    );

                    session.startTime = this.dt.getTime(
                        this.dt.convertTimeZone(session.startTime, this.dt.deviceTimeZone, "en-US")
                    );

                    if (
                        session.endTime !== "" &&
                        session.endTime !== null &&
                        typeof session.endTime !== "undefined"
                    ) {
                        session.endTime = this.dt.getTime(
                            this.dt.convertTimeZone(session.endTime, this.dt.deviceTimeZone, "en-US")
                        );
                    }

                    this.sessions.push(this.extractSessionValues(session));
                    this.allListSessions.push(this.extractSessionValues(session));
                }

                if (this.loader) {
                    this.loader = false;
                }
                if (this.loaderImport) {
                    this.loaderImport = false;
                }
            }
        );
    }

    /**
     * @description extract the values from some of the field of a session, treat them and then reassign those values to session
     * @param session 
     */
    extractSessionValues(session: Session): Session {
        // locations
        const locations = [];

        for (const uid in session.locations) {
            locations.push(session.locations[uid]);
        }

        // sort by the order field.
        locations.sort(function (a, b) {
            return a.order - b.order;
        });

        session.locations = locations;

        // tracks
        const tracks = [];
        for (const trackId in session.tracks) {
            tracks.push(session.tracks[trackId]);
        }

        tracks.sort((a, b) =>
            a.identifier < b.identifier
                ? -1
                : a.identifier > b.identifier
                    ? 1
                    : 0
        );

        session.tracks = tracks;

        const groups = [];
        for (const groupId in session.groups) {
            groups.push(session.groups[groupId]);
        }
        session.groups = groups;

        // speakers
        const speakers = [];
        for (const speakerId in session.speakers) {
            speakers.push(session.speakers[speakerId]);
        }
        session.speakers = speakers;

        // documents
        const documents = [];
        for (const documentId in session.documents) {
            documents.push(session.documents[documentId]);
        }
        session.documents = documents;

        return session;
    }

    getLocations() {
        this.dbLocation.getLocationsEvent(this.eventId, "asc")
            .then((locations) => { this.locations = locations; });
    }

    setSessionRemove(session) {
        this.auxDeleteSession = session;
    }

    removeSession() {
        this.loader = true;
        this.sessions = [];

        // if the session is in the selected array for removal, it removes it from the list.
        const pos = this.sessionsChecked
            .map(function (e) {
                return e.uid;
            })
            .indexOf(this.auxDeleteSession.uid);

        if (pos > -1) {
            this.sessionsChecked.splice(pos, 1);
        }

        this.dbSchedule.deleteSession(this.auxDeleteSession, (status) => {
            this.auxDeleteSession = null;
            this.getSessions();
        });
    }

    // ******************************************************************************** excel operations ***********************************************************************************
    // * GET EXCEL AND CONVERT TO JSON DATA */
    // load excel file
    sheetValidFormat: boolean = false;
    blankSheet: boolean = true;
    onFileChange(evt: any) {
        this.messageErrors = [];
        this.dataImportPrincipal = [];
        this.dataImportSessions_DeDE = [];
        this.dataImportSessions_EnUS = [];
        this.dataImportSessions_EsES = [];
        this.dataImportSessions_FrFR = [];
        this.dataImportSessions_PtBR = [];
        this.sessionTimeImport = [];
        this.sheetValidFormat = false;
        this.blankSheet = false;

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error("Cannot use multiple files");
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {
                type: "binary",
                dateNF: "dd/mm/yyyy hh:mm:ss",
                cellDates: true,
            });
            /*get data from worksheet*/
            for (const wsname of wb.SheetNames) {
                if (wsname.includes("Principal")) {
                    this.sheetValidFormat = true;
                }
                switch (wsname) {
                    case this.eventLanguage !== "pt_BR" &&
                        this.languages.PtBR &&
                        ("pt-BR" || "pt_BR"):
                        const ws_pt_BR: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.dataImportSessions_PtBR = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws_pt_BR, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        ); /* save data */
                        break;

                    case this.eventLanguage !== "en_US" &&
                        this.languages.EnUS &&
                        ("en-US" || "en_US"):
                        const ws_en_US: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.dataImportSessions_EnUS = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws_en_US, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        ); /* save data */
                        break;

                    case this.eventLanguage !== "es_ES" &&
                        this.languages.EsES &&
                        ("es-ES" || "es_ES"):
                        const ws_es_ES: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.dataImportSessions_EsES = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws_es_ES, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        ); /* save data */
                        break;

                    case this.eventLanguage !== "fr_FR" &&
                        this.languages.FrFR &&
                        ("fr-FR" || "fr_FR"):
                        const ws_fr_FR: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.dataImportSessions_FrFR = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws_fr_FR, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        ); /* save data */
                        break;

                    case this.eventLanguage !== "de_DE" &&
                        this.languages.DeDE &&
                        ("de-DE" || "de_DE"):
                        const ws_de_DE: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.dataImportSessions_DeDE = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws_de_DE, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        ); /* save data */
                        break;

                    case "Principal" || "principal":
                        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                        this.sessionTimeImport = ws; //saves the cells that will be used to pick up the dates and times to import.
                        this.dataImportPrincipal = <AOA>(
                            XLSX.utils
                                .sheet_to_json(ws, { header: 1 })
                                .filter(
                                    (o) => !Object.keys(o).every((k) => !o[k])
                                )
                        );
                        break;
                }
            }
        };
        reader.readAsBinaryString(target.files[0]);
    }

    // check import file
    invalidFormatSheetMsg: string = null;
    checkImport() {
        if (!this.event) {
            this.theError = true;
            return;
        }

        this.invalidFormatSheetMsg = null;
        this.loaderImport = true;
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.successMsg = false;

        this.sessionsChecked = [];
        this.messageErrors = [];
        this.sessionsErrorsApi = [];

        // Arrange the array de-DE
        for (let i = 0; i < this.dataImportSessions_DeDE.length; i++) {
            const row = this.dataImportSessions_DeDE[i];

            for (let j = 0; j < 3; j++) {
                if (typeof row[j] === "undefined") {
                    row[j] = "";
                }
            }
        }

        // Arrange the array en-US
        for (let i = 0; i < this.dataImportSessions_EnUS.length; i++) {
            const row = this.dataImportSessions_EnUS[i];

            for (let j = 0; j < 3; j++) {
                if (typeof row[j] === "undefined") {
                    row[j] = "";
                }
            }
        }

        // Arrange the array es-ES
        for (let i = 0; i < this.dataImportSessions_EsES.length; i++) {
            const row = this.dataImportSessions_EsES[i];

            for (let j = 0; j < 3; j++) {
                if (typeof row[j] === "undefined") {
                    row[j] = "";
                }
            }
        }

        // Arrange the array fr-FR
        for (let i = 0; i < this.dataImportSessions_FrFR.length; i++) {
            const row = this.dataImportSessions_FrFR[i];

            for (let j = 0; j < 3; j++) {
                if (typeof row[j] === "undefined") {
                    row[j] = "";
                }
            }
        }

        // Arrange the array pt-BR
        for (let i = 0; i < this.dataImportSessions_PtBR.length; i++) {
            const row = this.dataImportSessions_PtBR[i];

            for (let j = 0; j < 3; j++) {
                if (typeof row[j] === "undefined") {
                    row[j] = "";
                }
            }
        }

        // sort header
        if (this.dataImportPrincipal.length > 0) {
            const header = this.dataImportPrincipal[0];

            for (let i = 0; i < 11; i++) {
                if (typeof header[i] === "undefined") {
                    header[i] = "";
                }
            }
        }

        // Arrange the array and dates
        if (this.dataImportPrincipal.length > 0) {
            for (let i = 1; i < this.dataImportPrincipal.length; i++) {
                const row = this.dataImportPrincipal[i];

                // date
                const dateCell = this.sessionTimeImport[`C${i + 1}`];
                if (!dateCell) {
                    this.importCellError(`C${i + 1}`);
                    return;
                }

                // format 
                delete dateCell.w   // remove old formatted text
                dateCell.z = "dd/mm/yyyy";  // set cell format
                XLSX.utils.format_cell(dateCell);   // this refreshes the formatted text.
                
                const auxDate = dateCell.w.split("/");  // [dd, mm, yyyy]
                
                // startTime
                const sTimeCell = this.sessionTimeImport[`D${i + 1}`];
                if (!sTimeCell) {
                    this.importCellError(`D${i + 1}`);
                    return;
                }
                const auxSTime = sTimeCell.w.split(":");    // hh:mm[:ss]

                // endTime
                const eTimeCell = this.sessionTimeImport[`E${i + 1}`];
                if (eTimeCell) {
                    const auxETime = eTimeCell.w.split(":");    // hh:mm[:ss]
                    row[this.endTime] = this.dt.createDateInTZ(
                        auxDate[2], 
                        auxDate[1], 
                        auxDate[0], 
                        auxETime[0], 
                        auxETime[1], 
                        this.event.timezone
                    ).toISO();
                }

                row[this.startDate] = this.dt.createDateInTZ(
                    auxDate[2], 
                    auxDate[1], 
                    auxDate[0], 
                    auxSTime[0], 
                    auxSTime[1], 
                    this.event.timezone
                ).toISO();
                row[this.startTime] = this.dt.createDateInTZ(
                    auxDate[2], 
                    auxDate[1], 
                    auxDate[0], 
                    auxSTime[0], 
                    auxSTime[1], 
                    this.event.timezone
                ).toISO();

                // console.log(`sdate: ${row[this.startDate]}`)
                // console.log(`stime: ${row[this.startTime]}`)
                // console.log(`etime: ${row[this.endTime]}`)

                // for (let j = 0; j < 12; j++) {
                //     // tidy up undefined cells
                //     if (typeof row[j] === "undefined") {
                //         row[j] = "";
                //     }

                //     // get startDate
                //     if (j === this.startDate) {
                //         if (
                //             typeof this.sessionTimeImport[`C${i + 1}`] !==
                //             "undefined" &&
                //             this.sessionTimeImport[`C${i + 1}`] !== null
                //         ) {
                //             const cell = this.sessionTimeImport[`C${i + 1}`];
                //             console.log(`cell: ${cell.w}`)
                //             delete cell.w; // remove old formatted text
                //             cell.z = "dd/mm/yyyy"; // set cell format
                //             XLSX.utils.format_cell(cell); // this refreshes the formatted text.

                //             row[this.startDate] = cell.w;
                //             console.log(`row[this.startDate]: ${row[this.startDate]}`)
                            
                //             let aux = row[this.startDate].split("/");
                //             let aux1 = this.dt.createDateInTZ(aux[2], aux[1], aux[0], 0, 0, this.event.timezone)
                //             console.log(`auxrow: ${aux}`);
                //             console.log(`aux1: ${aux1}`);

                //         }
                //     }

                //     // get startTime
                //     if (j === this.startTime) {
                //         if (
                //             typeof this.sessionTimeImport[`D${i + 1}`] !==
                //             "undefined" &&
                //             this.sessionTimeImport[`D${i + 1}`] !== null
                //         ) {
                //             const cell = this.sessionTimeImport[`D${i + 1}`];

                //             delete cell.w; // remove old formatted text
                //             cell.z = "h:mm:ss"; // set cell format
                //             XLSX.utils.format_cell(cell); // this refreshes the formatted text.

                //             row[this.startTime] = cell.w;
                //             console.log(`row[this.startTime]: ${row[this.startTime]}`)
                //         }
                //     }

                //     // get endTime
                //     if (j === this.endTime) {
                //         if (
                //             typeof this.sessionTimeImport[`E${i + 1}`] !==
                //             "undefined" &&
                //             this.sessionTimeImport[`E${i + 1}`] !== null
                //         ) {
                //             const cell = this.sessionTimeImport[`E${i + 1}`];

                //             delete cell.w; // remove old formatted text
                //             cell.z = "h:mm:ss"; // set cell format
                //             XLSX.utils.format_cell(cell); // this refreshes the formatted text.
                //             row[this.endTime] = cell.w;
                //             console.log(`row[this.endTime]: ${row[this.endTime]}`)
                //         }
                //     }
                // }
            }
        }

        if (!this.blankSheet) {
            if (this.dataImportPrincipal.length > 1) {
                if (this.sheetValidFormat) {

                    // console.log(this.dataImportPrincipal)

                    this.dbSchedule.importSessions(
                        this.eventId,
                        this.moduleId,
                        this.dataImportPrincipal,
                        this.dataImportSessions_DeDE,
                        this.dataImportSessions_EnUS,
                        this.dataImportSessions_EsES,
                        this.dataImportSessions_FrFR,
                        this.dataImportSessions_PtBR,
                        this.event.timezone,
                        (status) => {
                            // console.log(`status: ${JSON.stringify(status)}`)
                            if (status["result"]["messageErrors"].length > 0) {
                                this.messageErrors = [];
                                // to handle the error message for front view
                                for (const element of status["result"][
                                    "messageErrors"
                                ]) {
                                    this.getTranslate1(element[1]);
                                    this.messageErrors.push(
                                        `${element[0]} ${this.valueTranslation1}`
                                    );
                                }

                                this.loaderImport = false;
                                this.theError = true;
                            } else if (
                                status["result"]["messageErrors"].length <= 0 &&
                                status["result"]["generalErrors"].length <= 0 &&
                                status["result"]["idDouble"].length <= 0
                            ) {
                                this.successMsg = true;
                                this.getSessions();
                            } else if (
                                status["result"]["idDouble"].length > 0 &&
                                status["result"]["generalErrors"].length <= 0
                            ) {
                                this.theErrorApi = true;

                                this.sessionsErrorsApi =
                                    status["result"]["idDouble"];

                                for (let session of status["result"][
                                    "idDouble"
                                ]) {
                                    this.messageErrors.push(
                                        session["identifier"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.identifier_double"
                                        )
                                    );
                                }

                                this.getSessions();
                            } else if (
                                status["result"]["idDouble"].length > 0 &&
                                status["result"]["generalErrors"].length > 0
                            ) {
                                this.theErrorApi = true;

                                // get errors
                                for (const session of status["result"][
                                    "idDouble"
                                ]) {
                                    this.sessionsErrorsApi.push(session);
                                    this.messageErrors.push(
                                        session["identifier"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.identifier_double"
                                        )
                                    );
                                }

                                for (const session of status["result"][
                                    "generalErrors"
                                ]) {
                                    this.sessionsErrorsApi.push(session);
                                    this.messageErrors.push(
                                        session["identifier"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.general_errors_import_session"
                                        )
                                    );
                                }

                                this.getSessions();
                            } else if (
                                status["result"]["idDouble"].length <= 0 &&
                                status["result"]["generalErrors"].length > 0
                            ) {
                                this.theErrorApi = true;

                                this.sessionsErrorsApi =
                                    status["result"]["generalErrors"];

                                for (const session of status["result"][
                                    "generalErrors"
                                ]) {
                                    this.messageErrors.push(
                                        session["identifier"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.general_errors_import_session"
                                        )
                                    );
                                }

                                this.getSessions();
                            }
                        }
                    );
                } else {
                    this.loaderImport = false;
                    this.messageErrors = [];
                    this.invalidFormatSheetMsg = this.translateService.instant(
                        "global.invalid_spreadsheet"
                    );
                }
            } else {
                this.loaderImport = false;
                this.messageErrors = [];
                this.invalidFormatSheetMsg = this.translateService.instant(
                    "global.invalid_spreadsheet"
                );
                // this.getSessions()
            }
        } else {
            this.loaderImport = false;
            this.theError = true;
            this.messageErrors.push(
                this.translateService.instant("global.no_file_selected")
            );
        }

        this.clearInputFile(); // limpa o input do arquivo.
    }

    importCellError(cell: string) {
        this.loaderImport = false;
        this.theError = true;
        this.messageErrors = [
            `${cell} ${this.translateService.instant("global.has_invalid_value")}`
        ];
        this.clearInputFile(); // limpa o input do arquivo.
    }

    // export template excel
    exportTemplate() {
        // template for export excel
        const dataExportPrincipal = [
            [
                "identifier*",
                "name*",
                "Date* dd/mm/yyyy",
                "StartTime* hh:min",
                "EndTime hh:min",
                "description",
                "Ids Locations",
                "Ids Tracks",
                "Ids Groups",
                "Ids Speakers",
                "Ids Documents",
                "limit of participants",
            ],
            [
                "1",
                "Sales Workshop",
                "dd/mm/yyyy",
                "24h:min",
                "24h:min",
                "Learn the sales techniques with experienced from the market. Aguce your vision, ability and delight your customers",
                "Sala A;Sala B",
                "Track A;Track B",
                "Group A;Group B",
                "João Paulo;João Silva",
                "doc.jpg;doc.pdf",
                "10",
            ],
        ];

        const dataExport_DeDE = [
            ["Identifier*", "Name", "Description"],
            [
                "1",
                "Verkaufswerkstatt",
                "Erlernen Sie die Verkaufstechniken mit erfahrenen vom Markt. Aguce deine Vision, Fähigkeit und erfreuen Sie Ihre Kunden",
            ],
        ];

        const dataExport_EnUS = [
            ["Identifier*", "Name", "Description"],
            [
                "1",
                "Sales Workshop",
                "Learn the sales techniques with experienced from the market. Aguce your vision, ability and delight your customers",
            ],
        ];

        const dataExport_EsES = [
            ["Identifier*", "Name", "Description"],
            [
                "1",
                "Taller de ventas",
                "Aprenda las técnicas de ventas con los expertos del mercado. Agrupa su visión, habilidad y encante a sus clientes",
            ],
        ];

        const dataExport_FrFR = [
            ["Identifier*", "Name", "Description"],
            [
                "1",
                "Atelier de vente",
                "Apprenez les techniques de vente avec l expérience du marché. Aguce votre vision, capacité et ravir vos clients",
            ],
        ];

        const dataExport_PtBR = [
            ["Identifier*", "Name", "Description"],
            [
                "1",
                "Workshop de Vendas",
                "Aprenda as técnicas de vendas com experientes do mercado. Aguce sua visão, habilidade e encante seus clientes",
            ],
        ];

        const configCols = [];

        // sets the number and width of the columns
        for (let i = 0; i < 1000; i++) {
            configCols.push({ wpx: 150 }); // "pixels"
        }
        configCols.push({ hidden: false }); // hide column

        const wscols: XLSX.ColInfo[] = configCols;

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExportPrincipal);

        /* TEST: column props */
        ws["!cols"] = wscols;

        /* TEST: row props */
        ws["!rows"] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Principal");

        // For each enabled language other than the main language, generates a worksheet
        if (this.eventLanguage !== "pt_BR" && this.languages.PtBR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExport_PtBR);
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
        }

        if (this.eventLanguage !== "en_US" && this.languages.EnUS) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExport_EnUS);
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "en-US");
        }

        if (this.eventLanguage !== "es_ES" && this.languages.EsES) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExport_EsES);
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "es-ES");
        }

        if (this.eventLanguage !== "fr_FR" && this.languages.FrFR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExport_FrFR);
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
        }

        if (this.eventLanguage !== "de_DE" && this.languages.DeDE) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataExport_DeDE);
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "de-DE");
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "template_sessions.xlsx");
    }

    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    // exports the sessions that have failed to import
    async downloadSessionsImportError() {
        // initializes export arrays
        this.dataExportSessions = [];
        this.dataExportSessions_DeDE = [];
        this.dataExportSessions_EnUS = [];
        this.dataExportSessions_EsES = [];
        this.dataExportSessions_FrFR = [];
        this.dataExportSessions_PtBR = [];

        this.dataExportSessions = [
            [
                "identifier*",
                "name*",
                "Date* dd/mm/yyyy",
                "StartTime*hh:min",
                "EndTime hh:min",
                "description",
                "Ids Locations",
                "Ids Tracks",
                "Ids Groups",
                "Ids Speakers",
                "Ids Documents",
                "limit of participants",
            ],
        ];

        this.dataExportSessions_DeDE = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_EnUS = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_EsES = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_FrFR = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_PtBR = [["Identifier*", "Name", "Description"]];

        // order session by identifier
        const auxSessionsExport = [];

        for (const session of this.sessionsErrorsApi) {
            auxSessionsExport.push(session);
        }

        auxSessionsExport.sort(function (a, b) {
            return a.identifier < b.identifier
                ? -1
                : a.identifier > b.identifier
                    ? 1
                    : 0;
        });

        // add session excel
        for (const session of this.sessionsErrorsApi) {
            this.dataExportSessions.push(
                this.preparesSessionDownloadSessionsImportError(session)
            );

            if (this.eventLanguage !== "de_DE" && this.languages.DeDE) {
                this.dataExportSessions_DeDE.push(
                    this.prepareSessionExportLanguage("de_DE", session)
                );
            }

            if (this.eventLanguage !== "en_US" && this.languages.EnUS) {
                this.dataExportSessions_EnUS.push(
                    this.prepareSessionExportLanguage("en_US", session)
                );
            }

            if (this.eventLanguage !== "es_ES" && this.languages.EsES) {
                this.dataExportSessions_EsES.push(
                    this.prepareSessionExportLanguage("es_ES", session)
                );
            }

            if (this.eventLanguage !== "fr_FR" && this.languages.FrFR) {
                this.dataExportSessions_FrFR.push(
                    this.prepareSessionExportLanguage("fr_FR", session)
                );
            }

            if (this.eventLanguage !== "pt_BR" && this.languages.PtBR) {
                this.dataExportSessions_PtBR.push(
                    this.prepareSessionExportLanguage("pt_BR", session)
                );
            }
        }

        // sets the number and width of the columns
        const wscols: XLSX.ColInfo[] = [];

        for (let i = 0; i < 1000; i++) {
            wscols.push({ wpx: 150 });
        }
        wscols.push({ hidden: false });

        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportSessions
        );

        /* TEST: column props */
        ws["!cols"] = wscols;

        /* TEST: row props */
        ws["!rows"] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Principal");

        // For each enabled language other than the main language, generates a worksheet
        if (this.eventLanguage !== "pt_BR" && this.languages.PtBR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_PtBR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
        }

        if (this.eventLanguage !== "en_US" && this.languages.EnUS) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_EnUS
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "en-US");
        }

        if (this.eventLanguage !== "es_ES" && this.languages.EsES) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_EsES
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "es-ES");
        }

        if (this.eventLanguage !== "fr_FR" && this.languages.FrFR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_FrFR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
        }

        if (this.eventLanguage !== "de_DE" && this.languages.DeDE) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_DeDE
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "de-DE");
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "error-import.xlsx");
    }

    // prepare the session for export for  function exportAllSessions
    prepareSessionExport(session: Session) {
        const date = this.dt.getDate(
            this.dt.convertTimeZone(session.date, "America/Sao_Paulo", "en-US"),
            "pt-BR"     // pt-BR is the standard format for the exported spreadsheets (i.e. dd/mm/yyyy)
        );
        const stime = this.dt.getTime(
            this.dt.convertTimeZone(session.startTime, "America/Sao_Paulo", "en-US"),
            true
        );
        const etime = this.dt.getTime(
            this.dt.convertTimeZone(session.endTime, "America/Sao_Paulo", "en-US"),
            true
        );
        
        session = this.extractSessionValues(session);

        const row = [];
        row.push(session.identifier);
        row.push(this.prepareSessionLanguageEventExport(session.name));
        row.push(date);
        row.push(stime);
        row.push(etime);
        row.push(this.prepareSessionLanguageEventExport(session.descriptions));
        row.push(this.prepareListExport(session.locations));
        row.push(this.prepareListExport(session.tracks));
        row.push(this.prepareListExport(session.groups));
        row.push(this.prepareListExport(session.speakers));
        row.push(this.prepareListExport(session.documents));
        row.push(session.limitAttendees);

        if (this.event.allow_visio) {
            row.push(
                session.visio && session.visio.roomUrl
                    ? session.visio.roomUrl
                    : "none"
            );
            row.push(
                session.visio && session.visio.hostRoomUrl
                    ? session.visio.hostRoomUrl
                    : "none"
            );
        }

        return row;
    }

    // returns the data according to the main language of the event. Used to return the session name and description.
    prepareSessionLanguageEventExport(data: any) {
        switch (this.eventLanguage) {
            case "de_DE":
                return data.DeDE;
            case "en_US":
                return data.EnUS;
            case "es_ES":
                return data.EsES;
            case "fr_FR":
                return data.FrFR;
            default:
                return data.PtBR;
        }
    }

    // prepare session for multi-language export
    prepareSessionExportLanguage(language: string, session: Session) {
        const row = [];

        switch (language) {
            case "pt_BR":
                row.push(session.identifier);
                row.push(session.name.PtBR);

                if (
                    typeof session.descriptions.PtBR !== "undefined" &&
                    session.descriptions.PtBR !== null
                ) {
                    row.push(session.descriptions.PtBR);
                } else {
                    row.push("");
                }

                break;

            case "en_US":
                row.push(session.identifier);
                row.push(session.name.EnUS);

                if (
                    typeof session.descriptions.EnUS !== "undefined" &&
                    session.descriptions.EnUS !== null
                ) {
                    row.push(session.descriptions.EnUS);
                } else {
                    row.push("");
                }

                break;

            case "es_ES":
                row.push(session.identifier);
                row.push(session.name.EsES);

                if (
                    typeof session.descriptions.EsES !== "undefined" &&
                    session.descriptions.EsES !== null
                ) {
                    row.push(session.descriptions.EsES);
                } else {
                    row.push("");
                }

                break;

            case "fr_FR":
                row.push(session.identifier);
                row.push(session.name.FrFR);

                if (
                    typeof session.descriptions.FrFR !== "undefined" &&
                    session.descriptions.FrFR !== null
                ) {
                    row.push(session.descriptions.FrFR);
                } else {
                    row.push("");
                }

                break;

            case "de_DE":
                row.push(session.identifier);
                row.push(session.name.DeDE);

                if (
                    typeof session.descriptions.DeDE !== "undefined" &&
                    session.descriptions.DeDE !== null
                ) {
                    row.push(session.descriptions.DeDE);
                } else {
                    row.push("");
                }

                break;
        }

        return row;
    }

    // prepare data list for export.
    prepareListExport(list: any) {
        let myDatas = "";
        let i = 1;
        for (const data of list) {
            if (i < list.length) {
                myDatas += data.identifier + ";";
            } else {
                myDatas += data.identifier;
            }
            i++;
        }
        return myDatas;
    }

    // prepares the session for export for  function DownloadSessionsImportError
    preparesSessionDownloadSessionsImportError(session: Session) {
        const row = [];

        row.push(session.identifier);

        // add the name in the main language of the session.
        if (this.eventLanguage === "de_DE") {
            row.push(session.name.DeDE);
        } else if (this.eventLanguage === "en_US") {
            row.push(session.name.EnUS);
        } else if (this.eventLanguage === "es_ES") {
            row.push(session.name.EsES);
        } else if (this.eventLanguage === "fr_FR") {
            row.push(session.name.FrFR);
        } else {
            row.push(session.name.PtBR);
        }

        // date
        const auxDate = this.luxon.convertTimestampToDate(session.date);
        const year = auxDate.year;

        let day = null;

        if (auxDate.day < 10) {
            day = `0${auxDate.day}`;
        } else {
            day = auxDate.day.toString();
        }

        let month = null;

        if (auxDate.month < 10) {
            month = `0${auxDate.month}`;
        } else {
            month = auxDate.month.toString();
        }

        const date = `${day}/${month}/${year}`;
        row.push(date);

        // start time
        const auxStartTime = this.luxon.convertTimestampToDate(
            session.startTime
        );

        let shour = null;
        let sminute = null;

        if (auxStartTime.hour < 10) {
            shour = `0${auxStartTime.hour}`;
        } else {
            shour = auxStartTime.hour.toString();
        }

        if (auxStartTime.minute < 10) {
            sminute = `0${auxStartTime.minute}`;
        } else {
            sminute = auxStartTime.minute.toString();
        }

        row.push(`${shour}:${sminute}`);

        // end time
        if (
            session.endTime !== "" &&
            session.endTime !== null &&
            typeof session.endTime !== "undefined"
        ) {
            const auxEndTime = this.luxon.convertTimestampToDate(
                session.endTime
            );

            let ehour = null;
            let eminute = null;

            if (auxEndTime.hour < 10) {
                ehour = `0${auxEndTime.hour}`;
            } else {
                ehour = auxEndTime.hour.toString();
            }

            if (auxEndTime.minute < 10) {
                eminute = `0${auxEndTime.minute}`;
            } else {
                eminute = auxEndTime.minute.toString();
            }

            row.push(`${ehour}:${eminute}`);
        } else {
            row.push("");
        }

        // add the description in the main language of the session.
        if (this.eventLanguage === "de_DE") {
            row.push(session.descriptions.DeDE);
        } else if (this.eventLanguage === "en_US") {
            row.push(session.descriptions.EnUS);
        } else if (this.eventLanguage === "es_ES") {
            row.push(session.descriptions.EsES);
        } else if (this.eventLanguage === "fr_FR") {
            row.push(session.descriptions.FrFR);
        } else {
            row.push(session.descriptions.PtBR);
        }

        // locations
        // locations
        let totalLocations = 0;
        let cont = 1;
        let locations = "";

        for (let i in session.locations) {
            totalLocations++;
        }

        for (const index in session.locations) {
            const location = session.locations[index];

            if (cont < totalLocations) {
                locations += location.identifier + ";";
            } else {
                locations += location.identifier;
            }

            cont++;
        }

        row.push(locations);

        //  tracks
        let tracks = "";
        let contTracks = 1;
        let totalTracks = 0;

        for (let i in session.tracks) {
            totalTracks++;
        }

        for (const index in session.tracks) {
            const track = session.tracks[index];

            if (contTracks < totalTracks) {
                tracks += track.identifier + ";";
            } else {
                tracks += track.identifier;
            }

            contTracks++;
        }

        row.push(tracks);

        //  groups
        let groups = "";
        let contGroups = 1;
        let totalGroups = 0;

        for (let i in session.groups) {
            totalGroups++;
        }

        for (const index in session.groups) {
            const group = session.groups[index];

            if (contGroups < totalGroups) {
                groups += group.identifier + ";";
            } else {
                groups += group.identifier;
            }

            contGroups++;
        }

        row.push(groups);

        // speakers
        let speakers = "";
        let k = 1;
        let totalSpeakers = 0;
        for (let i in session.speakers) {
            totalSpeakers++;
        }

        for (const index in session.speakers) {
            const speaker = session.speakers[index];

            if (k < totalSpeakers) {
                speakers += speaker.identifier + ";";
            } else {
                speakers += speaker.identifier;
            }

            k++;
        }

        row.push(speakers);

        // documents
        let documents = "";
        let l = 1;
        let totalDocuments = 0;
        for (let i in session.documents) {
            totalDocuments++;
        }

        for (const index in session.documents) {
            const document = session.documents[index];

            if (k < totalDocuments) {
                documents += document.identifier + ";";
            } else {
                documents += document.identifier;
            }

            l++;
        }

        row.push(documents);

        // limitAttendees
        row.push(session.limitAttendees);

        return row;
    }

    // export sessions
    exportAllSessions() {
        // initializes export arrays
        this.dataExportSessions = [];
        this.dataExportSessions_DeDE = [];
        this.dataExportSessions_EnUS = [];
        this.dataExportSessions_EsES = [];
        this.dataExportSessions_FrFR = [];
        this.dataExportSessions_PtBR = [];

        this.dataExportSessions = [
            [
                "identifier*",
                "name*",
                "Date* dd/mm/yyyy",
                "StartTime*hh:min",
                "EndTime hh:min",
                "description",
                "Ids Locations",
                "Ids Tracks",
                "Ids Groups",
                "ids Speakers",
                "ids Documents",
                "limit of participants",
            ],
        ];

        if (this.event.allow_visio) {
            this.dataExportSessions[0].push("Visio-conference url");
            this.dataExportSessions[0].push("Visio-conference host url");
        }

        this.dataExportSessions_DeDE = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_EnUS = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_EsES = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_FrFR = [["Identifier*", "Name", "Description"]];

        this.dataExportSessions_PtBR = [["Identifier*", "Name", "Description"]];

        // // order session by identifier
        // const auxSessionsExport = [];

        // for (const session of this.rawSessions) {
        //     auxSessionsExport.push(session);
        // }

        this.oldSessions.sort(function (a, b) {
            return a.identifier < b.identifier
                ? -1
                : a.identifier > b.identifier
                    ? 1
                    : 0;
        });

        // get values of sessions
        for (const session of this.oldSessions) {
            this.dataExportSessions.push(this.prepareSessionExport(session));

            if (this.eventLanguage !== "de_DE" && this.languages.DeDE) {
                this.dataExportSessions_DeDE.push(
                    this.prepareSessionExportLanguage("de_DE", session)
                );
            }

            if (this.eventLanguage !== "en_US" && this.languages.EnUS) {
                this.dataExportSessions_EnUS.push(
                    this.prepareSessionExportLanguage("en_US", session)
                );
            }

            if (this.eventLanguage !== "es_ES" && this.languages.EsES) {
                this.dataExportSessions_EsES.push(
                    this.prepareSessionExportLanguage("es_ES", session)
                );
            }

            if (this.eventLanguage !== "fr_FR" && this.languages.FrFR) {
                this.dataExportSessions_FrFR.push(
                    this.prepareSessionExportLanguage("fr_FR", session)
                );
            }

            if (this.eventLanguage !== "pt_BR" && this.languages.PtBR) {
                this.dataExportSessions_PtBR.push(
                    this.prepareSessionExportLanguage("pt_BR", session)
                );
            }
        }

        // sets the number and width of the columns
        const wscols: XLSX.ColInfo[] = [];

        for (let i = 0; i < 1000; i++) {
            wscols.push({ wpx: 150 });
        }
        wscols.push({ hidden: false });

        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportSessions
        );

        /* TEST: column props */
        ws["!cols"] = wscols;

        /* TEST: row props */
        ws["!rows"] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Principal");

        // For each enabled language other than the main language, generates a worksheet
        if (this.eventLanguage !== "pt_BR" && this.languages.PtBR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_PtBR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
        }

        if (this.eventLanguage !== "en_US" && this.languages.EnUS) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_EnUS
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "en-US");
        }

        if (this.eventLanguage !== "es_ES" && this.languages.EsES) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_EsES
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "es-ES");
        }

        if (this.eventLanguage !== "fr_FR" && this.languages.FrFR) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_FrFR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
        }

        if (this.eventLanguage !== "de_DE" && this.languages.DeDE) {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportSessions_DeDE
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "de-DE");
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "schedule.xlsx");
    }

    // clears the file input after import
    clearInputFile() {
        this.inputFile.nativeElement.value = "";
        this.nameFile = null;
    }

    // Arrange data array for import, leaving all arrays with the same size
    // 1000 is a random number
    sortDataForImport() {
        for (const array of this.dataImportPrincipal) {
            for (let i = 0; i < 22; i++) {
                if (typeof array[i] === "undefined") {
                    array[i] = "";
                }
            }
        }
        return this.dataImportPrincipal;
    }

    /***************************************************************TRANSLATE PROVISÓRIO************************************************************ */
    getTranslate1(key: string) {
        this.translateService.get(key).subscribe((res: string) => {
            this.valueTranslation1 = res;
        });
    }

    getTranslate2(key: string) {
        this.translateService.get(key).subscribe((res: string) => {
            this.valueTranslation2 = res;
        });
    }

    getTranslate3(key: string) {
        this.translateService.get(key).subscribe((res: string) => {
            this.valueTranslation3 = res;
        });
    }

    clearMessages() {
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.messageErrors = [];
        this.successMsg = false;
        this.sheetValidFormat = false;
        this.sheetValidFormatTrack = false;
        this.invalidFormatSheetMsg = null;
        this.invalidFormatSheetTrackMsg = null;
    }

    /********************************************************************************* checked sessions   ****************************************************************************** */

    onDeleteAll() {
        if (this.sessionsChecked.length > 0) {
            this.loader = true;
            this.sessions = [];

            this.dbSchedule.deleteSessions(this.sessionsChecked, (status) => {
                this.getSessions();
                this.sessionsChecked = [];
            });
        }
    }

    // adds and / or removes session from array sessionsChecked
    updateCheckedSession(session, event) {
        if (event.target.checked) {
            this.sessionsChecked.push(session);
        } else {
            const index = this.sessionsChecked.indexOf(session);

            if (index > -1) {
                this.sessionsChecked.splice(index, 1);
            }
        }
    }

    // adds or removes all sessions in the checked sessions array
    updateAllCheckedSessions(event) {
        if (event.target.checked) {
            for (const session of this.sessions) {
                const index = this.sessionsChecked.indexOf(session);

                if (index <= -1) {
                    this.sessionsChecked.push(session);
                }
            }
        } else {
            for (const session of this.sessions) {
                const index = this.sessionsChecked.indexOf(session);

                if (index > -1) {
                    this.sessionsChecked.splice(index, 1);
                }
            }
        }
    }

    // create track
    trackIdentifierDouble: boolean = false;
    createTrack() {
        this.trackIdentifierDouble = false;
        this.newTrackLoader = true;
        // this.dbSchedule.getTracksIds(this.moduleId, (trackIds: Array<Number>) => {
        this.trackIdentifier = this.trackIdentifier.toString();
        let indexIdentifier = this.checkTrackIdentifierDouble(
            this.trackIdentifier
        );
        if (indexIdentifier == -1) {
            if (
                this.trackName[this.principalEventLangFormated] !== "" &&
                this.nTrackBgColor !== "" &&
                this.nTrackTxtColor !== ""
            ) {
                for (let lang in this.trackName) {
                    if (this.trackName[lang] == "") {
                        this.trackName[lang] =
                            this.trackName[this.principalEventLangFormated];
                    }
                }
                let track = new Track(
                    this.trackIdentifier,
                    this.trackName,
                    this.nTrackBgColor,
                    this.nTrackTxtColor
                );
                this.dbSchedule.createNewTrack(
                    this.eventId,
                    this.moduleId,
                    track
                ).then(() => {
                    // $('#tracksModal').modal('toggle');
                    this.clearTrackInputs();
                    this.successSwal.fire();
                    this.newTrackLoader = false;
                }).catch((error) => {
                    console.error(error);
                    $("#tracksModal").modal("toggle");
                    this.clearTrackInputs();
                    this.errorSwal.fire();
                    this.newTrackLoader = false;
                })
            } else {
                // display error message
                this.trackErrorMsg = true;
                this.newTrackLoader = false;
            }
        } else {
            this.trackIdentifierDouble = true;
            this.newTrackLoader = false;
        }
    }

    checkTrackIdentifierDouble(identifier) {
        return this.tracks
            .map(function (e) {
                return e["identifier"];
            })
            .indexOf(identifier);
    }

    newIdTrack(trackIds) {
        let cont = 1;
        for (let id of trackIds) {
            if (cont == id) {
                cont++;
            } else {
                break;
            }
        }

        return cont;
    }

    // restore default track inputs
    clearTrackInputs() {
        this.trackName = {
            PtBR: "",
            EnUS: "",
            EsES: "",
            FrFR: "",
            DeDE: "",
        };
        this.trackIdentifier = null;
        this.nTrackBgColor = "#4d4646";
        this.nTrackTxtColor = "#ffffff";
        this.editTrackView = false;
        this.trackNameLength = false;
        this.trackErrorMsg = false;
        this.clearTrackForm();
    }

    // load all module tracks
    getTracks() {
        $(document).on("click", " .dropdown-menu", function (e) {
            e.stopPropagation();
        });
        this.dbSchedule.getModuleTracks(
            this.moduleId,
            (tracks: Array<Track>) => {
                this.tracks = [];
                this.tracks = tracks;
            }
        );
    }

    // get track delete reference
    getTrackDelete(uid: string) {
        this.trackDeleteId = uid;
    }

    // confirm track delete
    deleteTrack() {
        this.tracks.splice(this.tracks[this.trackDeleteId], 1);
        this.dbSchedule.deleteTrack(
            this.eventId,
            this.moduleId,
            this.trackDeleteId,
            (status) => {
                setTimeout(() => {
                    this.getSessions();
                }, 1500);
            }
        );
    }

    // rename track button
    blankTrackName: boolean = false;
    renameTrackOk: boolean = false;
    renameTrackError: boolean = false;
    renameTrack() {
        this.blankTrackName = false;
        this.renameTrackOk = false;
        this.renameTrackError = false;
        if (this.newTrackName[this.principalEventLangFormated] !== "") {
            for (let lang in this.newTrackName) {
                if (this.newTrackName[lang] == "") {
                    this.newTrackName[lang] =
                        this.newTrackName[this.principalEventLangFormated];
                }
            }
            this.dbSchedule.renameTrack(
                this.eventId,
                this.moduleId,
                this.newTrackName,
                (status) => {
                    if (status == true) {
                        // save ok
                        this.renameTrackOk = true;
                        setTimeout(() => {
                            this.renameTrackOk = false;
                        }, 5000);
                    } else {
                        // error
                        this.renameTrackError = false;
                        setTimeout(() => {
                            this.renameTrackError = false;
                        }, 5000);
                    }
                }
            );
        } else {
            // nome não pode estar em branco no idioma principal
            this.blankTrackName = true;
        }
    }

    filterSessionTrack() {
        if (this.trackFilter == "all") {
            this.sessions = this.allListSessions;
        } else {
            this.sessions = [];
            for (let session of this.allListSessions) {
                for (let track of session.tracks) {
                    if (track.uid == this.trackFilter) {
                        this.sessions.push(session);
                    }
                }
            }
        }
    }

    getEditTrack(trackId, index) {
        this.editTrackId = trackId;
        this.editTrackIndex = index;
        this.editTrackView = true;

        this.trackName = this.tracks[index]["name"];
        this.nTrackBgColor = this.tracks[index]["bgColor"];
        this.nTrackTxtColor = this.tracks[index]["txtColor"];
        this.trackIdentifier = this.tracks[index]["identifier"].toString();
    }

    trackEditSuccess: boolean = false;
    editTrack() {
        this.newTrackLoader = true;
        this.trackErrorMsg = false;
        this.trackNameLength = false;
        this.trackEditSuccess = false;

        if (
            this.trackName[this.principalEventLangFormated] !== "" &&
            this.nTrackBgColor !== "" &&
            this.nTrackTxtColor !== ""
        ) {
            for (let lang in this.trackName) {
                if (this.trackName[lang] == "") {
                    this.trackName[lang] =
                        this.trackName[this.principalEventLangFormated];
                }
            }
            let track = new Track(
                this.trackIdentifier,
                this.trackName,
                this.nTrackBgColor,
                this.nTrackTxtColor
            );
            track.$uid = this.editTrackId;
            this.dbSchedule.editTrack(
                this.moduleId,
                this.eventId,
                track,
                (status) => {
                    if (status) {
                        this.newTrackLoader = false;
                        this.trackEditSuccess = true;
                        this.trackEditSuccess = false;

                        this.clearTrackInputs();
                        this.successSwal.fire();
                        this.getTracks();
                        this.getSessions();
                        this.loader = true;
                        // setTimeout(() => {
                        //   this.getSessions()
                        // }, 3000);
                    } else {
                        this.clearTrackInputs();
                        this.errorSwal.fire();
                    }
                }
            );
        } else {
            // display error message
            this.trackErrorMsg = true;
            this.newTrackLoader = false;
        }
    }

    ExportTracks() {
        this.dataExportTracks = [
            ["Identifier*", "Name*", "Text color*", "Background color*"],
        ];

        this.dataExportTracksPtBR = [["Identifier*", "Name*"]];

        this.dataExportTracksEnUS = [["Identifier*", "Name*"]];

        this.dataExportTracksEsES = [["Identifier*", "Name*"]];

        this.dataExportTracksFrFR = [["Identifier*", "Name*"]];

        this.dataExportTracksDeDE = [["Identifier*", "Name*"]];

        for (let track of this.tracks) {
            this.dataExportTracks.push(
                this.prepareTrackExport(track, this.principalEventLangFormated)
            );
            this.dataExportTracksPtBR.push(
                this.prepareTrackExport(track, "PtBR")
            );
            this.dataExportTracksEnUS.push(
                this.prepareTrackExport(track, "EnUS")
            );
            this.dataExportTracksEsES.push(
                this.prepareTrackExport(track, "EsES")
            );
            this.dataExportTracksFrFR.push(
                this.prepareTrackExport(track, "FrFR")
            );
            this.dataExportTracksDeDE.push(
                this.prepareTrackExport(track, "DeDE")
            );
        }
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        // sets the number and width of the columns
        const wscols: XLSX.ColInfo[] = [];

        for (let i = 0; i < 1000; i++) {
            wscols.push({ wpx: 150 });
        }
        wscols.push({ hidden: false });

        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportTracks
        );
        /* TEST: column props */
        ws["!cols"] = wscols;
        /* TEST: row props */
        ws["!rows"] = wsrows;
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            "Principal - " + this.eventLanguage
        );

        if (this.languages.PtBR && this.eventLanguage !== "pt_BR") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportTracksPtBR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "pt_BR");
        }

        if (this.languages.EnUS && this.eventLanguage !== "en_US") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportTracksEnUS
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "en_US");
        }

        if (this.languages.EsES && this.eventLanguage !== "es_ES") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportTracksEsES
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "es_ES");
        }

        if (this.languages.FrFR && this.eventLanguage !== "fr_FR") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportTracksFrFR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "fr_FR");
        }

        if (this.languages.DeDE && this.eventLanguage !== "de_DE") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportTracksDeDE
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "de_DE");
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "tracks.xlsx");

        this.dataExportTracks = [];
        this.dataExportTracksPtBR = [];
        this.dataExportTracksEnUS = [];
        this.dataExportTracksEsES = [];
        this.dataExportTracksFrFR = [];
        this.dataExportTracksDeDE = [];
    }

    prepareTrackExport(track, lang) {
        let array: Array<any> = [];
        if (lang == this.principalEventLangFormated) {
            array = [
                track.identifier,
                track.name[lang],
                track.txtColor,
                track.bgColor,
            ];
        } else {
            array = [track.identifier, track.name[lang]];
        }

        return array;
    }

    /******************************** IMPORT TRACKS **********************/
    /* GET EXCEL AND CONVERT TO JSON DATA */
    sheetValidFormatTrack: boolean = false;
    getTrackFile(evt: any) {
        this.preCheckError = false;
        this.sheetValidFormatTrack = false;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error("Cannot use multiple files");
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
            this.messageErrors = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                let sheetAuxName: string = wb.SheetNames[i];
                let wsAux: XLSX.WorkSheet = wb.Sheets[sheetAuxName];
                let sheetAuxData = <AOA>(
                    XLSX.utils.sheet_to_json(wsAux, { header: 1 })
                );
                if (sheetAuxName.includes("Principal - ")) {
                    this.sheetValidFormatTrack = true;
                }
                if (sheetAuxName !== "Principal - " + this.event.language) {
                    this.preCheckImport(sheetAuxData, sheetAuxName);

                    if (sheetAuxName == "pt_BR") {
                        this.dataImportTrackPtBR = sheetAuxData;
                    }
                    if (sheetAuxName == "en_US") {
                        this.dataImportTrackEnUS = sheetAuxData;
                    }
                    if (sheetAuxName == "es_ES") {
                        this.dataImportTrackEsES = sheetAuxData;
                    }
                    if (sheetAuxName == "fr_FR") {
                        this.dataImportTrackFrFR = sheetAuxData;
                    }
                    if (sheetAuxName == "de_DE") {
                        this.dataImportTrackDeDE = sheetAuxData;
                    }
                }
            }
            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.dataImportTrack = <AOA>(
                XLSX.utils.sheet_to_json(ws, { header: 1 })
            );
        };
        reader.readAsBinaryString(target.files[0]);
    }

    preCheckError: boolean = false;

    preCheckImport(data, tabName) {
        if (data.length > 0) {
            let row = 1;
            for (const element of data) {
                if (this.ignoreRowEmpty(element)) {
                    if (typeof element[this.trackIdN] !== "undefined") {
                        // converte ids números para strings
                        if (typeof element[this.trackIdN] === "number") {
                            const aux: number = element[this.trackIdN];
                            element[this.trackIdN] = aux.toString();
                        }
                    } else {
                        const column = this.checkLetterCols(this.trackIdN);
                        this.messageErrors.push(
                            tabName +
                            " -> " +
                            column +
                            row +
                            ": " +
                            " " +
                            this.translateService.instant(
                                "global.required_id"
                            )
                        );
                        this.preCheckError = true;
                    }
                    row++;
                }
            }
        }
    }

    invalidFormatSheetTrackMsg: string = null;
    checkTracks() {
        this.invalidFormatSheetTrackMsg = null;
        let validRows: number = 0;
        this.theErrorTrack = false;
        this.successMsgTrack = false;
        this.messageErrors = [];
        this.newTracks = [];

        if (this.dataImportTrack.length > 0) {
            if (this.sheetValidFormatTrack) {
                this.loaderImportTrack = true;
                let row = 1;
                for (const element of this.dataImportTrack) {
                    // IGNORE EMPTY ROWS
                    if (this.ignoreRowEmpty(element)) {
                        validRows++;
                        if (row > 1) {
                            const newTrack = new Track(null, null, null, null);
                            newTrack.$name = {
                                PtBR: "",
                                EnUS: "",
                                EsES: "",
                                FrFR: "",
                                DeDE: "",
                            };
                            // VALIDATE IDENTIFIERS
                            if (typeof element[this.trackIdN] !== "undefined") {
                                newTrack.$identifier =
                                    element[this.trackIdN].toString();
                                this.checkNumberRowIds(
                                    newTrack.$identifier,
                                    row
                                );
                            } else {
                                const column = this.checkLetterCols(
                                    this.trackIdN
                                );
                                this.messageErrors.push(
                                    column +
                                    row +
                                    ": " +
                                    " " +
                                    this.translateService.instant(
                                        "global.required_id"
                                    )
                                );
                            }

                            // VALIDATE NAME
                            if (
                                typeof element[this.trackNameN] !== "undefined"
                            ) {
                                if (
                                    typeof element[this.trackNameN] == "number"
                                ) {
                                    newTrack.$name[
                                        this.principalEventLangFormated
                                    ] = element[this.trackNameN].toString();
                                    newTrack.$queryName[
                                        this.principalEventLangFormated
                                    ] =
                                        newTrack.$name[
                                            this.principalEventLangFormated
                                        ].toUpperCase();
                                } else {
                                    newTrack.$name[
                                        this.principalEventLangFormated
                                    ] = element[this.trackNameN];
                                    newTrack.$queryName[
                                        this.principalEventLangFormated
                                    ] =
                                        newTrack.$name[
                                            this.principalEventLangFormated
                                        ].toUpperCase();
                                }
                            } else {
                                const column = this.checkLetterCols(
                                    this.trackNameN
                                );
                                this.messageErrors.push(
                                    column +
                                    row +
                                    ": " +
                                    " " +
                                    this.translateService.instant(
                                        "global.alert_name"
                                    )
                                );
                            }

                            // name
                            // this.queryName[lang] = this.name[lang].toUpperCase();

                            let indexTranslatedPtBr =
                                this.compareIdentifierTrack(
                                    newTrack["identifier"],
                                    this.dataImportTrackPtBR
                                );
                            if (
                                indexTranslatedPtBr > -1 &&
                                this.principalEventLangFormated !== "PtBR"
                            ) {
                                newTrack.$name["PtBR"] =
                                    this.dataImportTrackPtBR[
                                    indexTranslatedPtBr
                                    ][this.translatedNameTrack];
                                newTrack.$queryName["PtBR"] =
                                    newTrack.$name["PtBR"].toUpperCase();
                            }
                            let indexTranslatedEnUS =
                                this.compareIdentifierTrack(
                                    newTrack["identifier"],
                                    this.dataImportTrackEnUS
                                );
                            if (
                                indexTranslatedEnUS > -1 &&
                                this.principalEventLangFormated !== "EnUS"
                            ) {
                                newTrack.$name["EnUS"] =
                                    this.dataImportTrackEnUS[
                                    indexTranslatedEnUS
                                    ][this.translatedNameTrack];
                                newTrack.$queryName["EnUS"] =
                                    newTrack.$name["EnUS"].toUpperCase();
                            }
                            let indexTranslatedEsES =
                                this.compareIdentifierTrack(
                                    newTrack["identifier"],
                                    this.dataImportTrackEsES
                                );
                            if (
                                indexTranslatedEsES > -1 &&
                                this.principalEventLangFormated !== "EsES"
                            ) {
                                newTrack.$name["EsES"] =
                                    this.dataImportTrackEsES[
                                    indexTranslatedEsES
                                    ][this.translatedNameTrack];
                                newTrack.$queryName["EsES"] =
                                    newTrack.$name["EsES"].toUpperCase();
                            }
                            let indexTranslatedFrFR =
                                this.compareIdentifierTrack(
                                    newTrack["identifier"],
                                    this.dataImportTrackFrFR
                                );
                            if (
                                indexTranslatedFrFR > -1 &&
                                this.principalEventLangFormated !== "FrFR"
                            ) {
                                newTrack.$name["FrFR"] =
                                    this.dataImportTrackFrFR[
                                    indexTranslatedFrFR
                                    ][this.translatedNameTrack];
                                newTrack.$queryName["FrFR"] =
                                    newTrack.$name["FrFR"].toUpperCase();
                            }
                            let indexTranslatedDeDE =
                                this.compareIdentifierTrack(
                                    newTrack["identifier"],
                                    this.dataImportTrackDeDE
                                );
                            if (
                                indexTranslatedDeDE > -1 &&
                                this.principalEventLangFormated !== "DeDE"
                            ) {
                                newTrack.$name["DeDE"] =
                                    this.dataImportTrackDeDE[
                                    indexTranslatedDeDE
                                    ][this.translatedNameTrack];
                                newTrack.$queryName["DeDE"] =
                                    newTrack.$name["DeDE"].toUpperCase();
                            }

                            for (let lang in newTrack.$name) {
                                if (newTrack.$name[lang] == "") {
                                    newTrack.$name[lang] =
                                        newTrack.$name[
                                        this.principalEventLangFormated
                                        ];
                                    newTrack.$queryName[lang] =
                                        newTrack.$name[
                                            this.principalEventLangFormated
                                        ].toUpperCase();
                                }
                            }

                            // VALIDATE TEXT COLOR HEXADECIMAL
                            if (
                                typeof element[this.trackTxtColorN] !==
                                "undefined"
                            ) {
                                if (
                                    this.regex.verifyHex(
                                        element[this.trackTxtColorN]
                                    )
                                ) {
                                    newTrack.$txtColor =
                                        element[this.trackTxtColorN];
                                } else {
                                    const column = this.checkLetterCols(
                                        this.trackTxtColorN
                                    );
                                    this.messageErrors.push(
                                        column +
                                        row +
                                        ":" +
                                        " " +
                                        "Código hexadecimal inválido"
                                    );
                                }
                            } else {
                                const column = this.checkLetterCols(
                                    this.trackTxtColorN
                                );
                                this.messageErrors.push(
                                    column +
                                    row +
                                    ":" +
                                    " " +
                                    "Cor do texto é obrigatória"
                                );
                            }

                            // VALIDATE BACKGROUND COLOR HEXADECIMAL
                            if (
                                typeof element[this.trackBgColorN] !==
                                "undefined"
                            ) {
                                if (
                                    this.regex.verifyHex(
                                        element[this.trackBgColorN]
                                    )
                                ) {
                                    newTrack.$bgColor =
                                        element[this.trackBgColorN];
                                } else {
                                    const column = this.checkLetterCols(
                                        this.trackBgColorN
                                    );
                                    this.messageErrors.push(
                                        column +
                                        row +
                                        ":" +
                                        " " +
                                        "Código hexadecimal inválido"
                                    );
                                }
                            } else {
                                const column = this.checkLetterCols(
                                    this.trackBgColorN
                                );
                                this.messageErrors.push(
                                    column +
                                    row +
                                    ":" +
                                    " " +
                                    "Cor de fundo é obrigatória"
                                );
                            }

                            this.newTracks.push(newTrack);

                            if (this.messageErrors.length > 0) {
                                this.loaderImportTrack = false;
                                this.theErrorTrack = true;
                                this.successMsgTrack = false;
                            }
                            this.clearInputFileTrack(); // limpa o input do arquivo.
                        }
                    }
                    row++;
                }

                if (
                    this.newTracks.length == validRows - 1 &&
                    this.theErrorTrack == false
                ) {
                    this.importTracks();
                }
            } else {
                this.loaderImportTrack = false;
                this.theErrorTrack = false;
                this.successMsgTrack = false;
                this.invalidFormatSheetTrackMsg = this.translateService.instant(
                    "global.invalid_spreadsheet"
                );
            }
        } else {
            this.messageErrors.push(
                this.translateService.instant("global.no_file_selected")
            );
        }
    }

    compareIdentifierTrack(identifierTrack, translatedTrack) {
        let aux = this.translatedIdentifierTrack;
        let auxIdentifier = identifierTrack.toString();
        if (translatedTrack !== null && translatedTrack !== undefined) {
            return translatedTrack
                .map(function (e) {
                    return e[aux];
                })
                .indexOf(auxIdentifier);
        } else {
            return -1;
        }
    }

    clearInputFileTrack() {
        this.inputFileTrack.nativeElement.value = "";
        this.nameFile = null;
    }

    importTracks() {
        this.dbSchedule.importTracks(
            this.moduleId,
            this.eventId,
            this.newTracks,
            (status) => {
                if (status["message"] == "success") {
                    this.successMsgTrack = true;
                    this.loader = true;
                    this.loaderImportTrack = false;
                    this.theErrorTrack = false;
                    this.dataImportTrack = null;
                    this.messageErrors = [];
                    this.newTracks = [];
                    this.clearInputFileTrack();
                    if (status["result"]["arrayCreated"] !== undefined) {
                        this.importTrackCreated =
                            status["result"]["arrayCreated"].length;
                    }
                    if (status["result"]["arrayUpdated"] !== undefined) {
                        this.importTrackUpdated =
                            status["result"]["arrayUpdated"].length;
                    }
                    this.getSessions();
                } else {
                    this.errorSwal.fire();
                }
            }
        );
    }

    clearTrackForm() {
        this.nameFile = null;
        this.successMsgTrack = false;
        this.loaderImportTrack = false;
        this.theErrorTrack = false;
        this.dataImportTrack = null;
        this.messageErrors = [];
        this.newTracks = [];
    }

    /** IGNORE EMPTY ROWS */
    ignoreRowEmpty(row: any) {
        if (row.length < 1 || this.checkRowEmpty(row)) {
            return false;
        }
        return true;
    }

    /**VERIFY IF ROW IS EMPTY */
    checkRowEmpty(row: any) {
        for (const cel of row) {
            if (typeof cel !== "undefined" && cel.length > 0) {
                return false;
            }
        }
        return true;
    }

    /* CHECK IF HAVE DOUBLE IDENTIFIERS */
    checkNumberRowIds(identifier: any, rowId: number) {
        let cont = 2;
        for (const track of this.newTracks) {
            if (track.$identifier === identifier) {
                const column = this.checkLetterCols(this.trackIdN);
                this.messageErrors.push(
                    this.translateService.instant("global.equal_id") +
                    " " +
                    column +
                    cont +
                    " " +
                    this.translateService.instant("global.and") +
                    " " +
                    column +
                    rowId
                );
            }
            cont++;
        }
    }

    /* RETURN EXCEL COLUMN LETTER OF PARAMETER */
    checkLetterCols(column: number) {
        let letter = "";
        switch (column) {
            case this.trackIdN: {
                letter = "A";
                break;
            }
            case this.trackNameN: {
                letter = "B";
                break;
            }
            case this.trackTxtColorN: {
                letter = "C";
                break;
            }
            case this.trackBgColorN: {
                letter = "D";
                break;
            }
        }
        return letter;
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case "pt_BR": {
                formatedLang = "PtBR";
                break;
            }
            case "en_US": {
                formatedLang = "EnUS";
                break;
            }
            case "es_ES": {
                formatedLang = "EsES";
                break;
            }
            case "fr_FR": {
                formatedLang = "FrFR";
                break;
            }
            case "de_DE": {
                formatedLang = "DeDE";
                break;
            }
        }
        return formatedLang;
    }

    async setBtnsText(type: string) {
        if (type === "subscribe") {
            this.disableSubscribe = true;
            this.subscribeSessionBtn = this.subscribeSessionBtn.trim();
            await this.dbSchedule.updateProperty(
                this.eventId,
                this.moduleId,
                "subscribeSessionBtn",
                this.subscribeSessionBtn
            );
            this.disableSubscribe = false;
        } else {
            this.disableUnsubscribe = true;
            this.unsubscribeSessionBtn = this.unsubscribeSessionBtn.trim();
            await this.dbSchedule.updateProperty(
                this.eventId,
                this.moduleId,
                "unsubscribeSessionBtn",
                this.unsubscribeSessionBtn
            );
            this.disableUnsubscribe = false;
        }
    }

    /**
     * Create all visios for created sessions
     */
    async createAllVisios() {
        this.loader = true;
        try {
            if (this.event.allow_visio) {
                for (let i = 0; i < this.oldSessions.length; i++) {
                    let session = this.oldSessions[i];
                    // Delete old room if exist
                    if (
                        session.visio &&
                        session.visio.type === "whereby" &&
                        session.visio.meetingId
                    ) {
                        await this.SWhereby.deleteMeeting(
                            session.visio.meetingId
                        );
                    }
                    let resp: any = null;
                    let endDate = session.newEndTime
                        ? this.luxon
                            .createDate(
                                session.newEndTime.year.toString(),
                                session.newEndTime.month.toString(),
                                session.newEndTime.day.toString(),
                                "00",
                                "00",
                                "00"
                            )
                            .toISO()
                        : DateTime.local().plus({ days: 30 }).toISO();
                    let meeting: any = {
                        roomNamePrefix:
                            "/" + session.name[this.principalEventLangFormated],
                        roomMode: "group",
                        endDate: endDate,
                        fields: ["hostRoomUrl"],
                    };
                    resp = await this.SWhereby.createMeeting(meeting);
                    if (resp.body && resp.body.meetingId && resp.body.roomUrl) {
                        session.visio = {
                            type: "whereby",
                            zoomIntegrationId: "",
                            password: "",
                            meetingId: resp.body.meetingId
                                ? resp.body.meetingId
                                : "",
                            roomUrl: resp.body.roomUrl ? resp.body.roomUrl : "",
                            hostRoomUrl: resp.body.hostRoomUrl
                                ? resp.body.hostRoomUrl
                                : "",
                            startDate: null,
                            endDate: endDate ? endDate : null,
                            roomMode: "group",
                            roomName:
                                session.name[this.principalEventLangFormated],
                            allowChatOnVisio: true,
                            allowScreenShareOnVisio: true,
                            allowLeaveOnVisio: true,
                            activated: true,
                            groupsVisioHost: {},
                        };
                    }
                }

                await this.dbSchedule.updateSessions(
                    this.eventId,
                    this.moduleId,
                    this.oldSessions
                );

                this.loader = false;
                this.successSwal.fire();
            }
        } catch (error) {
            this.loader = false;
            this.errorSwal.fire();
        }
    }

    /**
     * @description verify if the modal "settingsModal" is open or close, and when it is closed by any means, 
     * this method executes "updateSettings()" that update all the values related to ui-switches
     * (i.e. all boolean fields in the modal)
     */
    async checkModalStatus() {
        const status = new Promise<boolean>((resolve) => {
            let modal: HTMLElement;

            const checkModal = () => {
                modal = document.getElementById("settingsModal");
                console.log(`inside promise`);

                if (modal.style.display === "none") { resolve(true); }
                else { setTimeout(checkModal, 2000); }
            }

            setTimeout(() => {
                // this timeout is needed so the modal can open before the method is executed
                checkModal();
            }, 500);
        })

        status.then(() => {
            // send all settigns data to db
            if (this.settingsUpdated) { this.updateSettings(); }
            console.log(`then ->`)
        }).catch((error) => {
            console.log(error);
        })
    }

    /**
     * @description update all boolean fields from "settingsModal" (ui-switches), in the database
     */
    updateSettings() {
        this.settingsUpdated = false;

        const settings = {
            timezoneType: this.module.timezoneType,
            habiliedPersonal: this.module.habiliedPersonal,
            habilitedLimit: this.module.habilitedLimit,
            allowNextPrevBtn: this.module.allowNextPrevBtn,
            allowVisio: this.module.allowVisio,
            showHeader: this.module.showHeader,
            showSpeakers: this.module.showSpeakers,
            showDescription: this.module.showDescription,
            icsDownloadable: this.module.icsDownloadable,
            showCustomFields: this.module.showCustomFields,
            enableTrackFilter: this.module.enableTrackFilter
        };

        this.dbSchedule.updateSettings(this.eventId, this.moduleId, settings);
    }

    /**
     * @description invert the boolean value of the object element that corresponds to the 
     * settingsField (key) passed as param 
     * @param settingsField a key of an instance of the object ModuleSchedule
     */
    invertValue(settingsField: string) {
        this.settingsUpdated = true;
        this.module[settingsField] = !this.module[settingsField];   
    }
}
