import { Injectable } from "@angular/core";

@Injectable()

export class PathRoutes {
    // global
    static ROUTER_LOGIN = 'login';

    // adm-dashboard module
    static ROUTER_DASHBOARD = 'dashboard';
    static ROUTER_CLIENT_DASHBOARD = 'dash';


}