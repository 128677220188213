import { Component, OnInit } from '@angular/core';
import { DbAdminUserProvider } from '../../providers/database/db-admin-user';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-supergod',
    templateUrl: './supergod.component.html',
    styleUrls: ['./supergod.component.scss'],
    providers: [DbAdminUserProvider]
})
export class SupergodComponent implements OnInit {
    supergodsSubscriptions: Subscription;

    public listUsers = [];
    public p: number = 1;
    public deleteUserId: string;
    deleteUserIndex: number;
    loader: boolean = true;
    userType: number = null;
    userId: string = null;
    typeOrder: string = 'asc';

    constructor(private dbAdminUser: DbAdminUserProvider, private global: GlobalService) {
        this.global.loadService(() => {
            this.userId = this.global.userId;
            this.loadOrder().then(() => {
                this.getSuperGods();
            });
        })

    }

    ngAfterContentChecked() {
        this.userType = this.global.userType;
    }

    ngOnInit() {
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        if (this.supergodsSubscriptions && !this.supergodsSubscriptions.closed) {
            this.supergodsSubscriptions.unsubscribe();
        }
    }

    loadOrder() {
        return new Promise((resolve) => {
            this.userId = this.global.userId;

            this.dbAdminUser.getUser(this.userId, (data) => {
                let user = data.result;

                if (user.superGodsOrder !== undefined && user.superGodsOrder !== null && user.superGodsOrder !== '') {
                    this.typeOrder = user.superGodsOrder;
                }

                resolve(true);
            })
        })
    }

    getSuperGods() {
        if (this.supergodsSubscriptions && !this.supergodsSubscriptions.closed) {
            this.supergodsSubscriptions.unsubscribe();
        }

        this.supergodsSubscriptions = this.dbAdminUser.getSuperGods(this.typeOrder).subscribe((users) => {
            this.listUsers = users['result'];
            this.loader = false;
        })
    }

    changeOrder() {
        this.dbAdminUser.changeOrderSupergods(this.userId, this.typeOrder, (data) => {
            if (data == true) {
                this.getSuperGods();
            }
        })
    }

    getUserRemove(userId, index) {
        this.deleteUserId = userId;
        this.deleteUserIndex = index;
    }

    removeUser() {
        this.loader = true;
        this.dbAdminUser.removeUser(this.deleteUserId).subscribe((data) => {
            if (data == true) {
                this.listUsers.splice(this.deleteUserIndex, 1);
            }
            this.loader = false;
        })
    }
}
