/**pipe used to filter session titles. */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterSessions',
    pure: true
})
export class FilterPiperSessions implements PipeTransform {
    transform(list: any, term: any): any {
        // check if search term is undefined
        if (term === undefined) {
            return list;
        }

        return list.filter((s) =>
            (
                (s.name.PtBR || '') +
                (s.name.FrFR || '') +
                (s.name.EnUS || '') +
                (s.name.DeDE || '')
            )
                .toLowerCase()
                .includes(term.toLowerCase())
        );
    }
}
