import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";

export class ModuleEvent extends Module {
    visOnlyForLoggedUser?: boolean;

    constructor(name: NameModule, icon: string, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.EVENT, eventId, order, false, true, true);
        this.viewBackOffice = null;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}