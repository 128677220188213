import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PathApi } from "../../paths/path-api";
import { TypeModule } from "src/app/enums/type-module";
import { WordCloud } from "src/app/models/word-cloud";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
    providedIn: "root",
})
export class DbWordCloudProvider {
    public headers: HttpHeaders;
    public requestOptions;

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore,
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", "application/json");
        this.headers.append("Content-Type", "application/json");
        this.requestOptions = { headers: this.headers };
    }

    getWordCloudModule(eventId: string) {
        const ref = this.aFirestore.firestore
            .collection(`events/${eventId}/modules`)
            .where("type", "==", TypeModule.WORDCLOUD);

        return ref.get();
    }

    createWordCloud(moduleId: string, wordCloud: WordCloud) {
        const ref = this.aFirestore.firestore
            .collection("modules")
            .doc(moduleId)
            .collection("wordClouds")
            .doc();

        wordCloud._uid = ref.id;
        wordCloud = Object.assign({}, wordCloud);

        return ref.set(wordCloud);
    }

    fetchWordCloud(moduleId: string) {
        const ref = this.aFirestore.firestore
            .collection(`modules/${moduleId}/wordClouds`);
        return ref.get();
    }

    getWords(moduleId: string, cloudId: string) {
        const ref = this.aFirestore.firestore
            .collection(`modules/${moduleId}/wordClouds/${cloudId}/words`);
        return ref.get();
    }

    editWordCloud(moduleId: string, cloudId: string, wordCloud: WordCloud) {
        wordCloud = Object.assign({}, wordCloud);
        const ref = this.aFirestore.firestore
            .doc(`modules/${moduleId}/wordClouds/${cloudId}`);
        return ref.update(wordCloud);
    }

    toggleWordCloudVisibility(moduleId: string, cloudId: string, status: boolean) {
        const ref = this.aFirestore.firestore
            .doc(`modules/${moduleId}/wordClouds/${cloudId}`);
        return ref.update({ visibility: status });
    }

    exportCloud(moduleId: string, cloudId: string) {
        return this.http
            .get(
                PathApi.baseUrl +
                    PathApi.dbWordCloudExport +
                    "?moduleId=" +
                    moduleId +
                    "&cloudId=" +
                    cloudId,
                this.requestOptions
            );
    }

    clearResult(moduleId: string, cloudId: string) {
        return this.http
            .delete(
                PathApi.baseUrl +
                    PathApi.dbWordCloudClearResults +
                    "?moduleId=" +
                    moduleId +
                    "&cloudId=" +
                    cloudId,
                this.requestOptions
            );
    }

    deleteWordCloud(moduleId: string, cloudId: string) {
        return this.http
            .delete(
                PathApi.baseUrl +
                    PathApi.dbWordCloudDelete +
                    "?moduleId=" +
                    moduleId +
                    "&cloudId=" +
                    cloudId,
                this.requestOptions
            );
    }
}
