import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Location } from '../../models/location';
import { StorageService } from '../storage/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { Group } from 'src/app/models/group';
import { TypeModule } from 'src/app/enums/type-module';
import { ModuleGroup } from 'src/app/models/modules/module-groups';

@Injectable({
    providedIn: 'root'
})

export class DbGroupsProvider {
    public headers;
    public requestOptions;

    constructor(private aFirestore: AngularFirestore, private storage: StorageService, private http: HttpClient) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getGroupEvent(eventId: string): Promise<ModuleGroup> {
        return new Promise((resolve, reject) => {
            this.aFirestore.firestore
                .collection(`events/${eventId}/modules`)
                .where('type', '==', TypeModule.MANAGER_GROUP)
                .get()
                .then(snap => (snap.docs.length == 0) ? 
                    reject() : resolve(snap.docs[0].data() as ModuleGroup)
                ).catch(err => reject(err))
        })
    }

    searchModulesAndGroups(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.MANAGER_GROUP)
            .get()
            .then((value) => {
                value.forEach(element => {
                    let module = element.data();
                    let arrayAux = [];
                    ref
                        .doc(module.uid)
                        .collection('groups')
                        .get()
                        .then((valueGroup) => {
                            let size = valueGroup.size;
                            if (size >= 1) {
                                valueGroup.forEach(el => {
                                    arrayAux.push(el.data());
                                });

                                onResolve({
                                    moduleId: module.uid,
                                    groups: arrayAux
                                })

                            } else {
                                onResolve({
                                    moduleId: module.uid,
                                    groups: arrayAux
                                })
                            }
                        })
                });
            })
    }

    getGroupModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    getGroupsOfEvent(eventId: string, onResolve){
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules')
                    .where('eventId','==',eventId)
                    .where('type', '==', TypeModule.MANAGER_GROUP)
                    .get()
                    .then((value) => {
                        value.forEach(el => {
                            let module = el.data();
                            const newRef = db.collection('modules')
                                            .doc(module.uid)
                                            .collection('groups')
                                            .onSnapshot((values) => {
                                                const groups = []
                                                values.forEach(item => groups.push(item.data()))
                                                onResolve(groups)
                                            })
                        })
                    });
    }

    getGroupsOfEventPromise(eventId: string): Promise<any[]>{
        let db = this.aFirestore.firestore;
        return new Promise((resolve, reject) => {
            let ref = db.collection('modules')
                        .where('eventId','==',eventId)
                        .where('type', '==', TypeModule.MANAGER_GROUP)
                        .get()
                        .then((value) => {
                            value.forEach(el => {
                                let module = el.data();
                                const newRef = db.collection('modules')
                                                .doc(module.uid)
                                                .collection('groups')
                                                .onSnapshot((values) => {
                                                    const groups = []
                                                    values.forEach(item => groups.push(item.data()))
                                                    resolve(groups)
                                                })
                            })
                        });

        })
    }

    getGroups(moduleId: string, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;
        switch (typeOrder) {
            case 'asc': //a-z
                ref = db.collection('modules').doc(moduleId).collection('groups')
                    .orderBy('queryName', 'asc');
                break;

            case 'desc': //z-a
                ref = db.collection('modules').doc(moduleId).collection('groups')
                    .orderBy('queryName', 'desc');
                break;

            case 'oldest'://antiho-recente
                ref = db.collection('modules').doc(moduleId).collection('groups')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('groups')
                    .orderBy('createdAt', 'desc');
                break;
        }

        ref
            .onSnapshot((values) => {
                let groups = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        groups.push(element.data());
                    });
                }
                onResolve(groups);
            });
    }

    changeOrderItems(moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ orderGroups: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    async newGroup(moduleId: string, eventId: string, group: Group) {
        const batch = this.aFirestore.firestore.batch();
        
        let ref1: DocumentReference;
        if (!group.uid) {
            ref1 = this.aFirestore.firestore
                .collection(`events/${eventId}/modules/${moduleId}/groups`)
                .doc();
            
            group.uid = ref1.id;
        } else {
            ref1 = this.aFirestore.firestore.doc(
                `events/${eventId}/modules/${moduleId}/groups/${group.uid}`
            );
        }
        const ref2 = this.aFirestore.firestore
            .doc(`modules/${moduleId}/groups/${group.uid}`);
        
        group = Object.assign({}, group);
        
        batch.set(ref1, group);
        batch.set(ref2, group);

        return new Promise<any>((res, rej) => {
            batch.commit().then(() => res(group)).catch(err => rej(err))
        })
    }

    editGroup(moduleId: string, eventId: string, group: Group, onResolve) {
        let body = {
            moduleId: moduleId,
            eventId: eventId,
            group: group
        }

        this.http.post(PathApi.baseUrl + PathApi.dbGroupsEditGroup, body, this.requestOptions)
            .subscribe((status) => {
                onResolve(status['result']);
            });
    }

    deleteGroup(moduleId: string, groupId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('modules').doc(moduleId).collection('groups').doc(groupId).delete().then(() => {
            onResolve(true)
        }).catch(function (error) {
            onResolve(false)
        });
    }

    removeGroups(moduleId, listRemoveUids, onResolve) {
        let batch = this.aFirestore.firestore.batch()
        let db = this.aFirestore.firestore         
        
        for(const groupId of listRemoveUids){
           const ref =  db.collection('modules').doc(moduleId).collection('groups').doc(groupId)
            batch.delete(ref)
        }

        batch
            .commit()
            .then(() =>{
                onResolve(true)
            })
            .catch((err) =>{
                onResolve(false)
            })
        }

    existIdentifier(moduleId, identifier, onResolve) {
        let db = this.aFirestore.firestore;

        let moduleRef = db.collection('modules').doc(moduleId).collection('groups').where('identifier', '==', identifier);

        moduleRef.get()
            .then((data) => {
                if (data.size > 0) {
                    onResolve(true);
                } else {
                    onResolve(false);
                }
            })
    }

    getGroupsIds(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('groups').orderBy("identifier", "asc");

        ref.get().then((data) => {
            let listIds: Array<number> = [];
            data.forEach(element => {
                let track = element.data();
                listIds.push(track.identifier);
            });

            onResolve(listIds);
        })
    }

    importGroups(moduleId: string, eventId: string, groups: Array<Group>, onResolve) {
        let body = {
            moduleId: moduleId,
            eventId: eventId,
            groups: groups
        }
        this.http.post(PathApi.baseUrl + PathApi.dbGroupsImportGroup, body, this.requestOptions)
            .subscribe((_) => {
                onResolve(_);
            });
    }

    //========================================================== group multi language ===========================================

    // check if group exists by name
    checkGroupByName(name: string, eventId: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            const moduleGroup = await this.getGroupEvent(eventId)
            const moduleGroupId = moduleGroup.uid

            let db = this.aFirestore.firestore;

            db.collection('modules')
                .doc(moduleGroupId)
                .collection('groups')
                .where('name', '==', name)
                .get()
                .then((snapshot) => {
                    resolve(snapshot.size > 0 ? true : false)
                })

        })
    }

}