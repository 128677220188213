import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private auth: AuthService) {

    }
    canActivate(): boolean {
        return this.auth.authenticated();
    }
}
