export class NameSession {
    DeDE: string
    EnUS: string
    EsES: string
    FrFR: string
    PtBR: string

    constructor(PtBR: string, EnUS: string, EsES: string, FrFR: string, DeDE: string) {
        this.DeDE = DeDE
        this.EnUS = EnUS
        this.EsES = EsES
        this.FrFR = FrFR
        this.PtBR = PtBR
    }
}