import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleRooms extends Module {
    typeVision: number;
    selected: boolean;
    sortBy: string = "asc";

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number) {
        super(name, icon, TypeModule.ROOMS, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.selected = false;
    }
}
