import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Speaker } from 'src/app/models/speakers';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { Name } from 'src/app/models/name';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { eventCustomFieldAnswerOption } from 'src/app/models/eventCustomFieldAnswerOption';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbDocuments } from 'src/app/providers/database/db-documents';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { TypeUser } from 'src/app/enums/typeUser';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { NameGroup } from 'src/app/enums/name-group';
import { Attendee } from 'src/app/models/attendees';
import { Subscription } from 'rxjs';
import { NameModule } from 'src/app/models/name-module';
import { DBCustomFieldsProvider } from 'src/app/providers/database/db-custom-fields.service';
declare let $: any;

@Component({
    selector: 'app-edit-speaker',
    templateUrl: './edit-speaker.component.html',
    styleUrls: ['./edit-speaker.component.scss']
})
export class EditSpeakerComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmailAttendeeSwal') public errorEmailAttendeeSwal: SwalComponent;

    // ckeditConfig = environment.ckeditorConfig;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    formValidation: FormGroup;

    public eventId: string = null;
    public moduleId: string = null;
    module: any;
    public speakerId: string = null;
    public speaker;
    public loader: boolean = false;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;

    public listCustomFields: any[] = [];
    cfAnswerOptns = {};
    uneditedCfsAnswers: any[] = [];
    cfAnswers = {};

    // GROUPS FILTER
    listGroup: Array<Group>
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = '';
    errorFormGroup: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<any> = [];
    selectedDocument: Array<any> = [];
    filteredListDocuments = [];
    queryDocument = '';
    errorFormDocument: boolean = false;
    event: any;
    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string
    principalEventLangFormated: string = 'PtBR';
    listAttendees: Array<Attendee>
    selectedAttendee = [];
    filteredListAttendees = [];
    queryAttendee = '';

    attendeesField: string = "";
    customUser: any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbSpeaker: DbSpeakersProvider,
        private dbAttendee: DbAttendeesProvider,
        private router: Router,
        private dbGroups: DbGroupsProvider,
        private dbDocuments: DbDocuments,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        private SCustomFields: DBCustomFieldsProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.speakerId = this.route.snapshot.params['speakerId'];

        this.formValidation = fb.group({
            'photoUrl': [null],
            'id': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
            'email': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'emailRecovery': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'title_ptBR': [""],
            'title_enUS': [""],
            'title_esES': [""],
            'title_frFR': [""],
            'title_deDE': [""],
            'company': [null, Validators.compose([Validators.maxLength(1000)])],
            'website': [null, Validators.compose([Validators.maxLength(500)])],
            'facebook': [null, Validators.compose([Validators.maxLength(1000)])],
            'instagram': [null, Validators.compose([Validators.maxLength(500)])],
            'linkedin': [null, Validators.compose([Validators.maxLength(500)])],
            'twitter': [null, Validators.compose([Validators.maxLength(500)])],
            'description_ptBR': [""],
            'description_enUS': [""],
            'description_esES': [""],
            'description_frFR': [""],
            'description_deDE': [""],
            'groups': [null],
            'documents': [null],
            'points': [null],
            'attendees': [null]
        })
    }

    ngOnInit() {
        this.getModule();
        this.getAttendeesForEvent();
        this.getSpeaker();
        this.getGroups();
        this.getDocuments();
        this.startEvent();
        this.getUserLanguage();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
            this.principalEventLangFormated = this.convertLangFormat(this.userLanguage);
        })
    }

    fillOtherLanguages(item: NameModule) {
        item.DeDE = item[this.principalEventLangFormated];
        item.EnUS = item[this.principalEventLangFormated];
        item.EsES = item[this.principalEventLangFormated];
        item.FrFR = item[this.principalEventLangFormated];
        item.PtBR = item[this.principalEventLangFormated];
    }

    getModule() {
        this.dbSpeaker.speakerModule(this.moduleId, (module) => {
            this.module = module;
            this.attendeesField = module.attendeesField;
        });
    }

    getAttendeesForEvent() {
        this.subscriptions.push(this.dbAttendee.getAttendeesForEvent(this.eventId).subscribe((attendees) => {
            this.listAttendees = [];
            this.listAttendees = attendees;
        }))
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.languages = event.languages;
        });
    }

    getSpeaker() {
        this.dbSpeaker.getSpeaker(this.eventId, this.moduleId, this.speakerId, (data) => {
            this.speaker = data.result;
            // groups
            for (const uid in this.speaker['groups']) {
                this.selectedGroup.push(this.speaker['groups'][uid]);
            }
            for (const doc in this.speaker['documents']) {
                this.selectedDocument.push(this.speaker['documents'][doc]);
            }

            this.setFormValues();

            // Attendees
            this.selectedAttendee = [];
            for (const uid in this.speaker.attendees) {
                this.selectedAttendee.push(this.speaker.attendees[uid]);
            }

            this.getCustomFields();
        })
    }

    getGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = result['groups'];
        });
    }

    getDocuments() {
        this.dbDocuments.getEventDocuments(this.eventId, (documents: Array<any>) => {
            this.listDocument = [];
            this.listDocument = documents;
        });
    }

    getCustomFields() {
        Promise.all([ 
            this.SCustomFields.getCustomFields(this.eventId, this.moduleId),
            this.SCustomFields.getUserCfsAnswers(
                this.eventId, 
                this.speaker.uid, 
                TypeUser.SPEAKER
            )
        ]).then((result) => {
            result[0].forEach((cf) => {
                if (cf.type == 's') {
                    this.cfAnswerOptns[cf.uid] = cf.answerOptions;
                }
            })
            this.listCustomFields = result[0];

            result[1].forEach((cfAnswer) => {
                this.cfAnswers[cfAnswer.id] = cfAnswer.answer;
            });
            this.uneditedCfsAnswers = result[1];
        }).catch(error => console.error(error));
    }

    setFormValues() {
        this.formValidation.patchValue({
            id: this.speaker.identifier,
            name: this.speaker.name,
            email: this.speaker.email,
            emailRecovery: this.speaker.emailRecovery,
            photoUrl: this.speaker.photoUrl,
            title_ptBR: this.speaker.title.PtBR,
            title_enUS: this.speaker.title.EnUS,
            title_esES: this.speaker.title.EsES,
            title_frFR: this.speaker.title.FrFR,
            title_deDE: this.speaker.title.DeDE,
            company: this.speaker.company,
            website: this.speaker.website,
            facebook: this.speaker.facebook,
            instagram: this.speaker.instagram,
            linkedin: this.speaker.linkedin,
            twitter: this.speaker.twitter,
            description_ptBR: this.speaker.description.PtBR,
            description_enUS: this.speaker.description.EnUS,
            description_esES: this.speaker.description.EsES,
            description_frFR: this.speaker.description.FrFR,
            description_deDE: this.speaker.description.DeDE,
            points: this.speaker.points
        })
    }

    editSpeaker(formValues) {
        this.loader = true;
        let name = formValues.name.trim();
        let speaker = new Speaker(name, 4, this.eventId);
        speaker.$points = this.speaker.points;
        if (formValues.email) {
            speaker.$email = formValues.email.toLowerCase();
        } else {
            speaker.$email = formValues.email;
        }
        speaker.$moduleId = this.speaker.moduleId;
        speaker.$uid = this.speaker.uid;
        if (formValues.emailRecovery) {
            speaker.$emailRecovery = formValues.emailRecovery.toLowerCase();
        } else {
            speaker.$emailRecovery = formValues.emailRecovery;
        }
        speaker.$identifier = this.speaker.identifier;
        speaker.$description = {
            PtBR: formValues.description_ptBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EnUS: formValues.description_enUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EsES: formValues.description_esES.replace(/href="/g, 'class="wysiwyg-link" href="'),
            FrFR: formValues.description_frFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            DeDE: formValues.description_deDE.replace(/href="/g, 'class="wysiwyg-link" href="'),
        };
        speaker.$company = formValues.company;
        speaker.$title = {
            PtBR: formValues.title_ptBR,
            EnUS: formValues.title_enUS,
            EsES: formValues.title_esES,
            FrFR: formValues.title_frFR,
            DeDE: formValues.title_deDE,
        };
        if (formValues.website !== null && formValues.website !== undefined) speaker.$website = formValues.website;
        speaker.$facebook = formValues.facebook;
        speaker.$instagram = formValues.instagram;
        speaker.$linkedin = formValues.linkedin;
        speaker.$twitter = formValues.twitter;
        speaker.$photoUrl = this.speaker.photoUrl;

        let changeEmail = false;
        if (this.speaker.email !== speaker.$email) {
            changeEmail = true;
        }

        // groups
        if (this.selectedGroup.length > 0) {
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                speaker.$groups[group['uid']] = Object.assign({}, group)
            }
        }

        // Attendees
        if (this.selectedAttendee.length > 0) {
            for (let index in this.selectedAttendee) {
                const attendee = this.selectedAttendee[index]
                speaker.$attendees[attendee.uid] = Object.assign({}, attendee)
            }
        }

        // languages
        speaker.$language = this.event.language

        // documents
        if (this.selectedDocument.length > 0) {
            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                speaker.$documents[document['uid']] = Object.assign({}, document);
            }
        }

        this.dbSpeaker.verifyIdentifierValidBySpeaker(this.eventId, speaker.$identifier, speaker.$uid, (validIdentifier) => {
            this.dbSpeaker.verifyEmailValidBySpeaker(speaker.$email, speaker.$uid, (validEmail) => {
                if (validIdentifier && validEmail) {
                    this.dbSpeaker.editSpeaker(
                        this.eventId, 
                        this.moduleId, 
                        speaker, 
                        this.uneditedCfsAnswers.filter((el) => {
                            if (el.answer != this.cfAnswers[el.id]) {
                                el.answer = this.cfAnswers[el.id];
                                return true;
                            }
                            return false;
                        }),
                        this.finalFile, 
                        changeEmail, 
                    ).then(() => {
                        this.successSwal.fire();
                    }).catch((error) => {
                        console.error(error);
                        this.errorSwal.fire();
                    }).finally(() => { this.loader = false; });
                } else {
                    this.loader = false;
                    this.errorSwal.fire();
                }

            })
        })
    }

    /**
     * Filter attendees
     */
    filterAttendees() {
        if (this.queryAttendee !== "") {
            this.filteredListAttendees = this.listAttendees.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryAttendee.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListAttendees = [];
        }
    }

    /**
     * Select an attendee
     * @param item 
     */
    selectAttendee(item) {
        if (this.selectedAttendee.length > 0) {
            const index = this.selectedAttendee.indexOf(item);
            if (index == -1) {
                this.selectedAttendee.push(item);
            }
        } else {
            this.selectedAttendee.push(item);
        }
        this.queryAttendee = '';
        this.filteredListAttendees = [];
    }

    /**
     * Remove selected attendee
     * @param item 
     */
    removeSelectedAttendee(item) {
        this.selectedAttendee.splice(this.selectedAttendee.indexOf(item), 1);
    }

    /**
     * On upload image file
     * @param $event 
     */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }

    redirectList() {
        this.router.navigate(["event/" + this.eventId + "/speakers/" + this.moduleId]);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroups = [];
        }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = '';
        this.filteredListGroups = [];
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }

    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(function (el) {
                return el.name[this.principalEventLangFormated].toLowerCase().indexOf(this.queryDocument.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument.indexOf(item);
            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }
        this.queryDocument = '';
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeDocument(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

}
