export class DescriptionsSession{
    DeDE = ''
    EnUS = ''
    EsES = ''
    FrFR = ''
    PtBR = '' 

    constructor(){
        
    }
}