import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase/app'
import { SponsorProps } from 'src/app/client-dashboard/client-event-dashboard/client-modules/sponsors/@types/sponsor';
import { PathStorage } from 'src/app/paths/path-storage';
import { StandProps } from 'src/app/client-dashboard/client-event-dashboard/client-modules/stands/@types/stands';

@Injectable()

export class StorageService {
    storage: firebase.storage.Reference;
    constructor(
        private afStorage: AngularFireStorage
    ) {
        this.storage = firebase.storage().ref();
    }

    profilePicture(file: any, userId: string, onResolve) {
        const ref = this.storage.child(PathStorage.profile_pictures).child(userId);
        ref.putString(file, 'base64').then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        });
    }

    profilePictureAttendees(file: any, eventId: string, userId: string, onResolve) {
        let metadata = {
            contentType: 'image/jpeg',
        };

        const ref = this.storage.child(PathStorage.profile_pictures_attendees).child(eventId).child(userId);
        ref.putString(file, 'base64', metadata).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        });
    }

    profilePictureSpeakers(file: any, eventId: string, userId: string, onResolve) {
        let metadata = {
            contentType: 'image/jpeg',
        };

        const ref = this.storage.child(PathStorage.profile_pictures_speakers).child(eventId).child(userId);
        ref.putString(file, 'base64', metadata).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        });
    }

    sponsorPicture(file: string, fileType: 'logo' | 'banner', eventId: string, moduleId: string, sponsorId: string, onResolve: (url: string) => void) {
        const metadata = {
            contentType: 'image/jpeg',
        };

        const ref = this.storage.child(PathStorage.sponsors_picture).child(eventId).child(moduleId).child(sponsorId).child(fileType);

        ref.putString(file, 'base64', metadata).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        });
    }

    deleteSponsorPicture(eventId: string, moduleId: string, sponsor: SponsorProps | StandProps) {
        const logoRef = this.storage.child(PathStorage.sponsors_picture).child(eventId).child(moduleId).child(sponsor.uid).child("logo");
        const bannerRef = this.storage.child(PathStorage.sponsors_picture).child(eventId).child(moduleId).child(sponsor.uid).child("banner");

        const promises: Promise<void>[] = [];

        if (sponsor.logo) {
            promises.push(logoRef.delete());
        }

        if (sponsor.banner) {
            promises.push(bannerRef.delete());
        }

        return Promise.all(promises);
    }

    delete(userId: string) {
        var deleteRef = this.storage.child(PathStorage.profile_pictures).child(userId);
        deleteRef.delete();
    }

    widgetPicture(file: any, eventId: string, moduleId: string, widgetUID: string) {
        console.log('storage widgetPicture',file, eventId, moduleId, widgetUID)
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.widgets).child(eventId).child(moduleId).child(widgetUID);
            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(null);
                })
            }).catch((error) => {
                resolve(null);
            })
        })
    }

    deleteWidget(eventId: string, moduleId: string, widgetUID: string) {
        const deleteRef = this.storage.child(PathStorage.widgets).child(eventId).child(moduleId).child(widgetUID);
        deleteRef.delete();
    }

    locationPicture(file: any, eventId: string, moduleId: string, locationId: string, onResolve) {
        const ref = this.storage.child(PathStorage.locations).child(eventId).child(moduleId).child(locationId);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        })
    }

    deleteLocation(eventId: string, moduleId: string, locationId: string) {
        var deleteRef = this.storage.child(PathStorage.locations).child(eventId).child(moduleId).child(locationId);
        deleteRef.delete();
    }

    // galleryPicture(file: any, eventId: string, moduleId: string, folderId: string , imageId: string, onResolve) {
    //     const ref = this.storage.child(PathStorage.gallery).child(eventId).child(moduleId).child(folderId).child(imageId);
    //     ref.put(file).then((snapshot) => {
    //         ref.getDownloadURL().then((url: string) => {
    //             onResolve(url);
    //         }).catch((error) => {
    //             onResolve(null);
    //         })
    //     }).catch((error) => {
    //         onResolve(null);
    //     })
    // }

    // deleteGalleryPic(eventId: string, moduleId: string, folderId: string, imageId: string) {
    //     this.storage.child(PathStorage.gallery).child(eventId).child(moduleId).child(folderId).child(imageId).delete();
    // }

    // realiza upload de uma img para o firebase storage e retorna a url dela no storage para onResolve
    uploadImg(file: any, eventId: string, moduleId: string, folderId: string, imageId: string, pathStorage: string, onResolve) {
        const ref = this.storage.child(pathStorage).child(eventId).child(moduleId).child(folderId).child(imageId);

        ref.put(file).then(() => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch(() => {
                onResolve(null);
            })
        }).catch(() => {
            onResolve(null);
        })
    }

    // exclui uma img do firebase storage
    deleteImg(eventId: string, moduleId: string, folderId: string, imageId: string, pathStorage: string) {
        this.storage.child(pathStorage).child(eventId).child(moduleId).child(folderId).child(imageId).delete();
    }

    // // realiza o upload de uma img de capa de uma folder para o firebase storage
    // documentFolderImg(file: any, eventId: string, moduleId: string, folderId: string, imageId: string, onResolve) {
    //     const ref = this.storage.child(PathStorage.documents).child(eventId).child(moduleId).child(folderId).child(imageId);
    //     ref.put(file).then(() => {
    //         ref.getDownloadURL().then((url: string) => {
    //             onResolve(url);
    //         }).catch(() => {
    //             onResolve(null);
    //         })
    //     }).catch(() => {
    //         onResolve(null);
    //     })
    // }

    // // deleta o arquivo de img usado como capa da pasta de documentos
    // deleteDocFolderImg(eventId: string, moduleId: string, folderId: string, imageId: string) {
    //     this.storage.child(PathStorage.documents).child(eventId).child(moduleId).child(folderId).child(imageId).delete();
    // }

    documentFile(file: any, eventId: string, moduleId: string, folderId: string, docStorageId, type, onResolve) {
        const ref = this.storage.child(PathStorage.documents).child(eventId).child(moduleId).child(folderId).child(docStorageId);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        })
    }

    deleteDocument(eventId: string, moduleId: string, folderId: string, docId: string) {
        this.storage.child(PathStorage.documents).child(eventId).child(moduleId).child(folderId).child(docId).delete();
    }

    logoPicture(file: any, fileId: string, eventId: string, onResolve) {
        const ref = this.storage.child(PathStorage.event_visual).child(eventId).child(fileId);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        })
    }

    eventBgImg(file: any, eventId: string,imageUID: string) : Promise<string> {
        return new Promise((resolve,reject) => {
            const ref = this.storage.child(PathStorage.event_visual).child(eventId).child(imageUID);
            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(null);
                })
            }).catch((error) => {
                resolve(null);
            })
        })
    }

    deleteLogo(eventId: string, logoId: string) {
        var deleteRef = this.storage.child(PathStorage.event_visual).child(eventId).child(logoId);
        deleteRef.delete();
    }

    touchIconPicture(file: any, fileId: string, eventId: string, onResolve) {
        const ref = this.storage.child(PathStorage.event_visual).child(eventId).child(fileId);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            })
            // .catch((error) => {
            //     onResolve(null);
            // })
        })
        // .catch((error) => {
        //     onResolve(null);
        // })
    }

    deleteTouchIcon(eventId: string, iconId: string) {
        var deleteRef = this.storage.child(PathStorage.event_visual).child(eventId).child(iconId);
        deleteRef.delete();
    }

    bannerPicture(file: any, fileId: string, eventId: string, onResolve) {
        const ref = this.storage.child(PathStorage.event_visual).child(eventId).child(fileId);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        })
    }

    deleteBanner(eventId: string, bannerId: string) {
        var deleteRef = this.storage.child(PathStorage.locations).child(eventId).child(bannerId);
        deleteRef.delete();
    }

    notificationPicture(file: any, eventId: string, moduleId: string, notificationUID: string, onResolve) {
        const ref = this.storage.child(PathStorage.notifications).child(eventId).child(moduleId).child(notificationUID);
        ref.put(file).then((snapshot) => {
            ref.getDownloadURL().then((url: string) => {
                onResolve(url);
            }).catch((error) => {
                onResolve(null);
            })
        }).catch((error) => {
            onResolve(null);
        })
    }

    deleteNotification(eventId: string, moduleId: string, notificationUID: string) {
        const deleteRef = this.storage.child(PathStorage.notifications).child(eventId).child(moduleId).child(notificationUID);
        deleteRef.delete();
    }

    /********************************************* NEWS FEED ************************************************************************************** */

    uploadImgNewsFeed(file: any, postId, eventId: string, moduleId) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.news_feed).child(eventId).child(moduleId).child(postId);

            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(error);
                })
            }).catch((error) => {
                resolve(error);
            })
        })
    }

    deletePost(postId: string, eventId: string, moduleId: string) {
        const deleteRef = this.storage.child(PathStorage.news_feed).child(eventId).child(moduleId).child(postId)
        deleteRef.delete();
    }


    uploadMapImage(file: any, storageId: string, moduleId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.maps).child(eventId).child(moduleId).child(storageId);

            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(error);
                })
            }).catch((error) => {
                resolve(error);
            })
        })
    }

    deleteMap(storageId: string, moduleId: string, eventId: string) {
        const deleteRef = this.storage.child(PathStorage.maps).child(eventId).child(moduleId).child(storageId)
        deleteRef.delete();
    }

    uploadQuestionQuizImage(file: any, moduleId: string, quizId: string, questionId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.quiz).child(eventId).child(moduleId).child(quizId).child(questionId);

            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(error);
                })
            }).catch((error) => {
                resolve(error);
            })
        })
    }

    uploadAnswerQuizImage(file: any, eventId: string, moduleId: string, quizId: string, questionId: string, answerId: string) : Promise<string>{
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.quiz).child(eventId).child(moduleId).child(quizId).child(`${questionId}-${answerId}` );
            let metadata = {
                contentType: 'image/jpeg',
            };
            ref.putString(file, 'base64',metadata).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve('');
                })
            }).catch((error) => {
                resolve('');
            })
        })
    }

    uploadQuestionTrainingImage(file: any, moduleId: string, trainingId: string, questionId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.training).child(eventId).child(moduleId).child(trainingId).child(questionId);

            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(error);
                })
            }).catch((error) => {
                resolve(error);
            })
        })
    }

    containerLogo(file: any, containerId: string) : Promise<string>{
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.containers).child(containerId);
            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(null);
                })
            }).catch((error) => {
                resolve(null);
            })
        })
    }

    deleteBgImage(containerId: string) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.containers).child(containerId);
            ref.delete().then(() => resolve(true)).catch(er => {
                console.log(er);
                resolve(er);
            })
        })
    }

    deleteEventPublicBgImages(eventId: string, imgId: string) {
        return new Promise((resolve, reject) => {
            const ref = this.storage.child(PathStorage.event_visual).child(eventId).child(imgId);
            ref.delete().then(() => resolve(true)).catch(er => {
                console.log(er);
                resolve(er);
            })
        })
    }

    wdigetModuleBackground(file: any,fileDesktop: any, eventId: string, moduleId: string, imgMobileUID?: string,imgDesktopUID?: string) {
        const promiseMobile = file ? new Promise<string>((resolve, reject) => {
            // const ref = this.storage.child(PathStorage.widget_bgs).child(eventId).child(moduleId);
            const ref = this.storage.child(PathStorage.widgets).child(eventId).child(moduleId).child('imgBgMobile');
            // this.storage.child(PathStorage.widgets).child(eventId).child(moduleId).child(widgetUID);
            ref.put(file).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(null);
                })
            }).catch((error) => {
                resolve(null);
            })
        }) : Promise.resolve('emptyMobile')
        const promiseDesktop = fileDesktop ? new Promise<string>((resolve, reject) => {
            // const ref = this.storage.child(PathStorage.widget_bgs).child(eventId).child(moduleId);
            const ref = this.storage.child(PathStorage.widgets).child(eventId).child(moduleId).child('imgBgDesktop');
            ref.put(fileDesktop).then((snapshot) => {
                ref.getDownloadURL().then((url: string) => {
                    resolve(url);
                }).catch((error) => {
                    resolve(null);
                })
            }).catch((error) => {
                resolve(null);
            })
        }) : Promise.resolve('emptyDesktop')
        return Promise.all([promiseMobile,promiseDesktop])
    }

}
