import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DbAskQuestionProvider } from "../providers/database/db-ask-question";
import { Subscription } from "rxjs";
import { ModerateQuestion } from "../models/moderate-question";
import { FormatedEventLanguageService } from "../providers/formated-event-language/formated-event-language.service";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DbAttendeesProvider } from "src/app/providers/database/db-attendees";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-ask-question-session-moderate",
    templateUrl: "./ask-question-session-moderate.component.html",
    styleUrls: ["./ask-question-session-moderate.component.scss"],
})
export class AskQuestionSessionModerateComponent implements OnInit, OnDestroy {
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("confirmDeleteQuestion")
    public confirmDeleteQuestionSwal: SwalComponent;
    subscriptions: Subscription[] = [];
    eventId: string = null;
    moduleId: string = null;
    sessionId: string = null;
    session: any;

    loader: boolean = true;
    init: boolean = false;

    showedQuestions: ModerateQuestion[] = []; // Showed questions on front office
    alreadyAnsweredQuestions: ModerateQuestion[] = []; // Already answered questions (not visible on front office)
    hidedQuestions: ModerateQuestion[] = []; // Hided questions (not visible on front office)

    language: string = "EnUS";

    deleteQuestionSaved: ModerateQuestion;

    currentPageShowed: number = 1;
    currentPageAnswered: number = 1;
    currentPageHided: number = 1;

    numberOfItemsPerPage: number = 10;

    deleteType: string = "";
    deleteQuestionType: string = "";

    orderBy = "latest";

    constructor(
        private dbAskQuestion: DbAskQuestionProvider,
        private route: ActivatedRoute,
        private SLanguage: FormatedEventLanguageService,
        private dbAttendee: DbAttendeesProvider,
        private translateService: TranslateService
    ) {
        this.eventId = this.route.snapshot.params["eventId"];
        this.moduleId = this.route.snapshot.params["moduleId"];
        this.sessionId = this.route.snapshot.params["sessionId"];
    }

    ngOnInit() {
        this.language = this.SLanguage.getFormatedLanguage();
        this.getSession();
        this.getQuestions();
    }

    /**
     * Unsubscribe subscriptions on destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * Get session of questions
     */
    getSession() {
        this.subscriptions.push(
            this.dbAskQuestion
                .getAskQuestionsItemOrSession(
                    this.moduleId,
                    this.sessionId,
                    "sessions"
                )
                .subscribe((session) => {
                    console.log("Session: ", session, " - ", this.language);
                    this.session = session;
                })
        );
    }

    /**
     * Get all questions for a module and item
     */
    getQuestions() {
        this.subscriptions.push(
            this.dbAskQuestion
                .getAskQuestionsForItemOrSession(
                    this.moduleId,
                    this.sessionId,
                    "sessions"
                )
                .subscribe(async (questions: ModerateQuestion[]) => {
                    console.log('questions: ',[...questions].map(x => Object.assign(x,{})))

                    // this.alreadyAnsweredQuestions = this.showedQuestions = this.hidedQuestions =  []
                    const alreadyAnsweredQuestions = [...questions].filter(q => q.answered)
                    const showedQuestions = [...questions].filter(q => q.visibility)
                    const hidedQuestions = [...questions].filter(q => !q.visibility && !q.answered)
                    // questions.forEach((question) => {
                    //     /**
                    //      * Push and remove on different moderate array of questions, splice is for not having the flash on switch
                    //      */
                    //     if (!question.visibility && !question.answered) {
                    //         if (
                    //             !this.hidedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.hidedQuestions.push(question);
                    //         } else {
                    //             let quest = this.hidedQuestions[
                    //                 this.hidedQuestions.indexOf(
                    //                     this.hidedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 )
                    //             ];

                    //             if (quest.visibility !== question.visibility)
                    //                 quest.visibility = question.visibility;
                    //             if (quest.answered !== question.answered)
                    //                 quest.answered = question.answered;
                    //             quest.totalVotes = question.totalVotes;
                    //         }

                    //         if (
                    //             this.showedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.showedQuestions.splice(
                    //                 this.showedQuestions.indexOf(
                    //                     this.showedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }

                    //         if (
                    //             this.alreadyAnsweredQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.alreadyAnsweredQuestions.splice(
                    //                 this.alreadyAnsweredQuestions.indexOf(
                    //                     this.alreadyAnsweredQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }
                    //     } else if (question.answered) {
                    //         if (
                    //             !this.alreadyAnsweredQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.alreadyAnsweredQuestions.push(question);
                    //         } else {
                    //             let quest = this.alreadyAnsweredQuestions[
                    //                 this.alreadyAnsweredQuestions.indexOf(
                    //                     this.alreadyAnsweredQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 )
                    //             ];

                    //             if (quest.visibility !== question.visibility)
                    //                 quest.visibility = question.visibility;
                    //             if (quest.answered !== question.answered)
                    //                 quest.answered = question.answered;
                    //             quest.totalVotes = question.totalVotes;
                    //         }

                    //         if (
                    //             this.showedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.showedQuestions.splice(
                    //                 this.showedQuestions.indexOf(
                    //                     this.showedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }

                    //         if (
                    //             this.hidedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.hidedQuestions.splice(
                    //                 this.hidedQuestions.indexOf(
                    //                     this.hidedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }
                    //     } else if (question.visibility) {
                    //         if (
                    //             !this.showedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.showedQuestions.push(question);
                    //         } else {
                    //             let quest = this.showedQuestions[
                    //                 this.showedQuestions.indexOf(
                    //                     this.showedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 )
                    //             ];

                    //             if (quest.visibility !== question.visibility)
                    //                 quest.visibility = question.visibility;
                    //             if (quest.answered !== question.answered)
                    //                 quest.answered = question.answered;
                    //             quest.totalVotes = question.totalVotes;
                    //         }

                    //         if (
                    //             this.hidedQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.hidedQuestions.splice(
                    //                 this.hidedQuestions.indexOf(
                    //                     this.hidedQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }

                    //         if (
                    //             this.alreadyAnsweredQuestions.find(
                    //                 (questionFind) =>
                    //                     questionFind.uid == question.uid
                    //             )
                    //         ) {
                    //             this.alreadyAnsweredQuestions.splice(
                    //                 this.alreadyAnsweredQuestions.indexOf(
                    //                     this.alreadyAnsweredQuestions.find(
                    //                         (questionFind) =>
                    //                             questionFind.uid == question.uid
                    //                     )
                    //                 ),
                    //                 1
                    //             );
                    //         }
                    //     }
                    // });

                    const showedUsersIds = Array.from(new Set([...questions].map(q => q.userId)));
                    
                    const allAttendees = await this.dbAttendee.getAttendeesFromArrayIdsPromise(this.eventId, showedUsersIds)

                    // console.log('allAttendees', allAttendees)
                    
                    this.showedQuestions = [...showedQuestions].map(q => {
                        const newQ =  {...q}
                        const attendee = [...allAttendees].filter(_ => _.uid == newQ.userId)[0]
                        const name = attendee ? attendee.name : `${this.translateService.instant('global.anonymous_attendee')} (${showedUsersIds.indexOf(newQ.userId) + 1})`
                        newQ.userId = name;
                        return newQ;
                    });
                    
                    this.alreadyAnsweredQuestions = [...alreadyAnsweredQuestions].map(q => {
                        const newQ =  {...q}
                        const attendee = [...allAttendees].filter(_ => _.uid == newQ.userId)[0]
                        const name = attendee ? attendee.name : `${this.translateService.instant('global.anonymous_attendee')} (${showedUsersIds.indexOf(newQ.userId) + 1})`
                        newQ.userId = name;
                        return newQ;
                    });
                    
                    this.hidedQuestions = [...hidedQuestions].map(q => {
                        const newQ =  {...q}
                        const attendee = [...allAttendees].filter(_ => _.uid == newQ.userId)[0]
                        const name = attendee ? attendee.name : `${this.translateService.instant('global.anonymous_attendee')} (${showedUsersIds.indexOf(newQ.userId) + 1})`
                        newQ.userId = name;
                        return newQ;
                    });
                    this.changeOrder();

                    this.loader = false;
                    this.init = true;
                })
        );
    }

    /**
     * Change answered of a question
     */
    changeAnswered(question: ModerateQuestion) {
        this.dbAskQuestion.updateAnsweredAskQuestionItemOrSession(
            this.eventId,
            this.moduleId,
            this.sessionId,
            [question.uid],
            question.answered,
            "sessions"
        );
        if (question.answered && question.visibility) {
            question.visibility = false;
            this.changeVisibility(question);
        }
    }

    /**
     * Change visibility of a question
     * @param question
     */
    changeVisibility(question: ModerateQuestion) {
        this.dbAskQuestion.updateVisibilityAskQuestionItemOrSession(
            this.eventId,
            this.moduleId,
            this.sessionId,
            [question.uid],
            question.visibility,
            "sessions"
        );
    }

    /**
     * Edit a question
     * @param question
     */
    async editQuestion(question: ModerateQuestion) {
        if (question.edit) {
            question.edit = false;
            try {
                await this.dbAskQuestion.editQuestionOnItemOrSession(
                    this.moduleId,
                    this.sessionId,
                    question,
                    "sessions"
                );
            } catch (error) {
                this.errorSwal.fire();
            }
        } else {
            question.edit = true;
        }
    }

    /**
     * Ask for confirmation of deletion and save question on a temp variable
     * @param question
     * @param type
     * @param questionType
     */
    askForDeleteQuestion(
        question: ModerateQuestion,
        type: string,
        questionType: string
    ) {
        if (type == "all") {
            this.deleteType = type;
            this.deleteQuestionType = questionType;
        } else {
            this.deleteQuestionSaved = question;
        }
        this.confirmDeleteQuestionSwal.fire();
    }

    /**
     * Delete questions
     * @param question
     */
    async deleteQuestions(question: ModerateQuestion) {
        this.loader = true;
        let arrayToDelete = [];
        if (this.deleteType == "all") {
            if (this.deleteQuestionType == "showed") {
                arrayToDelete = this.showedQuestions.map((q) => q.uid);
            } else if (this.deleteQuestionType == "answered") {
                arrayToDelete = this.alreadyAnsweredQuestions.map((q) => q.uid);
            } else {
                arrayToDelete = this.hidedQuestions.map((q) => q.uid);
            }
        } else {
            arrayToDelete.push(question.uid);
        }

        this.dbAskQuestion
            .deleteQuestionForItemOrSession(
                this.moduleId,
                this.sessionId,
                arrayToDelete,
                "sessions"
            )
            .subscribe((result) => {
                console.log("Result: ", result);
                if (this.deleteType == "all") {
                    if (this.deleteQuestionType == "showed") {
                        this.showedQuestions = [];
                    } else if (this.deleteQuestionType == "answered") {
                        this.alreadyAnsweredQuestions = [];
                    } else {
                        this.hidedQuestions = [];
                    }
                } else {
                    if (
                        this.hidedQuestions.find(
                            (questionFind) => questionFind.uid == question.uid
                        )
                    ) {
                        this.hidedQuestions.splice(
                            this.hidedQuestions.indexOf(
                                this.hidedQuestions.find(
                                    (questionFind) =>
                                        questionFind.uid == question.uid
                                )
                            ),
                            1
                        );
                    }
                    if (
                        this.showedQuestions.find(
                            (questionFind) => questionFind.uid == question.uid
                        )
                    ) {
                        this.showedQuestions.splice(
                            this.showedQuestions.indexOf(
                                this.showedQuestions.find(
                                    (questionFind) =>
                                        questionFind.uid == question.uid
                                )
                            ),
                            1
                        );
                    }
                    if (
                        this.alreadyAnsweredQuestions.find(
                            (questionFind) => questionFind.uid == question.uid
                        )
                    ) {
                        this.alreadyAnsweredQuestions.splice(
                            this.alreadyAnsweredQuestions.indexOf(
                                this.alreadyAnsweredQuestions.find(
                                    (questionFind) =>
                                        questionFind.uid == question.uid
                                )
                            ),
                            1
                        );
                    }
                }
                this.deleteType = "";
                this.deleteQuestionType = "";
                this.deleteQuestionSaved = null;
                this.loader = false;
            });
    }

    /**
     * Enable or disable visibility on questions
     */
    async enableOrDisableAllVisibility(type: string, disable: boolean) {
        try {
            await this.dbAskQuestion.updateVisibilityAskQuestionItemOrSession(
                this.eventId,
                this.moduleId,
                this.sessionId,
                type == "showed"
                    ? this.showedQuestions.map((question) => question.uid)
                    : type == "hided"
                    ? this.hidedQuestions.map((question) => question.uid)
                    : this.alreadyAnsweredQuestions.map(
                          (question) => question.uid
                      ),
                disable,
                "sessions"
            );
        } catch (error) {
            this.errorSwal.fire();
        }
    }

    /**
     * Enable or disable answered on questions
     */
    async enableOrDisableAllAnswered(type: string, disable: boolean) {
        try {
            await this.dbAskQuestion.updateAnsweredAskQuestionItemOrSession(
                this.eventId,
                this.moduleId,
                this.sessionId,
                type == "showed"
                    ? this.showedQuestions.map((question) => question.uid)
                    : type == "hided"
                    ? this.hidedQuestions.map((question) => question.uid)
                    : this.alreadyAnsweredQuestions.map(
                          (question) => question.uid
                      ),
                disable,
                "sessions"
            );
        } catch (error) {
            this.errorSwal.fire();
        }
    }

    getDate(dateNumber) {
        return this.language !== "FrFR"
            ? moment(dateNumber * 1000).format("YYYY/MM/DD - HH:mm")
            : moment(dateNumber * 1000).format("DD/MM/YYYY - HH:mm");
    }

    changeOrder(){
        switch (this.orderBy) {
            case "latest":
                this.showedQuestions = [...this.showedQuestions].sort(
                    (a, b) => {
                        return a.createdAt > b.createdAt
                            ? -1
                            : a.createdAt < b.createdAt
                            ? 1
                            : 0;
                    }
                );
                this.hidedQuestions = [...this.hidedQuestions].sort((a, b) => {
                    return a.createdAt > b.createdAt
                        ? -1
                        : a.createdAt < b.createdAt
                        ? 1
                        : 0;
                });
                this.alreadyAnsweredQuestions = [
                    ...this.alreadyAnsweredQuestions,
                ].sort((a, b) => {
                    return a.createdAt > b.createdAt
                        ? -1
                        : a.createdAt < b.createdAt
                        ? 1
                        : 0;
                });
                break;
            case "oldest":
                this.showedQuestions = [...this.showedQuestions].sort(
                    (a, b) => {
                        return a.createdAt > b.createdAt
                            ? 1
                            : a.createdAt < b.createdAt
                            ? -1
                            : 0;
                    }
                );
                this.hidedQuestions = [...this.hidedQuestions].sort((a, b) => {
                    return a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                this.alreadyAnsweredQuestions = [
                    ...this.alreadyAnsweredQuestions,
                ].sort((a, b) => {
                    return a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                break;
            case "like-desc":
                this.showedQuestions = [...this.showedQuestions].sort(
                    (a, b) => {
                        return a.totalVotes > b.totalVotes
                            ? -1
                            : a.totalVotes < b.totalVotes
                            ? 1
                            : a.createdAt > b.createdAt
                            ? 1
                            : a.createdAt < b.createdAt
                            ? -1
                            : 0;
                    }
                );
                this.hidedQuestions = [...this.hidedQuestions].sort((a, b) => {
                    return a.totalVotes > b.totalVotes
                        ? -1
                        : a.totalVotes < b.totalVotes
                        ? 1
                        : a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                this.alreadyAnsweredQuestions = [
                    ...this.alreadyAnsweredQuestions,
                ].sort((a, b) => {
                    return a.totalVotes > b.totalVotes
                        ? -1
                        : a.totalVotes < b.totalVotes
                        ? 1
                        : a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                break;
            case "like-asc":
                this.showedQuestions = [...this.showedQuestions].sort(
                    (a, b) => {
                        return a.totalVotes > b.totalVotes
                            ? 1
                            : a.totalVotes < b.totalVotes
                            ? -1
                            : a.createdAt > b.createdAt
                            ? 1
                            : a.createdAt < b.createdAt
                            ? -1
                            : 0;
                    }
                );
                this.hidedQuestions = [...this.hidedQuestions].sort((a, b) => {
                    return a.totalVotes > b.totalVotes
                        ? 1
                        : a.totalVotes < b.totalVotes
                        ? -1
                        : a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                this.alreadyAnsweredQuestions = [
                    ...this.alreadyAnsweredQuestions,
                ].sort((a, b) => {
                    return a.totalVotes > b.totalVotes
                        ? 1
                        : a.totalVotes < b.totalVotes
                        ? -1
                        : a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt < b.createdAt
                        ? -1
                        : 0;
                });
                break;

            default:
                break;
        }
    }
}
