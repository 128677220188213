import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleGamification extends Module {
    selected: boolean;
    access_groups: any
    typeVision: number;
    typeOrder: string;
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, eventId: string, typeVision: number, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.GAMING, eventId, order, true, true, true);
        this.selected = true;
        this.access_groups = {};
        this.typeVision = typeVision;
        this.typeOrder = 'asc';
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }

}
