import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbGallery } from 'src/app/providers/database/db-gallery';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GalleryFolder } from 'src/app/models/gallery-folder';
import { DbManagerModuleProvider } from 'src/app/providers/database/db-manager-module';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DragulaService } from 'ng2-dragula';
import { NameModule } from 'src/app/models/name-module';
import { Languages } from 'src/app/models/languages';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { RegexProvider } from 'src/app/providers/regex/regex.service';
import { UUID } from 'angular2-uuid';
import { Image } from 'src/app/models/image';
import { PathStorage } from 'src/app/paths/path-storage';
import { TypeVisionDocument } from 'src/app/enums/type-vision-document';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { Group } from 'src/app/models/group';

declare let $: any;

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    providers: [DbGallery]
})
export class GalleryComponent implements OnInit {
    // get the language of the user.
    public userLanguage: string
    public eventLanguage: string   // get the language of the event.
    public languages: Languages = null //event languages
    public activeLanguage: string = null;

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    eventId: string;
    moduleId: string;

    folders: Array<any> = [];
    typeOrder: string = null;
    term;
    folderName: NameModule = new NameModule('', '', '', '', '');

    folderNameRequired: boolean = false;
    folderIdDelete: string = null;
    folderEdit;
    selectedAllInput: boolean = false;
    foldersSelected: Array<boolean> = [];
    loader: boolean = true;
    loadingCreate: boolean = false;
    folderView: boolean = false;
    module: any;

    onReorderShow: boolean = false;
    loaderGeneral: boolean = false;

    // vision foolder
    folderCreateTypeVision: number = TypeVisionDocument.GLOBAL_VISION
    folderEditTypeVision = null
    vision_global: number = TypeVisionDocument.GLOBAL_VISION
    vision_group_vision: number = TypeVisionDocument.GROUP_VISION

    // GROUPS FILTER
    listGroups: Array<Group>
    selectedGroupsCreate: Array<Group> = [];
    filteredListGroupsCreate = [];
    queryGroupsCreate = '';

    selectedGroupsEdit: Array<Group> = [];
    filteredListGroupsEdit = [];
    queryGroupsEdit = '';

    invalidSize: boolean = false;
    translateMsg: string;

    dropFolderConfig: DropzoneConfigInterface = {
        // Change this to your upload POST address:
        url: 'https://us-central1-high5event-a48b5.cloudfunctions.net/utilitiesDropzoneUpload',
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        autoReset: 17000,
        maxFiles: 1
    }
    fileImg: File;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private storage: StorageService,
        private regex: RegexProvider,
        private dbGallery: DbGallery,
        private dbModules: DbManagerModuleProvider,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private dragula: DragulaService,
        private dbGroups: DbGroupsProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];

        this.translateMsg = this.translateService.instant('comp.gallery.dragdrop_msg');
    }

    ngOnInit() {
        this.getModule();
        this.getUserLanguage();
        this.getEvent();
        this.getGroupsList();
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language

            // start dragula reorder bag
            this.dragula.dropModel('bag-folders-list').subscribe((value: any) => {
                this.onReorder(value);
            });
        });
    }

    event: any = null;
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.eventLanguage = event.language;
            this.activeLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.typeOrder = 'custom';
        this.folders = order.targetModel;
        for (let i = 0; i < (this.folders).length; ++i) {
            this.folders[i].order = i;
        }
    }

    saveReorder() {
        this.loaderGeneral = true;
        this.dbGallery.reorderFoldersList(this.moduleId, this.folders)
            .then((success) => {
                this.onReorderShow = false;
                this.loaderGeneral = false;
                if (this.typeOrder == 'custom') this.changeOrder();
                /* case success remove display of btn to save new order and display modal success*/
                this.successSwal.fire();
            }).catch((error) => {
                this.onReorderShow = false;
                this.loaderGeneral = false
                // case error, display modal error
                this.errorSwal.fire();
            })
    }

    getModule() {
        this.dbGallery.getModule(this.moduleId, (module) => {
            if (typeof module !== 'undefined' && module !== null) {
                this.module = module;
                this.typeOrder = module['typeOrder'];
                this.getFolders();
            }
        });
    }

    ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
        if (this.moduleId !== this.route.params['_value']['moduleId']) {
            this.moduleId = this.route.params['_value']['moduleId']
            this.folders = [];
            this.getModule();
            this.getUserLanguage()
        }
    }
    ngAfterContentChecked() {
        let auxRoute = this.router.url.includes('folder');
        if (auxRoute == true) {
            this.folderView = true;
        } else {
            this.folderView = false;
        }
    }

    getFolders() {
        this.dbGallery.getFolders(this.moduleId, this.typeOrder, (folders) => {
            this.folders = folders;
            if (this.eventId && this.moduleId) {
                if (this.folders.length == 0) {
                    this.module['viewApp'] = `/event/${this.eventId}/gallery/${this.moduleId}`;
                    this.dbModules.updateModule(this.module);
                } else if (this.folders.length == 1 && this.folders[0].uuid) {
                    this.module['viewApp'] = `/event/${this.eventId}/gallery/${this.moduleId}/folder/${this.folders[0].uid}`;
                    this.dbModules.updateModule(this.module);
                } else if (this.folders.length >= 2) {
                    this.module['viewApp'] = `/event/${this.eventId}/gallery/${this.moduleId}`;
                    this.dbModules.updateModule(this.module);
                }
            }
            this.loader = false;
        });
    }

    imgUpload(fileImg: File) {
        this.fileImg = fileImg;
        console.log("img upload:", this.fileImg);
    }

    createFolder() {
        this.folderNameRequired = false;
        let mainLanguageIsFilled = this.mainLanguageIsFilled(this.folderName);

        if (this.folderName !== null && this.folderName !== undefined && mainLanguageIsFilled) {
            let order = 0;
            if (this.folders.length !== 0) {
                order = this.folders.length;
            }

            this.loadingCreate = true;
            this.folderName = this.fillEmptyLanguage(this.folderName);
            let folder = new GalleryFolder(this.folderName, order, this.folderCreateTypeVision);

            // get groups
            if (this.folderCreateTypeVision === this.vision_group_vision) {
                for (const group of this.selectedGroupsCreate) {
                    const groupId = group.uid
                    folder.groups[groupId] = group
                }
            }

            this.dbGallery.createFolder(this.moduleId, folder, (status) => {
                console.log(folder);

                if (this.fileImg!) {
                    this.upload(this.fileImg, folder.uid);

                }
                if (status == true) {
                    $('#addFolder').modal('toggle');
                    this.folderName.PtBR = ''
                    this.folderName.EnUS = ''
                    this.folderName.EsES = ''
                    this.folderName.FrFR = ''
                    this.folderName.DeDE = ''
                    this.folderCreateTypeVision = this.vision_global
                    this.selectedGroupsCreate = []
                    this.filteredListGroupsCreate = []
                    this.successSwal.fire();
                    this.loadingCreate = false;
                } else {
                    this.loadingCreate = false;
                    this.errorSwal.fire();
                }
            });
        } else {
            this.folderNameRequired = true;
        }
    }

    editFolder() {
        this.folderNameRequired = false;
        let mainLanguageIsFilled = this.mainLanguageIsFilled(this.folderName);

        if (this.folderName !== null && this.folderName !== undefined && mainLanguageIsFilled) {
            this.loadingCreate = true;
            this.folderName = this.fillEmptyLanguage(this.folderName);
            this.folderEdit.name = this.folderName;
            this.folderEdit.typeVision = this.folderEditTypeVision;
            this.folderEdit.groups = {};

            if (this.folderEdit.typeVision === this.vision_group_vision) {
                for (const group of this.selectedGroupsEdit) {
                    const groupId = group.uid;
                    this.folderEdit.groups[groupId] = group;
                }
            }

            this.dbGallery.editFolder(this.moduleId, this.folderEdit, (status) => {
                if (this.fileImg!) {
                    this.upload(this.fileImg, this.folderEdit.uid);

                }
                if (status == true) {
                    this.loadingCreate = false;
                    $('#editFolder').modal('toggle');
                    this.folderEdit.PtBR = '';
                    this.folderEdit.EnUS = '';
                    this.folderEdit.EsES = '';
                    this.folderEdit.FrFR = '';
                    this.folderEdit.DeDE = '';
                    this.selectedGroupsEdit = [];
                    this.filteredListGroupsEdit = [];
                    this.successSwal.fire();
                } else {
                    this.loadingCreate = false;
                    this.errorSwal.fire();
                }
            });
        } else {
            this.folderNameRequired = true;
        }
    }

    deleteFolder() {
        this.loader = true;
        this.dbGallery.deleteFolders(this.eventId, this.moduleId, [this.folderIdDelete], (status) => {
            if (status == true) {
                this.successSwal.fire();
                this.loader = false;
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.folders.length; i++) {
                this.foldersSelected[this.folders[i]['uid']] = true;
            }
        } else {
            for (let i = 0; i < this.folders.length; i++) {
                this.foldersSelected[this.folders[i]['uid']] = false;
            }
        }
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];
        //let listImgStorageRemove = [];
        // let listRemoveIndexes = [];
        for (let i = 0; i < this.folders.length; i++) {
            if (this.foldersSelected[this.folders[i].uid] == true) {
                listRemove.push(this.folders[i].uid);
                //listImgStorageRemove.push(this.folders[i].storageId);
                // listRemoveIndexes.push(i);
            }
        }

        this.dbGallery.deleteFolders(this.eventId, this.moduleId, listRemove, (data) => {
            if (data == true) {
                //remove all selected box
                for (let j = 0; j < this.foldersSelected.length; j++) {
                    this.foldersSelected[this.folders[j].uid] = false;
                }

                this.getFolders();
                this.selectedAllInput = false;
                this.successSwal.fire();
                // this.loader = false;

            } else {
                this.loader = false;
                this.errorSwal.fire();
            }
        })
    }

    changeOrder() {
        this.dbGallery.changeOrderModule(this.moduleId, this.typeOrder, (status) => { });
    }

    getFolderEdit(folder) {
        this.folderName = folder.name;
        this.folderEdit = folder;
    }

    getFolderDelete(uid) {
        this.folderIdDelete = uid;
    }

    mainLanguageIsFilled(languagesObj) {
        switch (this.eventLanguage) {
            case 'pt_BR':
                if (languagesObj['PtBR'] !== '') {
                    return true;
                }
                break;

            case 'en_US':
                if (languagesObj['EnUS'] !== '') {
                    return true;
                }
                break;

            case 'es_ES':
                if (languagesObj['EsES'] !== '') {
                    return true;
                }
                break;

            case 'fr_FR':
                if (languagesObj['FrFR'] !== '') {
                    return true;
                }
                break;

            case 'de_DE':
                if (languagesObj['DeDE'] !== '') {
                    return true;
                }
                break;
        }

        return false;
    }

    fillEmptyLanguage(languagesObj) {
        let mainLanguageStr = null;

        switch (this.eventLanguage) {
            case 'pt_BR':
                mainLanguageStr = languagesObj['PtBR'];
                break;

            case 'en_US':
                mainLanguageStr = languagesObj['EnUS'];
                break;

            case 'es_ES':
                mainLanguageStr = languagesObj['EsES'];
                break;

            case 'fr_FR':
                mainLanguageStr = languagesObj['FrFR'];
                break;

            case 'de_DE':
                mainLanguageStr = languagesObj['DeDE'];
                break;
        }

        if (languagesObj['PtBR'] == '') {
            languagesObj['PtBR'] = mainLanguageStr;
        }

        if (languagesObj['EnUS'] == '') {
            languagesObj['EnUS'] = mainLanguageStr;
        }

        if (languagesObj['EsES'] == '') {
            languagesObj['EsES'] = mainLanguageStr;
        }

        if (languagesObj['FrFR'] == '') {
            languagesObj['FrFR'] = mainLanguageStr;
        }

        if (languagesObj['DeDE'] == '') {
            languagesObj['DeDE'] = mainLanguageStr;
        }

        return languagesObj;
    }


    // função para upload de img de capa para pasta
    upload(fileImg: File, folderId: string) {
        this.invalidSize = false;
        // arquivo
        const file = fileImg[0];
        let name = fileImg[0].name;
        let aux = name.split('.');
        const imgName = aux[0].toString().toUpperCase();
        const finalName = this.regex.removeAccents(imgName);
        const type = aux[1];
        let imgId = UUID.UUID();

        // VERIFICA O TAMANHO DA IMAGEM
        if (file.size <= this.event.gallery_file_limit) {
            console.log("here");
            this.storage.uploadImg(file, this.eventId, this.moduleId, folderId, imgId, PathStorage.gallery, (url) => {
                let image = new Image();
                image.name = finalName;
                image.url = url;
                image.storageId = imgId;
                image.type = type;
                image.createdAt = Date.now() / 1000 | 0;
                this.dbGallery.saveFolderImage(this.eventId, this.moduleId, folderId, image, (status) => {
                    if (!status) {
                        this.storage.deleteImg(this.eventId, this.moduleId, folderId, imgId, PathStorage.gallery);
                    }
                });
            });
        } else {
            this.invalidSize = true;
        }

    }

    getGroupsList() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroups
            this.listGroups = result['groups'];
        });
    }

    // filter groups
    filterGroupsCreate() {
        if (this.queryGroupsCreate !== "") {
            this.filteredListGroupsCreate = this.listGroups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroupsCreate.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroupsCreate = [];
        }
    }

    // select track from filtered list
    selectGroupCreate(item) {
        if (this.selectedGroupsCreate.length > 0) {
            const index = this.selectedGroupsCreate.indexOf(item);
            if (index == -1) {
                this.selectedGroupsCreate.push(item);
            }
        } else {
            this.selectedGroupsCreate.push(item);
        }
        this.queryGroupsCreate = '';
        this.filteredListGroupsCreate = [];
    }

    // remove selected location
    removeGroupCreate(item) {
        this.selectedGroupsCreate.splice(this.selectedGroupsCreate.indexOf(item), 1);
    }


    // filter groups
    filterGroupsEdit() {
        if (this.queryGroupsEdit !== "") {
            this.filteredListGroupsEdit = this.listGroups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroupsEdit.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroupsEdit = [];
        }
    }

    // select track from filtered list
    selectGroupEdit(item) {
        if (this.selectedGroupsEdit.length > 0) {
            const index = this.selectedGroupsEdit.map(function (e) { return e.uid; }).indexOf(item.uid);

            if (index == -1) {
                this.selectedGroupsEdit.push(item);
            }
        } else {
            this.selectedGroupsEdit.push(item);
        }
        this.queryGroupsEdit = '';
        this.filteredListGroupsEdit = [];
    }

    // remove selected location
    removeGroupEdit(item) {
        this.selectedGroupsEdit.splice(this.selectedGroupsEdit.indexOf(item), 1);
    }

}
